import React from "react";
import { Box, Typography, useTheme, Button } from "@mui/material";
import CountUp from 'react-countup';
import FlexBetween from "./FlexBetween";

const StatBoxAdmin = ({ title, value, increase, icon, description }) => {
  const theme = useTheme();

  return (
    <Box
      gridColumn="span 2"
      gridRow="span 1"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      flex="1 1 100%"
      backgroundColor={theme.palette.background.alt}
      borderRadius="1.5rem"
      boxShadow={5}
    >
      <FlexBetween>
        <Typography variant="h4" sx={{ color: theme.palette.secondary[100] }}>
          {title}
        </Typography>
        {icon}
      </FlexBetween>
      <FlexBetween gap="1rem">
        <Typography
          variant="h2"
          fontWeight="600"
          sx={{ color: theme.palette.secondary[200] }}
        >
          <CountUp  end={value} separator="." />
        </Typography>
        {/*<Typography
          mt={1}
          variant="h5"
          fontStyle="italic"
          sx={{ color: theme.palette.secondary.light }}
        >
          <CountUp end={increase} separator="." /> Familias
        </Typography>*/}
      </FlexBetween>
    </Box>
  );
};

export default StatBoxAdmin;
