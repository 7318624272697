import * as React from "react";
import { useState } from "react";
import { IMaskInput } from "react-imask";
import { Input, ListSubheader } from "@mui/material";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import "dayjs/locale/es";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#000) 000-0000"
      definitions={{
        "#": /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const PersonalForm = ({ sets,datos}) => {
  const [ejecutar, setEjecutar] = React.useState(true);
  const [data, setData] = useState([]);
  const [pro, setPro] = useState([]);
  const [postulado, setPostulado] = useState([]);
  const [registrado, setRegistrado] = useState(true);
  const catPro = async () => {
    try {
      const { data2 } = await axios
        .get("/profesionescat")
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setData(response.data);
          setEjecutar(false);

          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const cat = async () => {
    try {
      const { data2 } = await axios
        .get("/categorias")
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setPro(response.data);
          setEjecutar(false);

          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const getpostulado = async (cedula) => {
    sets.setValueCedula(cedula)
    if(cedula.length ==7 ||cedula.length ==8){
      console.log("se ejecuta");
      try {
        const { data1 } = await axios
          .get(`/postulado/`, {
            params: {
              cedula: cedula,
             
            },
          })
          .then(function (response) {
            // setAlerta({})
            
            let datos=response.data;
            console.log(datos.length);
            if(datos.length==0){
              setRegistrado(false);
              sets.setValueName("");
              sets.setValueApellido("");
              sets.setValue(null);
              sets.setValueDireccion("");
              sets.setValueCorreo("");
              sets.setOcup("");
              sets.setValues({
                textmask: "",
              });
            }else{
              setRegistrado(true)
              sets.setValueName(response.data[0].nombres);
              sets.setValueApellido(response.data[0].apellidos);
              sets.setValue(response.data[0].fecha_nacimiento);
              sets.setValueDireccion(response.data[0].direccion);
              sets.setValueCorreo(response.data[0].email);
              sets.setOcup(response.data[0].ocupacion);
              sets.setValues({
                textmask: response.data[0].telefono,
              });
            }
            console.log(response.data[0].nombres)
            setPostulado(response.data[0]);
            
            return;
          })
          .catch(function (error) {
            console.log("error");
            return;
          });
      } catch (error) {
        return;
      }
    }else{
      setRegistrado(false);
      sets.setValueName("");
      sets.setValueApellido("");
      sets.setValue(null);
      sets.setValueDireccion("");
      sets.setValueCorreo("");
      sets.setOcup("");
      sets.setValues({
        textmask: "",
      });
    }
    
  };
  //DatePicker handler
  const [value, setValue] = React.useState(null);

  //Phone Mask Handler
  // const [values, setValues] = React.useState({
  //   textmask: "",
  // });
  const handleChangeTel = (event) => {
    sets.setValues({
      ...datos.values,
      [event.target.name]: event.target.value,
    });
  };

  //input handlers

  const handleChangeOcup = (event) => {
    sets.setOcup(event.target.value);
  };
  if (ejecutar) {
    catPro();
    cat();
  }
  if (!data || !pro)
    return (
      <Container component="main" maxWidth="xs">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Datos Personales
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="cedula"
            type="number"
            name="cedula"
            label="Cédula"
            fullWidth
            variant="standard"
            value={datos.valueCedula}
            onChange={(event) =>
            getpostulado(event.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="nombre"
            name="nombre"
            label="Nombre"
            fullWidth
            disabled={registrado ?true:false}
            autoComplete="given-name"
            variant="standard"
            value={datos.valueName}
                    onChange={(event) =>
                    sets.setValueName(event.target.value)
                    }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="apellido"
            name="apellido"
            label="Apellido"
            fullWidth
            autoComplete="family-name"
            variant="standard"
            disabled={registrado ?true:false}
            value={datos.valueApellido}
            onChange={(event) =>
            sets.setValueApellido(event.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel htmlFor="telefono" variant="outlined">
              Teléfono
            </InputLabel>
            <Input
              value={datos.values.textmask}
              onChange={handleChangeTel}
              name="textmask"
              id="telefono"
              disabled={registrado ?true:false}
              inputComponent={TextMaskCustom}
              label="Teléfono"
              sx={{ bgcolor: "white" }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            {/*<DateField label="Fecha de Nacimiento" name="fnac" format="DD-MM-YYYY" required fullWidth />*/}
            <DesktopDatePicker
              label="Fecha de Nacimiento"
              name="fnac"
              inputFormat="DD/MM/YYYY"
              value={datos.value}
              disabled={registrado ?true:false}
              onChange={sets.setValue}
              renderInput={(params) => {
                return (
                  <TextField
                    fullWidth
                    variant="standard"
                    sx={{ bgcolor: "white" }}
                    {...params}
                  />
                );
              }}
              clearable
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel id="ocupación-label"> Ocupación </InputLabel>
            <Select
              id="ocupacion"
              name="ocupacion"
              disabled={registrado ?true:false}
              value={datos.ocup}
              label="Ocupación"
              onChange={handleChangeOcup}
              sx={{ bgcolor: "white" }}
              variant="standard"
            >
              <MenuItem value="">
                <em>Seleccione su Ocupación</em>
              </MenuItem>

              {/* {data.map(dato => {
                return <ListSubheader> {dato.nombre} </ListSubheader>
                 } )} */}

              {data.map((dat, index) => (
                <MenuItem value={dat.id_pro}>{dat.descripcion}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="direccion"
            name="direccion"
            label="Dirección"
            fullWidth
            multiline
            maxRows={4}
            variant="standard"
            value={datos.valueDireccion}
            disabled={registrado ?true:false}
            onChange={(event) =>
            sets.setValueDireccion(event.target.value)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="email"
            name="email"
            type="email"
            label="Correo electrónico"
            fullWidth
            variant="standard"
            disabled={registrado ?true:false}
            value={datos.valueCorreo}
            onChange={(event) =>
            sets.setValueCorreo(event.target.value)
            }
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PersonalForm;
