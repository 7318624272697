import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import HomeIcon from '@mui/icons-material/Home';
import BlockIcon from '@mui/icons-material/Block';

const Restricted = () => {

    const nav = useNavigate();
  
    //Theme
    const theme = useTheme();
  
    //BreadCrumbs
    function handleClickBreadCrumbs(event) {
      event.preventDefault();
    }
  
    //Routes
    const handleLinkHome = (event, message) => {
     if (message === 'home') {
       nav("/inicio")
     }
    };

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
      // useEffect hook
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }, []);
  
    if (isLoading) {
      return (
        <Container component="main" maxWidth="xs">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
     );
  }

  return (
   <>
     <Container>
       <Card sx={{ boxShadow:0, bgcolor:'transparent' }}>
        <CardContent>
          <Typography className='text-center'> <BlockIcon sx={{ fontWeight:'bold',mt:5, fontSize:150, color:'firebrick' }}/> </Typography>
          <Typography variant="h2" className='text-center' sx={{ fontWeight:'bold', mt:5, mb:2 }}> ¡ACCESO RESTRINGIDO! </Typography>
          <Typography variant="h4" className='text-center' sx={{ mt:2, mb:5 }}> No estás autorizado para acceder a esta página. </Typography>
          <Grid container justifyContent='center' mb={5}>
            <Grid item xs={11} sm={3}>
               <Button 
               variant='contained' 
               size='large' 
               sx={{ bgcolor:'teal', color:'white' }} 
               startIcon={<HomeIcon />}
               onClick={event => handleLinkHome(event, 'home')}
               fullWidth
               > 
                 Volver al Inicio 
               </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      </Container>
   </>
  )
}

export default Restricted
