import React, { useState, useMemo, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme, useMediaQuery, Divider } from "@mui/material";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import HomeIcon from "@mui/icons-material/Home";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import CardContent from "@mui/material/CardContent";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Modal from "@mui/material/Modal";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import nutIcon from "assets/NUT-icon.png";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import HealingIcon from "@mui/icons-material/Healing";
import DescriptionIcon from "@mui/icons-material/Description";
import StraightenIcon from "@mui/icons-material/Straighten";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import MedicationIcon from "@mui/icons-material/Medication";
import SchoolIcon from "@mui/icons-material/School";
import FeedIcon from "@mui/icons-material/Feed";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Restricted from "components/Restricted";
import { downloadExcel } from "react-export-table-to-excel";
import clienteAxios from "../../config/clienteAxios";
import axios from "axios";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ListaTranscripcion = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("prop");
  const proyect = JSON.parse(token);
  var tipoActividad = 0;
  let tipoAct = proyect.actividad;
  for (let i = 0; i < tipoAct.length; i++) {
    let vacio = 0;
    if (tipoAct[i] === "Jornada de Nutrición" || tipoAct[i] === "Control Nutricional") {
      vacio = 1;
    } else {
      if (tipoAct[i] === "Jornada Médica") {
        vacio = 2;
      }
    }

    tipoActividad = tipoActividad + vacio;
  }

  console.log(tipoActividad);

  const nav = useNavigate();
  const [ejecutar, setEjecutar] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [dataF, setDataF] = React.useState([]);
  const [beneficiario, setBeneficiario] = React.useState([]);
  const [beneficiarioPen, setBeneficiarioPen] = React.useState([]);
  const [beneficiarioErr, setBeneficiarioErr] = React.useState([]);
  const [beneficiarioNut, setBeneficiarioNut] = React.useState([]);
  const [beneficiarioPenNut, setBeneficiarioPenNut] = React.useState([]);
  const [beneficiarioErrNut, setBeneficiarioErrNut] = React.useState([]);
  const [beneficiarioMel, setBeneficiarioMel] = React.useState([]);
  const [beneficiarioPenMel, setBeneficiarioPenMel] = React.useState([]);
  const [beneficiarioErrMel, setBeneficiarioErrMel] = React.useState([]);
  const [beneficiarioDes, setBeneficiarioDes] = React.useState([]);
  const [beneficiarioPenDes, setBeneficiarioPenDes] = React.useState([]);
  const [beneficiarioErrDes, setBeneficiarioErrDes] = React.useState([]);
  const [beneficiarioCap, setBeneficiarioCap] = React.useState([]);
  const [beneficiarioPenCap, setBeneficiarioPenCap] = React.useState([]);
  const [beneficiarioErrCap, setBeneficiarioErrCap] = React.useState([]);
  const [items, setItems] = React.useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  //Selección de Filas
  const [rowSelection, setRowSelection] = useState({});
  useEffect(() => {
    console.info({ rowSelection });
  }, [rowSelection]);

  const rol = items.rol;

  const tableRef = useRef(null);

  //Theme
  const theme = useTheme();

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //Modal Styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "90%" : "40%",
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
  };

  //Tabs
  const [valueTabs, setValueTabs] = React.useState(0);
  const [tabsNutri, setTabsNutri] = React.useState(0);
  const [valueTabsSalud, setValueTabsSalud] = React.useState(0);
  const [valueTabsMenores, setValueTabsMenores] = React.useState(0);
  const [valueTabsMel, setValueTabsMel] = React.useState(0);
  const [valueTabsDesp, setValueTabsDesp] = React.useState(0);
  const [mainTabs, setMainTabs] = React.useState(0);

  const handleChangeTabs = (event, newValueTabs) => {
    setValueTabs(newValueTabs);
  };

  const handleTabsNutri = (event, newTabsNutri) => {
    setTabsNutri(newTabsNutri);
  };

  const handleChangeTabsSalud = (event, newValueSalud) => {
    setValueTabsSalud(newValueSalud);
  };

  const handleChangeTabsMenores = (event, newValueMenores) => {
    setValueTabsMenores(newValueMenores);
  };

  const handleChangeTabsMel = (event, newValueMel) => {
    setValueTabsMel(newValueMel);
  };

  const handleChangeTabsDesp = (event, newValueDesp) => {
    setValueTabsDesp(newValueDesp);
  };

  const handleChangeMainTabs = (event, newMainTabs) => {
    setMainTabs(newMainTabs);
  };

  //Select Handlers
  const [catNutri, setCatNutri] = React.useState("menores");

  const handleCatNutri = (event) => {
    setCatNutri(event.target.value);
  };

  //Modal Handlers
  const [openSalud, setOpenSalud] = React.useState(false);
  const [datosSalud, setDatosSalud] = React.useState({});
  const [datosTratamiento, setDatosTratamiento] = React.useState([]);
  const [datosMotivos, setDatosMotivos] = React.useState("");
  const [datos, setDatos] = React.useState({});
  const handleOpenSalud = (event, message, row) => {
    setDatosSalud(row.original);
    setDatosTratamiento(JSON.parse(row.original.tratamiento));
    let mot = JSON.parse(row.original.motivo);
    let motivos = "";
    for (let j = 0; j < mot.length; j++) {
      motivos = motivos + ", " + mot[j].motivoConsulta;
    }

    setDatosMotivos(motivos.slice(2));
    setOpenSalud(true);
  };

  const handleCloseSalud = () => setOpenSalud(false);

  const [openMenores, setOpenMenores] = React.useState(false);
  const handleOpenMenores = (row) => {
    setDatos(row);
    setOpenMenores(true);
  };
  const handleCloseMenores = () => setOpenMenores(false);

  const [openMel, setOpenMel] = React.useState(false);
  const handleOpenMel = (row) => {
    setDatos(row);
    setOpenMel(true);
  };
  const handleCloseMel = () => {
    setValueTabsMel(0);
    setOpenMel(false);
  };

  const [openDesp, setOpenDesp] = React.useState(false);
  const handleOpenDesp = (row) => {
    setDatos(row);
    setOpenDesp(true);
  };
  const handleCloseDesp = () => setOpenDesp(false);

  const [openCap, setOpenCap] = React.useState(false);
  const handleOpenCap = (row) => {
    setDatos(row);
    setOpenCap(true);
  };
  const handleCloseCap = () => setOpenCap(false);

  //BreadCrumbs
  function handleClickBreadCrumbs(event) {
    event.preventDefault();
  }

  //Routes
  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };

  const handleLinkTrans = (event, message) => {
    if (message === "trans") {
      nav("/transcripcion");
    }
  };

  const handleLinkSalud = (event, message) => {
    let prop = JSON.stringify(proyect);
    if (message === "salud") {
      nav(`/saludFormulario?prop=${prop}`);
    }
  };

  const handleLinkNutri = (event, message) => {
    let prop = JSON.stringify(proyect);
    if (message === "nutri") {
      nav(`/nutriFormulario?prop=${prop}`);
    }
  };

  const handleEditSalud = (event, message) => {
    if (message === "editSalud") {
      nav("/editarSalud");
    }
  };

  const handleEditNutri = (event, message, row) => {
    let prop = JSON.stringify(row.original);

    if (message === "editNutri") {
      nav(`/editarNutri?prop=${prop}`);
    }
  };
  

  const convocatorias = async () => {
    try {
      const { data1 } = await axios
        .get("/convocatoria/", {
          params: {
            id: 1,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setData(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const convocatoriasF = async () => {
    try {
      const { data1 } = await axios
        .get("/convocatoria/", {
          params: {
            id: 2,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setDataF(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const columnsSalud = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Número de Página",
      },
      {
        accessorKey: "nombre",
        header: "Fecha de Actividad",
      },
      {
        accessorKey: "actividad",
        header: "Cédula",
      },
      {
        accessorKey: "fecha",
        header: "Teléfono",
      },
      {
        accessorKey: "locacion",
        header: "Nombre del Representante",
      },
      {
        accessorKey: "locacion",
        header: "Nombres",
      },
      {
        accessorKey: "locacion",
        header: "Apellidos",
      },
      {
        accessorKey: "locacion",
        header: "Grupo Etnico",
      },
      {
        accessorKey: "locacion",
        header: "Municipio",
      },
      {
        accessorKey: "locacion",
        header: "Comunidad",
      },
      {
        accessorKey: "locacion",
        header: "Fecha de Nacimiento",
      },
      {
        accessorKey: "locacion",
        header: "Edad",
      },
      {
        accessorKey: "locacion",
        header: "Rango de Edad",
      },
      {
        accessorKey: "locacion",
        header: "Género",
      },
      {
        accessorKey: "locacion",
        header: "Discapacidad",
      },
      {
        accessorKey: "locacion",
        header: "Tipo de Discapacidad",
      },
      {
        accessorKey: "locacion",
        header: "Motivo de Consulta",
      },
      {
        accessorKey: "locacion",
        header: "Diagnóstico 1",
      },
      {
        accessorKey: "locacion",
        header: "Diagnóstico 2",
      },
      {
        accessorKey: "locacion",
        header: "Diagnóstico 3",
      },
      {
        accessorKey: "locacion",
        header: "Tipo de Enfermedad",
      },
      {
        accessorKey: "locacion",
        header: "Tratamiento",
      },
      {
        accessorKey: "locacion",
        header: "Comentarios",
      },
      {
        accessorKey: "locacion",
        header: "Médico Tratante",
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "estado",
        header: "Estado",
      },
      {
        accessorKey: "municipio",
        header: "Municipio",
      },
      {
        accessorKey: "parroquia",
        header: "Parroquia",
      },
      {
        accessorKey: "centro",
        header: "Centro de Salud",
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
      },
      {
        accessorKey: "grupo_etnico",
        header: "Grupo Etnico",
      },
      {
        accessorKey: "tipo_ingreso",
        header: "Tipo de Ingreso",
      },
      {
        accessorKey: "cedula",
        header: "Cédula del Representante",
      },
      {
        accessorKey: "nombre_rep",
        header: "Nombre del Representante",
      },
      {
        accessorKey: "telefono_rep",
        header: "Teléfono del Representante",
      },

      {
        accessorKey: "nombre",
        header: "Nombres del Beneficiario",
      },
      {
        accessorKey: "apellido",
        header: "Apellidos del Beneficiario",
      },
      {
        accessorKey: "sexo",
        header: "Género",
      },
      {
        accessorKey: "fecha_nacimiento",
        header: "Fecha de Nacimiento",
      },
      {
        accessorKey: "fecha_evaluacion",
        header: "Fecha de Evaluación",
      },
      {
        accessorKey: "edad",
        header: "Edad en Meses",
      },
      {
        accessorKey: "registrado",
        header: "Registrado",
      },
      {
        accessorKey: "escolaridad",
        header: "Escolaridad",
      },
      {
        accessorKey: "lactancia",
        header: "¿Recibe Lactancia Materna?",
      },
      {
        accessorKey: "veces",
        header: "¿Cuántas veces al día?",
      },
      {
        accessorKey: "biberon",
        header: "¿Usa Biberón?",
      },
      {
        accessorKey: "otros_alimentos",
        header: "Otros Alimentos",
      },
      {
        accessorKey: "agua_hervida",
        header: "Agua Hervida",
      },
      {
        accessorKey: "agua_filtrada",
        header: "Agua Filtrada o Clorada",
      },
      {
        accessorKey: "agua_sintratar",
        header: "Agua sin Tratamiento",
      },
      {
        accessorKey: "gripe_tos",
        header: "Gripe o Tos en la Última Semana",
      },
      {
        accessorKey: "fiebre",
        header: "Fiebre en la Última Semana",
      },
      {
        accessorKey: "diarrea",
        header: "Diarrea",
      },
      {
        accessorKey: "vomito",
        header: "Vómito",
      },
      {
        accessorKey: "paludismo_malaria",
        header: "Paludismo o Malaria",
      },
      {
        accessorKey: "discapacidad",
        header: "Discapacidad",
      },
      {
        accessorKey: "tipoDiscapacidad",
        header: "¿Cúal?",
      },
      {
        accessorKey: "edema",
        header: "Edema",
      },
      {
        accessorKey: "circunferencia",
        header: "Circunferencia de Brazo (CMB)",
      },
      {
        accessorKey: "clasificacion_pb",
        header: "Clasificación PB",
      },
      {
        accessorKey: "peso",
        header: "Peso (Kg)",
      },
      {
        accessorKey: "talla",
        header: "Talla (cm)",
      },
      {
        accessorKey: "puntaje_z",
        header: "Puntaje Z",
      },
      {
        accessorKey: "clasificacion_nut",
        header: "Clasificación Nutricional",
      },
      {
        accessorKey: "diagnostico_ingreso",
        header: "Diagnostico de Ingreso",
      },
      {
        accessorKey: "consejeria",
        header: "Consejería en Prácticas Clave",
      },
      {
        accessorKey: "referido",
        header: "referido",
      },
      {
        accessorKey: "albendazol",
        header: "Albendazol",
      },
      {
        accessorKey: "micronutrientes",
        header: "Micronutrientes",
      },
      {
        accessorKey: "lns_mq",
        header: "LNS-MQ (Sobre Amarillo)",
      },
      {
        accessorKey: "ruft",
        header: "RUTF (Sobre Rojo)",
      },
      {
        accessorKey: "kitH",
        header: "Kit de Higiene",
      },
      {
        accessorKey: "kitR",
        header: "Kit de Reposición",
      },
      {
        accessorKey: "amoxicilina",
        header: "Amoxicilina",
      },
      {
        accessorKey: "medicamentos",
        header: "medicamentos",
      },
      {
        accessorKey: "dosis_albendazol",
        header: "Dosis Albendazol",
      },
      {
        accessorKey: "dosis_medicamento",
        header: "Dosis de Suplemento Nutricional",
      },
      {
        accessorKey: "observaciones",
        header: "Observaciones",
      },
      {
        accessorKey: "medico",
        header: "Médico Tratante",
      },
    ],
    []
  );

  const columnsMel = useMemo(
    () => [
      {
        accessorKey: "estado",
        header: "Estado",
      },
      {
        accessorKey: "municipio",
        header: "Municipio",
      },
      {
        accessorKey: "parroquia",
        header: "Parroquia",
      },
      {
        accessorKey: "centro_atencion",
        header: "Centro de Salud",
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
      },
      {
        accessorKey: "grupo_etnico",
        header: "Grupo Etnico",
      },
      {
        accessorKey: "tipo_ingreso",
        header: "Tipo Ingreso",
      },
      {
        accessorKey: "cedula",
        header: "Cédula",
      },
      {
        accessorKey: "nombre",
        header: "Nombres",
      },
      {
        accessorKey: "apellido",
        header: "Apellidos",
      },
      {
        accessorKey: "telefono",
        header: "Teléfono",
      },
      {
        accessorKey: "fecha_nacimiento",
        header: "Fecha de Nacimiento",
      },
      {
        accessorKey: "gestante",
        header: "Gestante",
      },
      {
        accessorKey: "semana_gestacion",
        header: "Semanas de Gestación",
      },
      {
        accessorKey: "peso",
        header: "Peso (Kg)",
      },
      {
        accessorKey: "talla",
        header: "Talla (cm)",
      },
      {
        accessorKey: "lactancia",
        header: "Lactancia",
      },
      {
        accessorKey: "edema",
        header: "Edema",
      },
      {
        accessorKey: "fecha_evaluacion",
        header: "Fecha de Valoración",
      },
      {
        accessorKey: "edad",
        header: "Edad en Años",
      },
      {
        accessorKey: "criterio",
        header: "Criterio Medico",
      }, 
      {
        accessorKey: "circunferencia_brazo",
        header: "Circunferencia de Brazo (CMB)",
      },
      {
        accessorKey: "clasificacion_pb",
        header: "Clasificación PB",
      },
      {
        accessorKey: "imc",
        header: "IMC",
      },
      {
        accessorKey: "diagnostico_imc",
        header: "Diagnóstico IMC",
      },
      {
        accessorKey: "diagnostico_ingreso",
        header: "Diagnóstico de Ingreso",
      },
      {
        accessorKey: "diagnostico_gestacion",
        header: "Diagnostico de Ingreso por Semanas de Gestación",
      },
      {
        accessorKey: "consejeria",
        header: "Consejería en lactancia y alimentación del niño pequeño",
      },
      {
        accessorKey: "referido",
        header: "Referido",
      },
      {
        accessorKey: "albendazol",
        header: "Albendazol",
      },

      {
        accessorKey: "micronutrientes",
        header: "Micronutrientes",
      },
      {
        accessorKey: "lsnmq",
        header: "lsnmq",
      },
      {
        accessorKey: "plumpy",
        header: "Plumpy'Mum",
      },
      {
        accessorKey: "kitH",
        header: "Kit de Higiene",
      },
      {
        accessorKey: "kitR",
        header: "Kit de Reposición",
      },
      {
        accessorKey: "medicamentos",
        header: "Medicamentos",
      },
      {
        accessorKey: "dosis_albendazol",
        header: "Dosis Albendazol",
      },
      {
        accessorKey: "dosis_medicamento",
        header: "Dosis Medicamento",
      },
      {
        accessorKey: "observaciones",
        header: "Observaciones",
      },
      {
        accessorKey: "medico",
        header: "Médico Tratante",
      },
    ],
    []
  );

  const columnsDesp = useMemo(
    () => [
      {
        accessorKey: "fecha_inr",
        header: "Fecha Inicio del Reporte",
      },
      {
        accessorKey: "fecha_finr",
        header: "Fecha Final del Reporte",
      },
      {
        accessorKey: "nombre_centro",
        header: "Centro de Salud",
      },
      {
        accessorKey: "parroquia",
        header: "Parroquia",
      },
      {
        accessorKey: "municipio",
        header: "Municipio",
      },
      {
        accessorKey: "estado",
        header: "Estado",
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
      },
      {
        accessorKey: "ninos_desparacitados",
        header: "Total de Niños Desparasitados",
      },
      {
        accessorKey: "ninas_desparacitadas",
        header: "Total de Niñas Desparasitadas",
      },
      {
        accessorKey: "escolarizados",
        header: "Escolarizados",
      },
      {
        accessorKey: "equipo",
        header: "Equipo",
      },
    ],
    []
  );

  const columnsCap = useMemo(
    () => [
      {
        accessorKey: "fecha_inr",
        header: "Fecha Inicio",
      },
      {
        accessorKey: "fecha_finr",
        header: "Fecha Final",
      },
      {
        accessorKey: "nombre_centro",
        header: "Centro de Salud",
      },
      {
        accessorKey: "parroquia",
        header: "Parroquia",
      },
      {
        accessorKey: "municipio",
        header: "Municipio",
      },
      {
        accessorKey: "estado",
        header: "Estado",
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
      },
      {
        accessorKey: "hombres17",
        header: "Hombres de 0-17 años",
      },
      {
        accessorKey: "mujeres17",
        header: "Mujeres de 0-17 años",
      },
      {
        accessorKey: "hombres59",
        header: "Hombres de 18-59 años",
      },
      {
        accessorKey: "mujeres59",
        header: "Mujeres de 18-59 años",
      },
      {
        accessorKey: "hombres60",
        header: "Hombres de 60 años o más",
      },
      {
        accessorKey: "mujeres60",
        header: "Mujeres de 60 años o más",
      },
      {
        accessorKey: "total",
        header: "Total Capacitados",
      },
      {
        accessorKey: "indigena",
        header: "Indígenas",
      },
      {
        accessorKey: "mestizo",
        header: "Mestizo",
      },
      {
        accessorKey: "sd",
        header:"Sin información",
      },
      {
        accessorKey: "discapacitados",
        header: "Total Discapacitados",
      },
      {
        accessorKey: "total_lgbtqi",
        header: "Personas LGBTQI+",
      },
      {
        accessorKey: "equipo",
        header: "Equipo",
      },
    ],
    []
  );

  // Exportar Tabla
  function handleDownloadExcelM5(rows) {
    let tableData = rows.map((row) => Object.values(row.original));
    for (let index = 0; index < tableData.length; index++) {
      tableData[index] = tableData[index].splice(-62, 54);
    }
    const tableHeaders = columns.map((c) => c.header);
    downloadExcel({
      fileName: `Niños Menores de 5 Años - ${proyect.comunidad} (${proyect.fecha})`,
      sheet: "Niños Menores de 5 Años",
      tablePayload: {
        header: tableHeaders,
        body: tableData,
      },
    });
  }

  function handleDownloadExcelMel(rows) {
    const tableData = rows.map((row) => Object.values(row.original));
    for (let index = 0; index < tableData.length; index++) {
      tableData[index] = tableData[index].splice(-48, 40);
    }
    const tableHeaders = columnsMel.map((c) => c.header);
    downloadExcel({
      fileName: `MEL - ${proyect.comunidad} (${proyect.fecha})`,
      sheet: "MEL",
      tablePayload: {
        header: tableHeaders,
        body: tableData,
      },
    });
  }

  function handleDownloadExcelDesp(rows) {
    const tableData = rows.map((row) => Object.values(row.original));
    for (let index = 0; index < tableData.length; index++) {
      tableData[index] = tableData[index].splice(-17, 11);
    }
    const tableHeaders = columnsDesp.map((c) => c.header);
    downloadExcel({
      fileName: `Desparasitaciones - ${proyect.comunidad} (${proyect.fecha})`,
      sheet: "Desparasitaciones",
      tablePayload: {
        header: tableHeaders,
        body: tableData,
      },
    });
  }

  function handleDownloadExcelCap(rows) {
    const tableData = rows.map((row) => Object.values(row.original));
    for (let index = 0; index < tableData.length; index++) {
      tableData[index] = tableData[index].splice(-22, 16);
    }
    const tableHeaders = columnsCap.map((c) => c.header);
    downloadExcel({
      fileName: `Capacitaciones - ${proyect.comunidad} (${proyect.fecha})`,
      sheet: "Capacitaciones",
      tablePayload: {
        header: tableHeaders,
        body: tableData,
      },
    });
  }

  //Alerts Handlers
  const [openNoti, setOpenNoti] = React.useState(false);
  const [openNotiError, setOpenNotiError] = React.useState(false);
  const [openNotiEliminar, setOpenNotiEliminar] = React.useState(false);

  const beneficiarios = async () => {
    try {
      const { data1 } = await axios
        .get("/transcripbeneficiarios/", {
          params: {
            id: 1,
            idConvocatoria: proyect.id,
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setBeneficiario(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const beneficiariosPendiente = async () => {
    try {
      const { data1 } = await axios
        .get("/transcripbeneficiariosNut/", {
          params: {
            id: 0,
            idConvocatoria: proyect.id,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setBeneficiarioPen(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  // const evaluarTipos = async ()=> {
  //   for (let i = 0; i < datos.length; i++){

  //     for (let f = 0; f < datos[i].bases.length; f++){
  //         if(datos[i].bases[f] == items.idOcupacion ){
  //           datos[i].ident=true
  //         }
  //     }

  //    }
  // }
  const beneficiariosNut = async () => {
    try {
      const { data1 } = await axios
        .get("/transcripbeneficiariosNut/", {
          params: {
            id: 1,
            idConvocatoria: proyect.id,
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setBeneficiarioNut(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const beneficiariosNutPendiente = async () => {
    try {
      const { data1 } = await axios
        .get("/transcripbeneficiariosNut/", {
          params: {
            id: 0,
            idConvocatoria: proyect.id,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setBeneficiarioPenNut(response.data);
          console.log(response.data, "PEndie");
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const beneficiariosNutErrores = async () => {
    try {
      const { data1 } = await axios
        .get("/transcripbeneficiariosNut/", {
          params: {
            id: 3,
            idConvocatoria: proyect.id,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setBeneficiarioErrNut(response.data);
          console.log(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const beneficiariosMel = async () => {
    try {
      const { data1 } = await axios
        .get("/transcripbeneficiariosNutmel", {
          params: {
            id: 1,
            idConvocatoria: proyect.id,
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data, "Mel");
          setBeneficiarioMel(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log(error);
          return;
        });
    } catch (error) {
      return;
    }
  };
  const beneficiariosMelPendiente = async () => {
    try {
      const { data1 } = await axios
        .get("/transcripbeneficiariosNutmel/", {
          params: {
            id: 0,
            idConvocatoria: proyect.id,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setBeneficiarioPenMel(response.data);
          console.log(response.data, "PEndie");
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const beneficiariosMelErrores = async () => {
    try {
      const { data1 } = await axios
        .get("/transcripbeneficiariosNutmel/", {
          params: {
            id: 3,
            idConvocatoria: proyect.id,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setBeneficiarioErrMel(response.data);
          console.log(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const beneficiariosDes = async () => {
    try {
      const { data1 } = await axios
        .get("/transcripbeneficiariosNutdespa/", {
          params: {
            id: 1,
            idConvocatoria: proyect.id,
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setBeneficiarioDes(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const beneficiariosDesPendiente = async () => {
    try {
      const { data1 } = await axios
        .get("/transcripbeneficiariosNutdespa/", {
          params: {
            id: 0,
            idConvocatoria: proyect.id,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setBeneficiarioPenDes(response.data);
          console.log(response.data, "PEndie");
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const beneficiariosDesErrores = async () => {
    try {
      const { data1 } = await axios
        .get("/transcripbeneficiariosNutdespa/", {
          params: {
            id: 3,
            idConvocatoria: proyect.id,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setBeneficiarioErrDes(response.data);
          console.log(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const beneficiariosCap = async () => {
    try {
      const { data1 } = await axios
        .get("/transcripbeneficiariosNutcap/", {
          params: {
            id: 1,
            idConvocatoria: proyect.id,
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setBeneficiarioCap(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const beneficiariosCapPendiente = async () => {
    try {
      const { data1 } = await axios
        .get("/transcripbeneficiariosNutcap/", {
          params: {
            id: 0,
            idConvocatoria: proyect.id,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setBeneficiarioPenCap(response.data);
          console.log(response.data, "PEndie");
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const beneficiariosCapErrores = async () => {
    try {
      const { data1 } = await axios
        .get("/transcripbeneficiariosNutcap/", {
          params: {
            id: 3,
            idConvocatoria: proyect.id,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setBeneficiarioErrCap(response.data);
          console.log(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const handleAprobar = async (event, message, row) => {
    let estatus;
    let datos = row.original;
    let id;
    let clas;
    console.log(datos,"aqui");
    if (message == "aprobar") {
      estatus = 1;
    } else {
      estatus = 3;
    }
    if (datos.clasificacion == "Menores de Cinco") {
      id = datos.id;
      clas = datos.clasificacion;
    } else {
      if (datos.clasificacion == "Mel") {
        id = datos.idmel;
        clas = datos.clasificacion;
      } else {
        if (datos.clasificacion == "Desparasitacion") {
          id = datos.iddesparasitaciones;
          clas = datos.clasificacion;
        } else {
          id = datos.id_capacitaciones;
          clas = datos.calsificacion;
        }
      }
    }
    try {
      const { data1 } = await axios
        .put("actualizarbeneficiarionut", {
          id: id,
          estatus: estatus,
          clasificacion: clas,
          medicoId:datos.medicoId
        })
        .then(function (response) {
          setEjecutar(true);
          if (message == "aprobar") {
          setOpenNoti(true);
          setTimeout(() => {
            setOpenNoti(false);
          }, 2000);
        } else {
          setOpenNotiError(true);
          setTimeout(() => {
            setOpenNotiError(false);
          }, 2000);
        }
          return;
        })
        .catch(function (error) {
          setOpenNotiError(true);
          setTimeout(() => {
            setOpenNotiError(false);
          }, 2000);
          return;
        });
    } catch (error) {
      return;
    }
  };

  const handleEliminar= async (row) => {
 
    let datos = row;
    let id;
    let clas;
 
    if (datos.clasificacion == "Menores de Cinco") {
      id = datos.id;
      clas = datos.clasificacion;
    } else {
      if (datos.clasificacion == "Mel") {
        id = datos.idmel;
        clas = datos.clasificacion;
      } else {
        if (datos.clasificacion == "Desparasitacion") {
          id = datos.iddesparasitaciones;
          clas = datos.clasificacion;
        } else {
          id = datos.id_capacitaciones;
          clas = datos.calsificacion;
        }
      }
    }
    try {
      const { data1 } = await axios
        .put("/eliminarbeneficiarionut", {
          id: id,
          clasificacion: clas,
          usuario:items.usuario
        })
        .then(function (response) {
          setEjecutar(true);
          setOpenNotiEliminar(true);
          setTimeout(() => {
            setOpenNotiEliminar(false);
          }, 2000);
          return;
        })
        .catch(function (error) {
          return;
        });
    } catch (error) {
      return;
    }
  };

  if (ejecutar) {
    convocatorias();
    convocatoriasF();
    beneficiarios();
    beneficiariosNut();
    beneficiariosPendiente();
    beneficiariosNutPendiente();
    beneficiariosNutErrores();
    beneficiariosMel();
    beneficiariosMelPendiente();
    beneficiariosMelErrores();
    beneficiariosDes();
    beneficiariosDesPendiente();
    beneficiariosDesErrores();
    beneficiariosCap();
    beneficiariosCapPendiente();
    beneficiariosCapErrores();
  }
  console.log(beneficiario);
  if (!data)
    return (
      <Container component="main" maxWidth="xs">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );

  //const tipo = "nutri";

  return (
    <Box m="1.5rem 2.5rem">
      {rol === "1" || rol === "2" || rol === "8" || rol === "11" ? (
        <>
          <Collapse in={openNoti}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenNoti(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{
                position: "fixed",
                right: 12,
                zIndex: 999,
                borderRadius: 2,
                boxShadow: 4,
              }}
            >
              ¡Transcripción Aprobada!
            </Alert>
          </Collapse>
          <Collapse in={openNotiError}>
            <Alert
              variant="filled"
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenNotiError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{
                position: "fixed",
                right: 12,
                zIndex: 999,
                borderRadius: 2,
                boxShadow: 4,
              }}
            >
              ¡Transcripción Rechazada!
            </Alert>
          </Collapse>
          <Collapse in={openNotiEliminar}>
            <Alert
              variant="filled"
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenNotiEliminar(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{
                position: "fixed",
                right: 12,
                zIndex: 999,
                borderRadius: 2,
                boxShadow: 4,
              }}
            >
              ¡Beneficiario Eliminado!
            </Alert>
          </Collapse>
          <Box
            role="presentation"
            onClick={handleClickBreadCrumbs}
            sx={{ mb: 3 }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/inicio"
                onClick={(event) => handleLinkHome(event, "home")}
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Inicio
              </Link>
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/transcripcion"
                onClick={(event) => handleLinkTrans(event, "trans")}
              >
                <DriveFileRenameOutlineIcon
                  sx={{ mr: 0.5 }}
                  fontSize="inherit"
                />
                Transcripción
              </Link>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <AssignmentIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Convocatoria
              </Typography>
            </Breadcrumbs>
            <Typography variant="h3" sx={{ mt: 2 }} hidden={proyect.actividad == "Control Nutricional"}>
              <b>Convocatoria:</b> {proyect.comunidad} ({proyect.fecha})
            </Typography>
            <Typography variant="h3" sx={{ mt: 2 }} hidden={proyect.actividad != "Control Nutricional"}>
              <b>Convocatoria:</b> {proyect.comunidad}
            </Typography>
            <Box
              mt="10px"
              display="grid"
              gridTemplateColumns="repeat(8, 1fr)"
              gridAutoRows="160px"
              gap="20px"
              sx={{
                "& > div": {
                  gridColumn: isNonMediumScreens ? undefined : "span 12",
                },
              }}
            >
              <Box
                gridColumn="span 2"
                gridRow="span 2"
                p="1.5rem"
                borderRadius="1.5rem"
                mt="10px"
                height="fit-content"
              >
                <Grid container>
                  <Grid item xs={12} mt={2} hidden={tipoActividad === 1}>
                    <Card
                      className="text-center zoom"
                      sx={{
                        borderRadius: 6,
                        boxShadow: 8,
                        bgcolor: theme.palette.background.alt,
                      }}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid item xs={5}>
                            <Avatar
                              sx={{
                                width: 80,
                                height: 80,
                                bgcolor: "teal",
                                mt: 2,
                                mb: 2,
                                ml: 1,
                              }}
                            >
                              <LocalHospitalIcon
                                sx={{ fontSize: 50, color: "white" }}
                              />
                            </Avatar>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              sx={{
                                fontSize: 16,
                                fontWeight: "bold",
                                mt: 2,
                                ml: 2,
                              }}
                              gutterBottom
                            >
                              Transcribir Salud
                            </Typography>
                            <Button
                              variant="contained"
                              size="lg"
                              sx={{
                                bgcolor: "teal",
                                color: "white",
                                borderRadius: 2,
                                mt: 1,
                                ml: 2,
                              }}
                              fullWidth
                              onClick={(event) =>
                                handleLinkSalud(event, "salud")
                              }
                            >
                              Iniciar
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} mt={2} hidden={tipoActividad === 2}>
                    <Card
                      className="text-center zoom"
                      sx={{
                        borderRadius: 6,
                        boxShadow: 8,
                        bgcolor: theme.palette.background.alt,
                      }}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid item xs={5}>
                            <Avatar
                              sx={{
                                width: 80,
                                height: 80,
                                bgcolor: "teal",
                                mt: 2,
                                mb: 2,
                                ml: 1,
                              }}
                            >
                              <Box
                                component="img"
                                src={nutIcon}
                                height="50px"
                                width="50px"
                              />
                            </Avatar>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              sx={{
                                fontSize: 16,
                                fontWeight: "bold",
                                mt: 2,
                                ml: 2,
                              }}
                              gutterBottom
                            >
                              Transcribir Nutrición
                            </Typography>
                            <Button
                              variant="contained"
                              size="lg"
                              sx={{
                                bgcolor: "teal",
                                color: "white",
                                borderRadius: 2,
                                mt: 1,
                                ml: 2,
                              }}
                              fullWidth
                              onClick={(event) =>
                                handleLinkNutri(event, "nutri")
                              }
                            >
                              Iniciar
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
              <Box
                gridColumn="span 6"
                gridRow="span 3"
                backgroundColor={theme.palette.background.alt}
                p="1.5rem"
                borderRadius="1.5rem"
                mt="40px"
                height="fit-content"
                sx={{ boxShadow: 4 }}
              >
                {tipoActividad === 2 ? (
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={valueTabs}
                        onChange={handleChangeTabs}
                        variant={isSmallScreen ? "scrollable" : "fullWidth"}
                        scrollButtons
                        allowScrollButtonsMobile
                      >
                        <Tab
                          icon={<CheckCircleOutlineIcon />}
                          iconPosition="start"
                          label="Aprobadas"
                          {...a11yProps(0)}
                        />
                        <Tab
                          icon={<ScheduleIcon />}
                          iconPosition="start"
                          label="Pendientes"
                          {...a11yProps(1)}
                        />
                        <Tab
                          icon={<ErrorOutlineIcon />}
                          iconPosition="start"
                          label="Con Errores"
                          {...a11yProps(2)}
                        />
                        {/*<Tab icon={ <FindInPageOutlinedIcon />} iconPosition="start" label="Ver Todas" {...a11yProps(3)} />*/}
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={valueTabs} index={0}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                            Transcripciones Aprobadas
                          </Typography>
                        </Grid>
                      </Grid>
                      <MaterialReactTable
                        columns={columnsSalud}
                        data={beneficiario}
                        localization={MRT_Localization_ES}
                        enableRowActions
                        positionExpandColumn="first"
                        positionActionsColumn="first"
                        enableRowSelection
                        getRowId={(row) => row.userId}
                        onRowSelectionChange={setRowSelection}
                        state={{ rowSelection }}
                        renderRowActionMenuItems={({ row }) => [
                          <MenuItem onClick={handleOpenSalud}>
                            <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                          </MenuItem>,
                        ]}
                        muiTopToolbarProps={{
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiTableHeadCellProps={{
                          align: "center",
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiBottomToolbarProps={{
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiTableBodyProps={{
                          sx: {
                            "& tr:nth-of-type(odd)": {
                              backgroundColor: theme.palette.background.light,
                            },
                          },
                        }}
                        muiTableBodyCellProps={{
                          align: "center",
                        }}
                        muiTablePaperProps={{
                          elevation: 0,
                        }}
                        initialState={{
                          pagination: {
                            pageSize: 5,
                            pageIndex: 0,
                          },
                          sorting: [
                            {
                              id: "fecha",
                              desc: true,
                            },
                          ],
                        }}
                        muiTablePaginationProps={{
                          rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                          showFirstButton: false,
                          showLastButton: false,
                          SelectProps: {
                            native: true,
                          },
                          labelRowsPerPage: "Número de filas visibles",
                        }}
                      />
                    </CustomTabPanel>
                    <CustomTabPanel value={valueTabs} index={1}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                            Transcripciones Pendientes de Aprobación
                          </Typography>
                        </Grid>
                      </Grid>
                      <MaterialReactTable
                        columns={columnsSalud}
                        data={beneficiarioPen}
                        localization={MRT_Localization_ES}
                        enableRowActions
                        positionExpandColumn="first"
                        positionActionsColumn="first"
                        renderRowActionMenuItems={({ row }) => [
                          <MenuItem
                            onClick={(event) =>
                              handleOpenSalud(event, "editar", row)
                            }
                          >
                            <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                          </MenuItem>,
                          <MenuItem>
                            <ThumbUpIcon sx={{ mr: 1 }} /> <b>Aprobar</b>
                          </MenuItem>,
                          <MenuItem>
                            <ThumbDownAltIcon sx={{ mr: 1 }} /> <b>Rechazar</b>
                          </MenuItem>,
                        ]}
                        muiTopToolbarProps={{
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiTableHeadCellProps={{
                          align: "center",
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiBottomToolbarProps={{
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiTableBodyProps={{
                          sx: {
                            "& tr:nth-of-type(odd)": {
                              backgroundColor: theme.palette.background.light,
                            },
                          },
                        }}
                        muiTableBodyCellProps={{
                          align: "center",
                        }}
                        muiTablePaperProps={{
                          elevation: 0,
                        }}
                        initialState={{
                          pagination: {
                            pageSize: 5,
                            pageIndex: 0,
                          },
                          sorting: [
                            {
                              id: "fecha",
                              desc: true,
                            },
                          ],
                        }}
                        muiTablePaginationProps={{
                          rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                          showFirstButton: false,
                          showLastButton: false,
                          SelectProps: {
                            native: true,
                          },
                          labelRowsPerPage: "Número de filas visibles",
                        }}
                      />
                    </CustomTabPanel>
                    <CustomTabPanel value={valueTabs} index={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                            Trancripciones con Errores
                          </Typography>
                        </Grid>
                      </Grid>
                      <MaterialReactTable
                        columns={columnsSalud}
                        data={data}
                        localization={MRT_Localization_ES}
                        enableRowActions
                        positionExpandColumn="first"
                        positionActionsColumn="first"
                        enableRowSelection
                        getRowId={(row) => row.userId}
                        onRowSelectionChange={setRowSelection}
                        state={{ rowSelection }}
                        renderRowActionMenuItems={({ row }) => [
                          <MenuItem onClick={handleOpenSalud}>
                            <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                          </MenuItem>,
                          <MenuItem
                            onClick={(event) =>
                              handleEditSalud(event, "editSalud")
                            }
                          >
                            <EditIcon sx={{ mr: 1 }} /> <b>Editar</b>
                          </MenuItem>,
                        ]}
                        muiTopToolbarProps={{
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiTableHeadCellProps={{
                          align: "center",
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiBottomToolbarProps={{
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiTableBodyProps={{
                          sx: {
                            "& tr:nth-of-type(odd)": {
                              backgroundColor: theme.palette.background.light,
                            },
                          },
                        }}
                        muiTableBodyCellProps={{
                          align: "center",
                        }}
                        muiTablePaperProps={{
                          elevation: 0,
                        }}
                        initialState={{
                          pagination: {
                            pageSize: 5,
                            pageIndex: 0,
                          },
                          sorting: [
                            {
                              id: "fecha",
                              desc: true,
                            },
                          ],
                        }}
                        muiTablePaginationProps={{
                          rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                          showFirstButton: false,
                          showLastButton: false,
                          SelectProps: {
                            native: true,
                          },
                          labelRowsPerPage: "Número de filas visibles",
                        }}
                      />
                    </CustomTabPanel>
                    <CustomTabPanel value={valueTabs} index={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                            Todas las Transcripciones
                          </Typography>
                        </Grid>
                      </Grid>
                      <MaterialReactTable
                        columns={columns}
                        data={data}
                        localization={MRT_Localization_ES}
                        enableRowActions
                        positionExpandColumn="first"
                        positionActionsColumn="first"
                        renderRowActionMenuItems={({ row }) => [
                          <MenuItem onClick={handleOpenSalud}>
                            <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                          </MenuItem>,
                          <MenuItem onClick={handleOpenMenores}>
                            <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                          </MenuItem>,
                          <MenuItem onClick={handleOpenMel}>
                            <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                          </MenuItem>,
                          <MenuItem onClick={handleOpenDesp}>
                            <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                          </MenuItem>,
                        ]}
                        muiTopToolbarProps={{
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiTableHeadCellProps={{
                          align: "center",
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiBottomToolbarProps={{
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiTableBodyProps={{
                          sx: {
                            "& tr:nth-of-type(odd)": {
                              backgroundColor: theme.palette.background.light,
                            },
                          },
                        }}
                        muiTableBodyCellProps={{
                          align: "center",
                        }}
                        muiTablePaperProps={{
                          elevation: 0,
                        }}
                        initialState={{
                          pagination: {
                            pageSize: 5,
                            pageIndex: 0,
                          },
                          sorting: [
                            {
                              id: "fecha",
                              desc: true,
                            },
                          ],
                        }}
                        muiTablePaginationProps={{
                          rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                          showFirstButton: false,
                          showLastButton: false,
                          SelectProps: {
                            native: true,
                          },
                          labelRowsPerPage: "Número de filas visibles",
                        }}
                      />
                    </CustomTabPanel>
                  </Box>
                ) : tipoActividad === 1 ? (
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={tabsNutri}
                        onChange={handleTabsNutri}
                        variant={isSmallScreen ? "scrollable" : "fullWidth"}
                        scrollButtons
                        allowScrollButtonsMobile
                      >
                        <Tab
                          icon={<CheckCircleOutlineIcon />}
                          iconPosition="start"
                          label="Aprobadas"
                          {...a11yProps(0)}
                        />
                        <Tab
                          icon={<ScheduleIcon />}
                          iconPosition="start"
                          label="Pendientes"
                          {...a11yProps(1)}
                        />
                        <Tab
                          icon={<ErrorOutlineIcon />}
                          iconPosition="start"
                          label="Con Errores"
                          {...a11yProps(2)}
                        />
                        {/*<Tab icon={ <FindInPageOutlinedIcon />} iconPosition="start" label="Ver Todas" {...a11yProps(3)} />*/}
                      </Tabs>
                    </Box>
                    <Box
                      mt={2}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                      container
                    >
                      <FormControl sx={{ width: "30%" }} fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Categoría
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={catNutri}
                          label="Categoría"
                          onChange={handleCatNutri}
                        >
                          <MenuItem value={"menores"}>
                            Menores de 5 años
                          </MenuItem>
                          <MenuItem value={"mel"}>MEL</MenuItem>
                          <MenuItem value={"desp"} hidden={proyect.actividad == "Control Nutricional"}>Desparasitaciones</MenuItem>
                          <MenuItem value={"cap"} hidden={proyect.actividad == "Control Nutricional"}>Capacitaciones</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <CustomTabPanel value={tabsNutri} index={0}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                            Transcripciones Aprobadas
                          </Typography>
                        </Grid>
                      </Grid>
                      <MaterialReactTable
                        columns={
                          catNutri === "menores"
                            ? columns
                            : catNutri === "mel"
                            ? columnsMel
                            : catNutri === "desp"
                            ? columnsDesp
                            : columnsCap
                        }
                        data={
                          catNutri === "menores"
                            ? beneficiarioNut
                            : catNutri === "mel"
                            ? beneficiarioMel
                            : catNutri === "desp"
                            ? beneficiarioDes
                            : beneficiarioCap
                        }
                        localization={MRT_Localization_ES}
                        enableRowActions
                        positionExpandColumn="first"
                        positionActionsColumn="first"
                        renderTopToolbarCustomActions={({ table }) => (
                          <Box
                            sx={{
                              display: "flex",
                              gap: "16px",
                              padding: "8px",
                              flexWrap: "wrap",
                            }}
                          >
                            <Button
                              color="success"
                              disabled={
                                table.getPrePaginationRowModel().rows.length ===
                                0
                              }
                              //export all rows, including from the next page, (still respects filtering and sorting)
                              onClick={() =>
                                catNutri === "menores"
                                  ? handleDownloadExcelM5(
                                      table.getPrePaginationRowModel().rows
                                    )
                                  : catNutri === "mel"
                                  ? handleDownloadExcelMel(
                                      table.getPrePaginationRowModel().rows
                                    )
                                  : catNutri === "desp"
                                  ? handleDownloadExcelDesp(
                                      table.getPrePaginationRowModel().rows
                                    )
                                  : handleDownloadExcelCap(
                                      table.getPrePaginationRowModel().rows
                                    )
                              }
                              variant="contained"
                            >
                              <FileDownloadIcon sx={{ mr: 1 }} />
                              Exportar
                            </Button>
                          </Box>
                        )}
                        renderRowActionMenuItems={({ row, closeMenu }) => [
                          <MenuItem
                            onClick={(event) =>
                              handleEditNutri(event, "editNutri", row)
                            }
                          >
                            <EditIcon sx={{ mr: 1 }} /> <b>Editar</b>
                          </MenuItem>,
                          <MenuItem
                            onClick={(event) => {
                              closeMenu();
                              handleOpenMenores(row.original);
                            }}
                            style={{
                              display:
                                catNutri === "menores" ? "block" : "none",
                            }}
                          >
                            <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                          </MenuItem>,
                          <MenuItem
                            onClick={(event) => {
                              closeMenu();
                              handleOpenMel(row.original);
                            }}
                            style={{
                              display: catNutri === "mel" ? "block" : "none",
                            }}
                          >
                            <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                          </MenuItem>,
                          <MenuItem
                            onClick={(event) => {
                              closeMenu();
                              handleOpenDesp(row.original);
                            }}
                            style={{
                              display: catNutri === "desp" ? "block" : "none",
                            }}
                          >
                            <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                          </MenuItem>,
                          <MenuItem
                            onClick={(event) => {
                              closeMenu();
                              handleOpenCap(row.original);
                            }}
                            style={{
                              display: catNutri === "cap" ? "block" : "none",
                            }}
                          >
                            <FeedIcon sx={{ mr:1 }} /> <b>Ver Reporte</b>
                          </MenuItem>,
                          <MenuItem
                          onClick={(event) => {
                            closeMenu();
                            handleEliminar(row.original);
                          }}
                          style={{
                              color: "firebrick",
                              display: rol === "1" || rol === "2" ? "block": "none",
                            }}
                          >
                           <DeleteIcon sx={{ mr:1 }}/> <b>Eliminar</b>
                          </MenuItem>,
                        ]}
                        muiTopToolbarProps={{
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiTableHeadCellProps={{
                          align: "center",
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiBottomToolbarProps={{
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiTableBodyProps={{
                          sx: {
                            "& tr:nth-of-type(odd)": {
                              backgroundColor: theme.palette.background.light,
                            },
                          },
                        }}
                        muiTableBodyCellProps={{
                          align: "center",
                        }}
                        muiTablePaperProps={{
                          elevation: 0,
                        }}
                        initialState={{
                          pagination: {
                            pageSize: 5,
                            pageIndex: 0,
                          },
                          sorting: [
                            {
                              id: "fecha",
                              desc: true,
                            },
                          ],
                        }}
                        muiTablePaginationProps={{
                          rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                          showFirstButton: false,
                          showLastButton: false,
                          SelectProps: {
                            native: true,
                          },
                          labelRowsPerPage: "Número de filas visibles",
                        }}
                      />
                    </CustomTabPanel>
                    <CustomTabPanel value={tabsNutri} index={1}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                            Transcripciones Pendientes de Aprobación
                          </Typography>
                        </Grid>
                      </Grid>
                      <MaterialReactTable
                        columns={
                          catNutri === "menores"
                            ? columns
                            : catNutri === "mel"
                            ? columnsMel
                            : catNutri === "desp"
                            ? columnsDesp
                            : columnsCap
                        }
                        data={
                          catNutri === "menores"
                            ? beneficiarioPenNut
                            : catNutri === "mel"
                            ? beneficiarioPenMel
                            : catNutri === "desp"
                            ? beneficiarioPenDes
                            : beneficiarioPenCap
                        }
                        localization={MRT_Localization_ES}
                        enableRowActions
                        enableRowSelection
                        positionExpandColumn="first"
                        positionActionsColumn="first"
                        renderTopToolbarCustomActions={({ table }) => {
                          const handleAprobarBtn = (event) => {
                            table.getSelectedRowModel().flatRows.map((row) => {
                              /*alert('Agregado: ' + row.getValue('nombres'));*/
                              handleAprobar(event, "aprobar", row);
                            });
                          };

                          const handleRechazarBtn = (event) => {
                            table.getSelectedRowModel().flatRows.map((row) => {
                              /*alert('Agregado: ' + row.getValue('nombres'));*/
                              handleAprobar(event, "rechazar", row);
                            });
                          };
  
                          return (
                            <div style={{ display: "flex", gap: "0.5rem" }}>
                              <Button
                                color="success"
                                hidden={!table.getIsSomeRowsSelected() ? !table.getIsAllRowsSelected() : table.getIsAllRowsSelected() }
                                variant="contained"
                                onClick={handleAprobarBtn}
                              >
                                <ThumbUpIcon sx={{ mr: 1 }} /> Aprobar
                              </Button>
                              <Button
                                color="error"
                                hidden={!table.getIsSomeRowsSelected() ? !table.getIsAllRowsSelected() : table.getIsAllRowsSelected() }
                                variant="contained"
                                onClick={handleRechazarBtn}
                              >
                                <ThumbDownAltIcon sx={{ mr: 1 }} /> Rechazar
                              </Button>
                            </div>
                          );
                        }}
                        renderRowActionMenuItems={({ row, closeMenu }) => [
                          <MenuItem
                            onClick={(event) => {
                              handleOpenMenores(row.original);
                            }}
                            style={{
                              display:
                                catNutri === "menores" ? "block" : "none",
                            }}
                          >
                            <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                          </MenuItem>,
                          <MenuItem
                            onClick={handleOpenMel}
                            style={{
                              display: catNutri === "mel" ? "block" : "none",
                            }}
                          >
                            <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                          </MenuItem>,
                          <MenuItem
                            onClick={handleOpenDesp}
                            style={{
                              display: catNutri === "desp" ? "block" : "none",
                            }}
                          >
                            <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                          </MenuItem>,
                          <MenuItem
                            onClick={handleOpenDesp}
                            style={{
                              display: catNutri === "cap" ? "block" : "none",
                            }}
                          >
                            <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                          </MenuItem>,
                          <MenuItem
                            onClick={(event) => {
                              handleAprobar(event, "aprobar", row);
                              closeMenu();
                            }}
                          >
                            <ThumbUpIcon sx={{ mr: 1 }} /> <b>Aprobar</b>
                          </MenuItem>,
                          <MenuItem
                            onClick={(event) => {
                              handleAprobar(event, "rechazar", row);
                              closeMenu();
                            }}
                          >
                            <ThumbDownAltIcon sx={{ mr: 1 }} /> <b>Rechazar</b>
                          </MenuItem>,
                        ]}
                        muiTopToolbarProps={{
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiTableHeadCellProps={{
                          align: "center",
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiBottomToolbarProps={{
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiTableBodyProps={{
                          sx: {
                            "& tr:nth-of-type(odd)": {
                              backgroundColor: theme.palette.background.light,
                            },
                          },
                        }}
                        muiTableBodyCellProps={{
                          align: "center",
                        }}
                        muiTablePaperProps={{
                          elevation: 0,
                        }}
                        initialState={{
                          pagination: {
                            pageSize: 5,
                            pageIndex: 0,
                          },
                          sorting: [
                            {
                              id: "fecha",
                              desc: true,
                            },
                          ],
                        }}
                        muiTablePaginationProps={{
                          rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                          showFirstButton: false,
                          showLastButton: false,
                          SelectProps: {
                            native: true,
                          },
                          labelRowsPerPage: "Número de filas visibles",
                        }}
                      />
                    </CustomTabPanel>
                    <CustomTabPanel value={tabsNutri} index={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                            Transcripciones con Errores
                          </Typography>
                        </Grid>
                      </Grid>
                      <MaterialReactTable
                        columns={
                          catNutri === "menores"
                            ? columns
                            : catNutri === "mel"
                            ? columnsMel
                            : catNutri === "desp"
                            ? columnsDesp
                            : columnsCap
                        }
                        data={
                          catNutri === "menores"
                            ? beneficiarioErrNut
                            : catNutri === "mel"
                            ? beneficiarioErrMel
                            : catNutri === "desp"
                            ? beneficiarioErrDes
                            : beneficiarioErrCap
                        }
                        localization={MRT_Localization_ES}
                        enableRowActions
                        positionExpandColumn="first"
                        positionActionsColumn="first"
                        renderRowActionMenuItems={({ row, closeMenu }) => [
                          <MenuItem
                            onClick={(event) =>
                              handleEditNutri(event, "editNutri", row)
                            }
                          >
                            <EditIcon sx={{ mr: 1 }} /> <b>Editar</b>
                          </MenuItem>,
                          <MenuItem
                            onClick={(event) => {
                              handleOpenMenores(row.original);
                            }}
                            style={{
                              display:
                                catNutri === "menores" ? "block" : "none",
                            }}
                          >
                            <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                          </MenuItem>,
                          <MenuItem
                            onClick={handleOpenMel}
                            style={{
                              display: catNutri === "mel" ? "block" : "none",
                            }}
                          >
                            <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                          </MenuItem>,
                          <MenuItem
                            onClick={handleOpenDesp}
                            style={{
                              display: catNutri === "desp" ? "block" : "none",
                            }}
                          >
                            <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                          </MenuItem>,
                          <MenuItem
                            onClick={closeMenu}
                            style={{
                              display: catNutri === "cap" ? "block" : "none",
                            }}
                          >
                            <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                          </MenuItem>,
                            <MenuItem
                            onClick={(event) => {
                              closeMenu();
                              handleEliminar(row.original);
                            }}
                            style={{
                                color: "firebrick",
                                display: rol === "1" || rol === "2" ? "block": "none",
                              }}
                            >
                             <DeleteIcon sx={{ mr:1 }}/> <b>Eliminar</b>
                            </MenuItem>,
                        ]}
                        muiTopToolbarProps={{
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiTableHeadCellProps={{
                          align: "center",
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiBottomToolbarProps={{
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiTableBodyProps={{
                          sx: {
                            "& tr:nth-of-type(odd)": {
                              backgroundColor: theme.palette.background.light,
                            },
                          },
                        }}
                        muiTableBodyCellProps={{
                          align: "center",
                        }}
                        muiTablePaperProps={{
                          elevation: 0,
                        }}
                        initialState={{
                          pagination: {
                            pageSize: 5,
                            pageIndex: 0,
                          },
                          sorting: [
                            {
                              id: "fecha",
                              desc: true,
                            },
                          ],
                        }}
                        muiTablePaginationProps={{
                          rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                          showFirstButton: false,
                          showLastButton: false,
                          SelectProps: {
                            native: true,
                          },
                          labelRowsPerPage: "Número de filas visibles",
                        }}
                      />
                    </CustomTabPanel>
                    <CustomTabPanel value={tabsNutri} index={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                            Todas las Transcripciones
                          </Typography>
                        </Grid>
                      </Grid>
                      <MaterialReactTable
                        columns={columns}
                        data={data}
                        localization={MRT_Localization_ES}
                        enableRowActions
                        positionExpandColumn="first"
                        positionActionsColumn="first"
                        renderRowActionMenuItems={({ row }) => [
                          <MenuItem onClick={handleOpenSalud}>
                            <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                          </MenuItem>,
                          <MenuItem
                            onClick={(event) => {
                              handleOpenMenores(row.original);
                            }}
                          >
                            <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                          </MenuItem>,
                          <MenuItem onClick={handleOpenMel}>
                            <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                          </MenuItem>,
                          <MenuItem onClick={handleOpenDesp}>
                            <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                          </MenuItem>,
                        ]}
                        muiTopToolbarProps={{
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiTableHeadCellProps={{
                          align: "center",
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiBottomToolbarProps={{
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiTableBodyProps={{
                          sx: {
                            "& tr:nth-of-type(odd)": {
                              backgroundColor: theme.palette.background.light,
                            },
                          },
                        }}
                        muiTableBodyCellProps={{
                          align: "center",
                        }}
                        muiTablePaperProps={{
                          elevation: 0,
                        }}
                        initialState={{
                          pagination: {
                            pageSize: 5,
                            pageIndex: 0,
                          },
                          sorting: [
                            {
                              id: "fecha",
                              desc: true,
                            },
                          ],
                        }}
                        muiTablePaginationProps={{
                          rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                          showFirstButton: false,
                          showLastButton: false,
                          SelectProps: {
                            native: true,
                          },
                          labelRowsPerPage: "Número de filas visibles",
                        }}
                      />
                    </CustomTabPanel>
                  </Box>
                ) : (
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={mainTabs}
                        onChange={handleChangeMainTabs}
                        variant={isSmallScreen ? "scrollable" : "fullWidth"}
                        scrollButtons
                        allowScrollButtonsMobile
                      >
                        <Tab label="Salud" {...a11yProps(0)} />
                        <Tab label="Nutrición" {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={mainTabs} index={0}>
                      <Box sx={{ width: "100%" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <Tabs
                            value={valueTabs}
                            onChange={handleChangeTabs}
                            variant={isSmallScreen ? "scrollable" : "fullWidth"}
                            scrollButtons
                            allowScrollButtonsMobile
                          >
                            <Tab
                              icon={<CheckCircleOutlineIcon />}
                              iconPosition="start"
                              label="Aprobadas"
                              {...a11yProps(0)}
                            />
                            <Tab
                              icon={<ScheduleIcon />}
                              iconPosition="start"
                              label="Pendientes"
                              {...a11yProps(1)}
                            />
                            <Tab
                              icon={<ErrorOutlineIcon />}
                              iconPosition="start"
                              label="Con Errores"
                              {...a11yProps(2)}
                            />
                            {/*<Tab icon={ <FindInPageOutlinedIcon />} iconPosition="start" label="Ver Todas" {...a11yProps(3)} />*/}
                          </Tabs>
                        </Box>
                        <CustomTabPanel value={valueTabs} index={0}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="h5"
                                sx={{ fontWeight: "bold" }}
                              >
                                Trancripciones Aprobadas
                              </Typography>
                            </Grid>
                          </Grid>
                          <MaterialReactTable
                            columns={columnsSalud}
                            data={beneficiario}
                            localization={MRT_Localization_ES}
                            enableRowActions
                            positionExpandColumn="first"
                            positionActionsColumn="first"
                            renderRowActionMenuItems={({ row }) => [
                              <MenuItem onClick={handleOpenSalud}>
                                <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                              </MenuItem>,
                            ]}
                            muiTopToolbarProps={{
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiTableHeadCellProps={{
                              align: "center",
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiBottomToolbarProps={{
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiTableBodyProps={{
                              sx: {
                                "& tr:nth-of-type(odd)": {
                                  backgroundColor:
                                    theme.palette.background.light,
                                },
                              },
                            }}
                            muiTableBodyCellProps={{
                              align: "center",
                            }}
                            muiTablePaperProps={{
                              elevation: 0,
                            }}
                            initialState={{
                              pagination: {
                                pageSize: 5,
                                pageIndex: 0,
                              },
                              sorting: [
                                {
                                  id: "fecha",
                                  desc: true,
                                },
                              ],
                            }}
                            muiTablePaginationProps={{
                              rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                              showFirstButton: false,
                              showLastButton: false,
                              SelectProps: {
                                native: true,
                              },
                              labelRowsPerPage: "Número de filas visibles",
                            }}
                          />
                        </CustomTabPanel>
                        <CustomTabPanel value={valueTabs} index={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="h5"
                                sx={{ fontWeight: "bold" }}
                              >
                                Transcripciones Pendientes de Aprobación
                              </Typography>
                            </Grid>
                          </Grid>
                          <MaterialReactTable
                            columns={columnsSalud}
                            data={beneficiarioPen}
                            localization={MRT_Localization_ES}
                            enableRowActions
                            positionExpandColumn="first"
                            positionActionsColumn="first"
                            renderRowActionMenuItems={({ row }) => [
                              <MenuItem
                                onClick={(event) =>
                                  handleOpenSalud(event, "editar", row)
                                }
                              >
                                <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                              </MenuItem>,
                              <MenuItem>
                                <ThumbUpIcon sx={{ mr: 1 }} /> <b>Aprobar</b>
                              </MenuItem>,
                              <MenuItem>
                                <ThumbDownAltIcon sx={{ mr: 1 }} />
                                <b>Rechazar</b>
                              </MenuItem>,
                            ]}
                            muiTopToolbarProps={{
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiTableHeadCellProps={{
                              align: "center",
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiBottomToolbarProps={{
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiTableBodyProps={{
                              sx: {
                                "& tr:nth-of-type(odd)": {
                                  backgroundColor:
                                    theme.palette.background.light,
                                },
                              },
                            }}
                            muiTableBodyCellProps={{
                              align: "center",
                            }}
                            muiTablePaperProps={{
                              elevation: 0,
                            }}
                            initialState={{
                              pagination: {
                                pageSize: 5,
                                pageIndex: 0,
                              },
                              sorting: [
                                {
                                  id: "fecha",
                                  desc: true,
                                },
                              ],
                            }}
                            muiTablePaginationProps={{
                              rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                              showFirstButton: false,
                              showLastButton: false,
                              SelectProps: {
                                native: true,
                              },
                              labelRowsPerPage: "Número de filas visibles",
                            }}
                          />
                        </CustomTabPanel>
                        <CustomTabPanel value={valueTabs} index={2}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="h5"
                                sx={{ fontWeight: "bold" }}
                              >
                                Trancripciones con Errores
                              </Typography>
                            </Grid>
                          </Grid>
                          <MaterialReactTable
                            columns={columnsSalud}
                            data={data}
                            localization={MRT_Localization_ES}
                            enableRowActions
                            positionExpandColumn="first"
                            positionActionsColumn="first"
                            renderRowActionMenuItems={({ row }) => [
                              <MenuItem onClick={handleOpenSalud}>
                                <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                              </MenuItem>,
                              <MenuItem
                                onClick={(event) =>
                                  handleEditSalud(event, "editSalud")
                                }
                              >
                                <EditIcon sx={{ mr: 1 }} /> <b>Editar</b>
                              </MenuItem>,
                            ]}
                            muiTopToolbarProps={{
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiTableHeadCellProps={{
                              align: "center",
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiBottomToolbarProps={{
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiTableBodyProps={{
                              sx: {
                                "& tr:nth-of-type(odd)": {
                                  backgroundColor:
                                    theme.palette.background.light,
                                },
                              },
                            }}
                            muiTableBodyCellProps={{
                              align: "center",
                            }}
                            muiTablePaperProps={{
                              elevation: 0,
                            }}
                            initialState={{
                              pagination: {
                                pageSize: 5,
                                pageIndex: 0,
                              },
                              sorting: [
                                {
                                  id: "fecha",
                                  desc: true,
                                },
                              ],
                            }}
                            muiTablePaginationProps={{
                              rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                              showFirstButton: false,
                              showLastButton: false,
                              SelectProps: {
                                native: true,
                              },
                              labelRowsPerPage: "Número de filas visibles",
                            }}
                          />
                        </CustomTabPanel>
                        <CustomTabPanel value={valueTabs} index={3}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="h5"
                                sx={{ fontWeight: "bold" }}
                              >
                                Todas las Transcripciones
                              </Typography>
                            </Grid>
                          </Grid>
                          <MaterialReactTable
                            columns={columns}
                            data={data}
                            localization={MRT_Localization_ES}
                            enableRowActions
                            positionExpandColumn="first"
                            positionActionsColumn="first"
                            renderRowActionMenuItems={({ row }) => [
                              <MenuItem onClick={handleOpenSalud}>
                                <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                              </MenuItem>,
                              <MenuItem
                                onClick={(event) => {
                                  handleOpenMenores(row.original);
                                }}
                              >
                                <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                              </MenuItem>,
                              <MenuItem onClick={handleOpenMel}>
                                <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                              </MenuItem>,
                              <MenuItem onClick={handleOpenDesp}>
                                <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                              </MenuItem>,
                            ]}
                            muiTopToolbarProps={{
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiTableHeadCellProps={{
                              align: "center",
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiBottomToolbarProps={{
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiTableBodyProps={{
                              sx: {
                                "& tr:nth-of-type(odd)": {
                                  backgroundColor:
                                    theme.palette.background.light,
                                },
                              },
                            }}
                            muiTableBodyCellProps={{
                              align: "center",
                            }}
                            muiTablePaperProps={{
                              elevation: 0,
                            }}
                            initialState={{
                              pagination: {
                                pageSize: 5,
                                pageIndex: 0,
                              },
                              sorting: [
                                {
                                  id: "fecha",
                                  desc: true,
                                },
                              ],
                            }}
                            muiTablePaginationProps={{
                              rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                              showFirstButton: false,
                              showLastButton: false,
                              SelectProps: {
                                native: true,
                              },
                              labelRowsPerPage: "Número de filas visibles",
                            }}
                          />
                        </CustomTabPanel>
                      </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={mainTabs} index={1}>
                      <Box sx={{ width: "100%" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <Tabs
                            value={tabsNutri}
                            onChange={handleTabsNutri}
                            variant={isSmallScreen ? "scrollable" : "fullWidth"}
                            scrollButtons
                            allowScrollButtonsMobile
                          >
                            <Tab
                              icon={<CheckCircleOutlineIcon />}
                              iconPosition="start"
                              label="Aprobadas"
                              {...a11yProps(0)}
                            />
                            <Tab
                              icon={<ScheduleIcon />}
                              iconPosition="start"
                              label="Pendientes"
                              {...a11yProps(1)}
                            />
                            <Tab
                              icon={<ErrorOutlineIcon />}
                              iconPosition="start"
                              label="Con Errores"
                              {...a11yProps(2)}
                            />
                            {/*<Tab icon={ <FindInPageOutlinedIcon />} iconPosition="start" label="Ver Todas" {...a11yProps(3)} />*/}
                          </Tabs>
                        </Box>
                        <Box
                          mt={2}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                          }}
                          container
                        >
                          <FormControl sx={{ width: "30%" }} fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Categoría
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={catNutri}
                              label="Categoría"
                              onChange={handleCatNutri}
                            >
                              <MenuItem value={"menores"}>
                                Menores de 5 años
                              </MenuItem>
                              <MenuItem value={"mel"}>MEL</MenuItem>
                              <MenuItem value={"desp"}>
                                Desparasitaciones
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                        <CustomTabPanel value={tabsNutri} index={0}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="h5"
                                sx={{ fontWeight: "bold" }}
                              >
                                Trancripciones Aprobadas
                              </Typography>
                            </Grid>
                          </Grid>
                          <MaterialReactTable
                            columns={
                              catNutri === "menores"
                                ? columns
                                : catNutri === "mel"
                                ? columnsMel
                                : columnsDesp
                            }
                            data={
                              catNutri === "menores"
                                ? beneficiarioNut
                                : catNutri === "mel"
                                ? beneficiarioMel
                                : beneficiarioDes
                            }
                            localization={MRT_Localization_ES}
                            enableRowActions
                            positionExpandColumn="first"
                            positionActionsColumn="first"
                            renderRowActionMenuItems={({ row }) => [
                              <MenuItem
                                onClick={(event) => {
                                  handleOpenMenores(row.original);
                                }}
                                style={{
                                  display:
                                    catNutri === "menores" ? "block" : "none",
                                }}
                              >
                                <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                              </MenuItem>,
                              <MenuItem
                                onClick={handleOpenMel}
                                style={{
                                  display:
                                    catNutri === "mel" ? "block" : "none",
                                }}
                              >
                                <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                              </MenuItem>,
                              <MenuItem
                                onClick={handleOpenDesp}
                                style={{
                                  display:
                                    catNutri === "desp" ? "block" : "none",
                                }}
                              >
                                <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                              </MenuItem>,
                            ]}
                            muiTopToolbarProps={{
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiTableHeadCellProps={{
                              align: "center",
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiBottomToolbarProps={{
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiTableBodyProps={{
                              sx: {
                                "& tr:nth-of-type(odd)": {
                                  backgroundColor:
                                    theme.palette.background.light,
                                },
                              },
                            }}
                            muiTableBodyCellProps={{
                              align: "center",
                            }}
                            muiTablePaperProps={{
                              elevation: 0,
                            }}
                            initialState={{
                              pagination: {
                                pageSize: 5,
                                pageIndex: 0,
                              },
                              sorting: [
                                {
                                  id: "fecha",
                                  desc: true,
                                },
                              ],
                            }}
                            muiTablePaginationProps={{
                              rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                              showFirstButton: false,
                              showLastButton: false,
                              SelectProps: {
                                native: true,
                              },
                              labelRowsPerPage: "Número de filas visibles",
                            }}
                          />
                        </CustomTabPanel>
                        <CustomTabPanel value={tabsNutri} index={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="h5"
                                sx={{ fontWeight: "bold" }}
                              >
                                Transcripciones Pendientes de Aprobación
                              </Typography>
                            </Grid>
                          </Grid>
                          <MaterialReactTable
                            columns={
                              catNutri === "menores"
                                ? columns
                                : catNutri === "mel"
                                ? columnsMel
                                : columnsDesp
                            }
                            data={
                              catNutri === "menores"
                                ? beneficiarioPenNut
                                : catNutri === "mel"
                                ? beneficiarioPenMel
                                : beneficiarioPenDes
                            }
                            localization={MRT_Localization_ES}
                            enableRowActions
                            positionExpandColumn="first"
                            positionActionsColumn="first"
                            renderRowActionMenuItems={({ row, closeMenu }) => [
                              <MenuItem
                                onClick={(event) => {
                                  handleOpenMenores(row.original);
                                }}
                                style={{
                                  display:
                                    catNutri === "menores" ? "block" : "none",
                                }}
                              >
                                <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                              </MenuItem>,
                              <MenuItem
                                onClick={handleOpenMel}
                                style={{
                                  display:
                                    catNutri === "mel" ? "block" : "none",
                                }}
                              >
                                <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                              </MenuItem>,
                              <MenuItem
                                onClick={(event) => {
                                  handleOpenDesp(row.original);
                                }}
                                style={{
                                  display:
                                    catNutri === "desp" ? "block" : "none",
                                }}
                              >
                                <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                              </MenuItem>,
                              <MenuItem
                                onClick={(event) => {
                                  handleAprobar(event, "aprobar", row);
                                  closeMenu();
                                }}
                              >
                                <ThumbUpIcon sx={{ mr: 1 }} /> <b>Aprobar</b>
                              </MenuItem>,
                              <MenuItem
                                onClick={(event) => {
                                  handleAprobar(event, "rechazar", row);
                                  closeMenu();
                                }}
                              >
                                <ThumbDownAltIcon sx={{ mr: 1 }} />
                                <b>Rechazar</b>
                              </MenuItem>,
                            ]}
                            muiTopToolbarProps={{
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiTableHeadCellProps={{
                              align: "center",
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiBottomToolbarProps={{
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiTableBodyProps={{
                              sx: {
                                "& tr:nth-of-type(odd)": {
                                  backgroundColor:
                                    theme.palette.background.light,
                                },
                              },
                            }}
                            muiTableBodyCellProps={{
                              align: "center",
                            }}
                            muiTablePaperProps={{
                              elevation: 0,
                            }}
                            initialState={{
                              pagination: {
                                pageSize: 5,
                                pageIndex: 0,
                              },
                              sorting: [
                                {
                                  id: "fecha",
                                  desc: true,
                                },
                              ],
                            }}
                            muiTablePaginationProps={{
                              rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                              showFirstButton: false,
                              showLastButton: false,
                              SelectProps: {
                                native: true,
                              },
                              labelRowsPerPage: "Número de filas visibles",
                            }}
                          />
                        </CustomTabPanel>
                        <CustomTabPanel value={tabsNutri} index={2}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="h5"
                                sx={{ fontWeight: "bold" }}
                              >
                                Trancripciones con Errores
                              </Typography>
                            </Grid>
                          </Grid>
                          <MaterialReactTable
                            columns={
                              catNutri === "menores"
                                ? columns
                                : catNutri === "mel"
                                ? columnsMel
                                : columnsDesp
                            }
                            data={
                              catNutri === "menores"
                                ? beneficiarioPenNut
                                : catNutri === "mel"
                                ? beneficiarioErrMel
                                : beneficiarioErrDes
                            }
                            localization={MRT_Localization_ES}
                            enableRowActions
                            positionExpandColumn="first"
                            positionActionsColumn="first"
                            renderRowActionMenuItems={({ row, closeMenu }) => [
                              <MenuItem
                                onClick={(event) => {
                                  handleOpenMenores(row.original);
                                }}
                                style={{
                                  display:
                                    catNutri === "menores" ? "block" : "none",
                                }}
                              >
                                <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                              </MenuItem>,
                              <MenuItem
                                style={{
                                  display:
                                    catNutri === "mel" ? "block" : "none",
                                }}
                                onClick={(event) => {
                                  handleOpenMel(row);
                                  closeMenu();
                                }}
                              >
                                <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                              </MenuItem>,
                              <MenuItem
                                onClick={handleOpenDesp}
                                style={{
                                  display:
                                    catNutri === "desp" ? "block" : "none",
                                }}
                              >
                                <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                              </MenuItem>,
                              <MenuItem
                                onClick={(event) =>
                                  handleEditNutri(event, "editNutri")
                                }
                              >
                                <EditIcon sx={{ mr: 1 }} /> <b>Editar</b>
                              </MenuItem>,
                            ]}
                            muiTopToolbarProps={{
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiTableHeadCellProps={{
                              align: "center",
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiBottomToolbarProps={{
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiTableBodyProps={{
                              sx: {
                                "& tr:nth-of-type(odd)": {
                                  backgroundColor:
                                    theme.palette.background.light,
                                },
                              },
                            }}
                            muiTableBodyCellProps={{
                              align: "center",
                            }}
                            muiTablePaperProps={{
                              elevation: 0,
                            }}
                            initialState={{
                              pagination: {
                                pageSize: 5,
                                pageIndex: 0,
                              },
                              sorting: [
                                {
                                  id: "fecha",
                                  desc: true,
                                },
                              ],
                            }}
                            muiTablePaginationProps={{
                              rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                              showFirstButton: false,
                              showLastButton: false,
                              SelectProps: {
                                native: true,
                              },
                              labelRowsPerPage: "Número de filas visibles",
                            }}
                          />
                        </CustomTabPanel>
                        <CustomTabPanel value={tabsNutri} index={3}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant="h5"
                                sx={{ fontWeight: "bold" }}
                              >
                                Todas las Transcripciones
                              </Typography>
                            </Grid>
                          </Grid>
                          <MaterialReactTable
                            columns={columns}
                            data={data}
                            localization={MRT_Localization_ES}
                            enableRowActions
                            positionExpandColumn="first"
                            positionActionsColumn="first"
                            renderRowActionMenuItems={({ row }) => [
                              <MenuItem onClick={handleOpenSalud}>
                                <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                              </MenuItem>,
                              <MenuItem
                                onClick={(event) => {
                                  handleOpenMenores(row.original);
                                }}
                              >
                                <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                              </MenuItem>,
                              <MenuItem onClick={handleOpenMel}>
                                <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                              </MenuItem>,
                              <MenuItem onClick={handleOpenDesp}>
                                <FeedIcon sx={{ mr: 1 }} /> <b>Ver Reporte</b>
                              </MenuItem>,
                            ]}
                            muiTopToolbarProps={{
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiTableHeadCellProps={{
                              align: "center",
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiBottomToolbarProps={{
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiTableBodyProps={{
                              sx: {
                                "& tr:nth-of-type(odd)": {
                                  backgroundColor:
                                    theme.palette.background.light,
                                },
                              },
                            }}
                            muiTableBodyCellProps={{
                              align: "center",
                            }}
                            muiTablePaperProps={{
                              elevation: 0,
                            }}
                            initialState={{
                              pagination: {
                                pageSize: 5,
                                pageIndex: 0,
                              },
                              sorting: [
                                {
                                  id: "fecha",
                                  desc: true,
                                },
                              ],
                            }}
                            muiTablePaginationProps={{
                              rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                              showFirstButton: false,
                              showLastButton: false,
                              SelectProps: {
                                native: true,
                              },
                              labelRowsPerPage: "Número de filas visibles",
                            }}
                          />
                        </CustomTabPanel>
                      </Box>
                    </CustomTabPanel>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Modal open={openSalud} onClose={handleCloseSalud}>
            <Box sx={style}>
              <Tabs
                value={valueTabsSalud}
                onChange={handleChangeTabsSalud}
                variant={isSmallScreen ? "scrollable" : "fullWidth"}
                scrollButtons
                allowScrollButtonsMobile
              >
                <Tab
                  icon={<AssignmentIcon />}
                  label="Info. General"
                  {...a11yProps(0)}
                />
                <Tab
                  icon={<AssignmentIndIcon />}
                  label="Datos del Beneficiario"
                  {...a11yProps(1)}
                />
                <Tab
                  icon={<LocalHospitalIcon />}
                  label="Info. Médica"
                  {...a11yProps(2)}
                />
                <Tab
                  icon={<HealingIcon />}
                  label="Tratamiento"
                  {...a11yProps(3)}
                />
                <Tab
                  icon={<DescriptionIcon />}
                  label="Adicionales"
                  {...a11yProps(4)}
                />
              </Tabs>
              <CustomTabPanel value={valueTabsSalud} index={0}>
                <Typography mt={2}>
                  <b>Número de Página: {datosSalud.num_pagina}</b>
                </Typography>
                <Typography mt={2}>
                  <b>Fecha de Actividad: {datosSalud.fecha_actividad}</b>
                </Typography>
                <Typography mt={2}>
                  <b>Cédula de Identidad: {datosSalud.cedula}</b>
                </Typography>
                <Typography mt={2}>
                  <b>Número de Teléfono: {datosSalud.telefono}</b>
                </Typography>
                <Typography mt={2}>
                  <b>
                    Nombre y Apellido del Representate:
                    {datosSalud.nombre_representante}
                  </b>
                </Typography>
              </CustomTabPanel>
              <CustomTabPanel value={valueTabsSalud} index={1}>
                <Typography mt={2}>
                  <b>Nombres: {datosSalud.nombre_beneficiario}</b>
                </Typography>
                <Typography mt={2}>
                  <b>Apellidos: {datosSalud.apellido}</b>
                </Typography>
                <Typography mt={2}>
                  <b>Grupo Etnico:{datosSalud.grupo_etnico}</b>
                </Typography>
                <Typography mt={2}>
                  <b>Municipio: {datosSalud.municipio}</b>
                </Typography>
                <Typography mt={2}>
                  <b>Comunidad: {datosSalud.comunidad}</b>
                </Typography>
                <Typography mt={2}>
                  <b>Fecha de Nacimiento: {datosSalud.fecha_nacimiento}</b>
                </Typography>
                <Typography mt={2}>
                  <b>Edad: {datosSalud.edad}</b>
                </Typography>
                <Typography mt={2}>
                  <b>Rango de Edad: {datosSalud.rango_edad}</b>
                </Typography>
                <Typography mt={2}>
                  <b>Género: {datosSalud.genero}</b>
                </Typography>
                <Typography mt={2}>
                  <b>Discapacidad:{datosSalud.discapacidad}</b>
                </Typography>
              </CustomTabPanel>
              <CustomTabPanel value={valueTabsSalud} index={2}>
                <Typography mt={2}>
                  <b>Motivo de Consulta:</b>
                  {datosMotivos}
                </Typography>
                <Typography mt={2}>
                  <b>Diagnóstico 1:{datosSalud.diagnostico1} </b>
                </Typography>
                <Typography mt={2}>
                  <b>Diagnóstico 2:{datosSalud.diagnostico2} </b>
                </Typography>
                <Typography mt={2}>
                  <b>Diagnóstico 3: {datosSalud.diagnostico3} </b>
                </Typography>
                <Typography mt={2}>
                  <b>Tipo de Enfermedad:{datosSalud.enfermedad} </b>
                </Typography>
              </CustomTabPanel>
              <CustomTabPanel value={valueTabsSalud} index={3}>
                {datosTratamiento.map((dat, index) => (
                  <p>
                    {dat.medicamento} {dat.dosis}
                  </p>
                ))}
              </CustomTabPanel>
              <CustomTabPanel value={valueTabsSalud} index={4}>
                <Typography mt={2}>
                  <b>Médico Tratante: {datosSalud.medico} </b>
                </Typography>
                <Typography mt={2}>
                  <b>Comentarios: {datosSalud.comentarios} </b>
                </Typography>
              </CustomTabPanel>
              <Divider sx={{ mb: 2 }} />
              <Grid
                container
                sx={{ mt: 2 }}
                spacing={1}
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleCloseSalud}
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          <Modal open={openMenores} onClose={handleCloseMenores}>
            <Box sx={style}>
              <Tabs
                value={valueTabsMenores}
                onChange={handleChangeTabsMenores}
                variant={isSmallScreen ? "scrollable" : "fullWidth"}
                scrollButtons
                allowScrollButtonsMobile
              >
                <Tab
                  icon={<AssignmentIcon />}
                  label="Info. General"
                  {...a11yProps(0)}
                />
                <Tab
                  icon={<AssignmentIndIcon />}
                  label="Datos del Beneficiario"
                  {...a11yProps(1)}
                />
                <Tab
                  icon={<ChildCareIcon />}
                  label="Lactancia"
                  {...a11yProps(2)}
                />
                <Tab
                  icon={<StraightenIcon />}
                  label="Info. Nutricional"
                  {...a11yProps(3)}
                />
                <Tab
                  icon={<HealingIcon />}
                  label="Tratamiento"
                  {...a11yProps(4)}
                />
                <Tab
                  icon={<DescriptionIcon />}
                  label="Adicionales"
                  {...a11yProps(5)}
                />
              </Tabs>
              <CustomTabPanel value={valueTabsMenores} index={0}>
                <Typography mt={2}>
                  <b>Estado: </b>
                  {datos.estado}
                </Typography>
                <Typography mt={2}>
                  <b>Municipio: </b>
                  {datos.municipio}
                </Typography>
                <Typography mt={2}>
                  <b>Parroquia: </b>
                  {datos.parroquia}
                </Typography>
                <Typography mt={2}>
                  <b>Nombre del Centro de Salud: </b>
                  {datos.centro}
                </Typography>
                <Typography mt={2}>
                  <b>Comunidad: </b>
                  {datos.comunidad}
                </Typography>
              </CustomTabPanel>
              <CustomTabPanel value={valueTabsMenores} index={1}>
                <Typography mt={2}>
                  <b>Grupo Etnico: </b>
                  {datos.grupo_etnico}
                </Typography>
                <Typography mt={2}>
                  <b>Tipo de Ingreso: </b> {datos.tipo_ingreso}
                </Typography>
                <Typography mt={2}>
                  <b>Nombre del Representante: </b>
                  {datos.nombre_rep}
                </Typography>
                <Typography mt={2}>
                  <b>Número de Teléfono del Representante: </b>
                  {datos.telefono_rep}
                </Typography>
                <Typography mt={2}>
                  <b>Cédula del Representante: </b>
                  {datos.cedula}
                </Typography>
                <Typography mt={2}>
                  <b>Nombres del Beneficiario: </b>
                  {datos.nombre}
                </Typography>
                <Typography mt={2}>
                  <b>Apellidos del Beneficiario: </b> {datos.apellido}
                </Typography>
                <Typography mt={2}>
                  <b>Género: </b>
                  {datos.sexo}
                </Typography>
                <Typography mt={2}>
                  <b>Fecha de Nacimiento: </b> {datos.fecha_nacimiento}
                </Typography>
                <Typography mt={2}>
                  <b>Fecha de Evaluación: </b> {datos.fecha_evaluacion}
                </Typography>
                <Typography mt={2}>
                  <b>Edad en Meses: </b> {datos.edad}
                </Typography>
              </CustomTabPanel>
              <CustomTabPanel value={valueTabsMenores} index={2}>
                <Typography mt={2}>
                  <b>¿Recibe Lactancia Materna?: </b> {datos.lactancia}
                </Typography>
                <Typography mt={2}>
                  <b>¿Cuántas veces al día?: </b> {datos.veces}
                </Typography>
                <Typography mt={2}>
                  <b>¿Usa Biberón?: </b>
                  {datos.biberon}
                </Typography>
                <Typography mt={2}>
                  <b>Otros Alimentos: </b>
                  {datos.otros_alimentos}
                </Typography>
                <Typography mt={2}>
                  <b>Agua Hervida: </b>
                  {datos.agua_hervida}
                </Typography>
                <Typography mt={2}>
                  <b>Agua Filtrada o Clorada: </b>
                  {datos.agua_filtrada}
                </Typography>
                <Typography mt={2}>
                  <b>Agua sin Tratamiento: </b>
                  {datos.agua_sintratar}
                </Typography>
                <Typography mt={2}>
                  <b>Gripe o Tos en la Última Semana: </b>
                  {datos.gripe_tos}
                </Typography>
                <Typography mt={2}>
                  <b>Fiebre en la Última Semana: </b> {datos.fiebre}
                </Typography>
                <Typography mt={2}>
                  <b>Diarrea en la Última Semana: </b>
                  {datos.diarrea}
                </Typography>
                <Typography mt={2}>
                  <b>Paludismo o Malaria: </b>
                  {datos.vomito}
                </Typography>
                <Typography mt={2}>
                  <b>Discapacidad: </b>
                  {datos.discapacidad}
                </Typography>
                <Typography mt={2}>
                  <b>¿Cuál?: </b>
                  {datos.tipoDiscapacion}
                </Typography>
              </CustomTabPanel>
              <CustomTabPanel value={valueTabsMenores} index={3}>
                <Typography mt={2}>
                  <b>Edema: </b>
                  {datos.edema}
                </Typography>
                <Typography mt={2}>
                  <b>Circunferencia de Brazo (CMB): </b>
                  {datos.circunferencia}
                </Typography>
                <Typography mt={2}>
                  <b>Clasificación PB: </b>
                  {datos.clasificacion_pb}
                </Typography>
              </CustomTabPanel>
              <CustomTabPanel value={valueTabsMenores} index={4}>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={6} item>
                    <Typography mb={2}>
                      <b>Consejería en Prácticas Clave: </b>
                      {datos.consejeria}
                    </Typography>
                  </Grid>
                  <Grid xs={12} sm={6} item>
                    <Typography mb={2}>
                      <b>Albendazol: </b>
                      {datos.albendazol}
                    </Typography>
                  </Grid>
                  <Grid xs={12} sm={6} item>
                    <Typography mb={2}>
                      <b>Micronutrientes: </b> {datos.micronutrientes}
                    </Typography>
                  </Grid>
                  <Grid xs={12} sm={6} item>
                    <Typography mb={2}>
                      <b>LNS-MQ (Sobre Amarillo): </b> {datos.lns_mq}
                    </Typography>
                  </Grid>
                  <Grid xs={12} sm={6} item>
                    <Typography mb={2}>
                      <b>RUTF (Sobre Rojo): </b> {datos.ruft}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider>
                  <Chip label="DOSIS" />
                </Divider>
                <Typography mt={2}>
                  <b>Medicamento: </b> {datos.medicamentos}
                </Typography>
              </CustomTabPanel>
              <CustomTabPanel value={valueTabsMenores} index={5}>
                <Typography mt={2}>
                  <b>Observaciones: </b>
                  {datos.observaciones}
                </Typography>
                <Typography mt={2}>
                  <b>Médico Tratante: </b>
                  {datos.medico}
                </Typography>
              </CustomTabPanel>
              <Divider sx={{ mb: 2 }} />
              <Grid
                container
                sx={{ mt: 2 }}
                spacing={1}
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleCloseMenores}
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          <Modal open={openMel} onClose={handleCloseMel}>
            <Box sx={style}>
              <Tabs
                value={valueTabsMel}
                onChange={handleChangeTabsMel}
                variant={isSmallScreen ? "scrollable" : "fullWidth"}
                scrollButtons
                allowScrollButtonsMobile
              >
                <Tab
                  icon={<AssignmentIcon />}
                  label="Info. General"
                  {...a11yProps(0)}
                />
                <Tab
                  icon={<AssignmentIndIcon />}
                  label="Datos del Beneficiario"
                  {...a11yProps(1)}
                />
                <Tab
                  icon={<StraightenIcon />}
                  label="Info. Nutricional"
                  {...a11yProps(2)}
                />
                <Tab
                  icon={<HealingIcon />}
                  label="Tratamiento"
                  {...a11yProps(3)}
                />
                <Tab
                  icon={<DescriptionIcon />}
                  label="Adicionales"
                  {...a11yProps(4)}
                />
              </Tabs>
              <CustomTabPanel value={valueTabsMel} index={0}>
                <Typography mt={2}>
                  <b>Estado: </b> {datos.estado}
                </Typography>
                <Typography mt={2}>
                  <b>Municipio: </b> {datos.municipio}
                </Typography>
                <Typography mt={2}>
                  <b>Parroquia: </b> {datos.parroquia}
                </Typography>
                <Typography mt={2}>
                  <b>Nombre del Centro de Salud: </b> {datos.centro_atencion}
                </Typography>
                <Typography mt={2}>
                  <b>Comunidad: </b> {datos.comunidad}
                </Typography>
              </CustomTabPanel>
              <CustomTabPanel value={valueTabsMel} index={1}>
                <Typography mt={2}>
                  <b>Grupo Etnico: </b> {datos.grupo_etnico}
                </Typography>
                <Typography mt={2}>
                  <b>Cédula de Identidad: </b> {datos.cedula}
                </Typography>
                <Typography mt={2}>
                  <b>Nombres: </b> {datos.nombre}
                </Typography>
                <Typography mt={2}>
                  <b>Apellidos: </b> {datos.apellido}
                </Typography>
                <Typography mt={2}>
                  <b>Teléfono: </b> {datos.telefono}
                </Typography>
                <Typography mt={2}>
                  <b>Fecha de Nacimiento: </b> {datos.fecha_nacimiento}
                </Typography>
                <Typography mt={2}>
                  <b>Gestante: </b> {datos.gestante}
                </Typography>
                <Typography mt={2}>
                  <b>Semanas de Gestación: </b> {datos.semana_gestacion}
                </Typography>
                <Typography mt={2}>
                  <b>En Lactancia: </b> {datos.lactancia}
                </Typography>
                <Typography mt={2}>
                  <b>Fecha de Valoración: </b> {datos.fecha_evaluacion}
                </Typography>
                <Typography mt={2}>
                  <b>Edad en Años: </b> {datos.edad}
                </Typography>
              </CustomTabPanel>
              <CustomTabPanel value={valueTabsMel} index={2}>
                <Typography mt={2}>
                  <b>Circunferencia de Brazo (CMB): </b>{" "}
                  {datos.circunferencia_brazo}
                </Typography>
                <Typography mt={2}>
                  <b>Clasificación PB: </b> {datos.clasificacion_pb}
                </Typography>
                <Typography mt={2}>
                  <b>IMC: </b> {datos.imc}
                </Typography>
                <Typography mt={2}>
                  <b>Diagnóstico IMC: </b> {datos.diagnostico_imc}
                </Typography>
                <Typography mt={2}>
                  <b>Diagnóstico de Ingreso: </b> {datos.diagnostico_ingreso}
                </Typography>
              </CustomTabPanel>
              <CustomTabPanel value={valueTabsMel} index={3}>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={12} item>
                    <Typography mb={2}>
                      <b>
                        Consejería en Lactancia Materna y Alimentación del Niño
                        Pequeño:
                      </b>{" "}
                      {datos.consejeria}
                    </Typography>
                  </Grid>
                  <Grid xs={12} sm={3} item>
                    <Typography mb={2}>
                      <b>Referido: </b> {datos.referido}
                    </Typography>
                  </Grid>
                  <Grid xs={12} sm={3} item>
                    <Typography mb={2}>
                      <b>Albendazol: </b> {datos.albendazol}
                    </Typography>
                  </Grid>
                  <Grid xs={12} sm={3} item>
                    <Typography mb={2}>
                      <b>Micronutrientes: </b> {datos.micronutrientes}
                    </Typography>
                  </Grid>
                  <Grid xs={12} sm={3} item>
                    <Typography mb={2}>
                      <b>LNS-MQ: </b> {datos.lnsmq}
                    </Typography>
                  </Grid>
                  <Grid xs={12} sm={3} item>
                    <Typography mb={2}>
                      <b>Plumpy'Mum: </b> {datos.plumpy}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider>
                  <Chip label="DOSIS" />
                </Divider>
                <Typography mt={2}>
                  <b>Albendazol: </b> {datos.dosis_albendazol}
                </Typography>
                <Typography mt={2}>
                  <b>{datos.medicamentos}: </b> {datos.dosis_medicamento}
                </Typography>
              </CustomTabPanel>
              <CustomTabPanel value={valueTabsMel} index={4}>
                <Typography mt={2}>
                  <b>Observaciones: </b> {datos.observaciones}
                </Typography>
                <Typography mt={2}>
                  <b>Médico Tratante: </b> {datos.medico}
                </Typography>
              </CustomTabPanel>
              <Divider sx={{ mb: 2 }} />
              <Grid
                container
                sx={{ mt: 2 }}
                spacing={1}
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleCloseMel}
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          <Modal open={openDesp} onClose={handleCloseDesp}>
            <Box sx={style}>
              <Typography variant="h4" mb={1} sx={{ fontWeight: "bold" }}>
                <MedicationIcon sx={{ fontSize: 35 }} /> Reporte de
                Desparasitaciones
              </Typography>
              <Divider sx={{ mb: 3 }} />
              <Typography mt={2}>
                <b>Fecha Inicio del Reporte: </b> {datos.fecha_inr}
              </Typography>
              <Typography mt={2}>
                <b>Fecha Final del Reporte: </b> {datos.fecha_finr}
              </Typography>
              <Typography mt={2}>
                <b>Nombre del Centro de Salud: </b> {datos.nombre_centro}
              </Typography>
              <Typography mt={2}>
                <b>Parroquia: </b> {datos.parroquia}
              </Typography>
              <Typography mt={2}>
                <b>Municipio: </b> {datos.municipio}
              </Typography>
              <Typography mt={2}>
                <b>Estado: </b> {datos.estado}
              </Typography>
              <Typography mt={2}>
                <b>Comunidad: </b> {datos.comunidad}
              </Typography>
              <Typography mt={2}>
                <b>Total Niños Desparasitados: </b> {datos.ninos_desparacitados}
              </Typography>
              <Typography mt={2}>
                <b>Total Niñas Desparasitadas: </b> {datos.ninos_desparacitadas}
              </Typography>
              <Typography mt={2}>
                <b>Equipo: </b> {datos.equipo}
              </Typography>
              <Divider sx={{ mt: 3, mb: 2 }} />
              <Grid
                container
                sx={{ mt: 2 }}
                spacing={1}
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleCloseDesp}
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          <Modal open={openCap} onClose={handleCloseCap}>
            <Box sx={style}>
              <Typography variant="h4" mb={1} sx={{ fontWeight: "bold" }}>
                <SchoolIcon sx={{ fontSize: 35 }} /> Reporte de Capacitaciones
              </Typography>
              <Divider sx={{ mt: 2, mb: 2 }}>
                <Chip label="INFO. GENERAL" />
              </Divider>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Typography mt={2}>
                    <b>Fecha Inicio del Reporte:</b> {datos.fecha_inr}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography mt={2}>
                    <b>Fecha Final del Reporte:</b> {datos.fecha_finr}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography mt={2}>
                    <b>Nombre del Centro de Salud:</b> {datos.nombre_centro}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography mt={2}>
                    <b>Parroquia:</b> {datos.parroquia}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography mt={2}>
                    <b>Municipio:</b> {datos.municipio}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography mt={2}>
                    <b>Estado:</b> {datos.estado}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography mt={2}>
                    <b>Comunidad:</b> {datos.parroquia}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 2, mb: 2 }}>
                <Chip label="TOTALIZACIÓN" />
              </Divider>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Typography mt={2}>
                    <b>Hombres de 0-17 años:</b> {datos.hombres17}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography mt={2}>
                    <b>Mujeres de 0-17 años:</b> {datos.mujeres17}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography mt={2}>
                    <b>Hombres de 18-59 años:</b> {datos.hombres59}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography mt={2}>
                    <b>Mujeres de 18-59 años:</b> {datos.mujeres59}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography mt={2}>
                    <b>Hombres de 60 años o más:</b> {datos.hombres60}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography mt={2}>
                    <b>Mujeres de 60 años o más:</b> {datos.mujeres60}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography mt={4} variant="h4">
                    <b>Total de Capacitados: </b> {datos.total}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 2, mb: 2 }}>
                <Chip label="ADICIONALES" />
              </Divider>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Typography mt={2}>
                    <b>Personas LGBTQI+:</b> {datos.total_lgbtqi}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography mt={2}>
                    <b>Equipo:</b> {datos.equipo}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 3, mb: 2 }} />
              <Grid
                container
                sx={{ mt: 2 }}
                spacing={1}
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleCloseCap}
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </>
      ) : (
        <Restricted />
      )}
    </Box>
  );
};
export default ListaTranscripcion;
