import React, { useMemo, useEffect } from "react";
import { ResponsivePie } from "@nivo/pie";
import { useGetSalesQuery,useGetTotalQuery,useGetNinosQuery,useGetTotalesQuery,useGetNinasQuery,useGetMelQuery } from "state/api";
import { useTheme } from "@mui/material";
const PieGraph = (dato) => {
  const [ejecutar, setEjecutar] = React.useState(true);
  const [datos, setDatos] = React.useState([]);
  var fechaInicio=dato.fecha;
  var categoria=dato.categoria
  const [fechaFin, setFechaFin] = React.useState('');
  const [tabla, setTabla] = React.useState('niñoscinco');
  const [endpoint, setEnpoint] = React.useState('meses');
  const [data1, setData1] = React.useState([]);
  const [data2, setData2] = React.useState([]);
  const [data3, setData3] = React.useState([]);
  useEffect(() => {
    
    if(fechaInicio =='2022-01-01'){
      let datos2 = [
        {
          id: "Gestante",
          label: "Gestante",
          value: 0,
          
        },
        {
          id: "No Gestante",
          label: "No Gestante",
          value: 0,
         
        },
       
      ];
      let datos4 = [
        {
          id: "Gestante",
          label: "Gestante",
          value: 0,
          
        },
        {
          id: "No Gestante",
          label: "No Gestante",
          value: 0,
         
        },
       
      ];
      let datos1 = [
        {
          id: "Masculino",
          label: "Masculino",
          value: 119,
          
        },
        {
          id: "Femenino",
          label: "Femenino",
          value: 113,
         
        },
       
      ];
      setData1(datos1)
      setData2(datos2)
      setData3(datos4)
      setFechaFin('2022-12-31')
      setEjecutar(true);
    } else{
      if(fechaInicio =='2023-01-01'){
        let datos6 = [
          {
            id: "Masculino",
            label: "Masculino",
            value: 11485,
            
          },
          {
            id: "Femenino",
            label: "Femenino",
            value: 11213,
           
          },
         
        ];
        let datos3 = [
          {
            id: "Gestante",
            label: "Gestante",
            value: 2415,
            
          },
          {
            id: "No Gestante",
            label: "No Gestante",
            value: 2228,
           
          },
         
        ];
        let datos5 = [
          {
            id: "Niños",
            label: "Niños",
            value: 13558,
            
          },
          {
            id: "Niñas",
            label: "Niñas",
            value: 15010,
           
          },
         
        ];
        setData1(datos6)
        setData2(datos3)
        setData3(datos5)
        setFechaFin('2023-12-31')
        setEjecutar(true);
      }else{
        let datos6 = [
          {
            id: "Masculino",
            label: "Masculino",
            value: 5939,
            
          },
          {
            id: "Femenino",
            label: "Femenino",
            value: 5873,
           
          },
         
        ];
        let datos3 = [
          {
            id: "Gestante",
            label: "Gestante",
            value: 1021,
            
          },
          {
            id: "No Gestante",
            label: "No Gestante",
            value: 911,
           
          },
         
        ];
        let datos5 = [
          {
            id: "Niños",
            label: "Niños",
            value: 846,
            
          },
          {
            id: "Niñas",
            label: "Niñas",
            value: 940,
           
          },
         
        ];
        setData1(datos6)
        setData2(datos3)
        setData3(datos5)
        setFechaFin('2024-12-31')
        setEjecutar(true);
      }
     
    }

    if(categoria == "Menores de 5 Años"){
      setTabla('niñoscinco')
      setEnpoint('meses')
    }
    if(categoria == "MEL"){
      setTabla('mel')
      setEnpoint('meses')
    }
    if(categoria == "Desparasitaciones"){
      setTabla('desparasitaciones')
      setEnpoint('mesesDes')
    }
  }, [fechaInicio,categoria]);
  const { data, isLoading } =useGetTotalesQuery();
  
 
   //Theme
   const theme = useTheme();
  if (!data 
    ) return "Cargando...";

const dataTotales = Object.entries(data).map(
([category, sales], i) => ({
  id: category,
  label: category,
  value: sales
})
);
  return (
    <ResponsivePie
      data={categoria === 'Menores' ? data1: categoria === 'MEL'? data2:data3}
      margin={{ top: 30, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.6}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      colors={{ scheme: "set1" }}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "ruby",
          },
          id: "dots",
        },
        {
          match: {
            id: "c",
          },
          id: "dots",
        },
        {
          match: {
            id: "go",
          },
          id: "dots",
        },
        {
          match: {
            id: "python",
          },
          id: "dots",
        },
        {
          match: {
            id: "scala",
          },
          id: "lines",
        },
        {
          match: {
            id: "lisp",
          },
          id: "lines",
        },
        {
          match: {
            id: "elixir",
          },
          id: "lines",
        },
        {
          match: {
            id: "javascript",
          },
          id: "lines",
        },
      ]}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: 56,
          itemsSpacing: 0,
          itemWidth: 80,
          itemHeight: 18,
          itemTextColor: "#999",
          itemDirection: "top-to-bottom",
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />
  );
};

export default PieGraph;
