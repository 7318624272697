import React, { useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { useTheme, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CasesRoundedIcon from "@mui/icons-material/CasesRounded";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import SettingsApplicationsRoundedIcon from "@mui/icons-material/SettingsApplicationsRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import LeaderboardRoundedIcon from "@mui/icons-material/LeaderboardRounded";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import HomeIcon from "@mui/icons-material/Home";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import DeleteIcon from '@mui/icons-material/Delete';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Restricted from "components/Restricted";
import { useGetProyectoQuery } from "state/api";
import clienteAxios from "../../config/clienteAxios";
import axios from "axios";

export default function Proyectos() {
  const [items, setItems] = React.useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const rol = items.rol;

  //let proyectos = useGetProyectoQuery();
  const nav = useNavigate();
  const [data, setData] = React.useState([]);
  const [ejecutar, setEjecutar] = React.useState(true);

  //Theme
  const theme = useTheme();

  //BreadCrumbs
  function handleClickBreadCrumbs(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  //DropDown Button
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropDown = Boolean(anchorEl);
  const handleDropDown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDropDown = () => {
    setAnchorEl(null);
  };

  //Routes
  const handleLinkClick = (event, message) => {
    if (message === "nuevo") {
      nav("/crearProyecto");
    }
  };

  const handleLinkAdm = (event, message) => {
    if (message === "adm") {
      nav("/adminEquipos");
    }
  };

  const handleLinkGest = (event, message) => {
    if (message === "gest") {
      nav("/gestionProyectos");
    }
  };

  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };

  const handleLinkAct = (event, message) => {
    if (message === "act") {
      nav("/actividades");
    }
  };

  const handleLinkStats = (event, message) => {
    if (message === "stats") {
      nav("/estadisticasGenerales");
    }
  };

  const handleLinkEditar = (event, message, row) => {
    let pro = JSON.stringify(row.original);
    if (message === "editar") {
      nav(`/editarProyecto?prop=${pro}`);
    }
  };
  const handleEstado = (event, message, row) => {
let estado=1;
    let idproyecto =row.original.idproyectos
    if (message === "finalizar") {
      estado=2;
      cambiarEstado(idproyecto,estado);
    }else{
      if(message === "desactivar") {
estado=3;
cambiarEstado(idproyecto,estado);
      }else{
        estado=4;
        cambiarEstado(idproyecto,estado);
      }
    }
  };

  const handleLinkEst = (event, message, row) => {
    let pro = JSON.stringify(row.original);
    if (message === "est") {
      nav(`/estadisticas?prop=${pro}`);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "codigo",
        header: "Código",
      },
      {
        accessorKey: "nombre",
        header: "Nombre",
      },
      {
        accessorKey: "descripcion",
        header: "Descripcion",
      },
      {
        accessorKey: "estados",
        header: "Estado",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={{
              backgroundColor:
                cell.getValue() === "Activo" ? "green" : "firebrick",
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "12ch",
              p: "0.25rem",
            }}
          >
            {cell.getValue()}
          </Box>
        ),
      },
    ],
    []
  );

  const proyectos = async () => {
    try {
      const { data1 } = await axios
        .get("/proyectos")
        .then(function (response) {
          // setAlerta({})

          setData(response.data);
          setEjecutar(false);
          console.log(data);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };



  const cambiarEstado = async (id,estado) => {
    
    try {
      const { data1 } = await axios
        .put("/actualizarproyectos", {
          id: id,
          estado:estado
        })
        .then(function (response) {
       
        })
        .catch(function (error) {
       
        });

      
    } catch (error) {}

    //handleOpenAlert();
  };

  if (ejecutar) {
    proyectos();
  }

  if (!data)
    return (
      <Container component="main" maxWidth="xs">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  return (
    <Box m="1.5rem 2.5rem">
      {rol === "1" ||
      rol === "2" ||
      rol === "6" ||
      rol === "7" ||
      rol === "10" ? (
        <>
          <Box
            role="presentation"
            onClick={handleClickBreadCrumbs}
            sx={{ mb: 3 }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/inicio"
                onClick={(event) => handleLinkHome(event, "home")}
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Inicio
              </Link>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <CasesRoundedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Proyectos
              </Typography>
            </Breadcrumbs>
            <Typography variant="h3" sx={{ fontWeight: "bold", mt: 2 }}>
              Proyectos
            </Typography>
            <Box
              mt="10px"
              display="grid"
              gridTemplateColumns="repeat(8, 1fr)"
              gridAutoRows="160px"
              gap="20px"
              sx={{
                "& > div": {
                  gridColumn: isNonMediumScreens ? undefined : "span 12",
                },
              }}
            >
              <Box
                gridColumn="span 2"
                gridRow="span 3"
                p="1.5rem"
                borderRadius="1.5rem"
                mt="10px"
                height="fit-content"
              >
                <Grid container>
                  <Grid item xs={12} mt={2}>
                    <Card
                      className="text-center zoom"
                      sx={{
                        borderRadius: 6,
                        boxShadow: 8,
                        bgcolor: theme.palette.background.alt,
                      }}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid item xs={6}>
                            <Avatar
                              sx={{
                                width: 80,
                                height: 80,
                                bgcolor: "teal",
                                mt: 2,
                                mb: 2,
                                ml: 1,
                              }}
                            >
                              <PeopleAltRoundedIcon
                                sx={{ fontSize: 50, color: "white" }}
                              />
                            </Avatar>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              sx={{ fontSize: 16, fontWeight: "bold", mt: 2 }}
                              gutterBottom
                            >
                              Administrar Equipos
                            </Typography>
                            <Button
                              variant="contained"
                              size="lg"
                              sx={{
                                bgcolor: "teal",
                                color: "white",
                                borderRadius: 2,
                                mt: 1,
                              }}
                              fullWidth
                              onClick={(event) => handleLinkAdm(event, "adm")}
                            >
                              Iniciar
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <Card
                      className="text-center zoom"
                      sx={{
                        borderRadius: 6,
                        boxShadow: 8,
                        bgcolor: theme.palette.background.alt,
                      }}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid item xs={6}>
                            <Avatar
                              sx={{
                                width: 80,
                                height: 80,
                                bgcolor: "teal",
                                mt: 2,
                                mb: 2,
                                ml: 1,
                              }}
                            >
                              <SettingsApplicationsRoundedIcon
                                sx={{ fontSize: 50, color: "white" }}
                              />
                            </Avatar>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              sx={{ fontSize: 16, fontWeight: "bold", mt: 2 }}
                              gutterBottom
                            >
                              Gestión de Proyectos
                            </Typography>
                            <Button
                              variant="contained"
                              size="lg"
                              sx={{
                                bgcolor: "teal",
                                color: "white",
                                borderRadius: 2,
                                mt: 1,
                              }}
                              fullWidth
                              onClick={(event) => handleLinkGest(event, "gest")}
                            >
                              Iniciar
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} mt={2}>
                    <Card
                      className="text-center zoom"
                      sx={{
                        borderRadius: 6,
                        boxShadow: 8,
                        bgcolor: theme.palette.background.alt,
                      }}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid item xs={6}>
                            <Avatar
                              sx={{
                                width: 80,
                                height: 80,
                                bgcolor: "teal",
                                mt: 2,
                                mb: 2,
                                ml: 1,
                              }}
                            >
                              <LeaderboardRoundedIcon
                                sx={{ fontSize: 50, color: "white" }}
                              />
                            </Avatar>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              sx={{ fontSize: 16, fontWeight: "bold", mt: 3 }}
                              gutterBottom
                            >
                              Estadísticas Generales
                            </Typography>
                            <Button
                              variant="contained"
                              size="lg"
                              sx={{
                                bgcolor: "teal",
                                color: "white",
                                borderRadius: 2,
                                mt: 1,
                              }}
                              fullWidth
                              onClick={(event) =>
                                handleLinkStats(event, "stats")
                              }
                            >
                              Iniciar
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>

              <Box
                gridColumn="span 6"
                gridRow="span 3"
                backgroundColor={theme.palette.background.alt}
                p="1.5rem"
                borderRadius="1.5rem"
                mt="40px"
                height="fit-content"
                sx={{ boxShadow: 4 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h5" mt={1} sx={{ fontWeight: "bold" }}>
                      Listado de Proyectos
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box
                      container
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{ bgcolor: "teal", color: "white" }}
                        size="small"
                        onClick={(event) => handleLinkClick(event, "nuevo")}
                        style={{
                          display:
                            items.rol === "1"
                              ? "block"
                              : items.rol === "2"
                              ? "block"
                              : "none",
                        }}
                      >
                        <CreateNewFolderIcon sx={{ mr: 1 }} /> Nuevo Proyecto
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <MaterialReactTable
                  columns={columns}
                  data={data}
                  localization={MRT_Localization_ES}
                  enableRowActions
                  positionActionsColumn="first"
                  renderRowActionMenuItems={({ row }) => [
                    <MenuItem
                      onClick={(event) =>
                        handleLinkEditar(event, "editar", row)
                      }
                    >
                      <EditIcon sx={{ mr: 1 }} /> <b>Editar Proyecto</b>
                    </MenuItem>,
                    <MenuItem
                      onClick={(event) => handleLinkEst(event, "est", row)}
                    >
                      <LeaderboardRoundedIcon sx={{ mr: 1 }} />
                      <b>Estadísticas</b>
                    </MenuItem>,
                    <MenuItem
                      onClick={(event) =>handleEstado(event, "finalizar", row)}
                      sx={{ fontWeight: "bold" }}
                    >
                      <CheckCircleOutlineOutlinedIcon sx={{ mr: 1 }} />{" "}
                      Finalizar Proyecto
                    </MenuItem>,
                    <MenuItem
                      onClick={(event) =>handleEstado(event, "desactivar", row)}
                      sx={{ fontWeight: "bold" }}
                    >
                      <BlockOutlinedIcon sx={{ mr: 1 }} /> Desactivar Proyecto
                    </MenuItem>,
                     <MenuItem
                     onClick={(event) =>handleEstado(event, "eliminar", row)}
                     sx={{ color: "#FF6347", fontWeight: "bold" }}
                   >
                     <DeleteIcon sx={{ mr: 1 }} /> Eliminar Proyecto
                   </MenuItem>
                  ]}
                  muiTopToolbarProps={{
                    sx: {
                      backgroundColor: theme.palette.background.alt,
                    },
                  }}
                  muiTableHeadCellProps={{
                    align: "center",
                    sx: {
                      backgroundColor: theme.palette.background.alt,
                    },
                  }}
                  muiBottomToolbarProps={{
                    sx: {
                      backgroundColor: theme.palette.background.alt,
                    },
                  }}
                  muiTableBodyProps={{
                    sx: {
                      "& tr:nth-of-type(odd)": {
                        backgroundColor: theme.palette.background.light,
                      },
                    },
                  }}
                  muiTableBodyCellProps={{
                    align: "center",
                  }}
                  muiTablePaperProps={{
                    elevation: 0,
                    sx: {
                      backgroundColor: theme.palette.background.alt,
                    },
                  }}
                  initialState={{
                    pagination: {
                      pageSize: 5,
                      pageIndex: 0,
                    },
                  }}
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                    showFirstButton: false,
                    showLastButton: false,
                    SelectProps: {
                      native: true,
                    },
                    labelRowsPerPage: "Número de filas visibles",
                  }}
                  renderDetailPanel={({ row }) => (
                    <Box
                      sx={{
                        display: "grid",
                        margin: "auto",
                        gridTemplateColumns: "1fr 1fr",
                        width: "100%",
                      }}
                    >
                      <Typography>
                        <strong>Fecha de Inicio: </strong>
                        {row.original.fecha_inicio}
                      </Typography>
                      <Typography>
                        <strong>Fecha de Finalización: </strong>
                        {row.original.fecha_fin}
                      </Typography>
                      <Typography>
                        <strong>Socio: </strong> {row.original.nombresocios}
                      </Typography>
                    </Box>
                  )}
                />
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Restricted />
      )}
    </Box>
  );
}
