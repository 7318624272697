import React, { useMemo } from "react";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import BlockIcon from "@mui/icons-material/Block";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const VoluntList = (datos) => {
  console.log(datos);
  console.log(datos.datos.aceptada);

  //Theme
  const theme = useTheme();

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //Tabs Handler
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "nombres",
        header: "Nombre",
      },
      {
        accessorKey: "apellidos",
        header: "Apellido",
      },
      {
        accessorKey: "descripcion",
        header: "Ocupacion",
      },
    ],
    []
  );

  if (!datos) return "Loading...";
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant={isSmallScreen ? "scrollable" : "fullWidth"}
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab
            icon={<CheckCircleIcon />}
            iconPosition="start"
            label="Asist. Confirmada"
            {...a11yProps(0)}
          />
          <Tab
            icon={<CancelIcon />}
            iconPosition="start"
            label="Asist. Cancelada"
            {...a11yProps(1)}
          />
          <Tab
            icon={<WatchLaterIcon />}
            iconPosition="start"
            label="En espera"
            {...a11yProps(2)}
          />
          <Tab
            icon={<BlockIcon />}
            iconPosition="start"
            label="Asist. Rechazada"
            {...a11yProps(3)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <MaterialReactTable
          columns={columns}
          data={datos.datos.aceptada}
          localization={MRT_Localization_ES}
          enableFullScreenToggle={false}
          muiTopToolbarProps={{
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiTableHeadCellProps={{
            align: "center",
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiBottomToolbarProps={{
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiTableBodyProps={{
            sx: {
              "& tr:nth-of-type(odd)": {
                backgroundColor: theme.palette.background.light,
              },
            },
          }}
          muiTableBodyCellProps={{
            align: "center",
          }}
          muiTablePaperProps={{
            elevation: 0, //change the mui box shadow
            //customize paper styles
          }}
          initialState={{
            pagination: {
              pageSize: 3,
              pageIndex: 0,
            },
          }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
            showFirstButton: false,
            showLastButton: false,
            SelectProps: {
              native: true,
            },
            labelRowsPerPage: "Número de filas visibles",
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MaterialReactTable
          columns={columns}
          data={datos.datos.cancelada}
          localization={MRT_Localization_ES}
          enableFullScreenToggle={false}
          muiTopToolbarProps={{
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiTableHeadCellProps={{
            align: "center",
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiBottomToolbarProps={{
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiTableBodyProps={{
            sx: {
              "& tr:nth-of-type(odd)": {
                backgroundColor: theme.palette.background.light,
              },
            },
          }}
          muiTableBodyCellProps={{
            align: "center",
          }}
          muiTablePaperProps={{
            elevation: 0, //change the mui box shadow
            //customize paper styles
          }}
          initialState={{
            pagination: {
              pageSize: 3,
              pageIndex: 0,
            },
          }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
            showFirstButton: false,
            showLastButton: false,
            SelectProps: {
              native: true,
            },
            labelRowsPerPage: "Número de filas visibles",
          }}
          renderDetailPanel={({ row }) => (
            <Box
              sx={{
                display: "grid",
                margin: "auto",
                gridTemplateColumns: "1fr 1fr",
                width: "100%",
              }}
            >
              <Typography>
                Motivo de Cancelación: {row.original.motivo_falta}
              </Typography>
            </Box>
          )}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MaterialReactTable
          columns={columns}
          data={datos.datos.espera}
          localization={MRT_Localization_ES}
          enableFullScreenToggle={false}
          muiTopToolbarProps={{
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiTableHeadCellProps={{
            align: "center",
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiBottomToolbarProps={{
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiTableBodyProps={{
            sx: {
              "& tr:nth-of-type(odd)": {
                backgroundColor: theme.palette.background.light,
              },
            },
          }}
          muiTableBodyCellProps={{
            align: "center",
          }}
          muiTablePaperProps={{
            elevation: 0, //change the mui box shadow
            //customize paper styles
          }}
          initialState={{
            pagination: {
              pageSize: 3,
              pageIndex: 0,
            },
          }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
            showFirstButton: false,
            showLastButton: false,
            SelectProps: {
              native: true,
            },
            labelRowsPerPage: "Número de filas visibles",
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <MaterialReactTable
          columns={columns}
          data={datos.datos.rechazada}
          localization={MRT_Localization_ES}
          enableFullScreenToggle={false}
          muiTopToolbarProps={{
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiTableHeadCellProps={{
            align: "center",
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiBottomToolbarProps={{
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiTableBodyProps={{
            sx: {
              "& tr:nth-of-type(odd)": {
                backgroundColor: theme.palette.background.light,
              },
            },
          }}
          muiTableBodyCellProps={{
            align: "center",
          }}
          muiTablePaperProps={{
            elevation: 0, //change the mui box shadow
            //customize paper styles
          }}
          initialState={{
            pagination: {
              pageSize: 3,
              pageIndex: 0,
            },
          }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
            showFirstButton: false,
            showLastButton: false,
            SelectProps: {
              native: true,
            },
            labelRowsPerPage: "Número de filas visibles",
          }}
        />
      </TabPanel>
    </Box>
  );
};

export default VoluntList;
