import React, { useMemo, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Container from "@mui/material/Container";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import InventoryOutlined from "@mui/icons-material/InventoryOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PendingIcon from "@mui/icons-material/Pending";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import Restricted from "components/Restricted";
import { downloadExcel } from "react-export-table-to-excel";
import clienteAxios from "../../config/clienteAxios";
import axios from "axios";

const Postulaciones = () => {
  const nav = useNavigate();
  /*const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token =params.get("prop");
  const proyecto = JSON.parse(token) 
  console.log(proyecto)
  const [data, setData]= React.useState([]);
  
  const [tabla, setTabla] = React.useState(false);
  const [datos, setDatos] = React.useState({});
  const [solicitud, setSolicitud]= React.useState([]);*/
  const [postuladosAprobados, setPostuladosAprobados] = useState([]);
  const [postuladosPendientes, setPostuladosPendientes] = useState([]);
  const [postuladosRechazados, setPostuladosRechazados] = useState([]);
  const [ejecutar, setEjecutar] = React.useState(true);
  const [items, setItems] = React.useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const rol = items.rol;

  //Theme
  const theme = useTheme();

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //Modal Styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "90%" : "30%",
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 5,
    p: 4,
  };

  function handleClickBreadCrumbs(event) {
    event.preventDefault();
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const styleVol = {
    position: "inherit",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "90%" : "50%",
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    borderRadius: 4,
    boxShadow: 1,
    p: 4,
  };

  //Modal Handlers
  const [openInfo, setOpenInfo] = React.useState(false);
  const handleOpenInfo = () => setOpenInfo(true);
  const handleCloseInfo = () =>{
    setOpenInfo(false)
    setShowMore(false)
    setValuePostTabs(0)
  } ;

  const [openInfo2, setOpenInfo2] = React.useState(false);
  const handleOpenInfo2 = () => setOpenInfo2(true);
  const handleCloseInfo2 = () =>{
    setOpenInfo2(false)
    setShowMore(false)
    setValuePostTabs(0)
  } ;

  const [datosPostulado, setDatosPostulado] = React.useState(false);
  const [motivoRechazo, setMotivoRechazo] = React.useState("");
  

  const [openReject, setOpenReject] = React.useState(false);
  const handleOpenReject = () => setOpenReject(true);
  const handleCloseReject = () => setOpenReject(false);

  const [openAlert, setOpenAlert] = React.useState(false);
  const handleOpenAlert = () => {
    setOpenAlert(true);
    handleCloseAlert();
  };
  const handleCloseAlert = () =>
    setTimeout(() => {
      setOpenAlert(false);
    }, 2000);
    const handleMotivoRechazo = (event) => {
      setMotivoRechazo(event.target.value);
    };

  //Tabs Handlers
  const [valueTabs, setValueTabs] = React.useState(0);
  const handleChangeTabs = (event, newValueTabs) => {
    setValueTabs(newValueTabs);
  };

  const [valuePostTabs, setValuePostTabs] = React.useState(0);
  const handleChangePostTabs = (event, newValuePostTabs) => {
    setValuePostTabs(newValuePostTabs);
  };

  //Alerts Handlers
  const [openNoti, setOpenNoti] = React.useState(false);
  const [openNotiError, setOpenNotiError] = React.useState(false);

   // Exportar Tabla
   function handleDownloadPostPend(rows) {
    let tableData = rows.map((row) => Object.values(row.original));
    for (let index = 0; index < tableData.length; index++) {
      tableData[index] = tableData[index].splice(-14, 14);
    }
    for (let j = 0; j < tableData.length; j++) {
      tableData[j].splice(tableData[j].length -6, 5);
    }
    const tableHeaders = myConvos2.map((c) => c.header);
    downloadExcel({
      fileName: `Postulados Pendientes ${new Date().toLocaleDateString('en-GB')}`,
      sheet: "Postulados Pendientes",
      tablePayload: {
        header: tableHeaders,
        body: tableData,
      },
    });
  }

  //Routes
  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };

  const handleLinkGH = (event, message) => {
    if (message === "gestionHumana") {
      nav("/gestionhumana");
    }
  };

  const handleLinkRetro = (event, message) => {
    if (message === "retro") {
      nav("/crearRetroalimentacion");
    }
  };

  const myConvos = useMemo(
    () => [
      {
        accessorKey: "nombres",
        header: "Nombre",
      },
      {
        accessorKey: "apellidos",
        header: "Apellido",
      },
      {
        id:"fecha",
        accessorKey: "fecha_creacion",
        header: "Fecha de Postulación",
      },
      {
        accessorKey: "descripcion",
        header: "Ocupación",
      },
    ],
    []
  );

  const myConvos2 = useMemo(
    () => [
      {
        accessorKey: "nombres",
        header: "Nombre",
      },
      {
        accessorKey: "apellidos",
        header: "Apellido",
      },
      {
        accessorKey: "cedula",
        header: "Cédula de Identidad",
      },
      {
        accessorKey: "telefono",
        header: "Teléfono",
      },
      {
        accessorKey: "fecha_nacimiento",
        header: "Fecha de Nacimiento",
      },
      {
        accessorKey: "ocupacion",
        header: "Descripción",
      },
      {
        accessorKey: "direccion",
        header: "Dirección",
      },
      {
        accessorKey: "email",
        header: "Correo Electrónico",
      },
      /*{
        accessorKey: "motivo",
        header: "Motivo de Postulación",
      },
      {
        accessorKey: "observacion",
        header: "Motivo de Rechazo",
      },
      {
        accessorKey: "estado",
        header: "Estatus",
      },
      {
        accessorKey: "fecha_modificacion",
        header: "Fecha de Modificación",
      },
      {
        id:"fecha",
        accessorKey: "fecha_creacion",
        header: "Fecha de Postulación",
      },*/
      {
        accessorKey: "descripcion",
        header: "Ocupación",
      },
    ],
    []
  );

  const rejects = useMemo(
    () => [
      {
        accessorKey: "nombres",
        header: "Nombre",
      },
      {
        accessorKey: "apellidos",
        header: "Apellido",
      },
      {
        id:"fecha",
        accessorKey: "fecha_creacion",
        header: "Fecha de Postulación",
      },
      {
        accessorKey: "descripcion",
        header: "Ocupación",
      },
      {
        accessorKey: "observacion",
        header: "Motivo de Rechazo",
      },
    ],
    []
  );

  const getPostulacionesPendientes = async () => {
   
    try {
      const { data1 } = await axios
        .get(`/postulados/`, {
          params: {
            estado: 3,
            
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setPostuladosPendientes(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const getPostulacionesAceptados = async () => {
   
    try {
      const { data1 } = await axios
        .get(`/postulados/`, {
          params: {
            estado: 1,
            
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setPostuladosAprobados(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const getPostulacionesRechazados = async () => {
   
    try {
      const { data1 } = await axios
        .get(`/postulados/`, {
          params: {
            estado: 2,
            
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setPostuladosRechazados(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const handleRechazar = async () => {
   
    try {
      const { data1 } = await axios
        .put("/actualizarpostulacion", {
          id: datosPostulado.idPostulaciones,
          observacion: motivoRechazo,
          estado: 2,
          nombres: datosPostulado.nombres,
          apellidos: datosPostulado.apellidos,
          correo: datosPostulado.email,
      
        })
        .then(function (response) {
          console.log(response);
        setEjecutar(true)
        handleCloseReject();
        setMotivoRechazo("");
        setOpenNotiError(true);
        setTimeout(() => {
          setOpenNotiError(false);
        }, 2000);
        })
        /*.then(function (response) {
          nav("/");
       })*/
        .catch(function (error) {
     
          console.log("error");
      
        });

      console.log(data1);
    } catch (error) {}
  };
  const handleAceptar = async () => {
   
    try {
      const { data1 } = await axios
        .post("/aceptarpostulacion/", {
          id: datosPostulado.idPostulaciones,
          observacion: motivoRechazo,
          estado: 1,
          nombres: datosPostulado.nombres,
          apellidos: datosPostulado.apellidos,
          correo: datosPostulado.email,
          ocupacion: datosPostulado.ocupacion,
      
        })
        .then(function (response) {
          console.log(response);
        setEjecutar(true)
        handleCloseInfo();
        setMotivoRechazo("");
        setOpenNoti(true);
        setTimeout(() => {
          setOpenNoti(false);
        }, 2000);

        })
        /*.then(function (response) {
          nav("/");
       })*/
        .catch(function (error) {
     
          console.log(error);
      
        });

      console.log(data1);
    } catch (error) {}
  };

  if (ejecutar) {
  getPostulacionesAceptados();
  getPostulacionesRechazados();
    getPostulacionesPendientes();
  }
  const motivo =
    "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto, modi optio esse nostrum dicta fugiat porro deserunt voluptates dolore labore distinctio quam dolor ipsa molestias fuga facere dolorum dolorem! Alias.";
  const [showMore, setShowMore] = useState(false);

  /*if (!data ) return (
    <Container component="main" maxWidth="xs">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );*/

  return (
    <Box m="1.5rem 2.5rem">
      {rol === "1" || rol === "2" || rol === "7" ? (
        <>
        <Collapse in={openNoti}>
        <Alert
          variant="filled"
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenNoti(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ position:'fixed', right:12, zIndex:999, borderRadius:2, boxShadow:4 }}
        >
          ¡Postulación Aceptada!
        </Alert>
    </Collapse>
    <Collapse in={openNotiError}>
        <Alert
          variant="filled"
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenNotiError(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ position:'fixed', right:12, zIndex:999, borderRadius:2, boxShadow:4 }}
        >
          ¡Postulación Rechazada!
        </Alert>
    </Collapse>
          <Box
            role="presentation"
            onClick={handleClickBreadCrumbs}
            sx={{ mb: 3 }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/inicio"
                onClick={(event) => handleLinkHome(event, "home")}
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Inicio
              </Link>
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/gestionhumana"
                onClick={(event) => handleLinkGH(event, "gestionHumana")}
              >
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  <InventoryOutlined sx={{ mr: 0.5 }} fontSize="inherit" />
                  Gestión Humana
                </Typography>
              </Link>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <CalendarMonthOutlinedIcon
                  sx={{ mr: 0.5 }}
                  fontSize="inherit"
                />
                Postulaciones
              </Typography>
            </Breadcrumbs>
            <Typography variant="h4" sx={{ mt: 2 }}>
              <b>Postulaciones</b>
            </Typography>
          </Box>
          <Container>
            <Box
              gridColumn="span 8"
              gridRow="span 3"
              backgroundColor={theme.palette.background.alt}
              p="1.5rem"
              borderRadius="1.5rem"
              mt="40px"
              height="fit-content"
              sx={{ boxShadow: 4 }}
            >
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={valueTabs}
                    variant="fullWidth"
                    onChange={handleChangeTabs}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      icon={<PendingIcon />}
                      iconPosition="start"
                      label="Pendientes"
                      {...a11yProps(0)}
                    />
                    <Tab
                      icon={<CheckCircleIcon />}
                      iconPosition="start"
                      label="Aceptadas"
                      {...a11yProps(1)}
                    />
                    <Tab
                      icon={<CancelIcon />}
                      iconPosition="start"
                      label="Rechazadas"
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel value={valueTabs} index={0}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        Postulaciones Pendientes
                      </Typography>
                    </Grid>
                  </Grid>
                  <MaterialReactTable
                    columns={myConvos}
                    data={postuladosPendientes}
                    localization={MRT_Localization_ES}
                    enableRowActions
                    //enableRowSelection
                    positionActionsColumn="first"
                    renderTopToolbarCustomActions={({ table }) => (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "16px",
                          padding: "8px",
                          flexWrap: "wrap",
                        }}
                      >
                        <Button
                          color="info"
                          disabled={
                            table.getPrePaginationRowModel().rows.length ===
                            0
                          }
                          //export all rows, including from the next page, (still respects filtering and sorting)
                          onClick={() =>
                            handleDownloadPostPend(table.getPrePaginationRowModel().rows)
                          }
                          variant="contained"
                        >
                          <FileDownloadIcon sx={{ mr: 1 }} />
                          Exportar
                        </Button>
                      </Box>
                    )}
                    /*renderTopToolbarCustomActions={({ table }) => {
                      const handleAceptarBtn = (event) => {
                        table.getSelectedRowModel().flatRows.map((row) => {
                          //alert('Agregado: ' + row.getValue('nombres'));
                          handleAceptar();
                        });
                      };

                      return (
                        <div style={{ display: "flex", gap: "0.5rem" }}>
                          <Button
                          color="info"
                          disabled={
                            table.getPrePaginationRowModel().rows.length ===
                            0
                          }
                          //export all rows, including from the next page, (still respects filtering and sorting)
                          onClick={() =>
                            handleDownloadPostPend(table.getPrePaginationRowModel().rows)
                          }
                          variant="contained"
                        >
                          <FileDownloadIcon sx={{ mr: 1 }} />
                          Exportar
                        </Button>
                          <Button
                            color="success"
                            hidden={!table.getIsSomeRowsSelected() ? !table.getIsAllRowsSelected() : table.getIsAllRowsSelected() }
                            variant="contained"
                            onClick={handleAceptarBtn}
                          >
                            <ThumbUpIcon sx={{ mr: 1 }} /> Aceptar
                          </Button>
                        </div>
                      );
                    }}*/
                    displayColumnDefOptions={{
                      "mrt-row-actions": {
                        header: "Opciones",
                        size: 10,
                      }, 
                    }}
                    renderRowActionMenuItems={({ closeMenu, row }) => [
                      <MenuItem
                        onClick={(event) => {
                          handleOpenInfo();
                          closeMenu();
                          setDatosPostulado(row.original)
                        }}
                        sx={{ fontWeight: "bold" }}
                      >
                        <AssignmentOutlinedIcon sx={{ mr: 1 }} /> Ver
                        Postulación
                      </MenuItem>,
                    ]}
                    muiTopToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableHeadCellProps={{
                      align: "center",
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiBottomToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableBodyProps={{
                      sx: {
                        "& tr:nth-of-type(odd)": {
                          backgroundColor: theme.palette.background.light,
                        },
                      },
                    }}
                    muiTableBodyCellProps={{
                      align: "center",
                    }}
                    muiTablePaperProps={{
                      elevation: 0, //change the mui box shadow
                      //customize paper styles
                    }}
                    initialState={{
                      pagination: {
                        pageSize: 20,
                        pageIndex: 0,
                      },
                      sorting: [
                        {
                          id: "fecha",
                          desc: true,
                        },
                      ],
                    }}
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                      showFirstButton: false,
                      showLastButton: false,
                      SelectProps: {
                        native: true,
                      },
                      labelRowsPerPage: "Número de filas visibles",
                    }}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={valueTabs} index={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        Postulaciones Aceptadas
                      </Typography>
                    </Grid>
                    {/*<Grid item xs={12} sm={4}>
                      <Box
                        container
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <Button
                          variant="contained"
                          sx={{ bgcolor: "teal", color: "white" }}
                          size="small"
                        >
                          <ListAltOutlinedIcon sx={{ mr: 1 }} /> Generar Listado
                        </Button>
                      </Box>
                      </Grid>*/}
                  </Grid>
                  <MaterialReactTable
                    columns={myConvos}
                    data={postuladosAprobados}
                    localization={MRT_Localization_ES}
                    enableRowActions
                    positionActionsColumn="first"
                    displayColumnDefOptions={{
                      "mrt-row-actions": {
                        header: "Opciones",
                        size: 10,
                      },
                    }}
                    renderRowActionMenuItems={({ closeMenu, row }) => [
                      <MenuItem
                        onClick={(event) => {
                          handleOpenInfo2();
                          closeMenu();
                          setDatosPostulado(row.original)
                        }}
                        sx={{ fontWeight: "bold" }}
                      >
                        <DescriptionOutlinedIcon sx={{ mr: 1 }} /> Ver
                        Postulación
                      </MenuItem>,
                    ]}
                    muiTopToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableHeadCellProps={{
                      align: "center",
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiBottomToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableBodyProps={{
                      sx: {
                        "& tr:nth-of-type(odd)": {
                          backgroundColor: theme.palette.background.light,
                        },
                      },
                    }}
                    muiTableBodyCellProps={{
                      align: "center",
                    }}
                    muiTablePaperProps={{
                      elevation: 0, //change the mui box shadow
                      //customize paper styles
                    }}
                    initialState={{
                      pagination: {
                        pageSize: 20,
                        pageIndex: 0,
                      },
                      sorting: [
                        {
                          id: "fecha",
                          desc: true,
                        },
                      ],
                    }}
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                      showFirstButton: false,
                      showLastButton: false,
                      SelectProps: {
                        native: true,
                      },
                      labelRowsPerPage: "Número de filas visibles",
                    }}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={valueTabs} index={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        Postulaciones Rechazadas
                      </Typography>
                    </Grid>
                  </Grid>
                  <MaterialReactTable
                    columns={rejects}
                    data={postuladosRechazados}
                    localization={MRT_Localization_ES}
                    enableRowActions
                    positionActionsColumn="first"
                    displayColumnDefOptions={{
                      "mrt-row-actions": {
                        header: "Opciones",
                        size: 10,
                      },
                    }}
                    renderRowActionMenuItems={({ closeMenu, row }) => [
                      <MenuItem
                        onClick={(event) => {
                          handleOpenInfo2();
                          closeMenu();
                          setDatosPostulado(row.original)
                        }}
                        sx={{ fontWeight: "bold" }}
                      >
                        <DescriptionOutlinedIcon sx={{ mr: 1 }} /> Ver
                        Postulación
                      </MenuItem>,
                    ]}
                    muiTopToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableHeadCellProps={{
                      align: "center",
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiBottomToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableBodyProps={{
                      sx: {
                        "& tr:nth-of-type(odd)": {
                          backgroundColor: theme.palette.background.light,
                        },
                      },
                    }}
                    muiTableBodyCellProps={{
                      align: "center",
                    }}
                    muiTablePaperProps={{
                      elevation: 0, //change the mui box shadow
                      //customize paper styles
                    }}
                    initialState={{
                      pagination: {
                        pageSize: 20,
                        pageIndex: 0,
                      },
                      sorting: [
                        {
                          id: "fecha",
                          desc: true,
                        },
                      ],
                    }}
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                      showFirstButton: false,
                      showLastButton: false,
                      SelectProps: {
                        native: true,
                      },
                      labelRowsPerPage: "Número de filas visibles",
                    }}
                  />
                </CustomTabPanel>
              </Box>
            </Box>
          </Container>
          <Modal open={openInfo} onClose={handleCloseInfo}>
            <Box sx={style}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={valuePostTabs}
                    onChange={handleChangePostTabs}
                    variant={isSmallScreen ? "scrollable" : "fullWidth"}
                    scrollButtons
                    allowScrollButtonsMobile
                  >
                    <Tab
                      icon={<AccountBoxIcon />}
                      iconPosition="start"
                      label="Info. Personal"
                      {...a11yProps(0)}
                    />
                    <Tab
                      icon={<PsychologyAltIcon />}
                      iconPosition="start"
                      label="Motivo"
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel value={valuePostTabs} index={0}>
                  <Grid
                    container
                    mt={1}
                    mb={1}
                    spacing={2}
                    justifyContent="center"
                  >
                    <Grid item xs={12} sm={6}>
                      <b>Nombre: </b>{datosPostulado.nombres}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <b>Apellido: </b> {datosPostulado.apellidos}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <b>Cédula: </b> {datosPostulado.cedula}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <b>Teléfono: </b> {datosPostulado.telefono}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <b>Fecha de Nacimiento: </b> {datosPostulado.fecha_nacimiento}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <b>Ocupación: </b> {datosPostulado.descripcion}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <b>Correo Electrónico: </b>{datosPostulado.email}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <b>Dirección: </b>{datosPostulado.direccion}
                    </Grid>
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={valuePostTabs} index={1}>
                  {showMore ? datosPostulado===false? "":datosPostulado.motivo : datosPostulado===false? "":`${datosPostulado.motivo.substring(0, 100)}`}
                  <Button color="info" onClick={() => setShowMore(!showMore)}>
                    {showMore ? "Mostrar Menos" : "Mostrar Más"}
                  </Button>
                </CustomTabPanel>
              </Box>
              <Divider sx={{ mt: 3, mb: 2 }} />
              <Grid
                container
                sx={{ mt: 2 }}
                spacing={1}
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={(event) => {
                      handleCloseInfo();
                      handleOpenReject();
                    }}
                  >
                    Rechazar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={handleAceptar}
                    sx={{ bgcolor: "teal", color: "white" }}
                  >
                    Aceptar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          <Modal open={openInfo2} onClose={handleCloseInfo2}>
            <Box sx={style}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={valuePostTabs}
                    onChange={handleChangePostTabs}
                    variant={isSmallScreen ? "scrollable" : "fullWidth"}
                    scrollButtons
                    allowScrollButtonsMobile
                  >
                    <Tab
                      icon={<AccountBoxIcon />}
                      iconPosition="start"
                      label="Info. Personal"
                      {...a11yProps(0)}
                    />
                    <Tab
                      icon={<PsychologyAltIcon />}
                      iconPosition="start"
                      label="Motivo"
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel value={valuePostTabs} index={0}>
                  <Grid
                    container
                    mt={1}
                    mb={1}
                    spacing={2}
                    justifyContent="center"
                  >
                    <Grid item xs={12} sm={6}>
                      <b>Nombre: </b>{datosPostulado.nombres}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <b>Apellido: </b> {datosPostulado.apellidos}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <b>Cédula: </b> {datosPostulado.cedula}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <b>Teléfono: </b> {datosPostulado.telefono}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <b>Fecha de Nacimiento: </b> {datosPostulado.fecha_nacimiento}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <b>Ocupación: </b> {datosPostulado.descripcion}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <b>Correo Electrónico: </b>{datosPostulado.email}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <b>Dirección: </b>{datosPostulado.direccion}
                    </Grid>
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={valuePostTabs} index={1}>
                  {showMore ? datosPostulado===false? "":datosPostulado.motivo : datosPostulado===false? "":`${datosPostulado.motivo.substring(0, 100)}`}
                  <Button color="info" onClick={() => setShowMore(!showMore)}>
                    {showMore ? "Mostrar Menos" : "Mostrar Más"}
                  </Button>
                </CustomTabPanel>
              </Box>
              <Divider sx={{ mt: 3, mb: 2 }} />
              <Grid
                container
                sx={{ mt: 2 }}
                spacing={1}
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={(event) => {
                      handleCloseInfo2();
                    }}
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          <Modal open={openReject} onClose={handleCloseReject}>
            <Box sx={style}>
              <Typography variant="h4" mb={1}>
                Motivo de Rechazo
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Typography variant="h6" className="text-center" sx={{ mb: 1.5 }}>
                Especifica el Motivo de Rechazo
              </Typography>
              <form /*</Box>onSubmit={handleSubmit}*/>
                <TextField
                  name="mRechazo"
                  fullWidth
                  id="mRechazo"
                  label="Motivo de Rechazo"
                  value={motivoRechazo}
                  onChange={handleMotivoRechazo}
                  required
                />
              </form>
              <Divider sx={{ mt: 4 }} />
              <Grid
                container
                sx={{ mt: 2 }}
                spacing={1}
                justifyContent="flex-end"
              >
                <Grid item>
                <Button
                   variant="contained"
                   color="error"
                   onClick={(event) => {
                    handleCloseReject();
                   }}
                  >
                    Cerrar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{ bgcolor: "teal", color: "white" }}
                    onClick={handleRechazar}
                  >
                    Enviar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </>
      ) : (
        <Restricted />
      )}
    </Box>
  );
};

export default Postulaciones;
