import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { OutlinedInput, ListSubheader } from "@mui/material";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";
import { IMaskInput } from "react-imask";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import "dayjs/locale/es";
import logo from "assets/paluz-logo.png";
import clienteAxios from "../../config/clienteAxios";
import { useGetProcatQuery, useGetCategoriasQuery } from "state/api";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {" © "}
      {new Date().getFullYear()}
      {" - "}
      <Link color="inherit" href="https://paluz.org/">
        PALUZ
      </Link>{" "}
    </Typography>
  );
}

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#000) 000-0000"
      definitions={{
        "#": /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const theme = createTheme();

export default function Registro() {
  const [ejecutar, setEjecutar] = React.useState(true);
  const [data, setData] = useState([]);
  const [pro, setPro] = useState([]);

  const catPro = async () => {
    try {
      const { data2 } = await axios
        .get("/profesionescat")
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setData(response.data);
          setEjecutar(false);

          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const cat = async () => {
    try {
      const { data2 } = await axios
        .get("/categorias")
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setPro(response.data);
          setEjecutar(false);

          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let dataform = new FormData(event.currentTarget);
    console.log(value);

    try {
      const { data1 } = await axios
        .post("/perfil/voluntario/", {
          nombres: dataform.get("nombre"),
          apellidos: dataform.get("apellido"),
          cedula: dataform.get("cedula"),
          correo: dataform.get("email"),
          telefono: dataform.get("textmask"),
          fnacimiento: new Date(value).toLocaleDateString("es-ES"),
          ocupacion: dataform.get("ocupacion"),
          direccion: dataform.get("direccion"),
        })
        .then(function (response) {
          console.log(response);
          setAlerta({});
          event.target.reset();
          setOcup("");
          setAlertaF(false);
          setAlerta(true);
          setTimeout(function () {
            setAlerta(false);
            nav("/info");
          }, 2000);
          handleDateChange(null);
        })
        /*.then(function (response) {
          nav("/");
       })*/
        .catch(function (error) {
          event.preventDefault();
          console.log("error");
          // document.getElementById(":r7:").value='';
          setAlertaF(true);
          setTimeout(function () {
            setAlertaF(false);
          }, 3000);
          setAlerta(false);
        });

      console.log(data1);
    } catch (error) {}
  };

  const nav = useNavigate();

  const [alerta, setAlerta] = useState("");
  const [alertaF, setAlertaF] = useState("");
  const [ocup, setOcup] = React.useState("");
  const [date, setDate] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);

  // Loader
  const [openLoader, setOpenLoader] = React.useState(false);
  const handleCloseLoader = () => {
    setOpenLoader(false);
  };
  const handleOpenLoader = () => {
    setOpenLoader(true);
    /*setTimeout(function(){
      setOpenLoader(false);
  }, 3000);*/
  };

  const handleChange = (event) => {
    console.log("hola");
    setOcup(event.target.value);
  };

  const [values, setValues] = React.useState({
    textmask: "",
  });

  const [valueName, setValueName] = useState("");
  const [valueApellido, setValueApellido] = useState("");
  const [valueCedula, setValueCedula] = useState("");
  const [valueDireccion, setValueDireccion] = useState("");
  const [valueCorreo, setValueCorreo] = useState("");

  const oracionFormato = (string) => {
    return string
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  //DatePicker Value
  const [value, setValue] = React.useState(null);

  const handleChangeTel = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
  };
  const handleLinkClick = (event, message) => {
    if (message === "inicio") {
      nav("/");
    }
    console.log(event.currentTarget);
    console.log(message);
  };

  if (ejecutar) {
    catPro();
    cat();
  }
  if (!data || !pro)
    return (
      <Container component="main" maxWidth="xs">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );

  return (
    <div className="bg-img bg-div">
      <ThemeProvider theme={theme}>
        <div style={{ position: "relative", marginTop: 5, marginRight: 5 }}>
          {alerta ? (
            <Alert
              variant="filled"
              severity="success"
              sx={{
                position: "fixed",
                top: 5,
                right: 5,
                zIndex: 999,
                borderRadius: 2,
                boxShadow: 4,
              }}
            >
              ¡Usuario creado exitosamente!
            </Alert>
          ) : (
            ""
          )}
          {alertaF ? (
            <Alert
              variant="filled"
              severity="error"
              sx={{
                position: "fixed",
                top: 5,
                right: 5,
                zIndex: 999,
                borderRadius: 2,
                boxShadow: 4,
              }}
            >
              ¡No se pudo registrar el usuario!
            </Alert>
          ) : (
            ""
          )}
        </div>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              display="flex"
              component="img"
              alt="logo"
              src={logo}
              height="70px"
              width="240px"
              className="mb-3 img-responsive"
              maxWidth="sm"
            ></Box>
            <Typography component="h1" variant="h4">
              Regístrate
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="nombre"
                    required
                    fullWidth
                    id="nombre"
                    label="Nombre"
                    sx={{ bgcolor: "white" }}
                    value={valueName}
                    onChange={(event) =>
                      setValueName(oracionFormato(event.target.value))
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="apellido"
                    label="Apellido"
                    name="apellido"
                    sx={{ bgcolor: "white" }}
                    value={valueApellido}
                    onChange={(event) =>
                      setValueApellido(oracionFormato(event.target.value))
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    name="cedula"
                    required
                    fullWidth
                    id="cedula"
                    label="Cédula"
                    type="number"
                    sx={{ bgcolor: "white" }}
                    value={valueCedula}
                    onChange={(event) => setValueCedula(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel htmlFor="telefono" variant="outlined">
                      Teléfono
                    </InputLabel>
                    <OutlinedInput
                      value={values.textmask}
                      onChange={handleChangeTel}
                      name="textmask"
                      id="telefono"
                      inputComponent={TextMaskCustom}
                      label="Teléfono"
                      sx={{ bgcolor: "white" }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="es"
                  >
                    {/*<DateField label="Fecha de Nacimiento" name="fnac" format="DD-MM-YYYY" required fullWidth />*/}
                    <DesktopDatePicker
                      label="Fecha/Nacimiento"
                      name="fnac"
                      inputFormat="DD/MM/YYYY"
                      value={value}
                      onChange={setValue}
                      renderInput={(params) => {
                        return (
                          <TextField
                            fullWidth
                            sx={{ bgcolor: "white" }}
                            {...params}
                          />
                        );
                      }}
                      clearable
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel id="ocupación-label"> Ocupación </InputLabel>

                    <Select
                      id="ocupacion"
                      name="ocupacion"
                      value={ocup}
                      label="Ocupación"
                      onChange={handleChange}
                      sx={{ bgcolor: "white" }}
                    >
                      <MenuItem value="">
                        <em>Seleccione su Ocupación</em>
                      </MenuItem>

                      {/* {data.map(dato => {
                return <ListSubheader> {dato.nombre} </ListSubheader>
                 } )} */}

                      {data.map((dat, index) => (
                        <MenuItem value={dat.id_pro}>
                          {dat.descripcion}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="direccion"
                    name="direccion"
                    label="Dirección"
                    fullWidth
                    required
                    multiline
                    maxRows={4}
                    sx={{ bgcolor: "white" }}
                    value={valueDireccion}
                    onChange={(event) =>
                      setValueDireccion(oracionFormato(event.target.value))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Correo Electrónico"
                    name="email"
                    autoComplete="email"
                    sx={{ bgcolor: "white" }}
                    value={valueCorreo}
                    onChange={(event) => setValueCorreo(event.target.value)}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 4, mb: 2, bgcolor: "teal" }}
                disabled={
                  !valueName ||
                  !valueApellido ||
                  !valueCedula ||
                  !values.textmask ||
                  !value ||
                  !ocup ||
                  !valueDireccion ||
                  !valueCorreo
                }
              >
                Registrar Usuario
              </Button>
              <Grid container justifyContent="center">
                <Grid item>
                  <Link
                    variant="body2"
                    sx={{ cursor: "pointer" }}
                    onClick={(event) => handleLinkClick(event, "inicio")}
                  >
                    ¿Ya tienes cuenta? Inicia sesión
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 3, mb: 3 }} />
        </Container>
      </ThemeProvider>
    </div>
  );
}
