import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
//import { esES } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import "dayjs/locale/es";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import DeleteIcon from "@mui/icons-material/Delete";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import EditIcon from "@mui/icons-material/Edit";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Restricted from "components/Restricted";
import clienteAxios from "../../config/clienteAxios";
import {
  useGetProyectoQuery,
  useGetTipoActividadQuery,
  useGetEquiposQuery,
  useGetProfesionesQuery,
} from "state/api";
import { parseJSON, set } from "date-fns";
import axios from "axios";

const EditarActividad = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("prop");
  const convo = JSON.parse(token);
  console.log(convo,"prueba")
  let he = convo.fecha + "," + convo.horaEncuentro;
  let hs = convo.fecha + "," + convo.horaSalida;
  let hr = convo.fecha + "," + convo.horaRetorno;

  const de = new Date(he);
  const ds = new Date(hs);
  const dr = new Date(hr);
  var str3 = new Date(de).toString();
  str3 = str3.slice(16, -32);
  var str4 = new Date(ds).toString();
  str4 = str4.slice(16, -32);
  var str5 = new Date(dr).toString();
  str5 = str5.slice(16, -32);
  // let pro= useGetProyectoQuery();
  // pro=pro.data
  // console.log(convo.actividad);
  //  let actividad1= useGetTipoActividadQuery();
  // actividad1=actividad1.data
  //  console.log(actividad1);
  //  let equipos= useGetEquiposQuery();
  // equipos=equipos.data
  //  console.log(equipos);
  //  let roles= useGetProfesionesQuery();
  //  roles=roles.data
  //   console.log(roles);
  const [ejecutar, setEjecutar] = React.useState(true);
  const [pro, setPro] = React.useState([]);
  const [actividad1, setActividad1] = React.useState([]);
  const [equipos, setEquipos] = React.useState([]);
  const [roles, setRoles] = React.useState([]);

  const [items, setItems] = React.useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const rol = items.rol;

  const getPro = async () => {
    try {
      const { data1 } = await axios
        .get("/proyectos")
        .then(function (response) {
          // setAlerta({})

          setPro(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const getActividad1 = async () => {
    try {
      const { data1 } = await axios
        .get("/tipoactividad")
        .then(function (response) {
          // setAlerta({})

          setActividad1(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const getEquipos = async () => {
    try {
      const { data1 } = await axios
        .get("/equipos")
        .then(function (response) {
          // setAlerta({})

          setEquipos(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const getRoles = async () => {
    try {
      const { data1 } = await axios
        .get("/profesiones")
        .then(function (response) {
          // setAlerta({})

          setRoles(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const nav = useNavigate();
  //const [actividad2, setActividad2] = React.useState([]);

  //Theme
  const theme = useTheme();
  ////

  //DatePicker Value
  const [valueDP, setValueDP] = React.useState(convo.fecha);

  //TimePicker Values
  const [newValueHE, setValueHE] = React.useState(de);
  const [cambio, setCambio] = React.useState(false);
  const [newValueHEStr, setValueHEStr] = React.useState(str3);
  const handleChangeHE = (newValueHE) => {
    var str = new Date(newValueHE).toString();
    str = str.slice(16, -32);
    console.log(str);
    setValueHEStr(str);
    setValueHE(newValueHE);
  };

  const [newValueHS, setValueHS] = React.useState(ds);
  const [newValueHSStr, setValueHSStr] = React.useState(str4);
  const handleChangeHS = (newValueHS) => {
    var str1 = new Date(newValueHS).toString();
    str1 = str1.slice(16, -32);
    setValueHSStr(str1);
    setValueHS(newValueHS);
  };

  const [newValueRT, setValueRT] = React.useState(dr);
  const [newValueRTStr, setValueRTStr] = React.useState(str5);
  const handleChangeRT = (newValueRT) => {
    var str2 = new Date(newValueRT).toString();
    str2 = str2.slice(16, -32);
    setValueRTStr(str2);
    setValueRT(newValueRT);
  };

  //BreadCrumbs
  function handleClickBreadCrumbs(event) {
    event.preventDefault();
  }

  //Modal Styles
  const style = {
    position: "inherit",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 375,
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    borderRadius: 4,
    boxShadow: 1,
    p: 4,
  };

  //Modal Handlers
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openAlert, setOpenAlert] = React.useState(false);
  const handleOpenAlert = () => {
    setOpenAlert(true);
    handleCloseAlert();
  };
  const handleCloseAlert = () =>
    setTimeout(() => {
      setOpenAlert(false);
      nav("/voluntariado");
    }, 2000);

  //Actividad Select
  const [actividadOpc, setActividadOpc] = React.useState(convo.actividad1);
  const handleChangeActividad = (event) => {
    const {
      target: { value },
    } = event;
    setActividadOpc(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  //Equipos Select
  let equi = JSON.parse(convo.equipos);
  const [equiposOpc, setEquiposOpc] = React.useState(equi);
  const [equiposQuery, setEquiposQuery] = React.useState([]);
  const handleChangeEquipos = (event) => {
    const {
      target: { value },
    } = event;
    setEquiposOpc(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setEquiposQuery(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  //Proyecto Select
  const [proyecto, setProyecto] = React.useState(convo.idProyecto);
  const handleChangeProyecto = (event) => {
    setProyecto(event.target.value);
  };

  //Cantidad de Voluntarios Inputs
  let rel = JSON.parse(convo.cantidadVoluntarios);
  console.log(rel);
  const [data, setData] = useState(rel);
  const [data2, setData2] = useState([{ cantidad: "10" }]);
  const [activar, setActivar] = useState(false);
  let contador = 0;

  const handleClick = () => {
    setData([...data, { roles: "", cantidad: "" }]);
    setActivar(true);
  };
  const handleChangeInput = (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...data];
    onchangeVal[i][name] = value;
    setData(onchangeVal);

    for (let i = 0; i < data.length; i++) {
      if (data[i].roles !== "" && data[i].cantidad !== "") {
        console.log(data[i].cantidad);
      } else {
        contador = contador + 1;
      }
      if (contador !== 0) {
        setActivar(true);
      } else {
        setActivar(false);
      }
    }
  };
  const handleDelete = (i) => {
    const deleteVal = [...data];
    deleteVal.splice(i, 1);
    setData(deleteVal);
  };

  //Voluntarios Extra Radio
  const [valueExtra, setValueExtra] = React.useState("No");

  const handleChangeExtra = (event) => {
    setValueExtra(event.target.value);
  };

  //Routes
  const handleLinkClick = (event, message) => {
    if (message === "cancelar") {
      nav(-1);
    }
  };
  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };
  const handleLinkVol = (event, message) => {
    if (message === "voluntariado") {
      nav("/voluntariado");
    }
  };

  //Form Submit
  const [loc, setLoc] = React.useState(convo.locacion);
  const [estado, setEstado] = React.useState(convo.estadoConvo);
  const [municipio, setMunicipio] = React.useState(convo.municipio);
  const [parroquia, setParroquia] = React.useState(convo.parroquia);
  const [comunidad, setComunidad] = React.useState(convo.comunidad);
  const [puntoEncuentro, setPuntoEncuentro] = React.useState(
    convo.puntoEncuentro
  );
  const [desc, setDesc] = React.useState(convo.descripcionConvo);
  const [latitud, setLatitud] = React.useState(convo.latitud);
  const [longitud, setLongitud] = React.useState(convo.longitud);
  const [duracion, setDuracion] = React.useState(convo.duracion);
  const [comentarios, setComentarios] = React.useState(convo.comentarios);
  let insumo = JSON.parse(convo.insumos);
  const [stateInsumos, setCheck] = React.useState(insumo);
  let logis = JSON.parse(convo.logistica);
  const [stateLogistica, setCheckLogistica] = React.useState(logis);

  const handleLoc = (event) => {
    setLoc(event.target.value);
  };
  const handleEstado = (event) => {
    setEstado(event.target.value);
  };
  const handleMunicipio = (event) => {
    setMunicipio(event.target.value);
  };
  const handleParroquia = (event) => {
    setParroquia(event.target.value);
  };
  const handleComunidad = (event) => {
    setComunidad(event.target.value);
  };
  const handleDesc = (event) => {
    setDesc(event.target.value);
  };
  const handleLatitud = (event) => {
    setLatitud(event.target.value);
  };
  const handleLongitud = (event) => {
    setLongitud(event.target.value);
  };
  const handlePuntoEncuentro = (event) => {
    setPuntoEncuentro(event.target.value);
  };
  const handleDuracion = (event) => {
    setDuracion(event.target.value);
  };
  const handleComentarios = (event) => {
    setComentarios(event.target.value);
  };

  const handleChangeCheck = (event) => {
    setCheck({
      ...stateInsumos,
      [event.target.name]: event.target.checked,
    });
  };
  const handleChangeCheckLogistica = (event) => {
    setCheckLogistica({
      ...stateLogistica,
      [event.target.name]: event.target.checked,
    });
  };

  const { plumpy, rutf, desparasitantes, micronutrientes, medicamentos } =
    stateInsumos;
  const { sillas, mesas, minibus, pickup } = stateLogistica;

  const handleSubmit = async (event) => {
    let convocaProfesiones = "";
    for (let i = 0; i < data.length; i++) {
      convocaProfesiones = convocaProfesiones + data[i].roles + ",";
    }

    let query = equiposQuery.toString();
    let profesiones = convocaProfesiones.slice(
      0,
      convocaProfesiones.length - 1
    );

    console.log(data[0].roles);

    try {
      console.log(newValueRTStr);
      const { data1 } = await axios
        .put("/actualizarconvocatoria", {
          id: convo.id,
          idProyecto: proyecto,
          actividad: JSON.stringify(actividadOpc),
          estadoConvo: estado,
          municipio: municipio,
          parroquia: parroquia,
          comunidad: comunidad,
          fecha: valueDP,
          puntoEncuentro: puntoEncuentro,
          duracion: duracion,
          horaEncuentro: newValueHEStr,
          horaSalida: newValueHSStr,
          horaRetorno: newValueRTStr,
          cantidadVoluntarios: JSON.stringify(data),
          extra:valueExtra,
          equipos: JSON.stringify(equiposOpc),
          logistica: JSON.stringify(stateLogistica),
          comentarios: comentarios,
          insumos: JSON.stringify(stateInsumos),
          equiposQuery: query,
          profesionesQuery: profesiones,

          // //        nombres: (dataform.get('nombreProyecto')),
          // //        apellidos: (dataform.get('codigoProyecto')),
          // //        cedula: (dataform.get('descripcion')),
          // //        correo: dataform.get('email'),
          // //        telefono: dataform.get('textmask'),
          // //        //fnacimiento:new Date(selectedDate).toLocaleDateString('es-ES') ,
          // //        ocupacion: dataform.get('ocupacion'),
          // //        direccion: dataform.get('direccion'),
        })
        .then(function (response) {
          //console.log(response.data.idToken)
          //localStorage.setItem('token',JSON.stringify(response.data) )
          //setAuth(data)
          //event.target.reset();
        })
        .catch(function (error) {
          // //        event.preventDefault();
          // //        console.log('error')
          // //       // document.getElementById(":r7:").value='';
        });

      console.log(data1);
    } catch (error) {}

    handleOpenAlert();
  };
  if (ejecutar) {
    getActividad1();
    getEquipos();
    getPro();
    getRoles();
  }

  if (!actividad1 || !pro || !equipos || !roles)
    return (
      <Container component="main" maxWidth="xs">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );

  return (
    <Box m="1.5rem 1.5rem">
      {rol === "1" ||
      rol === "2" ||
      rol === "6" ||
      rol === "7" ||
      rol === "8" ||
      rol === "9" ||
      rol === "10" ? (
        <>
          <Box
            gridColumn="span 2"
            gridRow="span 1"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p="1.25rem 1rem"
            flex="1 1 100%"
            borderRadius="0.55rem"
          >
            <CssBaseline />
            <Box
              role="presentation"
              onClick={handleClickBreadCrumbs}
              sx={{ mb: 3 }}
            >
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  href="/inicio"
                  onClick={(event) => handleLinkHome(event, "home")}
                >
                  <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Inicio
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  href="/voluntariado"
                  onClick={(event) => handleLinkVol(event, "voluntariado")}
                >
                  <VolunteerActivismIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Voluntariado
                </Link>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  <EditIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Editar Convocatoria
                </Typography>
              </Breadcrumbs>
              <Typography variant="h3" sx={{ mt: 2 }}>
                {" "}
                <b> Editar Convocatoria: </b> {convo.actividad} ({convo.fecha}){" "}
              </Typography>
            </Box>
            <Container>
              <Card
                sx={{
                  borderRadius: 8,
                  boxShadow: 5,
                  bgcolor: theme.palette.background.alt,
                }}
              >
                <CardContent>
                  <Divider sx={{ mt: 2, mb: 3 }}>
                    <Chip label="INFORMACIÓN GENERAL" />
                  </Divider>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} mt={1}>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" required>
                            {" "}
                            Proyecto que Convoca{" "}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="convoc"
                            label="Proyecto que Convoca"
                            value={proyecto}
                            onChange={handleChangeProyecto}
                          >
                            <MenuItem value="">
                              <em> Seleccione un Proyecto </em>
                            </MenuItem>
                            {pro.map((dat, index) => (
                              <MenuItem value={dat.idproyectos}>
                                {dat.nombre}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" required>
                            {" "}
                            Tipo de Actividad{" "}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="tipoAct"
                            label="Tipo de Actividad"
                            multiple
                            value={actividadOpc}
                            onChange={handleChangeActividad}
                            input={<OutlinedInput label="Tipo de Actividad" />}
                          >
                            {actividad1.map((dat, index) => (
                              <MenuItem value={dat.nombre}>
                                {dat.nombre}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="descripcion"
                          required
                          fullWidth
                          id="descripcion"
                          label="Descripción de la Convocatoria"
                          value={desc}
                          onChange={handleDesc}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="estado"
                          required
                          fullWidth
                          id="estado"
                          label="Estado"
                          value={estado}
                          onChange={handleEstado}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="municipio"
                          required
                          fullWidth
                          id="municipio"
                          label="Municipio"
                          value={municipio}
                          onChange={handleMunicipio}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="parroquia"
                          required
                          fullWidth
                          id="parroquia"
                          label="Parroquia"
                          value={parroquia}
                          onChange={handleParroquia}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="comunidad"
                          required
                          fullWidth
                          id="comunidad"
                          label="Comunidad"
                          value={comunidad}
                          onChange={handleComunidad}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="latitud"
                          required
                          fullWidth
                          value={latitud}
                          id="latitud"
                          label="Latitud"
                          onChange={handleLatitud}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="longitud"
                          required
                          fullWidth
                          value={longitud}
                          id="longitud"
                          label="Longitud"
                          onChange={handleLongitud}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="es"
                        >
                          <DesktopDatePicker
                            label="Fecha"
                            name="fecha"
                            inputFormat="DD/MM/YYYY"
                            value={valueDP}
                            onChange={setValueDP}
                            renderInput={(params) => {
                              return <TextField fullWidth {...params} />;
                            }}
                            clearable
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="encuentro"
                          required
                          fullWidth
                          id="encuentro"
                          label="Punto de Encuentro"
                          value={puntoEncuentro}
                          onChange={handlePuntoEncuentro}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="duracion"
                          required
                          fullWidth
                          id="duracion"
                          label="Duración Aprox."
                          value={duracion}
                          onChange={handleDuracion}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileTimePicker
                            name="horaEncuentro"
                            label="Hora de Encuentro"
                            value={newValueHE}
                            onChange={handleChangeHE}
                            renderInput={(params) => {
                              return <TextField fullWidth {...params} />;
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileTimePicker
                            name="horaSalida"
                            label="Hora de Salida"
                            value={newValueHS}
                            onChange={handleChangeHS}
                            renderInput={(params) => (
                              <TextField fullWidth {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileTimePicker
                            name="horaRetorno"
                            label="Hora Estimada de Retorno"
                            value={newValueRT}
                            onChange={handleChangeRT}
                            renderInput={(params) => (
                              <TextField fullWidth {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 3 }}>
                      <Chip label="VOLUNTARIOS" />
                    </Divider>
                    {data.map((val, i) => (
                      <div>
                        <Grid
                          container
                          spacing={2}
                          sx={{ mt: 1 }}
                          justifyContent="center"
                        >
                          <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                              <InputLabel id="roles"> Roles </InputLabel>
                              <Select
                                labelId="roles-select"
                                id="roles-select"
                                label="Roles"
                                name="roles"
                                value={val.roles}
                                onChange={(e) => handleChangeInput(e, i)}
                              >
                                <MenuItem value="">
                                  <em> Seleccione un Rol </em>
                                </MenuItem>
                                {roles.map((dat, index) => (
                                  <MenuItem value={dat.idprofesiones}>
                                    {dat.descripcion}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              fullWidth
                              id="cantidad"
                              label="Cantidad"
                              name="cantidad"
                              value={val.cantidad}
                              onChange={(e) => handleChangeInput(e, i)}
                            />
                          </Grid>
                          <Grid item xs={4} sm={1} sx={{ mt: 1 }}>
                            <Tooltip title="Eliminar">
                              <IconButton
                                aria-label="delete"
                                size="large"
                                color="error"
                                onClick={() => handleDelete(i)}
                              >
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                    <Grid container justifyContent="center">
                      <Grid item xs={6} sm={2} sx={{ mt: 3 }}>
                        <Button
                          disabled={activar}
                          variant="contained"
                          sx={{
                            bgcolor: "teal",
                            color: "white",
                            borderRadius: 2,
                          }}
                          fullWidth
                          onClick={handleClick}
                        >
                          {" "}
                          Añadir Más{" "}
                        </Button>
                      </Grid>
                    </Grid>
                    {/*<p>{JSON.stringify(data)}</p>*/}
                    <Divider sx={{ mt: 4, mb: 3 }}>
                      <Chip label="EQUIPOS" />
                    </Divider>
                    <Grid container justifyContent="center">
                      <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            {" "}
                            Equipos{" "}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Equipos"
                            multiple
                            value={equiposOpc}
                            onChange={handleChangeEquipos}
                            input={<OutlinedInput label="Equipos" />}
                          >
                            {equipos.map((dat, index) => (
                              <MenuItem value={dat.id}>{dat.nombre}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 3 }}>
                      <Chip label="VOLUNTARIOS EXTRA" />
                    </Divider>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item xs={12} sm={3}>
                        <FormControl>
                          <FormLabel
                            className="text-center"
                            id="volExtra-label"
                          >
                            ¿Se requieren Voluntarios Extra?
                          </FormLabel>
                          <RadioGroup
                            row
                            name="volExtra"
                            value={valueExtra}
                            onChange={handleChangeExtra}
                            className="text-center"
                            sx={{ justifyContent: "center" }}
                          >
                            <FormControlLabel
                              value="Si"
                              control={<Radio />}
                              label="Si"
                            />
                            <FormControlLabel
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                    {/*<Grid container justifyContent="center">
                      <Grid item xs={12} sm={4} mt={2}>
                        <TextField
                          fullWidth
                          type="number"
                          id="cantidadExtra"
                          label="Cantidad"
                          name="cantidadExtra"
                          style={{
                            display: valueExtra === "No" ? "none" : "block",
                          }}
                        />
                      </Grid>
                    </Grid>*/}
                    <Box mt={5}>
                      <Typography variant="h6" className="text-center">
                        <InfoOutlinedIcon /> <strong>"Voluntario Extra"</strong>{" "}
                        se refiere a todo aquel voluntario que no pertenece a tu
                        equipo.
                      </Typography>
                    </Box>
                    <Divider sx={{ mt: 4, mb: 3 }}>
                      <Chip label="LOGÍSTICA NECESARIA" />
                    </Divider>
                    <Grid container justifyContent="center">
                      <Grid item xs={12} sm={5}>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="sillas"
                                color="success"
                                checked={sillas}
                                value="Sillas"
                                onChange={handleChangeCheckLogistica}
                              />
                            }
                            label="Sillas"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="mesas"
                                color="success"
                                checked={mesas}
                                value="Mesas"
                                onChange={handleChangeCheckLogistica}
                              />
                            }
                            label="Mesas"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="minibus"
                                color="success"
                                checked={minibus}
                                value="Minibus"
                                onChange={handleChangeCheckLogistica}
                              />
                            }
                            label="Minibus (Coaster)"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="pickup"
                                color="success"
                                checked={pickup}
                                value="Pickup"
                                onChange={handleChangeCheckLogistica}
                              />
                            }
                            label="Pickup"
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="center" mt={1.5}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="comentarios"
                          fullWidth
                          id="comentarios"
                          label="Comentarios"
                          value={comentarios}
                          onChange={handleComentarios}
                          multiline
                          maxRows={4}
                        />
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 3 }}>
                      <Chip label="INSUMOS NECESARIOS" />
                    </Divider>
                    <Grid container justifyContent="center">
                      <Grid item xs={12} sm={8}>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="plumpy"
                                color="success"
                                checked={plumpy}
                                value="Plumpy"
                                onChange={handleChangeCheck}
                              />
                            }
                            label="Plumpy"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="rutf"
                                color="success"
                                checked={rutf}
                                value="RUTF"
                                onChange={handleChangeCheck}
                              />
                            }
                            label="RUTF"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="desparasitantes"
                                color="success"
                                checked={desparasitantes}
                                value="Micronutrientes"
                                onChange={handleChangeCheck}
                              />
                            }
                            label="Desparasitantes"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="micronutrientes"
                                color="success"
                                checked={micronutrientes}
                                value="Micronutrientes"
                                onChange={handleChangeCheck}
                              />
                            }
                            label="Micronutrientes"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="medicamentos"
                                color="success"
                                checked={medicamentos}
                                value="Medicamentos"
                                onChange={handleChangeCheck}
                              />
                            }
                            label="Medicamentos"
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 5 }}></Divider>
                    <Grid
                      container
                      sx={{ mt: 2 }}
                      spacing={1}
                      justifyContent="center"
                    >
                      <Grid item sm={3} xs={6}>
                        <Button
                          variant="contained"
                          color="error"
                          size="large"
                          fullWidth
                          onClick={handleOpen}
                        >
                          {" "}
                          Cancelar{" "}
                        </Button>
                      </Grid>
                      <Grid item sm={3} xs={6}>
                        <Button
                          variant="contained"
                          onClick={handleSubmit}
                          sx={{ bgcolor: "teal", color: "white" }}
                          size="large"
                          fullWidth
                        >
                          {" "}
                          Guardar{" "}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                slotProps={{ backdrop: { style: { opacity: 0.2 } } }}
              >
                <Box sx={style}>
                  <Typography
                    variant="h6"
                    className="text-center"
                    sx={{ mb: 1.5 }}
                  >
                    {" "}
                    ¿Está seguro de cancelar esta operación?{" "}
                  </Typography>
                  <Divider sx={{ mt: 2 }} />
                  <Grid
                    container
                    sx={{ mt: 1 }}
                    spacing={1}
                    justifyContent="flex-end"
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                      >
                        {" "}
                        No{" "}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        sx={{ bgcolor: "teal", color: "white" }}
                        onClick={(event) => handleLinkClick(event, "cancelar")}
                      >
                        {" "}
                        Si{" "}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>

              <Modal
                open={openAlert}
                onClose={handleCloseAlert}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" className="text-center">
                    <TaskAltOutlinedIcon
                      color="success"
                      sx={{ fontWeight: "bold", mt: 3, fontSize: 80 }}
                    />
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    className="text-center"
                    sx={{ mt: 2, fontSize: 20, fontWeight: "bold" }}
                  >
                    ¡Convocatoria actualizada exitosamente!
                  </Typography>
                </Box>
              </Modal>
            </Container>
          </Box>
        </>
      ) : (
        <Restricted />
      )}
    </Box>
  );
};

export default EditarActividad;
