import React, { useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme, useMediaQuery } from "@mui/material";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import {
  Box,
  Button,
  Modal,
  Divider,
  Avatar,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import HomeIcon from "@mui/icons-material/Home";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MenuItem from "@mui/material/MenuItem";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import InventoryIcon from "@mui/icons-material/Inventory";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import StorageIcon from "@mui/icons-material/Storage";
import StarsIcon from "@mui/icons-material/Stars";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import PregnantWomanIcon from "@mui/icons-material/PregnantWoman";
import BadgeIcon from "@mui/icons-material/Badge";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SpeedIcon from "@mui/icons-material/Speed";
import Restricted from "components/Restricted";
import TransConvoList from "components/TransConvoList";
import clienteAxios from "../../config/clienteAxios";
import axios from "axios";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Transcripcion = () => {
  const nav = useNavigate();
  const [ejecutar, setEjecutar] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [dataF, setDataF] = React.useState([]);
  const [rankingNinos, setRankingNinos] = React.useState([]);
  const [rankingMel, setRankingMel] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [datos, setDatos] = React.useState([]);
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const rol = items.rol;

  //Theme
  const theme = useTheme();

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //Modal Styles
  const style = {
    position: "inherit",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "90%" : "auto",
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    borderRadius: 4,
    boxShadow: 1,
    p: 4,
  };

  //Tabs
  const [valueTabs, setValueTabs] = React.useState(0);

  const handleChangeTabs = (event, newValueTabs) => {
    setValueTabs(newValueTabs);
  };

  const [tabsRanking, setTabsRanking] = React.useState(1);

  const handleTabsRanking = (event, newValue) => {
    setTabsRanking(newValue);
  };

  //Modal Handlers
  const [openConvos, setOpenConvos] = React.useState(false);
  const handleOpenConvos = (row) => {
    setDatos(row);
    setOpenConvos(true);
  };
  const handleCloseConvos = () => setOpenConvos(false);

  //BreadCrumbs
  function handleClickBreadCrumbs(event) {
    event.preventDefault();
  }

  //Routes
  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };

  const handleLinkConvo = (event, message, row) => {
    let prop = JSON.stringify(row.original);
    if (message === "convo") {
      //nav(`/asistencia?con=${con}`)
      nav(`/listaTranscripcion?prop=${prop}`);
    }
  };

  const handleConsolidado = (event, message, row) => {
    let prop = JSON.stringify(row);
    if (message === "consolidado") {
      nav(`/baseDatos?prop=${prop}`);
    }
  };

  const convocatorias = async () => {
    try {
      const { data1 } = await axios
        .get("/convocatoria/", {
          params: {
            id: 1,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setData(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const convocatoriasF = async () => {
    try {
      const { data1 } = await axios
        .get("/convocatoria/", {
          params: {
            id: 2,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setDataF(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const proyectos = async () => {
    try {
      const { data1 } = await axios
        .get("/proyectos")
        .then(function (response) {
          // setAlerta({})
          let datos = response.data;
          let datosA = datos.filter((dato) => dato.estado === 1);
          let datosF = datos.filter((dato) => dato.estado === 2);
          setData(datosA);
          setDataF(datosF);
          setEjecutar(false);
          console.log(data);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const getRankingNinos = async () => {
    try {
      const { data1 } = await axios
        .get("/getrankingninos")
        .then(function (response) {
          // setAlerta({})
          let datos = response.data;
      
          setRankingNinos(datos);
        
          setEjecutar(false);
          console.log(data);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const getRankingMel = async () => {
    try {
      const { data1 } = await axios
        .get("/getrankingmel")
        .then(function (response) {
          // setAlerta({})
          let datos = response.data;
      
          setRankingMel(datos);
        
          setEjecutar(false);
          console.log(data);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "codigo",
        header: "Código",
      },
      {
        accessorKey: "nombre",
        header: "Poyecto",
      },
      {
        accessorKey: "descripcion",
        header: "Descripción",
      },
    ],
    []
  );

  if (ejecutar) {
    //convocatorias();
    //convocatoriasF();
    proyectos();
    getRankingNinos();
    getRankingMel();
  }
  if (!data)
    return (
      <Container component="main" maxWidth="xs">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );

  return (
    <Box m="1.5rem 2.5rem">
      {rol === "1" || rol === "2" || rol === "8" || rol === "11" ? (
        <>
          <Box
            role="presentation"
            onClick={handleClickBreadCrumbs}
            sx={{ mb: 3 }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/inicio"
                onClick={(event) => handleLinkHome(event, "home")}
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Inicio
              </Link>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <DriveFileRenameOutlineIcon
                  sx={{ mr: 0.5 }}
                  fontSize="inherit"
                />
                Transcripción
              </Typography>
            </Breadcrumbs>
            <Typography variant="h3" sx={{ fontWeight: "bold", mt: 2 }}>
              Transcripción
            </Typography>
            {/* /////////////////////VISTA PARA TRANSCRIPTORES///////////////////////////////// */}
            <Container hidden={ rol !== "11" }>
              <Box
                mt="10px"
                display="grid"
                gridTemplateColumns="repeat(8, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                  "& > div": {
                    gridColumn: isNonMediumScreens ? undefined : "span 12",
                  },
                }}
              >
                <Box
                  gridColumn="span 8"
                  gridRow="span 3"
                  backgroundColor={theme.palette.background.alt}
                  p="1.5rem"
                  borderRadius="1.5rem"
                  mt={isSmallScreen ? "0px" : "40px"}
                  height="fit-content"
                  sx={{ boxShadow: 4 }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={valueTabs}
                        onChange={handleChangeTabs}
                        variant={isSmallScreen ? "scrollable" : "fullWidth"}
                      >
                        <Tab
                          icon={<AssignmentTurnedInIcon />}
                          iconPosition="start"
                          label="Proyectos Activos"
                          {...a11yProps(0)}
                        />
                        <Tab
                          icon={<InventoryIcon />}
                          iconPosition="start"
                          label="Proyectos Finalizados"
                          {...a11yProps(1)}
                        />
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={valueTabs} index={0}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                            Proyectos Activos
                          </Typography>
                        </Grid>
                      </Grid>
                      <MaterialReactTable
                        columns={columns}
                        data={data}
                        localization={MRT_Localization_ES}
                        enableRowActions
                        positionExpandColumn="first"
                        positionActionsColumn="first"
                        renderRowActionMenuItems={({ row, closeMenu }) => [
                          <MenuItem
                            onClick={(event) => {
                              handleOpenConvos(row.original);
                              closeMenu();
                            }}
                          >
                            <ContentPasteSearchIcon sx={{ mr: 1 }} />
                            <b>Ver Convocatorias</b>
                          </MenuItem>,
                          <MenuItem
                            onClick={(event) =>
                              handleConsolidado(
                                event,
                                "consolidado",
                                row.original
                              )
                            }
                          >
                            <StorageIcon sx={{ mr: 1 }} />
                            <b>Ver Base de Datos</b>
                          </MenuItem>,
                        ]}
                        muiTopToolbarProps={{
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiTableHeadCellProps={{
                          align: "center",
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiBottomToolbarProps={{
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiTableBodyProps={{
                          sx: {
                            "& tr:nth-of-type(odd)": {
                              backgroundColor: theme.palette.background.light,
                            },
                          },
                        }}
                        muiTableBodyCellProps={{
                          align: "center",
                        }}
                        muiTablePaperProps={{
                          elevation: 0,
                        }}
                        initialState={{
                          pagination: {
                            pageSize: 5,
                            pageIndex: 0,
                          },
                          sorting: [
                            {
                              id: "fecha",
                              desc: true,
                            },
                          ],
                        }}
                        muiTablePaginationProps={{
                          rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                          showFirstButton: false,
                          showLastButton: false,
                          SelectProps: {
                            native: true,
                          },
                          labelRowsPerPage: "Número de filas visibles",
                        }}
                      />
                    </CustomTabPanel>
                    <CustomTabPanel value={valueTabs} index={1}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                            Proyectos Finalizados
                          </Typography>
                        </Grid>
                      </Grid>
                      <MaterialReactTable
                        columns={columns}
                        data={dataF}
                        localization={MRT_Localization_ES}
                        enableRowActions
                        positionExpandColumn="first"
                        positionActionsColumn="first"
                        renderRowActionMenuItems={({ row, closeMenu }) => [
                          <MenuItem
                            onClick={(event) => {
                              handleOpenConvos(row.original);
                              closeMenu();
                            }}
                          >
                            <ContentPasteSearchIcon sx={{ mr: 1 }} />
                            <b>Ver Convocatorias</b>
                          </MenuItem>,
                          <MenuItem
                            onClick={(event) =>
                              handleConsolidado(event, "consolidado")
                            }
                          >
                            <StorageIcon sx={{ mr: 1 }} />
                            <b>Ver Base de Datos</b>
                          </MenuItem>,
                        ]}
                        muiTopToolbarProps={{
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiTableHeadCellProps={{
                          align: "center",
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiBottomToolbarProps={{
                          sx: {
                            backgroundColor: theme.palette.background.alt,
                          },
                        }}
                        muiTableBodyProps={{
                          sx: {
                            "& tr:nth-of-type(odd)": {
                              backgroundColor: theme.palette.background.light,
                            },
                          },
                        }}
                        muiTableBodyCellProps={{
                          align: "center",
                        }}
                        muiTablePaperProps={{
                          elevation: 0,
                        }}
                        initialState={{
                          pagination: {
                            pageSize: 5,
                            pageIndex: 0,
                          },
                          sorting: [
                            {
                              id: "fecha",
                              desc: true,
                            },
                          ],
                        }}
                        muiTablePaginationProps={{
                          rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                          showFirstButton: false,
                          showLastButton: false,
                          SelectProps: {
                            native: true,
                          },
                          labelRowsPerPage: "Número de filas visibles",
                        }}
                      />
                    </CustomTabPanel>
                  </Box>
                </Box>
              </Box>
            </Container>
            {/* ////////////////////////////////////////////////////////////////////////////// */}
            <Box
              mt="10px"
              display="grid"
              gridTemplateColumns="repeat(8, 1fr)"
              gridAutoRows="160px"
              gap="20px"
              hidden={ rol === "11" }
              sx={{
                "& > div": {
                  gridColumn: isNonMediumScreens ? undefined : "span 12",
                },
              }}
            >
              <Box
                gridColumn="span 2"
                gridRow={isSmallScreen ? "span 4" : "span 3"}
                backgroundColor={theme.palette.background.alt}
                p="1.5rem"
                borderRadius="1.5rem"
                mt="40px"
                height="fit-content"
                sx={{ boxShadow: 4 }}
              >
                <Grid item xs={12} sm={12}>
                  <Typography
                    variant={isSmallScreen ? "h6" : "h5"}
                    className="text-center"
                    sx={{ fontWeight: "bold", mb: 3 }}
                  >
                    <SpeedIcon sx={{ mr: 1, fontSize: 25 }} /> Rendimiento de
                    Transcriptores
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      //backgroundColor: theme.palette.background.alt,
                      //boxShadow: 4,
                      //borderRadius: 4,
                    }}
                  >
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={tabsRanking}
                        onChange={handleTabsRanking}
                        variant={isSmallScreen ? "scrollable" : "fullWidth"}
                        scrollButtons
                        allowScrollButtonsMobile
                      >
                        <Tab
                          icon={<StarsIcon />}
                          label="Ranking General"
                          {...a11yProps(0)}
                          hidden
                        />
                        <Tab
                          icon={<ChildCareIcon />}
                          label="Menores de 5 años"
                          {...a11yProps(1)}
                        />
                        <Tab
                          icon={<PregnantWomanIcon />}
                          label="MEL"
                          {...a11yProps(2)}
                        />
                        <Tab
                          icon={<CheckCircleIcon />}
                          label="Salud"
                          {...a11yProps(3)}
                          hidden
                        />
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={tabsRanking} index={0}>
                      <Box
                        height={isSmallScreen ? "100%" : "105px"}
                        //className="scrollTest"
                      >
                        <Card
                          sx={{
                            backgroundColor: "transparent",
                            boxShadow: 0,
                            borderRadius: 0,
                          }}
                        >
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "goldenRod",
                                  width: 35,
                                  height: 35,
                                  border: "1px solid black",
                                }}
                              >
                                1
                              </Avatar>
                            }
                            title="Nombre Apellido"
                            subheader="Descripción"
                            titleTypographyProps={{
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          />
                          <Divider />
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "LightSteelBlue",
                                  width: 35,
                                  height: 35,
                                  border: "1px solid black",
                                }}
                              >
                                2
                              </Avatar>
                            }
                            title="Nombre Apellido"
                            subheader="Descripción"
                            titleTypographyProps={{
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          />
                          <Divider />
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "teal",
                                  width: 35,
                                  height: 35,
                                  border: "1px solid black",
                                }}
                              >
                                3
                              </Avatar>
                            }
                            title="Nombre Apellido"
                            subheader="Descripción"
                            titleTypographyProps={{
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          />
                        </Card>
                      </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabsRanking} index={1}>
                      <Box
                        //height={isSmallScreen ? "100%" : "105px"}
                        //className="scrollTest"
                        height="fit-content"
                      >
                        <Card
                          sx={{
                            backgroundColor: "transparent",
                            boxShadow: 0,
                            borderRadius: 0,
                          }}
                        >
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "goldenrod",
                                  width: 35,
                                  height: 35,
                                  border: "1px solid black",
                                }}
                              >
                                1
                              </Avatar>
                            }
                            title={rankingNinos.length <1 ? "" : rankingNinos[0].nombres+" "+rankingNinos[0].apellidos}
                            subheader={rankingNinos.length <1 ? "" : rankingNinos[0].total+" Pacientes"}
                           
                            titleTypographyProps={{
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          />
                          <Divider />
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "teal",
                                  width: 35,
                                  height: 35,
                                  border: "1px solid black",
                                }}
                              >
                                2
                              </Avatar>
                            }
                            title={rankingNinos.length <1 ? "" : rankingNinos[1].nombres+" "+rankingNinos[1].apellidos}
                            subheader={rankingNinos.length <1 ? "" : rankingNinos[1].total+" Pacientes"}
                            titleTypographyProps={{
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          />
                          <Divider />
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "teal",
                                  width: 35,
                                  height: 35,
                                  border: "1px solid black",
                                }}
                              >
                                3
                              </Avatar>
                            }
                            title={rankingNinos.length <1 ? "" : rankingNinos[2].nombres+" "+rankingNinos[2].apellidos}
                            subheader={rankingNinos.length <1 ? "" : rankingNinos[2].total+" Pacientes"}
                            titleTypographyProps={{
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          />
                          <Divider />
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "teal",
                                  width: 35,
                                  height: 35,
                                  border: "1px solid black",
                                }}
                              >
                                4
                              </Avatar>
                            }
                            title={rankingNinos.length <1 ? "" : rankingNinos[3].nombres+" "+rankingNinos[3].apellidos}
                            subheader={rankingNinos.length <1 ? "" : rankingNinos[3].total+" Pacientes"}
                            titleTypographyProps={{
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          />
                          <Divider />
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "firebrick",
                                  width: 35,
                                  height: 35,
                                  border: "1px solid black",
                                }}
                              >
                                5
                              </Avatar>
                            }
                            title={rankingNinos.length <1 ? "" : rankingNinos[4].nombres+" "+rankingNinos[4].apellidos}
                            subheader={rankingNinos.length <1 ? "" : rankingNinos[4].total+" Pacientes"}
                            titleTypographyProps={{
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          />
                        </Card>
                      </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabsRanking} index={2}>
                      <Box
                        //height={isSmallScreen ? "100%" : "105px"}
                        //className="scrollTest"
                        height="fit-content"
                      >
                        <Card
                          sx={{
                            backgroundColor: "transparent",
                            boxShadow: 0,
                            borderRadius: 0,
                          }}
                        >
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "goldenrod",
                                  width: 35,
                                  height: 35,
                                  border: "1px solid black",
                                }}
                              >
                                1
                              </Avatar>
                            }
                            title={rankingMel.length <1 ? "" : rankingMel[0].nombres+" "+rankingMel[0].apellidos}
                            subheader={rankingMel.length <1 ? "" : rankingMel[0].total+" Pacientes"}
                            titleTypographyProps={{
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          />
                          <Divider />
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "teal",
                                  width: 35,
                                  height: 35,
                                  border: "1px solid black",
                                }}
                              >
                                2
                              </Avatar>
                            }
                            title={rankingMel.length <1 ? "" : rankingMel[1].nombres+" "+rankingMel[1].apellidos}
                            subheader={rankingMel.length <1 ? "" : rankingMel[1].total+" Pacientes"}
                            titleTypographyProps={{
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          />
                          <Divider />
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "teal",
                                  width: 35,
                                  height: 35,
                                  border: "1px solid black",
                                }}
                              >
                                3
                              </Avatar>
                            }
                            title={rankingMel.length <1 ? "" : rankingMel[2].nombres+" "+rankingMel[2].apellidos}
                            subheader={rankingMel.length <1 ? "" : rankingMel[2].total+" Pacientes"}
                            titleTypographyProps={{
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          />
                          <Divider />
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "teal",
                                  width: 35,
                                  height: 35,
                                  border: "1px solid black",
                                }}
                              >
                                4
                              </Avatar>
                            }
                            title={rankingMel.length <1 ? "" : rankingMel[3].nombres+" "+rankingMel[3].apellidos}
                            subheader={rankingMel.length <1 ? "" : rankingMel[3].total+" Pacientes"}
                            titleTypographyProps={{
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          />
                          <Divider />
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "firebrick",
                                  width: 35,
                                  height: 35,
                                  border: "1px solid black",
                                }}
                              >
                                5
                              </Avatar>
                            }
                            title={rankingMel.length <1 ? "" : rankingMel[4].nombres+" "+rankingMel[4].apellidos}
                            subheader={rankingMel.length <1 ? "" : rankingMel[4].total+" Pacientes"}
                            titleTypographyProps={{
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          />
                        </Card>
                      </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabsRanking} index={3}>
                      <Box
                        height={isSmallScreen ? "100%" : "105px"}
                        className="scrollTest"
                      >
                        <Card
                          sx={{
                            backgroundColor: "transparent",
                            boxShadow: 0,
                            borderRadius: 0,
                          }}
                        >
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "goldenRod",
                                  width: 35,
                                  height: 35,
                                  border: "1px solid black",
                                }}
                              >
                                1
                              </Avatar>
                            }
                            title="Hola"
                            subheader="hola"
                          />
                          <Divider />
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "LightSteelBlue",
                                  width: 35,
                                  height: 35,
                                  border: "1px solid black",
                                }}
                              >
                                2
                              </Avatar>
                            }
                            title="Hola"
                            subheader="hola"
                          />
                          <Divider />
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "teal",
                                  width: 35,
                                  height: 35,
                                  border: "1px solid black",
                                }}
                              >
                                3
                              </Avatar>
                            }
                            title="Hola"
                            subheader="hola"
                          />
                        </Card>
                      </Box>
                    </CustomTabPanel>
                  </Box>
                </Grid>
              </Box>
              <Box
                gridColumn={rol === "11" ? "span 8" : "span 6"}
                //gridColumn="span 8"
                gridRow="span 3"
                backgroundColor={theme.palette.background.alt}
                p="1.5rem"
                borderRadius="1.5rem"
                mt={isSmallScreen ? "0px" : "40px"}
                height="fit-content"
                sx={{ boxShadow: 4 }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={valueTabs}
                      onChange={handleChangeTabs}
                      variant={isSmallScreen ? "scrollable" : "fullWidth"}
                    >
                      <Tab
                        icon={<AssignmentTurnedInIcon />}
                        iconPosition="start"
                        label="Proyectos Activos"
                        {...a11yProps(0)}
                      />
                      <Tab
                        icon={<InventoryIcon />}
                        iconPosition="start"
                        label="Proyectos Finalizados"
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={valueTabs} index={0}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                          Proyectos Activos
                        </Typography>
                      </Grid>
                    </Grid>
                    <MaterialReactTable
                      columns={columns}
                      data={data}
                      localization={MRT_Localization_ES}
                      enableRowActions
                      positionExpandColumn="first"
                      positionActionsColumn="first"
                      renderRowActionMenuItems={({ row, closeMenu }) => [
                        <MenuItem
                          onClick={(event) => {
                            handleOpenConvos(row.original);
                            closeMenu();
                          }}
                        >
                          <ContentPasteSearchIcon sx={{ mr: 1 }} />
                          <b>Ver Convocatorias</b>
                        </MenuItem>,
                        <MenuItem
                          onClick={(event) =>
                            handleConsolidado(
                              event,
                              "consolidado",
                              row.original
                            )
                          }
                        >
                          <StorageIcon sx={{ mr: 1 }} />
                          <b>Ver Base de Datos</b>
                        </MenuItem>,
                      ]}
                      muiTopToolbarProps={{
                        sx: {
                          backgroundColor: theme.palette.background.alt,
                        },
                      }}
                      muiTableHeadCellProps={{
                        align: "center",
                        sx: {
                          backgroundColor: theme.palette.background.alt,
                        },
                      }}
                      muiBottomToolbarProps={{
                        sx: {
                          backgroundColor: theme.palette.background.alt,
                        },
                      }}
                      muiTableBodyProps={{
                        sx: {
                          "& tr:nth-of-type(odd)": {
                            backgroundColor: theme.palette.background.light,
                          },
                        },
                      }}
                      muiTableBodyCellProps={{
                        align: "center",
                      }}
                      muiTablePaperProps={{
                        elevation: 0,
                      }}
                      initialState={{
                        pagination: {
                          pageSize: 5,
                          pageIndex: 0,
                        },
                        sorting: [
                          {
                            id: "fecha",
                            desc: true,
                          },
                        ],
                      }}
                      muiTablePaginationProps={{
                        rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                        showFirstButton: false,
                        showLastButton: false,
                        SelectProps: {
                          native: true,
                        },
                        labelRowsPerPage: "Número de filas visibles",
                      }}
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={valueTabs} index={1}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                          Proyectos Finalizados
                        </Typography>
                      </Grid>
                    </Grid>
                    <MaterialReactTable
                      columns={columns}
                      data={dataF}
                      localization={MRT_Localization_ES}
                      enableRowActions
                      positionExpandColumn="first"
                      positionActionsColumn="first"
                      renderRowActionMenuItems={({ row, closeMenu }) => [
                        <MenuItem
                          onClick={(event) => {
                            handleOpenConvos(row.original);
                            closeMenu();
                          }}
                        >
                          <ContentPasteSearchIcon sx={{ mr: 1 }} />
                          <b>Ver Convocatorias</b>
                        </MenuItem>,
                        <MenuItem
                          onClick={(event) =>
                            handleConsolidado(event, "consolidado")
                          }
                        >
                          <StorageIcon sx={{ mr: 1 }} />
                          <b>Ver Base de Datos</b>
                        </MenuItem>,
                      ]}
                      muiTopToolbarProps={{
                        sx: {
                          backgroundColor: theme.palette.background.alt,
                        },
                      }}
                      muiTableHeadCellProps={{
                        align: "center",
                        sx: {
                          backgroundColor: theme.palette.background.alt,
                        },
                      }}
                      muiBottomToolbarProps={{
                        sx: {
                          backgroundColor: theme.palette.background.alt,
                        },
                      }}
                      muiTableBodyProps={{
                        sx: {
                          "& tr:nth-of-type(odd)": {
                            backgroundColor: theme.palette.background.light,
                          },
                        },
                      }}
                      muiTableBodyCellProps={{
                        align: "center",
                      }}
                      muiTablePaperProps={{
                        elevation: 0,
                      }}
                      initialState={{
                        pagination: {
                          pageSize: 5,
                          pageIndex: 0,
                        },
                        sorting: [
                          {
                            id: "fecha",
                            desc: true,
                          },
                        ],
                      }}
                      muiTablePaginationProps={{
                        rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                        showFirstButton: false,
                        showLastButton: false,
                        SelectProps: {
                          native: true,
                        },
                        labelRowsPerPage: "Número de filas visibles",
                      }}
                    />
                  </CustomTabPanel>
                </Box>
              </Box>
            </Box>
          </Box>
          <Modal open={openConvos} onClose={handleCloseConvos}>
            <Box sx={style}>
              <Typography variant="h4">
                <b>Convocatorias de:</b> {datos.nombre}
              </Typography>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <TransConvoList datos={datos} />
              {/*<Button variant='contained' size='lg' onClick={event => handleLinkConvo(event, 'convo')}>
              Convocatorias
            </Button>*/}
              <Divider sx={{ mt: 2 }} />
              <Grid
                container
                sx={{ mt: 2 }}
                spacing={1}
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleCloseConvos}
                  >
                    Salir
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </>
      ) : (
        <Restricted />
      )}
    </Box>
  );
};

export default Transcripcion;
