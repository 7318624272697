import React from "react";
import TestPDF from "components/Pdf/test-pdf";

const PdfConsolidado = () => {
  return (
    <div className="App">
      <TestPDF />
    </div>
  );
};

export default PdfConsolidado;
