import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import logo from "assets/paluz-logo.png";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {" © "}
      {new Date().getFullYear()}
      {" - "}
      <Link color="inherit" href="https://paluz.org/">
        PALUZ
      </Link>
    </Typography>
  );
}

const theme = createTheme();

export default function InfoPage() {
  const nav = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
    });
  };

  const [correo, setEmail] = useState("");

  const handleLinkClick = (event, message) => {
    if (message === "inicio") {
      nav("/");
    }

    console.log("Link clickeado");
    console.log(event.currentTarget);
    console.log(message);
  };

  return (
    <div className="bg-img bg-div">
      <ThemeProvider theme={theme}>
        <Container component="main">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 6,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              display="flex"
              component="img"
              alt="logo"
              src={logo}
              height="80px"
              width="270px"
              mb={4}
            ></Box>
            <Typography align="center" component="h1" variant="h5">
              <CheckCircleOutlineOutlinedIcon
                color="success"
                style={{ fontSize: 65 }}
              />
            </Typography>
            <Typography component="h1" variant="h4">
              ¡Registro Exitoso!
            </Typography>
            <Box mt={5}>
              <Typography align="center" component="h1" variant="h5">
                Tu solicitud de registro en nuestra plataforma fue recibida
                exitosamente.
              </Typography>
              <Typography align="center" mt={1} component="h1" variant="h5">
                Te hemos enviado un <b>correo electrónico</b>, con los pasos a
                seguir para activar tu cuenta.
              </Typography>
              <Typography align="center" mt={5} variant="h6">
                <InfoOutlinedIcon sx={{ mr: 1 }} />
                <i>
                  Si no has recibido el correo, revisa tu bandeja de
                  <b>correo no deseado</b>.
                </i>
              </Typography>
              <Typography align="center" mt={6} component="h1" variant="h5">
                <b>¡Agradecemos tu interés en ser parte de nuestro equipo!</b>
              </Typography>
            </Box>
            <Box component="form" noValidate sx={{ mt: 3 }}>
              <Button
                fullWidth
                variant="contained"
                size="large"
                sx={{ mt: 4, mb: 2, bgcolor: "teal" }}
                onClick={(event) => handleLinkClick(event, "inicio")}
              >
                Ir al inicio
              </Button>
              <Grid container justifyContent="center">
                <Grid item></Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </div>
  );
}
