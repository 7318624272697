import React, { Fragment, useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  Line,
  Svg,
  StyleSheet,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import logo from "assets/paluz-logo.png";

// Create styles
const styles = StyleSheet.create({
  section: {
    margin: 10,
    padding: 10,
    width: 200,
    "@media max-width: 400": {
      width: 300,
    },
    "@media orientation: landscape": {
      width: 400,
    },
  },
  section2: {
    paddingRight: 40,
    paddingLeft: 30,
    width: 600,
  },
  titulo: {
    textAlign: "center",
    fontSize: 16,
    fontWeight: "extrabold",
    textDecoration: "underline",
    marginTop: 20,
    marginBottom: 10,
  },
  textBody: {
    margin: 12,
    fontSize: 13,
    width: "98%",
    textAlign: "justify",
    paddingLeft: 30,
    paddingRight: 40,
    lineHeight: 1.5,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  line: {
    x1: "0", //starting coords are x1 and y1
    y1: "0",
    x2: "600", //ending coords:
    y2: "0",
    strokeWidth: 8,
    stroke: "teal", //stroke color
  },
  lineFirma: {
    x1: "0", //starting coords are x1 and y1
    y1: "0",
    x2: "600", //ending coords:
    y2: "0",
    strokeWidth: 1,
    stroke: "black", //stroke color
  },
  table: {
    width: "86%",
    border: "2px solid black",
    margin: 12,
    textAlign: "center",
    alignSelf: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    fontSize: 11,
    borderTop: "1px solid black",
    //paddingTop: 8,
    //paddingBottom: 8,
  },
  header: {
    borderTop: "none",
    fontWeight: "bold",
    fontSize: 13,
    //backgroundColor: "#D3D3D3",
  },
  bold: {
    fontWeight: "bold",
  },
  col1: {
    width: "25%",
    borderRight: "2px solid black",
    paddingTop: 8,
    paddingBottom: 8,
  },
  col2: {
    width: "25%",
    borderRight: "2px solid black",
    paddingTop: 8,
    paddingBottom: 8,
  },
  col3: {
    width: "25%",
    borderRight: "2px solid black",
    paddingTop: 8,
    paddingBottom: 8,
  },
  col4: {
    width: "25%",
    paddingTop: 8,
    paddingBottom: 8,
  },
  /*col5: {
    width: "20%",
    paddingTop: 8,
    paddingBottom: 8,
  },*/
  voluntario: {
    textAlign: "center",
    fontSize: 13,
    fontWeight: "extrabold",
    marginTop: 50,
  },
  cedulaVoluntario: {
    textAlign: "center",
    fontSize: 13,
    fontWeight: "extrabold",
  },
  textDespedida: {
    fontSize: 13,
    paddingLeft: 30,
    paddingRight: 40,
    marginTop: 30,
  },
  textFirmas: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignContent: "center",
    flexWrap: "nowrap",
    alignItems: "center",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 35,
    fontSize: 13,
    marginTop: 60,
    textAlign: "center",
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 0,
    right: 0,
    paddingRight: 40,
    paddingLeft: 30,
    textAlign: "center",
    fontSize: 10,
  },
});

// Create Document Component
function TestPDF() {
  const data = [
    {
      periodoIncentivo: "31/01/2024",
      fechaEjecucion: "15/05/2024",
      numRecibo: "0001",
      monto: "300",
    },
    {
      periodoIncentivo: "29/02/2024",
      fechaEjecucion: "15/05/2024",
      numRecibo: "0001",
      monto: "300",
    },
    {
      periodoIncentivo: "30/03/2024",
      fechaEjecucion: "15/05/2024",
      numRecibo: "0001",
      monto: "300",
    },
  ];

  return (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document*/}
      <Document>
        {/*render a single page*/}
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Image src={logo} />
          </View>
          <View style={styles.section2}>
            <Svg width={"100%"} height={"20px"}>
              <Line style={styles.line} />
            </Svg>
          </View>
          <View style={styles.titulo}>
            <Text>Reporte del Proyecto</Text>
          </View>
          <View>
            <Text style={styles.textBody}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis illo
              perferendis nemo enim, quaerat cum saepe corrupti modi aliquam,
              molestias totam voluptatum incidunt excepturi, exercitationem
              neque deleniti architecto. Cum, totam.Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Quis illo perferendis nemo enim,
              quaerat cum saepe corrupti modi aliquam, molestias totam
              voluptatum incidunt excepturi, exercitationem neque deleniti
              architecto. Cum, totam.
            </Text>
            <Text style={styles.textBody}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis illo
              perferendis nemo enim, quaerat cum saepe corrupti modi aliquam,
              molestias totam voluptatum incidunt excepturi, exercitationem
              neque deleniti architecto. Cum, totam.Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Quis illo perferendis nemo enim,
              quaerat cum saepe corrupti modi aliquam, molestias totam
              voluptatum incidunt excepturi, exercitationem neque deleniti
              architecto. Cum, totam.
            </Text>
            <Text style={styles.textBody}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis illo
              perferendis nemo enim, quaerat cum saepe corrupti modi aliquam,
              molestias totam voluptatum incidunt excepturi, exercitationem
              neque deleniti architecto. Cum, totam.Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Quis illo perferendis nemo enim,
              quaerat cum saepe corrupti modi aliquam, molestias totam
              voluptatum incidunt excepturi, exercitationem neque deleniti
              architecto. Cum, totam.
            </Text>
          </View>
          <View style={styles.footer}>
            <Svg width={"100%"} height={"20px"}>
              <Line style={styles.line} />
            </Svg>
          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Image src={logo} />
          </View>
          <View style={styles.section2}>
            <Svg width={"100%"} height={"20px"}>
              <Line style={styles.line} />
            </Svg>
          </View>
          <View style={styles.titulo}>
            <Text>Recibo de incentivo</Text>
          </View>
          <View style={styles.table}>
            <View style={[styles.row, styles.bold, styles.header]}>
              <Text style={styles.col1}>Período de incentivo</Text>
              <Text style={styles.col2}>Fecha de ejecución</Text>
              <Text style={styles.col3}>Número de recibo</Text>
              <Text style={styles.col4}>Monto</Text>
            </View>
            {data.map((row, i) => (
              <View key={i} style={styles.row} wrap={false}>
                <Text style={styles.col1}>{row.periodoIncentivo}</Text>
                <Text style={styles.col2}>{row.fechaEjecucion}</Text>
                <Text style={styles.col3}>{row.numRecibo}</Text>
                <Text style={styles.col4}>${row.monto}</Text>
              </View>
            ))}
          </View>
          <Text style={styles.textBody}>
            Se ha pagado la cantidad de $1200, por el concepto de Incentivo a
            José Pirela a la cuenta personal FACEBANK de José Pirela
            correspondiente al mes de Enero, Febrero, Marzo, por actividades del
            <Text style={styles.bold}> PROYECTO VNZ/PCA202398/PD2023274.</Text>
          </Text>
          <Text style={styles.textBody}>Recibe:</Text>
          <View>
            <Text style={styles.voluntario}>José Pirela</Text>
            <Text style={styles.cedulaVoluntario}> C.I. 19.765.877 </Text>
          </View>
          <Text style={styles.textDespedida}>
            Sin más que agregar, atentamente
          </Text>
          <View style={styles.textFirmas}>
            <Text>Scarlet Morán {"\n"} Coordinadora de Gestión Humana</Text>
            <Text>Emelyn Suárez {"\n"} Coordinadora de Finanzas</Text>
          </View>
          <View style={styles.footer}>
            <Svg width={"100%"} height={"20px"}>
              <Line style={styles.line} />
            </Svg>
            <Text>PALUZ RIF J-411561673</Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}
export default TestPDF;
