import React from "react";
import { styled } from "@mui/material/styles";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useTheme, useMediaQuery, Alert } from "@mui/material";
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  Grid,
  Divider,
  TextField,
  Chip,
  Button,
  Tab,
  Tabs,
  Container,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  Tooltip,
  Backdrop,
  Avatar,
  Collapse,
} from "@mui/material";
import { IMaskInput } from "react-imask";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import GradeIcon from "@mui/icons-material/Grade";
import PersonIcon from "@mui/icons-material/Person";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import LockIcon from "@mui/icons-material/Lock";
import GestureIcon from "@mui/icons-material/Gesture";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import clienteAxios from "../../config/clienteAxios";
import DeleteIcon from "@mui/icons-material/Delete";
import StarsIcon from "@mui/icons-material/Stars";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import PregnantWomanIcon from "@mui/icons-material/PregnantWoman";
import BadgeIcon from "@mui/icons-material/Badge";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faJedi,
  faHandsBubbles,
  faBottleWater,
  faShirt,
  faBook,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFirstOrder,
  faGalacticSenate,
  faEmpire,
  faRebel,
  faGalacticRepublic,
} from "@fortawesome/free-brands-svg-icons";
import capIcon from "assets/cap-icon.png";
import {
  AccessTime,
  AirportShuttle,
  CalendarToday,
  Cancel,
  ContentPaste,
  Event,
  EventAvailable,
  EventBusy,
  EventBusyOutlined,
  EventOutlined,
  Face2,
  InfoOutlined,
  NoteAlt,
  Schedule,
  ScheduleOutlined,
  SentimentDissatisfiedOutlined,
  SentimentNeutral,
  SentimentVeryDissatisfied,
} from "@mui/icons-material";
import ProgressBar from "@ramonak/react-progress-bar";
import { text } from "@fortawesome/fontawesome-svg-core";
import logo from "../../assets/paluz-icon-2.png"

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

////////////////////////////// LINEAR PROGRESS //////////////////////////////////
const MIN = 0;
const MAX = 30;
// Function to normalise the values (MIN / MAX could be integrated)
const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN);

function LogrosLinearProgress(props) {
  const LogrosLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 400 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#00A19B" : "#00A19B",
    },
  }));

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "85%", mr: 2 }}>
        <LogrosLinearProgress
          variant="determinate"
          value={normalise(props.value)}
          sx={{ height: "25px", borderRadius: 3 }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="h3" color="text.secondary">
          <b>{`${Math.round(props.value)}/30`}</b>
        </Typography>
      </Box>
    </Box>
  );
}
///////////////////////////////////////////////////////////////////////////////

const MIN_LVL = 0;
const MAX_LVL = 25;

// Function to normalise the values (MIN / MAX could be integrated)
const normaliseLVL = (value) => ((value - MIN_LVL) * 100) / (MAX_LVL - MIN_LVL);

function BorderLinearProgress(props) {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 400 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1E90FF" : "#308fe8",
    },
  }));

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sm={7}>
        <Box sx={{ mr: 2 }}>
          <BorderLinearProgress
            variant="determinate"
            value={normaliseLVL(props.value)}
            sx={{ height: "20px", borderRadius: 3 }}
          />
        </Box>
      </Grid>
      <Grid item xs={5} sm={4}>
        <Box>
          <Typography color="text.secondary">
            Nivel:{" "}
            <b style={{ fontSize: 18 }}>{`${Math.round(props.value)}/25`}</b>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
/////////////////////////////////////////////////////////////////////////////////////////////////

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#000) 000-0000"
      definitions={{
        "#": /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        fontSize: "0.875rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const PerfilVoluntario = () => {
  const nav = useNavigate();
  //Theme
  const theme = useTheme();

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //////////////Totales//////////////////
  const [ninosAtendidos, setNinosAtendidos] = React.useState([]);
  const [adultosAtendidos, setAdultosAtendidos] = React.useState([]);
  const [convocatoriasAsignadas, setConvocatoriasAsignadas] = React.useState(
    []
  );
  const [convocatoriasAsistidas, setConvocatoriasAsistidas] = React.useState(
    []
  );
  const [refrigerios, setRefigerios] = React.useState([]);
  const [ninosMam, setNinosMam] = React.useState([]);
  const [ninosSam, setNinosSam] = React.useState([]);
  const [mel, setMel] = React.useState([]);
  const [kilometros, setKilometros] = React.useState([]);
  const [referidos, setReferidos] = React.useState([]);
  const getninosatendidos = async () => {
    try {
      const { data1 } = await axios
        .get("/getninosatendidos")
        .then(function (response) {
          // setAlerta({})
          let datos = response.data;

          setNinosAtendidos(datos);

          setEjecutar(false);
          console.log(response.data);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const getAdultosAtendidos = async () => {
    try {
      const { data1 } = await axios
        .get("/getadultosatendidos")
        .then(function (response) {
          // setAlerta({})
          let datos = response.data;

          setAdultosAtendidos(datos);

          setEjecutar(false);
          console.log(response.data);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const getConvocatoriasAsignadas = async () => {
    try {
      const { data1 } = await axios
        .get("/getconvocatoriasasignadas")
        .then(function (response) {
          // setAlerta({})
          let datos = response.data;

          setConvocatoriasAsignadas(datos);

          setEjecutar(false);
          console.log(response.data);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const getConvocatoriasAsistidas = async () => {
    try {
      const { data1 } = await axios
        .get("/getconvocatoriasasistidas")
        .then(function (response) {
          // setAlerta({})
          let datos = response.data;

          setConvocatoriasAsistidas(datos);

          setEjecutar(false);
          console.log(response.data);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const getNinosSam = async () => {
    try {
      const { data1 } = await axios
        .get("/getninossam")
        .then(function (response) {
          // setAlerta({})
          let datos = response.data;

          setNinosSam(datos);

          setEjecutar(false);
          console.log(response.data, "NIÑOS SAM");
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const getNinosMam = async () => {
    try {
      const { data1 } = await axios
        .get("/getninossam")
        .then(function (response) {
          // setAlerta({})
          let datos = response.data;

          setNinosMam(datos);

          setEjecutar(false);
          console.log(response.data, "NIÑOS MAM");
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const getMelAtendidas = async () => {
    try {
      const { data1 } = await axios
        .get("/getmelatendidas")
        .then(function (response) {
          // setAlerta({})
          let datos = response.data;

          setMel(datos);

          setEjecutar(false);
          console.log(response.data);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const getReferidos = async () => {
    try {
      const { data1 } = await axios
        .get("/getreferidos")
        .then(function (response) {
          // setAlerta({})
          let datos = response.data;

          setReferidos(datos);

          setEjecutar(false);
          console.log(response.data);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  //Tabs State
  const [items, setItems] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [valueOcupacion, setOcupacion] = React.useState("");
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
      //setOcupacion(12)
    }
  }, []);
  const [ejecutar, setEjecutar] = React.useState(true);

  const [tabsRanking, setTabsRanking] = React.useState(1);

  const handleTabsRanking = (event, newValue) => {
    setTabsRanking(newValue);
  };

  const [data, setData] = React.useState([]);
  const [valueName, setValueName] = useState("");
  const [valueApellido, setValueApellido] = useState("");
  const [valueDireccion, setValueDireccion] = useState("");
  const [valueCedula, setValueCedula] = useState("");
  const [valueMail, setValueMail] = useState("");
  const [valueAlergias, setValueAlergias] = useState("");
  const [valueEnfermedad, setValueEnfermedad] = useState("");
  const [valueContacto, setValueContacto] = useState("");
  const [valueParentezco, setValueParentezco] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firmaBlob, setFirmaBlob] = useState();
  const [alerta, setAlerta] = useState("");
  const [alertaF, setAlertaF] = useState("");

  const [convocatoriaAceptada, setVonvocatoriaAceptada] = useState("");
  const [convocatoriaRechazada, setVonvocatoriaRechazada] = useState("");
  const [convocatoriaCancelada, setVonvocatoriaCancelada] = useState("");
  const [convocatoriaConfirmada, setVonvocatoriaConfirmada] = useState("");
  const [convocatoriaInasistencias, setVonvocatoriaInasistencias] =
    useState("");
  const [convocatoriaRetrasos, setVonvocatoriaRetrasos] = useState("");
  const [ninosAtendido, setNinosAtendido] = useState("");
  const [diagSam, setDigSam] = useState("");
  const [diagman, setDiagMam] = useState("");
  const [melAtendida, setMelAtendida] = useState("");
  const [melBajoPeso, setMelBajoPeso] = useState("");
  const [referido, setReferido] = useState("");
  const [controlesAsistidos, setControlesAsistidos] = useState("");
  const [kilometrosRecorridos, setKilometrosRecorridos] = useState("");
  const [exp, setExp] = useState("");

  //alergias
  const [data1, setData1] = useState([{ alergia: "" }]);

  //enfermedad
  const [data2, setData2] = useState([{ enfBase: "" }]);
  const handleChangeOcupacion = (event) => {
    setOcupacion(event.target.value);
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const [emergencia, setEmergencia] = React.useState({
    textmask: "",
  });
  const [values, setValues] = React.useState({
    textmask: "",
  });

  const [niveles, setNiveles] = useState([]);
  const [expActual, setExpActual] = useState("");
  const [expMaxima, setExpMaxima] = useState("");
  const [nivelActual, setNivelActual] = useState(20);

  const datosPerfil = async () => {
    try {
      const { data1 } = await axios
        .get("/usaurioperfil/", {
          params: {
            email: items.usuario,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setEjecutar(false);
          let datos = response.data;
          setData(datos);
          console.log("Aqui -->",response.data);
          setValueName(response.data[0].nombres);
          setValueApellido(response.data[0].apellidos);
          setValueDireccion(response.data[0].direccion);
          setValueMail(response.data[0].email);
          setValueAlergias(response.data[0].alergias);
          setValueEnfermedad(response.data[0].enfermedad_base);
          setValueCedula(response.data[0].cedula);
          setValues({
            ...values,
            textmask: response.data[0].telefono,
          });
          setEmergencia({
            ...emergencia,
            textmask: response.data[0].telefono_emergencia,
          });
          setValueContacto(response.data[0].contacto_emergencia);
          setValueParentezco(response.data[0].parentezco);
          setGS(response.data[0].grupo_sanguineo);
          setOcupacion(Number(response.data[0].ocupacion));
          //let alergia = JSON.parse(response.data[0].alergias);
          //let enfermdad = JSON.parse(response.data[0].enfermedad_base);
          //setData1(alergia);
          //setData2(enfermdad);
          if (response.data[0].alergias != "") {
            let alergia = JSON.parse(response.data[0].alergias);
            setData1(alergia);
            if(alergia.length >0 && alergia[0].alergia !== ""){
              setActivar(false)
            }
          }
          if (response.data[0].enfermedad_base != "") {
            let enfermdad = JSON.parse(response.data[0].enfermedad_base);
            setData2(enfermdad);
            if(enfermdad.length >0 && enfermdad[0].enfBase){
              setActivar2(false)
            }
          }
          setFirmaBlob(response.data[0].firma);
          setVonvocatoriaAceptada(response.data[0].convocatoria_aceptada);
          setVonvocatoriaRechazada(response.data[0].convocatoria_rechazada);
          setVonvocatoriaCancelada(response.data[0].convocatoria_cancelada);
          setVonvocatoriaConfirmada(response.data[0].convocatoria_confirmada);
          setVonvocatoriaInasistencias(response.data[0].inasistencias);
          setVonvocatoriaRetrasos(response.data[0].retrasos);
          setNinosAtendido(response.data[0].ninos_atendidos);
          setDigSam(response.data[0].diag_sam);
          setDiagMam(response.data[0].diag_mam);
          setMelAtendida(response.data[0].mel_atendidas);
          setMelBajoPeso(response.data[0].mel_bajoPeso);
          setReferido(response.data[0].referidos);
          setControlesAsistidos(response.data[0].controles_asistidos);
          setKilometrosRecorridos(response.data[0].kilometros_recorridos);
          setExp(response.data[0].exp);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  useEffect(() => {
    for (let i = 0; i < niveles.length; i++) {
      console.log("paso");
      if (exp >= niveles[i].limite) {
        setProgressLVL(niveles[i].nivel);
        var j = i + 1;
        setExpActual(exp);
        setExpMaxima(niveles[j].limite);
      }
    }
  }, [data, niveles]);

  const nivel = async () => {
    try {
      const { data2 } = await axios
        .get("/niveles")
        .then(function (response) {
          // setAlerta({})
          console.log(response.data, "niveles");
          setNiveles(response.data);
          setEjecutar(false);

          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const [pro, setPro] = useState([]);

  const catPro = async () => {
    try {
      const { data2 } = await axios
        .get("/profesionescat")
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setPro(response.data);
          setEjecutar(false);

          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeTel = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeTelEmergencia = (event) => {
    setEmergencia({
      ...emergencia,
      [event.target.name]: event.target.value,
    });
  };

  //Alergias Inputs
  const [activar, setActivar] = useState(true);
  let contador = 0;

  const handleClick = () => {
    setData1([...data1, { alergia: "" }]);
    setActivar(true);
  };
  const handleChangeInput = (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...data1];
    onchangeVal[i][name] = value;
    setData(onchangeVal);

    for (let i = 0; i < data1.length; i++) {
      if (data1[i].alergia !== "") {
      } else {
        contador = contador + 1;
      }
      if (contador !== 0) {
        setActivar(true);
      } else {
        setActivar(false);
      }
    }
  };
  const handleDelete = (i) => {
    const deleteVal = [...data1];
    deleteVal.splice(i, 1);
    setData1(deleteVal);
  };

  //Enfermedad Base Inputs
  const [activar2, setActivar2] = useState(true);
  let contador2 = 0;

  const handleClickEnf = () => {
    setData2([...data2, { enfBase: "" }]);
    setActivar2(true);
  };
  const handleChangeInputEnf = (e, i) => {
    const { name, value } = e.target;
    const onchangeVal2 = [...data2];
    onchangeVal2[i][name] = value;
    setData2(onchangeVal2);

    for (let i = 0; i < data2.length; i++) {
      if (data2[i].enfBase !== "") {
      } else {
        contador2 = contador2 + 1;
      }
      if (contador2 !== 0) {
        setActivar2(true);
      } else {
        setActivar2(false);
      }
    }
  };
  const handleDeleteEnf = (i) => {
    const deleteVal2 = [...data2];
    deleteVal2.splice(i, 1);
    setData2(deleteVal2);
  };

  const oracionFormato = (string) => {
    return string
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  //BreadCrumbs
  function handleClickBreadCrumbs(event) {
    event.preventDefault();
  }

  //Routes
  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };

  const [grupoSanguineo, setGS] = React.useState("");

  const handleChangeGS = (event) => {
    setGS(event.target.value);
  };

  //imagen
  const [image, setImage] = React.useState("");
  const convertirBase64 = (imagenes) => {
    var enc = new TextDecoder("utf-8");
    var arr = new Uint8Array(firmaBlob.data);
    console.log(enc.decode(arr));
    Array.from(imagenes).forEach((imagen) => {
      var reader = new FileReader();
      reader.readAsDataURL(imagen);
      reader.onload = function () {
        var array = [];
        var base64 = reader.result;
        setImage(base64);
        array = base64.split(",");
        console.log(array[1]);
      };
    });
  };
  const handleSubmitInfoPersonal = async () => {
    console.log(valueName);
    console.log(valueApellido);
    console.log(valueCedula);
    console.log(valueMail);
    console.log(valueOcupacion);
    console.log(values.textmask);
    console.log(valueDireccion);
    try {

        const { data1 } = await axios.put('/perfil/personal/', {
           nombre: valueName,
           apellido: valueApellido,
           cedula:valueCedula,
           ocupacion:valueOcupacion,
           telefono:values.textmask,
           direccion:valueDireccion,
           email:valueMail
        })
        .then(function (response) {
          setOpenNoti(true);
          setTimeout(() => {
            setOpenNoti(false);
          }, 2000);
         // setAlerta({})
          //console.log(response.data.idToken)
            //localStorage.setItem('token',JSON.stringify(response.data) )
            //setAuth(data)

        })
        .catch(function (error) {

          console.log('error')
         // document.getElementById(":r7:").value='';

        });

    } catch (error) {

    }
  };
  const handleSubmitInfoMedica = async () => {
    console.log(grupoSanguineo);
    console.log(valueContacto);
    console.log(emergencia.textmask);
    console.log(valueParentezco);
    let alerg =JSON.stringify(data1)
    let enferme =JSON.stringify(data2)
    console.log(valueMail)
    try {

        const { data1 } = await axios.put('/perfil/medica/', {
          grupo_sanguineo:grupoSanguineo,
          alergias:alerg,
          enfermedad_base:enferme,
          contacto_emergencia:valueContacto,
          telefono_emergencia:emergencia.textmask,
          parentezco:valueParentezco,
          email:valueMail
        })  
        .then(function (response) {
          setOpenNotiMed(true);
          setTimeout(() => {
            setOpenNotiMed(false);
          }, 2000);
          console.log(response.data)
            //localStorage.setItem('token',JSON.stringify(response.data) )
            //setAuth(data)

        })
        .catch(function (error) {

          console.log('error')
         // document.getElementById(":r7:").value='';

        });

    } catch (error) {

    }
  };
  const handleSubmitSeguridad = async () => {
    
    try {
      const { data1 } = await axios
        .put("/perfil/contra/", {
          password: password,
          email:valueMail
        })
        .then(function (response) {
          // setAlerta({})
          setOpenNotiPass(true);
          setTimeout(() => {
            setOpenNotiPass(false);
          }, 2000);
          //console.log(response.data.idToken)
          //localStorage.setItem('token',JSON.stringify(response.data) )
          //setAuth(data)
        })
        .catch(function (error) {
          console.log("error");
          // document.getElementById(":r7:").value='';
        });
    } catch (error) {}
  };
  const handleSubmitFirma = async () => {
    try {
      const { data1 } = await axios
        .put("/perfil/firma/", {
          correo: items.usuario,
          firma: image,
        })
        .then(function (response) {
          // setAlerta({})
          //console.log(response.data.idToken)
          //localStorage.setItem('token',JSON.stringify(response.data) )
          //setAuth(data)
        })
        .catch(function (error) {
          console.log("error");
          // document.getElementById(":r7:").value='';
        });
    } catch (error) {}
  };
  if (ejecutar) {
    datosPerfil();
    getninosatendidos();
    getAdultosAtendidos();
    getConvocatoriasAsignadas();
    getConvocatoriasAsistidas();
    getNinosMam();
    getNinosSam();
    getMelAtendidas();
    getReferidos();
    catPro();
    nivel();
  }

  //Buttons handlers
  const [editPerfilBtn, setEditPerfilBtn] = useState(false);
  const handleEditPerfilBtn = () => {
    setEditPerfilBtn(true);
    setLogrosBtn(false);
    setRewardsBtn(false);
    setStatsBtn(false);
  };

  const [logrosBtn, setLogrosBtn] = useState(false);
  const handleLogrosBtn = () => {
    setLogrosBtn(true);
    setEditPerfilBtn(false);
    setRewardsBtn(false);
    setStatsBtn(false);
    setValue(0);
  };

  const [rewardsBtn, setRewardsBtn] = useState(false);
  const handleRewardsBtn = () => {
    setRewardsBtn(true);
    setLogrosBtn(false);
    setEditPerfilBtn(false);
    setStatsBtn(false);
    setValue(0);
  };

  const [statsBtn, setStatsBtn] = useState(false);
  const handleStatsBtn = () => {
    setStatsBtn(true);
    setLogrosBtn(false);
    setEditPerfilBtn(false);
    setRewardsBtn(false);
    setValue(0);
  };

  ///////////////////////// LINEAR PROGRESS STATE ////////////////////////////////////////////////////////////////

  const [progress, setProgress] = React.useState(0);

  let tier = "";
  if (progress >= 1 && progress <= 4) {
    tier = "piedra";
  } else {
    if (progress >= 5 && progress <= 9) {
      tier = "bronce";
    } else {
      if (progress >= 10 && progress <= 19) {
        tier = "plata";
      } else {
        if (progress >= 20 && progress <= 29) {
          tier = "oro";
        } else {
          if (progress >= 30) {
            tier = "diamante";
          }
        }
      }
    }
  }

  const [progressAceptadas, setProgressAceptadas] = React.useState(0);

  let tierAceptadas = "";
  if (progressAceptadas >= 1 && progressAceptadas <= 4) {
    tierAceptadas = "piedra";
  } else {
    if (progressAceptadas >= 5 && progressAceptadas <= 9) {
      tierAceptadas = "bronce";
    } else {
      if (progressAceptadas >= 10 && progressAceptadas <= 19) {
        tierAceptadas = "plata";
      } else {
        if (progressAceptadas >= 20 && progressAceptadas <= 29) {
          tierAceptadas = "oro";
        } else {
          if (progressAceptadas >= 30) {
            tierAceptadas = "diamante";
          }
        }
      }
    }
  }

  const [progressInasistencias, setProgressInasistencias] = React.useState(0);

  let tierInasistencias = "";
  if (progressInasistencias >= 11 && progressInasistencias <= 15) {
    tierInasistencias = "piedra";
  } else {
    if (progressInasistencias >= 8 && progressInasistencias <= 10) {
      tierInasistencias = "bronce";
    } else {
      if (progressInasistencias >= 4 && progressInasistencias <= 7) {
        tierInasistencias = "plata";
      } else {
        if (progressInasistencias >= 1 && progressInasistencias <= 3) {
          tierInasistencias = "oro";
        } else {
          if (progressInasistencias == 0) {
            tierInasistencias = "diamante";
          }
        }
      }
    }
  }

  const [progressRetrasos, setProgressRetrasos] = React.useState(0);

  let tierRetrasos = "";
  if (progressRetrasos >= 11 && progressRetrasos <= 15) {
    tierRetrasos = "piedra";
  } else {
    if (progressRetrasos >= 8 && progressRetrasos <= 10) {
      tierRetrasos = "bronce";
    } else {
      if (progressRetrasos >= 4 && progressRetrasos <= 7) {
        tierRetrasos = "plata";
      } else {
        if (progressRetrasos >= 1 && progressRetrasos <= 3) {
          tierRetrasos = "oro";
        } else {
          if (progressRetrasos == 0) {
            tierRetrasos = "diamante";
          }
        }
      }
    }
  }

  const [progressLVL, setProgressLVL] = React.useState("");

  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////// ALERTS HANDLERS //////////////////////////////////////////

  const [openNoti, setOpenNoti] = React.useState(false);
  const [openNotiMed, setOpenNotiMed] = React.useState(false);
  const [openNotiPass, setOpenNotiPass] = React.useState(false);
  const [openNotiError, setOpenNotiError] = React.useState(false);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [progressCircular, setProgressCircular] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgressCircular((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  if (!data) {
    return (
      <Container component="main" maxWidth="xs">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }

  return (
    <Box m="1.5rem 2.5rem">
      {/*  ///////////////////////////////////////////////  ALERTAS  ////////////////////////////////////////////////  */}
      <Collapse in={openNoti}>
        <Alert
          variant="filled"
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenNoti(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{
            position: "fixed",
            right: 12,
            zIndex: 999,
            borderRadius: 2,
            boxShadow: 4,
          }}
        >
          ¡Datos actualizados exitosamente!
        </Alert>
      </Collapse>
      <Collapse in={openNotiMed}>
        <Alert
          variant="filled"
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenNotiMed(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{
            position: "fixed",
            right: 12,
            zIndex: 999,
            borderRadius: 2,
            boxShadow: 4,
          }}
        >
          ¡Info. Médica actualizada exitosamente!
        </Alert>
      </Collapse>
      <Collapse in={openNotiPass}>
        <Alert
          variant="filled"
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenNotiPass(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{
            position: "fixed",
            right: 12,
            zIndex: 999,
            borderRadius: 2,
            boxShadow: 4,
          }}
        >
          ¡Contraseña actualizada exitosamente!
        </Alert>
      </Collapse>
      <Collapse in={openNotiError}>
        <Alert
          variant="filled"
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenNotiError(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{
            position: "fixed",
            right: 12,
            zIndex: 999,
            borderRadius: 2,
            boxShadow: 4,
          }}
        >
          ¡Error al guardar datos!
        </Alert>
      </Collapse>
      {/*  //////////////////////////////////////////////////////////////////////////////////////////////////////////  */}

      <Box role="presentation" onClick={handleClickBreadCrumbs} sx={{ mb: 3 }}>
        <Breadcrumbs>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            href={"/inicio"}
            onClick={(event) => handleLinkHome(event, "home")}
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Inicio
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <AccountCircleIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Perfil
          </Typography>
        </Breadcrumbs>
        <Typography
          variant="h3"
          sx={{ mt: 3, mb: 1 }}
          hidden={
            editPerfilBtn == true ||
            logrosBtn == true ||
            rewardsBtn == true ||
            statsBtn == true
          }
        >
          <b>Perfil:</b> {items.nombre + " " + items.apellido}
        </Typography>
        <Typography
          variant="h3"
          sx={{ mt: 3, mb: 1 }}
          hidden={
            logrosBtn == true ||
            rewardsBtn == true ||
            statsBtn == true ||
            editPerfilBtn == false
          }
        >
          <b>Editar Perfil:</b> {items.nombre + " " + items.apellido}
        </Typography>
        <Typography
          variant="h3"
          sx={{ mt: 3, mb: 1 }}
          hidden={
            editPerfilBtn == true ||
            rewardsBtn == true ||
            statsBtn == true ||
            logrosBtn == false
          }
        >
          <b>Logros:</b> {items.nombre + " " + items.apellido}
        </Typography>
        <Typography
          variant="h3"
          sx={{ mt: 3, mb: 1 }}
          hidden={
            editPerfilBtn == true ||
            logrosBtn == true ||
            statsBtn == true ||
            rewardsBtn == false
          }
        >
          <b>Recompensas:</b> {items.nombre + " " + items.apellido}
        </Typography>
        <Typography
          variant="h3"
          sx={{ mt: 3, mb: 1 }}
          hidden={
            editPerfilBtn == true ||
            logrosBtn == true ||
            rewardsBtn == true ||
            statsBtn == false
          }
        >
          <b>Estadísticas:</b> {items.nombre + " " + items.apellido}
        </Typography>
      </Box>
      <Box
        mt="10px"
        display="grid"
        gridTemplateColumns="repeat(8, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": {
            gridColumn: isNonMediumScreens ? undefined : "span 12",
          },
        }}
      >
        <Box
          gridColumn="span 3"
          gridRow={
            logrosBtn == false
              ? "span 3"
              : logrosBtn == true && isSmallScreen
              ? "span 5"
              : logrosBtn == true && isNonMediumScreens == false
              ? "span 4"
              : rewardsBtn == true && isSmallScreen
              ? "span 4"
              : statsBtn == true && isSmallScreen
              ? "span 4"
              : "span 3"
          }
          p="1.5rem"
          borderRadius="1.5rem"
          mt="10px"
          height="fit-content"
        >
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Card
                sx={{
                  borderRadius: 4,
                  boxShadow: 4,
                  bgcolor: theme.palette.background.alt,
                }}
              >
                <CardContent>
                  <Grid container mb={3} justifyContent="center">
                    <Grid item sm={3}>
                      <Avatar
                      src={logo}
                        sx={{
                          width: 130,
                          height: 130,
                          mt: 2,
                          color: "black",
                        }}
                      />
                    </Grid>
                    {/*<Grid item sm={3}>
                      <Box mt={4}>
                        <Grid container justifyContent="center" spacing={2}>
                          <Grid item sm={4}>
                            <Tooltip title="Título">
                              <FontAwesomeIcon icon={faJedi} fontSize={30} />
                            </Tooltip>
                          </Grid>
                          <Grid item sm={4}>
                            <Tooltip title="Título">
                              <FontAwesomeIcon
                                icon={faFirstOrder}
                                fontSize={30}
                              />
                            </Tooltip>
                          </Grid>
                          <Grid item sm={4}>
                            <Tooltip title="Título">
                              <FontAwesomeIcon icon={faEmpire} fontSize={30} />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box mt={3}>
                        <Grid container justifyContent="center" spacing={2}>
                          <Grid item sm={4}>
                            <Tooltip title="Título">
                              <FontAwesomeIcon icon={faRebel} fontSize={30} />
                            </Tooltip>
                          </Grid>
                          <Grid item sm={4}>
                            <Tooltip title="Título">
                              <FontAwesomeIcon
                                icon={faGalacticSenate}
                                fontSize={30}
                              />
                            </Tooltip>
                          </Grid>
                          <Grid item sm={4}>
                            <Tooltip title="Título">
                              <FontAwesomeIcon
                                icon={faGalacticRepublic}
                                fontSize={30}
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>*/}
                    <Grid item xs={12} sm={10}>
                      <Box mt={3}>
                        <Grid container justifyContent="center" spacing={isSmallScreen ? 2 : 5}>
                          <Grid item xs={8} sm={9}>
                            <ProgressBar
                              bgColor="#00a19b"
                              height="30px"
                              baseBgColor="lightgrey"
                              labelColor="#fff7f7"
                              completed={`${expActual}`}
                              maxCompleted={`${expMaxima}`}
                              animateOnRender={true}
                            />
                          </Grid>
                          <Grid item xs={4} sm={3}>
                            <Chip
                              label={`Niv. ${progressLVL}`}
                              sx={{
                                fontWeight: "bold",
                                fontSize: 14,
                                bgcolor: "teal",
                                color: "white",
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container justifyContent="center" mt={1}>
                          <Grid item xs={10} sm={8}>
                            <Typography
                              color="text.secondary"
                              sx={{ fontSize: 12 }}
                            >
                              <b>Siguiente Nivel: {expMaxima} Exp.</b>  
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    container
                    justifyContent="center"
                    mt={isSmallScreen ? 1 : 3}
                    spacing={2}
                  >
                    <Grid item xs={6} sm={5}>
                      <Button
                        variant="contained"
                        size={isSmallScreen ? "small" : "large"}
                        sx={{ bgcolor: "teal", color: "white" }}
                        fullWidth
                        onClick={handleEditPerfilBtn}
                        disabled={editPerfilBtn == true}
                      >
                        <ManageAccountsIcon
                          sx={{ mr: 1 }}
                          hidden={isSmallScreen}
                        />{" "}
                        Editar perfil
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={5}>
                      <Button
                        variant="contained"
                        size={isSmallScreen ? "small" : "large"}
                        sx={{ bgcolor: "teal", color: "white" }}
                        fullWidth
                        onClick={handleLogrosBtn}
                        disabled={logrosBtn == true}
                      >
                        <EmojiEventsIcon
                          sx={{ mr: 1 }}
                          hidden={isSmallScreen}
                        />{" "}
                        Logros
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    mt={1}
                    mb={1}
                    spacing={2}
                  >
                    <Grid item xs={6} sm={5}>
                      <Button
                        variant="contained"
                        size={isSmallScreen ? "small" : "large"}
                        sx={{ bgcolor: "teal", color: "white" }}
                        fullWidth
                        onClick={handleStatsBtn}
                        disabled={statsBtn == true}
                      >
                        <LeaderboardIcon
                          sx={{ mr: 1 }}
                          hidden={isSmallScreen}
                        />{" "}
                        Estadísticas
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={5}>
                      <Button
                        variant="contained"
                        size={isSmallScreen ? "small" : "large"}
                        sx={{ bgcolor: "teal", color: "white" }}
                        fullWidth
                        onClick={handleRewardsBtn}
                        disabled={rewardsBtn == true}
                      >
                        <GradeIcon sx={{ mr: 1 }} hidden={isSmallScreen} />{" "}
                        Recompensas
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {/* /////////////////////////////////// CAJA DE INFO. EDITAR PERFIL ////////////////////////////////////////// */}
            <Grid item xs={12} sm={12} hidden={editPerfilBtn == false || isSmallScreen}>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: theme.palette.background.alt,
                  boxShadow: 4,
                  borderRadius: 4,
                }}
              >
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 45,
                          height: 45,
                        }}
                      >
                        <PersonIcon sx={{ fontSize: 25, color: "white" }} hidden={value != 0}/>
                        <MedicalServicesIcon sx={{ fontSize: 25, color: "white" }} hidden={value != 1} />
                        <LockIcon sx={{ fontSize: 25, color: "white" }} hidden={value != 2} />
                      </Avatar>
                    }
                    //title="INFO. PERSONAL"
                    title={ value == 0 ? "INFO. PERSONAL" : value == 1 ? "INFO. MÉDICA" : "SEGURIDAD" }
                    subheader={ value == 0 ? "Aquí puedes actualizar toda tu información personal." : value == 1 ? "Aquí puedes actualizar toda tu información médica y contacto de emergencia." : "Aquí puedes cambiar tu contraseña." }
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                    subheaderTypographyProps={{
                      fontSize: 14,
                    }}
                  />
                </Card>
              </Box>
            </Grid>
            {/* /////////////////////////////////// RANKING LOGROS ////////////////////////////////////////// */}
            <Grid item xs={12} sm={12} hidden={logrosBtn == false}>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: theme.palette.background.alt,
                  boxShadow: 4,
                  borderRadius: 4,
                }}
              >
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={tabsRanking}
                    onChange={handleTabsRanking}
                    variant={isSmallScreen ? "scrollable" : "fullWidth"}
                    scrollButtons
                    allowScrollButtonsMobile
                  >
                    <Tab
                      icon={<StarsIcon />}
                      label="Ranking General"
                      {...a11yProps(0)}
                      hidden
                    />
                    <Tab
                      icon={<ChildCareIcon />}
                      label="Ranking Menores de 5 Años"
                      {...a11yProps(1)}
                    />
                    <Tab
                      icon={<PregnantWomanIcon />}
                      label="Ranking MEL"
                      {...a11yProps(2)}
                    />
                    <Tab
                      icon={<CheckCircleIcon />}
                      label="Convocatorias Asistidas"
                      {...a11yProps(3)}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel value={tabsRanking} index={0}>
                  <Box
                    height={isSmallScreen ? "100%" : "105px"}
                    className="scrollTest"
                  >
                    <Card
                      sx={{
                        backgroundColor: "transparent",
                        boxShadow: 0,
                        borderRadius: 0,
                      }}
                    >
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{
                              bgcolor: "goldenRod",
                              width: 35,
                              height: 35,
                              border: "1px solid black",
                            }}
                          >
                            1
                          </Avatar>
                        }
                        title="Nombre Apellido"
                        subheader="Descripción"
                        titleTypographyProps={{
                          fontWeight: "bold",
                          fontSize: 14,
                        }}
                      />
                      <Divider />
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{
                              bgcolor: "LightSteelBlue",
                              width: 35,
                              height: 35,
                              border: "1px solid black",
                            }}
                          >
                            2
                          </Avatar>
                        }
                        title="Nombre Apellido"
                        subheader="Descripción"
                        titleTypographyProps={{
                          fontWeight: "bold",
                          fontSize: 14,
                        }}
                      />
                      <Divider />
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{
                              bgcolor: "teal",
                              width: 35,
                              height: 35,
                              border: "1px solid black",
                            }}
                          >
                            3
                          </Avatar>
                        }
                        title="Nombre Apellido"
                        subheader="Descripción"
                        titleTypographyProps={{
                          fontWeight: "bold",
                          fontSize: 14,
                        }}
                      />
                    </Card>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={tabsRanking} index={1}>
                  <Box
                    height={isSmallScreen ? "100%" : "105px"}
                    className="scrollTest"
                  >
                    <Card
                      sx={{
                        backgroundColor: "transparent",
                        boxShadow: 0,
                        borderRadius: 0,
                      }}
                    >
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{
                              bgcolor: "goldenRod",
                              width: 35,
                              height: 35,
                              border: "1px solid black",
                            }}
                          >
                            1
                          </Avatar>
                        }
                        title={
                          ninosAtendidos.length < 1
                            ? ""
                            : ninosAtendidos[0].medico
                        }
                        subheader={
                          ninosAtendidos.length < 1
                            ? ""
                            : ninosAtendidos[0].total + " Pacientes"
                        }
                        titleTypographyProps={{
                          fontWeight: "bold",
                          fontSize: 14,
                        }}
                      />
                      <Divider />
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{
                              bgcolor: "LightSteelBlue",
                              width: 35,
                              height: 35,
                              border: "1px solid black",
                            }}
                          >
                            2
                          </Avatar>
                        }
                        title={
                          ninosAtendidos.length < 1
                            ? ""
                            : ninosAtendidos[1].medico
                        }
                        subheader={
                          ninosAtendidos.length < 1
                            ? ""
                            : ninosAtendidos[1].total + " Pacientes"
                        }
                        titleTypographyProps={{
                          fontWeight: "bold",
                          fontSize: 14,
                        }}
                      />
                      <Divider />
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{
                              bgcolor: "teal",
                              width: 35,
                              height: 35,
                              border: "1px solid black",
                            }}
                          >
                            3
                          </Avatar>
                        }
                        title={
                          ninosAtendidos.length < 1
                            ? ""
                            : ninosAtendidos[2].medico
                        }
                        subheader={
                          ninosAtendidos.length < 1
                            ? ""
                            : ninosAtendidos[2].total + " Pacientes"
                        }
                        titleTypographyProps={{
                          fontWeight: "bold",
                          fontSize: 14,
                        }}
                      />
                    </Card>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={tabsRanking} index={2}>
                  <Box
                    height={isSmallScreen ? "100%" : "105px"}
                    className="scrollTest"
                  >
                    <Card
                      sx={{
                        backgroundColor: "transparent",
                        boxShadow: 0,
                        borderRadius: 0,
                      }}
                    >
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{
                              bgcolor: "goldenRod",
                              width: 35,
                              height: 35,
                              border: "1px solid black",
                            }}
                          >
                            1
                          </Avatar>
                        }
                        title={
                          adultosAtendidos.length < 1
                            ? ""
                            : adultosAtendidos[0].medico
                        }
                        subheader={
                          adultosAtendidos.length < 1
                            ? ""
                            : adultosAtendidos[0].total + " Pacientes"
                        }
                        titleTypographyProps={{
                          fontWeight: "bold",
                          fontSize: 14,
                        }}
                      />
                      <Divider />
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{
                              bgcolor: "LightSteelBlue",
                              width: 35,
                              height: 35,
                              border: "1px solid black",
                            }}
                          >
                            2
                          </Avatar>
                        }
                        title={
                          adultosAtendidos.length < 1
                            ? ""
                            : adultosAtendidos[1].medico
                        }
                        subheader={
                          adultosAtendidos.length < 1
                            ? ""
                            : adultosAtendidos[1].total + " Pacientes"
                        }
                        titleTypographyProps={{
                          fontWeight: "bold",
                          fontSize: 14,
                        }}
                      />
                      <Divider />
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{
                              bgcolor: "teal",
                              width: 35,
                              height: 35,
                              border: "1px solid black",
                            }}
                          >
                            3
                          </Avatar>
                        }
                        title={
                          adultosAtendidos.length < 1
                            ? ""
                            : adultosAtendidos[2].medico
                        }
                        subheader={
                          adultosAtendidos.length < 1
                            ? ""
                            : adultosAtendidos[2].total + " Pacientes"
                        }
                        titleTypographyProps={{
                          fontWeight: "bold",
                          fontSize: 14,
                        }}
                      />
                    </Card>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={tabsRanking} index={3}>
                  <Box
                    height={isSmallScreen ? "100%" : "105px"}
                    className="scrollTest"
                  >
                    <Card
                      sx={{
                        backgroundColor: "transparent",
                        boxShadow: 0,
                        borderRadius: 0,
                      }}
                    >
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{
                              bgcolor: "goldenRod",
                              width: 35,
                              height: 35,
                              border: "1px solid black",
                            }}
                          >
                            1
                          </Avatar>
                        }
                        title={
                          !convocatoriasAsistidas.asistio
                            ? ""
                            : convocatoriasAsistidas.asistio[0].nombres +
                              " " +
                              convocatoriasAsistidas.asistio[0].apellidos
                        }
                        subheader={
                          !convocatoriasAsistidas.asistio
                            ? ""
                            : convocatoriasAsistidas.asistio[0].total +
                              " Convocatorias"
                        }
                        titleTypographyProps={{
                          fontWeight: "bold",
                          fontSize: 14,
                        }}
                      />
                      <Divider />
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{
                              bgcolor: "LightSteelBlue",
                              width: 35,
                              height: 35,
                              border: "1px solid black",
                            }}
                          >
                            2
                          </Avatar>
                        }
                        title={
                          !convocatoriasAsistidas.asistio
                            ? ""
                            : convocatoriasAsistidas.asistio[1].nombres +
                              " " +
                              convocatoriasAsistidas.asistio[1].apellidos
                        }
                        subheader={
                          !convocatoriasAsistidas.asistio
                            ? ""
                            : convocatoriasAsistidas.asistio[1].total +
                              " Convocatorias"
                        }
                        titleTypographyProps={{
                          fontWeight: "bold",
                          fontSize: 14,
                        }}
                      />
                      <Divider />
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{
                              bgcolor: "teal",
                              width: 35,
                              height: 35,
                              border: "1px solid black",
                            }}
                          >
                            3
                          </Avatar>
                        }
                        title={
                          !convocatoriasAsistidas.asistio
                            ? ""
                            : convocatoriasAsistidas.asistio[2].nombres +
                              " " +
                              convocatoriasAsistidas.asistio[2].apellidos
                        }
                        subheader={
                          !convocatoriasAsistidas.asistio
                            ? ""
                            : convocatoriasAsistidas.asistio[2].total +
                              " Convocatorias"
                        }
                        titleTypographyProps={{
                          fontWeight: "bold",
                          fontSize: 14,
                        }}
                      />
                    </Card>
                  </Box>
                </CustomTabPanel>
              </Box>
            </Grid>
            {/* /////////////////////////////////// CAJA DE INFO. ESTADÍSTICAS ////////////////////////////////////////// */}
            <Grid item xs={12} sm={12} hidden={statsBtn == false || isSmallScreen}>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: theme.palette.background.alt,
                  boxShadow: 4,
                  borderRadius: 4,
                }}
              >
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 45,
                          height: 45,
                        }}
                      >
                        <InfoOutlined sx={{ fontSize: 25, color: "white" }} />
                      </Avatar>
                    }
                    //title="INFO. PERSONAL"
                    title="ESTADÍSTICAS"
                    subheader="En esta sección puedes ver todas tus estadísticas."
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                    subheaderTypographyProps={{
                      fontSize: 14,
                    }}
                  />
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          gridColumn="span 5"
          gridRow="span 3"
          backgroundColor={
            editPerfilBtn == false
              ? "transparent"
              : theme.palette.background.alt
          }
          p="1.5rem"
          borderRadius="1rem"
          mt={
            editPerfilBtn == false && rewardsBtn == false
              ? "10px"
              : rewardsBtn == true && isSmallScreen
              ? "65px"
              : rewardsBtn == true && isSmallScreen == false
              ? "10px"
              : "35px"
          }
          height="fit-content"
          sx={{
            boxShadow: editPerfilBtn == false ? 0 : 4,
          }}
        >
          {/* //////////////////// NADA SELECCIONADO ////////////// */}
          <Box
            sx={{ width: "100%" }}
            hidden={
              editPerfilBtn == true ||
              logrosBtn == true ||
              rewardsBtn == true ||
              statsBtn == true
            }
          >
            <Grid container justifyContent="center" mt={5}>
              <Grid item>
                <AccountCircleIcon sx={{ fontSize: 150, color: "gray" }} />
              </Grid>
            </Grid>
            <Grid container justifyContent="center" mt={4}>
              <Grid item>
                <Typography variant="h3" sx={{ color: "gray" }}>
                  Selecciona una opción para iniciar
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {/* /////////////////// EDITAR PERFIL ////////////////// */}
          <Box sx={{ width: "100%" }} hidden={editPerfilBtn == false}>
            <Box sx={{ borderBottom: 3, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant={isSmallScreen ? "scrollable" : "fullWidth"}
                scrollButtons
                allowScrollButtonsMobile
              >
                <Tab
                  icon={<PersonIcon />}
                  iconPosition="start"
                  label="Info. Personal"
                  {...a11yProps(0)}
                />
                <Tab
                  icon={<MedicalServicesIcon />}
                  iconPosition="start"
                  label="Info. Médica"
                  {...a11yProps(1)}
                />
                <Tab
                  icon={<LockIcon />}
                  iconPosition="start"
                  label="Seguridad"
                  {...a11yProps(2)}
                />
                <Tab
                  icon={<GestureIcon />}
                  iconPosition="start"
                  label="Firma Digital"
                  {...a11yProps(3)}
                  hidden
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Box component="form">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} mt={2}>
                    <TextField
                      label="Nombre"
                      fullWidth
                      value={valueName}
                      onChange={(event) =>
                        setValueName(oracionFormato(event.target.value))
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} mt={2}>
                    <TextField
                      label="Apellido"
                      fullWidth
                      value={valueApellido}
                      onChange={(event) =>
                        setValueApellido(oracionFormato(event.target.value))
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} mt={2}>
                    <TextField
                      type="number"
                      label="Cédula"
                      fullWidth
                      value={valueCedula}
                      onChange={(event) =>
                        setValueCedula(oracionFormato(event.target.value))
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} mt={3}>
                    {/*<TextField
                         label="Ocupación" 
                         fullWidth
                         value={valueOcupacion}
                         onChange={event => setValueOcupacion(oracionFormato(event.target.value))}
                         />*/}
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Ocupación
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={valueOcupacion}
                        label="Ocupación"
                        onChange={handleChangeOcupacion}
                      >
                        <MenuItem value="">
                          <em>Seleccione su Ocupación</em>
                        </MenuItem>

                        {/* {data.map(dato => {
                          return <ListSubheader> {dato.nombre} </ListSubheader>
                        } )} */}

                        {pro.map((dat, index) => (
                          <MenuItem value={dat.id_pro}>
                            {dat.descripcion}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3} mt={3}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="telefono" variant="outlined">
                        Teléfono
                      </InputLabel>
                      <OutlinedInput
                        name="textmask"
                        id="telefono"
                        inputComponent={TextMaskCustom}
                        label="Teléfono"
                        value={values.textmask}
                        onChange={handleChangeTel}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3} mt={3}>
                    <TextField
                      label="Correo Electrónico"
                      fullWidth
                      value={valueMail}
                      onChange={(event) => setValueMail(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} mt={3}>
                    <TextField
                      label="Dirección"
                      fullWidth
                      value={valueDireccion}
                      onChange={(event) =>
                        setValueDireccion(oracionFormato(event.target.value))
                      }
                    />
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 5, mb: 3 }} />
                <Grid
                  container
                  sx={{ mt: 2 }}
                  spacing={1}
                  justifyContent="center"
                >
                  {/*<Grid item sm={3} xs={6}>
                    <Button
                      variant="contained"
                      color="error"
                      size="large"
                      fullWidth
                    >
                      Cancelar
                    </Button>
                  </Grid>*/}
                  <Grid item sm={4} xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleSubmitInfoPersonal}
                      sx={{ bgcolor: "teal", color: "white" }}
                      size="large"
                      fullWidth
                    >
                      Guardar Info. Personal
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box component="form">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3} mt={2}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Grupo Sanguíneo
                      </InputLabel>
                      <Select
                        id="demo-simple-select"
                        value={grupoSanguineo}
                        label="Grupo Sanguíneo"
                        onChange={handleChangeGS}
                      >
                        <MenuItem value={"0+"}>O+</MenuItem>
                        <MenuItem value={"0-"}>O-</MenuItem>
                        <MenuItem value={"A+"}>A+</MenuItem>
                        <MenuItem value={"A-"}>A-</MenuItem>
                        <MenuItem value={"B+"}>B+</MenuItem>
                        <MenuItem value={"B-"}>B-</MenuItem>
                        <MenuItem value={"AB+"}>AB+</MenuItem>
                        <MenuItem value={"AB-"}>AB-</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3} mt={2}>
                    <TextField
                      label="Contacto de Emergencia"
                      fullWidth
                      value={valueContacto}
                      onChange={(event) =>
                        setValueContacto(oracionFormato(event.target.value))
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} mt={2}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="telEmergencia" variant="outlined">
                        Teléfono de Emergencia
                      </InputLabel>
                      <OutlinedInput
                        name="textmask"
                        id="telEmergencia"
                        inputComponent={TextMaskCustom}
                        label="Teléfono de emergencia"
                        value={emergencia.textmask}
                        onChange={handleChangeTelEmergencia}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3} mt={2}>
                    <TextField
                      label="Parentesco"
                      fullWidth
                      value={valueParentezco}
                      onChange={(event) =>
                        setValueParentezco(oracionFormato(event.target.value))
                      }
                    />
                  </Grid>
                </Grid>
                <div style={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: isSmallScreen ? "column" : "row",
                    }}
                  >
                    <Item sx={{ width: "100%" }}>
                      {data1.map((val, i) => (
                        <div>
                          <Grid container spacing={1} sx={{ mt: 1 }}>
                            <Grid item xs={10} sm={11}>
                              <TextField
                                label="Alergia"
                                name="alergia"
                                fullWidth
                                value={val.alergia}
                                onChange={(e) => handleChangeInput(e, i)}
                              />
                            </Grid>
                            <Grid item xs={2} sm={1}>
                              <Tooltip title="Eliminar">
                                <IconButton
                                  aria-label="delete"
                                  size="large"
                                  color="error"
                                  disabled={val.alergia == ""}
                                  //sx={{ bgcolor:'Crimson', color:'white' }}
                                  onClick={() => handleDelete(i)}
                                >
                                  <DeleteIcon fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </div>
                      ))}
                      <Button
                        disabled={activar}
                        variant="contained"
                        size="large"
                        sx={{
                          bgcolor: "teal",
                          color: "white",
                          borderRadius: 2,
                          mt: 1,
                        }}
                        onClick={handleClick}
                      >
                        Añadir Más
                      </Button>
                    </Item>
                    <Item sx={{ width: "100%" }}>
                      {data2.map((val2, i) => (
                        <div>
                          <Grid container spacing={1} sx={{ mt: 1 }}>
                            <Grid item xs={10} sm={11}>
                              <TextField
                                label="Enfermedad Base"
                                name="enfBase"
                                fullWidth
                                value={val2.enfBase}
                                onChange={(e) => handleChangeInputEnf(e, i)}
                              />
                            </Grid>
                            <Grid item xs={2} sm={1}>
                              <Tooltip title="Eliminar">
                                <IconButton
                                  aria-label="delete"
                                  size="large"
                                  color="error"
                                  disabled={val2.enfBase == ""}
                                  //sx={{ bgcolor:'Crimson', color:'white' }}
                                  onClick={() => handleDeleteEnf(i)}
                                >
                                  <DeleteIcon fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </div>
                      ))}
                      <Button
                        disabled={activar2}
                        variant="contained"
                        size="large"
                        sx={{
                          bgcolor: "teal",
                          color: "white",
                          borderRadius: 2,
                          mt: 1,
                        }}
                        onClick={handleClickEnf}
                      >
                        Añadir Más
                      </Button>
                    </Item>
                  </Box>
                </div>
                {/*<p>{JSON.stringify(data1)}</p>
           <p>{JSON.stringify(data2)}</p>*/}
                <Divider sx={{ mt: 3, mb: 3 }} />
                <Grid
                  container
                  sx={{ mt: 2 }}
                  spacing={1}
                  justifyContent="center"
                >
                  {/*<Grid item sm={3} xs={6}>
                    <Button
                      variant="contained"
                      color="error"
                      size="large"
                      fullWidth
                    >
                      Cancelar
                    </Button>
                  </Grid>*/}
                  <Grid item sm={3} xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleSubmitInfoMedica}
                      sx={{ bgcolor: "teal", color: "white" }}
                      size="large"
                      fullWidth
                    >
                      Guardar Info. Médica
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Box component="form">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="password"
                      name="password"
                      label="Nueva Contraseña"
                      type={showPassword ? "text" : "password"}
                      sx={{ mt: 3 }}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="current-password"
                      error={password !== confirmPassword}
                      helperText={password !== confirmPassword ? "Las contraseñas no coinciden" : ""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Cambiar visibilidad de la contraseña"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="confirmPass"
                      name="confirmPassword"
                      label="Confirmar Contraseña"
                      type={showConfirmPassword ? "text" : "password"}
                      sx={{ mt: 3 }}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      autoComplete="current-password"
                      error={password !== confirmPassword}
                      helperText={password !== confirmPassword ? "Las contraseñas no coinciden" : ""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Cambiar visibilidad de la contraseña"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownConfirmPassword}
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 5, mb: 3 }} />
                <Grid
                  container
                  sx={{ mt: 2 }}
                  spacing={1}
                  justifyContent="center"
                >
                  {/*<Grid item sm={3} xs={6}>
                    <Button
                      variant="contained"
                      color="error"
                      size="large"
                      fullWidth
                    >
                      Cancelar
                    </Button>
                  </Grid>*/}
                  <Grid item sm={3} xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleSubmitSeguridad}
                      sx={{ bgcolor: "teal", color: "white" }}
                      size="large"
                      disabled={password !== confirmPassword || password == "" || confirmPassword == ""}
                      fullWidth
                    >
                     Guardar Contraseña
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <Box component="form">
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} sm={3} mt={2} mb={2}>
                    <Button
                      variant="contained"
                      type="file"
                      onChange={(e) => convertirBase64(e.target.files)}
                      size="large"
                      sx={{ bgcolor: "dodgerblue", color: "white" }}
                      fullWidth
                    >
                      Cargar Firma
                    </Button>
                    <input
                      type="file"
                      onChange={(e) => convertirBase64(e.target.files)}
                    ></input>
                    <img src={`${image}`} />
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 3, mb: 3 }} />
                <Grid
                  container
                  sx={{ mt: 2 }}
                  spacing={1}
                  justifyContent="center"
                >
                  <Grid item sm={3} xs={6}>
                    <Button
                      variant="contained"
                      color="error"
                      size="large"
                      fullWidth
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item sm={3} xs={6}>
                    <Button
                      variant="contained"
                      sx={{ bgcolor: "teal", color: "white" }}
                      size="large"
                      fullWidth
                      onClick={handleSubmitFirma}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </CustomTabPanel>
          </Box>

          {/* ////////////////////// LOGROS /////////////////////// */}
          <Box
            sx={{ width: "100%" }}
            hidden={
              editPerfilBtn == true ||
              logrosBtn == false ||
              rewardsBtn == true ||
              progress !== 0 ||
              tierInasistencias !== ""
            }
          >
            <Grid container justifyContent="center" mt={5}>
              <Grid item>
                <SentimentDissatisfiedOutlined
                  sx={{ fontSize: 150, color: "gray" }}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center" mt={4}>
              <Grid item>
                <Typography variant="h3" sx={{ color: "gray" }}>
                  Aún no tienes logros para mostrar aquí
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {/* ////////////////////// LOGROS/CONVOCATORIAS /////////////////////// */}
          <Box
            sx={{ width: "100%" }}
            hidden={logrosBtn == false || progress == 0}
          >
            <Card
              sx={{
                backgroundColor: theme.palette.background.alt,
                boxShadow: 4,
                borderRadius: 4,
              }}
            >
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: "teal", width: 65, height: 65 }}>
                    <EventAvailable sx={{ fontSize: 35, color: "white" }} />
                  </Avatar>
                }
                title={
                  <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                    Convocatorias Asistidas
                    <Chip
                      label="Piedra"
                      sx={{
                        fontSize: isSmallScreen ? 8 : 12,
                        ml: 1,
                        bgcolor: "#D2691E",
                        color: "white",
                        border: "1px solid black",
                        boxShadow: 1,
                      }}
                      hidden={
                        tier == "bronce" ||
                        tier == "plata" ||
                        tier == "oro" ||
                        tier == "diamante"
                      }
                    />
                    <Chip
                      label="Bronce"
                      sx={{
                        fontSize: isSmallScreen ? 8 : 12,
                        ml: 1,
                        bgcolor: "#B8860B",
                        border: "1px solid",
                        boxShadow: 1,
                      }}
                      hidden={
                        tier == "piedra" ||
                        tier == "plata" ||
                        tier == "oro" ||
                        tier == "diamante"
                      }
                    />
                    <Chip
                      label="Plata"
                      sx={{
                        fontSize: isSmallScreen ? 8 : 12,
                        ml: 1,
                        bgcolor: "#A9A9A9",
                        border: "1px solid",
                        boxShadow: 1,
                      }}
                      hidden={
                        tier == "piedra" ||
                        tier == "bronce" ||
                        tier == "oro" ||
                        tier == "diamante"
                      }
                    />
                    <Chip
                      label="Oro"
                      sx={{
                        fontSize: isSmallScreen ? 8 : 12,
                        ml: 1,
                        bgcolor: "#DAA520",
                        border: "1px solid",
                        boxShadow: 1,
                      }}
                      hidden={
                        tier == "piedra" ||
                        tier == "bronce" ||
                        tier == "plata" ||
                        tier == "diamante"
                      }
                    />
                    <Chip
                      label="Diamante"
                      sx={{
                        fontSize: isSmallScreen ? 8 : 12,
                        ml: 1,
                        bgcolor: "#F5DEB3",
                        border: "1px solid",
                        boxShadow: 3,
                      }}
                      hidden={
                        tier == "piedra" ||
                        tier == "bronce" ||
                        tier == "plata" ||
                        tier == "oro"
                      }
                    />
                  </Typography>
                }
                //subheader="September 14, 2016"
                titleTypographyProps={{
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              />
              <CardContent>
                <LogrosLinearProgress
                  sx={{ height: "10px", borderRadius: 3 }}
                  value={progress}
                />
                <Typography mt={3} variant="body1" color="text.secondary">
                  <b>Descripción del logro:</b> Cantidad de convocatorias
                  asistidas.
                </Typography>
              </CardContent>
            </Card>
          </Box>

          <Box
            sx={{ width: "100%" }}
            hidden={logrosBtn == false || progressAceptadas == 0}
            mt={progress == 0 ? 0 : 2}
          >
            <Card
              sx={{
                backgroundColor: theme.palette.background.alt,
                boxShadow: 4,
                borderRadius: 4,
              }}
            >
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: "teal", width: 65, height: 65 }}>
                    <EventOutlined sx={{ fontSize: 35, color: "white" }} />
                  </Avatar>
                }
                title={
                  <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                    Convocatorias Aceptadas
                    <Chip
                      label="Piedra"
                      sx={{
                        fontSize: isSmallScreen ? 8 : 12,
                        ml: 1,
                        bgcolor: "#D2691E",
                        color: "white",
                        border: "1px solid black",
                        boxShadow: 1,
                      }}
                      hidden={
                        tierAceptadas == "bronce" ||
                        tierAceptadas == "plata" ||
                        tierAceptadas == "oro" ||
                        tierAceptadas == "diamante"
                      }
                    />
                    <Chip
                      label="Bronce"
                      sx={{
                        fontSize: isSmallScreen ? 8 : 12,
                        ml: 1,
                        bgcolor: "#B8860B",
                        border: "1px solid",
                        boxShadow: 1,
                      }}
                      hidden={
                        tierAceptadas == "piedra" ||
                        tierAceptadas == "plata" ||
                        tierAceptadas == "oro" ||
                        tierAceptadas == "diamante"
                      }
                    />
                    <Chip
                      label="Plata"
                      sx={{
                        fontSize: isSmallScreen ? 8 : 12,
                        ml: 1,
                        bgcolor: "#A9A9A9",
                        border: "1px solid",
                        boxShadow: 1,
                      }}
                      hidden={
                        tierAceptadas == "piedra" ||
                        tierAceptadas == "bronce" ||
                        tierAceptadas == "oro" ||
                        tierAceptadas == "diamante"
                      }
                    />
                    <Chip
                      label="Oro"
                      sx={{
                        fontSize: isSmallScreen ? 8 : 12,
                        ml: 1,
                        bgcolor: "#DAA520",
                        border: "1px solid",
                        boxShadow: 1,
                      }}
                      hidden={
                        tierAceptadas == "piedra" ||
                        tierAceptadas == "bronce" ||
                        tierAceptadas == "plata" ||
                        tierAceptadas == "diamante"
                      }
                    />
                    <Chip
                      label="Diamante"
                      sx={{
                        fontSize: isSmallScreen ? 8 : 12,
                        ml: 1,
                        bgcolor: "#F5DEB3",
                        border: "1px solid",
                        boxShadow: 3,
                      }}
                      hidden={
                        tierAceptadas == "piedra" ||
                        tierAceptadas == "bronce" ||
                        tierAceptadas == "plata" ||
                        tierAceptadas == "oro"
                      }
                    />
                  </Typography>
                }
                //subheader="September 14, 2016"
                titleTypographyProps={{
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              />
              <CardContent>
                <LogrosLinearProgress
                  sx={{ height: "10px", borderRadius: 3 }}
                  value={progressAceptadas}
                />
                <Typography mt={3} variant="body1" color="text.secondary">
                  <b>Descripción del logro:</b> Cantidad de convocatorias
                  aceptadas.
                </Typography>
              </CardContent>
            </Card>
          </Box>

          <Box sx={{ width: "100%" }} hidden={logrosBtn == false} mt={progressAceptadas == 0 && progress == 0 ? 0 : 2}>
            <Card
              sx={{
                backgroundColor: theme.palette.background.alt,
                boxShadow: 4,
                borderRadius: 4,
              }}
            >
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: "teal", width: 65, height: 65 }}>
                    <EventBusyOutlined sx={{ fontSize: 35, color: "white" }} />
                  </Avatar>
                }
                title={
                  <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                    Convocatorias con Inasistencias
                    <Chip
                      label="Piedra"
                      sx={{
                        fontSize: isSmallScreen ? 8 : 12,
                        ml: 1,
                        bgcolor: "#D2691E",
                        color: "white",
                        border: "1px solid black",
                        boxShadow: 1,
                      }}
                      hidden={
                        tierInasistencias == "bronce" ||
                        tierInasistencias == "plata" ||
                        tierInasistencias == "oro" ||
                        tierInasistencias == "diamante"
                      }
                    />
                    <Chip
                      label="Bronce"
                      sx={{
                        fontSize: isSmallScreen ? 8 : 12,
                        ml: 1,
                        bgcolor: "#B8860B",
                        border: "1px solid",
                        boxShadow: 1,
                      }}
                      hidden={
                        tierInasistencias == "piedra" ||
                        tierInasistencias == "plata" ||
                        tierInasistencias == "oro" ||
                        tierInasistencias == "diamante"
                      }
                    />
                    <Chip
                      label="Plata"
                      sx={{
                        fontSize: isSmallScreen ? 8 : 12,
                        ml: 1,
                        bgcolor: "#A9A9A9",
                        border: "1px solid",
                        boxShadow: 1,
                      }}
                      hidden={
                        tierInasistencias == "piedra" ||
                        tierInasistencias == "bronce" ||
                        tierInasistencias == "oro" ||
                        tierInasistencias == "diamante"
                      }
                    />
                    <Chip
                      label="Oro"
                      sx={{
                        fontSize: isSmallScreen ? 8 : 12,
                        ml: 1,
                        bgcolor: "#DAA520",
                        border: "1px solid",
                        boxShadow: 1,
                      }}
                      hidden={
                        tierInasistencias == "piedra" ||
                        tierInasistencias == "bronce" ||
                        tierInasistencias == "plata" ||
                        tierInasistencias == "diamante"
                      }
                    />
                    <Chip
                      label="Diamante"
                      sx={{
                        fontSize: isSmallScreen ? 8 : 12,
                        ml: 1,
                        bgcolor: "#F5DEB3",
                        border: "1px solid",
                        boxShadow: 3,
                      }}
                      hidden={
                        tierInasistencias == "piedra" ||
                        tierInasistencias == "bronce" ||
                        tierInasistencias == "plata" ||
                        tierInasistencias == "oro"
                      }
                    />
                  </Typography>
                }
                //subheader="September 14, 2016"
                titleTypographyProps={{
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              />
              <CardContent>
                <LogrosLinearProgress
                  sx={{ height: "10px", borderRadius: 3 }}
                  value={progressInasistencias}
                />
                <Typography mt={3} variant="body1" color="text.secondary">
                  <b>Descripción del logro:</b> Cantidad de convocatorias donde
                  se confirmó asistencia, pero no asistió.
                </Typography>
              </CardContent>
            </Card>
          </Box>

          <Box sx={{ width: "100%" }} hidden={logrosBtn == false} mt={2}>
            <Card
              sx={{
                backgroundColor: theme.palette.background.alt,
                boxShadow: 4,
                borderRadius: 4,
              }}
            >
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: "teal", width: 65, height: 65 }}>
                    <Schedule sx={{ fontSize: 35, color: "white" }} />
                  </Avatar>
                }
                title={
                  <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                    Convocatorias con Retrasos
                    <Chip
                      label="Piedra"
                      sx={{
                        fontSize: isSmallScreen ? 8 : 12,
                        ml: 1,
                        bgcolor: "#D2691E",
                        color: "white",
                        border: "1px solid black",
                        boxShadow: 1,
                      }}
                      hidden={
                        tierRetrasos == "bronce" ||
                        tierRetrasos == "plata" ||
                        tierRetrasos == "oro" ||
                        tierRetrasos == "diamante"
                      }
                    />
                    <Chip
                      label="Bronce"
                      sx={{
                        fontSize: isSmallScreen ? 8 : 12,
                        ml: 1,
                        bgcolor: "#B8860B",
                        border: "1px solid",
                        boxShadow: 1,
                      }}
                      hidden={
                        tierRetrasos == "piedra" ||
                        tierRetrasos == "plata" ||
                        tierRetrasos == "oro" ||
                        tierRetrasos == "diamante"
                      }
                    />
                    <Chip
                      label="Plata"
                      sx={{
                        fontSize: isSmallScreen ? 8 : 12,
                        ml: 1,
                        bgcolor: "#A9A9A9",
                        border: "1px solid",
                        boxShadow: 1,
                      }}
                      hidden={
                        tierRetrasos == "piedra" ||
                        tierRetrasos == "bronce" ||
                        tierRetrasos == "oro" ||
                        tierRetrasos == "diamante"
                      }
                    />
                    <Chip
                      label="Oro"
                      sx={{
                        fontSize: isSmallScreen ? 8 : 12,
                        ml: 1,
                        bgcolor: "#DAA520",
                        border: "1px solid",
                        boxShadow: 1,
                      }}
                      hidden={
                        tierRetrasos == "piedra" ||
                        tierRetrasos == "bronce" ||
                        tierRetrasos == "plata" ||
                        tierRetrasos == "diamante"
                      }
                    />
                    <Chip
                      label="Diamante"
                      sx={{
                        fontSize: isSmallScreen ? 8 : 12,
                        ml: 1,
                        bgcolor: "#F5DEB3",
                        border: "1px solid",
                        boxShadow: 3,
                      }}
                      hidden={
                        tierRetrasos == "piedra" ||
                        tierRetrasos == "bronce" ||
                        tierRetrasos == "plata" ||
                        tierRetrasos == "oro"
                      }
                    />
                  </Typography>
                }
                //subheader="September 14, 2016"
                titleTypographyProps={{
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              />
              <CardContent>
                <LogrosLinearProgress
                  sx={{ height: "10px", borderRadius: 3 }}
                  value={progressRetrasos}
                />
                <Typography mt={3} variant="body1" color="text.secondary">
                  <b>Descripción del logro:</b> Cantidad de convocatorias donde
                  ha llegado tarde al punto de encuentro.
                </Typography>
              </CardContent>
            </Card>
          </Box>
          {/* /////////////////////////////////////////////////////////////////// */}

          {/* ///////////////////  RECOMPENSAS //////////////////// */}
          <Box sx={{ width: "100%" }} hidden={rewardsBtn == false}>
            <Grid container justifyContent="center" mt={5}>
              <Grid item>
                <ScheduleOutlined sx={{ fontSize: 150, color: "gray" }} />
              </Grid>
            </Grid>
            <Grid container justifyContent="center" mt={4}>
              <Grid item>
                <Typography variant="h3" sx={{ color: "gray" }}>
                  ¡Las recompensas estarán disponibles muy pronto!
                </Typography>
              </Grid>
            </Grid>
            {/*<Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 50,
                          height: 50,
                          border: "1px solid black",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faBook}
                          fontSize={25}
                          color="white"
                        />
                      </Avatar>
                    }
                    title="Libreta PALUZ"
                    subheader="100 puntos"
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  />
                  <CardContent>
                    <Grid container justifyContent="center" spacing={1}>
                      <Grid item xs={6} sm={6}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={80}
                          sx={{ mt: 1 }}
                        />
                      </Grid>
                      <Grid item xs={5} sm={5}>
                        <Typography ml={1}> 80/100 Ptos. </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 50,
                          height: 50,
                          border: "1px solid black",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faBottleWater}
                          fontSize={30}
                          color="white"
                        />
                      </Avatar>
                    }
                    title="Botella de Agua PALUZ"
                    subheader="100 puntos"
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  />
                  <CardContent>
                    <Grid container justifyContent="center" spacing={1}>
                      <Grid item xs={6} sm={6}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={40}
                          sx={{ mt: 1 }}
                        />
                      </Grid>
                      <Grid item xs={5} sm={5}>
                        <Typography ml={1}> 40/100 Ptos. </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 50,
                          height: 50,
                          border: "1px solid black",
                        }}
                      >
                        <Box
                          component="img"
                          src={capIcon}
                          height="35px"
                          width="35px"
                        />
                      </Avatar>
                    }
                    title="Gorra PALUZ"
                    subheader="100 puntos"
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  />
                  <CardContent>
                    <Grid container justifyContent="center" spacing={1}>
                      <Grid item xs={6} sm={6}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={60}
                          sx={{ mt: 1 }}
                        />
                      </Grid>
                      <Grid item xs={5} sm={5}>
                        <Typography ml={1}> 60/100 Ptos. </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 50,
                          height: 50,
                          border: "1px solid black",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPen}
                          fontSize={20}
                          color="white"
                        />
                      </Avatar>
                    }
                    title="Bolígrafo PALUZ"
                    subheader="100 puntos"
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  />
                  <CardContent>
                    <Grid container justifyContent="center" spacing={1}>
                      <Grid item xs={6} sm={6}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={15}
                          sx={{ mt: 1 }}
                        />
                      </Grid>
                      <Grid item xs={5} sm={5}>
                        <Typography ml={1}> 15/100 Ptos. </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 40,
                          height: 40,
                          border: "1px solid black",
                        }}
                      >
                        R
                      </Avatar>
                    }
                    title="Recompensa"
                    subheader="100 puntos"
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 14,
                    }}
                  />
                  <CardContent>
                    <Grid container justifyContent="center" spacing={1}>
                      <Grid item xs={6} sm={6}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={50}
                          sx={{ mt: 1 }}
                        />
                      </Grid>
                      <Grid item xs={5} sm={5}>
                        <Typography ml={1}> 50/100 Ptos. </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 40,
                          height: 40,
                          border: "1px solid black",
                        }}
                      >
                        R
                      </Avatar>
                    }
                    title="Recompensa"
                    subheader="100 puntos"
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 14,
                    }}
                  />
                  <CardContent>
                    <Grid container justifyContent="center" spacing={1}>
                      <Grid item xs={6} sm={6}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={50}
                          sx={{ mt: 1 }}
                        />
                      </Grid>
                      <Grid item xs={5} sm={5}>
                        <Typography ml={1}> 50/100 Ptos. </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 40,
                          height: 40,
                          border: "1px solid black",
                        }}
                      >
                        R
                      </Avatar>
                    }
                    title="Recompensa"
                    subheader="100 puntos"
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 14,
                    }}
                  />
                  <CardContent>
                    <Grid container justifyContent="center" spacing={1}>
                      <Grid item xs={6} sm={6}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={50}
                          sx={{ mt: 1 }}
                        />
                      </Grid>
                      <Grid item xs={5} sm={5}>
                        <Typography ml={1}> 50/100 Ptos. </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 40,
                          height: 40,
                          border: "1px solid black",
                        }}
                      >
                        R
                      </Avatar>
                    }
                    title="Recompensa"
                    subheader="100 puntos"
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 14,
                    }}
                  />
                  <CardContent>
                    <Grid container justifyContent="center" spacing={1}>
                      <Grid item xs={6} sm={6}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={50}
                          sx={{ mt: 1 }}
                        />
                      </Grid>
                      <Grid item xs={5} sm={5}>
                        <Typography ml={1}> 50/100 Ptos. </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>*/}
          </Box>

          {/* ///////////////////  MIS ESTADÍSTICAS //////////////////// */}
          <Box sx={{ width: "100%" }} hidden={statsBtn == false}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 60,
                          height: 60,
                          border: "1px solid black",
                        }}
                      >
                        <Event sx={{ fontSize: 35, color: "white" }} />
                      </Avatar>
                    }
                    title="Convocatorias Aceptadas"
                    subheader={convocatoriaAceptada + " " + "Convocatorias"}
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                    subheaderTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 60,
                          height: 60,
                          border: "1px solid black",
                        }}
                      >
                        <CalendarToday sx={{ fontSize: 30, color: "white" }} />
                      </Avatar>
                    }
                    title="Convocatorias Rechazadas"
                    subheader={convocatoriaRechazada + " " + "Convocatorias"}
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                    subheaderTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  />
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={1}>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 60,
                          height: 60,
                          border: "1px solid black",
                        }}
                      >
                        <EventAvailable sx={{ fontSize: 35, color: "white" }} />
                      </Avatar>
                    }
                    title="Convocatorias Confirmadas"
                    subheader={convocatoriaConfirmada + " " + "Convocatorias"}
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                    subheaderTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 60,
                          height: 60,
                          border: "1px solid black",
                        }}
                      >
                        <EventBusy sx={{ fontSize: 35, color: "white" }} />
                      </Avatar>
                    }
                    title="Convocatorias Canceladas"
                    subheader={convocatoriaCancelada + " " + "Convocatorias"}
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                    subheaderTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  />
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={1}>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 60,
                          height: 60,
                          border: "1px solid black",
                        }}
                      >
                        <Cancel sx={{ fontSize: 35, color: "white" }} />
                      </Avatar>
                    }
                    title="Cantidad de Inasistencias"
                    subheader={
                      convocatoriaInasistencias + " " + "Inasistencias"
                    }
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                    subheaderTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 60,
                          height: 60,
                          border: "1px solid black",
                        }}
                      >
                        <AccessTime sx={{ fontSize: 35, color: "white" }} />
                      </Avatar>
                    }
                    title="Cantidad de Retrasos"
                    subheader={convocatoriaRetrasos + " " + "Retrasos"}
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                    subheaderTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  />
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={1}>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 60,
                          height: 60,
                          border: "1px solid black",
                        }}
                      >
                        <ChildCareIcon sx={{ fontSize: 35, color: "white" }} />
                      </Avatar>
                    }
                    title="NNAs Atendidos"
                    subheader={ninosAtendido + " " + "NNAs"}
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                    subheaderTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 60,
                          height: 60,
                          border: "1px solid black",
                        }}
                      >
                        <PregnantWomanIcon
                          sx={{ fontSize: 35, color: "white" }}
                        />
                      </Avatar>
                    }
                    title="MEL Atendidas"
                    subheader={melAtendida + " " + "MEL"}
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                    subheaderTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  />
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={1}>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 60,
                          height: 60,
                          border: "1px solid black",
                        }}
                      >
                        <SentimentVeryDissatisfied
                          sx={{ fontSize: 35, color: "white" }}
                        />
                      </Avatar>
                    }
                    title="NNAs SAM Diagnósticados"
                    subheader={diagSam + " " + "NNAs"}
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                    subheaderTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 60,
                          height: 60,
                          border: "1px solid black",
                        }}
                      >
                        <SentimentNeutral
                          sx={{ fontSize: 35, color: "White" }}
                        />
                      </Avatar>
                    }
                    title="NNAs MAM Diagnósticados"
                    subheader={diagman + " " + "NNAs"}
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                    subheaderTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  />
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={1}>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 60,
                          height: 60,
                          border: "1px solid black",
                        }}
                      >
                        <NoteAlt sx={{ fontSize: 35, color: "white" }} />
                      </Avatar>
                    }
                    title="NNAs Referidos"
                    subheader={referido + " " + "NNAs"}
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                    subheaderTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 60,
                          height: 60,
                          border: "1px solid black",
                        }}
                      >
                        <Face2 sx={{ fontSize: 35, color: "white" }} />
                      </Avatar>
                    }
                    title="MEL Bajo Peso Atendidas"
                    subheader={melBajoPeso + " " + "MEL"}
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                    subheaderTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  />
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={1}>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 60,
                          height: 60,
                          border: "1px solid black",
                        }}
                      >
                        <ContentPaste sx={{ fontSize: 35, color: "white" }} />
                      </Avatar>
                    }
                    title="Controles Asistidos"
                    subheader={controlesAsistidos + " " + "Controles"}
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                    subheaderTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    boxShadow: 4,
                    borderRadius: 4,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: "teal",
                          width: 60,
                          height: 60,
                          border: "1px solid black",
                        }}
                      >
                        <AirportShuttle sx={{ fontSize: 35, color: "white" }} />
                      </Avatar>
                    }
                    title="Km Recorridos"
                    subheader={kilometrosRecorridos + " " + "Km"}
                    titleTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                    subheaderTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  />
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PerfilVoluntario;
