import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const EnsayoForm = ({ handleCount, mensaje }) => {
  const [text, setText] = useState(mensaje);
  const wordCount = text.split(/\s+/).filter(Boolean).length;

  //console.log('Contar: ' + mensaje)

  const handleTextChange = (e) => {
    setText(e.target.value);
    if (wordCount >= 200) {
      let dato = 1;
      handleCount(dato, text);
    } else {
      let dato = 2;
      handleCount(dato, text);
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        ¿Por qué quieres ser parte de PALUZ?
      </Typography>
      <Typography mt={2} gutterBottom>
        <InfoOutlinedIcon sx={{ color:'gray' }}/> Cuéntanos los motivos por los que quieres ser parte de nuestra organización <i>(min. 200 palabras)</i>.
      </Typography>
      <Grid container spacing={3} mt={1}>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="ensayo"
            name="ensayo"
            fullWidth
            variant="outlined"
            multiline
            value={text}
            onChange={handleTextChange}
            rows={10}
            cols={100}
          />
          <Typography mt={1} hidden={wordCount>200}><b style={{ color:wordCount<200?"firebrick":"darkgreen" }}>{wordCount}</b>/200</Typography>
          <Typography mt={1} hidden={wordCount<=200} sx={{ color:"darkgreen" }}><b>{wordCount}</b></Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EnsayoForm;
