import React, { useMemo, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Container from "@mui/material/Container";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import HomeIcon from "@mui/icons-material/Home";
import InventoryOutlined from "@mui/icons-material/InventoryOutlined";
import ListConvos from "components/ListConvos";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import Restricted from "components/Restricted";
import clienteAxios from "../../config/clienteAxios";
import axios from "axios";

const ListaConvocatorias = () => {
  const nav = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("prop");
  const proyecto = JSON.parse(token);
  const [data, setData] = React.useState([]);
  const [ejecutar, setEjecutar] = React.useState(true);
  const [tabla, setTabla] = React.useState(false);
  const [datos, setDatos] = React.useState({});
  const [solicitud, setSolicitud] = React.useState([]);
  const [solicitudPro, setSolicitudPro] = React.useState([]);
  const [solicitudFin, setSolicitudFin] = React.useState([]);

  const [items, setItems] = React.useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const rol = items.rol;

  //Theme
  const theme = useTheme();

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //Modal Styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 5,
    p: 4,
  };

  function handleClickBreadCrumbs(event) {
    event.preventDefault();
  }

  const styleVol = {
    position: "inherit",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "90%" : "50%",
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    borderRadius: 4,
    boxShadow: 1,
    p: 4,
  };

  //Modal Handlers
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = (row) => {
    solicitudes(row.original.id);
    solicitudesProceso(row.original.id);
    solicitudesFinalizada(row.original.id);

    console.log(row);

    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);

  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [openAlert, setOpenAlert] = React.useState(false);
  const handleOpenAlert = () => {
    setOpenAlert(true);
    handleCloseAlert();
    handleClose();
  };
  const handleCloseAlert = () =>
    setTimeout(() => {
      setOpenAlert(false);
    }, 2000);

  const [openAlertEdit, setOpenAlertEdit] = React.useState(false);
  const handleOpenAlertEdit = () => {
    setOpenAlertEdit(true);
    handleCloseAlertEdit();
    handleCloseEdit();
  };
  const handleCloseAlertEdit = () =>
    setTimeout(() => {
      setOpenAlertEdit(false);
    }, 2000);

  //Routes
  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };

  const handleLinkSum = (event, message) => {
    if (message === "suministros") {
      nav("/suministros");
    }
  };

  const handleLinkSolSum = (event, message, row) => {
    let conv = row.original;
    conv.nombrePro = proyecto.nombre;
    conv.idPro = proyecto.idproyectos;
    conv = JSON.stringify(conv);
    if (message === "solicitud") {
      nav(`/solicitudSuministros?prop=${conv}`);
    }
  };

  const handleLinkEditSol = (event, message, row) => {
    let conv = row.original;
    conv.nombrePro = proyecto.nombre;
    conv.idPro = proyecto.idproyectos;
    conv = JSON.stringify(conv);
    if (message === "editar") {
      nav(`/editarSolicitudSum?prop=${conv}`);
    }
  };

  const myConvos = useMemo(
    () => [
      {
        accessorKey: "actividad",
        header: "Tipo de actividad",
        size: 5,
      },
      {
        accessorKey: "fecha",
        header: "Fecha",
        size: 5,
      },
      {
        accessorKey: "estados",
        header: "Estado",
        size: 5,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue() === 1 ? (
              <Box
                sx={{
                  backgroundColor: "green",
                  borderRadius: "0.25rem",
                  color: "#fff",
                  maxWidth: "12ch",
                  p: "0.25rem",
                }}
              >
                Aceptada
              </Box>
            ) : cell.getValue() === 2 ? (
              <Box
                sx={{
                  backgroundColor: "black",
                  borderRadius: "0.25rem",
                  color: "#fff",
                  maxWidth: "12ch",
                  p: "0.25rem",
                }}
              >
                Rechazada
              </Box>
            ) : cell.getValue() === 3 ? (
              <Box
                sx={{
                  backgroundColor: "darkorange",
                  borderRadius: "0.25rem",
                  color: "#fff",
                  maxWidth: "14ch",
                  p: "0.25rem",
                }}
              >
                En Espera
              </Box>
            ) : (
              <Box
                sx={{
                  backgroundColor: "firebrick",
                  borderRadius: "0.25rem",
                  color: "#fff",
                  maxWidth: "12ch",
                  p: "0.25rem",
                }}
              >
                Cancelada
              </Box>
            )}
          </Box>
        ),
      },
    ],
    []
  );

  const proyectos = async () => {
    try {
      const { data1 } = await axios
        .get("/convproyecto/", {
          params: {
            id: proyecto.idproyectos,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setData(response.data);
          setEjecutar(false);
          console.log(data);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const solicitudes = async (id) => {
    try {
      const { data1 } = await axios
        .get("/convsolicitud/", {
          params: {
            id: id,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setSolicitud(response.data);

          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const solicitudesProceso = async (id) => {
    try {
      const { data1 } = await axios
        .get("/convsolicitudproceso/", {
          params: {
            id: id,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setSolicitudPro(response.data);

          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const solicitudesFinalizada = async (id) => {
    try {
      const { data1 } = await axios
        .get("/convsolicitudfinalizada/", {
          params: {
            id: id,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setSolicitudFin(response.data);

          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  if (ejecutar) {
    proyectos();
  }

  useEffect(() => {
    setDatos({
      solicitudes: solicitud,
      solicitudesPro: solicitudPro,
      solicitudesFin: solicitudFin,
    });
    setTabla(true);
  }, [solicitud, solicitudPro, solicitudFin]);
  if (!data)
    return (
      <Container component="main" maxWidth="xs">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  return (
    <Box m="1.5rem 2.5rem">
      {rol === "1" || rol === "2" || rol === "5" || rol === "7" ? (
        <>
          <Box
            role="presentation"
            onClick={handleClickBreadCrumbs}
            sx={{ mb: 3 }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/inicio"
                onClick={(event) => handleLinkHome(event, "home")}
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Inicio
              </Link>
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/inicio"
                onClick={(event) => handleLinkSum(event, "suministros")}
              >
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  <InventoryOutlined sx={{ mr: 0.5 }} fontSize="inherit" />
                  Suministros
                </Typography>
              </Link>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <CalendarMonthOutlinedIcon
                  sx={{ mr: 0.5 }}
                  fontSize="inherit"
                />
                Convocatorias
              </Typography>
            </Breadcrumbs>
            <Typography variant="h4" sx={{ mt: 2 }}>
              <b>Convocatorias:</b> {proyecto.nombre}
            </Typography>
          </Box>
          <Container>
            <Box
              gridColumn="span 8"
              gridRow="span 3"
              backgroundColor={theme.palette.background.alt}
              p="1.5rem"
              borderRadius="1.5rem"
              mt="40px"
              height="fit-content"
              sx={{ boxShadow: 4 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    Listado de Convocatorias
                  </Typography>
                </Grid>
              </Grid>

              <MaterialReactTable
                columns={myConvos}
                data={data}
                localization={MRT_Localization_ES}
                enableRowActions
                positionActionsColumn="last"
                displayColumnDefOptions={{
                  "mrt-row-actions": {
                    header: "Opciones",
                    size: 10,
                  },
                }}
                renderRowActionMenuItems={({ closeMenu, row }) => [
                  <MenuItem
                    onClick={(event) =>
                      handleLinkSolSum(event, "solicitud", row)
                    }
                    sx={{ fontWeight: "bold" }}
                  >
                    <AssignmentOutlinedIcon sx={{ mr: 1 }} /> Generar Solicitud
                  </MenuItem>,
                  <MenuItem
                    onClick={(event) => {
                      handleOpenModal(row);
                      closeMenu();
                    }}
                    sx={{ fontWeight: "bold" }}
                  >
                    <DescriptionOutlinedIcon sx={{ mr: 1 }} /> Ver Solicitudes
                  </MenuItem>,
                ]}
                muiTopToolbarProps={{
                  sx: {
                    backgroundColor: theme.palette.background.alt,
                  },
                }}
                muiTableHeadCellProps={{
                  align: "center",
                  sx: {
                    backgroundColor: theme.palette.background.alt,
                  },
                }}
                muiBottomToolbarProps={{
                  sx: {
                    backgroundColor: theme.palette.background.alt,
                  },
                }}
                muiTableBodyProps={{
                  sx: {
                    "& tr:nth-of-type(odd)": {
                      backgroundColor: theme.palette.background.light,
                    },
                  },
                }}
                muiTableBodyCellProps={{
                  align: "center",
                }}
                muiTablePaperProps={{
                  elevation: 0, //change the mui box shadow
                  //customize paper styles
                }}
                initialState={{
                  pagination: {
                    pageSize: 5,
                    pageIndex: 0,
                  },
                }}
                muiTablePaginationProps={{
                  rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                  showFirstButton: false,
                  showLastButton: false,
                  SelectProps: {
                    native: true,
                  },
                  labelRowsPerPage: "Número de filas visibles",
                }}

                /*renderDetailPanel={({ row }) => (
    <Box
      sx={{
        display: 'grid',
        margin: 'auto',
        gridTemplateColumns: '1fr 1fr',
        width: '100%',
      }}
    >
      <Typography>Address: {row.original.address}</Typography>
      <Typography>City: {row.original.city}</Typography>
      <Typography>State: {row.original.state}</Typography>
      <Typography>Country: {row.original.country}</Typography>
    </Box>
    )} */
              />

              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                slotProps={{ backdrop: { style: { opacity: 0.2 } } }}
              >
                <Box sx={styleVol}>
                  {tabla ? <ListConvos datos={datos} /> : ""}

                  <Divider sx={{ mt: 2 }} />
                  <Grid
                    container
                    sx={{ mt: 2 }}
                    spacing={1}
                    justifyContent="flex-end"
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleCloseModal}
                      >
                        Salir
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
            </Box>
          </Container>
        </>
      ) : (
        <Restricted />
      )}
    </Box>
  );
};

export default ListaConvocatorias;
