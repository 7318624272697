import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import "dayjs/locale/es";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import CasesRoundedIcon from "@mui/icons-material/CasesRounded";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import OutlinedInput from "@mui/material/OutlinedInput";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import Backdrop from "@mui/material/Backdrop";
import Restricted from "components/Restricted";
import CircularProgress from "@mui/material/CircularProgress";
import clienteAxios from "../../config/clienteAxios";

const CrearRetro = () => {
  const nav = useNavigate();

  const [items, setItems] = React.useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const rol = items.rol;

  //Theme
  const theme = useTheme();

  function handleClickBreadCrumbs(event) {
    event.preventDefault();
  }

  //DatePicker Value
  const [valueRecep, setValueRecep] = React.useState(null);
  const [valueResp, setValueResp] = React.useState(null);

  //Modal Handlers
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openAlert, setOpenAlert] = React.useState(false);
  const handleOpenAlert = () => {
    setOpenAlert(true);
    handleCloseAlert();
  };
  const handleCloseAlert = () =>
    setTimeout(() => {
      setOpenAlert(false);
      nav("/proyectos");
    }, 2000);

  //Routes
  const handleLinkClick = (event, message) => {
    if (message === "cancelar") {
      nav("/proyectos");
    }
  };

  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };

  const handleLinkGestion = (event, message) => {
    if (message === "gestion") {
      nav("/gestionhumana");
    }
  };

  const handleLinkRetro = (event, message) => {
    if (message === "retro") {
      nav("/retroalimentacion");
    }
  };

  //Modal Styles
  const style = {
    position: "inherit",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 375,
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    borderRadius: 4,
    boxShadow: 1,
    p: 4,
  };

  //Inputs States
  const [valueNombreEncargado, setValueNombreEncargado] = useState("");
  const [valueResumenRetro, setValueResumenRetro] = useState("");
  const [valueNombreBenef, setValueNombreBenef] = useState("");
  const [valueEdadBenef, setValueEdadBenef] = useState("");
  const [otro, setValueOtro] = useState("");
  const [tlf, setValueTlf] = useState("");
  const [direccion, setValueDireccion] = useState("");
  const [email, setValueEmail] = useState("");
  const [datosAdicionales, setValueDA] = useState("");
  const [ubicacion, setValueUbicacion] = useState("");
  const [proyecto, setValueProyecto] = useState("");
  const [nombrePR, setValueNamePR] = useState("");
  const [diasCerrarCiclo, setValueDiasCC] = useState("");
  const [comentAdicionales, setValueCA] = useState("");

  //Select States
  const [estatus, setEstatus] = React.useState("");
  const handleChangeEstatus = (event) => {
    setEstatus(event.target.value);
  };

  const [anonim, setAnonim] = React.useState("");
  const handleChangeAnonim = (event) => {
    setAnonim(event.target.value);
  };

  const [genero, setGenero] = React.useState("");
  const handleChangeGenero = (event) => {
    setGenero(event.target.value);
  };

  const [rangoEdadBenef, setRangoEdadBenef] = React.useState("");
  const handleChangeRangoEdad = (event) => {
    setRangoEdadBenef(event.target.value);
  };

  const [respIndividual, setRespIndividual] = React.useState("");
  const handleChangeValueRI = (event) => {
    setRespIndividual(event.target.value);
  };

  const [canalPref, setCanalPref] = React.useState("");
  const handleChangeValueCP = (event) => {
    setCanalPref(event.target.value);
  };

  const [canalRetro, setCanalRetro] = React.useState("");
  const handleChangeCanalRetro = (event) => {
    setCanalRetro(event.target.value);
  };

  const [estadoRecep, setEstadoRecep] = React.useState("");
  const handleChangeEstadoRecep = (event) => {
    setEstadoRecep(event.target.value);
    if (event.target.value === "Zulia") {
      setMunicipio(zuliaOpc);
    }
    if (event.target.value === "Falcón") {
      setMunicipio(falconOpc);
    }
    if (event.target.value === "Apure") {
      setMunicipio(apureOpc);
    }
  };

  const [municipio, setMunicipio] = React.useState([]);
  const [selecMunicipio, setSelecMunicipio] = React.useState("");
  const handleChangeMunicipio = (event) => {
    setSelecMunicipio(event.target.value);
  };

  const [departamento, setDepartamento] = React.useState("");
  const handleChangeDepartamento = (event) => {
    setDepartamento(event.target.value);
  };

  const [categoria, setCategoria] = React.useState("");
  const handleChangeCategoria = (event) => {
    setCategoria(event.target.value);
  };

  const [nivelPrioridad, setNivelPrioridad] = React.useState("");
  const handleChangeNP = (event) => {
    setNivelPrioridad(event.target.value);
  };

  const [respReq, setRespReq] = React.useState("");
  const handleChangeRespReq = (event) => {
    setRespReq(event.target.value);
  };

  const [medidaAdoptada, setMedidaAdoptada] = React.useState("");
  const handleChangeMA = (event) => {
    setMedidaAdoptada(event.target.value);
  };

  //Array Estados
  const estadosOpc = [
    {
      value: "Apure",
      label: "Apure",
    },
    {
      value: "Falcón",
      label: "Falcón",
    },
    {
      value: "Zulia",
      label: "Zulia",
    },
  ];

  //Arrays Municipios
  const apureOpc = [
    {
      value: "No hay opciones disponibles",
      label: "No hay opciones disponibles",
    },
  ];

  const falconOpc = [
    {
      value: "Colina",
      label: "Colina",
    },
    {
      value: "Miranda",
      label: "Miranda",
    },
  ];
  const zuliaOpc = [
    {
      value: "Guajira",
      label: "Guajira",
    },
    {
      value: "Mara",
      label: "Mara",
    },
    {
      value: "Maracaibo",
      label: "Maracaibo",
    },
    {
      value: "Rosario de Perijá",
      label: "Rosario de Perijá",
    },
    {
      value: "San Francisco",
      label: "San Francisco",
    },
  ];

  /*const handleSubmit = async event=> {
    event.preventDefault();
    let dataform = new FormData(event.currentTarget);
  
    try {
        const { data1 } = await clienteAxios.post('/crearproyectos/', {

        fecha:valueRecep
        nombre:valueNombreEncargado
        resumen:valueResumenRetro
        estatus:estatus
        nombre_beneficiario:valueNombreBenef
        solicitud_anonimo:anonim
        genero:genero
        edad:valueEdadBenef
        rango_edad:rangoEdadBenef
        respuesta_individual:respIndividual
        canal_respuesta:canalPref
        respuesta_otro:otro
        telefono:tlf
        email:email
        direccion:direccion
        info_adicional:datosAdicionales
        canal_retroalimentacion:canalRetro
        estado_retroalimentacion:estadoRecep
        municipio_retroalimentacion:selecMunicipio
        ubicacion_retroalimentacion:ubicacion
        proyecto_retroalimentacion:proyecto
        guarda_relacion:departamento
        categoria_retroalimentacion:categoria
        nivel_prioridad:nivelPrioridad
        tipo_respuesta:respReq
        fecha_respuesta:valueResp
        medida_adoptada:medidaAdoptada
        nombre_respuesta:nombrePR
        dias_ciclo:diasCerrarCiclo
        comentarios:comentAdicionales
        })
        .then(function (response) {
       })
       .catch(function (error) {
       });
       console.log(data1)

   } catch (error) {
      
    }
  
    handleOpenAlert();
  
  }; */

  /*if(ejecutar){
  usuariosTecnicos();

}
if (!socios.isSuccess || !usuarios || !sector.isSuccess) return (
  <Container component="main" maxWidth="xs">
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  </Container>
);  */

  return (
    <Box m="1.5rem 2.5rem">
      {rol === "1" || rol === "2" || rol === "7" ? (
        <>
          <Box
            gridColumn="span 2"
            gridRow="span 1"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p="1.25rem 1rem"
            flex="1 1 100%"
            borderRadius="0.55rem"
          >
            <CssBaseline />

            <Box
              role="presentation"
              onClick={handleClickBreadCrumbs}
              sx={{ mb: 3 }}
            >
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  href="/inicio"
                  onClick={(event) => handleLinkHome(event, "home")}
                >
                  <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Inicio
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  href="/gestionhumana"
                  onClick={(event) => handleLinkGestion(event, "gestion")}
                >
                  <CasesRoundedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Gestión Humana
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  href="/retroalimentación"
                  onClick={(event) => handleLinkRetro(event, "retro")}
                >
                  <CasesRoundedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Retroalimentación
                </Link>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  <CreateNewFolderIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Nueva Retroalimentación
                </Typography>
              </Breadcrumbs>
              <Typography
                variant="h3"
                component="div"
                sx={{ mt: 3, mb: 1, fontWeight: "bold" }}
              >
                Nueva Retroalimentación
              </Typography>
            </Box>
            <Container>
              <Card
                sx={{
                  borderRadius: 8,
                  boxShadow: 5,
                  bgcolor: theme.palette.background.alt,
                }}
              >
                <CardContent>
                  <Box
                    component="form"
                    noValidate
                    /*onSubmit={handleSubmit}*/ sx={{ mt: 3 }}
                  >
                    <Box>
                      <Divider sx={{ mt: 4, mb: 3 }}>
                        <Chip label="RESUMEN" />
                      </Divider>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="es"
                          >
                            <DesktopDatePicker
                              label="Fecha de recepción"
                              name="fechaRecepcion"
                              inputFormat="DD/MM/YYYY"
                              value={valueRecep}
                              onChange={setValueRecep}
                              disablePast
                              renderInput={(params) => {
                                return <TextField fullWidth {...params} />;
                              }}
                              clearable
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            name="nombreEncargado"
                            required
                            fullWidth
                            id="nombreEncargado"
                            label="Nombre del Encargado de Registrar"
                            value={valueNombreEncargado}
                            onChange={(event) =>
                              setValueNombreEncargado(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            name="resumenRetro"
                            required
                            fullWidth
                            id="resumenRetro"
                            label="Resumen de Retroalimentación"
                            value={valueResumenRetro}
                            onChange={(event) =>
                              setValueResumenRetro(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Estatus
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={estatus}
                              label="Estatus"
                              onChange={handleChangeEstatus}
                            >
                              <MenuItem value={"Procesada"}>Procesada</MenuItem>
                              <MenuItem value={"En revisión"}>
                                En revisión
                              </MenuItem>
                              <MenuItem value={"Remitida externamente"}>
                                Remitida externamente
                              </MenuItem>
                              <MenuItem value={"Correo no deseado"}>
                                Correo no deseado
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 4, mb: 3 }}>
                        <Chip label="DATOS DEL BENEFICIARIO" />
                      </Divider>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label="Nombre del beneficiario"
                            fullWidth
                            required
                            value={valueNombreBenef}
                            onChange={(event) =>
                              setValueNombreBenef(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              ¿Permanecer en anonimato?
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={anonim}
                              label="¿Permanecer en anonimato?"
                              onChange={handleChangeAnonim}
                            >
                              <MenuItem value={"Si"}>Si</MenuItem>
                              <MenuItem value={"No"}>No</MenuItem>
                              <MenuItem value={"No se sabe con certeza"}>
                                No se sabe con certeza
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Género
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={genero}
                              label="Género"
                              onChange={handleChangeGenero}
                            >
                              <MenuItem value={"Mujer"}>Mujer</MenuItem>
                              <MenuItem value={"Hombre"}>Hombre</MenuItem>
                              <MenuItem value={"No se sabe"}>
                                No se sabe
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label="Edad"
                            type="number"
                            fullWidth
                            required
                            value={valueEdadBenef}
                            onChange={(event) =>
                              setValueEdadBenef(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Rango de Edad
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={rangoEdadBenef}
                              label="Rango de Edad"
                              onChange={handleChangeRangoEdad}
                            >
                              <MenuItem value={"0-4 años"}>0-4 años</MenuItem>
                              <MenuItem value={"5-17 años"}>5-17 años</MenuItem>
                              <MenuItem value={"18-49 años"}>
                                18-49 años
                              </MenuItem>
                              <MenuItem value={"50 años o más"}>
                                50 años o más
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 4 }}>
                        <Chip label="DATOS DE CONTACTO" />
                      </Divider>
                      <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              ¿Solicitud de Respuesta individual?
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={respIndividual}
                              label="¿Solicitud de Respuesta individual?"
                              onChange={handleChangeValueRI}
                            >
                              <MenuItem value={"Si"}>Si</MenuItem>
                              <MenuItem value={"No"}>No</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Canal Preferente de Respuesta
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={canalPref}
                              label="Canal Preferente de Respuesta"
                              onChange={handleChangeValueCP}
                            >
                              <MenuItem value={"Por mensaje de Texto"}>
                                Por mensaje de Texto
                              </MenuItem>
                              <MenuItem value={"Por correo electrónico"}>
                                Por correo electrónico
                              </MenuItem>
                              <MenuItem value={"Por teléfono"}>
                                Por teléfono
                              </MenuItem>
                              <MenuItem value={"A través de un tercero"}>
                                A través de un tercero
                              </MenuItem>
                              <MenuItem value={"Otro"}>Otro</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            label='Si la respuesta fue "Otro", especifique'
                            value={otro}
                            fullWidth
                            disabled={canalPref !== "Otro"}
                            onChange={(event) =>
                              setValueOtro(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label="Número de Teléfono"
                            value={tlf}
                            type="number"
                            fullWidth
                            onChange={(event) =>
                              setValueTlf(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label="Correo Electrónico"
                            value={email}
                            type="email"
                            fullWidth
                            onChange={(event) =>
                              setValueEmail(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label="Dirección"
                            value={direccion}
                            fullWidth
                            onChange={(event) =>
                              setValueDireccion(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label="Datos Adicionales"
                            value={datosAdicionales}
                            fullWidth
                            onChange={(event) => setValueDA(event.target.value)}
                          />
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 4 }}>
                        <Chip label="INFORMACIÓN GENERAL" />
                      </Divider>
                      <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12} sm={3}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Canal de Retroalimentación
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={canalRetro}
                              label="Canal de Retroalimentación"
                              onChange={handleChangeCanalRetro}
                            >
                              <MenuItem value={"Buzón de Sugerencias"}>
                                Buzón de Sugerencias
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Estado de recepción
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={estadoRecep}
                              label="Estado de recepción"
                              onChange={handleChangeEstadoRecep}
                            >
                              <MenuItem value={"Apure"}>Apure</MenuItem>
                              <MenuItem value={"Falcón"}>Falcón</MenuItem>
                              <MenuItem value={"Zulia"}>Zulia</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Municipio
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={selecMunicipio}
                              label="Municipio"
                              onChange={handleChangeMunicipio}
                            >
                              {municipio.map((dat, index) => (
                                <MenuItem value={dat.value}>
                                  {dat.value}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label="Ubicación a la que hace referencia"
                            value={ubicacion}
                            required
                            fullWidth
                            onChange={(event) =>
                              setValueUbicacion(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Proyecto"
                            value={proyecto}
                            fullWidth
                            onChange={(event) =>
                              setValueProyecto(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Departamento
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={departamento}
                              label="Municipio"
                              onChange={handleChangeDepartamento}
                            >
                              <MenuItem value={"Educación"}>Educación</MenuItem>
                              <MenuItem value={"Nutrición"}>Nutrición</MenuItem>
                              <MenuItem value={"Salud"}>Salud</MenuItem>
                              <MenuItem value={"WASH"}>WASH</MenuItem>
                              <MenuItem value={"Desconocido/No Especificado"}>
                                Desconocido/No Especificado
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 4 }}>
                        <Chip label="GESTIÓN DEL CASO" />
                      </Divider>
                      <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Categoría
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={categoria}
                              label="Categoría"
                              onChange={handleChangeCategoria}
                            >
                              <MenuItem value={"Solicitud de información"}>
                                Solicitud de información
                              </MenuItem>
                              <MenuItem value={"Solicitudes de asistencia"}>
                                Solicitudes de asistencia
                              </MenuItem>
                              <MenuItem
                                value={
                                  "Queja programática: Insatisfacción leve"
                                }
                              >
                                Queja programática: Insatisfacción leve
                              </MenuItem>
                              <MenuItem
                                value={
                                  "Queja programática: Insatisfacción importante"
                                }
                              >
                                Queja programática: Insatisfacción importante
                              </MenuItem>
                              <MenuItem
                                value={
                                  "Incumplimiento del código de conducta y ética de PALUZ"
                                }
                              >
                                Incumplimiento del código de conducta y ética de
                                PALUZ
                              </MenuItem>
                              <MenuItem
                                value={"Retroalimentación general/Otro"}
                              >
                                Retroalimentación general/Otro
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Nivel de Prioridad
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={nivelPrioridad}
                              label="Nivel de Prioridad"
                              onChange={handleChangeNP}
                            >
                              <MenuItem value={"Crítico"}>Crítico</MenuItem>
                              <MenuItem value={"Alto"}>Alto</MenuItem>
                              <MenuItem value={"Medio"}>Medio</MenuItem>
                              <MenuItem value={"Bajo"}>Bajo</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Respuesta Requerida
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={respReq}
                              label="Respuesta Requerida"
                              onChange={handleChangeRespReq}
                            >
                              <MenuItem value={"Enviar una disculpa"}>
                                Enviar una disculpa
                              </MenuItem>
                              <MenuItem value={"Decisión/Acción correctiva"}>
                                Decisión/Acción correctiva
                              </MenuItem>
                              <MenuItem value={"Proporcionar una explicación"}>
                                Proporcionar una explicación
                              </MenuItem>
                              <MenuItem value={"Remisión externa"}>
                                Remisión externa
                              </MenuItem>
                              <MenuItem value={"Proporcionar bienes/servicios"}>
                                Proporcionar bienes/servicios
                              </MenuItem>
                              <MenuItem value={"Proporcionar información"}>
                                Proporcionar información
                              </MenuItem>
                              <MenuItem value={"Cambio de política"}>
                                Cambio de política
                              </MenuItem>
                              <MenuItem value={"Descartar la solicitud/queja"}>
                                Descartar la solicitud/queja
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 4, mb: 3 }}>
                        <Chip label="CERRAR EL CICLO" />
                      </Divider>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="es"
                          >
                            <DesktopDatePicker
                              label="Fecha de Respuesta"
                              name="fechaResp"
                              inputFormat="DD/MM/YYYY"
                              value={valueResp}
                              onChange={setValueResp}
                              disablePast
                              renderInput={(params) => {
                                return <TextField fullWidth {...params} />;
                              }}
                              clearable
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Medida Adoptada
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={medidaAdoptada}
                              label="Medida adoptada"
                              onChange={handleChangeMA}
                            >
                              <MenuItem value={"Disculpa enviada"}>
                                Disculpa enviada
                              </MenuItem>
                              <MenuItem value={"Medida correctiva adoptada"}>
                                Medida correctiva adoptada
                              </MenuItem>
                              <MenuItem value={"Explicación proporcionada"}>
                                Explicación proporcionada
                              </MenuItem>
                              <MenuItem value={"Remitida externamente"}>
                                Remitida externamente
                              </MenuItem>
                              <MenuItem
                                value={"Bienes/servicios proporcionados"}
                              >
                                Bienes/servicios proporcionados
                              </MenuItem>
                              <MenuItem value={"Información proporcionada"}>
                                Información proporcionada
                              </MenuItem>
                              <MenuItem value={"Política modificada"}>
                                Política modificada
                              </MenuItem>
                              <MenuItem value={"Cambio programático realizado"}>
                                Cambio programático realizado
                              </MenuItem>
                              <MenuItem value={"Descartada"}>
                                Descartada
                              </MenuItem>
                              <MenuItem value={"Otra"}>Otra</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label="Nombre de Quien Proporcionó la Respuesta"
                            value={nombrePR}
                            fullWidth
                            onChange={(event) =>
                              setValueNamePR(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label="Número de Días para Cerrar el Ciclo"
                            value={diasCerrarCiclo}
                            type="number"
                            fullWidth
                            onChange={(event) =>
                              setValueDiasCC(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            label="Comentarios Adicionales"
                            value={comentAdicionales}
                            fullWidth
                            multiline
                            maxRows={4}
                            onChange={(event) => setValueCA(event.target.value)}
                          />
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 5 }}></Divider>
                      <Grid
                        container
                        sx={{ mt: 2 }}
                        spacing={1}
                        justifyContent="center"
                      >
                        <Grid item sm={3} xs={6}>
                          <Button
                            variant="contained"
                            color="error"
                            size="large"
                            fullWidth
                            onClick={handleOpen}
                          >
                            Cancelar
                          </Button>
                        </Grid>
                        <Grid item sm={3} xs={6}>
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{ bgcolor: "teal", color: "white" }}
                            /*disabled={!valueName || !valueCodigo || !valueFI || !valueFF || !soc || sectorOpc=="" || !valueDescripcion || corP=="" || corLo=="" || corSum=="" || corMe=="" }*/ size="large"
                            fullWidth
                          >
                            Guardar
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                slotProps={{ backdrop: { style: { opacity: 0.2 } } }}
              >
                <Box sx={style}>
                  <Typography
                    variant="h6"
                    className="text-center"
                    sx={{ mb: 1.5 }}
                  >
                    ¿Está seguro de cancelar esta operación?
                  </Typography>
                  <Divider sx={{ mt: 2 }} />
                  <Grid
                    container
                    sx={{ mt: 1 }}
                    spacing={1}
                    justifyContent="flex-end"
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                      >
                        No
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        sx={{ bgcolor: "teal", color: "white" }}
                        onClick={(event) => handleLinkClick(event, "cancelar")}
                      >
                        Si
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
              <Modal
                open={openAlert}
                onClose={handleCloseAlert}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" className="text-center">
                    <TaskAltOutlinedIcon
                      color="success"
                      sx={{ fontWeight: "bold", mt: 3, fontSize: 80 }}
                    />
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    className="text-center"
                    sx={{ mt: 2, fontSize: 20, fontWeight: "bold" }}
                  >
                    ¡Guardado exitosamente!
                  </Typography>
                </Box>
              </Modal>
            </Container>
          </Box>
        </>
      ) : (
        <Restricted />
      )}
    </Box>
  );
};

export default CrearRetro;
