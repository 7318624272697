import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme, useMediaQuery } from "@mui/material";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Box, Divider, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import HomeIcon from "@mui/icons-material/Home";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Fab from "@mui/material/Fab";
import Stack from "@mui/material/Stack";
import PrintIcon from "@mui/icons-material/Print";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import icon from "assets/paluz-icon-2.png";
import Restricted from "components/Restricted";
import axios from "axios";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

const GeneradorFichas = () => {
  const [items, setItems] = React.useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const rol = items.rol;

  const nav = useNavigate();

  //Theme
  const theme = useTheme();

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //BreadCrumbs
  function handleClickBreadCrumbs(event) {
    event.preventDefault();
  }

  //Routes
  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };

  //Modal Handlers
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Configuración PDF

  const [ejecutar, setEjecutar] = React.useState(true);
  const [beneficiario, setBeneficiario] = React.useState([]);
  const [estado, setEstado] = React.useState("");
  const [codigo, setCodigo] = React.useState("");
  const [sector, setSector] = React.useState(0);

  const handleEstado = (event) => {
    setEstado(event.target.value);
    if (event.target.value == "Zulia") {
      setSector(2);
      setEjecutar(true);
    } else {
      setSector(1);
      setEjecutar(true);
    }
  };

  const beneficiarios = async () => {
    setEjecutar(false);
    try {
      const { data1 } = await axios
        .get(`/transcripbeneficiariosirc/`, {
          params: {
            sector: sector,
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setBeneficiario(response.data);
          setCodigo(response.data[0].codigo);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const createPDF = async () => {
    const pdf = new jsPDF("portrait", "pt", "letter");
    const data = await html2canvas(document.querySelector("#pdf"));
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 50, pdfWidth, pdfHeight);
    //pdf.save("reporte_asistencia.pdf");
    window.open(
      pdf.output("bloburl", {
        filename: `fichas_registro_${new Date().toJSON().slice(0, 10)}.pdf`,
      }),
      "_blank"
    );
    try {
      const { data1 } = await axios
        .post("/crearfichas/", {
          codigo: codigo,
          sector: sector,
          estado: estado.slice(0, 1),
        })
        .then(function (response) {
          setEjecutar(true);
        })
        .catch(function (error) {
          setEjecutar(true);
        });
      setEjecutar(true);
      console.log(data1);
    } catch (error) {
      setEjecutar(true);
    }
  };
  if (ejecutar) {
    beneficiarios();
  }

  return (
    <div className="exclude">
      {rol === "1" || rol === "2" || rol==="6" ? (
        <>
          <Box m="1.5rem 2.5rem">
            <Box
              role="presentation"
              onClick={handleClickBreadCrumbs}
              sx={{ mb: 3 }}
            >
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  href="/inicio"
                  onClick={(event) => handleLinkHome(event, "home")}
                >
                  <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Inicio
                </Link>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  <ContentPasteOutlinedIcon
                    sx={{ mr: 0.5 }}
                    fontSize="inherit"
                  />
                  Generador de Fichas de Registro
                </Typography>
              </Breadcrumbs>
              <Box sx={{ minWidth: 120, mt: 3 }}>
                <FormControl sx={{ width: isSmallScreen ? "100%" : "30%" }}>
                  <InputLabel>Estado</InputLabel>
                  <Select
                    id="demo-simple-select"
                    value={estado}
                    label="Estado"
                    onChange={handleEstado}
                    sx={{
                      bgcolor: theme.palette.background.alt,
                    }}
                  >
                    <MenuItem value="">
                      <em>Seleccionar Estado...</em>
                    </MenuItem>
                    <MenuItem value={"Falcón"}>Falcón</MenuItem>
                    <MenuItem value={"Zulia"}>Zulia</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Box
                display="flex-end"
                sx={{
                  "& > :not(style)": { m: 1 },
                  position: "absolute",
                  bottom: "0",
                }}
                style={{ display: estado !== "" ? "block" : "none" }}
              >
                <Fab variant="extended" color="error" onClick={handleOpen}>
                  <CancelRoundedIcon sx={{ mr: 1 }} />
                  Cancelar
                </Fab>
                <Fab variant="extended" color="success" onClick={createPDF}>
                  <PrintIcon sx={{ mr: 1 }} />
                  Imprimir
                </Fab>
              </Box>
            </Box>
            <Box mt="10px" hidden={estado === "Zulia" || estado === "Falcón"}>
            <Container>
              <Card sx={{ boxShadow: 0, bgcolor: "transparent" }}>
                <CardContent>
                  <Typography className="text-center">
                    <InfoOutlinedIcon
                      sx={{
                        fontWeight: "bold",
                        mt: 5,
                        fontSize: 120,
                        color: "GrayText",
                      }}
                    />
                  </Typography>
                  <Typography
                    variant={isSmallScreen ? "h4" : "h3"}
                    className="text-center"
                    sx={{ mt: 5, mb: 5, color: "GrayText" }}
                  >
                    Selecciona un Estado para Generar sus Fichas.
                  </Typography>
                </CardContent>
              </Card>
            </Container>
          </Box>
            <div id="pdf" style={{ display: estado !== "" ? "block" : "none" }}>
              {/*<Container>*/}
              <Box
                gridColumn="span 8"
                gridRow="span 3"
                backgroundColor="white"
                borderRadius="1.5rem"
                p="1.5rem"
                mt="25px"
                height="fit-content"
              >
                <Grid container justifyContent="center">
                  <Grid item sm={5}>
                    <Box sx={{ border: "1px solid black", width: "100%" }}>
                      <Grid container>
                        <Grid item sm={2} ml={2} mt={1}>
                          <Box
                            display="flex"
                            component="img"
                            alt="icon"
                            src={icon}
                            height="50px"
                            width="50px"
                          />
                        </Grid>
                        <Grid item sm={7} className="text-center" mt={2} mb={3}>
                          <b style={{ color: "black" }}>
                            DATOS DE IDENTIFICACIÓN
                          </b>
                        </Grid>
                        <Grid item sm={9} className="text-end" mb={3} ml={3}>
                          <Typography sx={{ fontSize: 14 }}>
                            <b style={{ color: "black" }}>REGISTRO N°:</b>
                            {estado.slice(0, 1) + "-" + (Number(codigo) + 1)}
                          </Typography>
                        </Grid>
                        <Grid item sm={7} mb={5} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Nombre:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "62%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item className="text-start" sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              C.I.:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "100%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={8} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Apellidos:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "54%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={7} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Teléfono:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "62%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Sexo:
                            </b>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              M
                            </b>{" "}
                            <Box
                              sx={{ border: "2px solid black", width: "25%" }}
                            ></Box>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              F
                            </b>{" "}
                            <Box
                              sx={{ border: "2px solid black", width: "25%" }}
                            ></Box>
                          </Stack>
                        </Grid>
                        <Grid item sm={7} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Fecha de Nacimiento:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "40%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item className="text-start" sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Edad:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "50%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={9} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Comunidad:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "45%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item sm={5}>
                    <Box sx={{ border: "1px solid black", width: "100%" }}>
                      <Grid container>
                        <Grid item sm={2} ml={2} mt={1}>
                          <Box
                            display="flex"
                            component="img"
                            alt="icon"
                            src={icon}
                            height="50px"
                            width="50px"
                          />
                        </Grid>
                        <Grid item sm={7} className="text-center" mt={2} mb={3}>
                          <b style={{ color: "black" }}>
                            DATOS DE IDENTIFICACIÓN
                          </b>
                        </Grid>
                        <Grid item sm={9} className="text-end" mb={3} ml={3}>
                          <Typography sx={{ fontSize: 14 }}>
                            <b style={{ color: "black" }}>REGISTRO N°:</b>
                            {estado.slice(0, 1) + "-" + (Number(codigo) + 2)}
                          </Typography>
                        </Grid>
                        <Grid item sm={7} mb={5} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Nombre:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "62%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item className="text-start" sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              C.I.:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "100%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={8} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Apellidos:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "54%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={7} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Teléfono:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "62%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Sexo:
                            </b>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              M
                            </b>{" "}
                            <Box
                              sx={{ border: "2px solid black", width: "25%" }}
                            ></Box>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              F
                            </b>{" "}
                            <Box
                              sx={{ border: "2px solid black", width: "25%" }}
                            ></Box>
                          </Stack>
                        </Grid>
                        <Grid item sm={7} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Fecha de Nacimiento:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "40%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item className="text-start" sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Edad:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "50%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={9} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Comunidad:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "45%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item sm={5} mt={1}>
                    <Box sx={{ border: "1px solid black", width: "100%" }}>
                      <Grid container>
                        <Grid item sm={2} ml={2} mt={1}>
                          <Box
                            display="flex"
                            component="img"
                            alt="icon"
                            src={icon}
                            height="50px"
                            width="50px"
                          />
                        </Grid>
                        <Grid item sm={7} className="text-center" mt={2} mb={3}>
                          <b style={{ color: "black" }}>
                            DATOS DE IDENTIFICACIÓN
                          </b>
                        </Grid>
                        <Grid item sm={9} className="text-end" mb={3} ml={3}>
                          <Typography sx={{ fontSize:14 }}>
                          <b style={{ color: "black" }}>
                            REGISTRO N°:
                          </b>
                          {estado.slice(0, 1) + "-" + (Number(codigo) + 3)}
                          </Typography>
                        </Grid>
                        <Grid item sm={7} mb={5} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Nombre:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "62%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item className="text-start" sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              C.I.:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "100%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={8} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Apellidos:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "54%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={7} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Teléfono:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "62%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Sexo:
                            </b>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              M
                            </b>{" "}
                            <Box
                              sx={{ border: "2px solid black", width: "25%" }}
                            ></Box>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              F
                            </b>{" "}
                            <Box
                              sx={{ border: "2px solid black", width: "25%" }}
                            ></Box>
                          </Stack>
                        </Grid>
                        <Grid item sm={7} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Fecha de Nacimiento:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "40%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item className="text-start" sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Edad:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "50%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={9} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Comunidad:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "45%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item sm={5} mt={1}>
                    <Box sx={{ border: "1px solid black", width: "100%" }}>
                      <Grid container>
                        <Grid item sm={2} ml={2} mt={1}>
                          <Box
                            display="flex"
                            component="img"
                            alt="icon"
                            src={icon}
                            height="50px"
                            width="50px"
                          />
                        </Grid>
                        <Grid item sm={7} className="text-center" mt={2} mb={3}>
                          <b style={{ color: "black" }}>
                            DATOS DE IDENTIFICACIÓN
                          </b>
                        </Grid>
                        <Grid item sm={9} className="text-end" mb={3} ml={3}>
                          <Typography sx={{ fontSize:14 }}>
                          <b style={{ color: "black" }}>
                            REGISTRO N°:
                          </b>
                          {estado.slice(0, 1) + "-" + (Number(codigo) + 4)}
                          </Typography>
                        </Grid>
                        <Grid item sm={7} mb={5} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Nombre:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "62%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item className="text-start" sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              C.I.:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "100%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={8} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Apellidos:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "54%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={7} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Teléfono:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "62%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Sexo:
                            </b>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              M
                            </b>{" "}
                            <Box
                              sx={{ border: "2px solid black", width: "25%" }}
                            ></Box>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              F
                            </b>{" "}
                            <Box
                              sx={{ border: "2px solid black", width: "25%" }}
                            ></Box>
                          </Stack>
                        </Grid>
                        <Grid item sm={7} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Fecha de Nacimiento:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "40%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item className="text-start" sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Edad:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "50%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={9} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Comunidad:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "45%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item sm={5} mt={1}>
                    <Box sx={{ border: "1px solid black", width: "100%" }}>
                      <Grid container>
                        <Grid item sm={2} ml={2} mt={1}>
                          <Box
                            display="flex"
                            component="img"
                            alt="icon"
                            src={icon}
                            height="50px"
                            width="50px"
                          />
                        </Grid>
                        <Grid item sm={7} className="text-center" mt={2} mb={3}>
                          <b style={{ color: "black" }}>
                            DATOS DE IDENTIFICACIÓN
                          </b>
                        </Grid>
                        <Grid item sm={9} className="text-end" mb={3} ml={3}>
                          <Typography sx={{ fontSize:14 }}>
                          <b style={{ color: "black" }}>
                            REGISTRO N°:
                          </b>
                          {estado.slice(0, 1) + "-" + (Number(codigo) + 5)}
                          </Typography>
                        </Grid>
                        <Grid item sm={7} mb={5} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Nombre:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "62%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item className="text-start" sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              C.I.:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "100%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={8} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Apellidos:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "54%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={7} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Teléfono:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "62%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Sexo:
                            </b>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              M
                            </b>{" "}
                            <Box
                              sx={{ border: "2px solid black", width: "25%" }}
                            ></Box>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              F
                            </b>{" "}
                            <Box
                              sx={{ border: "2px solid black", width: "25%" }}
                            ></Box>
                          </Stack>
                        </Grid>
                        <Grid item sm={7} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Fecha de Nacimiento:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "40%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item className="text-start" sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Edad:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "50%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={9} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Comunidad:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "45%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item sm={5} mt={1}>
                    <Box sx={{ border: "1px solid black", width: "100%" }}>
                      <Grid container>
                        <Grid item sm={2} ml={2} mt={1}>
                          <Box
                            display="flex"
                            component="img"
                            alt="icon"
                            src={icon}
                            height="50px"
                            width="50px"
                          />
                        </Grid>
                        <Grid item sm={7} className="text-center" mt={2} mb={3}>
                          <b style={{ color: "black" }}>
                            DATOS DE IDENTIFICACIÓN
                          </b>
                        </Grid>
                        <Grid item sm={9} className="text-end" mb={3} ml={3}>
                          <Typography sx={{ fontSize: 14 }}>
                            <b style={{ color: "black" }}>
                              REGISTRO N°:
                            </b>
                            {estado.slice(0, 1) + "-" + (Number(codigo) + 6)}
                          </Typography>
                        </Grid>
                        <Grid item sm={7} mb={5} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Nombre:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "62%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item className="text-start" sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              C.I.:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "100%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={8} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Apellidos:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "54%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={7} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Teléfono:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "62%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Sexo:
                            </b>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              M
                            </b>{" "}
                            <Box
                              sx={{ border: "2px solid black", width: "25%" }}
                            ></Box>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              F
                            </b>{" "}
                            <Box
                              sx={{ border: "2px solid black", width: "25%" }}
                            ></Box>
                          </Stack>
                        </Grid>
                        <Grid item sm={7} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Fecha de Nacimiento:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "40%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item className="text-start" sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Edad:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "50%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={9} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Comunidad:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "45%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item sm={5} mt={1}>
                    <Box sx={{ border: "1px solid black", width: "100%" }}>
                      <Grid container>
                        <Grid item sm={2} ml={2} mt={1}>
                          <Box
                            display="flex"
                            component="img"
                            alt="icon"
                            src={icon}
                            height="50px"
                            width="50px"
                          />
                        </Grid>
                        <Grid item sm={7} className="text-center" mt={2} mb={3}>
                          <b style={{ color: "black" }}>
                            DATOS DE IDENTIFICACIÓN
                          </b>
                        </Grid>
                        <Grid item sm={9} className="text-end" mb={3} ml={3}>
                          <Typography sx={{ fontSize:14 }}>
                          <b style={{ color: "black" }}>
                            REGISTRO N°:
                          </b>
                          {estado.slice(0, 1) + "-" + (Number(codigo) + 7)}
                          </Typography>
                        </Grid>
                        <Grid item sm={7} mb={5} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Nombre:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "62%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item className="text-start" sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              C.I.:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "100%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={8} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Apellidos:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "54%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={7} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Teléfono:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "62%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Sexo:
                            </b>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              M
                            </b>{" "}
                            <Box
                              sx={{ border: "2px solid black", width: "25%" }}
                            ></Box>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              F
                            </b>{" "}
                            <Box
                              sx={{ border: "2px solid black", width: "25%" }}
                            ></Box>
                          </Stack>
                        </Grid>
                        <Grid item sm={7} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Fecha de Nacimiento:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "40%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item className="text-start" sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Edad:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "50%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={9} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Comunidad:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "45%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item sm={5} mt={1}>
                    <Box sx={{ border: "1px solid black", width: "100%" }}>
                      <Grid container>
                        <Grid item sm={2} ml={2} mt={1}>
                          <Box
                            display="flex"
                            component="img"
                            alt="icon"
                            src={icon}
                            height="50px"
                            width="50px"
                          />
                        </Grid>
                        <Grid item sm={7} className="text-center" mt={2} mb={3}>
                          <b style={{ color: "black" }}>
                            DATOS DE IDENTIFICACIÓN
                          </b>
                        </Grid>
                        <Grid item sm={9} className="text-end" mb={3} ml={3}>
                          <Typography sx={{ fontSize:14 }}>
                          <b style={{ color: "black" }}>
                            REGISTRO N°:
                          </b>
                          {estado.slice(0, 1) + "-" + (Number(codigo) + 8)}
                          </Typography>
                        </Grid>
                        <Grid item sm={7} mb={5} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Nombre:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "62%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item className="text-start" sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              C.I.:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "100%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={8} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Apellidos:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "54%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={7} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Teléfono:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "62%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Sexo:
                            </b>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              M
                            </b>{" "}
                            <Box
                              sx={{ border: "2px solid black", width: "25%" }}
                            ></Box>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              F
                            </b>{" "}
                            <Box
                              sx={{ border: "2px solid black", width: "25%" }}
                            ></Box>
                          </Stack>
                        </Grid>
                        <Grid item sm={7} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Fecha de Nacimiento:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "40%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item className="text-start" sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Edad:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "50%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={9} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Comunidad:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "45%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item sm={5} mt={1}>
                    <Box sx={{ border: "1px solid black", width: "100%" }}>
                      <Grid container>
                        <Grid item sm={2} ml={2} mt={1}>
                          <Box
                            display="flex"
                            component="img"
                            alt="icon"
                            src={icon}
                            height="50px"
                            width="50px"
                          />
                        </Grid>
                        <Grid item sm={7} className="text-center" mt={2} mb={3}>
                          <b style={{ color: "black" }}>
                            DATOS DE IDENTIFICACIÓN
                          </b>
                        </Grid>
                        <Grid item sm={9} className="text-end" mb={3} ml={3}>
                          <Typography sx={{ fontSize:14 }}>
                          <b style={{ color: "black" }}>
                            REGISTRO N°:
                          </b>
                          {estado.slice(0, 1) + "-" + (Number(codigo) + 9)}
                          </Typography>
                        </Grid>
                        <Grid item sm={7} mb={5} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Nombre:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "62%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item className="text-start" sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              C.I.:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "100%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={8} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Apellidos:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "54%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={7} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Teléfono:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "62%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Sexo:
                            </b>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              M
                            </b>{" "}
                            <Box
                              sx={{ border: "2px solid black", width: "25%" }}
                            ></Box>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              F
                            </b>{" "}
                            <Box
                              sx={{ border: "2px solid black", width: "25%" }}
                            ></Box>
                          </Stack>
                        </Grid>
                        <Grid item sm={7} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Fecha de Nacimiento:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "40%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item className="text-start" sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Edad:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "50%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={9} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Comunidad:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "45%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item sm={5} mt={1}>
                    <Box sx={{ border: "1px solid black", width: "100%" }}>
                      <Grid container>
                        <Grid item sm={2} ml={2} mt={1}>
                          <Box
                            display="flex"
                            component="img"
                            alt="icon"
                            src={icon}
                            height="50px"
                            width="50px"
                          />
                        </Grid>
                        <Grid item sm={7} className="text-center" mt={2} mb={3}>
                          <b style={{ color: "black" }}>
                            DATOS DE IDENTIFICACIÓN
                          </b>
                        </Grid>
                        <Grid item sm={9} className="text-end" mb={3} ml={3}>
                          <Typography sx={{ fontSize:14 }}>
                          <b style={{ color: "black" }}>
                            REGISTRO N°:
                          </b>
                          {estado.slice(0, 1) + "-" + (Number(codigo) + 10)}
                          </Typography>
                        </Grid>
                        <Grid item sm={7} mb={5} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Nombre:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "62%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item className="text-start" sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              C.I.:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "100%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={8} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Apellidos:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "54%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={7} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Teléfono:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "62%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Sexo:
                            </b>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              M
                            </b>{" "}
                            <Box
                              sx={{ border: "2px solid black", width: "25%" }}
                            ></Box>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              F
                            </b>{" "}
                            <Box
                              sx={{ border: "2px solid black", width: "25%" }}
                            ></Box>
                          </Stack>
                        </Grid>
                        <Grid item sm={7} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Fecha de Nacimiento:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "40%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item className="text-start" sm={3} mb={3} ml={2}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Edad:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "50%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                        <Grid item sm={9} mb={3} ml={1}>
                          <Stack direction="row" spacing={2}>
                            <b style={{ fontSize: "11px", color: "black" }}>
                              Comunidad:
                            </b>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                width: "45%",
                              }}
                            ></div>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/*</Container>*/}
            </div>
          </Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-description"
                align="center"
                sx={{ mt: 2 }}
              >
                ¿Está seguro de cancelar esta operación?
              </Typography>
              <Divider sx={{ mt: 2 }}></Divider>
              <Grid
                container
                sx={{ mt: 1 }}
                spacing={2}
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleClose}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{ bgcolor: "teal", color: "white" }}
                    onClick={(event) => handleLinkHome(event, "home")}
                  >
                    Si
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </>
      ) : (
        <Box m="1.5rem 2.5rem">
          <Restricted />
        </Box>
      )}
    </div>
  );
};

export default GeneradorFichas;
