import React, { useState, useMemo, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
//import { esES } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import "dayjs/locale/es";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import HomeIcon from "@mui/icons-material/Home";
import InventoryOutlined from "@mui/icons-material/InventoryOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearchOutlined";
import Restricted from "components/Restricted";
import clienteAxios from "../../config/clienteAxios";
import axios from "axios";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ListSubheader,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

const allOptions = ["Acetaminofen", "Metronidazol", "Ibuprofeno", "Albendazol"];

const ProcesarSolicitud = () => {
  const nav = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("prop");
  const proyectoConv = JSON.parse(token);

  const [selectedOption, setSelectedOption] = useState(allOptions[0]);

  const [searchText, setSearchText] = useState("");
  const displayedOptions = useMemo(
    () => allOptions.filter((option) => containsText(option, searchText)),
    [searchText]
  );

  const [items, setItems] = React.useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const rol = items.rol;

  //Theme
  const theme = useTheme();

  //BreadCrumbs
  function handleClickBreadCrumbs(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  //DatePicker Values
  const [valueFechaSol, setValueFechaSol] = React.useState(proyectoConv.fecha);
  const [valueFechaCon, setValueFechaCon] = React.useState(
    proyectoConv.fecha_convocatoria
  );

  //Inputs Values
  const [valueProyecto, setValueProyecto] = React.useState(
    proyectoConv.nombrepro
  );
  const handleValueProyecto = (event) => {
    setValueProyecto(event.target.value);
  };
  const [valueConvocatoria, setValueConvocatoria] = React.useState(
    proyectoConv.nombreact
  );
  const handleValueConvocatoria = (event) => {
    setValueConvocatoria(event.target.value);
  };

  //Articulos Inputs
  let sol = JSON.parse(proyectoConv.solicitud);
  const [data, setData] = useState(sol);

  const handleClick = () => {
    setData([
      ...data,
      { articulo: "", solicitado: "", entregado: "", devuelto: "", usado: "" },
    ]);
  };
  const handleChangeInput = (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...data];
    onchangeVal[i][name] = value;
    setData(onchangeVal);
  };
  const handleDelete = (i) => {
    const deleteVal = [...data];
    deleteVal.splice(i, 1);
    setData(deleteVal);
  };

  //Modal Handlers
  const [openCancel, setOpenCancel] = React.useState(false);
  const handleOpenCancel = () => setOpenCancel(true);
  const handleCloseCancel = () => setOpenCancel(false);

  const [openAlert, setOpenAlert] = React.useState(false);
  const handleOpenAlert = () => {
    setOpenAlert(true);
    handleCloseAlert();
  };
  const handleCloseAlert = () =>
    setTimeout(() => {
      setOpenAlert(false);
      nav("/suministros");
    }, 2000);

  //Modal Styles
  const style = {
    position: "inherit",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    borderRadius: 4,
    boxShadow: 1,
    p: 4,
  };

  //Routes
  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };
  const handleLinkSum = (event, message) => {
    if (message === "suministros") {
      nav("/suministros");
    }
  };
  const handleLinkCancel = (event, message) => {
    if (message === "cancelar") {
      nav("/suministros");
    }
  };

  const handleSubmit = async (event) => {
    try {
      const { data1 } = await axios
        .put("/actializarsolicitud/", {
          id: proyectoConv.idsolicitudes,
          fecha: valueFechaSol,
          solicitud: JSON.stringify(data),
          estado: 2,

          // //        nombres: (dataform.get('nombreProyecto')),
          // //        apellidos: (dataform.get('codigoProyecto')),
          // //        cedula: (dataform.get('descripcion')),
          // //        correo: dataform.get('email'),
          // //        telefono: dataform.get('textmask'),
          // //        //fnacimiento:new Date(selectedDate).toLocaleDateString('es-ES') ,
          // //        ocupacion: dataform.get('ocupacion'),
          // //        direccion: dataform.get('direccion'),
        })
        .then(function (response) {
          //console.log(response.data.idToken)
          //localStorage.setItem('token',JSON.stringify(response.data) )
          //setAuth(data)
          //event.target.reset();
        })
        .catch(function (error) {
          // //        event.preventDefault();
          // //        console.log('error')
          // //       // document.getElementById(":r7:").value='';
        });
    } catch (error) {}

    handleOpenAlert();
  };
  return (
    <Box m="1.5rem 2.5rem">
      {rol === "1" || rol === "2" || rol === "5" || rol === "7" ? (
        <>
          <Box
            role="presentation"
            onClick={handleClickBreadCrumbs}
            sx={{ mb: 3 }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/inicio"
                onClick={(event) => handleLinkHome(event, "home")}
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Inicio
              </Link>
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/suministros"
                onClick={(event) => handleLinkSum(event, "suministros")}
              >
                <InventoryOutlined sx={{ mr: 0.5 }} fontSize="inherit" />
                Suministros
              </Link>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <ContentPasteSearchOutlinedIcon
                  sx={{ mr: 0.5 }}
                  fontSize="inherit"
                />
                Procesar Solicitud
              </Typography>
            </Breadcrumbs>
          </Box>
          <Container>
            <Box
              gridColumn="span 8"
              gridRow="span 3"
              backgroundColor={theme.palette.background.alt}
              p="1.5rem"
              borderRadius="1.5rem"
              mt="40px"
              height="fit-content"
              sx={{ boxShadow: 4 }}
            >
              <Typography variant="h4" sx={{ mt: 2 }}>
                <b>Solicitud de Suministros:</b>
              </Typography>
              <Divider sx={{ mt: 3, mb: 4 }}>
                <Chip label="INFORMACIÓN GENERAL" />
              </Divider>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="es"
                  >
                    <DesktopDatePicker
                      label="Fecha de Solicitud"
                      disabled
                      name="fechaSol"
                      inputFormat="DD/MM/YYYY"
                      value={valueFechaSol}
                      onChange={setValueFechaSol}
                      disablePast
                      renderInput={(params) => {
                        return <TextField fullWidth {...params} />;
                      }}
                      clearable
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="es"
                  >
                    <DesktopDatePicker
                      label="Fecha de Convocatoria"
                      name="fechaCon"
                      inputFormat="DD/MM/YYYY"
                      value={valueFechaCon}
                      onChange={setValueFechaCon}
                      disabled
                      renderInput={(params) => {
                        return <TextField fullWidth {...params} />;
                      }}
                      clearable
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} mt={1}>
                  <TextField
                    fullWidth
                    id="proyecto"
                    label="Proyecto"
                    name="proyecto"
                    value={valueProyecto}
                    onChange={handleValueProyecto}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6} mt={1}>
                  <TextField
                    fullWidth
                    id="convocatoria"
                    label="Convocatoria"
                    value={valueConvocatoria}
                    onChange={handleValueConvocatoria}
                    disabled
                  />
                </Grid>
              </Grid>
              <Divider sx={{ mt: 4 }}>
                <Chip label="SUMINISTROS" />
              </Divider>
              {data.map((val, i) => (
                <div>
                  <Grid
                    container
                    spacing={2}
                    sx={{ mt: 1 }}
                    justifyContent="center"
                  >
                    <Grid item xs={12} sm={5}>
                      <FormControl fullWidth>
                        <InputLabel id="search-select-label">
                          Artículo
                        </InputLabel>
                        <Select
                          MenuProps={{ autoFocus: false }}
                          labelId="search-select-label"
                          id="articulo"
                          value={val.articulo}
                          name="articulo"
                          label="Artículo"
                          disabled
                          onChange={(e) => {
                            setSelectedOption(e.target.value);
                            handleChangeInput(e, i);
                          }}
                          onClose={() => setSearchText("")}
                          renderValue={() => val.articulo}
                        >
                          <ListSubheader sx={{ background: "transparent" }}>
                            <TextField
                              size="small"
                              autoFocus
                              placeholder="Buscar Artículo..."
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => setSearchText(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key !== "Escape") {
                                  e.stopPropagation();
                                }
                              }}
                            />
                          </ListSubheader>
                          {displayedOptions.map((option, i) => (
                            <MenuItem key={i} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        id="entregado"
                        label="Entregado"
                        fullWidth
                        required
                        name="entregado"
                        value={val.entregado}
                        onChange={(e) => handleChangeInput(e, i)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        id="solicitado"
                        label="Solicitado"
                        name="solicitado"
                        value={val.solicitado}
                        onChange={(e) => handleChangeInput(e, i)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        hidden
                        id="devuelto"
                        label="Devuelto"
                        name="devuelto"
                        value={val.devuelto}
                        onChange={(e) => handleChangeInput(e, i)}
                      />
                    </Grid>
                    <Grid item xs={5} sm={1} sx={{ mt: 1 }}>
                      <Tooltip title="Eliminar Artículo">
                        <Button
                          variant="contained"
                          hidden
                          color="error"
                          fullWidth
                          sx={{ borderRadius: 2 }}
                          onClick={() => handleDelete(i)}
                        >
                          <DeleteIcon />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </div>
              ))}
              <Grid container justifyContent="center">
                <Grid item xs={8} sm={1}>
                  <Button
                    hidden
                    variant="contained"
                    sx={{ bgcolor: "teal", color: "white", borderRadius: 2 }}
                    fullWidth
                    onClick={handleClick}
                  >
                    Añadir Más
                  </Button>
                </Grid>
              </Grid>
              {/*<p>{JSON.stringify(data)}</p>*/}
              <Divider sx={{ mt: 3, mb: 2 }} />
              <Grid
                container
                sx={{ mt: 2 }}
                spacing={1}
                justifyContent="center"
              >
                <Grid item sm={3} xs={6}>
                  <Button
                    variant="contained"
                    color="error"
                    size="large"
                    onClick={handleOpenCancel}
                    fullWidth
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item sm={3} xs={6}>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{ bgcolor: "teal", color: "white" }}
                    size="large"
                    disabled={
                      !valueFechaSol ||
                      !valueFechaCon ||
                      !valueProyecto ||
                      !valueConvocatoria
                    }
                    fullWidth
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>

          <Modal
            open={openCancel}
            onClose={handleCloseCancel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            slotProps={{ backdrop: { style: { opacity: 0.2 } } }}
          >
            <Box sx={style}>
              <Typography variant="h6" className="text-center" sx={{ mb: 1.5 }}>
                ¿Está seguro de cancelar esta operación?
              </Typography>
              <Divider sx={{ mt: 2 }} />
              <Grid
                container
                sx={{ mt: 1 }}
                spacing={1}
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleCloseCancel}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{ bgcolor: "teal", color: "white" }}
                    onClick={(event) => handleLinkCancel(event, "cancelar")}
                  >
                    Si
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>

          <Modal
            open={openAlert}
            onClose={handleCloseAlert}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" className="text-center">
                <TaskAltOutlinedIcon
                  color="success"
                  sx={{ fontWeight: "bold", mt: 3, fontSize: 80 }}
                />
              </Typography>
              <Typography
                id="modal-modal-description"
                className="text-center"
                sx={{ mt: 2, fontSize: 20, fontWeight: "bold" }}
              >
                ¡Solicitud procesada exitosamente!
              </Typography>
            </Box>
          </Modal>
        </>
      ) : (
        <Restricted />
      )}
    </Box>
  );
};

export default ProcesarSolicitud;
