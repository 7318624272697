import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography/Typography";
import Container from "@mui/material/Container";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import GoogleMapReact from "google-map-react";
import MyMarker from "./myMarkers";
import HomeIcon from "@mui/icons-material/Home";
import CasesRoundedIcon from "@mui/icons-material/CasesRounded";
import LeaderboardRoundedIcon from "@mui/icons-material/LeaderboardRounded";
import { Map } from "@mui/icons-material";
import axios from "axios";

export default function App() {
  //Navigation
  const nav = useNavigate();

  //BreadCrumbs
  function handleClickBreadCrumbs(event) {
    event.preventDefault();
  }

  //Routes
  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };

  const handleLinkProjects = (event, message) => {
    if (message === "proyectos") {
      nav("/proyectos");
    }
  };

  const handleLinkStats = (event, message) => {
    if (message === "stats") {
      nav("/estadisticasGenerales");
    }
  };

  const [ejecutar, setEjecutar] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [dataConsolidadoNinos, setDataConsolidadoNinos] = React.useState([]);
  const [heatPoints, setHeatPoints] = React.useState([]);
  const convocatorias = async () => {
    try {
      const { data1 } = await axios
        .get("/convocatorias/", {})
        .then(function (response) {
          // setAlerta({})
          console.log(response.data, "se ejecuto");
          setData(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const consolidadoNinos = async () => {
    try {
      const { data1 } = await axios
        .get("/consolidadoninos/", {
          params: {
            id: 33,
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);

          setDataConsolidadoNinos(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const points = [
    { id: 1, title: "Zulia", lat: 9.8333333333333, lng: -72.25, weight: 2500 },

    { id: 3, title: "Barquisimeto", lat: 10.0647, lng: -69.35703, weight: 950 },
  ];
  const renderMarkers = (map, maps) => {
    var heatsPoints = [];
    for (let i = 1; i < data.length; i++) {
      for (let l = 0; l < dataConsolidadoNinos.length; l++) {
        console.log("holaaaaa");
        if (data[i].comunidad == dataConsolidadoNinos[l].comunidad) {
          let intensidad;
          if (
            Number(dataConsolidadoNinos[l].ninoMam) +
              Number(dataConsolidadoNinos[l].ninoSam) >
            100
          ) {
            intensidad = 0.5;
          } else {
            if (
              Number(dataConsolidadoNinos[l].ninoMam) +
                Number(dataConsolidadoNinos[l].ninoSam) >
              50
            ) {
              intensidad = 1;
            } else {
              intensidad = 0;
            }
          }
          heatsPoints[i] = {
            lat: Number(data[i].latitud),
            lng: Number(data[i].longitud),
            weight: intensidad,
          };
        }
      }
    }

    setHeatPoints(heatsPoints);

    console.log(heatsPoints);

    let marcas = [];
    // for (let j = 1; j < data.length; j++) {

    //     marcas[j] = new maps.Marker({
    //       position: { lat: Number(data[j].latitud), lng:Number(data[j].longitud)},
    //       map,
    //       title: data[j].comunidad,
    //     })

    //     }
    // let marker1 = new maps.Marker({
    //   position: { lat: 9.8333333333333, lng: -72.25 },
    //   map,
    //   title: "Hello World!",
    // });

    // let marker2 = new maps.Marker({
    //   position: { lat: 10.0647, lng: -69.35703 },
    //   map,
    //   title: "Hello World!",
    // });

    for (let j = 0; j < marcas.length; j++) {
      console.log(marcas[j]);
      return marcas[j];
    }
  };
  if (ejecutar) {
    convocatorias();
    consolidadoNinos();
  }
  if (data.length < 1 || dataConsolidadoNinos.length < 1)
    return (
      <Container component="main" maxWidth="xs">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  return (
    <div
      className="App"
      style={{
        height: "75%",
        width: "95%",
        marginTop: "35px",
        marginLeft: "50px",
      }}
    >
      <Box role="presentation" onClick={handleClickBreadCrumbs} sx={{ mb: 3 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            href="/inicio"
            onClick={(event) => handleLinkHome(event, "home")}
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Inicio
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            href="/proyectos"
            onClick={(event) => handleLinkProjects(event, "proyectos")}
          >
            <CasesRoundedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Proyectos
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            href="/estadisticasGenerales"
            onClick={(event) => handleLinkStats(event, "stats")}
          >
            <LeaderboardRoundedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Estadísticas Generales
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="text.primary"
          >
            <Map sx={{ mr: 0.5 }} fontSize="inherit" />
            Mapa de Calor
          </Typography>
        </Breadcrumbs>
        <Typography variant="h3" sx={{ mt: 2 }}>
          <b>Mapa de Calor: </b>VNZ/PCA202398/PD2023274
        </Typography>
      </Box>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyBsjqm0MIju2qroTB6CWPmTHDQgRsHa2S8",
          language: "es",
          region: "US",
        }}
        defaultCenter={{ lat: 10.66663, lng: -71.61245 }}
        defaultZoom={9}
        //distanceToMouse={distanceToMouse}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        heatmap={{
          positions: heatPoints,
          options: { radius: 20, opacity: 10 },
        }}
        heatmapLibrary={true}
        options={getMapOptions}
      >
        {points.map(({ lat, lng, id, title, weight }) => {
          return (
            <MyMarker
              key={id}
              lat={lat}
              lng={lng}
              text={id}
              tooltip={title + " - " + weight + " Pacientes"}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
}

const LIST_OF_POINTS = [
  {
    lat: 9.8333333333333,
    lng: -72.25,
    weight: 2500,
  },
  {
    lat: 10.48801,
    lng: -66.87919,
    weight: 5000,
  },
  {
    lat: 10.0647,
    lng: -69.35703,
    weight: 990,
  },
  //Add as many coordinates you want.
];

const getMapOptions = (maps) => {
  return {
    streetViewControl: false,
    scaleControl: true,
    fullscreenControl: true,
    styles: [
      {
        featureType: "poi.business",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
    ],
    gestureHandling: "greedy",
    disableDoubleClickZoom: false,
    mapTypeControl: true,
    dissipating: true,
    mapTypeId: maps.MapTypeId.ROADMAP,
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.TOP_LEFT,
      mapTypeIds: [
        maps.MapTypeId.ROADMAP,
        maps.MapTypeId.SATELLITE,
        maps.MapTypeId.HYBRID,
      ],
    },

    zoomControl: true,
    clickableIcons: true,
  };
};
