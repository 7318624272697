import React, { useMemo, useEffect } from "react";
import { ResponsiveBar } from "@nivo/bar";
import axios from "axios";
const BarGraph = (dato) => {
  const [ejecutar, setEjecutar] = React.useState(true);
  const [datos, setDatos] = React.useState([]);
  var fechaInicio=dato.fecha;
  var categoria=dato.categoria
  const [fechaFin, setFechaFin] = React.useState('');
  const [tabla, setTabla] = React.useState('niñoscinco');
  const [endpoint, setEnpoint] = React.useState('meses');
  const [data1, setData1] = React.useState([]);
  const [data2, setData2] = React.useState([]);
  const [data3, setData3] = React.useState([]);
  
  const beneficiariosNut = async () => {
    try {
      const { data2 } = await axios
        .get("/municipiosdiagnosticosPrueba/", {
          params: {
            fechaInicio: fechaInicio,
            fechaFin: fechaFin
          },
        })
        .then(function (response) {
          // setAlerta({})
        
          let lista = response.data;
        
          let nuevoObjeto = {}
          
          //Recorremos el arreglo 
          lista.forEach( x => {
            //Si la ciudad no existe en nuevoObjeto entonces
            //la creamos e inicializamos el arreglo de profesionales. 
            if( !nuevoObjeto.hasOwnProperty(x.municipio)){
              nuevoObjeto[x.municipio] = {
                diagnosticos: []
              }
            }
            
          
            
          })
          let container = [];
         
          let vacio=[];
          
          let municipios = Object.keys(nuevoObjeto);
          console.log(municipios,"datos Diagnostico ");
          for (let i = 0; i < municipios.length; i++) {
            let arreglo={};
                        for (let j = 0; j < lista.length; j++) {
              
            if(municipios[i] === lista[j].municipio){
           arreglo[lista[j].clasificacion_pb]=lista[j].total
           arreglo.municipio=lista[j].municipio
          
            }
           
            
            }
            container=arreglo;
            vacio=vacio.concat(container)
            console.log(vacio,"datos Diagnostico ");
         
           
          }
     
        
         
          
       

          setDatos(vacio);
          setEjecutar(false);

          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  let etiquetas=["Desnutrición Aguda Moderada",
  
  "Desnutrición Aguda Severa",

  "Peso Adecuado",

  "Sobrepeso",

  "municipio",
  
  "Cañada De Urdaneta"]
  useEffect(() => {
  console.log("cambio")
    if(fechaInicio =='2022-01-01'){

let datoPrueba=[];
let datosmuni=[];

for (let index = 0; index < datos.length; index++) {
  let acumulador={}
  acumulador=datos[index]


  datosmuni=acumulador;
  datoPrueba=datoPrueba.concat(acumulador)
  console.log(datoPrueba,"dat1");
  
}

      let datos1 = [
        {
          municipio: "Indígena Bolivariano Guajira",
          Adecuado:2000,
           "Desnutrición_aguda_moderada":8,
            "Desnutrición_aguda_severa":1,
            "Referencia_valoracion_medica":0,
             "Riesgo_de_desnutricion":75
        
         
        },
        {
          municipio: "Machiques de Perijá",
          
          "Adecuado":0,
           "Desnutrición aguda moderada":0,
            "Desnutrición aguda severa":0,
            "Referencia valoracion medica":0,
             "Riesgo de desnutricion":0
        },
        {
          municipio: "Mara",
          
          "Adecuado":0,
           "Desnutrición aguda moderada":0,
            "Desnutrición aguda severa":0,
            "Referencia valoracion medica":0,
             "Riesgo de desnutricion":0
        },
        {
          municipio: "Maracaibo",
          "Adecuado":0,
           "Desnutrición aguda moderada":0,
            "Desnutrición aguda severa":0,
            "Referencia valoracion medica":0,
             "Riesgo de desnutricion":0
        },
        {
          municipio: "Rosario de Perija",
         
          "Adecuado":0,
           "Desnutrición aguda moderada":0,
            "Desnutrición aguda severa":0,
            "Referencia valoracion medica":0,
             "Riesgo de desnutricion":0
        },
        {
          municipio: "San Francisco",
          "Adecuado":0,
           "Desnutrición aguda moderada":0,
            "Desnutrición aguda severa":0,
            "Referencia valoracion medica":0,
             "Riesgo de desnutricion":0
        },
    
      ];
      let datos2 = [
        {
          municipio: "Indígena Bolivariano Guajira",
          "Peso Adecuado":0,
          "Bajo Peso":0,
        
          
        
         
        },
        {
          municipio: "Machiques de Perijá",
          
          "Peso Adecuado":0,
           "Bajo Peso":0,
         
        },
        {
          municipio: "Mara",
          
          "Peso Adecuado":0,
           "Desnutrición aguda moderada":0,
           
        },
        {
          municipio: "Maracaibo",
          "Peso Adecuado":0,
           "Bajo Peso":0,
         
        },
        {
          municipio: "Rosario de Perija",
         
          "Peso Adecuado":0,
           "Bajo Peso":0,
         
        },
        {
          municipio: "San Francisco",
          "Peso Adecuado":0,
           "Desnutrición aguda moderada":0,
        
        },
    
      ];
    
      let datos4 = [
        {
          
          municipio: "Indígena Bolivariano Guajira",
          
          "Total":0,
          
          
        
         
        },
        {
          
          municipio: "Elias Sanchez Rubio",
          "Total":0,
          
          
        
         
        },
        {
          municipio: "Machiques de Perijá",
          
          "Total":0,
           
         
        },
        {
          municipio: "Mara",
          
          "Total":0,
      
           
        },
        {
          municipio: "Maracaibo",
          "Total":0,
          
         
        },
        {
          municipio: "Rosario de Perija",
         
          "Total":0,
          
         
        },
        {
          municipio: "San Francisco",
          "Total":0,
          
        
        },
        {
          municipio: "Sinamaica",
          "Total":0,
          
        
        },
        {
          municipio: "Idalfonso Vasquez",
          "Total":0,
          
        
        },
        {
          municipio: "Villa del Rosario",
          "Total":0,
          
        
        },
        {
          municipio: "San Rafael del Mojan",
          "Total":0,
          
        
        },
        {
          municipio: "Francisco Eugenio Bustamante",
          "Total":0,
          
        
        },
        {
          municipio: "Sixto Zambrano",
          "Total":0,
          
        
        },
        {
          municipio: "Tamare",
          "Total":0,
          
        
        },
        {
          municipio: "Ricaurie",
          "Total":0,
          
        
        },
        {
          municipio: "Libertad",
          "Total":0,
          
        
        },
      ];
      setData1(datoPrueba)
      setData2(datos2)
      setData3(datos4)
      setFechaFin('2022-12-31')
      setEjecutar(true);
    } else{

      if(fechaInicio =='2023-01-01'){
        let datoPrueba=[];
let datosmuni=[];

for (let index = 0; index < datos.length; index++) {
  let acumulador={}
  acumulador=datos[index]


  datosmuni=acumulador;
  datoPrueba=datoPrueba.concat(acumulador)
  console.log(datoPrueba,"dat1");
  
}
        let datos3 = [
          {
            municipio: "Indígena Bolivariano Guajira",
            "Peso Adecuado":843,
            "Bajo Peso":126,
          
            
          
           
          },
          {
            municipio: "Machiques de Perijá",
            
            "Peso Adecuado":245,
             "Bajo Peso":13,
           
          },
          {
            municipio: "Mara",
            
            "Peso Adecuado":1021,
             "Desnutrición aguda moderada":87,
             
          },
          {
            municipio: "Maracaibo",
            "Peso Adecuado":1500,
             "Bajo Peso":174,
           
          },
          {
            municipio: "Rosario de Perija",
           
            "Peso Adecuado":474,
             "Bajo Peso":39,
           
          },
          {
            municipio: "San Francisco",
            "Peso Adecuado":104,
             "Desnutrición aguda moderada":17,
          
          },
      
        ];
      
        let datos5 = [
          {
            
            municipio: "Indígena Bolivariano Guajira",
            
            "Total":6094,
            
            
          
           
          },
          {
            
            municipio: "Elias Sanchez Rubio",
            "Total":198,
            
            
          
           
          },
          {
            municipio: "Machiques de Perijá",
            
            "Total":1389,
             
           
          },
          {
            municipio: "Mara",
            
            "Total":5311,
        
             
          },
          {
            municipio: "Maracaibo",
            "Total":9055,
            
           
          },
          {
            municipio: "Rosario de Perija",
           
            "Total":3117,
            
           
          },
          {
            municipio: "San Francisco",
            "Total":1069,
            
          
          },
          {
            municipio: "Sinamaica",
            "Total":157,
            
          
          },
          {
            municipio: "Idalfonso Vasquez",
            "Total":325,
            
          
          },
          {
            municipio: "Villa del Rosario",
            "Total":186,
            
          
          },
          {
            municipio: "San Rafael del Mojan",
            "Total":517,
            
          
          },
          {
            municipio: "Francisco Eugenio Bustamante",
            "Total":270,
            
          
          },
          {
            municipio: "Sixto Zambrano",
            "Total":104,
            
          
          },
          {
            municipio: "Tamare",
            "Total":148,
            
          
          },
          {
            municipio: "Ricaurie",
            "Total":289,
            
          
          },
          {
            municipio: "Libertad",
            "Total":339,
            
          
          },
        ];
        let datos6 = [
          {
            municipio: "Indígena Bolivariano Guajira",
            "Adecuado":2822,
             "Desnutrición aguda moderada":228,
              "Desnutrición aguda severa":45,
              "Referencia valoracion medica":7,
               "Riesgo de desnutricion":1856
          
           
          },
          {
            municipio: "Machiques de Perijá",
            
            "Adecuado":773,
             "Desnutrición aguda moderada":53,
              "Desnutrición aguda severa":11,
              "Referencia valoracion medica":6,
               "Riesgo de desnutricion":285
          },
          {
            municipio: "Mara",
            
            "Adecuado":3908,
             "Desnutrición aguda moderada":130,
              "Desnutrición aguda severa":20,
              "Referencia valoracion medica":1,
               "Riesgo de desnutricion":1545
          },
          {
            municipio: "Maracaibo",
            "Adecuado":5357,
             "Desnutrición aguda moderada":223,
              "Desnutrición aguda severa":27,
              "Referencia valoracion medica":11,
               "Riesgo de desnutricion":2619
          },
          {
            municipio: "Rosario de Perija",
           
            "Adecuado":1615,
             "Desnutrición aguda moderada":25,
              "Desnutrición aguda severa":6,
              "Referencia valoracion medica":2,
               "Riesgo de desnutricion":520
          },
          {
            municipio: "San Francisco",
            "Adecuado":423,
             "Desnutrición aguda moderada":13,
              "Desnutrición aguda severa":2,
              "Referencia valoracion medica":0,
               "Riesgo de desnutricion":166
          },
      
        ];
        setData1(datoPrueba)
        setData2(datos3)
        setData3(datos5)
        setFechaFin('2023-12-31')
        setEjecutar(true);
      }else{
        let datos3 = [
          {
            municipio: "Indígena Bolivariano Guajira",
            "Peso Adecuado":843,
            "Bajo Peso":126,
          
            
          
           
          },
          {
            municipio: "Machiques de Perijá",
            
            "Peso Adecuado":245,
             "Bajo Peso":13,
           
          },
          {
            municipio: "Mara",
            
            "Peso Adecuado":1021,
             "Desnutrición aguda moderada":87,
             
          },
          {
            municipio: "Maracaibo",
            "Peso Adecuado":1500,
             "Bajo Peso":174,
           
          },
          {
            municipio: "Rosario de Perija",
           
            "Peso Adecuado":474,
             "Bajo Peso":39,
           
          },
          {
            municipio: "San Francisco",
            "Peso Adecuado":104,
             "Desnutrición aguda moderada":17,
          
          },
      
        ];
      
        let datos5 = [
          {
            
            municipio: "Indígena Bolivariano Guajira",
            
            "Total":6094,
            
            
          
           
          },
          {
            
            municipio: "Elias Sanchez Rubio",
            "Total":198,
            
            
          
           
          },
          {
            municipio: "Machiques de Perijá",
            
            "Total":1389,
             
           
          },
          {
            municipio: "Mara",
            
            "Total":5311,
        
             
          },
          {
            municipio: "Maracaibo",
            "Total":9055,
            
           
          },
          {
            municipio: "Rosario de Perija",
           
            "Total":3117,
            
           
          },
          {
            municipio: "San Francisco",
            "Total":1069,
            
          
          },
          {
            municipio: "Sinamaica",
            "Total":157,
            
          
          },
          {
            municipio: "Idalfonso Vasquez",
            "Total":325,
            
          
          },
          {
            municipio: "Villa del Rosario",
            "Total":186,
            
          
          },
          {
            municipio: "San Rafael del Mojan",
            "Total":517,
            
          
          },
          {
            municipio: "Francisco Eugenio Bustamante",
            "Total":270,
            
          
          },
          {
            municipio: "Sixto Zambrano",
            "Total":104,
            
          
          },
          {
            municipio: "Tamare",
            "Total":148,
            
          
          },
          {
            municipio: "Ricaurie",
            "Total":289,
            
          
          },
          {
            municipio: "Libertad",
            "Total":339,
            
          
          },
        ];
        let datos6 = [
          {
            municipio: "Indígena Bolivariano Guajira",
            "Adecuado":2822,
             "Desnutrición aguda moderada":228,
              "Desnutrición aguda severa":45,
              "Referencia valoracion medica":7,
               "Riesgo de desnutricion":1856
          
           
          },
          {
            municipio: "Machiques de Perijá",
            
            "Adecuado":773,
             "Desnutrición aguda moderada":53,
              "Desnutrición aguda severa":11,
              "Referencia valoracion medica":6,
               "Riesgo de desnutricion":285
          },
          {
            municipio: "Mara",
            
            "Adecuado":3908,
             "Desnutrición aguda moderada":130,
              "Desnutrición aguda severa":20,
              "Referencia valoracion medica":1,
               "Riesgo de desnutricion":1545
          },
          {
            municipio: "Maracaibo",
            "Adecuado":5357,
             "Desnutrición aguda moderada":223,
              "Desnutrición aguda severa":27,
              "Referencia valoracion medica":11,
               "Riesgo de desnutricion":2619
          },
          {
            municipio: "Rosario de Perija",
           
            "Adecuado":1615,
             "Desnutrición aguda moderada":25,
              "Desnutrición aguda severa":6,
              "Referencia valoracion medica":2,
               "Riesgo de desnutricion":520
          },
          {
            municipio: "San Francisco",
            "Adecuado":423,
             "Desnutrición aguda moderada":13,
              "Desnutrición aguda severa":2,
              "Referencia valoracion medica":0,
               "Riesgo de desnutricion":166
          },
      
        ];
        setData1(datos6)
        setData2(datos3)
        setData3(datos5)
        setFechaFin('2024-12-31')
        setEjecutar(true);
      }
    
    }

    if(categoria == "Menores de 5 Años"){
      setTabla('niñoscinco')
      setEnpoint('meses')
     
    }
    if(categoria == "MEL"){
      setTabla('mel')
      setEnpoint('meses')
    
    }
    if(categoria == "Desparasitaciones"){
      setTabla('desparasitaciones')
      setEnpoint('mesesDes')
    }
  }, [fechaInicio,categoria]);
  if (ejecutar) {
    
    beneficiariosNut();
  } 

  
 

  console.log(datos)

  return (
    <ResponsiveBar
      data={ categoria === 'Menores' ? data1: categoria === 'MEL'? data2:data3}
      keys={categoria === 'Menores' ? ["Peso Adecuado", "Desnutrición aguda moderada", "Desnutrición aguda severa", "Referencia valoracion medica", "Riesgo de desnutricion"]:categoria === 'MEL'? ["Peso Adecuado","Bajo Peso"]:["Total"]}
      indexBy="municipio"
      margin={{ top: 30, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      maxValue={5000}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "set2" }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "fries",
          },
          id: "dots",
        },
        {
          match: {
            id: "sandwich",
          },
          id: "lines",
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 20,
        legend: "Municipio",
        legendPosition: "middle",
        legendOffset: 32,
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Beneficiarios",
        legendPosition: "middle",
        legendOffset: -40,
        truncateTickAt: 0,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={(e) =>
        e.id + ": " + e.formattedValue + " in country: " + e.indexValue
      }
    />
  );
};

export default BarGraph;
