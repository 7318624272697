import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Box, Button, IconButton } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import HomeIcon from "@mui/icons-material/Home";
import GroupsOutlined from "@mui/icons-material/GroupsOutlined";
import RuleOutlinedIcon from "@mui/icons-material/RuleOutlined";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import NoFoodIcon from "@mui/icons-material/NoFood";
import Restricted from "components/Restricted";
import clienteAxios from "../../config/clienteAxios";
import axios from "axios";

const Asistencia = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("con");
  const con = JSON.parse(token);
  console.log(con);

  const nav = useNavigate();
  const [aceptada, setAceptada] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [cantidadRefrigerio, setcantidadRefrigerio] = React.useState(0);
  const  [equipos, setEquipos] = React.useState([]);
  const  [equipoInfo, setEquipoInfo] = React.useState([]);
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const rol = items.rol;

  //Theme
  const theme = useTheme();

  //Get Date
  var todayDate = new Date().toISOString().slice(0, 10);

  //BreadCrumbs
  function handleClickBreadCrumbs(event) {
    event.preventDefault();
  }

  //Routes
  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };
  const handleLinkGestion = (event, message) => {
    if (message === "gestion") {
      nav(-1);
    }
  };
  const handleLinkReporte = (event, message) => {
    if (message === "reporte") {
      let report = JSON.stringify(aceptada);
      nav(`/asistreporte?prop=${report}`);
    }
  };
  const handleLinkListado = (event, message) => {
    if (message === "listado") {
      let lista = JSON.stringify(aceptada);
      nav(`/asistlista?prop=${lista}`);
    }
  };
  const getEquipos = async () => {
    try {
      const { data1 } = await axios
        .get("/equipos")
        .then(function (response) {
          // setAlerta({})
let equipoRegistrados=response.data;
let equiposConvo = JSON.parse(con.equipos)
var nombreEquipos="";
  for (let i = 0; i < equipoRegistrados.length; i++) {
    for (let j= 0; j < equiposConvo.length; j++) {
    if(equiposConvo[j]===equipoRegistrados[i].id){
nombreEquipos=equipoRegistrados[i].nombre+", "+nombreEquipos
    }
      
    }
    
  }
  
  setEquipoInfo(nombreEquipos.slice(0,-2));
          setEquipos(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const aceptadas = async () => {
    try {
      const { data1 } = await axios
        .get("/aceptada/", {
          params: {
            id: con.id,
          },
        })
        .then(function (response) {
          // setAlerta({})
          let datos=response.data;
          let str = 0;

          for (let i = 0; i < datos.length; i++) {
            console.log(datos[i].refrigerio,"cuentasss")
            str = datos[i].refrigerio + str;
          }
          setcantidadRefrigerio(str)
          setAceptada(response.data);
          setEjecutar(false);

          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const columns = [
    {
      accessorKey: "nombres",
      header: "Nombres",
      size: 120,
    },
    {
      accessorKey: "apellidos",
      header: "Apellidos",
      size: 120,
    },
    {
      accessorKey: "descripcion",
      header: "Ocupación",
    },
    {
      accessorKey: "refrigerio",
      header: "Refrigerio",
      Cell: ({ cell }) => (
        <Box component="span">
          {cell.getValue() === 1 ? (
            <Tooltip title="Con Refrigerio">
              <FastfoodIcon sx={{ color: "darkgreen" }} />
            </Tooltip>
          ) : (
            <Tooltip title="Sin Refrigerio">
              <NoFoodIcon sx={{ color: "firebrick" }} />
            </Tooltip>
          )}
        </Box>
      ),
    },
    {
      accessorKey: "asistencias",
      header: "Estado",
      Cell: ({ cell }) => (
        <Box
          component="span"
          sx={{
            backgroundColor:
                cell.getValue() === "Asistente"
                ? "green"
                : cell.getValue() === "Retrasado"
                ? "darkorange"
                : cell.getValue() === "Inasistente"
                ?"firebrick"
                :
                "dodgerblue",
            borderRadius: "0.25rem",
            color: "#fff",
            maxWidth: "9ch",
            p: "0.25rem",
          }}
        >
          {cell.getValue()}
        </Box>
      ),
    },
  ];
  const [ejecutar, setEjecutar] = React.useState(true);
  if (ejecutar) {
    aceptadas();
    getEquipos();
  }
  const handleAsistencia = async (dato, dato2) => {
    console.log(dato.original.idConvocatoria);
    console.log(dato2);
    try {
      const { data1 } = await axios
        .put("/asignarasistencia", {
          id: dato.original.idConvocatoria,
          asistencia: dato2,
          email: dato.original.email,
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response);
          //localStorage.setItem('token',JSON.stringify(response.data) )
          //setAuth(data)
          setEjecutar(true);
        })
        .catch(function (error) {
          console.log("error");
          // document.getElementById(":r7:").value='';
        });
      //setOpen(false);
    } catch (error) {
      //handleOpenAlertReject();
    }
  };
  //Asistencia
  const asist = "sr";
  console.log(aceptada, "hola");
  if (!aceptada)
    return (
      <Container component="main" maxWidth="xs">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );

  return (
    <Box m="1.5rem 2.5rem">
      {rol === "1" || rol === "2" || rol === "7" ? (
        <>
          <Box
            role="presentation"
            onClick={handleClickBreadCrumbs}
            sx={{ mb: 3 }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/inicio"
                onClick={(event) => handleLinkHome(event, "home")}
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Inicio
              </Link>
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/gestionhumana"
                onClick={(event) => handleLinkGestion(event, "gestion")}
              >
                <GroupsOutlined sx={{ mr: 0.5 }} fontSize="inherit" />
                Gestión Humana
              </Link>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <RuleOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Asistencia
              </Typography>
            </Breadcrumbs>
            <Typography variant="h3" sx={{ mt: 2 }}>
              <b>Asistencia:</b> {con.actividad[0]} ({con.fecha} )
            </Typography>
            <Typography variant="h4" sx={{ mt: 2 }}>
              <b>Equipo:</b> {equipoInfo}
            </Typography>
            {/*<Typography variant="h3" sx={{ mt: 2 }}>
              <b> Cantidad de Refrigerios:</b> {cantidadRefrigerio}
            </Typography>*/}
          </Box>
          <Container>
            <Box
              gridColumn="span 8"
              gridRow="span 3"
              backgroundColor={theme.palette.background.alt}
              p="1.5rem"
              borderRadius="1.5rem"
              mt="40px"
              height="fit-content"
              sx={{ boxShadow: 4 }}
            >
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={8}>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    Listado de Voluntarios 
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Box
                    container
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <Button
                      variant="contained"
                      startIcon={<ContentPasteOutlinedIcon />}
                      sx={{ bgcolor: "teal", color: "white" }}
                      size="small"
                      onClick={(event) => handleLinkReporte(event, "reporte")}
                    >
                      Generar Reporte
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Box
                    container
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <Button
                      variant="contained"
                      startIcon={<ListAltIcon />}
                      sx={{ bgcolor: "teal", color: "white" }}
                      size="small"
                      onClick={(event) => handleLinkListado(event, "listado")}
                    >
                      Generar Listado
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <MaterialReactTable
                columns={columns}
                data={aceptada}
                localization={MRT_Localization_ES}
                enableRowActions
                positionActionsColumn="last"
                renderRowActions={({ row, table }) => [
                  <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
                    <Tooltip title="Asistente">
                      <span>
                        <IconButton
                          color="success"
                          //style={{ display: row.getValue('asistencias') === "Asistente" ? "none" : "block" }}
                          onClick={(event) => handleAsistencia(row, 5)}
                          disabled={
                            /*todayDate !== con.fecha ||*/
                            row.getValue("asistencias") === "Asistente"
                          }
                        >
                          <CheckCircleOutlineOutlinedIcon
                            sx={{ fontSize: 30 }}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Retrasado">
                      <span>
                        <IconButton
                          color="warning"
                          //style={{ display: row.getValue('asistencias') === "Retrasado" ? "none" : "block" }}
                          onClick={(event) => handleAsistencia(row, 6)}
                          disabled={
                            /*todayDate !== con.fecha ||*/
                            row.getValue("asistencias") === "Retrasado"
                          }
                        >
                          <AccessTimeOutlinedIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Inasistente">
                      <span>
                        <IconButton
                          color="error"
                          //style={{ display: row.getValue('asistencias') === "Inasistente" ? "none" : "block" }}
                          onClick={(event) => handleAsistencia(row, 7)}
                          disabled={
                            /*todayDate !== con.fecha ||*/
                            row.getValue("asistencias") === "Inasistente"
                          }
                        >
                          <CancelOutlinedIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Suplente">
                      <span>
                        <IconButton
                          color="info"
                          //style={{ display: row.getValue('asistencias') === "Inasistente" ? "none" : "block" }}
                          onClick={(event) => handleAsistencia(row, 8)}
                          disabled={
                            /*todayDate !== con.fecha ||*/
                            row.getValue("asistencias") === "Suplente"
                          }
                        >
                          <PublishedWithChangesIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>,
                ]}
                displayColumnDefOptions={{
                  "mrt-row-actions": {
                    header: "Asistencia",
                    size: 50,
                  },
                }}
                muiTopToolbarProps={{
                  sx: {
                    backgroundColor: theme.palette.background.alt,
                  },
                }}
                muiTableHeadCellProps={{
                  align: "center",
                  sx: {
                    backgroundColor: theme.palette.background.alt,
                  },
                }}
                muiBottomToolbarProps={{
                  sx: {
                    backgroundColor: theme.palette.background.alt,
                  },
                }}
                muiTableBodyProps={{
                  sx: {
                    "& tr:nth-of-type(odd)": {
                      backgroundColor: theme.palette.background.alt,
                    },
                  },
                }}
                muiTableBodyCellProps={{
                  align: "center",
                }}
                muiTablePaperProps={{
                  elevation: 0,
                }}
                initialState={{
                  pagination: {
                    pageSize: 10,
                    pageIndex: 0,
                  },
                }}
                muiTablePaginationProps={{
                  rowsPerPageOptions: [10, 20, 30, 50, 100],
                  showFirstButton: false,
                  showLastButton: false,
                  SelectProps: {
                    native: true,
                  },
                  labelRowsPerPage: "Número de filas visibles",
                }}
              />
            </Box>
          </Container>
        </>
      ) : (
        <Restricted />
      )}
    </Box>
  );
};

export default Asistencia;
