import React, { useEffect, useMemo } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme, useMediaQuery } from "@mui/material";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import HomeIcon from "@mui/icons-material/Home";
import DepartureBoardOutlined from "@mui/icons-material/DepartureBoardOutlined";
import ChildCareOutlinedIcon from "@mui/icons-material/ChildCareOutlined";
import PregnantWomanOutlinedIcon from "@mui/icons-material/PregnantWomanOutlined";
import MedicationOutlinedIcon from "@mui/icons-material/MedicationOutlined";
import SchoolIcon from "@mui/icons-material/School";
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import Restricted from "components/Restricted";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TableViewIcon from "@mui/icons-material/TableView";
import { AssignmentLate, BarChart, StorageOutlined } from "@mui/icons-material";
import { downloadExcel } from "react-export-table-to-excel";
import axios from "axios";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Consolidado = () => {
  const [items, setItems] = React.useState([]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("prop");
  const proyect = JSON.parse(token);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const rol = items.rol;

  const nav = useNavigate();

  //Theme
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //Tabs Handlers
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //Select Handler
  const [comunidad, setComunidad] = React.useState("");
  const [totalNinos, setTotalNinos] = React.useState([]);
  const [totalMel, setTotalMel] = React.useState([]);
  const handleSelect = (event) => {
    setComunidad(event.target.value);
    setTotalNinos(
      dataConsolidadoNinos.filter((dat) => dat.comunidad === event.target.value)
    );
    setTotalMel(
      dataConsolidadoMel.filter((dat) => dat.comunidad === event.target.value)
    );
    console.log(totalNinos);
    console.log(totalMel);
    if (event.target.value === "") {
      setExpanded(!expanded);
    }
  };

  //Expand Handler
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleViewTable = () => {
    setExpanded(!expanded);
  };

  // Exportar Tabla
  function handleDownloadExcelM5(rows) {
    let tableData = rows.map((row) => Object.values(row.original));
    for (let index = 0; index < tableData.length; index++) {
      tableData[index] = tableData[index].splice(-62, 54);
    }
    const tableHeaders = columns.map((c) => c.header);
    downloadExcel({
      fileName: `Base de Datos - Niños Menores de 5 Años ${new Date().toLocaleDateString(
        "en-GB"
      )}`,
      sheet: "Niños Menores de 5 Años",
      tablePayload: {
        header: tableHeaders,
        body: tableData,
      },
    });
  }

  function handleDownloadExcelMel(rows) {
    const tableData = rows.map((row) => Object.values(row.original));
    for (let index = 0; index < tableData.length; index++) {
      tableData[index] = tableData[index].splice(-48, 40);
    }
    const tableHeaders = columnsMel.map((c) => c.header);
    downloadExcel({
      fileName: `Base de Datos - MEL ${new Date().toLocaleDateString("en-GB")}`,
      sheet: "MEL",
      tablePayload: {
        header: tableHeaders,
        body: tableData,
      },
    });
  }

  function handleDownloadExcelDesp(rows) {
    const tableData = rows.map((row) => Object.values(row.original));
    for (let index = 0; index < tableData.length; index++) {
      tableData[index] = tableData[index].splice(-17, 11);
    }
    const tableHeaders = columnsDesp.map((c) => c.header);
    downloadExcel({
      fileName: `Base de Datos - Desparasitaciones ${new Date().toLocaleDateString(
        "en-GB"
      )}`,
      sheet: "Desparasitaciones",
      tablePayload: {
        header: tableHeaders,
        body: tableData,
      },
    });
  }

  function handleDownloadExcelCap(rows) {
    const tableData = rows.map((row) => Object.values(row.original));
    for (let index = 0; index < tableData.length; index++) {
      tableData[index] = tableData[index].splice(-22, 16);
    }
    const tableHeaders = columnsCap.map((c) => c.header);
    downloadExcel({
      fileName: `Base de Datos - Capacitaciones ${new Date().toLocaleDateString(
        "en-GB"
      )}`,
      sheet: "Capacitaciones",
      tablePayload: {
        header: tableHeaders,
        body: tableData,
      },
    });
  }

  function handleDownloadExcelRef(rows) {
    let tableData = rows.map((row) => Object.values(row.original));
    for (let index = 0; index < tableData.length; index++) {
      tableData[index] = tableData[index].splice(-62, 54);
    }
    const tableHeaders = columns.map((c) => c.header);
    downloadExcel({
      fileName: `Base de Datos - Referidos ${new Date().toLocaleDateString(
        "en-GB"
      )}`,
      sheet: "Referidos",
      tablePayload: {
        header: tableHeaders,
        body: tableData,
      },
    });
  }

  function handleDownloadExcelConsolidado() {
    let ninofiltro = dataConsolidadoNinos;
    let melfiltro = dataConsolidadoMel;
    for (let index = 0; index < ninofiltro.length; index++) {
      for (let j = 0; j < melfiltro.length; j++) {
        if (ninofiltro[index].comunidad === melfiltro[j].comunidad) {
          ninofiltro[index].totalMel = melfiltro[j]["count(*)"];
          ninofiltro[index].mayoresGestante = melfiltro[j].mayoresGestante;
          ninofiltro[index].menoresGestante = melfiltro[j].menoresGestante;
          ninofiltro[index].mayoresLactantes = melfiltro[j].mayoresLactantes;
          ninofiltro[index].menoresLactantes = melfiltro[j].menoresLactantes;
          ninofiltro[index].mayoresBajoGestante =
            melfiltro[j].mayoresBajoGestante;
          ninofiltro[index].menoresBajoGestante =
            melfiltro[j].menoresBajoGestante;
          ninofiltro[index].mayoresBajoLactantes =
            melfiltro[j].mayoresBajoLactantes;
          ninofiltro[index].menoresBajoLactantes =
            melfiltro[j].menoresBajoLactantes;
          ninofiltro[index].poblacionIndigenaMel =
            melfiltro[j].poblacionIndigena;
          ninofiltro[index].cajasMicroMel = melfiltro[j].cajasMicro;
        }
      }
    }
    console.log(ninofiltro);
    const tableData = ninofiltro.map((row) => Object.values(row));
    for (let index = 0; index < tableData.length; index++) {
      tableData[index] = tableData[index];
    }
    const tableHeaders = columnsConsolidado.map((c) => c.header);
    downloadExcel({
      fileName: `Estadísticas Generales - ${new Date().toLocaleDateString(
        "en-GB"
      )}`,
      sheet: "Estadísticas",
      tablePayload: {
        header: tableHeaders,
        body: tableData,
      },
    });
  }

  useEffect(() => {
    if (comunidad === "") {
      setExpanded(false);
    } else {
      setExpanded(false);
      setTimeout(() => {
        setExpanded(true);
      }, 900);
    }
  }, [comunidad]);

  //BreadCrumbs
  function handleClickBreadCrumbs(event) {
    event.preventDefault();
  }

  //Routes
  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };
  const handleLinkTrans = (event, message) => {
    if (message === "trans") {
      nav("/transcripcion");
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "estado",
        header: "Estado",
      },
      {
        accessorKey: "municipio",
        header: "Municipio",
      },
      {
        accessorKey: "parroquia",
        header: "Parroquia",
      },
      {
        accessorKey: "centro",
        header: "Centro de Salud",
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
      },
      {
        accessorKey: "grupo_etnico",
        header: "Grupo Etnico",
      },
      {
        accessorKey: "tipo_ingreso",
        header: "Tipo de Ingreso",
      },
      {
        accessorKey: "cedula",
        header: "Cédula del Representante",
      },
      {
        accessorKey: "nombre_rep",
        header: "Nombre del Representante",
      },
      {
        accessorKey: "telefono_rep",
        header: "Teléfono del Representante",
      },

      {
        accessorKey: "nombre",
        header: "Nombres del Beneficiario",
      },
      {
        accessorKey: "apellido",
        header: "Apellidos del Beneficiario",
      },
      {
        accessorKey: "sexo",
        header: "Género",
      },
      {
        accessorKey: "fecha_nacimiento",
        header: "Fecha de Nacimiento",
      },
      {
        accessorKey: "fecha_evaluacion",
        header: "Fecha de Evaluación",
      },
      {
        accessorKey: "edad",
        header: "Edad en Meses",
      },
      {
        accessorKey: "registrado",
        header: "Registrado",
      },
      {
        accessorKey: "escolaridad",
        header: "Escolaridad",
      },
      {
        accessorKey: "lactancia",
        header: "¿Recibe Lactancia Materna?",
      },
      {
        accessorKey: "veces",
        header: "¿Cuántas veces al día?",
      },
      {
        accessorKey: "biberon",
        header: "¿Usa Biberón?",
      },
      {
        accessorKey: "otros_alimentos",
        header: "Otros Alimentos",
      },
      {
        accessorKey: "agua_hervida",
        header: "Agua Hervida",
      },
      {
        accessorKey: "agua_filtrada",
        header: "Agua Filtrada o Clorada",
      },
      {
        accessorKey: "agua_sintratar",
        header: "Agua sin Tratamiento",
      },
      {
        accessorKey: "gripe_tos",
        header: "Gripe o Tos en la Última Semana",
      },
      {
        accessorKey: "fiebre",
        header: "Fiebre en la Última Semana",
      },
      {
        accessorKey: "diarrea",
        header: "Diarrea",
      },
      {
        accessorKey: "vomito",
        header: "Vómito",
      },
      {
        accessorKey: "paludismo_malaria",
        header: "Paludismo o Malaria",
      },
      {
        accessorKey: "discapacidad",
        header: "Discapacidad",
      },
      {
        accessorKey: "tipoDiscapacidad",
        header: "¿Cúal?",
      },
      {
        accessorKey: "edema",
        header: "Edema",
      },
      {
        accessorKey: "circunferencia",
        header: "Circunferencia de Brazo (CMB)",
      },
      {
        accessorKey: "clasificacion_pb",
        header: "Clasificación PB",
      },
      {
        accessorKey: "peso",
        header: "Peso (Kg)",
      },
      {
        accessorKey: "talla",
        header: "Talla (cm)",
      },
      {
        accessorKey: "puntaje_z",
        header: "Puntaje Z",
      },
      {
        accessorKey: "clasificacion_nut",
        header: "Clasificación Nutricional",
      },
      {
        accessorKey: "diagnostico_ingreso",
        header: "Diagnostico de Ingreso",
      },
      {
        accessorKey: "consejeria",
        header: "Consejería en Prácticas Clave",
      },
      {
        accessorKey: "referido",
        header: "referido",
      },
      {
        accessorKey: "albendazol",
        header: "Albendazol",
      },
      {
        accessorKey: "micronutrientes",
        header: "Micronutrientes",
      },
      {
        accessorKey: "lns_mq",
        header: "LNS-MQ (Sobre Amarillo)",
      },
      {
        accessorKey: "ruft",
        header: "RUTF (Sobre Rojo)",
      },
      {
        accessorKey: "kitH",
        header: "Kit de Higiene",
      },
      {
        accessorKey: "kitR",
        header: "Kit de Reposición",
      },
      {
        accessorKey: "amoxicilina",
        header: "Amoxicilina",
      },
      {
        accessorKey: "medicamentos",
        header: "medicamentos",
      },
      {
        accessorKey: "dosis_albendazol",
        header: "Dosis Albendazol",
      },
      {
        accessorKey: "dosis_medicamento",
        header: "Dosis de Suplemento Nutricional",
      },
      {
        accessorKey: "observaciones",
        header: "Observaciones",
      },
      {
        accessorKey: "medico",
        header: "Médico Tratante",
      },
    ],
    []
  );

  const columnsMel = useMemo(
    () => [
      {
        accessorKey: "estado",
        header: "Estado",
      },
      {
        accessorKey: "municipio",
        header: "Municipio",
      },
      {
        accessorKey: "parroquia",
        header: "Parroquia",
      },
      {
        accessorKey: "centro_atencion",
        header: "Centro de Salud",
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
      },
      {
        accessorKey: "grupo_etnico",
        header: "Grupo Etnico",
      },
      {
        accessorKey: "tipo_ingreso",
        header: "Tipo Ingreso",
      },
      {
        accessorKey: "cedula",
        header: "Cédula",
      },
      {
        accessorKey: "nombre",
        header: "Nombres",
      },
      {
        accessorKey: "apellido",
        header: "Apellidos",
      },
      {
        accessorKey: "telefono",
        header: "Teléfono",
      },
      {
        accessorKey: "fecha_nacimiento",
        header: "Fecha de Nacimiento",
      },
      {
        accessorKey: "gestante",
        header: "Gestante",
      },
      {
        accessorKey: "semana_gestacion",
        header: "Semanas de Gestación",
      },
      {
        accessorKey: "peso",
        header: "Peso (Kg)",
      },
      {
        accessorKey: "talla",
        header: "Talla (cm)",
      },
      {
        accessorKey: "lactancia",
        header: "Lactancia",
      },
      {
        accessorKey: "edema",
        header: "Edema",
      },
      {
        accessorKey: "criterio",
        header: "Criterio Médico",
      },
      {
        accessorKey: "fecha_evaluacion",
        header: "Fecha de Valoración",
      },
      {
        accessorKey: "edad",
        header: "Edad en Años",
      },
      {
        accessorKey: "circunferencia_brazo",
        header: "Circunferencia de Brazo (CMB)",
      },
      {
        accessorKey: "clasifiacion_pb",
        header: "Clasificación PB",
      },
      {
        accessorKey: "imc",
        header: "IMC",
      },
      {
        accessorKey: "diagnostico_imc",
        header: "Diagnostico IMC",
      },
      {
        accessorKey: "diagnostico_ingreso",
        header: "Diagnostico Ingreso",
      },
      {
        accessorKey: "diagnostico_gestacion",
        header: "Diagnostico IMC por semanas de gestación",
      },
      {
        accessorKey: "consejeria",
        header: "Consejería en lactancia y alimentación del niño pequeño",
      },
      {
        accessorKey: "referido",
        header: "Referido",
      },
      {
        accessorKey: "albendazol",
        header: "Albendazol",
      },

      {
        accessorKey: "micronutrientes",
        header: "Micronutrientes",
      },
      {
        accessorKey: "lsnmq",
        header: "LNS-MQ",
      },
      {
        accessorKey: "plumpy",
        header: "Plumpy'Mum",
      },
      {
        accessorKey: "kitH",
        header: "Kit de Higiene",
      },
      {
        accessorKey: "kitR",
        header: "kit de Reposición",
      },
      {
        accessorKey: "medicamentos",
        header: "Medicamentos",
      },
      {
        accessorKey: "dosis_albendazol",
        header: "Dosis Albendazol",
      },
      {
        accessorKey: "dosis_medicamento",
        header: "Dosis Medicamento",
      },
      {
        accessorKey: "observaciones",
        header: "Observaciones",
      },
      {
        accessorKey: "medico",
        header: "Médico Tratante",
      },
    ],
    []
  );

  const columnsDesp = useMemo(
    () => [
      {
        accessorKey: "fecha_inr",
        header: "Fecha Inicio del Reporte",
      },
      {
        accessorKey: "fecha_finr",
        header: "Fecha Final del Reporte",
      },
      {
        accessorKey: "nombre_centro",
        header: "Centro de Salud",
      },
      {
        accessorKey: "parroquia",
        header: "Parroquia",
      },
      {
        accessorKey: "municipio",
        header: "Municipio",
      },
      {
        accessorKey: "estado",
        header: "Estado",
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
      },
      {
        accessorKey: "ninos_desparacitados",
        header: "Total de Niños Desparasitados",
      },
      {
        accessorKey: "ninas_desparacitadas",
        header: "Total de Niñas Desparasitadas",
      },
      {
        accessorKey: "escolarizados",
        header: "Escolarizados",
      },
      {
        accessorKey: "equipo",
        header: "Equipo",
      },
    ],
    []
  );

  const columnsCap = useMemo(
    () => [
      {
        accessorKey: "fecha_inr",
        header: "Fecha Inicio",
      },
      {
        accessorKey: "fecha_finr",
        header: "Fecha Final",
      },
      {
        accessorKey: "nombre_centro",
        header: "Centro de Salud",
      },
      {
        accessorKey: "parroquia",
        header: "Parroquia",
      },
      {
        accessorKey: "municipio",
        header: "Municipio",
      },
      {
        accessorKey: "estado",
        header: "Estado",
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
      },
      {
        accessorKey: "hombres17",
        header: "Hombres de 0-17 años",
      },
      {
        accessorKey: "mujeres17",
        header: "Mujeres de 0-17 años",
      },
      {
        accessorKey: "hombres59",
        header: "Hombres de 18-59 años",
      },
      {
        accessorKey: "mujeres59",
        header: "Mujeres de 18-59 años",
      },
      {
        accessorKey: "hombres60",
        header: "Hombres de 60 años o más",
      },
      {
        accessorKey: "mujeres60",
        header: "Mujeres de 60 años o más",
      },
      {
        accessorKey: "total",
        header: "Total Capacitados",
      },
      {
        accessorKey: "total_Lgtbq",
        header: "Personas LGBTQI+",
      },
      {
        accessorKey: "equipo",
        header: "Equipo",
      },
    ],
    []
  );

  const columnsConsolidado = useMemo(
    () => [
      {
        header: "Municipio",
      },
      {
        header: "Parroquia",
      },
      {
        header: "Comunidad",
      },
      {
        header: "Total de Tamizados",
      },
      {
        header: "Total Niñas",
      },
      {
        header: "Total Niños",
      },
      {
        header: "Niñas de 6 a 23 meses",
      },
      {
        header: "Niños de 6 a 23 meses",
      },

      {
        header: "Niñas de 24 a 59 meses",
      },
      {
        header: "Niños de 24 a 59 meses",
      },
      {
        header: "Niñas de 6 a 23 meses (SAM)",
      },
      {
        header: "Niños de 6 a 23 meses (SAM)",
      },
      {
        header: "Niñas de 24 a 59 meses (SAM)",
      },
      {
        header: "Niños de 24 a 59 meses (SAM)",
      },
      {
        header: "Niñas de 6 a 23 meses (MAM)",
      },
      {
        header: "Niños de 6 a 23 meses (MAM)",
      },
      {
        header: "Niñas de 24 a 59 meses (MAM)",
      },
      {
        header: "Niños de 24 a 59 meses (MAM)",
      },
      {
        header: "Niñas de 6 a 23 meses (Adecuado)",
      },
      {
        header: "Niños de 6 a 23 meses (Adecuado)",
      },
      {
        header: "Niñas de 24 a 59 meses (Adecuado)",
      },
      {
        header: "Niños de 24 a 59 meses (Adecuado)",
      },
      {
        header: "Niñas de 6 a 23 meses (Micronutrientes)",
      },
      {
        header: "Niños de 6 a 23 meses (Micronutrientes)",
      },
      {
        header: "Niñas de 24 a 59 meses (Micronutrientes)",
      },
      {
        header: "Niños de 24 a 59 meses (Micronutrientes)",
      },
      {
        header: "Niñas de 6 a 23 meses (PA)",
      },
      {
        header: "Niños de 6 a 23 meses (PA)",
      },
      {
        header: "Niñas de 24 a 59 meses (PA)",
      },
      {
        header: "Niños de 24 a 59 meses (PA)",
      },
      {
        header: "Total PA",
      },
      {
        header: "Total Puntaje Z entre -2 y -1",
      },
      {
        header: "Población Indígena (NNA)",
      },
      {
        header: "Discapacitados (NNA)",
      },
      {
        header: "Total SAM",
      },
      {
        header: "Total MAM",
      },
      {
        header: "RUTF (SAM)",
      },
      {
        header: "RUTF (MAM)",
      },
      {
        header: "Total Cajas Micronutrientes (NNA)",
      },
      {
        header: "Total MEL",
      },
      {
        header: "Gestantes Mayores de 18 años",
      },
      {
        header: "Gestantes Menores de 18 años",
      },
      {
        header: "Lactantes Mayores de 18 años",
      },
      {
        header: "Lactantes Menores de 18 años",
      },
      {
        header: "Gestantes Mayores de 18 años (Bajo Peso)",
      },
      {
        header: "Gestantes Menores de 18 años (Bajo Peso)",
      },
      {
        header: "Lactantes Mayores de 18 años (Bajo Peso)",
      },
      {
        header: "Lactantes Menores de 18 años (Bajo Peso)",
      },
      {
        header: "Población Indígena (MEL)",
      },
      {
        header: "LNS-MQ (Cajas)",
      },
    ],
    []
  );

  const [ejecutar, setEjecutar] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [dataReferidos, setDataReferidos] = React.useState([]);
  const [dataMel, setDataMel] = React.useState([]);
  const [dataDesp, setDataDesp] = React.useState([]);
  const [dataCap, setDataCap] = React.useState([]);
  const [dataConsolidadoNinos, setDataConsolidadoNinos] = React.useState([]);
  const [dataConsolidadoMel, setDataConsolidadoMel] = React.useState([]);
  const [dataComunidades, setDataComunidad] = React.useState([]);

  const ninosCinco = async () => {
    try {
      const { data1 } = await axios
        .get("/todosninos/", {
          params: {
            id: proyect.idproyectos,
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setData(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const ninosRferidos = async () => {
    try {
      const { data1 } = await axios
        .get("/todosReferidos/", {
          params: {
            id: proyect.idproyectos,
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setDataReferidos(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const mel = async () => {
    try {
      const { data1 } = await axios
        .get("/todosmel/", {
          params: {
            id: proyect.idproyectos,
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setDataMel(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const desparasitaciones = async () => {
    try {
      const { data1 } = await axios
        .get("/todosdesp/", {
          params: {
            id: proyect.idproyectos,
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setDataDesp(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const Capacitaciones = async () => {
    try {
      const { data1 } = await axios
        .get("/todoscap/", {
          params: {
            id: proyect.idproyectos,
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setDataCap(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const consolidadoNinos = async () => {
    try {
      const { data1 } = await axios
        .get("/consolidadoninos/", {
          params: {
            id: proyect.idproyectos,
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);

          setDataConsolidadoNinos(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const consolidadoMel = async () => {
    try {
      const { data1 } = await axios
        .get("/consolidadomel/", {
          params: {
            id: proyect.idproyectos,
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setDataConsolidadoMel(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const getcomunidades = async () => {
    try {
      const { data1 } = await axios
        .get("/getcomunidades/", {
          params: {
            id: proyect.idproyectos,
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setDataComunidad(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  if (ejecutar) {
    mel();
    desparasitaciones();
    Capacitaciones();
    ninosCinco();
    ninosRferidos();
    consolidadoNinos();
    consolidadoMel();
    getcomunidades();
    
  }

  return (
    <Box m="1.5rem 2.5rem">
      {rol === "1" || rol === "2" || rol === "8" ? (
        <>
          <Box
            role="presentation"
            onClick={handleClickBreadCrumbs}
            sx={{ mb: 3 }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/inicio"
                onClick={(event) => handleLinkHome(event, "home")}
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Inicio
              </Link>
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/transcripcion"
                onClick={(event) => handleLinkTrans(event, "trans")}
              >
                <DriveFileRenameOutlineIcon
                  sx={{ mr: 0.5 }}
                  fontSize="inherit"
                />
                Transcripción
              </Link>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <StorageOutlined sx={{ mr: 0.5 }} fontSize="inherit" />
                Base de Datos
              </Typography>
            </Breadcrumbs>
            <Typography variant="h3" sx={{ mt: 2 }}>
              <b>Base de Datos: </b> {proyect.nombre}
            </Typography>
          </Box>
          <Box
            mt="10px"
            display="grid"
            gridTemplateColumns="repeat(8, 1fr)"
            gridAutoRows="160px"
            gap="20px"
            sx={{
              "& > div": {
                gridColumn: isNonMediumScreens ? undefined : "span 12",
              },
            }}
          >
            <Box
              gridColumn="span 3"
              gridRow="span 3"
              p="1.5rem"
              borderRadius="1.5rem"
              mt="10px"
              height="fit-content"
            >
              <Grid container>
                <Grid item xs={12} mt={2}>
                  <Card
                    sx={{
                      backgroundColor: theme.palette.background.alt,
                      borderRadius: 6,
                      boxShadow: 4,
                    }}
                  >
                    <CardHeader
                      avatar={
                        <Avatar sx={{ bgcolor: "teal", width: 50, height: 50 }}>
                          <BarChart sx={{ color: "white", fontSize: 30 }} />
                        </Avatar>
                      }
                      title="Estadísticas del Proyecto"
                      //subheader="September 14, 2016"
                      titleTypographyProps={{
                        fontWeight: "bold",
                        fontSize: 20,
                      }}
                    />
                    <CardContent>
                      <Typography mb={3}>
                        Selecciona una comunidad para ver sus estadísticas.
                      </Typography>
                      <Grid container justifyContent="center">
                        <Grid item xs={12} sm={11}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Comunidad
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={comunidad}
                              label="Comunidad"
                              onChange={handleSelect}
                            >
                              <MenuItem value={""}>
                                <em>Seleccionar comunidad...</em>
                              </MenuItem>
                              {dataComunidades.map((dat, index) => (
                                <MenuItem value={dat.comunidad}>
                                  {dat.comunidad}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 3, mb: 3 }} />
                      <Typography mb={2}>
                        También puedes exportar todas las estadísticas a{" "}
                        <b>Excel</b>.
                      </Typography>
                      <Grid container spacing={2} justifyContent="center">
                        <Grid item sm={11}>
                          <Button
                            variant="contained"
                            sx={{ bgcolor: "teal", color: "white" }}
                            fullWidth
                            onClick={handleDownloadExcelConsolidado}
                          >
                            <FileDownloadIcon sx={{ mr: 1 }} /> Exportar a Excel
                          </Button>
                          {/*<a href="/pdfConsolidado" target="_blank" rel="noreferrer">Test PDF</a>*/}
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions disableSpacing>
                      <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="ver más"
                        disabled={comunidad === ""}
                      >
                        <ExpandMoreIcon sx={{ fontSize: 35 }} />
                      </ExpandMore>
                    </CardActions>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Divider>
                          <Chip label="INFO. GENERAL" size="small" />
                        </Divider>
                        <Box mt={3} mb={3}>
                          <Grid
                            container
                            spacing={1}
                            justifyContent={isSmallScreen ? "center" : ""}
                          >
                            <Grid item sm={6}>
                              <Typography
                                mb={2}
                                className={isSmallScreen ? "text-center" : ""}
                              >
                                <b>Municipio: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].municipio
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography>
                                <b>Parroquia: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].parroquia
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider>
                          <Chip label="NIÑOS TAMIZADOS" size="small" />
                        </Divider>
                        <Box mt={3} mb={3}>
                          <Grid
                            container
                            spacing={1}
                            justifyContent={isSmallScreen ? "center" : ""}
                          >
                            <Grid item sm={6}>
                              <Typography mb={1}>
                                <b>Femenino 6-23 Meses: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninas6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography mb={1}>
                                <b>Masculino 6-23 Meses: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninos6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography mb={1}>
                                <b>Femenino 24-59 Meses: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninas24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography mb={1}>
                                <b>Masculino 24-59 Meses: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninos24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography
                                mt={3}
                                mb={isSmallScreen ? 2 : 0}
                                variant="h5"
                                className={isSmallScreen ? "text-center" : ""}
                              >
                                <b>TOTAL: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0]["count(*)"]
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography mb={1}>
                                <b>Población Indígena: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].poblacionIndigena
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography mb={1}>
                                <b>Discapacidad: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].discapacidad
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>Referidos: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].referidos
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider>
                          <Chip label="SAM" size="small" />
                        </Divider>
                        <Box mt={3} mb={3}>
                          <Grid
                            container
                            spacing={1}
                            justifyContent={isSmallScreen ? "center" : ""}
                          >
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>Femenino 6-23 Meses: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninasSevera6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography mb={1}>
                                <b>Masculino 6-23 Meses: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosSevera6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>Femenino 24-59 Meses: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninasSevera24_59
                                  : "Seleccione Comunidad"}
                              </Typography>

                              <Typography mb={1}>
                                <b>Masculino 24-59 Meses: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosSevera24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>Femenino 6-23 Meses con Discapacidad: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosModerada6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>Masculino 6-23 Meses con Discapacidad: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosModerada24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>Femenino 24-59 Meses con Discapacidad: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosModerada6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>Masculino 24-59 Meses con Discapacidad: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosModerada24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>Femenino 6-23 Meses (Pob. Indígena): </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosModerada6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>Masculino 6-23 Meses (Pob. Indígena): </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosModerada24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>Femenino 24-59 Meses (Pob. Indígena): </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosModerada6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>Masculino 24-59 Meses (Pob. Indígena): </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosModerada24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Typography
                            className="text-center"
                            mt={2}
                            variant="h5"
                          >
                            <b>TOTAL: </b>{" "}
                            {totalNinos.length > 0
                              ? totalNinos[0].ninoSam
                              : "Seleccione Comunidad"}
                          </Typography>
                        </Box>
                        <Divider>
                          <Chip label="MAM" size="small" />
                        </Divider>
                        <Box mt={3} mb={3}>
                          <Grid
                            container
                            spacing={1}
                            justifyContent={isSmallScreen ? "center" : ""}
                          >
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>Femenino 6-23 Meses: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninasModerada6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>Femenino 24-59 Meses: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninasModerada24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>Masculino 6-23 Meses: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosModerada6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography mb={1}>
                                <b>Masculino 24-59 Meses: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosModerada24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>Femenino 6-23 Meses con Discapacidad: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosModerada6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>Masculino 6-23 Meses con Discapacidad: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosModerada24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>Femenino 24-59 Meses con Discapacidad: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosModerada6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>Masculino 24-59 Meses con Discapacidad: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosModerada24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>Femenino 6-23 Meses (Pob. Indígena): </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosModerada6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>Masculino 6-23 Meses (Pob. Indígena): </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosModerada24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>Femenino 24-59 Meses (Pob. Indígena): </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosModerada6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>Masculino 24-59 Meses (Pob. Indígena): </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosModerada24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Typography
                            className="text-center"
                            mt={2}
                            variant="h5"
                          >
                            <b>TOTAL: </b>{" "}
                            {totalNinos.length > 0
                              ? totalNinos[0].ninoMam
                              : "Seleccione Comunidad"}
                          </Typography>
                        </Box>
                        <Divider>
                          <Chip label="SOBREPESO" size="small" />
                        </Divider>
                        <Box mt={3} mb={3}>
                          <Grid
                            container
                            spacing={1}
                            justifyContent={isSmallScreen ? "center" : ""}
                          >
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>Femenino 6-23 Meses con Discapacidad: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninasSobreDis6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>Masculino 6-23 Meses con Discapacidad: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosSobreDis6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>Femenino 24-59 Meses con Discapacidad: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninasSobreDis24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>Masculino 24-59 Meses con Discapacidad: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosSobreDis24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>Femenino 6-23 Meses (Pob. Indígena): </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninasSobreIndige6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>Masculino 6-23 Meses (Pob. Indígena): </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosSobreIndige6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>Femenino 24-59 Meses (Pob. Indígena): </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninasSobreIndige24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>Masculino 24-59 Meses (Pob. Indígena): </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosSobreIndige24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Typography
                            className="text-center"
                            mt={2}
                            variant="h5"
                          >
                            <b>TOTAL: </b>{" "}
                            {totalNinos.length > 0
                              ? totalNinos[0].ninoSobrepeso
                              : "Seleccione Comunidad"}
                          </Typography>
                        </Box>
                        <Divider>
                          <Chip label="OBESIDAD" size="small" />
                        </Divider>
                        <Box mt={3} mb={3}>
                          <Grid
                            container
                            spacing={1}
                            justifyContent={isSmallScreen ? "center" : ""}
                          >
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>Femenino 6-23 Meses con Discapacidad: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninasObesidadDis6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>Masculino 6-23 Meses con Discapacidad: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosObesidadDis6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>Femenino 24-59 Meses con Discapacidad: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninasObesidadDis24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>Masculino 24-59 Meses con Discapacidad: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosObesidadDis24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>Femenino 6-23 Meses (Pob. Indígena): </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninasObesidadIndige6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>Masculino 6-23 Meses (Pob. Indígena): </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosObesidadIndige6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>Femenino 24-59 Meses (Pob. Indígena): </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninasObesidadDis24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>Masculino 24-59 Meses (Pob. Indígena): </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosObesidadIndige24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Typography
                            className="text-center"
                            mt={2}
                            variant="h5"
                          >
                            <b>TOTAL: </b>{" "}
                            {totalNinos.length > 0
                              ? totalNinos[0].ninoObesidad
                              : "Seleccione Comunidad"}
                          </Typography>
                        </Box>
                        <Divider>
                          <Chip label="PA" size="small" />
                        </Divider>
                        <Box mt={3} mb={3}>
                          <Grid
                            container
                            spacing={1}
                            justifyContent={isSmallScreen ? "center" : ""}
                          >
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>Femenino 6-23 Meses: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninasPa6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>Femenino 24-59 Meses: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninasPa24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>Masculino 6-23 Meses: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosPa6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>Masculino 24-59 Meses: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosPa24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Typography
                            className="text-center"
                            mt={2}
                            variant="h5"
                          >
                            <b>TOTAL: </b>{" "}
                            {totalNinos.length > 0
                              ? totalNinos[0].ninoPa
                              : "Seleccione Comunidad"}
                          </Typography>
                        </Box>
                        <Divider>
                          <Chip label="MEL" size="small" />
                        </Divider>
                        <Box mt={3} mb={3}>
                          <Grid
                            container
                            spacing={1}
                            justifyContent={isSmallScreen ? "center" : ""}
                          >
                            <Grid
                              item
                              sm={6}
                              className={isSmallScreen ? "text-center" : ""}
                            >
                              <Typography mb={1}>
                                <b>Gest. Menores de 18 años: </b>{" "}
                                {totalMel.length > 0
                                  ? totalMel[0].menoresGestante
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography mb={3}>
                                <b>Gest. Mayores de 18 años: </b>{" "}
                                {totalMel.length > 0
                                  ? totalMel[0].mayoresGestante
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography mb={1}>
                                <b>Lac. Menores de 18 años: </b>{" "}
                                {totalMel.length > 0
                                  ? totalMel[0].menoresLactantes
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>Lac. Mayores de 18 años: </b>{" "}
                                {totalMel.length > 0
                                  ? totalMel[0].mayoresLactantes
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography mt={3}>
                                <b>Gest. Menores de 18 años (Bajo Peso) : </b>{" "}
                                {totalMel.length > 0
                                  ? totalMel[0].menoresBajoGestante
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography mt={1}>
                                <b>Gest. Mayores de 18 años (Bajo Peso) : </b>{" "}
                                {totalMel.length > 0
                                  ? totalMel[0].mayoresBajoGestante
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography mt={3}>
                                <b>Lac. Menores de 18 años (Bajo Peso) : </b>{" "}
                                {totalMel.length > 0
                                  ? totalMel[0].menoresBajoLactantes
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography mt={1}>
                                <b>Lac. Mayores de 18 años (Bajo Peso) : </b>{" "}
                                {totalMel.length > 0
                                  ? totalMel[0].mayoresBajoLactantes
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>Población Indígena: </b>{" "}
                                {totalMel.length > 0
                                  ? totalMel[0].poblacionIndigena
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography mb={2}>
                                <b>Bajo Peso Menores de 18 años (Pob. Indígena): </b>{" "}
                                {totalMel.length > 0
                                  ? totalMel[0].poblacionIndigena
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography mb={2}>
                                <b>Bajo Peso Mayores de 18 años (Pob. Indígena): </b>{" "}
                                {totalMel.length > 0
                                  ? totalMel[0].poblacionIndigena
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider>
                          <Chip label="DESPARASITACIÓN" size="small" />
                        </Divider>
                        <Box mt={3} mb={3}>
                          <Grid
                            container
                            spacing={1}
                            justifyContent={isSmallScreen ? "center" : ""}
                          >
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>NNA's 6-23 Meses: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosModerada6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>MEL Menores de 18 años: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosModerada24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>NNA's 24-59 Meses: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosModerada6_23
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>MEL Mayores de 18 años: </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].ninosModerada24_59
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider>
                          <Chip label="INSUMOS UTILIZADOS" size="small" />
                        </Divider>
                        <Box mt={3} mb={3}>
                          <Grid
                            container
                            spacing={1}
                            justifyContent={isSmallScreen ? "center" : ""}
                          >
                            <Grid
                              item
                              sm={6}
                              className={isSmallScreen ? "text-center" : ""}
                            >
                              <Typography mb={1}>
                                <b>RUTF (SAM): </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].rutfSam
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography mb={2}>
                                <b>RUTF (MAM): </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].rutfMam
                                  : "Seleccione Comunidad"}
                              </Typography>
                              <Typography>
                                <b>Micronutrientes (Cajas): </b>{" "}
                                {totalNinos.length > 0
                                  ? totalNinos[0].cajasMicro
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography mb={2}>
                                <b>LNS-MQ (Cajas): </b>{" "}
                                {totalMel.length > 0
                                  ? totalMel[0].cajasMicro
                                  : "Seleccione Comunidad"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </CardContent>
                    </Collapse>
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={12}
                  mt={2}
                  hidden={isNonMediumScreens || expanded == false}
                >
                  <Card
                    sx={{
                      backgroundColor: theme.palette.background.alt,
                      borderRadius: 6,
                      boxShadow: 4,
                    }}
                  >
                    <CardContent>
                      <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={11}>
                          <Button
                            variant="contained"
                            sx={{ bgcolor: "teal", color: "white" }}
                            fullWidth
                            onClick={handleViewTable}
                          >
                            <TableViewIcon sx={{ mr: 1 }} /> Ver Tabla
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <Box
              gridColumn="span 5"
              gridRow="span 3"
              backgroundColor={theme.palette.background.alt}
              p="1.5rem"
              borderRadius="1.5rem"
              mt="45px"
              height="fit-content"
              sx={{ boxShadow: 4 }}
              hidden={isSmallScreen && expanded == true}
            >
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    variant={isSmallScreen ? "scrollable" : "fullWidth"}
                    onChange={handleChange}
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="basic tabs example"
                  >
                    <Tab
                      icon={<ChildCareOutlinedIcon />}
                      iconPosition="start"
                      label="Menores de 5 Años"
                      {...a11yProps(0)}
                    />
                    <Tab
                      icon={<PregnantWomanOutlinedIcon />}
                      iconPosition="start"
                      label="MEL"
                      {...a11yProps(1)}
                    />
                    <Tab
                      icon={<MedicationOutlinedIcon />}
                      iconPosition="start"
                      label="Desparasitaciones"
                      {...a11yProps(2)}
                    />
                    <Tab
                      icon={<SchoolIcon />}
                      iconPosition="start"
                      label="Capacitaciones"
                      {...a11yProps(3)}
                    />
                    <Tab
                      icon={<AssignmentLateIcon />}
                      iconPosition="start"
                      label="Referidos"
                      {...a11yProps(4)}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <MaterialReactTable
                    columns={columns}
                    data={data}
                    localization={MRT_Localization_ES}
                    enableRowSelection
                    positionExpandColumn="first"
                    positionActionsColumn="first"
                    renderTopToolbarCustomActions={({ table }) => (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "16px",
                          padding: "8px",
                          flexWrap: "wrap",
                        }}
                      >
                        <Button
                          color="info"
                          disabled={
                            table.getPrePaginationRowModel().rows.length === 0
                          }
                          //export all rows, including from the next page, (still respects filtering and sorting)
                          onClick={() =>
                            handleDownloadExcelM5(
                              table.getPrePaginationRowModel().rows
                            )
                          }
                          variant="contained"
                        >
                          <FileDownloadIcon sx={{ mr: 1 }} />
                          Exportar Todo
                        </Button>
                        <Button
                          color="success"
                          disabled={
                            !table.getIsSomeRowsSelected() &&
                            !table.getIsAllRowsSelected()
                          }
                          //only export selected rows
                          onClick={() =>
                            handleDownloadExcelM5(
                              table.getSelectedRowModel().rows
                            )
                          }
                          startIcon={<FileDownloadIcon />}
                          variant="contained"
                        >
                          Exportar Selección
                        </Button>
                      </Box>
                    )}
                    muiTopToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableHeadCellProps={{
                      align: "center",
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiBottomToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableBodyProps={{
                      sx: {
                        "& tr:nth-of-type(odd)": {
                          backgroundColor: theme.palette.background.light,
                        },
                      },
                    }}
                    muiTableBodyCellProps={{
                      align: "center",
                    }}
                    muiTablePaperProps={{
                      elevation: 0,
                    }}
                    initialState={{
                      pagination: {
                        pageSize: 20,
                        pageIndex: 0,
                      },
                    }}
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                      showFirstButton: false,
                      showLastButton: false,
                      SelectProps: {
                        native: true,
                      },
                      labelRowsPerPage: "Número de filas visibles",
                    }}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <MaterialReactTable
                    columns={columnsMel}
                    data={dataMel}
                    localization={MRT_Localization_ES}
                    enableRowSelection
                    positionExpandColumn="first"
                    positionActionsColumn="first"
                    renderTopToolbarCustomActions={({ table }) => (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "16px",
                          padding: "8px",
                          flexWrap: "wrap",
                        }}
                      >
                        <Button
                          color="info"
                          disabled={
                            table.getPrePaginationRowModel().rows.length === 0
                          }
                          //export all rows, including from the next page, (still respects filtering and sorting)
                          onClick={() =>
                            handleDownloadExcelMel(
                              table.getPrePaginationRowModel().rows
                            )
                          }
                          variant="contained"
                        >
                          <FileDownloadIcon sx={{ mr: 1 }} />
                          Exportar
                        </Button>
                        <Button
                          color="success"
                          disabled={
                            !table.getIsSomeRowsSelected() &&
                            !table.getIsAllRowsSelected()
                          }
                          //only export selected rows
                          onClick={() =>
                            handleDownloadExcelMel(
                              table.getSelectedRowModel().rows
                            )
                          }
                          startIcon={<FileDownloadIcon />}
                          variant="contained"
                        >
                          Exportar Selección
                        </Button>
                      </Box>
                    )}
                    muiTopToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableHeadCellProps={{
                      align: "center",
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiBottomToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableBodyProps={{
                      sx: {
                        "& tr:nth-of-type(odd)": {
                          backgroundColor: theme.palette.background.light,
                        },
                      },
                    }}
                    muiTableBodyCellProps={{
                      align: "center",
                    }}
                    muiTablePaperProps={{
                      elevation: 0,
                    }}
                    initialState={{
                      pagination: {
                        pageSize: 20,
                        pageIndex: 0,
                      },
                    }}
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                      showFirstButton: false,
                      showLastButton: false,
                      SelectProps: {
                        native: true,
                      },
                      labelRowsPerPage: "Número de filas visibles",
                    }}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <MaterialReactTable
                    columns={columnsDesp}
                    data={dataDesp}
                    localization={MRT_Localization_ES}
                    enableRowSelection
                    positionExpandColumn="first"
                    positionActionsColumn="first"
                    renderTopToolbarCustomActions={({ table }) => (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "16px",
                          padding: "8px",
                          flexWrap: "wrap",
                        }}
                      >
                        <Button
                          color="info"
                          disabled={
                            table.getPrePaginationRowModel().rows.length === 0
                          }
                          //export all rows, including from the next page, (still respects filtering and sorting)
                          onClick={() =>
                            handleDownloadExcelDesp(
                              table.getPrePaginationRowModel().rows
                            )
                          }
                          variant="contained"
                        >
                          <FileDownloadIcon sx={{ mr: 1 }} />
                          Exportar
                        </Button>
                        <Button
                          color="success"
                          disabled={
                            !table.getIsSomeRowsSelected() &&
                            !table.getIsAllRowsSelected()
                          }
                          //only export selected rows
                          onClick={() =>
                            handleDownloadExcelDesp(
                              table.getSelectedRowModel().rows
                            )
                          }
                          startIcon={<FileDownloadIcon />}
                          variant="contained"
                        >
                          Exportar Selección
                        </Button>
                      </Box>
                    )}
                    muiTopToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableHeadCellProps={{
                      align: "center",
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiBottomToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableBodyProps={{
                      sx: {
                        "& tr:nth-of-type(odd)": {
                          backgroundColor: theme.palette.background.light,
                        },
                      },
                    }}
                    muiTableBodyCellProps={{
                      align: "center",
                    }}
                    muiTablePaperProps={{
                      elevation: 0,
                    }}
                    initialState={{
                      pagination: {
                        pageSize: 20,
                        pageIndex: 0,
                      },
                    }}
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                      showFirstButton: false,
                      showLastButton: false,
                      SelectProps: {
                        native: true,
                      },
                      labelRowsPerPage: "Número de filas visibles",
                    }}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                  <MaterialReactTable
                    columns={columnsCap}
                    data={dataCap}
                    localization={MRT_Localization_ES}
                    enableRowSelection
                    positionExpandColumn="first"
                    positionActionsColumn="first"
                    renderTopToolbarCustomActions={({ table }) => (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "16px",
                          padding: "8px",
                          flexWrap: "wrap",
                        }}
                      >
                        <Button
                          color="info"
                          disabled={
                            table.getPrePaginationRowModel().rows.length === 0
                          }
                          //export all rows, including from the next page, (still respects filtering and sorting)
                          onClick={() =>
                            handleDownloadExcelCap(
                              table.getPrePaginationRowModel().rows
                            )
                          }
                          variant="contained"
                        >
                          <FileDownloadIcon sx={{ mr: 1 }} />
                          Exportar
                        </Button>
                        <Button
                          color="success"
                          disabled={
                            !table.getIsSomeRowsSelected() &&
                            !table.getIsAllRowsSelected()
                          }
                          //only export selected rows
                          onClick={() =>
                            handleDownloadExcelCap(
                              table.getSelectedRowModel().rows
                            )
                          }
                          startIcon={<FileDownloadIcon />}
                          variant="contained"
                        >
                          Exportar Selección
                        </Button>
                      </Box>
                    )}
                    muiTopToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableHeadCellProps={{
                      align: "center",
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiBottomToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableBodyProps={{
                      sx: {
                        "& tr:nth-of-type(odd)": {
                          backgroundColor: theme.palette.background.light,
                        },
                      },
                    }}
                    muiTableBodyCellProps={{
                      align: "center",
                    }}
                    muiTablePaperProps={{
                      elevation: 0,
                    }}
                    initialState={{
                      pagination: {
                        pageSize: 20,
                        pageIndex: 0,
                      },
                    }}
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                      showFirstButton: false,
                      showLastButton: false,
                      SelectProps: {
                        native: true,
                      },
                      labelRowsPerPage: "Número de filas visibles",
                    }}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={4}>
                  <MaterialReactTable
                    columns={columns}
                    data={dataReferidos}
                    localization={MRT_Localization_ES}
                    enableRowSelection
                    positionExpandColumn="first"
                    positionActionsColumn="first"
                    renderTopToolbarCustomActions={({ table }) => (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "16px",
                          padding: "8px",
                          flexWrap: "wrap",
                        }}
                      >
                        <Button
                          color="info"
                          disabled={
                            table.getPrePaginationRowModel().rows.length === 0
                          }
                          //export all rows, including from the next page, (still respects filtering and sorting)
                          onClick={() =>
                            handleDownloadExcelRef(
                              table.getPrePaginationRowModel().rows
                            )
                          }
                          variant="contained"
                        >
                          <FileDownloadIcon sx={{ mr: 1 }} />
                          Exportar Todo
                        </Button>
                        <Button
                          color="success"
                          disabled={
                            !table.getIsSomeRowsSelected() &&
                            !table.getIsAllRowsSelected()
                          }
                          //only export selected rows
                          onClick={() =>
                            handleDownloadExcelRef(
                              table.getSelectedRowModel().rows
                            )
                          }
                          startIcon={<FileDownloadIcon />}
                          variant="contained"
                        >
                          Exportar Selección
                        </Button>
                      </Box>
                    )}
                    muiTopToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableHeadCellProps={{
                      align: "center",
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiBottomToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableBodyProps={{
                      sx: {
                        "& tr:nth-of-type(odd)": {
                          backgroundColor: theme.palette.background.light,
                        },
                      },
                    }}
                    muiTableBodyCellProps={{
                      align: "center",
                    }}
                    muiTablePaperProps={{
                      elevation: 0,
                    }}
                    initialState={{
                      pagination: {
                        pageSize: 20,
                        pageIndex: 0,
                      },
                    }}
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                      showFirstButton: false,
                      showLastButton: false,
                      SelectProps: {
                        native: true,
                      },
                      labelRowsPerPage: "Número de filas visibles",
                    }}
                  />
                </CustomTabPanel>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Restricted />
      )}
    </Box>
  );
};

export default Consolidado;