import React, { useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme, useMediaQuery } from "@mui/material";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import HomeIcon from "@mui/icons-material/Home";
import GroupsOutlined from "@mui/icons-material/GroupsOutlined";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import CardContent from "@mui/material/CardContent";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Chip from "@mui/material/Chip";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import RuleOutlinedIcon from "@mui/icons-material/RuleOutlined";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import InfoIcon from "@mui/icons-material/Info";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MenuItem from "@mui/material/MenuItem";
import Restricted from "components/Restricted";
import CountUp from "react-countup";
import clienteAxios from "../../config/clienteAxios";
import axios from "axios";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const GestionHumana = () => {
  const [items, setItems] = React.useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const rol = items.rol;

  const nav = useNavigate();
  const [ejecutar, setEjecutar] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [dataF, setDataF] = React.useState([]);
  const [equipos, setEquipos] = React.useState([]);
  //Theme
  const theme = useTheme();

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //Modal Styles
  const style = {
    position: "inherit",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "90%" : "35%",
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    borderRadius: 4,
    boxShadow: 1,
    p: 4,
  };

  //info Handlers
  const [infoConvo, setInfoConvo] = React.useState("");

  //Logística Handlers
  const [sillas, setSillas] = React.useState("");
  const [mesas, setMesas] = React.useState("");
  const [minibus, setMinibus] = React.useState("");
  const [pickup, setPickup] = React.useState("");

  //Insumos Handlers
  const [plumpy, setPlumpy] = React.useState("");
  const [rutf, setRutf] = React.useState("");
  const [desp, setDesp] = React.useState("");
  const [microNut, setMicroNut] = React.useState("");
  const [meds, setMeds] = React.useState("");

  //Modal Handlers
  const [openInfo, setOpenInfo] = React.useState(false);
  const handleOpenInfo = (row) => {
    var datosLogistica = JSON.parse(row.original.logistica);
    var datosInsumos = JSON.parse(row.original.insumos);
    let equiposConvo = JSON.parse(row.original.equipos);
    var nombreEquipos = "";
    for (let i = 0; i < equipos.length; i++) {
      for (let j = 0; j < equiposConvo.length; j++) {
        if (equiposConvo[j] === equipos[i].id) {
          nombreEquipos = equipos[i].nombre + ", " + nombreEquipos;
        }
      }
    }

    setInfoConvo(nombreEquipos.slice(0, -2));

    if (datosLogistica.sillas) {
      setSillas("Si");
    } else {
      setSillas("No");
    }
    if (datosLogistica.mesas) {
      setMesas("Si");
    } else {
      setMesas("No");
    }
    if (datosLogistica.minibus) {
      setMinibus("Si");
    } else {
      setMinibus("No");
    }
    if (datosLogistica.pickup) {
      setPickup("Si");
    } else {
      setPickup("No");
    }
    if (datosInsumos.plumpy) {
      setPlumpy("Si");
    } else {
      setPlumpy("No");
    }
    if (datosInsumos.rutf) {
      setRutf("Si");
    } else {
      setRutf("No");
    }
    if (datosInsumos.desparasitantes) {
      setDesp("Si");
    } else {
      setDesp("No");
    }
    if (datosInsumos.micronutrientes) {
      setMicroNut("Si");
    } else {
      setMicroNut("No");
    }
    if (datosInsumos.medicamentos) {
      setMeds("Si");
    } else {
      setMeds("No");
    }
    var datosInfo = JSON.parse(row.original.equipos);

    totalesConvo(row.original.id);
    setOpenInfo(true);
  };
  const handleCloseInfo = () => setOpenInfo(false);

  //Tabs
  const [valueTabs, setValueTabs] = React.useState(0);

  const handleChangeTabs = (event, newValueTabs) => {
    setValueTabs(newValueTabs);
  };

  //BreadCrumbs
  function handleClickBreadCrumbs(event) {
    event.preventDefault();
  }

  //Routes
  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };

  const handleLinkAsist = (event, message, row) => {
    let con = JSON.stringify(row.original);
    if (message === "asist") {
      nav(`/asistencia?con=${con}`);
    }
  };

  const handleLinkRetro = (event, message) => {
    if (message === "retro") {
      nav("/retroalimentacion");
    }
  };

  const handleLinkPost = (event, message) => {
    if (message === "post") {
      nav("/postulaciones");
    }
  };
  const [datosConvo, setDatosConvo] = React.useState([]);
  const totalesConvo = async (id) => {
    try {
      const { data1 } = await axios
        .get("/totalesconvocatorias/", {
          params: {
            id: id,
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setDatosConvo(response.data);

          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const getEquipos = async () => {
    try {
      const { data1 } = await axios
        .get("/equipos")
        .then(function (response) {
          // setAlerta({})

          setEquipos(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const convocatorias = async () => {
    try {
      const { data1 } = await axios
        .get("/convocatoria/", {
          params: {
            id: 1,
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setData(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const convocatoriasF = async () => {
    try {
      const { data1 } = await axios
        .get("/convocatoria/", {
          params: {
            id: 2,
          },
        })
        .then(function (response) {
          // setAlerta({})

          setDataF(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "nombre",
        header: "Poyecto",
      },
      {
        accessorKey: "actividad",
        header: "Tipo de actividad",
      },
      {
        accessorKey: "fecha",
        header: "Fecha",
      },
      {
        accessorKey: "estadoConvo",
        header: "Estado",
      },
      {
        accessorKey: "municipio",
        header: "Municipio",
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
      },
      {
        accessorKey: "extra",
        header: "Extras",
      },
    ],
    []
  );

  if (ejecutar) {
    convocatorias();
    convocatoriasF();
    getEquipos();
  }
  if (!data)
    return (
      <Container component="main" maxWidth="xs">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );

  return (
    <Box m="1.5rem 2.5rem">
      {rol === "1" || rol === "2" || rol === "7" ? (
        <>
          <Box
            role="presentation"
            onClick={handleClickBreadCrumbs}
            sx={{ mb: 3 }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/inicio"
                onClick={(event) => handleLinkHome(event, "home")}
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Inicio
              </Link>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <GroupsOutlined sx={{ mr: 0.5 }} fontSize="inherit" />
                Gestión Humana
              </Typography>
            </Breadcrumbs>
            <Typography variant="h3" sx={{ fontWeight: "bold", mt: 2 }}>
              Gestión Humana
            </Typography>
            <Box
              mt="10px"
              display="grid"
              gridTemplateColumns="repeat(8, 1fr)"
              gridAutoRows="160px"
              gap="20px"
              sx={{
                "& > div": {
                  gridColumn: isNonMediumScreens ? undefined : "span 12",
                },
              }}
            >
              <Box
                gridColumn="span 2"
                gridRow="span 2"
                p="1.5rem"
                borderRadius="1.5rem"
                mt="10px"
                height="fit-content"
              >
                <Grid container>
                  <Grid item xs={12} mt={2}>
                    <Card
                      className="text-center zoom"
                      sx={{
                        borderRadius: 6,
                        boxShadow: 8,
                        bgcolor: theme.palette.background.alt,
                      }}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid item xs={5}>
                            <Avatar
                              sx={{
                                width: 80,
                                height: 80,
                                bgcolor: "teal",
                                mt: 2,
                                mb: 2,
                                ml: 1,
                              }}
                            >
                              <ContactPageIcon
                                sx={{ fontSize: 50, color: "white" }}
                              />
                            </Avatar>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              sx={{
                                fontSize: 16,
                                fontWeight: "bold",
                                mt: 2,
                                ml: 2,
                              }}
                              gutterBottom
                            >
                              Postulaciones
                            </Typography>
                            <Button
                              variant="contained"
                              size="lg"
                              sx={{
                                bgcolor: "teal",
                                color: "white",
                                borderRadius: 2,
                                mt: 1,
                                ml: 2,
                              }}
                              fullWidth
                              onClick={(event) => handleLinkPost(event, "post")}
                            >
                              Iniciar
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <Card
                      className="text-center zoom"
                      sx={{
                        borderRadius: 6,
                        boxShadow: 8,
                        bgcolor: theme.palette.background.alt,
                      }}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid item xs={5}>
                            <Avatar
                              sx={{
                                width: 80,
                                height: 80,
                                bgcolor: "teal",
                                mt: 2,
                                mb: 2,
                                ml: 1,
                              }}
                            >
                              <InboxIcon
                                sx={{ fontSize: 50, color: "white" }}
                              />
                            </Avatar>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              sx={{
                                fontSize: 16,
                                fontWeight: "bold",
                                mt: 2,
                                ml: 2,
                              }}
                              gutterBottom
                            >
                              Sugerencias
                            </Typography>
                            <Button
                              variant="contained"
                              size="lg"
                              sx={{
                                bgcolor: "teal",
                                color: "white",
                                borderRadius: 2,
                                mt: 1,
                                ml: 2,
                              }}
                              fullWidth
                              onClick={(event) =>
                                handleLinkRetro(event, "retro")
                              }
                            >
                              Iniciar
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
              <Box
                gridColumn="span 6"
                gridRow="span 3"
                backgroundColor={theme.palette.background.alt}
                p="1.5rem"
                borderRadius="1.5rem"
                mt="40px"
                height="fit-content"
                sx={{ boxShadow: 4 }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={valueTabs}
                      onChange={handleChangeTabs}
                      variant={isSmallScreen ? "scrollable" : "fullWidth"}
                    >
                      <Tab
                        icon={<EventAvailableIcon />}
                        iconPosition="start"
                        label="Convocatorias Activas"
                        {...a11yProps(0)}
                      />
                      <Tab
                        icon={<EventBusyIcon />}
                        iconPosition="start"
                        label="Convocatorias Finalizadas"
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={valueTabs} index={0}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                          Convocatorias Activas
                        </Typography>
                      </Grid>
                    </Grid>
                    <MaterialReactTable
                      columns={columns}
                      data={data}
                      localization={MRT_Localization_ES}
                      enableRowActions
                      positionExpandColumn="first"
                      positionActionsColumn="first"
                      renderRowActionMenuItems={({ row, closeMenu }) => [
                        <MenuItem
                          onClick={(event) =>
                            handleLinkAsist(event, "asist", row)
                          }
                        >
                          <RuleOutlinedIcon sx={{ mr: 1 }} /> <b>Asistencia</b>
                        </MenuItem>,
                        <MenuItem
                          onClick={(event) => {
                            handleOpenInfo(row);
                            closeMenu();
                          }}
                        >
                          <InfoIcon sx={{ mr: 1 }} /> <b>Ver Información</b>
                        </MenuItem>,
                      ]}
                      muiTopToolbarProps={{
                        sx: {
                          backgroundColor: theme.palette.background.alt,
                        },
                      }}
                      muiTableHeadCellProps={{
                        align: "center",
                        sx: {
                          backgroundColor: theme.palette.background.alt,
                        },
                      }}
                      muiBottomToolbarProps={{
                        sx: {
                          backgroundColor: theme.palette.background.alt,
                        },
                      }}
                      muiTableBodyProps={{
                        sx: {
                          "& tr:nth-of-type(odd)": {
                            backgroundColor: theme.palette.background.light,
                          },
                        },
                      }}
                      muiTableBodyCellProps={{
                        align: "center",
                      }}
                      muiTablePaperProps={{
                        elevation: 0,
                      }}
                      initialState={{
                        pagination: {
                          pageSize: 20,
                          pageIndex: 0,
                        },
                        sorting: [
                          {
                            id: "fecha",
                            desc: true,
                          },
                        ],
                      }}
                      muiTablePaginationProps={{
                        rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                        showFirstButton: false,
                        showLastButton: false,
                        SelectProps: {
                          native: true,
                        },
                        labelRowsPerPage: "Número de filas visibles",
                      }}
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={valueTabs} index={1}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                          Convocatorias Finalizadas
                        </Typography>
                      </Grid>
                    </Grid>
                    <MaterialReactTable
                      columns={columns}
                      data={dataF}
                      localization={MRT_Localization_ES}
                      enableRowActions
                      positionExpandColumn="first"
                      positionActionsColumn="first"
                      renderRowActionMenuItems={({ row }) => [
                        <MenuItem
                          onClick={(event) =>
                            handleLinkAsist(event, "asist", row)
                          }
                        >
                          <RuleOutlinedIcon sx={{ mr: 1 }} /> <b>Asistencia</b>
                        </MenuItem>,
                      ]}
                      muiTopToolbarProps={{
                        sx: {
                          backgroundColor: theme.palette.background.alt,
                        },
                      }}
                      muiTableHeadCellProps={{
                        align: "center",
                        sx: {
                          backgroundColor: theme.palette.background.alt,
                        },
                      }}
                      muiBottomToolbarProps={{
                        sx: {
                          backgroundColor: theme.palette.background.alt,
                        },
                      }}
                      muiTableBodyProps={{
                        sx: {
                          "& tr:nth-of-type(odd)": {
                            backgroundColor: theme.palette.background.light,
                          },
                        },
                      }}
                      muiTableBodyCellProps={{
                        align: "center",
                      }}
                      muiTablePaperProps={{
                        elevation: 0,
                      }}
                      initialState={{
                        pagination: {
                          pageSize: 20,
                          pageIndex: 0,
                        },
                        sorting: [
                          {
                            id: "fecha",
                            desc: true,
                          },
                        ],
                      }}
                      muiTablePaginationProps={{
                        rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                        showFirstButton: false,
                        showLastButton: false,
                        SelectProps: {
                          native: true,
                        },
                        labelRowsPerPage: "Número de filas visibles",
                      }}
                    />
                  </CustomTabPanel>
                </Box>
              </Box>
            </Box>
          </Box>
          <Modal open={openInfo} onClose={handleCloseInfo}>
            <Box sx={style}>
              <Typography variant="h5">
                {" "}
                <InfoOutlinedIcon sx={{ fontSize: 27 }} />{" "}
                <b style={{ fontSize: 18 }}> Detalles de la Convocatoria:</b>{" "}
                {infoConvo}
              </Typography>
              <Divider sx={{ mt: 1, mb: 2 }}>
                <Chip label="INFO. GENERAL" />
              </Divider>
              <Grid container>
                <Grid item sx={12} sm={6}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    <b>Asistencias Confirmadas:</b>{" "}
                    <CountUp end={datosConvo.totalAceptadas} separator="." />{" "}
                  </Typography>
                </Grid>
                <Grid item sx={12} sm={6}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    <b>Asistencias Rechazadas:</b>{" "}
                    <CountUp end={datosConvo.totalRechazadas} separator="." />{" "}
                  </Typography>
                </Grid>
                <Grid item sx={12} sm={6}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    <b>Cantidad de Refrigerios:</b>{" "}
                    <CountUp
                      end={Number(datosConvo.totalRefrigerios)}
                      separator="."
                    />{" "}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 3 }}>
                <Chip label="LOGÍSTICA" />
              </Divider>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <Typography variant="h5" mt={3}>
                    <b>Sillas:</b> {sillas}{" "}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography variant="h5" mt={3}>
                    {" "}
                    <b>Mesas:</b> {mesas}{" "}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography variant="h5">
                    {" "}
                    <b>Minibus (Coaster):</b> {minibus}{" "}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography variant="h5">
                    {" "}
                    <b>Pick-Up:</b> {pickup}{" "}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 4 }}>
                <Chip label="INSUMOS" />
              </Divider>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={4}>
                  <Typography variant="h5" mt={3}>
                    <b>Plumpy:</b> {plumpy}{" "}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Typography variant="h5" mt={3}>
                    <b>RUTF:</b> {rutf}{" "}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Typography variant="h5" mt={3}>
                    <b>Micronutrientes:</b> {microNut}{" "}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Typography variant="h5">
                    <b>Desparasitantes:</b> {desp}{" "}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Typography variant="h5">
                    <b>Medicamentos:</b> {meds}{" "}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 3, mb: 2 }} />
              <Grid
                container
                sx={{ mt: 2 }}
                spacing={1}
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={(event) => {
                      handleCloseInfo();
                    }}
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </>
      ) : (
        <Restricted />
      )}
    </Box>
  );
};

export default GestionHumana;
