import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme, Modal, Box } from "@mui/material";
import "./cardStyles.scss";
import logo from "../../assets/paluz-icon-2.png";

const ProfileCard = (data) => {
  const [items, setItems] = useState([]);
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const nav = useNavigate();

  //Theme
  const theme = useTheme();

  //Modal Styles
  const style = {
    position: "inherit",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    borderRadius: 4,
    boxShadow: 1,
    p: 4,
  };

  //Routes
  const handleLinkPerfil = (event, message) => {
    if (message === "perfil") {
      nav("/perfil");
    }
  };

  return (
    <div class="frame">
      <div class="center">
        <div class="profile">
          <div class="image">
            <div class="circle-1"></div>
            <div class="circle-2"></div>
            <img src={logo} width="70" height="70" alt="PALUZ" />
          </div>

          <div class="name">
            {items.nombre} {items.apellido}
          </div>
          <div class="job">{items.ocupacion}</div>

          <div class="actions">
            <button
              class="btn"
              onClick={(event) => {
                handleLinkPerfil(event, "perfil");
                data.onCloseModal();
              }}
            >
              Ver Perfil
            </button>
            <button class="btn-danger" onClick={data.onCloseModal}>
              CERRAR
            </button>
          </div>
        </div>

        <div class="stats">
          <div class="box">
            {/*<span class="value">1</span>
            <span class="parameter">Ranking General</span>*/}
          </div>
          {/*<div class="box">
            <span class="value">1</span>
            <span class="parameter">Ranking NUT</span>
          </div>
          <div class="box">
            <span class="value">1</span>
            <span class="parameter">Ranking Salud</span>
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
