import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  LightModeOutlined,
  DarkModeOutlined,
  Menu as MenuIcon,
  ArrowDropDownOutlined,
} from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import FlexBetween from "components/FlexBetween";
import { useDispatch } from "react-redux";
import { setMode } from "state";
import icon from "assets/paluz-icon-3.png";
import icon2 from "assets/paluz-icon-2.png";
import {
  AppBar,
  Button,
  Box,
  Tooltip,
  Typography,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
  Modal,
} from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BuildCircleOutlinedIcon from "@mui/icons-material/BuildCircleOutlined";
import ProfileCard from "components/ProfileCard/Card";

const Navbar = ({ user, isSidebarOpen, setIsSidebarOpen, props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Theme
  const theme = useTheme();

  //Navigate
  const nav = useNavigate();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const [anchorTools, setAnchorTools] = React.useState(null);
  const openTools = Boolean(anchorTools);
  const handleClickTools = (event) => {
    setAnchorTools(event.currentTarget);
  };
  const handleCloseTools = () => {
    setAnchorTools(null);
  };

  const [items, setItems] = useState([]);
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    localStorage.removeItem("token");
    setAnchorEl(null);
    navigate("/");
  };

  //Modal Styles
  const style = {
    position: "inherit",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    borderRadius: 4,
    boxShadow: 1,
    p: 4,
  };

  //Modal Handlers
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //Routes
  const handleLinkFichas = (event, message) => {
    if (message === "fichas") {
      nav("/generadorFichas");
    }
  };

  return (
    <AppBar
      sx={{
        position: "sticky",
        background: theme.palette.background.main,
        boxShadow: 10,
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <FlexBetween>
          <Tooltip
            title={isSidebarOpen === true ? "Ocultar Menú" : "Mostrar Menú"}
          >
            <IconButton
              sx={{ color: "white" }}
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            >
              {isSidebarOpen === true && !isNonMobile ? (
                <MenuIcon fontSize="large" />
              ) : isSidebarOpen === true ? (
                <MenuOpenIcon fontSize="large" />
              ) : (
                <MenuIcon fontSize="large" />
              )}
            </IconButton>
          </Tooltip>
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap="1rem">
          <Tooltip title={"Herramientas"}>
            <IconButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClickTools}
            >
              <BuildCircleOutlinedIcon
                sx={{ fontSize: "25px", color: "white" }}
              />
            </IconButton>
          </Tooltip>
          <Menu
            id="basic-menu"
            anchorEl={anchorTools}
            open={openTools}
            onClose={handleCloseTools}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={(event) => {
                handleLinkFichas(event, "fichas");
                handleCloseTools();
              }}
            >
              Generador de Fichas de Registro
            </MenuItem>
          </Menu>
          <Tooltip
            title={
              theme.palette.mode === "dark"
                ? "Desactivar Modo Oscuro"
                : "Activar Modo Oscuro"
            }
          >
            <IconButton onClick={() => dispatch(setMode())}>
              {theme.palette.mode === "dark" ? (
                <DarkModeOutlined sx={{ fontSize: "25px", color: "white" }} />
              ) : (
                <LightModeOutlined sx={{ fontSize: "25px", color: "white" }} />
              )}
            </IconButton>
          </Tooltip>
          <FlexBetween>
            <Button
              onClick={handleClick}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textTransform: "none",
                gap: "1rem",
              }}
            >
              <Box
                component="img"
                alt="profile"
                src={theme.palette.mode === "dark" ? icon2 : icon}
                height="40px"
                width="40px"
                borderRadius="50%"
                sx={{ objectFit: "cover" }}
              />
              <Box textAlign="left">
                <Typography
                  fontWeight="bold"
                  fontSize="0.85rem"
                  sx={{ color: "white" }}
                >
                  {items.nombre} {items.apellido}
                </Typography>
                <Typography fontSize="0.75rem" sx={{ color: "white" }}>
                  {items.ocupacion}
                </Typography>
                <Typography fontSize="0.65rem" sx={{ color: "white" }}>
                  {items.nombreRol}
                </Typography>
              </Box>
              <ArrowDropDownOutlined
                sx={{ color: "white", fontSize: "25px" }}
              />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleCloseMenu}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <MenuItem
                onClick={(event) => {
                  handleOpen();
                  handleCloseMenu();
                }}
                sx={{ fontWeight: "bold" }}
              >
                <AccountCircleIcon sx={{ mr: 1 }} /> Perfil{" "}
              </MenuItem>
              <MenuItem
                onClick={(event) => {
                  handleLogOut();
                  handleCloseMenu();
                }}
                sx={{ fontWeight: "bold" }}
              >
                <LogoutIcon sx={{ mr: 1 }} /> Cerrar Sesión{" "}
              </MenuItem>
            </Menu>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <ProfileCard onCloseModal={handleClose} />
        </Box>
      </Modal>
    </AppBar>
  );
};

export default Navbar;
