import React, { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { useTheme, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import HomeIcon from "@mui/icons-material/Home";
import InventoryOutlined from "@mui/icons-material/InventoryOutlined";
import CasesRoundedIcon from "@mui/icons-material/CasesRounded";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';
import InboxIcon from "@mui/icons-material/Inbox";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Restricted from "components/Restricted";
import clienteAxios from "../../config/clienteAxios";
import axios from "axios";

//Tabs Settings
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Retroalimentacion = () => {
  const nav = useNavigate();
 
  const [data1, setData1] = React.useState([]);
  const [ejecutar, setEjecutar] = React.useState(true);
  const [sugerenciaV, setSugerenciaV] = React.useState();

  const [items, setItems] = React.useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const rol = items.rol;

  //Theme
  const theme = useTheme();

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [nombreEquipo, setNombreEquipo] = useState("");
  const [descEquipo, setDescEquipo] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 5,
    p: 4,
  };

  function handleClickBreadCrumbs(event) {
    event.preventDefault();
  }

  //Modal Handlers
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [openAlert, setOpenAlert] = React.useState(false);
  const handleOpenAlert = () => {
    setOpenAlert(true);
    handleCloseAlert();
    handleClose();
  };
  const handleCloseAlert = () =>
    setTimeout(() => {
      setOpenAlert(false);
    }, 2000);

  const [openAlertEdit, setOpenAlertEdit] = React.useState(false);
  const handleOpenAlertEdit = () => {
    setOpenAlertEdit(true);
    handleCloseAlertEdit();
    handleCloseEdit();
  };
  const handleCloseAlertEdit = () =>
    setTimeout(() => {
      setOpenAlertEdit(false);
    }, 2000);

  //Tabs Handlers
  const [valueTabs, setValueTabs] = React.useState(0);

  const handleChangeTabs = (event, newValueTabs) => {
    setValueTabs(newValueTabs);
  };

  //Routes
  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };

  const handleLinkGestion = (event, message) => {
    if (message === "gestion") {
      nav("/gestionHumana");
    }
  };

  const handleLinkConv = (event, message, row) => {
    let pro = JSON.stringify(row.original);
    if (message === "convos") {
      nav("/retroConvocatorias");
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "firstName",
        header: "First Name",
      },
      {
        accessorKey: "lastName",
        header: "Last Name",
      },
    ],
    []
  );

  const sugVol = useMemo(
    () => [
      {
        accessorKey: "fecha_registro",
        header: "Fecha",
      },
      {
        accessorKey: "nombre",
        header: "Nombre",
      },
      {
        accessorKey: "estado",
        header: "Estado",
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue() === "Sin Revisar" ? (
              <Box
                sx={{
                  backgroundColor: "black",
                  borderRadius: "0.25rem",
                  color: "#fff",
                  maxWidth: "12ch",
                  p: "0.25rem",
                }}
              >
                Sin Revisar
              </Box>
            ) : (
              <Box
                sx={{
                  backgroundColor: "green",
                  borderRadius: "0.25rem",
                  color: "#fff",
                  maxWidth: "12ch",
                  p: "0.25rem",
                }}
              >
                Revisado
              </Box>
            )}
          </Box>
        ),
      },
    ],
    []
  );

  const handleAsistencia = async (dato) => {
    console.log(dato.original.id_sugerencia);

    try {
      const { data1 } = await axios
        .put("/revisarsugerencia", {
          id: dato.original.id_sugerencias,
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response);
          //localStorage.setItem('token',JSON.stringify(response.data) )
          //setAuth(data)
          setEjecutar(true);
        })
        .catch(function (error) {
          console.log("error");
          // document.getElementById(":r7:").value='';
        });
      //setOpen(false);
    } catch (error) {
      //handleOpenAlertReject();
    }
  };

  const data = async () => {
    try {
      const { data1 } = await axios
        .get("/sugerencias/")
        .then(function (response) {
          // setAlerta({})

          setSugerenciaV(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const proyectos = async () => {
    try {
      const { data1 } = await axios
        .get("/proyectos")
        .then(function (response) {
          // setAlerta({})

          setData1(response.data);
          setEjecutar(false);
          console.log(data);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  if (ejecutar) {
    data();
    proyectos();
  }

  if (!sugerenciaV)
    return (
      <Container component="main" maxWidth="xs">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );

  return (
    <Box m="1.5rem 2.5rem">
      {rol === "1" || rol === "2" || rol === "7" ? (
        <>
          <Box
            role="presentation"
            onClick={handleClickBreadCrumbs}
            sx={{ mb: 3 }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/inicio"
                onClick={(event) => handleLinkHome(event, "home")}
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Inicio
              </Link>
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/gestionHumana"
                onClick={(event) => handleLinkGestion(event, "gestion")}
              >
                <InventoryOutlined sx={{ mr: 0.5 }} fontSize="inherit" />
                Gestión Humana
              </Link>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <InboxIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Sugerencias
              </Typography>
            </Breadcrumbs>
            <Typography variant="h3" sx={{ fontWeight: "bold", mt: 2 }}>
              Sugerencias
            </Typography>
          </Box>
          <Container>
            <Box
              gridColumn="span 8"
              gridRow="span 3"
              backgroundColor={theme.palette.background.alt}
              p="1.5rem"
              borderRadius="1.5rem"
              mt="40px"
              height="fit-content"
              sx={{ boxShadow: 4 }}
            >
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={valueTabs}
                    onChange={handleChangeTabs}
                    variant={isSmallScreen ? "scrollable" : "fullWidth"}
                    scrollButtons
                    allowScrollButtonsMobile
                  >
                    <Tab
                      icon={<CasesRoundedIcon />}
                      iconPosition="start"
                      label="Proyectos"
                      {...a11yProps(0)}
                    />
                    <Tab
                      icon={<PeopleAltIcon />}
                      iconPosition="start"
                      label="Voluntarios"
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel value={valueTabs} index={0}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        Listado de Proyectos
                      </Typography>
                    </Grid>
                  </Grid>

                  <MaterialReactTable
                    columns={columns}
                    data={data1}
                    localization={MRT_Localization_ES}
                    enableRowActions
                    positionActionsColumn="first"
                    displayColumnDefOptions={{
                      "mrt-row-actions": {
                        header: "Opciones",
                        size: 10,
                      },
                    }}
                    renderRowActionMenuItems={({ row }) => [
                      <MenuItem
                        onClick={(event) =>
                          handleLinkConv(event, "convos", row)
                        }
                      >
                        <ContentPasteSearchOutlinedIcon sx={{ mr:1 }} /><b>Ver Convocatorias</b>
                      </MenuItem>,
                    ]}
                    muiTopToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableHeadCellProps={{
                      align: "center",
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiBottomToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableBodyProps={{
                      sx: {
                        "& tr:nth-of-type(odd)": {
                          backgroundColor: theme.palette.background.light,
                        },
                      },
                    }}
                    muiTableBodyCellProps={{
                      align: "center",
                    }}
                    muiTablePaperProps={{
                      elevation: 0, //change the mui box shadow
                      //customize paper styles
                    }}
                    initialState={{
                      pagination: {
                        pageSize: 5,
                        pageIndex: 0,
                      },
                    }}
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                      showFirstButton: false,
                      showLastButton: false,
                      SelectProps: {
                        native: true,
                      },
                      labelRowsPerPage: "Número de filas visibles",
                    }}

                    /*renderDetailPanel={({ row }) => (
    <Box
      sx={{
        display: 'grid',
        margin: 'auto',
        gridTemplateColumns: '1fr 1fr',
        width: '100%',
      }}
    >
      <Typography>Address: {row.original.address}</Typography>
      <Typography>City: {row.original.city}</Typography>
      <Typography>State: {row.original.state}</Typography>
      <Typography>Country: {row.original.country}</Typography>
    </Box>
    )} */
                  />
                </CustomTabPanel>
                <CustomTabPanel value={valueTabs} index={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        Sugerencias de Voluntarios
                      </Typography>
                    </Grid>
                  </Grid>
                  <MaterialReactTable
                    columns={sugVol}
                    data={sugerenciaV}
                    localization={MRT_Localization_ES}
                    enableRowActions
                    positionActionsColumn="last"
                    positionExpandColumn="first"
                    displayColumnDefOptions={{
                      "mrt-row-actions": {
                        header: "Acciones",
                        size: 10,
                      },
                    }}
                    renderRowActions={({ row, table }) => [
                      <Box
                        sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}
                      >
                        <Tooltip title={row.getValue("estado") === "Revisado" ? "Revisado" : "Revisar"}>
                          <span>
                            <IconButton
                              color="success"
                              onClick={(event) => handleAsistencia(row)}
                              disabled={ row.getValue("estado") === "Revisado"}
                              /*style={{
                                display:
                                  row.getValue("estado") === "Revisado"
                                    ? "none"
                                    : "block",
                              }}*/
                            >
                              <CheckCircleOutlineOutlinedIcon
                                sx={{ fontSize: 30 }}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Box>,
                    ]}
                    muiTopToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableHeadCellProps={{
                      align: "center",
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiBottomToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableBodyProps={{
                      sx: {
                        "& tr:nth-of-type(odd)": {
                          backgroundColor: theme.palette.background.light,
                        },
                      },
                    }}
                    muiTableBodyCellProps={{
                      align: "center",
                    }}
                    muiTablePaperProps={{
                      elevation: 0,
                    }}
                    initialState={{
                      pagination: {
                        pageSize: 5,
                        pageIndex: 0,
                      },
                    }}
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                      showFirstButton: false,
                      showLastButton: false,
                      SelectProps: {
                        native: true,
                      },
                      labelRowsPerPage: "Número de filas visibles",
                    }}
                    renderDetailPanel={({ row }) => (
                      <Box
                        sx={{
                          display: "grid",
                          margin: "auto",
                          gridTemplateColumns: "1fr 1fr",
                          width: "100%",
                        }}
                      >
                        <Typography>
                          <b>Sugerencia:</b> {row.original.sugerencia}
                        </Typography>
                      </Box>
                    )}
                  />
                </CustomTabPanel>
              </Box>
            </Box>
          </Container>
        </>
      ) : (
        <Restricted />
      )}
    </Box>
  );
};

export default Retroalimentacion;
