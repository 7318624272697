import React, { Component } from "react";

class DesktopNotification extends Component {
  constructor() {
    super();
    this.showNotification = this.showNotification.bind(this);
  }

  componentDidMount() {
    if (!("Notification" in window)) {
      console.log("El Navegador no soporta notificaciones");
    } else {
      Notification.requestPermission();
    }
  }

  showNotification() {
    var options = {
      body: "Esta es una notificación",
      icon: "https://i.postimg.cc/Vv3GQZg7/icono-paluz-2.png",
      dir: "ltr",
    };
    new Notification("PALUZ", options);
  }

  render() {
    return (
      <div style={{ marginTop: 10 }}>
        <button onClick={this.showNotification}>Prueba Notificaciones</button>
      </div>
    );
  }
}

export default DesktopNotification;
