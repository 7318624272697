import React, { useMemo, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Container from "@mui/material/Container";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import HomeIcon from "@mui/icons-material/Home";
import InventoryOutlined from "@mui/icons-material/InventoryOutlined";
import ListConvos from "components/ListConvos";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import Restricted from "components/Restricted";
import clienteAxios from "../../config/clienteAxios";

const RetroConvocatorias = () => {
  const nav = useNavigate();
  /*const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token =params.get("prop");
  const proyecto = JSON.parse(token) 
  console.log(proyecto)
  const [data, setData]= React.useState([]);
  const [ejecutar, setEjecutar] = React.useState(true);
  const [tabla, setTabla] = React.useState(false);
  const [datos, setDatos] = React.useState({});
  const [solicitud, setSolicitud]= React.useState([]);*/

  const [items, setItems] = React.useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const rol = items.rol;

  //Theme
  const theme = useTheme();

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //Modal Styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 5,
    p: 4,
  };

  function handleClickBreadCrumbs(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const styleVol = {
    position: "inherit",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "90%" : "50%",
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    borderRadius: 4,
    boxShadow: 1,
    p: 4,
  };

  //Modal Handlers
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = (row) => {
    setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);

  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [openAlert, setOpenAlert] = React.useState(false);
  const handleOpenAlert = () => {
    setOpenAlert(true);
    handleCloseAlert();
    handleClose();
  };
  const handleCloseAlert = () =>
    setTimeout(() => {
      setOpenAlert(false);
    }, 2000);

  const [openAlertEdit, setOpenAlertEdit] = React.useState(false);
  const handleOpenAlertEdit = () => {
    setOpenAlertEdit(true);
    handleCloseAlertEdit();
    handleCloseEdit();
  };
  const handleCloseAlertEdit = () =>
    setTimeout(() => {
      setOpenAlertEdit(false);
    }, 2000);

  //Routes
  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };

  const handleLinkGH = (event, message) => {
    if (message === "gestionHumana") {
      nav("/gestionhumana");
    }
  };

  const handleLinkRetro = (event, message) => {
    if (message === "retro") {
      nav("/crearRetroalimentacion");
    }
  };

  const myConvos = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        accessorKey: "firstName",
        header: "First Name",
      },
      {
        accessorKey: "lastName",
        header: "Last Name",
      },
    ],
    []
  );

  const data = [
    {
      id: "1",
      firstName: "Dylan",
      middleName: "Sprouse",
      lastName: "Murray",
      address: "261 Erdman Ford",
      city: "East Daphne",
      state: "Kentucky",
      country: "United States",
    },
    {
      id: "2",
      firstName: "Raquel",
      middleName: "Hakeem",
      lastName: "Kohler",
      address: "769 Dominic Grove",
      city: "Vancouver",
      state: "British Columbia",
      country: "Canada",
    },
    {
      id: "3",
      firstName: "Ervin",
      middleName: "Kris",
      lastName: "Reinger",
      address: "566 Brakus Inlet",
      city: "South Linda",
      state: "West Virginia",
      country: "United States",
    },
    {
      id: "4",
      firstName: "Brittany",
      middleName: "Kathryn",
      lastName: "McCullough",
      address: "722 Emie Stream",
      city: "Lincoln",
      state: "Nebraska",
      country: "United States",
    },
    {
      id: "5",
      firstName: "Branson",
      middleName: "John",
      lastName: "Frami",
      address: "32188 Larkin Turnpike",
      city: "Charleston",
      state: "South Carolina",
      country: "United States",
    },
    {
      id: "6",
      firstName: "Branson",
      middleName: "John",
      lastName: "Frami",
      address: "32188 Larkin Turnpike",
      city: "Charleston",
      state: "South Carolina",
      country: "United States",
    },
    {
      id: "7",
      firstName: "Branson",
      middleName: "John",
      lastName: "Frami",
      address: "32188 Larkin Turnpike",
      city: "Charleston",
      state: "South Carolina",
      country: "United States",
    },
    {
      id: "8",
      firstName: "Branson",
      middleName: "John",
      lastName: "Frami",
      address: "32188 Larkin Turnpike",
      city: "Charleston",
      state: "South Carolina",
      country: "United States",
    },
    {
      id: "9",
      firstName: "Branson",
      middleName: "John",
      lastName: "Frami",
      address: "32188 Larkin Turnpike",
      city: "Charleston",
      state: "South Carolina",
      country: "United States",
    },
    {
      id: "10",
      firstName: "Branson",
      middleName: "John",
      lastName: "Frami",
      address: "32188 Larkin Turnpike",
      city: "Charleston",
      state: "South Carolina",
      country: "United States",
    },
    {
      id: "11",
      firstName: "Branson",
      middleName: "John",
      lastName: "Frami",
      address: "32188 Larkin Turnpike",
      city: "Charleston",
      state: "South Carolina",
      country: "United States",
    },
  ];

  /*if (!data ) return (
    <Container component="main" maxWidth="xs">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );*/

  return (
    <Box m="1.5rem 2.5rem">
      {rol === "1" || rol === "2" || rol === "7" ? (
        <>
          <Box
            role="presentation"
            onClick={handleClickBreadCrumbs}
            sx={{ mb: 3 }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/inicio"
                onClick={(event) => handleLinkHome(event, "home")}
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Inicio
              </Link>
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/gestionhumana"
                onClick={(event) => handleLinkGH(event, "gestionHumana")}
              >
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  <InventoryOutlined sx={{ mr: 0.5 }} fontSize="inherit" />
                  Gestión Humana
                </Typography>
              </Link>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <CalendarMonthOutlinedIcon
                  sx={{ mr: 0.5 }}
                  fontSize="inherit"
                />
                Retroalimentación
              </Typography>
            </Breadcrumbs>
            <Typography variant="h4" sx={{ mt: 2 }}>
              <b>Convocatorias:</b>
            </Typography>
          </Box>
          <Container>
            <Box
              gridColumn="span 8"
              gridRow="span 3"
              backgroundColor={theme.palette.background.alt}
              p="1.5rem"
              borderRadius="1.5rem"
              mt="40px"
              height="fit-content"
              sx={{ boxShadow: 4 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    Listado de Convocatorias
                  </Typography>
                </Grid>
              </Grid>

              <MaterialReactTable
                columns={myConvos}
                data={data}
                localization={MRT_Localization_ES}
                enableRowActions
                positionActionsColumn="first"
                displayColumnDefOptions={{
                  "mrt-row-actions": {
                    header: "Opciones",
                    size: 10,
                  },
                }}
                renderRowActionMenuItems={({ closeMenu, row }) => [
                  <MenuItem
                    onClick={(event) => {
                      handleLinkRetro(event, "retro");
                      closeMenu();
                    }}
                    sx={{ fontWeight: "bold" }}
                  >
                    <AssignmentOutlinedIcon sx={{ mr: 1 }} /> Ingresar
                    Retroalimentación
                  </MenuItem>,
                  <MenuItem
                    onClick={(event) => {
                      handleOpenModal(row);
                      closeMenu();
                    }}
                    sx={{ fontWeight: "bold" }}
                  >
                    <DescriptionOutlinedIcon sx={{ mr: 1 }} /> Ver Todas
                  </MenuItem>,
                ]}
                muiTopToolbarProps={{
                  sx: {
                    backgroundColor: theme.palette.background.alt,
                  },
                }}
                muiTableHeadCellProps={{
                  align: "center",
                  sx: {
                    backgroundColor: theme.palette.background.alt,
                  },
                }}
                muiBottomToolbarProps={{
                  sx: {
                    backgroundColor: theme.palette.background.alt,
                  },
                }}
                muiTableBodyProps={{
                  sx: {
                    "& tr:nth-of-type(odd)": {
                      backgroundColor: theme.palette.background.light,
                    },
                  },
                }}
                muiTableBodyCellProps={{
                  align: "center",
                }}
                muiTablePaperProps={{
                  elevation: 0, //change the mui box shadow
                  //customize paper styles
                }}
                initialState={{
                  pagination: {
                    pageSize: 5,
                    pageIndex: 0,
                  },
                }}
                muiTablePaginationProps={{
                  rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                  showFirstButton: false,
                  showLastButton: false,
                  SelectProps: {
                    native: true,
                  },
                  labelRowsPerPage: "Número de filas visibles",
                }}

                /*renderDetailPanel={({ row }) => (
    <Box
      sx={{
        display: 'grid',
        margin: 'auto',
        gridTemplateColumns: '1fr 1fr',
        width: '100%',
      }}
    >
      <Typography>Address: {row.original.address}</Typography>
      <Typography>City: {row.original.city}</Typography>
      <Typography>State: {row.original.state}</Typography>
      <Typography>Country: {row.original.country}</Typography>
    </Box>
    )} */
              />

              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                slotProps={{ backdrop: { style: { opacity: 0.2 } } }}
              >
                <Box sx={styleVol}>
                  <MaterialReactTable
                    columns={myConvos}
                    data={data}
                    localization={MRT_Localization_ES}
                    enableRowActions
                    positionActionsColumn="first"
                    displayColumnDefOptions={{
                      "mrt-row-actions": {
                        header: "Opciones",
                        size: 10,
                      },
                    }}
                    renderRowActionMenuItems={({ closeMenu, row }) => [
                      <MenuItem
                        onClick={(event) => {
                          closeMenu();
                        }}
                        sx={{ fontWeight: "bold" }}
                      >
                        Editar
                      </MenuItem>,
                      <MenuItem
                        onClick={(event) => {
                          closeMenu();
                        }}
                        sx={{ fontWeight: "bold" }}
                      >
                        Ver Reporte
                      </MenuItem>,
                    ]}
                    muiTopToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableHeadCellProps={{
                      align: "center",
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiBottomToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableBodyProps={{
                      sx: {
                        "& tr:nth-of-type(odd)": {
                          backgroundColor: theme.palette.background.light,
                        },
                      },
                    }}
                    muiTableBodyCellProps={{
                      align: "center",
                    }}
                    muiTablePaperProps={{
                      elevation: 0, //change the mui box shadow
                      //customize paper styles
                    }}
                    initialState={{
                      pagination: {
                        pageSize: 5,
                        pageIndex: 0,
                      },
                    }}
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                      showFirstButton: false,
                      showLastButton: false,
                      SelectProps: {
                        native: true,
                      },
                      labelRowsPerPage: "Número de filas visibles",
                    }}
                  />

                  <Divider sx={{ mt: 2 }} />
                  <Grid
                    container
                    sx={{ mt: 2 }}
                    spacing={1}
                    justifyContent="flex-end"
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleCloseModal}
                      >
                        Salir
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
            </Box>
          </Container>
        </>
      ) : (
        <Restricted />
      )}
    </Box>
  );
};

export default RetroConvocatorias;
