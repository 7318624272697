import React, { useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';



const Review = ({datos}) => {
  const dayjs = require("dayjs");

  const hoy = dayjs(datos.value).format("DD-MM-YYYY");
  const data = datos.mensaje;
  const [showMore, setShowMore] = useState(false);
  console.log(hoy);
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
       Resumen
      </Typography>
      <Divider>
        <Chip label="Datos Personales" size="small" />
      </Divider>
      <Grid container mt={1} mb={1} spacing={2} justifyContent='center'>
        <Grid item xs={12} sm={6}>
          <b>Nombre:</b> {datos.valueName}
        </Grid>
        <Grid item xs={12} sm={6}>
          <b>Apellido:</b> {datos.valueApellido}
        </Grid>
        <Grid item xs={12} sm={6}>
          <b>Cédula:</b> {datos.valueCedula}
        </Grid>
        <Grid item xs={12} sm={6}>
          <b>Teléfono:</b> {datos.values.textmask}
        </Grid>
        <Grid item xs={12} sm={6}>
          <b>Fecha de Nacimiento:</b> {hoy}
        </Grid>
        <Grid item xs={12} sm={6}>
          <b>Ocupación:</b> {datos.ocup}
        </Grid>
        <Grid item xs={12} sm={6}>
          <b>Correo Electrónico:</b> {datos.valueCorreo}
        </Grid>
        <Grid item xs={12} sm={6}>
          <b>Dirección:</b> {datos.valueDireccion}
        </Grid>
      </Grid>
      <Divider sx={{ mt:1, mb:1 }}>
        <Chip label="Motivo de Postulación" size="small" />
      </Divider>
      {showMore ? data : `${data.substring(0,100)}`}
      <Button onClick={() => setShowMore(!showMore)}>{showMore ? "Mostrar Menos" : "Mostrar Más"}</Button>
    </React.Fragment>
  )
}

export default Review