import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Box, Button, Grid, useTheme, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import HomeIcon from "@mui/icons-material/Home";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import SafetyCheckOutlinedIcon from "@mui/icons-material/SafetyCheckOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import AcceptTab from "components/AcceptTab";
import Restricted from "components/Restricted";
import clienteAxios from "../../config/clienteAxios";
import { mkConfig, generateCsv, download } from "export-to-csv";
import {
  useGetUsuariosQuery,
  useGetRolesQuery,
  useGetTiposQuery,
  useGetVoluntariosQuery,
} from "state/api";
import axios from "axios";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Admin = () => {
  const [items, setItems] = React.useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const rolUsuario = items.rol;

  const [open, setOpen] = React.useState(false);
  const [ejecutar, setEjecutar] = React.useState(true);
  const [datoVo, setdatoVo] = React.useState({});
  const [data, setData] = React.useState([]);
  const [datosUsuario, setDatosUsuario] = React.useState({});
  const [user, setUser] = React.useState([]);
  const [userInactivo, setUserInactivo] = React.useState([]);
  const [voluntarios, setVoluntarios] = React.useState([]);
  const [usuarios, setUsuarios] = React.useState([]);
  const [tipos, setTipos] = React.useState([]);
  const [roles, setRoles] = React.useState([]);

  //Tab Panel
  const [valueTabs, setValueTabs] = React.useState(0);

  const handleChangeTabs = (event, newValueTabs) => {
    setValueTabs(newValueTabs);
  };

  //Theme
  const theme = useTheme();

  const getTipos = async () => {
    try {
      const { data1 } = await axios
        .get("/tipo")
        .then(function (response) {
          // setAlerta({})

          setTipos(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const getRoles = async () => {
    try {
      const { data1 } = await axios
        .get("/rol")
        .then(function (response) {
          // setAlerta({})

          setRoles(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const voluntariosO = async () => {
    try {
      const { data1 } = await axios
        .get("/voluntarios/")
        .then(function (response) {
          // setAlerta({})

          setData(response.data);

          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const userS = async () => {
    try {
      const { data1 } = await axios
        .get("/usuarios/")
        .then(function (response) {
          // setAlerta({})
          let datos = response.data
          datos = datos.filter((dato) => dato.estado ===0);
          setUserInactivo(datos)
          setUser(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  if (ejecutar) {
    voluntariosO();
    userS();
    getRoles();
    getTipos();
  }
  const handleSubmit = async () => {
    try {
      const { data1 } = await axios
        .put("/perfil/cuentausuario/", {
          correo: datosUsuario.email,
          rol: rol,
          tipo: vol,
          estado: est,
          token: datosUsuario.token,
          nombres: datosUsuario.nombres,
        })
        .then(function (response) {
          // setAlerta({})
          //console.log(response.data.idToken)
          //localStorage.setItem('token',JSON.stringify(response.data) )
          //setAuth(data)
          setEjecutar(true);
          setOpen(false);
        })
        .catch(function (error) {
          console.log("error");
          // document.getElementById(":r7:").value='';
        });

      setOpen(false);
    } catch (error) {}
  };
  //console.log(data);
  console.log(voluntariosO);

  //BreadCrumbs
  function handleClickBreadCrumbs(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "inherit",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "90%" : "30%",
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    borderRadius: 4,
    boxShadow: 1,
    p: 4,
  };

  const handleOpen = () => {
    setOpen(true);
    setRol("");
    setEst("");
    setVol("");
  };
  const handleClose = () => {
    setOpen(false);
    setRol("");
    setEst("");
    setVol("");
    setEjecutar(true);
  };

  const [openAM, setOpenAM] = React.useState(false);
  const handleOpenAM = (dato) => {
    setdatoVo(dato);
    setOpenAM(true);
  };
  const handleCloseAM = () => {
    setEjecutar(true);
    setOpenAM(false);
  };

  //Rol de Usuario Select
  const [rol, setRol] = React.useState("");
  const handleChangeRol = (event) => {
    setRol(event.target.value);
    console.log(rol);
  };

  // Estado De Usuario Select
  const [est, setEst] = React.useState("");
  const handleChangeEst = (event) => {
    setEst(event.target.value);
    console.log(est);
  };

  //Tipo de Voluntario Select
  const [vol, setVol] = React.useState("");
  const handleChangeVol = (event) => {
    setVol(event.target.value);
    console.log(vol);
  };

  //const { data, isLoading } = useGetAdminsQuery();

  const columns = useMemo(
    () => [
      {
        accessorKey: "nombres",
        header: "Nombre",
        size: 5,
      },
      {
        accessorKey: "apellidos",
        header: "Apellido",
        size: 5,
      },
      /*{
        accessorKey: "cedula",
        header: "Cedula",
        size: 5,
      },*/
      {
        accessorKey: "estados",
        header: "Estado",
        size: 5,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={{
              backgroundColor:
                cell.getValue() === "Activo" ? "green" : "firebrick",
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "20ch",
              p: "0.25rem",
            }}
          >
            {cell.getValue()}
          </Box>
        ),
      },
    ],
    []
  );

  const pendientes = useMemo(
    () => [
      {
        accessorKey: "nombres",
        header: "Nombre",
        size: 5,
      },
      {
        accessorKey: "apellidos",
        header: "Apellido",
        size: 5,
      },
      {
        accessorKey: "revisado",
        header: "Estado",
        size: 5,
        Cell: ({ cell }) =>
          cell.getValue() === 0 ? (
            <Box
              component="span"
              sx={{
                backgroundColor: "darkorange",
                borderRadius: "0.25rem",
                color: "#fff",
                maxWidth: "20ch",
                p: "0.25rem",
              }}
            >
              Pendiente
            </Box>
          ) : (
            ""
          ),
      },
    ],
    []
  );

  const dataInactivos=[];

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };


  const [rowSelection, setRowSelection] = useState({});

  if (!usuarios || !roles || !tipos || data == "")
    return (
      <Container component="main" maxWidth="xs">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );

  return (
    <Box m="1.5rem 2.5rem">
      {rolUsuario === "1" || rolUsuario === "2" ? (
        <>
          <Box
            role="presentation"
            onClick={handleClickBreadCrumbs}
            sx={{ mb: 3 }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/inicio"
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Inicio
              </Link>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <AdminPanelSettingsOutlinedIcon
                  sx={{ mr: 0.5 }}
                  fontSize="inherit"
                />
                Administrador
              </Typography>
            </Breadcrumbs>
            <Typography variant="h3" sx={{ fontWeight: "bold", mt: 2 }}>
              Administración de usuarios
            </Typography>
          </Box>
          <Container>
            <Box
              gridColumn="span 8"
              gridRow="span 3"
              backgroundColor={theme.palette.background.alt}
              p="1rem"
              borderRadius="1.5rem"
              mt="20px"
              height="fit-content"
              sx={{ boxShadow: 4 }}
            >
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={valueTabs}
                    onChange={handleChangeTabs}
                    variant={isSmallScreen ? "scrollable" : "fullWidth"}
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label="Lista de Usuarios"
                      icon={<HowToRegOutlinedIcon />}
                      iconPosition="start"
                      {...a11yProps(0)}
                      wrapped
                    />
                    <Tab
                      label="Acceso Pendiente"
                      icon={<SafetyCheckOutlinedIcon />}
                      iconPosition="start"
                      {...a11yProps(1)}
                      wrapped
                    />
                    <Tab
                      label="Usuarios Inactivos"
                      icon={<PersonOffOutlinedIcon />}
                      iconPosition="start"
                      {...a11yProps(2)}
                      wrapped
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel value={valueTabs} index={0}>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <Typography variant="h5">
                        <b>Listado de Usuarios</b>
                      </Typography>
                    </Grid>
                  </Grid>
                  <MaterialReactTable
                    columns={columns}
                    data={user}
                    localization={MRT_Localization_ES}
                    enableRowActions
                    /*muiTableBodyRowProps={({ row }) => ({
        //implement row selection click events manually
        onClick: () =>
          setRowSelection((prev) => ({
            ...prev,
            [row.id]: !prev[row.id],
          })),
        selected: rowSelection[row.id],
        sx: {
          cursor: 'pointer',
        },
      })}
      state={{ rowSelection }}*/
                    positionActionsColumn="last"
                    renderTopToolbarCustomActions={({ table }) => (
                      <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                      >
                        <Button
                          color="success"
                          disabled={table.getPrePaginationRowModel().rows.length === 0}
                          //export all rows, including from the next page, (still respects filtering and sorting)
                          onClick={() =>
                            handleExportRows(table.getPrePaginationRowModel().rows)
                          }
                          variant="contained"
                        >
                          <FileDownloadIcon sx={{ mr:1 }}/>Exportar
                        </Button>
                      </Box>
                    )}
                    renderRowActions={({ row }) => (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          gap: "0.5rem",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="success"
                          sx={{ bgcolor: "teal", color: "white" }}
                          onClick={() => {
                            handleOpen();
                            setDatosUsuario(row.original);
                            console.log(row.original)
                            setRol(Number(row.original.rol));
                            setEst(row.original.estado);
                            setVol(Number(row.original.tipo));
                          }}
                        >
                          Opciones
                        </Button>
                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          slotProps={{ backdrop: { style: { opacity: 0.2 } } }}
                        >
                          <Box sx={style}>
                            <Typography
                              id="modal-modal-title"
                              variant="h5"
                              component="h2"
                            >
                              <b>
                                {datosUsuario.nombres} {datosUsuario.apellidos}
                              </b>
                            </Typography>
                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 1 }}
                            >
                              <b> {datosUsuario.descripcion} </b>
                            </Typography>
                            <Divider sx={{ mt: 3 }}>
                              <Chip label="ROL DEL USUARIO" />
                            </Divider>
                            <Box sx={{ minWidth: 120, mt: 3 }}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Roles de Usuario
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="Roles de Usuario"
                                  value={rol}
                                  onChange={handleChangeRol}
                                >
                                  <MenuItem value="">
                                    <em>Seleccione Rol</em>
                                  </MenuItem>
                                  {roles.map((dat, index) => (
                                    <MenuItem value={dat.idroles}>
                                      {dat.descripcion}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                            <Divider sx={{ mt: 3 }}>
                              <Chip label="ESTADO DEL USUARIO" />
                            </Divider>
                            <Box sx={{ minWidth: 120, mt: 3 }}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Estado del Usuario
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="Estado del Usuario"
                                  value={est}
                                  onChange={handleChangeEst}
                                >
                                  <MenuItem value={1}> Activo </MenuItem>
                                  <MenuItem value={0}> Inactivo </MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                            <Divider sx={{ mt: 3 }}>
                              <Chip label="TIPO DE VOLUNTARIO" />
                            </Divider>
                            <Box sx={{ minWidth: 120, mt: 3 }}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Tipo de Voluntario
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="Tipo de Voluntario"
                                  value={vol}
                                  onChange={handleChangeVol}
                                >
                                  <MenuItem value="">
                                    <em>Seleccione Tipo</em>
                                  </MenuItem>

                                  {tipos.map((dat, index) => (
                                    <MenuItem value={dat.idtipo}>
                                      {dat.nombre}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                            <Divider sx={{ mt: 4 }} />
                            <Grid
                              container
                              sx={{ mt: 2 }}
                              spacing={1}
                              justifyContent="flex-end"
                            >
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="error"
                                  onClick={handleClose}
                                >
                                  Cancelar
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  variant="contained"
                                  sx={{ bgcolor: "teal", color: "white" }}
                                  onClick={handleSubmit}
                                >
                                  Aceptar
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </Modal>
                      </div>
                    )}
                    muiTopToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableHeadCellProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiBottomToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableBodyProps={{
                      sx: {
                        "& tr:nth-of-type(odd)": {
                          backgroundColor: theme.palette.background.light,
                        },
                      },
                    }}
                    muiTablePaperProps={{
                      elevation: 0, //change the mui box shadow
                      //customize paper styles
                    }}
                    initialState={{
                      pagination: {
                        pageSize: 20,
                        pageIndex: 0,
                      },
                    }}
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                      showFirstButton: false,
                      showLastButton: false,
                      SelectProps: {
                        native: true,
                      },
                      labelRowsPerPage: "Número de filas visibles",
                    }}
                    renderDetailPanel={({ row }) => (
                      <Box
                        sx={{
                          display: "grid",
                          margin: "auto",
                          gridTemplateColumns: "1fr 1fr",
                          width: "100%",
                        }}
                      >
                        <Typography>
                          <b>Email:</b> {row.original.email}
                        </Typography>
                        <Typography>
                          <b>Ocupacion:</b> {row.original.descripcion}
                        </Typography>
                        <Typography>
                          <b>Tipo:</b> {row.original.nombre}
                        </Typography>
                        <Typography>
                          <b>Rol:</b> {row.original.roles}
                        </Typography>
                      </Box>
                    )}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={valueTabs} index={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <Typography variant="h5">
                        <b>Acceso Pendiente</b>
                      </Typography>
                    </Grid>
                  </Grid>
                  <MaterialReactTable
                    columns={pendientes}
                    data={data[0]}
                    localization={MRT_Localization_ES}
                    enableRowActions
                    positionActionsColumn="last"
                    renderRowActions={({ row }) => (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          gap: "0.5rem",
                        }}
                      >
                        <Tooltip title="Aceptar/Rechazar Usuario">
                          <Button
                            variant="contained"
                            color="success"
                            sx={{ bgcolor: "teal", color: "white" }}
                            onClick={() => {
                              handleOpenAM(row.original);
                            }}
                          >
                            <ManageAccountsIcon />
                          </Button>
                        </Tooltip>
                        <Modal
                          open={openAM}
                          onClose={handleCloseAM}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          slotProps={{ backdrop: { style: { opacity: 0.1 } } }}
                        >
                          <Box sx={style}>
                            <AcceptTab dato={datoVo} onClose={handleCloseAM} />
                          </Box>
                        </Modal>
                      </div>
                    )}
                    muiTopToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableHeadCellProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiBottomToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableBodyProps={{
                      sx: {
                        "& tr:nth-of-type(odd)": {
                          backgroundColor: theme.palette.background.light,
                        },
                      },
                    }}
                    muiTablePaperProps={{
                      elevation: 0, //change the mui box shadow
                      //customize paper styles
                    }}
                    initialState={{
                      pagination: {
                        pageSize: 20,
                        pageIndex: 0,
                      },
                    }}
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                      showFirstButton: false,
                      showLastButton: false,
                      SelectProps: {
                        native: true,
                      },
                      labelRowsPerPage: "Número de filas visibles",
                    }}
                    renderDetailPanel={({ row }) => (
                      <Box
                        sx={{
                          display: "grid",
                          margin: "auto",
                          gridTemplateColumns: "1fr 1fr",
                          width: "100%",
                        }}
                      >
                        <Typography>
                          <b>Cedula:</b> {row.original.cedula}
                        </Typography>
                        <Typography>
                          <b>Telefono:</b> {row.original.telefono}
                        </Typography>
                        <Typography>
                          <b>Email:</b> {row.original.email}
                        </Typography>
                        <Typography>
                          <b>Ocupacion:</b> {row.original.descripcion}
                        </Typography>
                      </Box>
                    )}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={valueTabs} index={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <Typography variant="h5">
                        <b>Listado de Usuarios Inactivos</b>
                      </Typography>
                    </Grid>
                  </Grid>
                  <MaterialReactTable
                    columns={columns}
                    data={userInactivo}
                    localization={MRT_Localization_ES}
                    enableRowActions
                    positionActionsColumn="last"
                    renderRowActions={({ row }) => (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          gap: "0.5rem",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="success"
                          sx={{ bgcolor: "teal", color: "white" }}
                          onClick={() => {
                            handleOpen();
                            setDatosUsuario(row.original);
                            setRol(row.original.roles);
                            setEst(row.original.estados);
                            setVol(row.original.nombre);
                          }}
                        >
                          Opciones
                        </Button>
                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          slotProps={{ backdrop: { style: { opacity: 0.2 } } }}
                        >
                          <Box sx={style}>
                            <Typography
                              id="modal-modal-title"
                              variant="h5"
                              component="h2"
                            >
                              <b>
                                {datosUsuario.nombres} {datosUsuario.apellidos}
                              </b>
                            </Typography>
                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 1 }}
                            >
                              <b> {datosUsuario.descripcion} </b>
                            </Typography>
                            <Divider sx={{ mt: 3 }}>
                              <Chip label="ROL DEL USUARIO" />
                            </Divider>
                            <Box sx={{ minWidth: 120, mt: 3 }}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Roles de Usuario
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="Roles de Usuario"
                                  value={rol}
                                  onChange={handleChangeRol}
                                >
                                  <MenuItem value="">
                                    <em>Seleccione Rol</em>
                                  </MenuItem>
                                  {roles.map((dat, index) => (
                                    <MenuItem value={dat.idroles}>
                                      {dat.descripcion}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                            <Divider sx={{ mt: 3 }}>
                              <Chip label="ESTADO DEL USUARIO" />
                            </Divider>
                            <Box sx={{ minWidth: 120, mt: 3 }}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Estado del Usuario
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="Estado del Usuario"
                                  value={est}
                                  onChange={handleChangeEst}
                                >
                                  <MenuItem value={1}> Activo </MenuItem>
                                  <MenuItem value={0}> Inactivo </MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                            <Divider sx={{ mt: 3 }}>
                              <Chip label="TIPO DE VOLUNTARIO" />
                            </Divider>
                            <Box sx={{ minWidth: 120, mt: 3 }}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Tipo de Voluntario
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="Tipo de Voluntario"
                                  value={vol}
                                  onChange={handleChangeVol}
                                >
                                  <MenuItem value="">
                                    <em>Seleccione Tipo</em>
                                  </MenuItem>

                                  {tipos.map((dat, index) => (
                                    <MenuItem value={dat.idtipo}>
                                      {dat.nombre}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                            <Divider sx={{ mt: 4 }} />
                            <Grid
                              container
                              sx={{ mt: 2 }}
                              spacing={1}
                              justifyContent="flex-end"
                            >
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="error"
                                  onClick={handleClose}
                                >
                                  Cancelar
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  variant="contained"
                                  sx={{ bgcolor: "teal", color: "white" }}
                                  onClick={handleSubmit}
                                >
                                  Aceptar
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </Modal>
                      </div>
                    )}
                    muiTopToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableHeadCellProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiBottomToolbarProps={{
                      sx: {
                        backgroundColor: theme.palette.background.alt,
                      },
                    }}
                    muiTableBodyProps={{
                      sx: {
                        "& tr:nth-of-type(odd)": {
                          backgroundColor: theme.palette.background.light,
                        },
                      },
                    }}
                    muiTablePaperProps={{
                      elevation: 0, //change the mui box shadow
                      //customize paper styles
                    }}
                    initialState={{
                      pagination: {
                        pageSize: 20,
                        pageIndex: 0,
                      },
                    }}
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                      showFirstButton: false,
                      showLastButton: false,
                      SelectProps: {
                        native: true,
                      },
                      labelRowsPerPage: "Número de filas visibles",
                    }}
                    renderDetailPanel={({ row }) => (
                      <Box
                        sx={{
                          display: "grid",
                          margin: "auto",
                          gridTemplateColumns: "1fr 1fr",
                          width: "100%",
                        }}
                      >
                        <Typography>
                          <b>Email:</b> {row.original.email}
                        </Typography>
                        <Typography>
                          <b>Ocupacion:</b> {row.original.descripcion}
                        </Typography>
                        <Typography>
                          <b>Tipo:</b> {row.original.nombre}
                        </Typography>
                        <Typography>
                          <b>Rol:</b> {row.original.roles}
                        </Typography>
                      </Box>
                    )}
                  />
                </CustomTabPanel>
              </Box>
            </Box>
          </Container>
        </>
      ) : (
        <Restricted />
      )}
    </Box>
  );
};

export default Admin;
