import React, { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import InventoryOutlined from "@mui/icons-material/InventoryOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Restricted from "components/Restricted";
import clienteAxios from "../../config/clienteAxios";
import axios from "axios";

const Suministros = () => {
  const nav = useNavigate();
  const [data, setData] = React.useState([]);
  const [ejecutar, setEjecutar] = React.useState(true);

  const [items, setItems] = React.useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const rol = items.rol;

  //Theme
  const theme = useTheme();

  const [nombreEquipo, setNombreEquipo] = useState("");
  const [descEquipo, setDescEquipo] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 5,
    p: 4,
  };

  function handleClickBreadCrumbs(event) {
    event.preventDefault();
  }

  //Modal Handlers
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [openAlert, setOpenAlert] = React.useState(false);
  const handleOpenAlert = () => {
    setOpenAlert(true);
    handleCloseAlert();
    handleClose();
  };
  const handleCloseAlert = () =>
    setTimeout(() => {
      setOpenAlert(false);
    }, 2000);

  const [openAlertEdit, setOpenAlertEdit] = React.useState(false);
  const handleOpenAlertEdit = () => {
    setOpenAlertEdit(true);
    handleCloseAlertEdit();
    handleCloseEdit();
  };
  const handleCloseAlertEdit = () =>
    setTimeout(() => {
      setOpenAlertEdit(false);
    }, 2000);

  //Routes
  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };

  const handleLinkConv = (event, message, row) => {
    let pro = JSON.stringify(row.original);
    if (message === "convos") {
      nav(`/listaConvocatorias?prop=${pro}`);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "nombre",
        header: "Nombre",
      },
      {
        accessorKey: "descripcion",
        header: "Descripcion",
      },
      {
        accessorKey: "estados",
        header: "Estado",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={{
              backgroundColor:
                cell.getValue() === "Activo" ? "green" : "firebrick",
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "12ch",
              p: "0.25rem",
            }}
          >
            {cell.getValue()}
          </Box>
        ),
      },
    ],
    []
  );

  const proyectos = async () => {
    try {
      const { data1 } = await axios
        .get("/proyectos")
        .then(function (response) {
          // setAlerta({})

          setData(response.data);
          setEjecutar(false);
          console.log(data);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  if (ejecutar) {
    proyectos();
  }
  if (!data)
    return (
      <Container component="main" maxWidth="xs">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  return (
    <Box m="1.5rem 2.5rem">
      {rol === "1" || rol === "2" || rol === "5" || rol === "7" ? (
        <>
          <Box
            role="presentation"
            onClick={handleClickBreadCrumbs}
            sx={{ mb: 3 }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/inicio"
                onClick={(event) => handleLinkHome(event, "home")}
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Inicio
              </Link>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <InventoryOutlined sx={{ mr: 0.5 }} fontSize="inherit" />
                Suministros
              </Typography>
            </Breadcrumbs>
            <Typography variant="h3" sx={{ fontWeight: "bold", mt: 2 }}>
              Suministros
            </Typography>
          </Box>
          <Container>
            <Box
              gridColumn="span 8"
              gridRow="span 3"
              backgroundColor={theme.palette.background.alt}
              p="1.5rem"
              borderRadius="1.5rem"
              mt="40px"
              height="fit-content"
              sx={{ boxShadow: 4 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    Listado de Proyectos
                  </Typography>
                </Grid>
              </Grid>

              <MaterialReactTable
                columns={columns}
                data={data}
                localization={MRT_Localization_ES}
                enableRowActions
                positionActionsColumn="last"
                displayColumnDefOptions={{
                  "mrt-row-actions": {
                    header: "Opciones",
                    size: 10,
                  },
                }}
                renderRowActions={({ row }) => (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      gap: "0.5rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="success"
                      sx={{ bgcolor: "teal", color: "white" }}
                      onClick={(event) => handleLinkConv(event, "convos", row)}
                    >
                      Ver Convocatorias
                    </Button>
                  </div>
                )}
                muiTopToolbarProps={{
                  sx: {
                    backgroundColor: theme.palette.background.alt,
                  },
                }}
                muiTableHeadCellProps={{
                  align: "center",
                  sx: {
                    backgroundColor: theme.palette.background.alt,
                  },
                }}
                muiBottomToolbarProps={{
                  sx: {
                    backgroundColor: theme.palette.background.alt,
                  },
                }}
                muiTableBodyProps={{
                  sx: {
                    "& tr:nth-of-type(odd)": {
                      backgroundColor: theme.palette.background.light,
                    },
                  },
                }}
                muiTableBodyCellProps={{
                  align: "center",
                }}
                muiTablePaperProps={{
                  elevation: 0, //change the mui box shadow
                  //customize paper styles
                }}
                initialState={{
                  pagination: {
                    pageSize: 5,
                    pageIndex: 0,
                  },
                }}
                muiTablePaginationProps={{
                  rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                  showFirstButton: false,
                  showLastButton: false,
                  SelectProps: {
                    native: true,
                  },
                  labelRowsPerPage: "Número de filas visibles",
                }}

                /*renderDetailPanel={({ row }) => (
    <Box
      sx={{
        display: 'grid',
        margin: 'auto',
        gridTemplateColumns: '1fr 1fr',
        width: '100%',
      }}
    >
      <Typography>Address: {row.original.address}</Typography>
      <Typography>City: {row.original.city}</Typography>
      <Typography>State: {row.original.state}</Typography>
      <Typography>Country: {row.original.country}</Typography>
    </Box>
    )} */
              />
            </Box>
          </Container>
        </>
      ) : (
        <Restricted />
      )}
    </Box>
  );
};

export default Suministros;
