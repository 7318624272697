import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme, useMediaQuery } from "@mui/material";
import { mockData } from "./mockData";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/SearchRounded";
import icon from "assets/paluz-icon-2.png";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Result from "./Result";
import useSearchIndex from "./useSearchIndex";
import clienteAxios from "../../config/clienteAxios";
import { useGetSemanasQuery } from "state/api";
import axios from "axios";

const miniSearchOptions = {
  fields: ["cedula", "nombre_rep", "nombre", "apellido"],
  storeFields: [
    "estado",
    "municipio",
    "parroquia",
    "centro",
    "comunidad",
    "grupo_etnico",
    "tipo_ingreso",
    "nombre_rep",
    "apellido_rep",
    "telefono_rep",
    "cedula",
    "nombre",
    "apellido",
    "sexo",
    "fecha_nacimiento",
    "fecha_evaluacion",
    "edad",
    "lactancia",
    "veces",
    "biberon",
    "otros_alimentos",
    "agua_hervida",
    "agua_filtrada",
    "agua_sintratar",
    "gripe_tos",
    "fiebre",
    "diarrea",
    "vomito",
    "paludismo_malaria",
    "discapacidad",
    "edema",
    "circunferencia",
    "consejeria",
    "albendazol",
    "micronutrientes",
    "lns_mq",
    "ruft",
    "dosis",
    "resultados_seguimiento",
    "observaciones",
    "clasificacion_pb",
    "tipo",
  ],
  searchOptions: {
    boost: { cedula: 2, nombre: 1 },
    prefix: false,
    fuzzy: 0,
  },
};

export default function SearchPage() {
  const nav = useNavigate();

  //Theme
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [data, setData] = React.useState([]);
  const [ejecutar, setEjecutar] = React.useState(true);
  const [searchValue, setSearchValue] = React.useState("");
  const [inputValue, setInputValue] = useState("");

  //Alerts Handlers
  const [openNotiError, setOpenNotiError] = React.useState(false);
  const [openNotiWarning, setOpenNotiWarning] = React.useState(false);

  //Routes
  const handleLinkResult = (data) => {
    let prop = JSON.stringify(data);

    nav(`/resultadosBusqueda?prop=${prop}`);
  };

  const voluntariosO = async () => {
    console.log(searchValue);
    const words = searchValue.split(" ");

    // Agregar el símbolo "+" al inicio de cada palabra y unirlas en un nuevo string
    const formattedString = words.map((word) => `+${word}`).join(" ");

    console.log(formattedString.slice(1));
    try {
      const { data1 } = await axios
        .get("/busqueda/", {
          params: {
            word: formattedString,
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setData(response.data);
          if(response.data.length > 1000) {
            setOpenNotiWarning(true);
          setTimeout(() => {
            setOpenNotiWarning(false);
          }, 3000);
          } else {
            handleLinkResult(response.data);
          }
          return;
        })
        .catch(function (error) {
          // Alerta de Error //
          setOpenNotiError(true);
          setTimeout(() => {
            setOpenNotiError(false);
          }, 3000);
          console.log(error);
          return;
        });
    } catch (error) {
      return;
    }
  };

  // const { results, search, searchIndex } = useSearchIndex(
  //   data,
  //   miniSearchOptions,
  //   {}
  // );

  return (
    <>
      <div className="flex flex-col items-center justify-start p-8 max-w-[1200px] mx-auto min-h-full">
        <Grid container justifyContent="center" mt={1}>
          <Grid item xs={7} sm={2}>
            <Box
              component="img"
              alt="logo"
              src={icon}
              height="150px"
              width="150px"
            ></Box>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" mt={3}>
          <Grid item xs={12} sm={8}>
            <Typography className="text-center">
              Ingrese la cédula de identidad o nombre del beneficiario{" "}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" mt={2} spacing={2}>
          <Grid item xs={12} sm={8}>
            {/*<TextField
            type="search"
            value={inputValue}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ bgcolor: theme.palette.background.alt }}
            onChange={(e) => {
              search(e.target.value);
              setInputValue(e.target.value);
            }}
          />*/}
            <TextField
              type="search"
              value={searchValue}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                sx: { borderRadius: 10, bgcolor: theme.palette.background.alt },
              }}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" mt={isSmallScreen ? 4 : 3}>
          <Grid item xs={6} sm={2}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              sx={{ borderRadius: 4, bgcolor: "teal" }}
              onClick={voluntariosO}
            >
              <b>Buscar</b>
            </Button>
          </Grid>
        </Grid>
        <Collapse in={openNotiError}>
          <Alert
            variant="filled"
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenNotiError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{
              mt:5,
              right: 12,
              zIndex: 999,
              borderRadius: 2,
              boxShadow: 4,
            }}
          >
            No se encontró el beneficiario.
          </Alert>
        </Collapse>
        <Collapse in={openNotiWarning}>
          <Alert
            variant="filled"
            severity="warning"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenNotiWarning(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{
              mt:5,
              right: 12,
              zIndex: 999,
              borderRadius: 2,
              boxShadow: 4,
            }}
          >
           Su búsqueda arroja muchos resultados. Por favor, sea más específico.
          </Alert>
        </Collapse>
        {/*<Stack direction={{ xs: "column", sm: "column" }}>
        {results.length
          ? results.map((result) => <Result key={result.id} result={result} />)
          : ""}
      </Stack>*/}
      </div>
    </>
  );
}
