import React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Drawer,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  AdminPanelSettingsOutlined,
  VolunteerActivismOutlined,
  InventoryOutlined,
  DepartureBoardOutlined,
  GroupsOutlined,
  CasesOutlined,
} from "@mui/icons-material";
import FamilyRestroomOutlinedIcon from "@mui/icons-material/FamilyRestroomOutlined";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import logo from "assets/paluz-logo.png";
import icon from "assets/paluz-icon-2.png";

const navItems = [
  {
    text: "Inicio",
    icon: <HomeOutlined />,
  },
  {
    text: "MÓDULOS",
    icon: null,
  },
  {
    text: "Proyectos",
    icon: <CasesOutlined />,
  },
  {
    text: "Voluntariado",
    icon: <VolunteerActivismOutlined />,
  },
  {
    text: "Beneficiarios",
    icon: <FamilyRestroomOutlinedIcon />,
  },
  {
    text: "Logística",
    icon: <DepartureBoardOutlined />,
  },
  {
    text: "Suministros",
    icon: <InventoryOutlined />,
  },
  {
    text: "Gestión Humana",
    icon: <GroupsOutlined />,
  },
  {
    text: "Transcripción",
    icon: <DriveFileRenameOutlineIcon />,
  },
  {
    text: "Admin",
    icon: <AdminPanelSettingsOutlined />,
  },
];

const navItemsCoord = [
  {
    text: "Inicio",
    icon: <HomeOutlined />,
  },
  {
    text: "MÓDULOS",
    icon: null,
  },
  {
    text: "Proyectos",
    icon: <CasesOutlined />,
  },
  {
    text: "Voluntariado",
    icon: <VolunteerActivismOutlined />,
  },
  {
    text: "Beneficiarios",
    icon: <FamilyRestroomOutlinedIcon />,
  },
];

const navItemsLog = [
  {
    text: "Inicio",
    icon: <HomeOutlined />,
  },
  {
    text: "MÓDULOS",
    icon: null,
  },
  {
    text: "Voluntariado",
    icon: <VolunteerActivismOutlined />,
  },
  {
    text: "Logística",
    icon: <DepartureBoardOutlined />,
  },
];

const navItemsVol = [
  {
    text: "Inicio",
    icon: <HomeOutlined />,
  },
  {
    text: "MÓDULOS",
    icon: null,
  },
  {
    text: "Voluntariado",
    icon: <VolunteerActivismOutlined />,
  },
];

const navItemsSum = [
  {
    text: "Inicio",
    icon: <HomeOutlined />,
  },
  {
    text: "MÓDULOS",
    icon: null,
  },
  {
    text: "Voluntariado",
    icon: <VolunteerActivismOutlined />,
  },
  {
    text: "Suministros",
    icon: <InventoryOutlined />,
  },
];

const navItemsProy = [
  {
    text: "Inicio",
    icon: <HomeOutlined />,
  },
  {
    text: "MÓDULOS",
    icon: null,
  },
  {
    text: "Proyectos",
    icon: <CasesOutlined />,
  },
  {
    text: "Voluntariado",
    icon: <VolunteerActivismOutlined />,
  },
  {
    text: "Beneficiarios",
    icon: <FamilyRestroomOutlinedIcon />,
  },
];

const navItemsHum = [
  {
    text: "Inicio",
    icon: <HomeOutlined />,
  },
  {
    text: "MÓDULOS",
    icon: null,
  },
  {
    text: "Proyectos",
    icon: <CasesOutlined />,
  },
  {
    text: "Voluntariado",
    icon: <VolunteerActivismOutlined />,
  },
  {
    text: "Beneficiarios",
    icon: <FamilyRestroomOutlinedIcon />,
  },
  {
    text: "Logística",
    icon: <DepartureBoardOutlined />,
  },
  {
    text: "Suministros",
    icon: <InventoryOutlined />,
  },
  {
    text: "Gestión Humana",
    icon: <GroupsOutlined />,
  },
];
const navItemsTerreno = [
  {
    text: "Inicio",
    icon: <HomeOutlined />,
  },
  {
    text: "MÓDULOS",
    icon: null,
  },
  {
    text: "Voluntariado",
    icon: <VolunteerActivismOutlined />,
  },
  {
    text: "Beneficiarios",
    icon: <FamilyRestroomOutlinedIcon />,
  },
  {
    text: "Transcripción",
    icon: <DriveFileRenameOutlineIcon />,
  },
];
const navItemsTrans = [
  {
    text: "Inicio",
    icon: <HomeOutlined />,
  },
  {
    text: "MÓDULOS",
    icon: null,
  },
  {
    text: "Voluntariado",
    icon: <VolunteerActivismOutlined />,
  },
  {
    text: "Transcripción",
    icon: <DriveFileRenameOutlineIcon />,
  },
];

const navItemsPrueba = [
  {
    text: "Inicio",
    icon: <HomeOutlined />,
  },
  {
    text: "MÓDULOS",
    icon: null,
  },
  {
    text: "Voluntariado",
    icon: <VolunteerActivismOutlined />,
  },
];

const Sidebar = ({
  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();

  const handleDrawerToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [items, setItems] = React.useState([]);

  const [nav, setNav] = React.useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  //Sidebar Contents
  var drawerContent;
  const rol = items.rol;

  if (rol == 1) {
    drawerContent = (
      <List>
        {navItems.map(({ text, icon }) => {
          if (!icon) {
            return (
              <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                {text}
              </Typography>
            );
          }
          const lcText = text
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          const textNorm = lcText.replace(/\s+/g, "");

          return (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/${textNorm}`);
                  setActive(textNorm);
                }}
                sx={{
                  backgroundColor:
                    active === textNorm
                      ? theme.palette.secondary[600]
                      : "transparent",
                  color:
                    active === textNorm
                      ? theme.palette.primary[200]
                      : theme.palette.secondary[300],
                }}
              >
                <ListItemIcon
                  sx={{
                    ml: "2rem",
                    color:
                      active === textNorm
                        ? theme.palette.primary[200]
                        : theme.palette.secondary[300],
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText primary={text} />
                {active === textNorm && (
                  <ChevronRightOutlined sx={{ ml: "auto" }} />
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  }
  if (rol == 2) {
    drawerContent = (
      <List>
        {navItems.map(({ text, icon }) => {
          if (!icon) {
            return (
              <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                {text}
              </Typography>
            );
          }
          const lcText = text
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          const textNorm = lcText.replace(/\s+/g, "");

          return (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/${textNorm}`);
                  setActive(textNorm);
                }}
                sx={{
                  backgroundColor:
                    active === textNorm
                      ? theme.palette.secondary[600]
                      : "transparent",
                  color:
                    active === textNorm
                      ? theme.palette.primary[200]
                      : theme.palette.secondary[300],
                }}
              >
                <ListItemIcon
                  sx={{
                    ml: "2rem",
                    color:
                      active === textNorm
                        ? theme.palette.primary[200]
                        : theme.palette.secondary[300],
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText primary={text} />
                {active === textNorm && (
                  <ChevronRightOutlined sx={{ ml: "auto" }} />
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  }
  if (rol == 3) {
    drawerContent = (
      <List>
        {navItemsVol.map(({ text, icon }) => {
          if (!icon) {
            return (
              <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                {text}
              </Typography>
            );
          }
          const lcText = text
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          const textNorm = lcText.replace(/\s+/g, "");

          return (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/${textNorm}`);
                  setActive(textNorm);
                }}
                sx={{
                  backgroundColor:
                    active === textNorm
                      ? theme.palette.secondary[600]
                      : "transparent",
                  color:
                    active === textNorm
                      ? theme.palette.primary[200]
                      : theme.palette.secondary[300],
                }}
              >
                <ListItemIcon
                  sx={{
                    ml: "2rem",
                    color:
                      active === textNorm
                        ? theme.palette.primary[200]
                        : theme.palette.secondary[300],
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText primary={text} />
                {active === textNorm && (
                  <ChevronRightOutlined sx={{ ml: "auto" }} />
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  }
  if (rol == 4) {
    drawerContent = (
      <List>
        {navItemsLog.map(({ text, icon }) => {
          if (!icon) {
            return (
              <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                {text}
              </Typography>
            );
          }
          const lcText = text
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          const textNorm = lcText.replace(/\s+/g, "");

          return (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/${textNorm}`);
                  setActive(textNorm);
                }}
                sx={{
                  backgroundColor:
                    active === textNorm
                      ? theme.palette.secondary[600]
                      : "transparent",
                  color:
                    active === textNorm
                      ? theme.palette.primary[200]
                      : theme.palette.secondary[300],
                }}
              >
                <ListItemIcon
                  sx={{
                    ml: "2rem",
                    color:
                      active === textNorm
                        ? theme.palette.primary[200]
                        : theme.palette.secondary[300],
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText primary={text} />
                {active === textNorm && (
                  <ChevronRightOutlined sx={{ ml: "auto" }} />
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  }
  if (rol == 5) {
    drawerContent = (
      <List>
        {navItemsSum.map(({ text, icon }) => {
          if (!icon) {
            return (
              <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                {text}
              </Typography>
            );
          }
          const lcText = text
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          const textNorm = lcText.replace(/\s+/g, "");

          return (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/${textNorm}`);
                  setActive(textNorm);
                }}
                sx={{
                  backgroundColor:
                    active === textNorm
                      ? theme.palette.secondary[600]
                      : "transparent",
                  color:
                    active === textNorm
                      ? theme.palette.primary[200]
                      : theme.palette.secondary[300],
                }}
              >
                <ListItemIcon
                  sx={{
                    ml: "2rem",
                    color:
                      active === textNorm
                        ? theme.palette.primary[200]
                        : theme.palette.secondary[300],
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText primary={text} />
                {active === textNorm && (
                  <ChevronRightOutlined sx={{ ml: "auto" }} />
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  }
  if (rol == 6) {
    drawerContent = (
      <List>
        {navItemsProy.map(({ text, icon }) => {
          if (!icon) {
            return (
              <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                {text}
              </Typography>
            );
          }
          const lcText = text
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          const textNorm = lcText.replace(/\s+/g, "");

          return (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/${textNorm}`);
                  setActive(textNorm);
                }}
                sx={{
                  backgroundColor:
                    active === textNorm
                      ? theme.palette.secondary[600]
                      : "transparent",
                  color:
                    active === textNorm
                      ? theme.palette.primary[200]
                      : theme.palette.secondary[300],
                }}
              >
                <ListItemIcon
                  sx={{
                    ml: "2rem",
                    color:
                      active === textNorm
                        ? theme.palette.primary[200]
                        : theme.palette.secondary[300],
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText primary={text} />
                {active === textNorm && (
                  <ChevronRightOutlined sx={{ ml: "auto" }} />
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  }
  if (rol == 7) {
    drawerContent = (
      <List>
        {navItemsHum.map(({ text, icon }) => {
          if (!icon) {
            return (
              <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                {text}
              </Typography>
            );
          }
          const lcText = text
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          const textNorm = lcText.replace(/\s+/g, "");

          return (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/${textNorm}`);
                  setActive(textNorm);
                }}
                sx={{
                  backgroundColor:
                    active === textNorm
                      ? theme.palette.secondary[600]
                      : "transparent",
                  color:
                    active === textNorm
                      ? theme.palette.primary[200]
                      : theme.palette.secondary[300],
                }}
              >
                <ListItemIcon
                  sx={{
                    ml: "2rem",
                    color:
                      active === textNorm
                        ? theme.palette.primary[200]
                        : theme.palette.secondary[300],
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText primary={text} />
                {active === textNorm && (
                  <ChevronRightOutlined sx={{ ml: "auto" }} />
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  }
  if (rol == 8) {
    drawerContent = (
      <List>
        {navItemsTerreno.map(({ text, icon }) => {
          if (!icon) {
            return (
              <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                {text}
              </Typography>
            );
          }
          const lcText = text
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          const textNorm = lcText.replace(/\s+/g, "");

          return (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/${textNorm}`);
                  setActive(textNorm);
                }}
                sx={{
                  backgroundColor:
                    active === textNorm
                      ? theme.palette.secondary[600]
                      : "transparent",
                  color:
                    active === textNorm
                      ? theme.palette.primary[200]
                      : theme.palette.secondary[300],
                }}
              >
                <ListItemIcon
                  sx={{
                    ml: "2rem",
                    color:
                      active === textNorm
                        ? theme.palette.primary[200]
                        : theme.palette.secondary[300],
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText primary={text} />
                {active === textNorm && (
                  <ChevronRightOutlined sx={{ ml: "auto" }} />
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  }
  if (rol == 9) {
    drawerContent = (
      <List>
        {navItemsTerreno.map(({ text, icon }) => {
          if (!icon) {
            return (
              <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                {text}
              </Typography>
            );
          }
          const lcText = text
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          const textNorm = lcText.replace(/\s+/g, "");

          return (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/${textNorm}`);
                  setActive(textNorm);
                }}
                sx={{
                  backgroundColor:
                    active === textNorm
                      ? theme.palette.secondary[600]
                      : "transparent",
                  color:
                    active === textNorm
                      ? theme.palette.primary[200]
                      : theme.palette.secondary[300],
                }}
              >
                <ListItemIcon
                  sx={{
                    ml: "2rem",
                    color:
                      active === textNorm
                        ? theme.palette.primary[200]
                        : theme.palette.secondary[300],
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText primary={text} />
                {active === textNorm && (
                  <ChevronRightOutlined sx={{ ml: "auto" }} />
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  }
  if (rol == 10) {
    drawerContent = (
      <List>
        {navItemsProy.map(({ text, icon }) => {
          if (!icon) {
            return (
              <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                {text}
              </Typography>
            );
          }
          const lcText = text
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          const textNorm = lcText.replace(/\s+/g, "");

          return (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/${textNorm}`);
                  setActive(textNorm);
                }}
                sx={{
                  backgroundColor:
                    active === textNorm
                      ? theme.palette.secondary[600]
                      : "transparent",
                  color:
                    active === textNorm
                      ? theme.palette.primary[200]
                      : theme.palette.secondary[300],
                }}
              >
                <ListItemIcon
                  sx={{
                    ml: "2rem",
                    color:
                      active === textNorm
                        ? theme.palette.primary[200]
                        : theme.palette.secondary[300],
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText primary={text} />
                {active === textNorm && (
                  <ChevronRightOutlined sx={{ ml: "auto" }} />
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  }
  if (rol == 11) {
    drawerContent = (
      <List>
        {navItemsTrans.map(({ text, icon }) => {
          if (!icon) {
            return (
              <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                {text}
              </Typography>
            );
          }
          const lcText = text
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          const textNorm = lcText.replace(/\s+/g, "");

          return (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/${textNorm}`);
                  setActive(textNorm);
                }}
                sx={{
                  backgroundColor:
                    active === textNorm
                      ? theme.palette.secondary[600]
                      : "transparent",
                  color:
                    active === textNorm
                      ? theme.palette.primary[200]
                      : theme.palette.secondary[300],
                }}
              >
                <ListItemIcon
                  sx={{
                    ml: "2rem",
                    color:
                      active === textNorm
                        ? theme.palette.primary[200]
                        : theme.palette.secondary[300],
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText primary={text} />
                {active === textNorm && (
                  <ChevronRightOutlined sx={{ ml: "auto" }} />
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  }
  if (rol == 12) {
    drawerContent = (
      <List>
        {navItemsTrans.map(({ text, icon }) => {
          if (!icon) {
            return (
              <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                {text}
              </Typography>
            );
          }
          const lcText = text
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          const textNorm = lcText.replace(/\s+/g, "");

          return (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/${textNorm}`);
                  setActive(textNorm);
                }}
                sx={{
                  backgroundColor:
                    active === textNorm
                      ? theme.palette.secondary[600]
                      : "transparent",
                  color:
                    active === textNorm
                      ? theme.palette.primary[200]
                      : theme.palette.secondary[300],
                }}
              >
                <ListItemIcon
                  sx={{
                    ml: "2rem",
                    color:
                      active === textNorm
                        ? theme.palette.primary[200]
                        : theme.palette.secondary[300],
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText primary={text} />
                {active === textNorm && (
                  <ChevronRightOutlined sx={{ ml: "auto" }} />
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  }

  // if (rol > 2) {
  //    drawerContent = (
  //     <List>
  //       {navItemsVol.map(({ text, icon }) => {
  //         if (!icon) {
  //           return (
  //             <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
  //               {text}
  //             </Typography>
  //           );
  //           }
  //            const lcText = text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  //            const textNorm= lcText.replace(/\s+/g, '');

  //            return (
  //             <ListItem key={text} disablePadding>
  //               <ListItemButton
  //                 onClick={() => {
  //                 navigate(`/${textNorm}`);
  //                 setActive(textNorm);
  //               }}
  //               sx={{
  //               backgroundColor:
  //               active === textNorm
  //               ? theme.palette.secondary[600]
  //               : "transparent",
  //               color:
  //               active === textNorm
  //               ? theme.palette.primary[200]
  //               : theme.palette.secondary[300],
  //               }}
  //               >
  //               <ListItemIcon
  //                 sx={{
  //                   ml: "2rem",
  //                   color:
  //                   active === textNorm
  //                   ? theme.palette.primary[600]
  //                   : theme.palette.secondary[200],
  //                   }}
  //                   >
  //                     {icon}
  //                 </ListItemIcon>
  //                 <ListItemText primary={text} />
  //                 {active === textNorm && (
  //                 <ChevronRightOutlined sx={{ ml: "auto" }} />
  //                 )}
  //                 </ListItemButton>
  //                 </ListItem>
  //              );
  //             })}
  //       </List>
  //   )
  // }else{
  //    drawerContent = (
  //     <List>
  //       {navItems.map(({ text, icon }) => {
  //         if (!icon) {
  //           return (
  //             <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
  //               {text}
  //             </Typography>
  //           );
  //           }
  //            const lcText = text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  //            const textNorm= lcText.replace(/\s+/g, '');

  //            return (
  //             <ListItem key={text} disablePadding>
  //               <ListItemButton
  //                 onClick={() => {
  //                 navigate(`/${textNorm}`);
  //                 setActive(textNorm);
  //               }}
  //               sx={{
  //               backgroundColor:
  //               active === textNorm
  //               ? theme.palette.secondary[600]
  //               : "transparent",
  //               color:
  //               active === textNorm
  //               ? theme.palette.primary[200]
  //               : theme.palette.secondary[300],
  //               }}
  //               >
  //               <ListItemIcon
  //                 sx={{
  //                   ml: "2rem",
  //                   color:
  //                   active === textNorm
  //                   ? theme.palette.primary[600]
  //                   : theme.palette.secondary[200],
  //                   }}
  //                   >
  //                     {icon}
  //                 </ListItemIcon>
  //                 <ListItemText primary={text} />
  //                 {active === textNorm && (
  //                 <ChevronRightOutlined sx={{ ml: "auto" }} />
  //                 )}
  //                 </ListItemButton>
  //                 </ListItem>
  //              );
  //             })}
  //       </List>
  //   )
  // }

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  return (
    <Box component="nav" sx={{ boxShadow: 10 }}>
      {isSidebarOpen && (
        /* Desktop Sidebar */
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            display: { xs: "none", sm: "block" },
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[100],
              backgroundColor: theme.palette.background.alt,
              boxSixing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box
            width="100%"
            className="scroll"
            mb="80px"
            position="relative"
            sx={{ backgroundColor: theme.palette.background.alt }}
          >
            <Box marginLeft="30px" marginTop="5px" marginBottom="5px">
              <Box
                display="flex"
                component="img"
                alt="logo"
                src={logo}
                height="50px"
                width="180px"
              />
            </Box>
            {drawerContent}
          </Box>
          <Box
            position="absolute"
            mb={3}
            bottom="0px"
            sx={{ backgroundColor: theme.palette.background.alt }}
          >
            <Divider />
            <FlexBetween textTransform="none" gap="1rem" m="1.5rem 2rem 0 2rem">
              <Box
                component="img"
                alt="profile"
                src={icon}
                height="45px"
                width="45px"
                borderRadius="50%"
                sx={{ objectFit: "cover" }}
              />
              <Box textAlign="left">
                <Typography
                  fontWeight="bold"
                  fontSize="0.9rem"
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {items.nombre} {items.apellido}
                </Typography>
                <Typography
                  fontSize="0.8rem"
                  sx={{ color: theme.palette.secondary[200] }}
                >
                  {items.ocupacion}
                </Typography>
                <Typography
                  fontSize="0.65rem"
                  mt={0.2}
                  sx={{ color: theme.palette.secondary[200] }}
                >
                  {items.nombreRol}
                </Typography>
              </Box>
            </FlexBetween>
          </Box>
        </Drawer>
      )}

      {/* Mobile Sidebar */}
      <Drawer
        variant="temporary"
        open={!isSidebarOpen}
        onClose={handleDrawerToggle}
        anchor="left"
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          width: drawerWidth,
          "& .MuiDrawer-paper": {
            color: theme.palette.secondary[100],
            backgroundColor: theme.palette.background.alt,
            boxSixing: "border-box",
            borderWidth: isNonMobile ? 0 : "2px",
            width: drawerWidth,
          },
        }}
      >
        <Box width="100%" position="relative">
          <Box marginLeft="30px" marginTop="5px" marginBottom="5px">
            <FlexBetween color={theme.palette.secondary.main}>
              <Box
                display="flex"
                component="img"
                alt="logo"
                src={logo}
                height="50px"
                width="180px"
              />
              {!isNonMobile && (
                <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                  <ChevronLeft />
                </IconButton>
              )}
            </FlexBetween>
          </Box>
          {drawerContent}
        </Box>
        <Box position="absolute" bottom="0" mb={3}>
          <Divider sx={{ width: "100%" }} />
          <FlexBetween textTransform="none" gap="1rem" m="1.5rem 2rem 0 2rem">
            <Box
              component="img"
              alt="profile"
              src={icon}
              height="40px"
              width="40px"
              borderRadius="50%"
              sx={{ objectFit: "cover" }}
            />
            <Box textAlign="left">
              <Typography
                fontWeight="bold"
                fontSize="0.9rem"
                sx={{ color: theme.palette.secondary[100] }}
              >
                {items.nombre} {items.apellido}
              </Typography>
              <Typography
                fontSize="0.8rem"
                sx={{ color: theme.palette.secondary[200] }}
              >
                {items.ocupacion}
              </Typography>
              <Typography
                fontSize="0.7rem"
                sx={{ color: theme.palette.secondary[200] }}
              >
                {items.nombreRol}
              </Typography>
            </Box>
          </FlexBetween>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
