import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import HomeIcon from "@mui/icons-material/Home";
import DepartureBoardOutlined from "@mui/icons-material/DepartureBoardOutlined";
import ConstructionIcon from "@mui/icons-material/Construction";
import FamilyRestroomOutlinedIcon from "@mui/icons-material/FamilyRestroomOutlined";
import Restricted from "components/Restricted";
import iconNutri from "assets/icon-nutri.png";
import iconSalud from "assets/icon-salud.png";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tooltip from "@mui/material/Tooltip";
import {
  AccountCircle,
  Assignment,
  ChevronRight,
  FastForward,
  HolidayVillage,
  Home,
  LocationOn,
  Medication,
  PersonSearch,
} from "@mui/icons-material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ResultadosBusqueda = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("prop");
  const proyect = JSON.parse(token);
  console.log(proyect);
  const [items, setItems] = React.useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const rol = items.rol;

  const nav = useNavigate();

  //Theme
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  //Modal Styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "95%" : "30%",
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
  };

  //Modal Handlers
  const [open, setOpen] = React.useState(false);
  const [datosModal, setDatosModal] = React.useState({});
  const handleOpen = (event, dato) => {
    console.log(dato.dat, "datos");
    setDatosModal(dato.dat);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setValue(0);
  };

  //Tabs Handlers
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //BreadCrumbs
  function handleClickBreadCrumbs(event) {
    event.preventDefault();
  }

  //Routes
  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };

  const handleLinkBenef = (event, message) => {
    if (message === "benef") {
      nav("/beneficiarios");
    }
  };

  return (
    <Box m="1.5rem 2.5rem">
      {rol === "1" || rol === "2" || rol === "4" ? (
        <>
          <Box
            role="presentation"
            onClick={handleClickBreadCrumbs}
            sx={{ mb: 3 }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/inicio"
                onClick={(event) => handleLinkHome(event, "home")}
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Inicio
              </Link>
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/beneficiarios"
                onClick={(event) => handleLinkBenef(event, "benef")}
              >
                <FamilyRestroomOutlinedIcon
                  sx={{ mr: 0.5 }}
                  fontSize="inherit"
                />
                Beneficiarios
              </Link>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <PersonSearch sx={{ mr: 0.5 }} fontSize="inherit" />
                Búsqueda
              </Typography>
            </Breadcrumbs>
            <Typography variant="h3" sx={{ mt: 2, mb: 5 }}>
              <b>Beneficiarios Encontrados:</b> {proyect.length}
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {proyect.map((dat, index) => (
              <Grid
                item
                xs={12}
                sm={proyect.length <= 1 ? 5 : proyect.length <= 2 ? 6 : 4}
              >
                <Card
                  sx={{
                    borderRadius: 3,
                    boxShadow: 4,
                    bgcolor: theme.palette.background.alt,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Tooltip
                        title={dat.tipo === "NUT" ? "Nutrición" : "Salud"}
                      >
                        <Avatar
                          src={dat.tipo == "NUT" ? iconNutri : iconSalud}
                          sx={{ width: 50, height: 50, mt: 1, ml: 1 }}
                        />
                      </Tooltip>
                    }
                    title={
                      <Link
                        underline="hover"
                        sx={{ cursor: "pointer", color: "dodgerblue" }}
                        onClick={(event) => handleOpen(event, { dat })}
                      >
                        {dat.nombre} {dat.apellido} {">>"}
                      </Link>
                    }
                    subheader={`Edad: ` + dat.edad}
                    titleTypographyProps={{
                      mt: 2,
                      fontWeight: "bold",
                      fontSize: 16,
                      mb: 1,
                    }}
                    subheaderTypographyProps={{
                      fontWeight: "bold",
                      fontSize: 14,
                    }}
                  />
                  <CardContent>
                    <Typography
                      variant="h6"
                      ml={3}
                      sx={{ color: "text.secondary" }}
                    >
                      <b>
                        <LocationOn sx={{ mr: 1 }} />
                        Municipio:{" "}
                      </b>{" "}
                      {dat.municipio}
                    </Typography>
                    <Typography
                      variant="h6"
                      ml={3}
                      sx={{ color: "text.secondary" }}
                    >
                      <b>
                        <HolidayVillage sx={{ mr: 1 }} />
                        Parroquia:{" "}
                      </b>{" "}
                      {dat.parroquia}
                    </Typography>
                    <Typography
                      variant="h6"
                      ml={3}
                      sx={{ color: "text.secondary" }}
                    >
                      <b>
                        <Home sx={{ mr: 1 }} />
                        Comunidad:{" "}
                      </b>{" "}
                      {dat.comunidad}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* ////////////////////////// MODAL FICHA BENEFICIARIO //////////////////////////////////// */}
          <Modal
            open={open}
            onClose={handleClose}
          >
            <Box sx={style}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    variant={isSmallScreen ? "scrollable" : "fullWidth"}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      icon={<AccountCircle />}
                      label="Datos Básicos"
                      {...a11yProps(0)}
                    />
                    <Tab
                      icon={<Assignment />}
                      label="Diagnósticos"
                      {...a11yProps(1)}
                    />
                    <Tab
                      icon={<Medication />}
                      label="Tratamientos"
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  {/*<img
                    className="icon-img-modal"
                    title={datosModal.tipo === "NUT" ? "Nutrición" : "Salud"}
                    src={datosModal.tipo === "NUT" ? iconNutri : iconSalud}
                  />*/}
                  <Grid container justifyContent="center">
                    <Grid item xs={3} sm={2}>
                      <Tooltip
                        title={
                          datosModal.tipo === "NUT" ? "Nutrición" : "Salud"
                        }
                      >
                        <Avatar
                          src={
                            datosModal.tipo === "NUT" ? iconNutri : iconSalud
                          }
                          sx={{ width: 55, height: 55, mt: 1 }}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={11} sm={11} mt={2}>
                      <Typography
                        variant="h4"
                        className="text-center"
                        sx={{ fontWeight: "bold", mb: 2 }}
                      >
                        {datosModal.nombre} {datosModal.apellido}{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center">
                    <Grid item xs={11} mt={3}>
                      <Typography>
                        <strong>Nombre Representante: </strong>
                        {datosModal.nombre_rep} {datosModal.apellido_rep}
                      </Typography>
                    </Grid>
                    <Grid item xs={11} mt={1}>
                      <Typography>
                        <strong>Cédula: </strong>
                        {datosModal.cedula}
                      </Typography>
                    </Grid>
                    <Grid item xs={11} mt={1}>
                      <Typography>
                        <strong>Fecha de Nacimiento: </strong>
                        {datosModal.fecha_nacimiento}
                      </Typography>
                    </Grid>
                    <Grid item xs={11} mt={1}>
                      <Typography>
                        <strong>Edad: </strong>
                        {datosModal.edad}
                      </Typography>
                    </Grid>
                    <Grid item xs={11} mt={1}>
                      <Typography>
                        <strong>Género: </strong>
                        {datosModal.sexo}
                      </Typography>
                    </Grid>
                    <Grid item xs={11} mt={1}>
                      <Typography>
                        <strong>Grupo Étnico: </strong>
                        {datosModal.grupo_etnico}
                      </Typography>
                    </Grid>
                    <Grid item xs={11} mt={1}>
                      <Typography>
                        <strong>Registrado: </strong>
                        {datosModal.registrado == ""
                          ? "S/D"
                          : datosModal.registrado}
                      </Typography>
                    </Grid>
                    <Grid item xs={11} mt={1}>
                      <Typography>
                        <strong>Escolaridad: </strong>
                        {datosModal.escolaridad == ""
                          ? "S/D"
                          : datosModal.escolaridad}
                      </Typography>
                    </Grid>
                    <Grid item xs={11} mt={1}>
                      <Typography>
                        <strong>Discapacidad: </strong>
                        {datosModal.discapacidad}
                      </Typography>
                    </Grid>
                    <Grid item xs={11} mt={1}>
                      <Typography>
                        <strong>Municipio: </strong>
                        {datosModal.municipio}
                      </Typography>
                    </Grid>
                    <Grid item xs={11} mt={1}>
                      <Typography>
                        <strong>Comunidad: </strong>
                        {datosModal.comunidad}
                      </Typography>
                    </Grid>
                    <Grid item xs={11} mt={1}>
                      <Typography>
                        <strong>Atendido por: </strong>
                        {datosModal.medico == "" || datosModal.medico == null
                          ? "S/D"
                          : datosModal.medico}
                      </Typography>
                    </Grid>
                    <Grid item xs={11} mt={1}>
                      <Typography>
                        <strong>Fecha de Evaluación: </strong>
                        {datosModal.fecha_evaluacion}
                      </Typography>
                    </Grid>
                    <Grid item xs={11} mt={1}>
                      <Typography>
                        <strong>Comentarios: </strong>
                        {datosModal.observaciones == ""
                          ? "S/D"
                          : datosModal.observaciones}
                      </Typography>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Grid container justifyContent="center">
                    <Grid item xs={12} mt={2}>
                      <Typography>
                        <strong>Peso: </strong>
                        {datosModal.peso} Kg
                      </Typography>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <Typography>
                        <strong>Talla: </strong>
                        {datosModal.talla} cm
                      </Typography>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <Typography>
                        <strong>Circunferencia de brazo (CMB): </strong>
                        {datosModal.circunferencia} cm
                      </Typography>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <Typography>
                        <strong>Clasificación PB: </strong>
                        {datosModal.clasificacion_pb}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <Typography>
                        <strong>Puntaje Z: </strong>
                        {datosModal.puntaje_z == ""
                          ? "S/D"
                          : datosModal.puntaje_z}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <Typography>
                        <strong>Clasificación Nutricional: </strong>
                        {datosModal.clasificacion_nut == ""
                          ? "S/D"
                          : datosModal.clasificacion_nut}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <Divider sx={{ mt: 2, mb: 4 }} />
                      <Typography className="text-center" sx={{ fontSize: 16 }}>
                        <strong>Diagnóstico de Ingreso: </strong>
                        {datosModal.diagnostico_ingreso == ""
                          ? "S/D"
                          : datosModal.diagnostico_ingreso}
                      </Typography>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Grid container justifyContent="center">
                    <Grid item xs={12} mt={1}>
                      <Typography>
                        <strong>Albendazol: </strong>
                        {datosModal.albendazol}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <Typography>
                        <strong>Micronutrientes: </strong>
                        {datosModal.micronutrientes}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <Typography>
                        <strong>LNS-MQ(Sobre Amarillo): </strong>
                        {datosModal.lns_mq}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <Typography>
                        <strong>RUTF(Sobre Rojo): </strong>
                        {datosModal.ruft}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <Divider sx={{ mt: 2, mb: 4 }} />
                      <Typography className="text-center" sx={{ fontSize: 16 }}>
                        <strong>Dosis: </strong>
                        {datosModal.dosis_medicamento == ""
                          ? "S/D"
                          : datosModal.dosis_medicamento}
                      </Typography>
                    </Grid>
                  </Grid>
                </TabPanel>
                <Divider sx={{ mt: 4 }} />
                <Grid
                  container
                  sx={{ mt: 2 }}
                  spacing={1}
                  justifyContent="flex-end"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleClose}
                    >
                      Cerrar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Modal>
          {/* //////////////////////////////////////////////////////////////////////////////////////////// */}
        </>
      ) : (
        <Restricted />
      )}
    </Box>
  );
};

export default ResultadosBusqueda;
