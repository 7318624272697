import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Box, Divider, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import HomeIcon from "@mui/icons-material/Home";
import GroupsOutlined from "@mui/icons-material/GroupsOutlined";
import RuleOutlinedIcon from "@mui/icons-material/RuleOutlined";
import logo from "assets/paluz-logo.png";
import line from "assets/line-reporte.png";
import Fab from "@mui/material/Fab";
import PrintIcon from "@mui/icons-material/Print";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import Restricted from "components/Restricted";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

const ReporteAsistencia = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("prop");
  const report = JSON.parse(token);
  console.log(report);

  let act = JSON.parse(report[0].actividad);
  const actvidad = act[0];
  const nav = useNavigate();

  const [items, setItems] = React.useState([]);
  const [equipos, setEquipos] = React.useState([]);
  const [equipoInfo, setEquipoInfo] = React.useState([]);
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const rol = items.rol;

  //Theme
  const theme = useTheme();

  //BreadCrumbs
  function handleClickBreadCrumbs(event) {
    event.preventDefault();
  }

  //Routes
  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };
  const handleLinkGestion = (event, message) => {
    if (message === "gestion") {
      nav("/gestionhumana");
    }
  };
  const handleLinkAsist = (event, message) => {
    if (message === "asist") {
      nav(-1);
    }
  };

  //Modal Handlers
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Configuración PDF
  const createPDF = async () => {
    const canvas = await html2canvas(document.querySelector("#pdf"));
    const image = { type: "PNG", quality: 0.98 };
    const margin = [0, 0.5];

    var imgWidth = 8.5;
    var pageHeight = 10.68;

    var innerPageWidth = imgWidth - margin[0] * 2;
    var innerPageHeight = pageHeight - margin[1] * 2;

    // Calcular número de páginas.
    var pxFullHeight = canvas.height;
    var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
    var nPages = Math.ceil(pxFullHeight / pxPageHeight);

    // Definir pageHeight por separado para que pueda ser ajustada en la página final.
    var pageHeight = innerPageHeight;

    // Crear un canvas de una página para dividir la imagen completa.
    var pageCanvas = document.createElement("canvas");
    var pageCtx = pageCanvas.getContext("2d");
    pageCanvas.width = canvas.width;
    pageCanvas.height = pxPageHeight;

    // Inicializar el PDF.
    var pdf = new jsPDF("p", "in", "letter");

    for (var page = 0; page < nPages; page++) {
      // Ajustar la página final para reducir el tamaño del archivo.
      if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
        pageCanvas.height = pxFullHeight % pxPageHeight;
        pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
      }

      // Mostrar la imagen.
      var w = pageCanvas.width;
      var h = pageCanvas.height;
      pageCtx.fillStyle = "white";
      pageCtx.fillRect(0, 0, w, h);
      pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

      // Añadir la página al PDF.
      if (page > 0) pdf.addPage();
      debugger;
      var imgData = pageCanvas.toDataURL("image/" + image.type, image.quality);
      pdf.addImage(imgData, image.type, 0, 0.5, imgWidth, pageHeight);
    }

    // Contar el número de páginas del PDF.
    /*const pageCount = pdf.internal.getNumberOfPages();
      for(var i = 0; i < pageCount; i++) { 
      pdf.setPage(i); 
      let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
      pdf.setFontSize(8);
      pdf.text('Pág. ' + pageCurrent + '/' + pageCount, 1, pdf.internal.pageSize.height - 0.5);
    }*/

    // Generar el PDF en una nueva ventana.
    window.open(
      pdf.output("bloburl", {
        filename: `reporte_asistencia_${new Date().toJSON().slice(0, 10)}.pdf`,
      }),
      "_blank"
    );
  };

  const getEquipos = async () => {
    try {
      const { data1 } = await axios
        .get("/equipos")
        .then(function (response) {
          // setAlerta({})
          let equipoRegistrados = response.data;
          let equiposConvo = JSON.parse(report[0].equipos);
          var nombreEquipos = "";
          for (let i = 0; i < equipoRegistrados.length; i++) {
            for (let j = 0; j < equiposConvo.length; j++) {
              if (equiposConvo[j] === equipoRegistrados[i].id) {
                nombreEquipos =
                  equipoRegistrados[i].nombre + ", " + nombreEquipos;
              }
            }
          }

          setEquipoInfo(nombreEquipos.slice(0, -2));
          setEquipos(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const [ejecutar, setEjecutar] = React.useState(true);
  if (ejecutar) {
    getEquipos();
  }

  return (
    <div className="exclude">
      {rol === "1" || rol === "2" || rol === "7" ? (
        <>
          <Box m="1.5rem 2.5rem">
            <Box
              role="presentation"
              onClick={handleClickBreadCrumbs}
              sx={{ mb: 3 }}
            >
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  href="/inicio"
                  onClick={(event) => handleLinkHome(event, "home")}
                >
                  <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Inicio
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  href="/gestionhumana"
                  onClick={(event) => handleLinkGestion(event, "gestion")}
                >
                  <GroupsOutlined sx={{ mr: 0.5 }} fontSize="inherit" />
                  Gestión Humana
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  href="/asistencia"
                  onClick={(event) => handleLinkAsist(event, "asist")}
                >
                  <RuleOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Asistencia
                </Link>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  <ContentPasteOutlinedIcon
                    sx={{ mr: 0.5 }}
                    fontSize="inherit"
                  />
                  Reporte de Asistencia
                </Typography>
              </Breadcrumbs>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Box
                display="flex-end"
                sx={{
                  "& > :not(style)": { m: 1 },
                  position: "absolute",
                  bottom: "0",
                }}
              >
                <Fab variant="extended" color="error" onClick={handleOpen}>
                  <CancelRoundedIcon sx={{ mr: 1 }} />
                  Cancelar
                </Fab>
                <Fab variant="extended" color="success" onClick={createPDF}>
                  <PrintIcon sx={{ mr: 1 }} />
                  Imprimir
                </Fab>
              </Box>
            </Box>
            <div id="pdf">
              <Container>
                <Box
                  gridColumn="span 8"
                  gridRow="span 3"
                  backgroundColor="white"
                  borderRadius="1.5rem"
                  p="1.5rem"
                  mt="25px"
                  height="fit-content"
                >
                  <Box
                    sx={{
                      marginTop: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      display="flex"
                      component="img"
                      alt="logo"
                      src={logo}
                      height="35%"
                      width="30%"
                      alignSelf="flex-start"
                    ></Box>
                    <Box
                      display="flex"
                      component="img"
                      alt="line1"
                      src={line}
                      width="98%"
                      sx={{ mt: 2 }}
                    ></Box>
                    <Box sx={{ mt: 5 }}>
                      <Grid container justifyContent="center">
                        <Grid item xs={7} sm={12}>
                          <Typography
                            sx={{
                              fontSize: 25,
                              fontWeight: "bold",
                              color: "black",
                              alignSelf: "center",
                            }}
                          >
                            <u> Reporte de Asistencia </u>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ mt: 8 }}>
                      <Grid container justifyContent="flex-start" ml={5}>
                        <Grid item>
                          <Box sx={{ mb: 5 }}>
                            <Typography sx={{ fontSize: 18, color: "black" }}>
                              <b> Fecha: </b>
                              {report[0].fecha}
                            </Typography>
                            <Typography
                              sx={{ fontSize: 18, color: "black", mt: 1 }}
                            >
                              <b> Equipo: </b> {equipoInfo}
                            </Typography>
                            <Typography
                              sx={{ fontSize: 18, color: "black", mt: 1 }}
                            >
                              <b> Estado: </b> {report[0].estadoConvo}
                            </Typography>
                            <Typography
                              sx={{ fontSize: 18, color: "black", mt: 1 }}
                            >
                              <b> Municipio: </b> {report[0].municipio}
                            </Typography>
                            <Typography
                              sx={{ fontSize: 18, color: "black", mt: 1 }}
                            >
                              <b> Comunidad: </b> {report[0].comunidad}
                            </Typography>
                            <Typography
                              sx={{ fontSize: 18, color: "black", mt: 1 }}
                            >
                              <b> Tipo de Actividad: </b> {actvidad}
                            </Typography>
                            <Typography
                              sx={{ fontSize: 18, color: "black", mt: 1 }}
                            >
                              <b> Proyecto que convoca: </b>
                              <p style={{ wordWrap: "break-word" }}>
                                {report[0].nombre}
                              </p>
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Box sx={{ overflow: "auto" }} position="relative">
                        <Box
                          sx={{
                            width: "100%",
                            display: "table",
                            tableLayout: "fixed",
                          }}
                        >
                          <TableContainer>
                            <Table
                              sx={{ minWidth: 800 }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: 18,
                                      color: "white",
                                      background: "teal",
                                      border: "2px solid black",
                                    }}
                                  >
                                    Nombre
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: 18,
                                      color: "white",
                                      background: "teal",
                                      border: "2px solid black",
                                    }}
                                  >
                                    Apellido
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: 18,
                                      color: "white",
                                      background: "teal",
                                      border: "2px solid black",
                                    }}
                                  >
                                    Ocupación
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: 18,
                                      color: "white",
                                      background: "teal",
                                      border: "2px solid black",
                                    }}
                                  >
                                    Refrigerio
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: 18,
                                      color: "white",
                                      background: "teal",
                                      border: "2px solid black",
                                    }}
                                  >
                                    Asistencia
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {report.map((x, i) => (
                                  <TableRow>
                                    <TableCell
                                      align="center"
                                      sx={{
                                        color: "black",
                                        border: "2px solid black",
                                        fontSize: 16
                                      }}
                                    >
                                      {x.nombres}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{
                                        color: "black",
                                        border: "2px solid black",
                                        fontSize: 16
                                      }}
                                    >
                                      {x.apellidos}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{
                                        color: "black",
                                        border: "2px solid black",
                                        fontSize: 16
                                      }}
                                    >
                                      {x.descripcion}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{
                                        color: "black",
                                        border: "2px solid black",
                                        fontSize: 16
                                      }}
                                    >
                                      {x.refrigerio === 1 ? "Si" : "No"}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{
                                        color: "black",
                                        border: "2px solid black",
                                        fontSize: 16
                                      }}
                                    >
                                      {x.asistencias}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Box>
                      <Grid
                        container
                        sx={{ mt: 15, mb: 10 }}
                        justifyContent="center"
                        spacing={5}
                      >
                        {/*<Grid item xs={6}>
                          <Box>
                            <Typography
                              align="center"
                              sx={{
                                display: "inline-block",
                                borderBottom: "1px solid #000",
                                width: "100%",
                              }}
                            ></Typography>
                            <Typography
                              align="center"
                              sx={{
                                fontSize: 16,
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              Scarlet Morán
                            </Typography>
                            <Typography
                              align="center"
                              sx={{
                                fontSize: 16,
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              Coordinador de Gestión Humana
                            </Typography>
                          </Box>
                            </Grid>*/}
                        <Grid item xs={6}>
                          <Box>
                            <Typography
                              align="center"
                              sx={{
                                display: "inline-block",
                                borderBottom: "1px solid #000",
                                width: "100%",
                              }}
                            ></Typography>
                            <Typography
                              align="center"
                              sx={{
                                fontSize: 16,
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              Valeria Valera
                            </Typography>
                            <Typography
                              align="center"
                              sx={{
                                fontSize: 16,
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              Coordinadora de Voluntariado
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Container>
            </div>
          </Box>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-description"
                align="center"
                sx={{ mt: 2 }}
              >
                ¿Está seguro de cancelar esta operación?
              </Typography>
              <Divider sx={{ mt: 2 }}></Divider>
              <Grid
                container
                sx={{ mt: 1 }}
                spacing={2}
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleClose}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{ bgcolor: "teal", color: "white" }}
                    onClick={(event) => handleLinkGestion(event, "gestion")}
                  >
                    Si
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </>
      ) : (
        <Box m="1.5rem 2.5rem">
          <Restricted />
        </Box>
      )}
    </div>
  );
};

export default ReporteAsistencia;
