import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
//import { esES } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import "dayjs/locale/es";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import DeleteIcon from "@mui/icons-material/Delete";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Restricted from "components/Restricted";
import clienteAxios from "../../config/clienteAxios";
import {
  useGetProyectoQuery,
  useGetTipoActividadQuery,
  useGetEquiposQuery,
  useGetProfesionesQuery,
} from "state/api";
import { set } from "date-fns";
import axios from "axios";

const CrearActividad = () => {
  // let pro= useGetProyectoQuery();
  // pro=pro.data
  // console.log(pro);
  //  let actividad1= useGetTipoActividadQuery();
  // actividad1=actividad1.data
  //  console.log(actividad1);
  //  let equipos= useGetEquiposQuery();
  // equipos=equipos.data
  //  console.log(equipos);
  //  let roles= useGetProfesionesQuery();
  //  roles=roles.data
  //   console.log(roles);
  const [ejecutar, setEjecutar] = React.useState(true);
  const [pro, setPro] = React.useState([]);
  const [actividad1, setActividad1] = React.useState([]);
  const [equipos, setEquipos] = React.useState([]);
  const [roles, setRoles] = React.useState([]);

  const [items, setItems] = React.useState([]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;


  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const rol = items.rol;

  const getPro = async () => {
    try {
      const { data1 } = await axios
        .get("/proyectos")
        .then(function (response) {
          // setAlerta({})
          let datos = response.data;
          datos = datos.filter((dato) => dato.estado === 1);
          setPro(datos);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const getActividad1 = async () => {
    try {
      const { data1 } = await axios
        .get("/tipoactividad")
        .then(function (response) {
          // setAlerta({})

          setActividad1(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const getEquipos = async () => {
    try {
      const { data1 } = await axios
        .get("/equipos")
        .then(function (response) {
          // setAlerta({})

          setEquipos(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const getRoles = async () => {
    try {
      const { data1 } = await axios
        .get("/profesiones")
        .then(function (response) {
          // setAlerta({})

          setRoles(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const nav = useNavigate();
  //const [actividad2, setActividad2] = React.useState([]);

  //Theme
  const theme = useTheme();
  ////

  //DatePicker Value
  const [valueDP, setValueDP] = React.useState(null);

  //TimePicker Values
  const [newValueHE, setValueHE] = React.useState(null);
  const [newValueHEStr, setValueHEStr] = React.useState(null);
  const handleChangeHE = (newValueHE) => {
    var str = new Date(newValueHE).toString();
    str = str.slice(16, -32);
    console.log(str);
    setValueHEStr(str);
    setValueHE(newValueHE);
  };

  const [newValueHS, setValueHS] = React.useState(null);
  const [newValueHSStr, setValueHSStr] = React.useState(null);
  const handleChangeHS = (newValueHS) => {
    var str1 = new Date(newValueHS).toString();
    str1 = str1.slice(16, -32);
    setValueHSStr(str1);
    setValueHS(newValueHS);
  };

  const [newValueRT, setValueRT] = React.useState(null);
  const [newValueRTStr, setValueRTStr] = React.useState(null);
  const handleChangeRT = (newValueRT) => {
    var str2 = new Date(newValueRT).toString();
    str2 = str2.slice(16, -32);
    setValueRTStr(str2);
    setValueRT(newValueRT);
  };

  //BreadCrumbs
  function handleClickBreadCrumbs(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  //Modal Styles
  const style = {
    position: "inherit",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 375,
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    borderRadius: 4,
    boxShadow: 1,
    p: 4,
  };

  //Modal Handlers
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openAlert, setOpenAlert] = React.useState(false);
  const handleOpenAlert = () => {
    setOpenAlert(true);
    handleCloseAlert();
  };
  const handleCloseAlert = () =>
    setTimeout(() => {
      setOpenAlert(false);
      nav("/voluntariado");
    }, 2000);

  //Actividad Select
  const [actividadOpc, setActividadOpc] = React.useState([]);
  const handleChangeActividad = (event) => {
    const {
      target: { value },
    } = event;
    setActividadOpc(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  //Equipos Select
  const [equiposOpc, setEquiposOpc] = React.useState([]);
  const [equiposQuery, setEquiposQuery] = React.useState([]);
  const handleChangeEquipos = (event) => {
    const {
      target: { value },
    } = event;
    setEquiposOpc(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setEquiposQuery(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  //Proyecto Select
  const [proyecto, setProyecto] = React.useState("");
  const handleChangeProyecto = (event) => {
    setProyecto(event.target.value);
  };

  //Cantidad de Voluntarios Inputs
  const [data, setData] = useState([{ roles: "" }]);
  const [data2, setData2] = useState([{ cantidad: "" }]);
  const [activar, setActivar] = useState(true);
  let contador = 0;

  const handleClick = () => {
    setData([...data, { roles: "", cantidad: "" }]);
    setActivar(true);
  };
  const handleChangeInput = (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...data];
    onchangeVal[i][name] = value;
    setData(onchangeVal);

    for (let i = 0; i < data.length; i++) {
      if (data[i].roles !== "" && data[i].cantidad !== "") {
        console.log(data[i].cantidad);
      } else {
        contador = contador + 1;
      }
      if (contador !== 0) {
        setActivar(true);
      } else {
        setActivar(false);
      }
    }
  };
  const handleDelete = (i) => {
    const deleteVal = [...data];
    deleteVal.splice(i, 1);
    setData(deleteVal);
  };

  //Voluntarios Extra Radio
  const [valueExtra, setValueExtra] = React.useState("No");
  const handleChangeExtra = (event) => {
    setValueExtra(event.target.value);
  };

  //Voluntarios Extra Inputs
  const [dataExtra, setDataExtra] = useState([{ rolesExtra: "" }]);
  const [dataExtra2, setDataExtra2] = useState([{ cantidadExtra: "" }]);
  const [activarExtra, setActivarExtra] = useState(true);
  let contadorExtra = 0;

  const handleClickExtra = () => {
    setDataExtra([...dataExtra, { rolesExtra: "", cantidadExtra: "" }]);
    setActivarExtra(true);
  };
  const handleChangeInputExtra = (e, i) => {
    const { name, value } = e.target;
    const onchangeValExtra = [...dataExtra];
    onchangeValExtra[i][name] = value;
    setDataExtra(onchangeValExtra);

    for (let i = 0; i < dataExtra.length; i++) {
      if (dataExtra[i].rolesExtra !== "" && dataExtra[i].cantidadExtra !== "") {
        console.log(dataExtra[i].cantidadExtra);
      } else {
        contadorExtra = contadorExtra + 1;
      }
      if (contadorExtra !== 0) {
        setActivarExtra(true);
      } else {
        setActivarExtra(false);
      }
    }
  };
  const handleDeleteExtra = (i) => {
    const deleteValExtra = [...dataExtra];
    deleteValExtra.splice(i, 1);
    setDataExtra(deleteValExtra);
  };

  //Routes
  const handleLinkClick = (event, message) => {
    if (message === "cancelar") {
      nav(-1);
    }
  };
  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };
  const handleLinkVol = (event, message) => {
    if (message === "voluntariado") {
      nav("/voluntariado");
    }
  };

  //Form Submit
  const [loc, setLoc] = React.useState("");
  const [estado, setEstado] = React.useState("");
  const [municipio, setMunicipio] = React.useState("");
  const [parroquia, setParroquia] = React.useState("");
  const [comunidad, setComunidad] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const [latitud, setLatitud] = React.useState("");
  const [longitud, setLongitud] = React.useState("");
  const [puntoEncuentro, setPuntoEncuentro] = React.useState("");
  const [duracion, setDuracion] = React.useState("");
  const [comentarios, setComentarios] = React.useState("");
  const [stateInsumos, setCheck] = React.useState({
    plumpy: false,
    rutf: false,
    desparasitantes: false,
    micronutrientes: false,
    medicamentos: false,
  });
  const [stateLogistica, setCheckLogistica] = React.useState({
    sillas: false,
    mesas: false,
    minibus: false,
    pickup: false,
  });
  const handleLoc = (event) => {
    setLoc(event.target.value);
  };
  const handleEstado = (event) => {
    setEstado(event.target.value);
  };
  const handleMunicipio = (event) => {
    setMunicipio(event.target.value);
  };
  const handleParroquia = (event) => {
    setParroquia(event.target.value);
  };
  const handleComunidad = (event) => {
    setComunidad(event.target.value);
  };
  const handleDesc = (event) => {
    setDesc(event.target.value);
  };
  const handleLatitud = (event) => {
    setLatitud(event.target.value);
  };
  const handleLongitud = (event) => {
    setLongitud(event.target.value);
  };
  const handlePuntoEncuentro = (event) => {
    setPuntoEncuentro(event.target.value);
  };
  const handleDuracion = (event) => {
    setDuracion(event.target.value);
  };
  const handleComentarios = (event) => {
    setComentarios(event.target.value);
  };

  const handleChangeCheck = (event) => {
    setCheck({
      ...stateInsumos,
      [event.target.name]: event.target.checked,
    });
  };
  const handleChangeCheckLogistica = (event) => {
    setCheckLogistica({
      ...stateLogistica,
      [event.target.name]: event.target.checked,
    });
  };

  const { plumpy, rutf, desparasitantes, micronutrientes, medicamentos } =
    stateInsumos;
  const { sillas, mesas, minibus, pickup } = stateLogistica;

  const handleSubmit = async (event) => {
    let convocaProfesiones = [];
    for (let i = 0; i < data.length; i++) {
      let vacio = [];
      vacio = data[i].roles;
      convocaProfesiones = convocaProfesiones.concat(vacio);
    }

    console.log(convocaProfesiones.slice(0, convocaProfesiones.length - 1));
    event.preventDefault();
    console.log(stateLogistica);
    console.log(stateInsumos);
    let query = equiposQuery.toString();
    let profesiones = convocaProfesiones;
    console.log(convocaProfesiones, "estas son las profesiones");
    console.log(equiposQuery);
    try {
      const { data1 } = await axios
        .post("/crearconvocatoria/", {
          //idConvocatoria: params.idConvocatoria,
          idProyecto: proyecto,
          actividad: JSON.stringify(actividadOpc),
          descripcion: desc,
          estadoConvo: estado,
          municipio: municipio,
          parroquia: parroquia,
          comunidad: comunidad,
          latitud: latitud,
          longitud: longitud,
          fecha: valueDP,
          puntoEncuentro: puntoEncuentro,
          duracion: duracion,
          horaEncuentro: newValueHEStr,
          horaSalida: newValueHSStr,
          horaRetorno: newValueRTStr,
          cantidadVoluntarios: JSON.stringify(data),
          equipos: JSON.stringify(equiposOpc),
          logistica: JSON.stringify(stateLogistica),
          comentarios: comentarios,
          insumos: JSON.stringify(stateInsumos),
          equiposQuery: equiposQuery,
          profesionesQuery: convocaProfesiones,
          extra: valueExtra,

          // //        nombres: (dataform.get('nombreProyecto')),
          // //        apellidos: (dataform.get('codigoProyecto')),
          // //        cedula: (dataform.get('descripcion')),
          // //        correo: dataform.get('email'),
          // //        telefono: dataform.get('textmask'),
          // //        //fnacimiento:new Date(selectedDate).toLocaleDateString('es-ES') ,
          // //        ocupacion: dataform.get('ocupacion'),
          // //        direccion: dataform.get('direccion'),
        })
        .then(function (response) {
          //console.log(response.data.idToken)
          //localStorage.setItem('token',JSON.stringify(response.data) )
          //setAuth(data)
          //event.target.reset();
        })
        .catch(function (error) {
          // //        event.preventDefault();
          // //        console.log('error')
          // //       // document.getElementById(":r7:").value='';
        });

      console.log(data1);
    } catch (error) {}

    handleOpenAlert();
  };

  if (ejecutar) {
    getActividad1();
    getEquipos();
    getPro();
    getRoles();
  }

  if (!actividad1 || !pro || !equipos || !roles)
    return (
      <Container component="main" maxWidth="xs">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );

  return (
    <Box m="1.5rem 1.5rem">
      {rol === "1" ||
      rol === "2" ||
      rol === "6" ||
      rol === "7" ||
      rol === "8" ||
      rol === "9" ||
      rol === "10" ? (
        <>
          <Box
            gridColumn="span 2"
            gridRow="span 1"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p="1.25rem 1rem"
            flex="1 1 100%"
            borderRadius="0.55rem"
          >
            <CssBaseline />
            <Box
              role="presentation"
              onClick={handleClickBreadCrumbs}
              sx={{ mb: 3 }}
            >
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  href="/inicio"
                  onClick={(event) => handleLinkHome(event, "home")}
                >
                  <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Inicio
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  href="/voluntariado"
                  onClick={(event) => handleLinkVol(event, "voluntariado")}
                >
                  <VolunteerActivismIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Voluntariado
                </Link>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  <MoreTimeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Crear Convocatoria
                </Typography>
              </Breadcrumbs>
              <Typography variant="h3" sx={{ fontWeight: "bold", mt: 2 }}>
                {" "}
                Crear Convocatoria{" "}
              </Typography>
            </Box>
            <Container>
              <Card
                sx={{
                  borderRadius: 8,
                  boxShadow: 5,
                  bgcolor: theme.palette.background.alt,
                }}
              >
                <CardContent>
                  <Divider sx={{ mt: 2, mb: 3 }}>
                    <Chip label="INFORMACIÓN GENERAL" />
                  </Divider>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} mt={1}>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" required>
                            {" "}
                            Proyecto que Convoca{" "}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="convoc"
                            label="Proyecto que Convoca"
                            value={proyecto}
                            onChange={handleChangeProyecto}
                          >
                            <MenuItem value="">
                              <em> Seleccione un Proyecto </em>
                            </MenuItem>
                            {pro.map((dat, index) => (
                              <MenuItem value={dat.idproyectos}>
                                {dat.nombre}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" required>
                            {" "}
                            Tipo de Actividad{" "}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="tipoAct"
                            label="Tipo de Actividad"
                            multiple
                            value={actividadOpc}
                            onChange={handleChangeActividad}
                            input={<OutlinedInput label="Tipo de Actividad" />}
                          >
                            {actividad1.map((dat, index) => (
                              <MenuItem value={dat.nombre}>
                                {dat.nombre}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="descripcion"
                          required
                          fullWidth
                          id="descripcion"
                          label="Descripción de la Convocatoria"
                          value={desc}
                          onChange={handleDesc}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="estado"
                          required
                          fullWidth
                          id="estado"
                          label="Estado"
                          value={estado}
                          onChange={handleEstado}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="municipio"
                          required
                          fullWidth
                          id="municipio"
                          label="Municipio"
                          value={municipio}
                          onChange={handleMunicipio}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="parroquia"
                          required
                          fullWidth
                          id="parroquia"
                          label="Parroquia"
                          value={parroquia}
                          onChange={handleParroquia}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="comunidad"
                          required
                          fullWidth
                          id="comunidad"
                          label="Comunidad"
                          value={comunidad}
                          onChange={handleComunidad}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="latitud"
                          required
                          fullWidth
                          id="latitud"
                          label="Latitud"
                          value={latitud}
                          onChange={handleLatitud}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="longitud"
                          required
                          fullWidth
                          id="longitud"
                          label="Longitud"
                          value={longitud}
                          onChange={handleLongitud}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="es"
                        >
                          <DesktopDatePicker
                            label="Fecha"
                            name="fecha"
                            inputFormat="DD/MM/YYYY"
                            value={valueDP}
                            onChange={setValueDP}
                            disablePast
                            renderInput={(params) => {
                              return <TextField fullWidth {...params} />;
                            }}
                            clearable
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="encuentro"
                          required
                          fullWidth
                          id="encuentro"
                          label="Punto de Encuentro"
                          value={puntoEncuentro}
                          onChange={handlePuntoEncuentro}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="duracion"
                          required
                          fullWidth
                          id="duracion"
                          label="Duración Aprox."
                          value={duracion}
                          onChange={handleDuracion}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileTimePicker
                            name="horaEncuentro"
                            label="Hora de Encuentro"
                            value={newValueHE}
                            onChange={handleChangeHE}
                            renderInput={(params) => {
                              return <TextField fullWidth {...params} />;
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileTimePicker
                            name="horaSalida"
                            label="Hora de Salida"
                            value={newValueHS}
                            onChange={handleChangeHS}
                            renderInput={(params) => (
                              <TextField fullWidth {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileTimePicker
                            name="horaRetorno"
                            label="Hora Estimada de Retorno"
                            value={newValueRT}
                            onChange={handleChangeRT}
                            renderInput={(params) => (
                              <TextField fullWidth {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 3 }}>
                      <Chip label="VOLUNTARIOS" />
                    </Divider>
                    {data.map((val, i) => (
                      <div>
                        <Grid
                          container
                          spacing={2}
                          sx={{ mt: 1 }}
                          justifyContent="center"
                        >
                          <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                              <InputLabel id="roles"> Roles </InputLabel>
                              <Select
                                labelId="roles-select"
                                id="roles-select"
                                label="Roles"
                                name="roles"
                                value={val.roles}
                                onChange={(e) => handleChangeInput(e, i)}
                              >
                                <MenuItem value="">
                                  <em> Seleccione un Rol </em>
                                </MenuItem>
                                {roles.map((dat, index) => (
                                  <MenuItem value={dat.idprofesiones}>
                                    {dat.descripcion}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              fullWidth
                              id="cantidad"
                              label="Cantidad"
                              name="cantidad"
                              value={val.cantidad}
                              onChange={(e) => handleChangeInput(e, i)}
                            />
                          </Grid>
                          <Grid item xs={4} sm={1}>
                            <Tooltip title="Eliminar">
                              <IconButton
                                aria-label="delete"
                                size="large"
                                color="error"
                                onClick={() => handleDelete(i)}
                              >
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                    <Grid container justifyContent="center">
                      <Grid item xs={6} sm={2} sx={{ mt: 3 }}>
                        <Button
                          //disabled={activar}
                          variant="contained"
                          sx={{
                            bgcolor: "teal",
                            color: "white",
                            borderRadius: 2,
                          }}
                          fullWidth
                          onClick={handleClick}
                        >
                          {" "}
                          Añadir Más{" "}
                        </Button>
                      </Grid>
                    </Grid>
                    {/*<p>{JSON.stringify(data)}</p>*/}
                    <Divider sx={{ mt: 4, mb: 3 }}>
                      <Chip label="EQUIPOS" />
                    </Divider>
                    <Grid container justifyContent="center">
                      <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            {" "}
                            Equipos{" "}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Equipos"
                            multiple
                            value={equiposOpc}
                            onChange={handleChangeEquipos}
                            input={<OutlinedInput label="Equipos" />}
                          >
                            {equipos.map((dat, index) => (
                              <MenuItem value={dat.id}>{dat.nombre}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    {/*<Divider sx={{ mt: 4, mb: 3 }}>
                      <Chip label="VOLUNTARIOS ESPECÍFICOS" />
                    </Divider>
                    <Grid container justifyContent="center">
                      <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                        <Autocomplete
                          multiple
                          id="tags-standard"
                          options={top100Films}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.title}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.title}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Voluntarios"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>*/}
                    <Divider sx={{ mt: 4, mb: 3 }}>
                      <Chip label="VOLUNTARIOS EXTRA" />
                    </Divider>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item xs={12} sm={3}>
                        <FormControl>
                          <FormLabel
                            className="text-center"
                            id="volExtra-label"
                          >
                            ¿Se requieren Voluntarios Extra?
                          </FormLabel>
                          <RadioGroup
                            row
                            name="volExtra"
                            value={valueExtra}
                            onChange={handleChangeExtra}
                            className="text-center"
                            sx={{ justifyContent: "center" }}
                          >
                            <FormControlLabel
                              value="Si"
                              control={<Radio />}
                              label="Si"
                            />
                            <FormControlLabel
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                    {/*{dataExtra.map((val, i) => (
                      <div
                        style={{
                          display: valueExtra === "No" ? "none" : "block",
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{ mt: 1 }}
                          justifyContent="center"
                        >
                          <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                              <InputLabel id="roles"> Roles </InputLabel>
                              <Select
                                labelId="roles-select"
                                id="roles-select"
                                label="Roles"
                                name="rolesExtra"
                                value={val.rolesExtra}
                                onChange={(e) => handleChangeInputExtra(e, i)}
                              >
                                <MenuItem value="">
                                  <em> Seleccione un Rol </em>
                                </MenuItem>
                                {roles.map((dat, index) => (
                                  <MenuItem value={dat.idprofesiones}>
                                    {dat.descripcion}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              fullWidth
                              id="cantidadExtra"
                              label="Cantidad"
                              name="cantidadExtra"
                              value={val.cantidadExtra}
                              onChange={(e) => handleChangeInputExtra(e, i)}
                            />
                          </Grid>
                          <Grid item xs={4} sm={1}>
                            <Tooltip title="Eliminar">
                              <IconButton
                                aria-label="delete"
                                size="large"
                                color="error"
                                onClick={() => handleDeleteExtra(i)}
                              >
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                    <Grid container justifyContent="center">
                      <Grid item xs={6} sm={2} sx={{ mt: 3 }}>
                        <Button
                          disabled={activarExtra}
                          variant="contained"
                          sx={{
                            bgcolor: "teal",
                            color: "white",
                            borderRadius: 2,
                          }}
                          style={{
                            display: valueExtra === "No" ? "none" : "block",
                          }}
                          onClick={handleClickExtra}
                          fullWidth
                        >
                          Añadir Más
                        </Button>
                      </Grid>
                    </Grid>*/}
                    <Box mt={5}>
                      <Typography variant="h6" className="text-center">
                        <InfoOutlinedIcon /> <strong>"Voluntario Extra"</strong>{" "}
                        se refiere a todo aquel voluntario que no pertenece a tu
                        equipo.
                      </Typography>
                    </Box>
                    <Divider sx={{ mt: 4, mb: 3 }}>
                      <Chip label="LOGÍSTICA NECESARIA" />
                    </Divider>
                    <Grid container justifyContent="center">
                      <Grid item xs={12} sm={5}>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="sillas"
                                color="success"
                                checked={sillas}
                                value="Sillas"
                                onChange={handleChangeCheckLogistica}
                              />
                            }
                            label="Sillas"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="mesas"
                                color="success"
                                checked={mesas}
                                value="Mesas"
                                onChange={handleChangeCheckLogistica}
                              />
                            }
                            label="Mesas"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="minibus"
                                color="success"
                                checked={minibus}
                                value="Minibus"
                                onChange={handleChangeCheckLogistica}
                              />
                            }
                            label="Minibus (Coaster)"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="pickup"
                                color="success"
                                checked={pickup}
                                value="Pickup"
                                onChange={handleChangeCheckLogistica}
                              />
                            }
                            label="Pickup"
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="center" mt={1.5}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="comentarios"
                          fullWidth
                          id="comentarios"
                          label="Comentarios"
                          value={comentarios}
                          onChange={handleComentarios}
                          multiline
                          maxRows={4}
                        />
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 3 }}>
                      <Chip label="INSUMOS NECESARIOS" />
                    </Divider>
                    <Grid container justifyContent="center">
                      <Grid item xs={12} sm={8}>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="plumpy"
                                color="success"
                                checked={plumpy}
                                value="Plumpy"
                                onChange={handleChangeCheck}
                              />
                            }
                            label="Plumpy"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="rutf"
                                color="success"
                                checked={rutf}
                                value="RUTF"
                                onChange={handleChangeCheck}
                              />
                            }
                            label="RUTF"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="desparasitantes"
                                color="success"
                                checked={desparasitantes}
                                value="Micronutrientes"
                                onChange={handleChangeCheck}
                              />
                            }
                            label="Desparasitantes"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="micronutrientes"
                                color="success"
                                checked={micronutrientes}
                                value="Micronutrientes"
                                onChange={handleChangeCheck}
                              />
                            }
                            label="Micronutrientes"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="medicamentos"
                                color="success"
                                checked={medicamentos}
                                value="Medicamentos"
                                onChange={handleChangeCheck}
                              />
                            }
                            label="Medicamentos"
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 5 }}></Divider>
                    <Grid
                      container
                      sx={{ mt: 2 }}
                      spacing={1}
                      justifyContent="center"
                    >
                      <Grid item sm={3} xs={6}>
                        <Button
                          variant="contained"
                          color="error"
                          size="large"
                          fullWidth
                          onClick={handleOpen}
                        >
                          {" "}
                          Cancelar{" "}
                        </Button>
                      </Grid>
                      <Grid item sm={3} xs={6}>
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{ bgcolor: "teal", color: "white" }}
                          size="large"
                          disabled={
                            !proyecto ||
                            actividadOpc == "" ||
                            !valueDP ||
                            !puntoEncuentro ||
                            !duracion ||
                            !newValueHE ||
                            !newValueHS ||
                            !newValueRT
                          }
                          fullWidth
                        >
                          {" "}
                          Guardar{" "}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                slotProps={{ backdrop: { style: { opacity: 0.2 } } }}
              >
                <Box sx={style}>
                  <Typography
                    variant="h6"
                    className="text-center"
                    sx={{ mb: 1.5 }}
                  >
                    {" "}
                    ¿Está seguro de cancelar esta operación?{" "}
                  </Typography>
                  <Divider sx={{ mt: 2 }} />
                  <Grid
                    container
                    sx={{ mt: 1 }}
                    spacing={1}
                    justifyContent="flex-end"
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                      >
                        {" "}
                        No{" "}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        sx={{ bgcolor: "teal", color: "white" }}
                        onClick={(event) => handleLinkClick(event, "cancelar")}
                      >
                        {" "}
                        Si{" "}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>

              <Modal
                open={openAlert}
                onClose={handleCloseAlert}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" className="text-center">
                    <TaskAltOutlinedIcon
                      color="success"
                      sx={{ fontWeight: "bold", mt: 3, fontSize: 80 }}
                    />
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    className="text-center"
                    sx={{ mt: 2, fontSize: 20, fontWeight: "bold" }}
                  >
                    ¡Convocatoria creada exitosamente!
                  </Typography>
                </Box>
              </Modal>
            </Container>
          </Box>
        </>
      ) : (
        <Restricted />
      )}
    </Box>
  );
};

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  {
    title: "The Lord of the Rings: The Return of the King",
    year: 2003,
  },
  { title: "The Good, the Bad and the Ugly", year: 1966 },
  { title: "Fight Club", year: 1999 },
  {
    title: "The Lord of the Rings: The Fellowship of the Ring",
    year: 2001,
  },
  {
    title: "Star Wars: Episode V - The Empire Strikes Back",
    year: 1980,
  },
  { title: "Forrest Gump", year: 1994 },
  { title: "Inception", year: 2010 },
  {
    title: "The Lord of the Rings: The Two Towers",
    year: 2002,
  },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: "Goodfellas", year: 1990 },
  { title: "The Matrix", year: 1999 },
  { title: "Seven Samurai", year: 1954 },
  {
    title: "Star Wars: Episode IV - A New Hope",
    year: 1977,
  },
  { title: "City of God", year: 2002 },
  { title: "Se7en", year: 1995 },
  { title: "The Silence of the Lambs", year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: "Life Is Beautiful", year: 1997 },
  { title: "The Usual Suspects", year: 1995 },
  { title: "Léon: The Professional", year: 1994 },
  { title: "Spirited Away", year: 2001 },
  { title: "Saving Private Ryan", year: 1998 },
  { title: "Once Upon a Time in the West", year: 1968 },
  { title: "American History X", year: 1998 },
  { title: "Interstellar", year: 2014 },
  { title: "Casablanca", year: 1942 },
  { title: "City Lights", year: 1931 },
  { title: "Psycho", year: 1960 },
  { title: "The Green Mile", year: 1999 },
  { title: "The Intouchables", year: 2011 },
  { title: "Modern Times", year: 1936 },
  { title: "Raiders of the Lost Ark", year: 1981 },
  { title: "Rear Window", year: 1954 },
  { title: "The Pianist", year: 2002 },
  { title: "The Departed", year: 2006 },
  { title: "Terminator 2: Judgment Day", year: 1991 },
  { title: "Back to the Future", year: 1985 },
  { title: "Whiplash", year: 2014 },
  { title: "Gladiator", year: 2000 },
  { title: "Memento", year: 2000 },
  { title: "The Prestige", year: 2006 },
  { title: "The Lion King", year: 1994 },
  { title: "Apocalypse Now", year: 1979 },
  { title: "Alien", year: 1979 },
  { title: "Sunset Boulevard", year: 1950 },
  {
    title:
      "Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb",
    year: 1964,
  },
  { title: "The Great Dictator", year: 1940 },
  { title: "Cinema Paradiso", year: 1988 },
  { title: "The Lives of Others", year: 2006 },
  { title: "Grave of the Fireflies", year: 1988 },
  { title: "Paths of Glory", year: 1957 },
  { title: "Django Unchained", year: 2012 },
  { title: "The Shining", year: 1980 },
  { title: "WALL·E", year: 2008 },
  { title: "American Beauty", year: 1999 },
  { title: "The Dark Knight Rises", year: 2012 },
  { title: "Princess Mononoke", year: 1997 },
  { title: "Aliens", year: 1986 },
  { title: "Oldboy", year: 2003 },
  { title: "Once Upon a Time in America", year: 1984 },
  { title: "Witness for the Prosecution", year: 1957 },
  { title: "Das Boot", year: 1981 },
  { title: "Citizen Kane", year: 1941 },
  { title: "North by Northwest", year: 1959 },
  { title: "Vertigo", year: 1958 },
  {
    title: "Star Wars: Episode VI - Return of the Jedi",
    year: 1983,
  },
  { title: "Reservoir Dogs", year: 1992 },
  { title: "Braveheart", year: 1995 },
  { title: "M", year: 1931 },
  { title: "Requiem for a Dream", year: 2000 },
  { title: "Amélie", year: 2001 },
  { title: "A Clockwork Orange", year: 1971 },
  { title: "Like Stars on Earth", year: 2007 },
  { title: "Taxi Driver", year: 1976 },
  { title: "Lawrence of Arabia", year: 1962 },
  { title: "Double Indemnity", year: 1944 },
  {
    title: "Eternal Sunshine of the Spotless Mind",
    year: 2004,
  },
  { title: "Amadeus", year: 1984 },
  { title: "To Kill a Mockingbird", year: 1962 },
  { title: "Toy Story 3", year: 2010 },
  { title: "Logan", year: 2017 },
  { title: "Full Metal Jacket", year: 1987 },
  { title: "Dangal", year: 2016 },
  { title: "The Sting", year: 1973 },
  { title: "2001: A Space Odyssey", year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: "Toy Story", year: 1995 },
  { title: "Bicycle Thieves", year: 1948 },
  { title: "The Kid", year: 1921 },
  { title: "Inglourious Basterds", year: 2009 },
  { title: "Snatch", year: 2000 },
  { title: "3 Idiots", year: 2009 },
  { title: "Monty Python and the Holy Grail", year: 1975 },
];

export default CrearActividad;
