import React, { useMemo, useEffect } from "react";
import { Box } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import axios from "axios";
import { useTheme } from "@mui/material";

const LineGraph = (dato) => {
  const theme = useTheme();
  const [ejecutar, setEjecutar] = React.useState(true);
  const [beneficiario, setBeneficiario] = React.useState([]);
  var fechaInicio = dato.fecha;
  var categoria = dato.categoria;
  const [fechaFin, setFechaFin] = React.useState("");
  const [tabla, setTabla] = React.useState("niñoscinco");
  const [endpoint, setEnpoint] = React.useState("meses");

  useEffect(() => {
    if (fechaInicio == "2022-01-01") {
      setFechaFin("2022-12-31");
      setEjecutar(true);
    } else {
      if(fechaInicio == "2023-01-01"){
        setFechaFin("2023-12-31");
      setEjecutar(true);
      }else{
        setFechaFin("2024-12-31");
        setEjecutar(true);
      }
      
    }

    if (categoria == "Menores") {
      setTabla("niñoscinco");
      setEnpoint("meses");
    }
    if (categoria == "MEL") {
      setTabla("mel");
      setEnpoint("meses");
    }
    if (categoria == "Desparasitaciones") {
      setTabla("desparasitaciones");
      setEnpoint("mesesDes");
    }
  }, [fechaInicio, categoria]);

  const data = [
    {
      id: "japan",
      color: "hsl(325, 70%, 50%)",
      data: [
        {
          x: "plane",
          y: 45,
        },
        {
          x: "helicopter",
          y: 49,
        },
        {
          x: "boat",
          y: 41,
        },
        {
          x: "train",
          y: 238,
        },
        {
          x: "subway",
          y: 201,
        },
        {
          x: "bus",
          y: 220,
        },
        {
          x: "car",
          y: 215,
        },
        {
          x: "moto",
          y: 241,
        },
        {
          x: "bicycle",
          y: 39,
        },
        {
          x: "horse",
          y: 54,
        },
        {
          x: "skateboard",
          y: 199,
        },
        {
          x: "others",
          y: 1,
        },
      ],
    },
    {
      id: "france",
      color: "hsl(147, 70%, 50%)",
      data: [
        {
          x: "plane",
          y: 255,
        },
        {
          x: "helicopter",
          y: 236,
        },
        {
          x: "boat",
          y: 274,
        },
        {
          x: "train",
          y: 61,
        },
        {
          x: "subway",
          y: 184,
        },
        {
          x: "bus",
          y: 210,
        },
        {
          x: "car",
          y: 82,
        },
        {
          x: "moto",
          y: 124,
        },
        {
          x: "bicycle",
          y: 144,
        },
        {
          x: "horse",
          y: 297,
        },
        {
          x: "skateboard",
          y: 230,
        },
        {
          x: "others",
          y: 122,
        },
      ],
    },
    {
      id: "us",
      color: "hsl(339, 70%, 50%)",
      data: [
        {
          x: "plane",
          y: 284,
        },
        {
          x: "helicopter",
          y: 104,
        },
        {
          x: "boat",
          y: 194,
        },
        {
          x: "train",
          y: 77,
        },
        {
          x: "subway",
          y: 233,
        },
        {
          x: "bus",
          y: 255,
        },
        {
          x: "car",
          y: 46,
        },
        {
          x: "moto",
          y: 47,
        },
        {
          x: "bicycle",
          y: 40,
        },
        {
          x: "horse",
          y: 286,
        },
        {
          x: "skateboard",
          y: 202,
        },
        {
          x: "others",
          y: 136,
        },
      ],
    },
    {
      id: "germany",
      color: "hsl(165, 70%, 50%)",
      data: [
        {
          x: "plane",
          y: 170,
        },
        {
          x: "helicopter",
          y: 92,
        },
        {
          x: "boat",
          y: 45,
        },
        {
          x: "train",
          y: 270,
        },
        {
          x: "subway",
          y: 225,
        },
        {
          x: "bus",
          y: 195,
        },
        {
          x: "car",
          y: 177,
        },
        {
          x: "moto",
          y: 208,
        },
        {
          x: "bicycle",
          y: 68,
        },
        {
          x: "horse",
          y: 74,
        },
        {
          x: "skateboard",
          y: 51,
        },
        {
          x: "others",
          y: 94,
        },
      ],
    },
    {
      id: "norway",
      color: "hsl(136, 70%, 50%)",
      data: [
        {
          x: "plane",
          y: 35,
        },
        {
          x: "helicopter",
          y: 102,
        },
        {
          x: "boat",
          y: 52,
        },
        {
          x: "train",
          y: 123,
        },
        {
          x: "subway",
          y: 284,
        },
        {
          x: "bus",
          y: 272,
        },
        {
          x: "car",
          y: 24,
        },
        {
          x: "moto",
          y: 173,
        },
        {
          x: "bicycle",
          y: 298,
        },
        {
          x: "horse",
          y: 76,
        },
        {
          x: "skateboard",
          y: 201,
        },
        {
          x: "others",
          y: 48,
        },
      ],
    },
  ];

  const beneficiarios = async () => {
    setEjecutar(false);
    try {
      const { data1 } = await axios
        .get(`/${endpoint}/`, {
          params: {
            fechaInicio: fechaInicio,
            fechaFin: fechaFin,
            tabla: tabla,
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setBeneficiario(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  console.log(beneficiario);
  if (ejecutar) {
    if (fechaInicio == "2022-01-01") {
      setFechaFin("2022-12-31");
    } else {
      if(fechaInicio == "2023-01-01"){
        setFechaFin("2023-12-31");
      }else{
        setFechaFin("2024-12-31");
      }
     
    }
    beneficiarios();
  }
  console.log(fechaInicio);
  console.log(fechaFin);

  const [totalSalesLine, totalUnitsLine] = useMemo(() => {
    if (ejecutar) return [];

    const monthlyData = beneficiario.total[0];
    let keys = [];
    keys = Object.keys(monthlyData);
    let value = [];
    value = Object.values(monthlyData);
    let container = [];
    let semanas = [];
    const usersByLikes = () => {
      for (let i = 0; i < Object.keys(monthlyData).length; i++) {
        let vacio = {};
        let j = i + 2;
        vacio.x = keys[i];
        vacio.y = Number(value[i]);

        container = vacio;

        semanas = semanas.concat(container);
      }
    };
    usersByLikes();
    let graf = semanas.slice(1, Object.keys(monthlyData).length);
    console.log(semanas.slice(1, Object.keys(monthlyData).length));
    const totalSalesLine = {
      id: "Total Atendidos",
      color: theme.palette.secondary.main,
      data: graf,
    };

    const totalUnitsLine = {
      id: "totalUnits",
      color: theme.palette.secondary[600],
      data: graf,
    };

    // Object.values(monthlyData).reduce(
    //   (acc, { month, totalSales, totalUnits }) => {
    //     const curSales = acc.sales + totalSales;
    //     const curUnits = acc.units + totalUnits;

    //     totalSalesLine.data = [
    //       ...totalSalesLine.data,
    //       { x: month, y: curSales },
    //     ];
    //     totalUnitsLine.data = [
    //       ...totalUnitsLine.data,
    //       { x: month, y: curUnits },
    //     ];

    //     return { sales: curSales, units: curUnits };
    //   },
    //   { sales: 0, units: 0 }
    // );

    return [[totalSalesLine], [totalUnitsLine]];
  }, [beneficiario]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <ResponsiveLine
      data={totalSalesLine}
      margin={{ top: 30, right: 110, bottom: 50, left: 60 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: true,
        reverse: false,
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Meses",
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Beneficiarios",
        legendOffset: -40,
        legendPosition: "middle",
      }}
      colors={{ scheme: "set1" }}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "top-to-bottom",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default LineGraph;