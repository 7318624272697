import React, { useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import EditIcon from "@mui/icons-material/Edit";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import HomeIcon from "@mui/icons-material/Home";
import CasesRoundedIcon from "@mui/icons-material/CasesRounded";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import GroupIcon from "@mui/icons-material/Group";
import PowerSettingNewIcon from "@mui/icons-material/PowerSettingsNew";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Restricted from "components/Restricted";
import clienteAxios from "../../config/clienteAxios";
import { useGetEquiposQuery } from "state/api";
import { TroubleshootSharp } from "@mui/icons-material";
import { set } from "date-fns";
import axios from "axios";

const AdminEquipos = () => {
  const [items, setItems] = React.useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const rol = items.rol;

  //Theme
  const theme = useTheme();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 5,
    p: 4,
  };

  const [equipos, setEquipos] = React.useState([]);
  const [equipo, setEquipo] = React.useState([]);
  const [ejecutar, setEjecutar] = React.useState(true);

  const [valueNombreEquipo, setNombreEquipo] = React.useState("");
  const [valueDescripcionEquipo, setDescripcionEquipo] = React.useState("");

  const [nombreNuevoEquipo, setNombreNuevoEquipo] = React.useState("");
  const [descripcionNueva, setDescripcionNueva] = React.useState("");

  const handleNombreNuevoEquipo = (event) => {
    setNombreNuevoEquipo(event.target.value);
  };

  const handleDescripcionNueva = (event) => {
    setDescripcionNueva(event.target.value);
  };

  const nav = useNavigate();

  function handleClickBreadCrumbs(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  //Modal Handlers
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openEdit, setOpenEdit] = React.useState(false);

  const handleOpenEdit = (row) => {
    setEquipo(row.original);
    setNombreNuevoEquipo(row.original.nombre);
    setDescripcionNueva(row.original.descripcion);
    setOpenEdit(true);
  };
  const handleCloseEdit = () => setOpenEdit(false);

  const [openAlertEdit, setOpenAlertEdit] = React.useState(false);
  const handleOpenAlertEdit = async (event) => {
    event.preventDefault();
    let dataform = new FormData(event.currentTarget);
    console.log(equipo.id);
    console.log(dataform.get("nombreNuevoEquipo"));
    console.log(dataform.get("descripcionNueva"));
    try {
      const { data1 } = await axios
        .put("/actualizarequipos", {
          id: equipo.id,
          nombre: dataform.get("nombreNuevoEquipo"),
          descripcion: dataform.get("descripcionNueva"),
        })
        .then(function (response) {
          // setAlerta({})
          //console.log(response.data.idToken)
          //localStorage.setItem('token',JSON.stringify(response.data) )
          //setAuth(data)
          setEjecutar(true);
          setOpenAlertEdit(true);
          handleCloseAlertEdit();
          handleCloseEdit();
        })
        .catch(function (error) {
          console.log("error");
          // document.getElementById(":r7:").value='';
        });
      //setOpen(false);
    } catch (error) {}
  };
  const handleCloseAlertEdit = () =>
    setTimeout(() => {
      setOpenAlertEdit(false);
    }, 2000);

  const [openAlert, setOpenAlert] = React.useState(false);
  const handleOpenAlert = () => {
    setOpenAlert(true);
    handleCloseAlert();
    handleClose();
  };
  const handleCloseAlert = () =>
    setTimeout(() => {
      setOpenAlert(false);
    }, 2000);

  //DropDown Button
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openDropDown = Boolean(anchorEl);
  const handleDropDown = (event) => {
    setAnchorEl(event.currentTarget);
    console.log(event);
  };
  const handleEliminar = async (dato, mensaje) => {
    console.log(dato);

    console.log(mensaje);

    try {
      const { data1 } = await axios
        .put("/eliminarequipos", {
          id: dato.original.id,
          estado: mensaje,
        })
        .then(function (response) {
          setEjecutar(true);
        })
        .catch(function (error) {
          console.log("error");
        });
    } catch (error) {}
  };

  //Routes
  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };

  const handleLinkProjects = (event, message) => {
    if (message === "proyectos") {
      nav("/proyectos");
    }
  };

  const data = async () => {
    try {
      const { data1 } = await axios
        .get("/equipos/")
        .then(function (response) {
          // setAlerta({})

          setEquipos(response.data);
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  if (ejecutar) {
    data();
  }

  const handleLinkAdd = (event, message, dato) => {
    let pro = JSON.stringify(dato.original);
    if (message === "agregar") {
      console.log(dato, "hola");
      nav(`/agregarMiembros?id=${pro}`);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "nombre",
        header: "Nombre",
      },
      {
        accessorKey: "descripcion",
        header: "Descripcion",
      },
      {
        accessorKey: "estado",
        header: "Estado",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={{
              backgroundColor:
                cell.getValue() === "Activo" ? "green" : "firebrick",
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "9ch",
              p: "0.25rem",
            }}
          >
            {cell.getValue()}
          </Box>
        ),
      },
    ],
    []
  );
  const handleSubmit = async (event) => {
    event.preventDefault();
    let dataform = new FormData(event.currentTarget);

    try {
      const { data1 } = await axios
        .post("/crearequipos/", {
          nombre: dataform.get("nombreEquipo"),
          descripcion: dataform.get("descripcion"),
        })
        .then(function (response) {
          handleOpenAlert();
          setEjecutar(true);
          setNombreEquipo("");
          setDescripcionEquipo("");
        })
        .catch(function (error) {});

      console.log(data1);
    } catch (error) {}
  };
  console.log(equipos);
  if (equipos == "")
    return (
      <Container component="main" maxWidth="xs">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );

  return (
    <Box m="1.5rem 2.5rem">
      {rol === "1" ||
      rol === "2" ||
      rol === "6" ||
      rol === "7" ||
      rol === "10" ? (
        <>
          <Box
            role="presentation"
            onClick={handleClickBreadCrumbs}
            sx={{ mb: 3 }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/inicio"
                onClick={(event) => handleLinkHome(event, "home")}
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Inicio
              </Link>
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/proyectos"
                onClick={(event) => handleLinkProjects(event, "proyectos")}
              >
                <CasesRoundedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Proyectos
              </Link>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <GroupIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Administrar Equipos
              </Typography>
            </Breadcrumbs>
            <Typography variant="h3" sx={{ fontWeight: "bold", mt: 2 }}>
              Administrar Equipos
            </Typography>
          </Box>
          <Box
            gridColumn="span 2"
            gridRow="span 1"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p="1rem 1rem"
            flex="1 1 100%"
            borderRadius="0.55rem"
          >
            <CssBaseline />
            <Container>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={style}
                >
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{ mb: 2, fontWeight: "bold" }}
                  >
                    Crear Equipo
                  </Typography>
                  <Divider />
                  <Grid container spacing={2} mt={2} mb={3}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        name="nombreEquipo"
                        required
                        fullWidth
                        id="nombreEquipo"
                        label="Nombre de Equipo"
                        value={valueNombreEquipo}
                        onChange={(event) =>
                          setNombreEquipo(event.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        required
                        fullWidth
                        id="descripcion"
                        label="Descripción"
                        name="descripcion"
                        value={valueDescripcionEquipo}
                        onChange={(event) =>
                          setDescripcionEquipo(event.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    container
                    sx={{ mt: 2 }}
                    spacing={1}
                    justifyContent="center"
                  >
                    <Grid item sm={6} xs={6}>
                      <Button
                        variant="contained"
                        color="error"
                        size="large"
                        fullWidth
                        onClick={handleClose}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ bgcolor: "teal", color: "white" }}
                        size="large"
                        disabled={!valueNombreEquipo || !valueDescripcionEquipo}
                        fullWidth
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
              <Box
                gridColumn="span 8"
                gridRow="span 3"
                backgroundColor={theme.palette.background.alt}
                p="1.5rem"
                borderRadius="1.5rem"
                mt="5px"
                height="fit-content"
                sx={{ boxShadow: 4 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      Listado de Equipos
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box
                      container
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <Button
                        variant="contained"
                        startIcon={<GroupAddIcon />}
                        sx={{ bgcolor: "teal", color: "white" }}
                        onClick={handleOpen}
                      >
                        Nuevo Equipo
                      </Button>
                    </Box>
                  </Grid>
                </Grid>

                <MaterialReactTable
                  columns={columns}
                  data={equipos}
                  localization={MRT_Localization_ES}
                  enableRowActions
                  positionActionsColumn="last"
                  muiTablePaperProps={{
                    elevation: 0,
                    sx: {
                      backgroundColor: theme.palette.background.alt,
                    },
                  }}
                  renderRowActionMenuItems={({ closeMenu, row }) => [
                    <MenuItem
                      onClick={(event) => {
                        handleOpenEdit(row);
                        closeMenu();
                      }}
                    >
                      <EditIcon sx={{ mr: 1 }} /> <b> Editar Equipo </b>
                    </MenuItem>,
                    <MenuItem
                      onClick={(event) => handleLinkAdd(event, "agregar", row)}
                    >
                      <PersonAddAltOutlinedIcon sx={{ mr: 1 }} />
                      <b> Añadir Miembros </b>
                    </MenuItem>,
                    <div>
                      {row.original.estado === "Activo" ? (
                        <MenuItem
                          onClick={(event) => {
                            handleEliminar(row, "Inactivo");
                            closeMenu();
                          }}
                          sx={{ color: "#FF6347", fontWeight: "bold" }}
                        >
                          <BlockOutlinedIcon sx={{ mr: 1 }} /> Desactivar Equipo
                        </MenuItem>
                      ) : (
                        <MenuItem
                          onClick={(event) => {
                            handleEliminar(row, "Activo");
                            closeMenu();
                          }}
                          sx={{ fontWeight: "bold" }}
                        >
                          <PowerSettingNewIcon sx={{ mr: 1 }} /> Activar Equipo
                        </MenuItem>
                      )}
                    </div>,
                  ]}
                  muiTopToolbarProps={{
                    sx: {
                      backgroundColor: theme.palette.background.alt,
                    },
                  }}
                  muiTableHeadCellProps={{
                    align: "center",
                    sx: {
                      backgroundColor: theme.palette.background.alt,
                    },
                  }}
                  muiBottomToolbarProps={{
                    sx: {
                      backgroundColor: theme.palette.background.alt,
                    },
                  }}
                  muiTableBodyProps={{
                    sx: {
                      "& tr:nth-of-type(odd)": {
                        backgroundColor: theme.palette.background.light,
                      },
                    },
                  }}
                  muiTableBodyCellProps={{
                    align: "center",
                  }}
                  initialState={{
                    pagination: {
                      pageSize: 20,
                      pageIndex: 0,
                    },
                  }}
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                    showFirstButton: false,
                    showLastButton: false,
                    SelectProps: {
                      native: true,
                    },
                    labelRowsPerPage: "Número de filas visibles",
                  }}
                />
                <Modal
                  open={openAlert}
                  onClose={handleCloseAlert}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography id="modal-modal-title" className="text-center">
                      <TaskAltOutlinedIcon
                        color="success"
                        sx={{ fontWeight: "bold", mt: 3, fontSize: 80 }}
                      />
                    </Typography>
                    <Typography
                      id="modal-modal-description"
                      className="text-center"
                      sx={{ mt: 2, fontSize: 20, fontWeight: "bold" }}
                    >
                      ¡El equipo se ha creado exitosamente!
                    </Typography>
                  </Box>
                </Modal>
                <Modal
                  open={openEdit}
                  onClose={handleCloseEdit}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleOpenAlertEdit}
                    sx={style}
                  >
                    <Typography variant="h4" component="div" sx={{ mb: 2 }}>
                      <b>Editar:</b> {equipo.nombre}
                    </Typography>
                    <Divider />
                    <Grid container spacing={2} mt={2} mb={3}>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          name="nombreNuevoEquipo"
                          required
                          fullWidth
                          id="nombreNuevoEquipo"
                          label="Nombre de Equipo"
                          value={nombreNuevoEquipo}
                          onChange={handleNombreNuevoEquipo}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          required
                          fullWidth
                          id="descripcionNueva"
                          label="Descripción"
                          name="descripcionNueva"
                          value={descripcionNueva}
                          onChange={handleDescripcionNueva}
                        />
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid
                      container
                      sx={{ mt: 2 }}
                      spacing={1}
                      justifyContent="center"
                    >
                      <Grid item sm={6} xs={6}>
                        <Button
                          variant="contained"
                          color="error"
                          size="large"
                          fullWidth
                          onClick={handleCloseEdit}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item sm={6} xs={6}>
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{ bgcolor: "teal", color: "white" }}
                          size="large"
                          disabled={!nombreNuevoEquipo || !descripcionNueva}
                          fullWidth
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>

                <Modal
                  open={openAlertEdit}
                  onClose={handleCloseAlertEdit}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography id="modal-modal-title" className="text-center">
                      <TaskAltOutlinedIcon
                        color="success"
                        sx={{ fontWeight: "bold", fontSize: 80 }}
                      />
                    </Typography>
                    <Typography
                      id="modal-modal-description"
                      className="text-center"
                      sx={{ mt: 2, fontSize: 20, fontWeight: "bold" }}
                    >
                      ¡Equipo actualizado exitosamente!
                    </Typography>
                  </Box>
                </Modal>
              </Box>
            </Container>
          </Box>
        </>
      ) : (
        <Restricted />
      )}
    </Box>
  );
};

export default AdminEquipos;
