import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import { OutlinedInput, ListSubheader, useMediaQuery } from "@mui/material";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import PropTypes from "prop-types";
import { IMaskInput } from "react-imask";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import "dayjs/locale/es";
import logo from "assets/paluz-logo.png";
import clienteAxios from "../../config/clienteAxios";
import { useGetProcatQuery, useGetCategoriasQuery } from "state/api";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import PersonalForm from "./personalForm";
import EnsayoForm from "./ensayoForm";
import Review from "./review";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import axios from "axios";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {" © "}
      {new Date().getFullYear()}
      {" - "}
      <Link color="inherit" href="https://paluz.org/">
        PALUZ
      </Link>
    </Typography>
  );
}

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#000) 000-0000"
      definitions={{
        "#": /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function Postulacion() {
  const [count, setCount] = React.useState(false);
  const [mensaje, setMensaje] = React.useState("");

  const handleCount = (dato, text) => {
    if (dato == 1) {
      setCount(true);
      setMensaje(text);
    } else {
      setCount(false);
    }
  };

  const handleName = ( text) => {
    setValueName(text);
  };

  //Theme
  const theme = createTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //Modal Styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "90%" : 450,
    bgcolor: "background.paper",
    border: "2px solid #000",
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
  };

  const steps = [
    "Datos personales",
    "¿Por qué quieres ser parte de PALUZ?",
    "Resumen",
  ];  
  
  const [valueName, setValueName] = useState("");
  const [valueApellido, setValueApellido] = useState("");
  const [valueCedula, setValueCedula] = useState("");
  const [valueDireccion, setValueDireccion] = useState("");
  const [valueCorreo, setValueCorreo] = useState("");
  const [values, setValues] = React.useState({
    textmask: "",
  });
  const [ocup, setOcup] = React.useState("");

  const oracionFormato = (string) => {
    return string
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  //DatePicker Value
  const [value, setValue] = React.useState(null);

  const handleChangeTel = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
  };

  const datos={valueName,valueApellido,valueCedula,valueDireccion,valueCorreo,values,ocup,value,mensaje}
const sets ={setValues,setValueName,setValueApellido,setValueCedula,setValueCorreo,setValueDireccion,setOcup,setValue}
  function getStepContent(step) {
    switch (step) {
      case 0:
        return <PersonalForm  sets={sets} datos={datos}/>;
      case 1:
        return <EnsayoForm handleCount={handleCount} mensaje={mensaje} />;
      case 2:
        return <Review datos={datos} />;
      default:
        throw new Error("Sección Desconocida");
    }
  }

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    if(activeStep === steps.length - 1){
      console.log('pasa')
      handleSubmit();
    }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const [ejecutar, setEjecutar] = React.useState(true);
  const [data, setData] = useState([]);
  const [pro, setPro] = useState([]);

  const catPro = async () => {
    try {
      const { data2 } = await axios
        .get("/profesionescat")
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setData(response.data);
          setEjecutar(false);

          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const cat = async () => {
    try {
      const { data2 } = await axios
        .get("/categorias")
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setPro(response.data);
          setEjecutar(false);

          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const handleSubmit = async (event) => {


console.log('paso1')
    try {
      const { data1 } = await axios
        .post("/crearpostulacion/", {
          nombres: valueName,
          apellidos: valueApellido,
          cedula: valueCedula,
          correo: valueCorreo,
          telefono: values.textmask,
          fnacimiento: value,
          ocupacion: ocup,
          direccion: valueDireccion,
          motivo:mensaje
        })
        .then(function (response) {
          console.log(response);
          setAlerta({});
          event.target.reset();
          setOcup("");
          setAlertaF(false);
          setAlerta(true);
          setTimeout(function () {
            setAlerta(false);
            nav("/info");
          }, 2000);
          handleDateChange(null);
        })
        /*.then(function (response) {
          nav("/");
       })*/
        .catch(function (error) {
          event.preventDefault();
          console.log("error");
          // document.getElementById(":r7:").value='';
          setAlertaF(true);
          setTimeout(function () {
            setAlertaF(false);
          }, 3000);
          setAlerta(false);
        });

      console.log(data1);
    } catch (error) {}
  };

  const nav = useNavigate();

  const [alerta, setAlerta] = useState("");
  const [alertaF, setAlertaF] = useState("");
 
  const [date, setDate] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);

  // Loader
  const [openLoader, setOpenLoader] = React.useState(false);
  const handleCloseLoader = () => {
    setOpenLoader(false);
  };
  const handleOpenLoader = () => {
    setOpenLoader(true);
    /*setTimeout(function(){
      setOpenLoader(false);
  }, 3000);*/
  };

  const handleLinkClick = (event, message) => {
    if (message === "inicio") {
      nav("/");
    }
    console.log(event.currentTarget);
    console.log(message);
  };

  //Modal Handlers
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (ejecutar) {
    catPro();
    cat();
  }
  if (!data || !pro)
    return (
      <Container component="main" maxWidth="xs">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );

  return (
    <div className="bg-img bg-div">
      <ThemeProvider theme={theme}>
        <div style={{ position: "relative", marginTop: 5, marginRight: 5 }}>
          {alerta ? (
            <Alert
              variant="filled"
              severity="success"
              sx={{
                position: "fixed",
                top: 5,
                right: 5,
                zIndex: 999,
                borderRadius: 2,
                boxShadow: 4,
              }}
            >
              ¡Usuario creado exitosamente!
            </Alert>
          ) : (
            ""
          )}
          {alertaF ? (
            <Alert
              variant="filled"
              severity="error"
              sx={{
                position: "fixed",
                top: 5,
                right: 5,
                zIndex: 999,
                borderRadius: 2,
                boxShadow: 4,
              }}
            >
              ¡No se pudo registrar el usuario!
            </Alert>
          ) : (
            ""
          )}
        </div>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              display="flex"
              component="img"
              alt="logo"
              src={logo}
              height="70px"
              width="240px"
              className="mb-3 img-responsive"
              maxWidth="sm"
            ></Box>
            <Typography component="h1" variant="h4">
              ¡Postúlate!
            </Typography>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              <Stepper activeStep={activeStep} sx={{ pt: 2, pb: 5 }}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography className="text-center" mb={2}>
                    <CheckCircleOutlinedIcon
                      color="success"
                      sx={{ fontSize: 65 }}
                    />
                  </Typography>
                  <Typography
                    className="text-center"
                    variant="h5"
                    gutterBottom
                    mb={2}
                  >
                    <b>¡Postulación Enviada!</b>
                  </Typography>
                  <Typography
                    className="text-center"
                    variant="subtitle1"
                    mt={1}
                  >
                    Nuestro personal de Gestión Humana revisará tu solicitud,
                    en breve recibirás un <b>correo electrónico</b> con el
                    veredicto.
                  </Typography>
                  <Typography
                    className="text-center"
                    variant="h6"
                    mt={2}
                  >
                    <b>¡Gracias por postularte!</b>
                  </Typography>
                  <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    sx={{ mt: 4, mb: 2, bgcolor: "teal" }}
                    onClick={(event) => handleLinkClick(event, "inicio")}
                  >
                    Ir al inicio
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                  <Grid container>
                    <Grid item sm={6}>
                      <Box
                        sx={{ display: "flex", justifyContent: "flex-start" }}
                      >
                        <Button
                          variant="contained"
                          color="error"
                          sx={{ mt: 5, ml: 1 }}
                          onClick={handleOpen}
                        >
                          Salir
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item sm={6}>
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        {activeStep !== 0 && (
                          <Button onClick={handleBack} sx={{ mt: 5, ml: 1 }}>
                            Atrás
                          </Button>
                        )}

                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 5, ml: 1, bgcolor: "teal", color: "white" }}
                          disabled={activeStep === 1 && count === false}
                        >
                          {activeStep === steps.length - 1
                            ? "Enviar"
                            : "Siguiente"}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </Paper>
          </Box>
          <Copyright sx={{ mt: 1, mb: 3 }} />
        </Container>
      </ThemeProvider>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slotProps={{ backdrop: { style: { opacity: 0.2 } } }}
      >
        <Box sx={style}>
          <Typography variant="h5" className="text-center" sx={{ mb: 3 }}>
            ¿Está seguro de cancelar esta operación?
          </Typography>
          <Divider sx={{ mt: 2 }} />
          <Grid container sx={{ mt: 2 }} spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button variant="contained" color="error" onClick={handleClose}>
                No
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{ bgcolor: "teal", color: "white" }}
                onClick={(event) => handleLinkClick(event, "inicio")}
              >
                Si
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
