import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import BlockIcon from "@mui/icons-material/Block";
import logo from "assets/paluz-logo.png";

//Footer
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {" © "}
      {new Date().getFullYear()}
      {" - "}
      <Link color="inherit" href="https://paluz.org/">
        PALUZ
      </Link>
    </Typography>
  );
}

//Theme
const theme = createTheme();

const ErrorPage = () => {
  //Navigation
  const nav = useNavigate();

  //Links Handlers
  const handleLinkClick = (event, message) => {
    if (message === "home") {
      nav("/");
    }
  };

  return (
    <div className="bg-img bg-div">
      <ThemeProvider theme={theme}>
        <Container component="main">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 6,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              display="flex"
              component="img"
              alt="logo"
              src={logo}
              height="80px"
              width="275px"
              mb={4}
            ></Box>
            <Typography align="center" component="h1" variant="h5" mt={5}>
              <BlockIcon style={{ fontSize: 115, color: "firebrick" }} />
            </Typography>
            <Typography component="h1" variant="h4" mt={4}>
              <strong>¡Acceso Restringido!</strong>
            </Typography>
            <Box mt={5}>
              <Typography align="center" component="h1" variant="h5">
                Debes iniciar sesión para visualizar esta página.
              </Typography>
            </Box>
            <Box component="form" noValidate sx={{ mt: 3 }}>
              <Button
                fullWidth
                variant="contained"
                size="large"
                sx={{ mt: 4, mb: 2, bgcolor: "teal" }}
                onClick={(event) => handleLinkClick(event, "home")}
              >
                Volver al inicio
              </Button>
              <Grid container justifyContent="center">
                <Grid item></Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default ErrorPage;
