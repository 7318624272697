import React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

const MyMarker = ({ text, tooltip, $hover }) => {
  const handleClick = () => {
    console.log(`You clicked on ${tooltip}`);
  };

  const positionRef = React.useRef({
    x: 0,
    y: 0,
  });
  const popperRef = React.useRef(null);
  const areaRef = React.useRef(null);

  const handleMouseMove = (event) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  return (
    <div
      className={$hover /*? "circle hover" : "circle"*/}
      onClick={handleClick}
    >
      <Tooltip
        title={tooltip}
        placement="top"
        arrow
        PopperProps={{
          popperRef,
          anchorEl: {
            getBoundingClientRect: () => {
              return new DOMRect(
                positionRef.current.x,
                areaRef.current.getBoundingClientRect().y,
                0,
                0
              );
            },
          },
        }}
      >
        <Box ref={areaRef} onMouseMove={handleMouseMove}>
          <span className="circleText" /*title={tooltip}*/>{text}</span>
        </Box>
      </Tooltip>
    </div>
  );
};

export default MyMarker;
