import React, { useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Box, Button, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import axios from "axios";

const TransConvoList = (datos) => {
  console.log(datos.datos.idproyectos);
  const nav = useNavigate();

  //Theme
  const theme = useTheme();

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
      },
      {
        id:"fecha",
        accessorKey: "fecha",
        header: "Fecha",
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
      },
    ],
    []
  );

  const data = [
    {
      id: "1",
      firstName: "Dylan",
      middleName: "Sprouse",
      lastName: "Murray",
      address: "261 Erdman Ford",
      city: "East Daphne",
      state: "Kentucky",
      country: "United States",
    },
    {
      id: "2",
      firstName: "Raquel",
      middleName: "Hakeem",
      lastName: "Kohler",
      address: "769 Dominic Grove",
      city: "Vancouver",
      state: "British Columbia",
      country: "Canada",
    },
    {
      id: "3",
      firstName: "Ervin",
      middleName: "Kris",
      lastName: "Reinger",
      address: "566 Brakus Inlet",
      city: "South Linda",
      state: "West Virginia",
      country: "United States",
    },
    {
      id: "4",
      firstName: "Brittany",
      middleName: "Kathryn",
      lastName: "McCullough",
      address: "722 Emie Stream",
      city: "Lincoln",
      state: "Nebraska",
      country: "United States",
    },
    {
      id: "5",
      firstName: "Branson",
      middleName: "John",
      lastName: "Frami",
      address: "32188 Larkin Turnpike",
      city: "Charleston",
      state: "South Carolina",
      country: "United States",
    },
    {
      id: "6",
      firstName: "Branson",
      middleName: "John",
      lastName: "Frami",
      address: "32188 Larkin Turnpike",
      city: "Charleston",
      state: "South Carolina",
      country: "United States",
    },
    {
      id: "7",
      firstName: "Branson",
      middleName: "John",
      lastName: "Frami",
      address: "32188 Larkin Turnpike",
      city: "Charleston",
      state: "South Carolina",
      country: "United States",
    },
    {
      id: "8",
      firstName: "Branson",
      middleName: "John",
      lastName: "Frami",
      address: "32188 Larkin Turnpike",
      city: "Charleston",
      state: "South Carolina",
      country: "United States",
    },
    {
      id: "9",
      firstName: "Branson",
      middleName: "John",
      lastName: "Frami",
      address: "32188 Larkin Turnpike",
      city: "Charleston",
      state: "South Carolina",
      country: "United States",
    },
    {
      id: "10",
      firstName: "Branson",
      middleName: "John",
      lastName: "Frami",
      address: "32188 Larkin Turnpike",
      city: "Charleston",
      state: "South Carolina",
      country: "United States",
    },
    {
      id: "11",
      firstName: "Branson",
      middleName: "John",
      lastName: "Frami",
      address: "32188 Larkin Turnpike",
      city: "Charleston",
      state: "South Carolina",
      country: "United States",
    },
  ];

  const handleLinkTrans = (event, message, row) => {
    let pro = JSON.stringify(row.original);
    if (message === "trans") {
      nav(`/listaTranscripcion?prop=${pro}`);
    }
  };

  const [dataF, setDataF] = React.useState([]);
  const [ejecutar, setEjecutar] = React.useState(true);
  const convocatoriasF = async () => {
    try {
      const { data1 } = await axios
        .get("/convocatoriaproyectos/", {
          params: {
            id: 2,
          idProyecto:datos.datos.idproyectos
          },
        })
        .then(function (response) {
          // setAlerta({})

          setDataF(response.data);
          console.log(response.data,"convopro")
          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log(error);
          return;
        });
    } catch (error) {
      return;
    }
  };

  if (ejecutar) {
    convocatoriasF();
  }

//   const proyect = dataF;

//   for (let i = 0; i < proyect.length; i++){
//     let tipoAct = proyect[i].actividad;
//     for (let j = 0; j < tipoAct.length; j++) {
//       let vacio = 0;
//       if (tipoAct[j] === "Jornada de Nutrición") {
//         vacio = 1;
//         proyect[i].tipoActividad=vacio
//       } else {
//         if (tipoAct[j] === "Jornada Médica") {
//           vacio = 2;
//           proyect[i].tipoActividad=vacio
//         }else{
//           proyect[i].tipoActividad=vacio
//         }
//       }
  
  
//     }
//   }
 
//  var datos= proyect.filter((dato) => dato.tipoActividad != 0);
  

  return (
    <div>
      <MaterialReactTable
        columns={columns}
        data={dataF}
        enableFullScreenToggle={false}
        localization={MRT_Localization_ES}
        enableRowActions
        positionActionsColumn="first"
        renderRowActionMenuItems={({ row, closeMenu }) => [
          <MenuItem
            onClick={(event) => handleLinkTrans(event, "trans", row, closeMenu)}
          >
            <DriveFileRenameOutlineIcon sx={{ mr: 1 }} />
            <b>Transcribir</b>
          </MenuItem>,
        ]}
        muiTopToolbarProps={{
          sx: {
            backgroundColor: theme.palette.background.alt,
          },
        }}
        muiTableHeadCellProps={{
          align: "center",
          sx: {
            backgroundColor: theme.palette.background.alt,
          },
        }}
        muiBottomToolbarProps={{
          sx: {
            backgroundColor: theme.palette.background.alt,
          },
        }}
        muiTableBodyProps={{
          sx: {
            "& tr:nth-of-type(odd)": {
              backgroundColor: theme.palette.background.light,
            },
          },
        }}
        muiTableBodyCellProps={{
          align: "center",
        }}
        muiTablePaperProps={{
          elevation: 0, //change the mui box shadow
          //customize paper styles
        }}
        initialState={{
          pagination: {
            pageSize: 3,
            pageIndex: 0,
          },
          sorting: [
            {
              id: "fecha",
              desc: true,
            },
          ],
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
          showFirstButton: false,
          showLastButton: false,
          SelectProps: {
            native: true,
          },
          labelRowsPerPage: "Número de filas visibles",
        }}
      />
    </div>
  );
};

export default TransConvoList;
