import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme, useMediaQuery } from "@mui/material";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import FamilyRestroomOutlinedIcon from "@mui/icons-material/FamilyRestroomOutlined";
import ChildCareOutlinedIcon from "@mui/icons-material/ChildCareOutlined";
import PregnantWomanOutlinedIcon from "@mui/icons-material/PregnantWomanOutlined";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import Filter1OutlinedIcon from "@mui/icons-material/Filter1Outlined";
import Filter2OutlinedIcon from "@mui/icons-material/Filter2Outlined";
import Filter3OutlinedIcon from "@mui/icons-material/Filter3Outlined";
import Filter4OutlinedIcon from "@mui/icons-material/Filter4Outlined";
import Filter5OutlinedIcon from "@mui/icons-material/Filter5Outlined";
import Filter6OutlinedIcon from "@mui/icons-material/Filter6Outlined";
import Filter7OutlinedIcon from "@mui/icons-material/Filter7Outlined";
import Filter8OutlinedIcon from "@mui/icons-material/Filter8Outlined";
import EditIcon from "@mui/icons-material/Edit";
import SearchPage from "components/Search/SearchPage";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { LocalizationProvider } from "@mui/x-date-pickers";
//import { esES } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import "dayjs/locale/es";
import Restricted from "components/Restricted";
import axios from "axios";
import {
  CheckCircleOutline,
  ContentPasteGo,
  ContentPasteSearch,
  PendingOutlined,
  Receipt,
  ReceiptOutlined,
  SentimentVeryDissatisfied,
} from "@mui/icons-material";
import Pending from "@mui/icons-material/Pending";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Beneficiarios = () => {
  const nav = useNavigate();

  const [items, setItems] = React.useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const rol = items.rol;

  //Theme
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //DatePickers States
  const [fechaEvaluacion, setFechaEvaluacion] = React.useState(null);
  const [fechaEvaluacionEditar, setFechaEvaluacionEditar] =
    React.useState(null);
  const [fechaEvalMicro, setFechaEvalMicro] = React.useState(null);
  const [fechaEvalMicroEditar, setFechaEvalMicroEditar] = React.useState(null);
  const [fechaEvalMel, setFechaEvalMel] = React.useState(null);
  const [fechaEvalMelEditar, setFechaEvalMelEditar] = React.useState(null);

  //Modal Styles
  const style = {
    position: "inherit",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "90%" : "40%",
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    borderRadius: 4,
    boxShadow: 1,
    p: 4,
  };

  const styleFichaM5 = {
    position: "inherit",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "90%" : "45%",
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    borderRadius: 4,
    boxShadow: 1,
    p: 4,
  };

  //Select Handler
  const [optBenef, setOptBenef] = React.useState("");
  const handleOptBenef = (event) => {
    setOptBenef(event.target.value);
    // if (event.target.value == "Control") {
    //   //////Totales/////
    //   let samTotal = dataSam;
    //   let microTotal = dataMicro;
    //   let melTotal = dataMel;
    //   ///////////////////////
    //   let evalSeguimiento = 0;
    //   let evalIngreso = 0;
    //   let contador = 0;
    //   let indiceContador = "";
    //   let contadorSeguimiento = 0;
    //   let numerosSeguimientos = 0;
    //   ////////////////////////////SAM//////////////////////////////
    //   for (let i = 0; i < samTotal.length; i++) {
    //     numerosSeguimientos = 0;
    //     for (let j = 0; j < todosSeguimientos.length; j++) {
    //       if (samTotal[i].id == todosSeguimientos[j].idBeneficiario) {
    //         numerosSeguimientos = numerosSeguimientos + 1;
    //         samTotal[i].seguimientos = numerosSeguimientos;
    //         samTotal[i][todosSeguimientos[j].num_seguimiento] =
    //           todosSeguimientos[j].diagnostico_ingreso;

    //         if (
    //           todosSeguimientos[j].diagnostico_ingreso ==
    //           "Desnutrición Aguda Severa"
    //         ) {
    //           evalSeguimiento = 1;
    //         } else {
    //           if (
    //             todosSeguimientos[j].diagnostico_ingreso ==
    //             "Desnutrición Aguda Moderada"
    //           ) {
    //             evalSeguimiento = 2;
    //           } else {
    //             if (todosSeguimientos[j].diagnostico_ingreso == "Adecuado") {
    //               evalSeguimiento = 3;
    //             } else {
    //               if (todosSeguimientos[j].diagnostico_ingreso == "Soprepeso") {
    //                 evalSeguimiento = 4;
    //               } else {
    //                 evalSeguimiento = 5;
    //               }
    //             }
    //           }
    //         }
    //         if (
    //           samTotal[i].diagnostico_ingreso == "Desnutrición Aguda Severa"
    //         ) {
    //           evalIngreso = 1;
    //         } else {
    //           if (
    //             samTotal[i].diagnostico_ingreso == "Desnutrición Aguda Moderada"
    //           ) {
    //             evalIngreso = 2;
    //           } else {
    //             if (samTotal[i].diagnostico_ingreso == "Adecuado") {
    //               evalIngreso = 5;
    //             } else {
    //               if (samTotal[i].diagnostico_ingreso == "Soprepeso") {
    //                 evalIngreso = 3;
    //               } else {
    //                 evalIngreso = 4;
    //               }
    //             }
    //           }
    //         }
    //         if (evalSeguimiento > evalIngreso) {
    //           samTotal[i].evolucion = "Positiva";
    //         } else {
    //           samTotal[i].evolucion = "Negativa";
    //         }

    //         if (indiceContador == "") {
    //           if (todosSeguimientos[j].diagnostico_ingreso == "S/D") {
    //             indiceContador = j;
    //             contador = contador + 1;
    //           }
    //         } else {
    //           if (j == indiceContador + 1) {
    //             console.log(contador, "entro");
    //             contador = contador + 1;
    //           }
    //         }
    //         console.log(contador, "total");

    //         if ((todosSeguimientos[j].diagnostico_ingreso = "S/D")) {
    //           indiceContador = j;
    //           contadorSeguimiento = contadorSeguimiento + 1;
    //         }

    //         if (contadorSeguimiento < 4) {
    //           samTotal[i].adherencia = "No";
    //           if (contador >= 2) {
    //             samTotal[i].adherencia = "Deserto";
    //           }
    //         } else {
    //           samTotal[i].adherencia = "Si";
    //         }
    //       } else {
    //         samTotal[i].seguimientos = numerosSeguimientos;
    //       }
    //     }
    //   }
    //   ///////////////////////MICRO////////////////////////////////
    //   for (let i = 0; i < microTotal.length; i++) {
    //     numerosSeguimientos = 0;
    //     for (let j = 0; j < todosSeguimientos.length; j++) {
    //       if (microTotal[i].id == todosSeguimientos[j].idBeneficiario) {
    //         numerosSeguimientos = numerosSeguimientos + 1;
    //         microTotal[i].seguimientos = numerosSeguimientos;
    //         microTotal[i][todosSeguimientos[j].num_seguimiento] =
    //           todosSeguimientos[j].asistio;

    //         if (indiceContador == "") {
    //           if (todosSeguimientos[j].asistio == "No") {
    //             indiceContador = j;
    //             contador = contador + 1;
    //           }
    //         } else {
    //           if (j == indiceContador + 1) {
    //             console.log(contador, "entro");
    //             contador = contador + 1;
    //           }
    //         }
    //         console.log(contador, "total");

    //         if ((todosSeguimientos[j].asistio = "No")) {
    //           indiceContador = j;
    //           contadorSeguimiento = contadorSeguimiento + 1;
    //         }

    //         if (contadorSeguimiento < 2) {
    //           microTotal[i].adherencia = "No";
    //           if (contador >= 2) {
    //             microTotal[i].adherencia = "Deserto";
    //           }
    //         } else {
    //           microTotal[i].adherencia = "Si";
    //         }
    //       } else {
    //         microTotal[i].seguimientos = numerosSeguimientos;
    //       }
    //     }
    //   }
    //   //////////////////////////MEL///////////////////////////////////
    //   for (let i = 0; i < melTotal.length; i++) {
    //     numerosSeguimientos = 0;
    //     for (let j = 0; j < todosSeguimientos.length; j++) {
    //       if (melTotal[i].idmel == todosSeguimientos[j].idBeneficiario) {
    //         numerosSeguimientos = numerosSeguimientos + 1;
    //         melTotal[i].seguimientos = numerosSeguimientos;
    //         melTotal[i][todosSeguimientos[j].num_seguimiento] =
    //           todosSeguimientos[j].diagnostico_ingreso;

    //         if (todosSeguimientos[j].diagnostico_ingreso == "Bajo Peso") {
    //           evalSeguimiento = 1;
    //         } else {
    //           if (todosSeguimientos[j].diagnostico_ingreso == "Adecuado") {
    //             evalSeguimiento = 2;
    //           } else {
    //             if (todosSeguimientos[j].diagnostico_ingreso == "Soprepeso") {
    //               evalSeguimiento = 3;
    //             } else {
    //               evalSeguimiento = 4;
    //             }
    //           }
    //         }
    //         if (melTotal[i].diagnostico_ingreso == "Bajo Peso") {
    //           evalIngreso = 1;
    //         } else {
    //           if (melTotal[i].diagnostico_ingreso == "Adecuado") {
    //             evalIngreso = 2;
    //           } else {
    //             if (melTotal[i].diagnostico_ingreso == "Soprepeso") {
    //               evalIngreso = 3;
    //             } else {
    //               evalIngreso = 4;
    //             }
    //           }
    //         }
    //         if (evalSeguimiento > evalIngreso) {
    //           melTotal[i].evolucion = "Positiva";
    //         } else {
    //           melTotal[i].evolucion = "Negativa";
    //         }

    //         if (indiceContador == "") {
    //           if (todosSeguimientos[j].diagnostico_ingreso == "S/D") {
    //             indiceContador = j;
    //             contador = contador + 1;
    //           }
    //         } else {
    //           if (j == indiceContador + 1) {
    //             console.log(contador, "entro");
    //             contador = contador + 1;
    //           }
    //         }
    //         console.log(contador, "total");

    //         if ((todosSeguimientos[j].diagnostico_ingreso = "S/D")) {
    //           indiceContador = j;
    //           contadorSeguimiento = contadorSeguimiento + 1;
    //         }

    //         if (contadorSeguimiento < 2) {
    //           melTotal[i].adherencia = "No";
    //           if (contador >= 2) {
    //             melTotal[i].adherencia = "Deserto";
    //           }
    //         } else {
    //           melTotal[i].adherencia = "Si";
    //         }
    //       } else {
    //         melTotal[i].seguimientos = numerosSeguimientos;
    //       }
    //     }
    //   }
    //   ////////////////////////////////////////////////////////////////
    //   console.log(contadorSeguimiento, "CountSe");
    //   setDataSam([...samTotal]);
    //   setDataMicro([...microTotal]);
    //   setDataMel([...melTotal]);
    //   console.log(samTotal, "paso");
    //   console.log(todosSeguimientos, "paso");
    // }
  };

  const [estatusM5, setEstatusM5] = React.useState("");
  const handleEstatusM5 = (event) => {
    setEstatusM5(event.target.value);
  };

  const [estatusMicro, setEstatusMicro] = React.useState("");
  const handleEstatusMicro = (event) => {
    setEstatusMicro(event.target.value);
  };

  const [estatusMel, setEstatusMel] = React.useState("");
  const handleEstatusMel = (event) => {
    setEstatusMel(event.target.value);
  };

  const [estatusM5Editar, setEstatusM5Editar] = React.useState("");
  const handleEstatusM5Editar = (event) => {
    setEstatusM5Editar(event.target.value);
  };

  const [estatusMicroEditar, setEstatusMicroEditar] = React.useState("");
  const handleEstatusMicroEditar = (event) => {
    setEstatusMicroEditar(event.target.value);
  };

  const [estatusMelEditar, setEstatusMelEditar] = React.useState("");
  const handleEstatusMelEditar = (event) => {
    setEstatusMelEditar(event.target.value);
  };

  const handleSeguimientos = () => {
    //////Totales/////
    let samTotal = dataSam;
    let microTotal = dataMicro;
    let melTotal = dataMel;
    ///////////////////////
    let evalSeguimiento = 0;
    let evalIngreso = 0;
    let contador = 0;
    let indiceContador = "";
    let contadorSeguimiento = 0;
    let numerosSeguimientos = 0;

    ////////////////////////////SAM//////////////////////////////
    for (let i = 0; i < samTotal.length; i++) {
      numerosSeguimientos = 0;
      for (let j = 0; j < todosSeguimientos.length; j++) {
        if (samTotal[i].id == todosSeguimientos[j].idBeneficiario) {
          numerosSeguimientos = numerosSeguimientos + 1;
          samTotal[i].seguimientos = numerosSeguimientos;
          samTotal[i][todosSeguimientos[j].num_seguimiento] =
            todosSeguimientos[j].diagnostico_ingreso;

          if (
            todosSeguimientos[j].diagnostico_ingreso ==
            "Desnutrición Aguda Severa"
          ) {
            evalSeguimiento = 1;
          } else {
            if (
              todosSeguimientos[j].diagnostico_ingreso ==
              "Desnutrición Aguda Moderada"
            ) {
              evalSeguimiento = 2;
            } else {
              if (todosSeguimientos[j].diagnostico_ingreso == "Adecuado") {
                evalSeguimiento = 3;
              } else {
                if (todosSeguimientos[j].diagnostico_ingreso == "Soprepeso") {
                  evalSeguimiento = 4;
                } else {
                  evalSeguimiento = 5;
                }
              }
            }
          }
          if (samTotal[i].diagnostico_ingreso == "Desnutrición Aguda Severa") {
            evalIngreso = 1;
          } else {
            if (
              samTotal[i].diagnostico_ingreso == "Desnutrición Aguda Moderada"
            ) {
              evalIngreso = 2;
            } else {
              if (samTotal[i].diagnostico_ingreso == "Adecuado") {
                evalIngreso = 5;
              } else {
                if (samTotal[i].diagnostico_ingreso == "Soprepeso") {
                  evalIngreso = 3;
                } else {
                  evalIngreso = 4;
                }
              }
            }
          }
          if (evalSeguimiento > evalIngreso) {
            samTotal[i].evolucion = "Positiva";
          } else {
            samTotal[i].evolucion = "Negativa";
          }

          if (indiceContador == "") {
            if (todosSeguimientos[j].diagnostico_ingreso == "S/D") {
              indiceContador = j;
              contador = contador + 1;
            }
          } else {
            if (j == indiceContador + 1) {
              console.log(contador, "entro");
              contador = contador + 1;
            }
          }
          console.log(contador, "total");

          if ((todosSeguimientos[j].diagnostico_ingreso = "S/D")) {
            indiceContador = j;
            contadorSeguimiento = contadorSeguimiento + 1;
          }

          if (contadorSeguimiento < 4) {
            samTotal[i].adherencia = "No";
            if (contador >= 2) {
              samTotal[i].adherencia = "Deserto";
            }
          } else {
            samTotal[i].adherencia = "Si";
          }
        } else {
          samTotal[i].seguimientos = numerosSeguimientos;
        }
      }
    }
    ///////////////////////MICRO////////////////////////////////
    for (let i = 0; i < microTotal.length; i++) {
      numerosSeguimientos = 0;
      for (let j = 0; j < todosSeguimientos.length; j++) {
        if (microTotal[i].id == todosSeguimientos[j].idBeneficiario) {
          numerosSeguimientos = numerosSeguimientos + 1;
          microTotal[i].seguimientos = numerosSeguimientos;
          microTotal[i][todosSeguimientos[j].num_seguimiento] =
            todosSeguimientos[j].asistio;

          if (indiceContador == "") {
            if (todosSeguimientos[j].asistio == "No") {
              indiceContador = j;
              contador = contador + 1;
            }
          } else {
            if (j == indiceContador + 1) {
              console.log(contador, "entro");
              contador = contador + 1;
            }
          }
          console.log(contador, "total");

          if ((todosSeguimientos[j].asistio = "No")) {
            indiceContador = j;
            contadorSeguimiento = contadorSeguimiento + 1;
          }

          if (contadorSeguimiento < 2) {
            microTotal[i].adherencia = "No";
            if (contador >= 2) {
              microTotal[i].adherencia = "Desertó";
            }
          } else {
            microTotal[i].adherencia = "Si";
          }
        } else {
          microTotal[i].seguimientos = numerosSeguimientos;
        }
      }
    }
    //////////////////////////MEL///////////////////////////////////
    for (let i = 0; i < melTotal.length; i++) {
      numerosSeguimientos = 0;
      for (let j = 0; j < todosSeguimientos.length; j++) {
        if (melTotal[i].idmel == todosSeguimientos[j].idBeneficiario) {
          numerosSeguimientos = numerosSeguimientos + 1;
          melTotal[i].seguimientos = numerosSeguimientos;
          melTotal[i][todosSeguimientos[j].num_seguimiento] =
            todosSeguimientos[j].diagnostico_ingreso;

          if (todosSeguimientos[j].diagnostico_ingreso == "Bajo Peso") {
            evalSeguimiento = 1;
          } else {
            if (todosSeguimientos[j].diagnostico_ingreso == "Adecuado") {
              evalSeguimiento = 2;
            } else {
              if (todosSeguimientos[j].diagnostico_ingreso == "Soprepeso") {
                evalSeguimiento = 3;
              } else {
                evalSeguimiento = 4;
              }
            }
          }
          if (melTotal[i].diagnostico_ingreso == "Bajo Peso") {
            evalIngreso = 1;
          } else {
            if (melTotal[i].diagnostico_ingreso == "Adecuado") {
              evalIngreso = 2;
            } else {
              if (melTotal[i].diagnostico_ingreso == "Soprepeso") {
                evalIngreso = 3;
              } else {
                evalIngreso = 4;
              }
            }
          }
          if (evalSeguimiento > evalIngreso) {
            melTotal[i].evolucion = "Positiva";
          } else {
            melTotal[i].evolucion = "Negativa";
          }

          if (indiceContador == "") {
            if (todosSeguimientos[j].diagnostico_ingreso == "S/D") {
              indiceContador = j;
              contador = contador + 1;
            }
          } else {
            if (j == indiceContador + 1) {
              console.log(contador, "entro");
              contador = contador + 1;
            }
          }
          console.log(contador, "total");

          if ((todosSeguimientos[j].diagnostico_ingreso = "S/D")) {
            indiceContador = j;
            contadorSeguimiento = contadorSeguimiento + 1;
          }

          if (contadorSeguimiento < 2) {
            melTotal[i].adherencia = "No";
            if (contador >= 2) {
              melTotal[i].adherencia = "Desertó";
            }
          } else {
            melTotal[i].adherencia = "Si";
          }
        } else {
          melTotal[i].seguimientos = numerosSeguimientos;
        }
      }
    }
    ////////////////////////////////////////////////////////////////
    console.log(contadorSeguimiento, "CountSe");
    setDataSam([]);
    console.log(samTotal, "paso");
    console.log(todosSeguimientos, "paso");
  };

  const [optAsist, setOptAsist] = React.useState("");
  const handleOptAsist = (event) => {
    setOptAsist(event.target.value);
    if (event.target.value === "No") {
      setOptEdema("S/D");
      setPesoBenef("S/D");
      setTallaBenef("S/D");
      setPuntajeZ("S/D");
      setDiagPZ("S/D");
      setPB("S/D");
      setDiagPB("S/D");
      setDiagFinal("S/D");
    } else {
      if (event.target.value === "Si" || event.target.value === "") {
        setOptRazon("");
        setOptEdema("");
        setPesoBenef("");
        setTallaBenef("");
        setPuntajeZ("");
        setDiagPZ("");
        setPB("");
        setDiagPB("");
        setDiagFinal("");
      }
    }
  };

  const [optAsistMel, setOptAsistMel] = React.useState("");
  const handleOptAsistMel = (event) => {
    setOptAsistMel(event.target.value);
    if (event.target.value === "No") {
      setSemGest("S/D");
      setPesoMel("S/D");
      setTallaMel("S/D");
      setImc("S/D");
      setDiagImc("S/D");
      setPbMel("S/D");
      setDiagPbMel("S/D");
      setDiagFinalMel("S/D");
    } else {
      if (event.target.value === "Si" || event.target.value === "") {
        setOptRazonMel("S/D");
        setSemGest("");
        setPesoMel("");
        setTallaMel("");
        setImc("");
        setDiagImc("");
        setPbMel("");
        setDiagPbMel("");
        setDiagFinalMel("");
      }
    }
  };

  const [optRazon, setOptRazon] = React.useState("");
  const handleOptRazon = (event) => {
    setOptRazon(event.target.value);
  };

  const [optRazonMel, setOptRazonMel] = React.useState("");
  const handleOptRazonMel = (event) => {
    setOptRazonMel(event.target.value);
  };

  const [optEdema, setOptEdema] = React.useState("");
  const handleOptEdema = (event) => {
    setOptEdema(event.target.value);
    if (event.target.value == "Si") {
      setDiagFinal("Desnutrición Aguda Severa");
      setDiagPZ("S/D");
      setDiagPB("S/D");
      setOptEdema(event.target.value);
    } else {
      setOptEdema(event.target.value);
      setPB("");
      setPuntajeZ("");
      setDiagFinal("");
      setDiagPB("");
      setDiagPZ("");
    }
  };

  const [semGest, setSemGest] = useState("");
  const handleSemGest = (event) => {
    setSemGest(event.target.value);
  };

  const [optSeg, setOptSeg] = React.useState("");
  const handleOptSeg = (event) => {
    setOptSeg(event.target.value);
    if (event.target.value === "No") {
      setOptEsquema("S/D");
      setMotivoEsquema("S/D");
      setConsejeria("S/D");
      setOptMotivo("");
    } else {
      if (event.target.value === "Si" || event.target.value === "") {
        setOptEsquema("");
        setMotivoEsquema("");
        setConsejeria("");
        setOptMotivo("S/D");
      }
    }
  };

  const [optMotivo, setOptMotivo] = React.useState("");
  const handleOptMotivo = (event) => {
    setOptMotivo(event.target.value);
  };

  const [optEsquema, setOptEsquema] = React.useState("");
  const handleOptEsquema = (event) => {
    setOptEsquema(event.target.value);
  };

  const [consejeria, setConsejeria] = React.useState("");
  const handleConsejeria = (event) => {
    setConsejeria(event.target.value);
  };

  /////////////////////////////////////////////////////////////////////////

  const [optAsistEditar, setOptAsistEditar] = React.useState("");
  const handleOptAsistEditar = (event) => {
    setOptAsistEditar(event.target.value);
    if (event.target.value === "No") {
      setOptEdemaEditar("S/D");
      setPesoBenefEditar("S/D");
      setTallaBenefEditar("S/D");
      setPuntajeZEditar("S/D");
      setDiagPZEditar("S/D");
      setPBEditar("S/D");
      setDiagPBEditar("S/D");
      setDiagFinalEditar("S/D");
    } else {
      if (event.target.value === "Si" || event.target.value === "") {
        setOptRazonEditar("");
        setOptEdemaEditar("");
        setPesoBenefEditar("");
        setTallaBenefEditar("");
        setPuntajeZEditar("");
        setDiagPZEditar("");
        setPBEditar("");
        setDiagPBEditar("");
        setDiagFinalEditar("");
      }
    }
  };

  const [optRazonEditar, setOptRazonEditar] = React.useState("");
  const handleOptRazonEditar = (event) => {
    setOptRazonEditar(event.target.value);
  };

  const [optEdemaEditar, setOptEdemaEditar] = React.useState("");
  const handleOptEdemaEditar = (event) => {
    setOptEdemaEditar(event.target.value);
    if (event.target.value == "Si") {
      setDiagFinalEditar("Desnutrición Aguda Severa");
      setDiagPZEditar("S/D");
      setDiagPBEditar("S/D");
      setOptEdemaEditar(event.target.value);
    } else {
      setOptEdemaEditar(event.target.value);
      setPBEditar("");
      setPuntajeZEditar("");
      setDiagFinalEditar("");
      setDiagPBEditar("");
      setDiagPZEditar("");
    }
  };

  const [optSegEditar, setOptSegEditar] = React.useState("");
  const handleOptSegEditar = (event) => {
    setOptSegEditar(event.target.value);
    if (event.target.value === "No") {
      setOptEsquemaEditar("S/D");
      setMotivoEsquemaEditar("S/D");
      setConsejeriaEditar("S/D");
      setOptMotivoEditar("");
    } else {
      if (event.target.value === "Si" || event.target.value === "") {
        setOptEsquemaEditar("");
        setMotivoEsquemaEditar("");
        setConsejeriaEditar("");
        setOptMotivoEditar("S/D");
      }
    }
  };

  const [optMotivoEditar, setOptMotivoEditar] = React.useState("");
  const handleOptMotivoEditar = (event) => {
    setOptMotivoEditar(event.target.value);
  };

  const [optEsquemaEditar, setOptEsquemaEditar] = React.useState("");
  const handleOptEsquemaEditar = (event) => {
    setOptEsquemaEditar(event.target.value);
  };

  const [consejeriaEditar, setConsejeriaEditar] = React.useState("");
  const handleConsejeriaEditar = (event) => {
    setConsejeriaEditar(event.target.value);
  };

  const [optAsistMelEditar, setOptAsistMelEditar] = React.useState("");
  const handleOptAsistMelEditar = (event) => {
    setOptAsistMelEditar(event.target.value);
    if (event.target.value === "No") {
      setSemGestEditar("S/D");
      setPesoMelEditar("S/D");
      setTallaMelEditar("S/D");
      setImcEditar("S/D");
      setDiagImcEditar("S/D");
      setPbMelEditar("S/D");
      setDiagPbMelEditar("S/D");
      setDiagFinalMelEditar("S/D");
    } else {
      if (event.target.value === "Si" || event.target.value === "") {
        setOptRazonMelEditar("S/D");
        setSemGestEditar("");
        setPesoMelEditar("");
        setTallaMelEditar("");
        setImcEditar("");
        setDiagImcEditar("");
        setPbMelEditar("");
        setDiagPbMelEditar("");
        setDiagFinalMelEditar("");
      }
    }
  };

  const [optRazonMelEditar, setOptRazonMelEditar] = React.useState("");
  const handleOptRazonMelEditar = (event) => {
    setOptRazonMelEditar(event.target.value);
  };

  const [semGestEditar, setSemGestEditar] = useState("");
  const handleSemGestEditar = (event) => {
    setSemGestEditar(event.target.value);
  };
  /////////////////////////////////////////////////////////////////////

  /////////// VARIABLES COMPARACIÓN DE DIAGNÓSTICOS /////////////
  const [scorePB, setScorePB] = useState(0);
  const [scorePZ, setScorePZ] = useState(0);
  const [scorePbMel, setScorePbMel] = useState(0);
  const [scoreImcMel, setScoreImcMel] = useState(0);
  const [scorePBEditar, setScorePBEditar] = useState(0);
  const [scorePZEditar, setScorePZEditar] = useState(0);
  const [scorePbMelEditar, setScorePbMelEditar] = useState(0);
  const [scoreImcMelEditar, setScoreImcMelEditar] = useState(0);
  //////////////////////////////////////////////////////////////

  //////////////////// Datos ingreso /////////////////////////
  const [datoIngreso, setDatoIngreso] = React.useState({});
  const [todosSeguimientos, setTodosSeguimientos] = React.useState([]);

  //Input States M5
  const [pesoBenef, setPesoBenef] = React.useState("");
  const [tallaBenef, setTallaBenef] = React.useState("");
  const [puntajeZ, setPuntajeZ] = React.useState("");
  const handlePuntajeZ = (event) => {
    setPuntajeZ(event.target.value);
    if (
      Number(event.target.value) <= -2.9 &&
      Number(event.target.value) >= -4.5
    ) {
      setDiagPZ("Desnutrición Aguda Severa");
      setScorePZ(1);
    } else {
      if (
        Number(event.target.value) <= -1.9 &&
        Number(event.target.value) >= -2.89
      ) {
        setDiagPZ("Desnutrición Aguda Moderada");
        setScorePZ(2);
      } else {
        if (
          Number(event.target.value) <= 1.89 &&
          Number(event.target.value) >= -1.89 &&
          event.target.value !== ""
        ) {
          setDiagPZ("Adecuado");
          setScorePZ(5);
        } else {
          if (
            Number(event.target.value) >= 1.9 &&
            Number(event.target.value) <= 2.89
          ) {
            setDiagPZ("Soprepeso");
            setScorePZ(4);
          } else {
            if (Number(event.target.value) >= 2.9) {
              setDiagPZ("Obesidad");
              setScorePZ(3);
            } else {
              if (event.target.value === "") {
                setDiagPZ("");
                setScorePZ(0);
              }
            }
          }
        }
      }
    }
  };

  const [diagPZ, setDiagPZ] = React.useState("");
  const [pb, setPB] = React.useState("");
  const handlePb = (event) => {
    setPB(event.target.value);
    /*if (Number(event.target.value) >= 18) {
      setDiagPB("Sobrepeso");
      setScorePB(4);
    } else {*/
    if (Number(event.target.value) <= 30 && Number(event.target.value) > 12.5) {
      setDiagPB("Peso Adecuado");
      setScorePB(5);
    } else {
      if (
        Number(event.target.value) <= 12.5 &&
        Number(event.target.value) >= 11.5
      ) {
        setDiagPB("Desnutrición Aguda Moderada");
        setScorePB(2);
      } else {
        if (
          Number(event.target.value) < 11.5 &&
          Number(event.target.value) >= 8
        ) {
          setDiagPB("Desnutrición Aguda Severa");
          setScorePB(1);
        } else {
          if (event.target.value === "") {
            setDiagPB("");
            setScorePB(0);
          }
        }
      }
    }
    //}
  };
  const [diagPB, setDiagPB] = React.useState("");
  const [diagFinal, setDiagFinal] = React.useState("");
  const [observacionesM5, setObservacionesM5] = React.useState("");
  ///////////////////////////////////////////////////////////////////////////

  const [pesoBenefEditar, setPesoBenefEditar] = React.useState("");
  const [tallaBenefEditar, setTallaBenefEditar] = React.useState("");
  const [puntajeZEditar, setPuntajeZEditar] = React.useState("");
  const handlePuntajeZEditar = (event) => {
    setPuntajeZEditar(event.target.value);
    if (
      Number(event.target.value) <= -2.9 &&
      Number(event.target.value) >= -4.5
    ) {
      setDiagPZEditar("Desnutrición Aguda Severa");
      setScorePZEditar(1);
    } else {
      if (
        Number(event.target.value) <= -1.9 &&
        Number(event.target.value) >= -2.89
      ) {
        setDiagPZEditar("Desnutrición Aguda Moderada");
        setScorePZEditar(2);
      } else {
        if (
          Number(event.target.value) <= 1.89 &&
          Number(event.target.value) >= -1.89 &&
          event.target.value !== ""
        ) {
          setDiagPZEditar("Adecuado");
          setScorePZEditar(5);
        } else {
          if (
            Number(event.target.value) >= 1.9 &&
            Number(event.target.value) <= 2.89
          ) {
            setDiagPZEditar("Soprepeso");
            setScorePZEditar(4);
          } else {
            if (Number(event.target.value) >= 2.9) {
              setDiagPZEditar("Obesidad");
              setScorePZEditar(3);
            } else {
              if (event.target.value === "") {
                setDiagPZEditar("");
                setScorePZEditar(0);
              }
            }
          }
        }
      }
    }
  };

  const [diagPZEditar, setDiagPZEditar] = React.useState("");
  const [pbEditar, setPBEditar] = React.useState("");
  const handlePbEditar = (event) => {
    setPBEditar(event.target.value);
    /*if (Number(event.target.value) >= 18) {
      setDiagPBEditar("Sobrepeso");
      setScorePBEditar(4);
    } else {*/
    if (Number(event.target.value) <= 30 && Number(event.target.value) > 12.5) {
      setDiagPBEditar("Peso Adecuado");
      setScorePBEditar(5);
    } else {
      if (
        Number(event.target.value) <= 12.5 &&
        Number(event.target.value) >= 11.5
      ) {
        setDiagPBEditar("Desnutrición Aguda Moderada");
        setScorePBEditar(2);
      } else {
        if (
          Number(event.target.value) < 11.5 &&
          Number(event.target.value) >= 8
        ) {
          setDiagPBEditar("Desnutrición Aguda Severa");
          setScorePBEditar(1);
        } else {
          if (event.target.value === "") {
            setDiagPBEditar("");
            setScorePBEditar(0);
          }
        }
      }
    }
    //}
  };
  const [diagPBEditar, setDiagPBEditar] = React.useState("");
  const [diagFinalEditar, setDiagFinalEditar] = React.useState("");
  const [observacionesM5Editar, setObservacionesM5Editar] = React.useState("");
  //////////////////////////////////////////////////////////////////////////

  //Input States Mel
  const [pesoMel, setPesoMel] = React.useState("");
  const [tallaMel, setTallaMel] = React.useState("");
  const [imc, setImc] = React.useState("");
  const handleImc = (event) => {
    setImc(event.target.value);
    if (Number(event.target.value) < 18.5 && event.target.value !== "") {
      setDiagImc("Bajo Peso");
      setScoreImcMel(1);
    } else {
      if (
        Number(event.target.value) >= 18.5 &&
        Number(event.target.value) <= 24.99
      ) {
        setDiagImc("Adecuado");
        setScoreImcMel(3);
      } else {
        if (
          Number(event.target.value) >= 25 &&
          Number(event.target.value) <= 29.99
        ) {
          setDiagImc("Sobrepeso");
          setScoreImcMel(4);
        } else {
          if (Number(event.target.value) >= 30) {
            setDiagImc("Obesidad");
            setScoreImcMel(5);
          } else {
            if (event.target.value === "") {
              setDiagImc("");
              setScoreImcMel(0);
            }
          }
        }
      }
    }
  };

  const [pbMel, setPbMel] = React.useState("");
  const handlePbMel = (event) => {
    setPbMel(event.target.value);
    if (Number(event.target.value) <= 22.1 && event.target.value !== "") {
      setDiagPbMel("Bajo Peso");
      setScorePbMel(1);
    } else {
      if (
        Number(event.target.value) > 22.1 &&
        Number(event.target.value) < 33.09
      ) {
        setDiagPbMel("Peso adecuado");
        setScorePbMel(3);
      } else {
        if (Number(event.target.value) >= 33.1) {
          setDiagPbMel("Obesidad");
          setScorePbMel(4);
        } else {
          if (event.target.value === "") {
            setDiagPbMel("");
            setScorePbMel(0);
          }
        }
      }
    }
  };

  const [diagImc, setDiagImc] = React.useState("");
  const [diagPbMel, setDiagPbMel] = React.useState("");
  const [diagFinalMel, setDiagFinalMel] = React.useState("");
  const [observacionesMel, setObservacionesMel] = React.useState("");
  ////////////////////////////////////////////////////////////////////////////

  const [pesoMelEditar, setPesoMelEditar] = React.useState("");
  const [tallaMelEditar, setTallaMelEditar] = React.useState("");
  const [imcEditar, setImcEditar] = React.useState("");
  const handleImcEditar = (event) => {
    setImcEditar(event.target.value);
    if (Number(event.target.value) < 18.5 && event.target.value !== "") {
      setDiagImcEditar("Bajo Peso");
      setScoreImcMelEditar(1);
    } else {
      if (
        Number(event.target.value) >= 18.5 &&
        Number(event.target.value) <= 24.99
      ) {
        setDiagImcEditar("Adecuado");
        setScoreImcMelEditar(3);
      } else {
        if (
          Number(event.target.value) >= 25 &&
          Number(event.target.value) <= 29.99
        ) {
          setDiagImcEditar("Sobrepeso");
          setScoreImcMelEditar(4);
        } else {
          if (Number(event.target.value) >= 30) {
            setDiagImcEditar("Obesidad");
            setScoreImcMelEditar(5);
          } else {
            if (event.target.value === "") {
              setDiagImcEditar("");
              setScoreImcMelEditar(0);
            }
          }
        }
      }
    }
  };

  const [pbMelEditar, setPbMelEditar] = React.useState("");
  const handlePbMelEditar = (event) => {
    setPbMelEditar(event.target.value);
    if (Number(event.target.value) <= 22.1 && event.target.value !== "") {
      setDiagPbMelEditar("Bajo Peso");
      setScorePbMelEditar(1);
    } else {
      if (
        Number(event.target.value) > 22.1 &&
        Number(event.target.value) < 33.09
      ) {
        setDiagPbMelEditar("Peso adecuado");
        setScorePbMelEditar(3);
      } else {
        if (Number(event.target.value) >= 33.1) {
          setDiagPbMelEditar("Obesidad");
          setScorePbMelEditar(4);
        } else {
          if (event.target.value === "") {
            setDiagPbMelEditar("");
            setScorePbMelEditar(0);
          }
        }
      }
    }
  };

  const [diagImcEditar, setDiagImcEditar] = React.useState("");
  const [diagPbMelEditar, setDiagPbMelEditar] = React.useState("");
  const [diagFinalMelEditar, setDiagFinalMelEditar] = React.useState("");
  const [observacionesMelEditar, setObservacionesMelEditar] =
    React.useState("");
  ///////////////////////////////////////////////////////////////////////////

  //Micronutrientes Input States
  const [motivoEsquema, setMotivoEsquema] = React.useState("");
  const [observacionesMN, setObservacionesMN] = React.useState("");
  //////////////////////////////////////////////////////////////////

  const [motivoEsquemaEditar, setMotivoEsquemaEditar] = React.useState("");
  const [observacionesMNEditar, setObservacionesMNEditar] = React.useState("");
  /////////////////////////////////////////////////////////////////

  //Alerts Handlers
  const [openNoti, setOpenNoti] = React.useState(false);
  const [openNotiError, setOpenNotiError] = React.useState(false);
  const [openNotiEliminar, setOpenNotiEliminar] = React.useState(false);

  //////////////// COMPARACIÓN DIAGNÓSTICOS M5 ///////////////
  useEffect(() => {
    if (scorePZ === "" || scorePB === "") {
      setDiagFinal("");
    } else {
      if (scorePB < scorePZ) {
        setDiagFinal(diagPB);
      } else {
        if (scorePB > scorePZ) {
          setDiagFinal(diagPZ);
        } else {
          if (scorePB == scorePZ) {
            setDiagFinal(diagPZ);
          }
        }
      }
    }
  }, [scorePB, scorePZ]);

  // EDITAR //
  useEffect(() => {
    if (scorePZEditar === "" || scorePBEditar === "") {
      setDiagFinalEditar("");
    } else {
      if (scorePBEditar < scorePZEditar) {
        setDiagFinalEditar(diagPBEditar);
      } else {
        if (scorePBEditar > scorePZEditar) {
          setDiagFinalEditar(diagPZEditar);
        } else {
          if (scorePBEditar == scorePZEditar) {
            setDiagFinalEditar(diagPZEditar);
          }
        }
      }
    }
  }, [scorePBEditar, scorePZEditar]);
  //////////////////////////////////////////////////////////

  //////////////// COMPARACIÓN DIAGNÓSTICOS MEL ///////////////
  useEffect(() => {
    if (scorePbMel === "" || scoreImcMel === "") {
      setDiagFinalMel("");
    } else {
      if (scorePbMel < scoreImcMel) {
        setDiagFinalMel(diagPbMel);
      } else {
        if (scorePbMel > scoreImcMel) {
          setDiagFinalMel(diagImc);
        } else {
          if (scorePbMel == scoreImcMel) {
            setDiagFinalMel(diagImc);
          }
        }
      }
    }
  }, [scorePbMel, scoreImcMel]);

  // EDITAR //
  useEffect(() => {
    if (scorePbMelEditar === "" || scoreImcMelEditar === "") {
      setDiagFinalMelEditar("");
    } else {
      if (scorePbMelEditar < scoreImcMelEditar) {
        setDiagFinalMelEditar(diagPbMelEditar);
      } else {
        if (scorePbMelEditar > scoreImcMelEditar) {
          setDiagFinalMelEditar(diagImcEditar);
        } else {
          if (scorePbMelEditar == scoreImcMelEditar) {
            setDiagFinalMel(diagImcEditar);
          }
        }
      }
    }
  }, [scorePbMelEditar, scoreImcMelEditar]);
  /////////////////////////////////////////////////////////////

  //Tabs Handler
  const [tabsCat, setTabsCat] = React.useState(0);
  const handleTabsCat = (event, newValue) => {
    setTabsCat(newValue);
    setTabsPend(0);
    setTabsBenef(0);
    setTabsDesertor(0);
    setTabsReferidos(0);
    setTabsAltaNut(0);
  };

  const [tabsPend, setTabsPend] = React.useState(0);
  const handleTabsPend = (event, newValue) => {
    setTabsPend(newValue);
  };

  const [tabsBenef, setTabsBenef] = React.useState(0);
  const handleTabsBenef = (event, newValue) => {
    setTabsBenef(newValue);
  };

  const [tabsDesertor, setTabsDesertor] = React.useState(0);
  const handleTabsDesertor = (event, newValue) => {
    setTabsDesertor(newValue);
  };

  const [tabsReferidos, setTabsReferidos] = React.useState(0);
  const handleTabsReferidos = (event, newValue) => {
    setTabsReferidos(newValue);
  };

  const [tabsAltaNut, setTabsAltaNut] = React.useState(0);
  const handleTabsAltaNut = (event, newValue) => {
    setTabsAltaNut(newValue);
  };

  const [tabsFichaM5, setTabsFichaM5] = React.useState(0);
  const handleTabsFichaM5 = (event, newValue) => {
    setTabsFichaM5(newValue);
  };

  const [tabsFichaMicro, setTabsFichaMicro] = React.useState(0);
  const handleTabsFichaMicro = (event, newValue) => {
    setTabsFichaMicro(newValue);
  };

  const [tabsFichaMel, setTabsFichaMel] = React.useState(0);
  const handleTabsFichaMel = (event, newValue) => {
    setTabsFichaMel(newValue);
  };

  const [tabsEditM5, setTabsEditM5] = React.useState(0);
  const [idSeguimiento, setIdSeguimiento] = React.useState("");
  const handleTabsEditM5 = (event, newValue) => {
    setTabsEditM5(newValue);
    setIdSeguimiento(seguimientos[newValue].idSeguimiento);
    setOptAsistEditar(seguimientos[newValue].asistio);
    setFechaEvaluacionEditar(seguimientos[newValue].fecha_evaluacion);
    setEstatusM5Editar(seguimientos[newValue].estado);
    setOptRazonEditar(seguimientos[newValue].razon);
    setOptEdemaEditar(seguimientos[newValue].edema);
    setPesoBenefEditar(seguimientos[newValue].peso);
    setTallaBenefEditar(seguimientos[newValue].talla);
    setPuntajeZEditar(seguimientos[newValue].puntaje_z);
    setDiagPZEditar(seguimientos[newValue].clasificacion_nut);
    setPBEditar(seguimientos[newValue].circunferencia);
    setDiagPBEditar(seguimientos[newValue].clasificacion_pb);
    setDiagFinalEditar(seguimientos[newValue].diagnostico_ingreso);
    setObservacionesM5Editar(seguimientos[newValue].observacion);
  };

  const [tabsEditMicro, setTabsEditMicro] = React.useState(0);
  const handleTabsEditMicro = (event, newValue) => {
    setTabsEditMicro(newValue);
    setOptSegEditar(seguimientos[newValue].asistio);
    setFechaEvalMicroEditar(seguimientos[newValue].fecha_evaluacion);
    setEstatusMicroEditar(seguimientos[newValue].estado);
    setOptEsquemaEditar(seguimientos[newValue].esquema);
    setMotivoEsquemaEditar(seguimientos[newValue].motivoEsquema);
    setConsejeriaEditar(seguimientos[newValue].consejeria);
    setOptMotivoEditar(seguimientos[newValue].motivo);
    setObservacionesMNEditar(seguimientos[newValue].observacion);
  };

  const [tabsEditMel, setTabsEditMel] = React.useState(0);
  const handleTabsEditMel = (event, newValue) => {
    setTabsEditMel(newValue);
    setIdSeguimiento(seguimientos[newValue].idSeguimiento);
    setOptAsistMelEditar(seguimientos[newValue].asistio);
    setFechaEvalMelEditar(seguimientos[newValue].fecha_evaluacion);
    setEstatusMelEditar(seguimientos[newValue].estado);
    setOptRazonMelEditar(seguimientos[newValue].razon);
    setSemGestEditar(seguimientos[newValue].gestacion);
    setPesoMelEditar(seguimientos[newValue].peso);
    setTallaMelEditar(seguimientos[newValue].talla);
    setImcEditar(seguimientos[newValue].imc);
    setDiagImcEditar(seguimientos[newValue].diagnostico_imc);
    setPbMelEditar(seguimientos[newValue].circunferencia);
    setDiagPbMelEditar(seguimientos[newValue].clasificacion_pb);
    setDiagFinalMelEditar(seguimientos[newValue].diagnostico_ingreso);
  };

  //Modal Handler
  const [openControl, setOpenControl] = React.useState(false);
  const handleOpenControl = (row) => {
    setDatoIngreso(row.original);

    setOpenControl(true);
  };

  const handleCloseControl = (event) => {
    setOptAsist("");
    setOptRazon("");
    setOptEdema("");
    setPesoBenef("");
    setTallaBenef("");
    setPuntajeZ("");
    setDiagPZ("");
    setPB("");
    setDiagPB("");
    setDiagFinal("");
    setObservacionesM5("");
    setOpenControl(false);
  };

  /////////////////Guardar M5//////////////////
  const handleGuardarControl = async () => {
    console.log(datoIngreso);
    let codigo = datoIngreso.codigo_proyecto;
    let medicoNombre = items.nombre + " " + items.apellido;

    setDisableBtnM5(true);
    try {
      const data1 = await axios
        .post("crearcontrol/", {
          codigo_proyecto: codigo,
          id_convocatoria: datoIngreso.id_convocatoria,
          beneficiario: datoIngreso.id,
          asistio: optAsist,
          fecha_evaluacion: fechaEvaluacion,
          estado: estatusM5,
          razon: optRazon,
          edema: optEdema,
          peso: pesoBenef,
          talla: tallaBenef,
          puntaje_z: puntajeZ,
          clasificacion_nut: diagPZ,
          circunferencia: pb,
          clasificacion_pb: diagPB,
          diagnostico_ingreso: diagFinal,
          observacion: observacionesM5,
          medico: medicoNombre,
          medicoId: items.id,
          estatus: 1,
          num_seguimiento: datoIngreso.seguimientos + 1,
          clasificacion: "SAM",
        })
        .then(function (response) {
          setOpenNoti(true);
          setTimeout(() => {
            setOpenNoti(false);
          }, 2000);
          setOptAsist("");
          setOptRazon("");
          setOptEdema("");
          setPesoBenef("");
          setTallaBenef("");
          setPuntajeZ("");
          setDiagPZ("");
          setPB("");
          setDiagPB("");
          setDiagFinal("");
          setObservacionesM5("");
          handleCloseControl();
          setDisableBtnM5(false);
        })
        .catch(function (error) {
          setOpenNotiError(true);
          setTimeout(() => {
            setOpenNotiError(false);
          }, 2000);
        });
    } catch (error) {
      console.log(error);
    }
  };

  //////////////////////Guardar Seguimiento////////////////////
  const [openControlMicro, setOpenControlMicro] = React.useState(false);
  const handleOpenControlMicro = (row) => {
    setDatoIngreso(row.original);
    console.log(row.original);
    setOpenControlMicro(true);
  };
  const handleCloseControlMicro = (event) => {
    setOptSeg("");
    setOptMotivo("");
    setOptEsquema("");
    setMotivoEsquema("");
    setConsejeria("");
    setObservacionesMN("");
    setOpenControlMicro(false);
  };

  const handleGuardarControlMN = async () => {
    console.log(datoIngreso);
    let codigo = datoIngreso.codigo_proyecto;
    let medicoNombre = items.nombre + " " + items.apellido;
    try {
      const data1 = await axios
        .post("crearseguimiento/", {
          codigo_proyecto: codigo,
          id_convocatoria: datoIngreso.id_convocatoria,
          beneficiario: datoIngreso.id,
          asistio: optSeg,
          fecha_evaluacion: fechaEvalMicro,
          estado: estatusMicro,
          motivo: optMotivo,
          esquema: optEsquema,
          motivoEsquema: motivoEsquema,
          consejeria: consejeria,
          observacion: observacionesMN,
          medico: medicoNombre,
          medicoId: items.id,
          estatus: 1,
          num_seguimiento: codigo,
          clasificacion: "Micro",
        })
        .then(function (response) {
          setOpenNoti(true);
          setTimeout(() => {
            setOpenNoti(false);
          }, 2000);
          setOptSeg("");
          setOptMotivo("");
          setOptEsquema("");
          setMotivoEsquema("");
          setConsejeria("");
          setObservacionesMN("");
          handleCloseControlMicro();
        })
        .catch(function (error) {
          setOpenNotiError(true);
          setTimeout(() => {
            setOpenNotiError(false);
          }, 2000);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [openControlMel, setOpenControlMel] = React.useState(false);
  const handleOpenControlMel = (row) => {
    setDatoIngreso(row.original);
    setOpenControlMel(true);
  };
  const handleCloseControlMel = (event) => {
    setOptAsistMel("");
    setOptRazonMel("");
    setSemGest("");
    setPesoMel("");
    setTallaMel("");
    setImc("");
    setDiagImc("");
    setPbMel("");
    setDiagPbMel("");
    setDiagFinalMel("");
    setObservacionesMel("");
    setOpenControlMel(false);
  };

  const handleGuardarControlMel = async () => {
    console.log(datoIngreso);
    let codigo = datoIngreso.codigo_proyecto;
    let medicoNombre = items.nombre + " " + items.apellido;
    try {
      const data1 = await axios
        .post("crearcontrolmel/", {
          codigo_proyecto: codigo,
          id_convocatoria: datoIngreso.id_convocatoria,
          beneficiario: datoIngreso.idmel,
          asistio: optAsistMel,
          fecha_evaluacion: fechaEvalMel,
          estado: estatusMel,
          razon: optRazonMel,
          gestacion: semGest,
          peso: pesoMel,
          talla: tallaMel,
          imc: imc,
          diagnostico_imc: diagImc,
          circunferencia: pbMel,
          clasificacion_pb: diagPbMel,
          diagnostico_ingreso: diagFinalMel,
          diagnostico_gestacion: diagSemGestMel,
          observacion: observacionesMel,
          medico: medicoNombre,
          medicoId: items.id,
          estatus: 1,
          num_seguimiento: codigo,
          clasificacion: "Mel",
        })
        .then(function (response) {
          setOpenNoti(true);
          setTimeout(() => {
            setOpenNoti(false);
          }, 2000);
          setOptAsistMel("");
          setOptRazonMel("");
          setSemGest("");
          setPesoMel("");
          setTallaMel("");
          setImc("");
          setDiagImc("");
          setPbMel("");
          setDiagPbMel("");
          setDiagSemGestMel("");
          setDiagFinalMel("");
          setObservacionesMel("");
          handleCloseControlMel();
        })
        .catch(function (error) {
          console.log(error);
          setOpenNotiError(true);
          setTimeout(() => {
            setOpenNotiError(false);
          }, 2000);
        });
    } catch (error) {
      console.log(error);
    }
  };

  ////////////////////////////Guardar Editar///////////////////////////
  const handleEditarControlM5 = async () => {
    console.log("Editar");
    let codigo = datoIngreso.codigo_proyecto;
    let medicoNombre = items.nombre + " " + items.apellido;
    console.log(datoIngreso);
    try {
      const data1 = await axios
        .put("/editarcontrol", {
          beneficiario: datoIngreso.id,
          idSeguimiento: idSeguimiento,
          asistio: optAsistEditar,
          fecha_evaluacion: fechaEvaluacionEditar,
          estado: estatusM5Editar,
          razon: optRazonEditar,
          edema: optEdemaEditar,
          peso: pesoBenefEditar,
          talla: tallaBenefEditar,
          puntaje_z: puntajeZEditar,
          clasificacion_nut: diagPZEditar,
          circunferencia: pbEditar,
          clasificacion_pb: diagPBEditar,
          diagnostico_ingreso: diagFinalEditar,
          observacion: observacionesM5Editar,
        })
        .then(function (response) {
          setOpenNoti(true);
          setTimeout(() => {
            setOpenNoti(false);
          }, 2000);
          handleCloseEditM5();
        })
        .catch(function (error) {
          setOpenNotiError(true);
          setTimeout(() => {
            setOpenNotiError(false);
          }, 2000);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditarControlMicro = async () => {
    try {
      const data1 = await axios
        .put("/editarcontrol", {
          idSeguimiento: idSeguimiento,

          asistio: optSegEditar,
          fecha_evaluacion: fechaEvalMicroEditar,
          estado: estatusMicroEditar,
          motivo: optMotivoEditar,
          esquema: optEsquemaEditar,
          motivoEsquema: motivoEsquemaEditar,
          consejeria: consejeriaEditar,
          observacion: observacionesMNEditar,
        })
        .then(function (response) {
          setOpenNoti(true);
          setTimeout(() => {
            setOpenNoti(false);
          }, 2000);
          handleCloseEditMicro();
        })
        .catch(function (error) {
          setOpenNotiError(true);
          setTimeout(() => {
            setOpenNotiError(false);
          }, 2000);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditarControlMel = async () => {
    try {
      const data1 = await axios
        .put("/editarcontrolMel", {
          idSeguimiento: idSeguimiento,
          asistio: optAsistMelEditar,
          fecha_evaluacion: fechaEvalMelEditar,
          estado: estatusMelEditar,
          razon: optRazonMelEditar,
          gestacion: semGestEditar,
          peso: pesoMelEditar,
          talla: tallaMelEditar,
          imc: imcEditar,
          diagnostico_imc: diagImcEditar,
          circunferencia: pbMelEditar,
          clasificacion_pb: diagPbMelEditar,
          diagnostico_ingreso: diagFinalMelEditar,
          diagnostico_gestacion: diagSemGestMelEditar,
          observacion: observacionesMelEditar,
        })
        .then(function (response) {
          setOpenNoti(true);
          setTimeout(() => {
            setOpenNoti(false);
          }, 2000);
          handleCloseEditMel();
        })
        .catch(function (error) {
          setOpenNotiError(true);
          setTimeout(() => {
            setOpenNotiError(false);
          }, 2000);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //////////////////////////////////////////////////////////////////////

  //////////////////////////Eliminar Control/////////////////////////////
  const handleEliminarControlM5 = async () => {
    console.log(idSeguimiento, "Elimiar");
    let codigo = datoIngreso.codigo_proyecto;
    let medicoNombre = items.nombre + " " + items.apellido;
    try {
      const data1 = await axios
        .put("/eliminarControl", {
          idSeguimiento: idSeguimiento,
        })
        .then(function (response) {
          setOpenNoti(true);
          setTimeout(() => {
            setOpenNotiEliminar(false);
          }, 2000);
          handleCloseEditM5();
        })
        .catch(function (error) {
          setOpenNotiError(true);
          setTimeout(() => {
            setOpenNotiError(false);
          }, 2000);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleEliminarControlMicro = async () => {
    console.log("Editar");
    let codigo = datoIngreso.codigo_proyecto;
    let medicoNombre = items.nombre + " " + items.apellido;
    try {
      const data1 = await axios
        .put("/eliminarControl", {
          idSeguimiento: idSeguimiento,
        })
        .then(function (response) {
          setOpenNoti(true);
          setTimeout(() => {
            setOpenNotiEliminar(false);
          }, 2000);
          handleCloseEditMicro();
        })
        .catch(function (error) {
          setOpenNotiError(true);
          setTimeout(() => {
            setOpenNotiError(false);
          }, 2000);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEliminarControlMel = async () => {
    console.log("Editar");
    let codigo = datoIngreso.codigo_proyecto;
    let medicoNombre = items.nombre + " " + items.apellido;
    try {
      const data1 = await axios
        .put("/eliminarControl", {
          idSeguimiento: idSeguimiento,
        })
        .then(function (response) {
          setOpenNoti(true);
          setTimeout(() => {
            setOpenNotiEliminar(false);
          }, 2000);
          handleCloseEditMel();
        })
        .catch(function (error) {
          setOpenNotiError(true);
          setTimeout(() => {
            setOpenNotiError(false);
          }, 2000);
        });
    } catch (error) {
      console.log(error);
    }
  };

  ///////////////////////////////////////////////////////////////////////
  const [openFichaM5, setFichaM5] = React.useState(false);
  const handleFichaM5 = (row) => {
    setDatoIngreso(row.original);
    getseguimientos(row.original.id);
    setFichaM5(true);
  };
  const handleCloseFichaM5 = (event) => {
    setFichaM5(false);
    getseguimientos([]);
    setTabsFichaM5(0);
  };

  const [openFichaMicro, setFichaMicro] = React.useState(false);
  const handleFichaMicro = (row) => {
    console.log(row.original);
    setDatoIngreso(row.original);
    getseguimientos(row.original.id);
    setFichaMicro(true);
  };
  const handleCloseFichaMicro = (event) => {
    setFichaMicro(false);
    setTabsFichaMicro(0);
    getseguimientos([]);
  };

  const [openFichaMel, setFichaMel] = React.useState(false);
  const handleFichaMel = (row) => {
    setDatoIngreso(row.original);
    getseguimientos(row.original.idmel);
    setFichaMel(true);
  };
  const handleCloseFichaMel = (event) => {
    setFichaMel(false);
    getseguimientos([]);
    setTabsFichaMel(0);
  };

  const [openEditM5, setEditM5] = React.useState(false);
  const handleOpenEditM5 = (row) => {
    setDatoIngreso(row.original);
    getseguimientos(row.original.id);
    setEditM5(true);
  };

  const handleCloseEditM5 = (event) => {
    setOptAsist("");
    setOptRazon("");
    setOptEdema("");
    setPesoBenef("");
    setTallaBenef("");
    setPuntajeZ("");
    setDiagPZ("");
    setPB("");
    setDiagPB("");
    setDiagFinal("");
    setObservacionesM5("");
    setTabsEditM5(0);
    setEditM5(false);
    setEstatusM5Editar("");
    setFechaEvaluacionEditar(null);
  };

  const [openEditMicro, setOpenEditMicro] = React.useState(false);
  const handleOpenEditMicro = (row) => {
    setDatoIngreso(row.original);
    getseguimientos(row.original.id);
    setOpenEditMicro(true);
  };
  const handleCloseEditMicro = (event) => {
    setOptSeg("");
    setOptMotivo("");
    setOptEsquema("");
    setMotivoEsquema("");
    setConsejeria("");
    setObservacionesMN("");
    setTabsEditMicro(0);
    setOpenEditMicro(false);
    setEstatusMicroEditar("");
    setFechaEvalMicroEditar(null);
  };

  const [openEditMel, setOpenEditMel] = React.useState(false);
  const handleOpenEditMel = (row) => {
    setDatoIngreso(row.original);
    getseguimientos(row.original.idmel);
    setOpenEditMel(true);
  };
  const handleCloseEditMel = (event) => {
    setOptAsistMelEditar("");
    setOptRazonMelEditar("");
    setSemGestEditar("");
    setPesoMelEditar("");
    setTallaMelEditar("");
    setImcEditar("");
    setDiagImcEditar("");
    setPbMelEditar("");
    setDiagPbMelEditar("");
    setDiagSemGestMelEditar("");
    setDiagFinalMelEditar("");
    setObservacionesMelEditar("");
    setTabsEditMel(0);
    setOpenEditMel(false);
    setEstatusMelEditar("");
    setFechaEvalMelEditar(null);
  };

  //BreadCrumbs
  function handleClickBreadCrumbs(event) {
    event.preventDefault();
  }

  // Deshabilitar Submit /////////////////////////////////////////////////
  const [disableBtnM5, setDisableBtnM5] = React.useState(false);
  const [disableBtnMel, setDisableBtnMel] = React.useState(false);
  const [disableBtnDes, setDisableBtnDes] = React.useState(false);
  const [disableBtnCap, setDisableBtnCap] = React.useState(false);
  ////////////////////////////////////////////////////////////////////////

  /////////////////////////  ARRAY TABLA EN CONTROL  ///////////////////////////
  const columns = useMemo(
    () => [
      {
        accessorKey: "nombre",
        header: "Nombre",
        size: 150,
      },
      {
        accessorKey: "apellido",
        header: "Apellido",
        size: 150,
      },
      {
        accessorKey: "telefono_rep",
        header: "Telefono",
        size: 150,
      },
      {
        accessorKey: "edad",
        header: "Edad",
        size: 150,
      },
      {
        accessorKey: "grupo_etnico",
        header: "Grupo Etnico",
        size: 150,
      },
      {
        accessorKey: "registrado",
        header: "Registrado",
        size: 150,
      },
      {
        accessorKey: "discapacidad",
        header: "Discapacidad",
        size: 150,
      },
      {
        accessorKey: "fecha_evaluacion",
        header: "Fecha de Ingreso",
        size: 150,
      },

      {
        accessorKey: "municipio",
        header: "Municipio",
        size: 150,
      },
      {
        accessorKey: "parroquia",
        header: "Parroquia",
        size: 150,
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
        size: 150,
      },
      {
        accessorKey: "Diagnostico",
        header: "Diagnóstico Ingreso",
        size: 150,
      },
      {
        accessorKey: "evaluacion1",
        header: "Control I",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "MAM"
              ? "MAM"
              : cell.getValue() === "SAM"
              ? "SAM"
              : "S/D"}
          </Typography>
        ),
      },
      {
        accessorKey: "evaluacion2",
        header: "Control II",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "MAM"
              ? "MAM"
              : cell.getValue() === "SAM"
              ? "SAM"
              : "S/D"}
          </Typography>
        ),
      },
      {
        accessorKey: "evaluacion3",
        header: "Control III",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "MAM"
              ? "MAM"
              : cell.getValue() === "SAM"
              ? "SAM"
              : "S/D"}
          </Typography>
        ),
      },
      {
        accessorKey: "evaluacion4",
        header: "Control IV",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "MAM"
              ? "MAM"
              : cell.getValue() === "SAM"
              ? "SAM"
              : "S/D"}
          </Typography>
        ),
      },
      {
        accessorKey: "evaluacion5",
        header: "Control V",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "MAM"
              ? "MAM"
              : cell.getValue() === "SAM"
              ? "SAM"
              : "S/D"}
          </Typography>
        ),
      },
      {
        accessorKey: "evaluacion6",
        header: "Control VI",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "MAM"
              ? "MAM"
              : cell.getValue() === "SAM"
              ? "SAM"
              : "S/D"}
          </Typography>
        ),
      },
      {
        accessorKey: "evaluacion7",
        header: "Control VII",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "MAM"
              ? "MAM"
              : cell.getValue() === "SAM"
              ? "SAM"
              : "S/D"}
          </Typography>
        ),
      },
      {
        accessorKey: "evaluacion8",
        header: "Control VIII",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "MAM"
              ? "MAM"
              : cell.getValue() === "SAM"
              ? "SAM"
              : "S/D"}
          </Typography>
        ),
      },
      {
        accessorKey: "evolucion",
        header: "Evolución",
        size: 150,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={{
              backgroundColor:
                cell.getValue() === "Positiva" ? "green" : cell.getValue() === "Negativa" ? "firebrick" : "black",
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "9ch",
              p: "0.25rem",
            }}
          >
            {cell.getValue()}
          </Box>
        ),
      },
    ],
    []
  );

  const columnsMicro = useMemo(
    () => [
      {
        accessorKey: "nombre",
        header: "Nombre",
        size: 150,
      },
      {
        accessorKey: "apellido",
        header: "Apellido",
        size: 150,
      },
      {
        accessorKey: "telefono_rep",
        header: "Telefono",
        size: 150,
      },
      {
        accessorKey: "edad",
        header: "Edad",
        size: 150,
      },
      {
        accessorKey: "grupo_etnico",
        header: "Grupo Etnico",
        size: 150,
      },
      {
        accessorKey: "registrado",
        header: "Registrado",
        size: 150,
      },
      {
        accessorKey: "discapacidad",
        header: "discapacidad",
        size: 150,
      },
      {
        accessorKey: "fecha_evaluacion",
        header: "Fecha de Ingreso",
        size: 150,
      },
      {
        accessorKey: "municipio",
        header: "Municipio",
        size: 150,
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
        size: 150,
      },
      {
        accessorKey: "evaluacion1",
        header: "Control I",
        size: 150,
      },
      {
        accessorKey: "evaluacion2",
        header: "Control II",
        size: 150,
      },
      {
        accessorKey: "evaluacion3",
        header: "Control III",
        size: 150,
      },
      {
        accessorKey: "evaluacion4",
        header: "Control IV",
        size: 150,
      },
    ],
    []
  );
  const columnsMel = useMemo(
    () => [
      {
        accessorKey: "nombre",
        header: "Nombre",
        size: 150,
      },
      {
        accessorKey: "apellido",
        header: "Apellido",
        size: 150,
      },
      {
        accessorKey: "cedula",
        header: "Cedula",
        size: 150,
      },
      {
        accessorKey: "telefono",
        header: "Telefono",
        size: 150,
      },
      {
        accessorKey: "edad",
        header: "Edad",
        size: 150,
      },
      {
        accessorKey: "grupo_etnico",
        header: "Grupo Etnico",
        size: 150,
      },
      {
        accessorKey: "gestante",
        header: "Gestante",
        size: 150,
      },
      {
        accessorKey: "discapacidad",
        header: "Discapacidad",
        size: 150,
      },
      {
        accessorKey: "fecha_evaluacion",
        header: "Fecha de Ingreso",
        size: 150,
      },
      {
        accessorKey: "municipio",
        header: "Municipio",
        size: 150,
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
        size: 150,
      },
      {
        accessorKey: "diagnostico_ingreso",
        header: "Diagnostico Ingreso",
        size: 150,
      },
      {
        accessorKey: "evaluacion1",
        header: "Control I",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "Bajo"
              ? "Bajo Peso"
              : "S/D"}
          </Typography>
        )
      },
      {
        accessorKey: "evaluacion2",
        header: "Control II",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "Bajo"
              ? "Bajo Peso"
              : "S/D"}
          </Typography>
        )
      },
      {
        accessorKey: "evaluacion3",
        header: "Control III",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "Bajo"
              ? "Bajo Peso"
              : "S/D"}
          </Typography>
        )
      },
      {
        accessorKey: "evaluacion4",
        header: "Control IV",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "Bajo"
              ? "Bajo Peso"
              : "S/D"}
          </Typography>
        )
      },
      {
        accessorKey: "evolucion",
        header: "Evolución",
        size: 150,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={{
              backgroundColor:
                cell.getValue() === "Positiva" ? "green" : cell.getValue() === "Negativa" ? "firebrick" : "black",
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "9ch",
              p: "0.25rem",
            }}
          >
            {cell.getValue()}
          </Box>
        ),
      },
    ],
    []
  );

  ///////////////////////////  ARRAY TABLA PENDIENTES //////////////////////////////////////

  const columnsPendM5 = useMemo(
    () => [
      {
        accessorKey: "nombre",
        header: "Nombre",
        size: 150,
      },
      {
        accessorKey: "apellido",
        header: "Apellido",
        size: 150,
      },
      {
        accessorKey: "telefono_rep",
        header: "Telefono",
        size: 150,
      },
      {
        accessorKey: "edad",
        header: "Edad",
        size: 150,
      },
      {
        accessorKey: "grupo_etnico",
        header: "Grupo Etnico",
        size: 150,
      },
      {
        accessorKey: "registrado",
        header: "Registrado",
        size: 150,
      },
      {
        accessorKey: "discapacidad",
        header: "Discapacidad",
        size: 150,
      },
      {
        accessorKey: "fecha_evaluacion",
        header: "Fecha de Ingreso",
        size: 150,
      },

      {
        accessorKey: "municipio",
        header: "Municipio",
        size: 150,
      },
      {
        accessorKey: "parroquia",
        header: "Parroquia",
        size: 150,
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
        size: 150,
      },
      {
        accessorKey: "Diagnostico",
        header: "Diagnóstico Ingreso",
        size: 150,
      },
    ],
    []
  );

  const columnsPendMicro = useMemo(
    () => [
      {
        accessorKey: "nombre",
        header: "Nombre",
        size: 150,
      },
      {
        accessorKey: "apellido",
        header: "Apellido",
        size: 150,
      },
      {
        accessorKey: "telefono_rep",
        header: "Telefono",
        size: 150,
      },
      {
        accessorKey: "edad",
        header: "Edad",
        size: 150,
      },
      {
        accessorKey: "grupo_etnico",
        header: "Grupo Etnico",
        size: 150,
      },
      {
        accessorKey: "registrado",
        header: "Registrado",
        size: 150,
      },
      {
        accessorKey: "discapacidad",
        header: "discapacidad",
        size: 150,
      },
      {
        accessorKey: "fecha_evaluacion",
        header: "Fecha de Ingreso",
        size: 150,
      },
      {
        accessorKey: "municipio",
        header: "Municipio",
        size: 150,
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
        size: 150,
      },
    ],
    []
  );

  const columnsPendMel = useMemo(
    () => [
      {
        accessorKey: "nombre",
        header: "Nombre",
        size: 150,
      },
      {
        accessorKey: "apellido",
        header: "Apellido",
        size: 150,
      },
      {
        accessorKey: "cedula",
        header: "Cedula",
        size: 150,
      },
      {
        accessorKey: "telefono",
        header: "Telefono",
        size: 150,
      },
      {
        accessorKey: "edad",
        header: "Edad",
        size: 150,
      },
      {
        accessorKey: "grupo_etnico",
        header: "Grupo Etnico",
        size: 150,
      },
      {
        accessorKey: "gestante",
        header: "Gestante",
        size: 150,
      },
      {
        accessorKey: "discapacidad",
        header: "Discapacidad",
        size: 150,
      },
      {
        accessorKey: "fecha_evaluacion",
        header: "Fecha de Ingreso",
        size: 150,
      },
      {
        accessorKey: "municipio",
        header: "Municipio",
        size: 150,
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
        size: 150,
      },
      {
        accessorKey: "diagnostico_ingreso",
        header: "Diagnostico Ingreso",
        size: 150,
      },
    ],
    []
  );

  ////////////////////////////  ARRAY TABLA DESERTOR  /////////////////////////////////////

  const columnsDesertorM5 = useMemo(
    () => [
      {
        accessorKey: "nombre",
        header: "Nombre",
        size: 150,
      },
      {
        accessorKey: "apellido",
        header: "Apellido",
        size: 150,
      },
      {
        accessorKey: "telefono_rep",
        header: "Telefono",
        size: 150,
      },
      {
        accessorKey: "edad",
        header: "Edad",
        size: 150,
      },
      {
        accessorKey: "grupo_etnico",
        header: "Grupo Etnico",
        size: 150,
      },
      {
        accessorKey: "registrado",
        header: "Registrado",
        size: 150,
      },
      {
        accessorKey: "discapacidad",
        header: "Discapacidad",
        size: 150,
      },
      {
        accessorKey: "fecha_evaluacion",
        header: "Fecha de Ingreso",
        size: 150,
      },

      {
        accessorKey: "municipio",
        header: "Municipio",
        size: 150,
      },
      {
        accessorKey: "parroquia",
        header: "Parroquia",
        size: 150,
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
        size: 150,
      },
      {
        accessorKey: "Diagnostico",
        header: "Diagnóstico Ingreso",
        size: 150,
      },
    ],
    []
  );

  const columnsDesertorMicro = useMemo(
    () => [
      {
        accessorKey: "nombre",
        header: "Nombre",
        size: 150,
      },
      {
        accessorKey: "apellido",
        header: "Apellido",
        size: 150,
      },
      {
        accessorKey: "telefono_rep",
        header: "Telefono",
        size: 150,
      },
      {
        accessorKey: "edad",
        header: "Edad",
        size: 150,
      },
      {
        accessorKey: "grupo_etnico",
        header: "Grupo Etnico",
        size: 150,
      },
      {
        accessorKey: "registrado",
        header: "Registrado",
        size: 150,
      },
      {
        accessorKey: "discapacidad",
        header: "discapacidad",
        size: 150,
      },
      {
        accessorKey: "fecha_evaluacion",
        header: "Fecha de Ingreso",
        size: 150,
      },
      {
        accessorKey: "municipio",
        header: "Municipio",
        size: 150,
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
        size: 150,
      },
    ],
    []
  );

  const columnsDesertorMel = useMemo(
    () => [
      {
        accessorKey: "nombre",
        header: "Nombre",
        size: 150,
      },
      {
        accessorKey: "apellido",
        header: "Apellido",
        size: 150,
      },
      {
        accessorKey: "cedula",
        header: "Cedula",
        size: 150,
      },
      {
        accessorKey: "telefono",
        header: "Telefono",
        size: 150,
      },
      {
        accessorKey: "edad",
        header: "Edad",
        size: 150,
      },
      {
        accessorKey: "grupo_etnico",
        header: "Grupo Etnico",
        size: 150,
      },
      {
        accessorKey: "gestante",
        header: "Gestante",
        size: 150,
      },
      {
        accessorKey: "discapacidad",
        header: "Discapacidad",
        size: 150,
      },
      {
        accessorKey: "fecha_evaluacion",
        header: "Fecha de Ingreso",
        size: 150,
      },
      {
        accessorKey: "municipio",
        header: "Municipio",
        size: 150,
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
        size: 150,
      },
      {
        accessorKey: "diagnostico_ingreso",
        header: "Diagnostico Ingreso",
        size: 150,
      },
    ],
    []
  );

  //////////////////////////////////  ARRAY TABLA REFERIDO ////////////////////////////////

  const columnsReferidoM5 = useMemo(
    () => [
      {
        accessorKey: "nombre",
        header: "Nombre",
        size: 150,
      },
      {
        accessorKey: "apellido",
        header: "Apellido",
        size: 150,
      },
      {
        accessorKey: "telefono_rep",
        header: "Telefono",
        size: 150,
      },
      {
        accessorKey: "edad",
        header: "Edad",
        size: 150,
      },
      {
        accessorKey: "grupo_etnico",
        header: "Grupo Etnico",
        size: 150,
      },
      {
        accessorKey: "registrado",
        header: "Registrado",
        size: 150,
      },
      {
        accessorKey: "discapacidad",
        header: "Discapacidad",
        size: 150,
      },
      {
        accessorKey: "fecha_evaluacion",
        header: "Fecha de Ingreso",
        size: 150,
      },

      {
        accessorKey: "municipio",
        header: "Municipio",
        size: 150,
      },
      {
        accessorKey: "parroquia",
        header: "Parroquia",
        size: 150,
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
        size: 150,
      },
      {
        accessorKey: "Diagnostico",
        header: "Diagnóstico Ingreso",
        size: 150,
      },
    ],
    []
  );

  const columnsReferidoMicro = useMemo(
    () => [
      {
        accessorKey: "nombre",
        header: "Nombre",
        size: 150,
      },
      {
        accessorKey: "apellido",
        header: "Apellido",
        size: 150,
      },
      {
        accessorKey: "telefono_rep",
        header: "Telefono",
        size: 150,
      },
      {
        accessorKey: "edad",
        header: "Edad",
        size: 150,
      },
      {
        accessorKey: "grupo_etnico",
        header: "Grupo Etnico",
        size: 150,
      },
      {
        accessorKey: "registrado",
        header: "Registrado",
        size: 150,
      },
      {
        accessorKey: "discapacidad",
        header: "discapacidad",
        size: 150,
      },
      {
        accessorKey: "fecha_evaluacion",
        header: "Fecha de Ingreso",
        size: 150,
      },
      {
        accessorKey: "municipio",
        header: "Municipio",
        size: 150,
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
        size: 150,
      },
    ],
    []
  );

  const columnsReferidoMel = useMemo(
    () => [
      {
        accessorKey: "nombre",
        header: "Nombre",
        size: 150,
      },
      {
        accessorKey: "apellido",
        header: "Apellido",
        size: 150,
      },
      {
        accessorKey: "cedula",
        header: "Cedula",
        size: 150,
      },
      {
        accessorKey: "telefono",
        header: "Telefono",
        size: 150,
      },
      {
        accessorKey: "edad",
        header: "Edad",
        size: 150,
      },
      {
        accessorKey: "grupo_etnico",
        header: "Grupo Etnico",
        size: 150,
      },
      {
        accessorKey: "gestante",
        header: "Gestante",
        size: 150,
      },
      {
        accessorKey: "discapacidad",
        header: "Discapacidad",
        size: 150,
      },
      {
        accessorKey: "fecha_evaluacion",
        header: "Fecha de Ingreso",
        size: 150,
      },
      {
        accessorKey: "municipio",
        header: "Municipio",
        size: 150,
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
        size: 150,
      },
      {
        accessorKey: "diagnostico_ingreso",
        header: "Diagnostico Ingreso",
        size: 150,
      },
    ],
    []
  );

  //////////////////// ARRAY TABLA ALTA NUTRICIONAL /////////////////////////////
  const columnsAltaM5 = useMemo(
    () => [
      {
        accessorKey: "nombre",
        header: "Nombre",
        size: 150,
      },
      {
        accessorKey: "apellido",
        header: "Apellido",
        size: 150,
      },
      {
        accessorKey: "telefono_rep",
        header: "Telefono",
        size: 150,
      },
      {
        accessorKey: "edad",
        header: "Edad",
        size: 150,
      },
      {
        accessorKey: "grupo_etnico",
        header: "Grupo Etnico",
        size: 150,
      },
      {
        accessorKey: "registrado",
        header: "Registrado",
        size: 150,
      },
      {
        accessorKey: "discapacidad",
        header: "Discapacidad",
        size: 150,
      },
      {
        accessorKey: "fecha_evaluacion",
        header: "Fecha de Ingreso",
        size: 150,
      },

      {
        accessorKey: "municipio",
        header: "Municipio",
        size: 150,
      },
      {
        accessorKey: "parroquia",
        header: "Parroquia",
        size: 150,
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
        size: 150,
      },
      {
        accessorKey: "Diagnostico",
        header: "Diagnóstico Ingreso",
        size: 150,
      },
      {
        accessorKey: "evaluacion1",
        header: "Control I",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "MAM"
              ? "MAM"
              : cell.getValue() === "SAM"
              ? "SAM"
              : "S/D"}
          </Typography>
        )
      },
      {
        accessorKey: "evaluacion2",
        header: "Control II",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "MAM"
              ? "MAM"
              : cell.getValue() === "SAM"
              ? "SAM"
              : "S/D"}
          </Typography>
        )
      },
      {
        accessorKey: "evaluacion3",
        header: "Control III",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "MAM"
              ? "MAM"
              : cell.getValue() === "SAM"
              ? "SAM"
              : "S/D"}
          </Typography>
        )
      },
      {
        accessorKey: "evaluacion4",
        header: "Control IV",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "MAM"
              ? "MAM"
              : cell.getValue() === "SAM"
              ? "SAM"
              : "S/D"}
          </Typography>
        )
      },
      {
        accessorKey: "evaluacion5",
        header: "Control V",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "MAM"
              ? "MAM"
              : cell.getValue() === "SAM"
              ? "SAM"
              : "S/D"}
          </Typography>
        )
      },
      {
        accessorKey: "evaluacion6",
        header: "Control VI",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "MAM"
              ? "MAM"
              : cell.getValue() === "SAM"
              ? "SAM"
              : "S/D"}
          </Typography>
        )
      },
      {
        accessorKey: "evaluacion7",
        header: "Control VII",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "MAM"
              ? "MAM"
              : cell.getValue() === "SAM"
              ? "SAM"
              : "S/D"}
          </Typography>
        )
      },
      {
        accessorKey: "evaluacion8",
        header: "Control VIII",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "MAM"
              ? "MAM"
              : cell.getValue() === "SAM"
              ? "SAM"
              : "S/D"}
          </Typography>
        ),
      },
      {
        accessorKey: "evolucion",
        header: "Evolución",
        size: 150,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={{
              backgroundColor:
                cell.getValue() === "Positiva" ? "green" : cell.getValue() === "Negativa" ? "firebrick" : "black",
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "9ch",
              p: "0.25rem",
            }}
          >
            {cell.getValue()}
          </Box>
        ),
      },
    ],
    []
  );

  const columnsAltaMicro = useMemo(
    () => [
      {
        accessorKey: "nombre",
        header: "Nombre",
        size: 150,
      },
      {
        accessorKey: "apellido",
        header: "Apellido",
        size: 150,
      },
      {
        accessorKey: "telefono_rep",
        header: "Telefono",
        size: 150,
      },
      {
        accessorKey: "edad",
        header: "Edad",
        size: 150,
      },
      {
        accessorKey: "grupo_etnico",
        header: "Grupo Etnico",
        size: 150,
      },
      {
        accessorKey: "registrado",
        header: "Registrado",
        size: 150,
      },
      {
        accessorKey: "discapacidad",
        header: "discapacidad",
        size: 150,
      },
      {
        accessorKey: "fecha_evaluacion",
        header: "Fecha de Ingreso",
        size: 150,
      },
      {
        accessorKey: "municipio",
        header: "Municipio",
        size: 150,
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
        size: 150,
      },
      {
        accessorKey: "evaluacion1",
        header: "Control I",
        size: 150,
      },
      {
        accessorKey: "evaluacion2",
        header: "Control II",
        size: 150,
      },
      {
        accessorKey: "evaluacion3",
        header: "Control III",
        size: 150,
      },
      {
        accessorKey: "evaluacion4",
        header: "Control IV",
        size: 150,
      },
    ],
    []
  );
  const columnsAltaMel = useMemo(
    () => [
      {
        accessorKey: "nombre",
        header: "Nombre",
        size: 150,
      },
      {
        accessorKey: "apellido",
        header: "Apellido",
        size: 150,
      },
      {
        accessorKey: "cedula",
        header: "Cedula",
        size: 150,
      },
      {
        accessorKey: "telefono",
        header: "Telefono",
        size: 150,
      },
      {
        accessorKey: "edad",
        header: "Edad",
        size: 150,
      },
      {
        accessorKey: "grupo_etnico",
        header: "Grupo Etnico",
        size: 150,
      },
      {
        accessorKey: "gestante",
        header: "Gestante",
        size: 150,
      },
      {
        accessorKey: "discapacidad",
        header: "Discapacidad",
        size: 150,
      },
      {
        accessorKey: "fecha_evaluacion",
        header: "Fecha de Ingreso",
        size: 150,
      },
      {
        accessorKey: "municipio",
        header: "Municipio",
        size: 150,
      },
      {
        accessorKey: "comunidad",
        header: "Comunidad",
        size: 150,
      },
      {
        accessorKey: "diagnostico_ingreso",
        header: "Diagnostico Ingreso",
        size: 150,
      },
      {
        accessorKey: "evaluacion1",
        header: "Control I",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "Bajo"
              ? "Bajo Peso"
              : "S/D"}
          </Typography>
        )
      },
      {
        accessorKey: "evaluacion2",
        header: "Control II",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "Bajo"
              ? "Bajo Peso"
              : "S/D"}
          </Typography>
        )
      },
      {
        accessorKey: "evaluacion3",
        header: "Control III",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "Bajo"
              ? "Bajo Peso"
              : "S/D"}
          </Typography>
        )
      },
      {
        accessorKey: "evaluacion4",
        header: "Control IV",
        size: 150,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue() === "AD"
              ? "Adecuado"
              : cell.getValue() === "Bajo"
              ? "Bajo Peso"
              : "S/D"}
          </Typography>
        )
      },
      {
        accessorKey: "evolucion",
        header: "Evolución",
        size: 150,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={{
              backgroundColor:
                cell.getValue() === "Positiva" ? "green" : cell.getValue() === "Negativa" ? "firebrick" : "black",
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "9ch",
              p: "0.25rem",
            }}
          >
            {cell.getValue()}
          </Box>
        ),
      },
    ],
    []
  );

  //////////////////////////////////////////////////

  const [ejecutar, setEjecutar] = React.useState(true);
  const [dataSam, setDataSam] = React.useState([]);
  const [dataMel, setDataMel] = React.useState([]);
  const [dataMicro, setDataMicro] = React.useState([]);

  const beneficiarioSamMan = async () => {
    try {
      const { data1 } = await axios
        .get("/beneficiariosingreso/", {
          params: {
            id: 33,
          },
        })
        .then(function (response) {
          // setAlerta({})
          let datos = response.data;

          setDataSam(datos);

          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const beneficiarioMel = async () => {
    try {
      const { data1 } = await axios
        .get("/beneficiariosingresomel/", {
          params: {
            id: 33,
          },
        })
        .then(function (response) {
          // setAlerta({})
          let datos = response.data;

          setDataMel(datos);

          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const beneficiarioMicro = async () => {
    try {
      const { data1 } = await axios
        .get("/todosbeneficiariosnuevo/", {
          params: {
            id: 33,
          },
        })
        .then(function (response) {
          // setAlerta({})
          let datos = response.data;
          console.log(response.data, "nuevos");
          setDataMicro(datos);

          setEjecutar(false);
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const todosSeguimiento = async () => {
    try {
      const { data1 } = await axios
        .get("/gettodosseguimientos/")
        .then(function (response) {
          // setAlerta({})
          let datos = response.data;

          setTodosSeguimientos(datos);

          setEjecutar(false);
          console.log(datos, "Todos");
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const [todosSeguimientosBeneficiarios, setTodosSeguimientosBeneficiarios] =
    React.useState([]);
  const todosSeguimientoBeneficiario = async () => {
    try {
      const { data1 } = await axios
        .get("/getTodosSeguimientosBeneficiarios/")
        .then(function (response) {
          // setAlerta({})
          let datos = response.data;

          setTodosSeguimientosBeneficiarios(datos);

          setEjecutar(false);
          console.log(datos[0].sam, "Todos Seguimientos");
          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const [seguimientos, setSeguimientos] = React.useState([]);
  const getseguimientos = async (id) => {
    try {
      const { data1 } = await axios
        .get(`/getseguimientos/`, {
          params: {
            idBeneficiario: id,
          },
        })
        .then(function (response) {
          // setAlerta({})
          console.log(response.data, "seg");
          setSeguimientos(response.data);
          setIdSeguimiento(response.data[0].idSeguimiento);
          setFechaEvaluacionEditar(response.data[0].fecha_evaluacion);
          setEstatusM5Editar(response.data[0].estado);
          setOptAsistEditar(response.data[0].asistio);
          setOptEdemaEditar(response.data[0].edema);
          setPesoBenefEditar(response.data[0].peso);
          setTallaBenefEditar(response.data[0].talla);
          setPuntajeZEditar(response.data[0].puntaje_z);
          setDiagPZEditar(response.data[0].clasificacion_nut);
          setPBEditar(response.data[0].circunferencia);
          setDiagPBEditar(response.data[0].clasificacion_pb);
          setDiagFinalEditar(response.data[0].diagnostico_ingreso);
          setObservacionesM5Editar(response.data[0].diagnostico_ingreso);
          ///////////////////MEL///////////////////
          setOptAsistMelEditar(response.data[0].asistio);
          setOptRazonMelEditar(response.data[0].razon);
          setFechaEvalMelEditar(response.data[0].fecha_evaluacion);
          setEstatusMelEditar(response.data[0].estado);
          setSemGestEditar(response.data[0].gestacion);
          setPesoMelEditar(response.data[0].peso);
          setTallaMelEditar(response.data[0].talla);
          setImcEditar(response.data[0].imc);
          setDiagImcEditar(response.data[0].diagnostico_imc);
          setPbMelEditar(response.data[0].circunferencia);
          setDiagPbMelEditar(response.data[0].clasificacion_pb);
          setDiagFinalMelEditar(response.data[0].diagnostico_ingreso);
          ////////////////////////MICRO//////////////////////////////////

          setOptSegEditar(response.data[0].asistio);
          setFechaEvalMicroEditar(response.data[0].fecha_evaluacion);
          setEstatusMicroEditar(response.data[0].estado);
          setOptEsquemaEditar(response.data[0].esquema);
          setMotivoEsquemaEditar(response.data[0].motivoEsquema);
          setConsejeriaEditar(response.data[0].consejeria);
          setOptMotivoEditar(response.data[0].motivo);
          setObservacionesMNEditar(response.data[0].observacion);
        })
        .catch(function (error) {
          setSeguimientos([]);
          console.log(error);
          return;
        });
    } catch (error) {
      return;
    }
  };

  const [diagSemGestMel, setDiagSemGestMel] = useState("");
  const [diagSemGestMelEditar, setDiagSemGestMelEditar] = useState("");

  ////////////////  DIAGNÓSTICO Semanas Gestacion ///////////////
  useEffect(() => {
    if (optAsistMel == "Si") {
      if (semGest == "6") {
        if (Number(imc) < 20 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 20 && Number(imc) <= 24.9 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 25 && Number(imc) <= 30 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 30 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////////
      if (semGest == "7") {
        if (Number(imc) < 20.1 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 20.1 && Number(imc) <= 24.9 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 25 && Number(imc) <= 30 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 30 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      ///////////////////////////
      if (semGest == "8") {
        if (Number(imc) < 20.2 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 20.2 && Number(imc) <= 25 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 25.1 && Number(imc) <= 30.1 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 30.1 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      ///////////////////////////
      if (semGest == "9") {
        if (Number(imc) < 20.2 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 20.2 && Number(imc) <= 25.1 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 25.2 && Number(imc) <= 30.2 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 30.2 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGest == "10") {
        if (Number(imc) < 20.3 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 20.3 && Number(imc) <= 25.2 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 25.3 && Number(imc) <= 30.2 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 30.2 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////
      if (semGest == "11") {
        if (Number(imc) < 20.4 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 20.4 && Number(imc) <= 25.3 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 25.4 && Number(imc) <= 30.3 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 30.3 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGest == "12") {
        if (Number(imc) < 20.5 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 20.5 && Number(imc) <= 25.4 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 25.5 && Number(imc) <= 30.3 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 30.3 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      ///////////////////////////
      if (semGest == "13") {
        if (Number(imc) < 20.7 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 20.7 && Number(imc) <= 25.6 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 25.7 && Number(imc) <= 30.4 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 30.4 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      ///////////////////////////
      if (semGest == "14") {
        if (Number(imc) < 20.8 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 20.8 && Number(imc) <= 25.7 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 25.8 && Number(imc) <= 30.5 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 30.5 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////
      if (semGest == "15") {
        if (Number(imc) < 20.9 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 20.9 && Number(imc) <= 25.8 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 25.9 && Number(imc) <= 30.6 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 30.6 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////////
      if (semGest == "16") {
        if (Number(imc) < 21.1 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 21.1 && Number(imc) <= 25.9 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 26 && Number(imc) <= 30.7 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 30.7 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////////
      if (semGest == "17") {
        if (Number(imc) < 21.2 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 21.2 && Number(imc) <= 26 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 26.1 && Number(imc) <= 30.8 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 30.8 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      ///////////////////////////
      if (semGest == "18") {
        if (Number(imc) < 21.3 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 21.3 && Number(imc) <= 26.1 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 26.2 && Number(imc) <= 30.9 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 30.9 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////
      if (semGest == "19") {
        if (Number(imc) < 21.5 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 21.5 && Number(imc) <= 26.2 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 26.3 && Number(imc) <= 30.9 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 30.9 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////
      if (semGest == "20") {
        if (Number(imc) < 21.6 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 21.6 && Number(imc) <= 26.3 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 26.4 && Number(imc) <= 31 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 31 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      ////////////////////////
      if (semGest == "21") {
        if (Number(imc) < 21.8 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 21.8 && Number(imc) <= 26.4 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 26.5 && Number(imc) <= 31.1 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 31.1 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////
      if (semGest == "22") {
        if (Number(imc) < 21.9 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 21.9 && Number(imc) <= 26.6 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 26.7 && Number(imc) <= 31.2 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 31.2 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      ///////////////////////////
      if (semGest == "23") {
        if (Number(imc) < 22.1 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 22.1 && Number(imc) <= 26.7 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 26.8 && Number(imc) <= 31.3 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 31.3 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGest == "24") {
        if (Number(imc) < 22.3 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 22.3 && Number(imc) <= 26.9 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 27 && Number(imc) <= 31.5 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 31.5 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////
      if (semGest == "25") {
        if (Number(imc) < 22.5 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 22.5 && Number(imc) <= 27 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 27.1 && Number(imc) <= 31.6 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 31.6 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      ////////////////////////
      if (semGest == "26") {
        if (Number(imc) < 22.7 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 22.7 && Number(imc) <= 27.2 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 27.2 && Number(imc) <= 31.7 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 31.7 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGest == "27") {
        if (Number(imc) < 22.8 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 22.8 && Number(imc) <= 27.3 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 27.4 && Number(imc) <= 31.8 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 31.8 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGest == "28") {
        if (Number(imc) < 23 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 23 && Number(imc) <= 27.5 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 27.6 && Number(imc) <= 31.9 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 31.9 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGest == "29") {
        if (Number(imc) < 23.2 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 23.2 && Number(imc) <= 27.6 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 27.7 && Number(imc) <= 32 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 32 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGest == "30") {
        if (Number(imc) < 23.4 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 23.4 && Number(imc) <= 27.8 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 27.9 && Number(imc) <= 32.1 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 32.1 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGest == "31") {
        if (Number(imc) < 23.5 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 23.5 && Number(imc) <= 27.9 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 28 && Number(imc) <= 32.2 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 32.2 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGest == "32") {
        if (Number(imc) < 23.7 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 23.7 && Number(imc) <= 28 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 28.1 && Number(imc) <= 32.3 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 32.3 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGest == "33") {
        if (Number(imc) < 23.9 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 23.9 && Number(imc) <= 28.1 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 28.2 && Number(imc) <= 32.4 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 32.4 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGest == "34") {
        if (Number(imc) < 24 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 24 && Number(imc) <= 28.3 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 28.4 && Number(imc) <= 32.5 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 32.5 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGest == "35") {
        if (Number(imc) < 24.2 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 24.2 && Number(imc) <= 28.4 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 28.5 && Number(imc) <= 32.6 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 32.6 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGest == "36") {
        if (Number(imc) < 24.3 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 24.3 && Number(imc) <= 28.5 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 28.6 && Number(imc) <= 32.7 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 32.7 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGest == "37") {
        if (Number(imc) < 24.5 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 24.5 && Number(imc) <= 28.7 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 28.8 && Number(imc) <= 32.8 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 32.7 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGest == "38") {
        if (Number(imc) < 24.6 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 24.6 && Number(imc) <= 28.8 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 28.9 && Number(imc) <= 32.9 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 32.9 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGest == "39") {
        if (Number(imc) < 24.8 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 24.8 && Number(imc) <= 28.9 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 29 && Number(imc) <= 33 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 33 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGest == "40") {
        if (Number(imc) < 25 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 25 && Number(imc) <= 29.1 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 29.2 && Number(imc) <= 33.1 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 33.1 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
      ///////////////////////////////
      if (semGest == "41") {
        if (Number(imc) < 25.1 && imc !== "") {
          console.log("Bajo Peso");
        } else {
          if (Number(imc) >= 25.1 && Number(imc) <= 29.2 && imc !== "") {
            console.log("Adecuado");
          } else {
            if (Number(imc) >= 29.3 && Number(imc) <= 33.2 && imc !== "") {
              console.log("Sobrepeso");
            } else {
              if (Number(imc) > 33.2 && imc !== "") {
                console.log("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////
      if (semGest == "42") {
        if (Number(imc) < 25.1 && imc !== "") {
          setDiagSemGestMel("Bajo Peso");
        } else {
          if (Number(imc) >= 25.1 && Number(imc) <= 29.2 && imc !== "") {
            setDiagSemGestMel("Adecuado");
          } else {
            if (Number(imc) >= 29.3 && Number(imc) <= 33.2 && imc !== "") {
              setDiagSemGestMel("Sobrepeso");
            } else {
              if (Number(imc) > 33.2 && imc !== "") {
                setDiagSemGestMel("Obesidad");
              }
            }
          }
        }
      }
    }
  }, [imc]);

  /////////////////////
  useEffect(() => {
    if (optAsistMelEditar == "Si") {
      if (semGestEditar == "6") {
        if (Number(imcEditar) < 20 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 20 &&
            Number(imcEditar) <= 24.9 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 25 &&
              Number(imcEditar) <= 30 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 30 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////////
      if (semGestEditar == "7") {
        if (Number(imcEditar) < 20.1 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 20.1 &&
            Number(imcEditar) <= 24.9 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 25 &&
              Number(imcEditar) <= 30 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 30 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      ///////////////////////////
      if (semGestEditar == "8") {
        if (Number(imcEditar) < 20.2 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 20.2 &&
            Number(imcEditar) <= 25 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 25.1 &&
              Number(imcEditar) <= 30.1 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 30.1 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      ///////////////////////////
      if (semGestEditar == "9") {
        if (Number(imcEditar) < 20.2 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 20.2 &&
            Number(imcEditar) <= 25.1 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 25.2 &&
              Number(imcEditar) <= 30.2 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 30.2 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGestEditar == "10") {
        if (Number(imcEditar) < 20.3 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 20.3 &&
            Number(imcEditar) <= 25.2 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 25.3 &&
              Number(imcEditar) <= 30.2 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 30.2 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////
      if (semGestEditar == "11") {
        if (Number(imcEditar) < 20.4 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 20.4 &&
            Number(imcEditar) <= 25.3 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 25.4 &&
              Number(imcEditar) <= 30.3 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 30.3 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGestEditar == "12") {
        if (Number(imcEditar) < 20.5 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 20.5 &&
            Number(imcEditar) <= 25.4 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 25.5 &&
              Number(imcEditar) <= 30.3 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 30.3 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      ///////////////////////////
      if (semGestEditar == "13") {
        if (Number(imcEditar) < 20.7 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 20.7 &&
            Number(imcEditar) <= 25.6 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 25.7 &&
              Number(imcEditar) <= 30.4 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 30.4 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      ///////////////////////////
      if (semGestEditar == "14") {
        if (Number(imcEditar) < 20.8 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 20.8 &&
            Number(imcEditar) <= 25.7 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 25.8 &&
              Number(imcEditar) <= 30.5 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 30.5 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////
      if (semGestEditar == "15") {
        if (Number(imcEditar) < 20.9 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 20.9 &&
            Number(imcEditar) <= 25.8 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 25.9 &&
              Number(imcEditar) <= 30.6 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 30.6 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////////
      if (semGestEditar == "16") {
        if (Number(imcEditar) < 21.1 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 21.1 &&
            Number(imcEditar) <= 25.9 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 26 &&
              Number(imcEditar) <= 30.7 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 30.7 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////////
      if (semGestEditar == "17") {
        if (Number(imcEditar) < 21.2 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 21.2 &&
            Number(imcEditar) <= 26 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 26.1 &&
              Number(imcEditar) <= 30.8 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 30.8 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      ///////////////////////////
      if (semGestEditar == "18") {
        if (Number(imcEditar) < 21.3 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 21.3 &&
            Number(imcEditar) <= 26.1 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 26.2 &&
              Number(imcEditar) <= 30.9 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 30.9 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////
      if (semGestEditar == "19") {
        if (Number(imcEditar) < 21.5 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 21.5 &&
            Number(imcEditar) <= 26.2 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 26.3 &&
              Number(imcEditar) <= 30.9 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 30.9 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////
      if (semGestEditar == "20") {
        if (Number(imcEditar) < 21.6 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 21.6 &&
            Number(imcEditar) <= 26.3 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 26.4 &&
              Number(imcEditar) <= 31 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 31 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      ////////////////////////
      if (semGestEditar == "21") {
        if (Number(imcEditar) < 21.8 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 21.8 &&
            Number(imcEditar) <= 26.4 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 26.5 &&
              Number(imcEditar) <= 31.1 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 31.1 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////
      if (semGestEditar == "22") {
        if (Number(imcEditar) < 21.9 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 21.9 &&
            Number(imcEditar) <= 26.6 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 26.7 &&
              Number(imcEditar) <= 31.2 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 31.2 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      ///////////////////////////
      if (semGestEditar == "23") {
        if (Number(imcEditar) < 22.1 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 22.1 &&
            Number(imcEditar) <= 26.7 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 26.8 &&
              Number(imcEditar) <= 31.3 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 31.3 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGestEditar == "24") {
        if (Number(imcEditar) < 22.3 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 22.3 &&
            Number(imcEditar) <= 26.9 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 27 &&
              Number(imcEditar) <= 31.5 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 31.5 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////
      if (semGestEditar == "25") {
        if (Number(imcEditar) < 22.5 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 22.5 &&
            Number(imcEditar) <= 27 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 27.1 &&
              Number(imcEditar) <= 31.6 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 31.6 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      ////////////////////////
      if (semGestEditar == "26") {
        if (Number(imcEditar) < 22.7 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 22.7 &&
            Number(imcEditar) <= 27.2 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 27.2 &&
              Number(imcEditar) <= 31.7 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 31.7 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGestEditar == "27") {
        if (Number(imcEditar) < 22.8 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 22.8 &&
            Number(imcEditar) <= 27.3 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 27.4 &&
              Number(imcEditar) <= 31.8 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 31.8 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGestEditar == "28") {
        if (Number(imcEditar) < 23 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 23 &&
            Number(imcEditar) <= 27.5 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 27.6 &&
              Number(imcEditar) <= 31.9 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 31.9 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGestEditar == "29") {
        if (Number(imcEditar) < 23.2 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 23.2 &&
            Number(imcEditar) <= 27.6 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 27.7 &&
              Number(imcEditar) <= 32 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 32 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGestEditar == "30") {
        if (Number(imcEditar) < 23.4 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 23.4 &&
            Number(imcEditar) <= 27.8 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 27.9 &&
              Number(imcEditar) <= 32.1 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 32.1 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGestEditar == "31") {
        if (Number(imcEditar) < 23.5 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 23.5 &&
            Number(imcEditar) <= 27.9 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 28 &&
              Number(imcEditar) <= 32.2 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 32.2 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGestEditar == "32") {
        if (Number(imcEditar) < 23.7 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 23.7 &&
            Number(imcEditar) <= 28 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 28.1 &&
              Number(imcEditar) <= 32.3 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 32.3 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGestEditar == "33") {
        if (Number(imcEditar) < 23.9 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 23.9 &&
            Number(imcEditar) <= 28.1 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 28.2 &&
              Number(imcEditar) <= 32.4 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 32.4 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGestEditar == "34") {
        if (Number(imcEditar) < 24 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 24 &&
            Number(imcEditar) <= 28.3 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 28.4 &&
              Number(imcEditar) <= 32.5 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 32.5 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGestEditar == "35") {
        if (Number(imcEditar) < 24.2 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 24.2 &&
            Number(imcEditar) <= 28.4 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 28.5 &&
              Number(imcEditar) <= 32.6 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 32.6 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGestEditar == "36") {
        if (Number(imcEditar) < 24.3 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 24.3 &&
            Number(imcEditar) <= 28.5 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 28.6 &&
              Number(imcEditar) <= 32.7 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 32.7 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGestEditar == "37") {
        if (Number(imcEditar) < 24.5 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 24.5 &&
            Number(imcEditar) <= 28.7 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 28.8 &&
              Number(imcEditar) <= 32.8 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 32.7 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGestEditar == "38") {
        if (Number(imcEditar) < 24.6 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 24.6 &&
            Number(imcEditar) <= 28.8 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 28.9 &&
              Number(imcEditar) <= 32.9 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 32.9 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGestEditar == "39") {
        if (Number(imcEditar) < 24.8 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 24.8 &&
            Number(imcEditar) <= 28.9 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 29 &&
              Number(imcEditar) <= 33 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 33 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      //////////////////////////
      if (semGestEditar == "40") {
        if (Number(imcEditar) < 25 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 25 &&
            Number(imcEditar) <= 29.1 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 29.2 &&
              Number(imcEditar) <= 33.1 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 33.1 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
      ///////////////////////////////
      if (semGestEditar == "41") {
        if (Number(imcEditar) < 25.1 && imcEditar !== "") {
          console.log("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 25.1 &&
            Number(imcEditar) <= 29.2 &&
            imcEditar !== ""
          ) {
            console.log("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 29.3 &&
              Number(imcEditar) <= 33.2 &&
              imcEditar !== ""
            ) {
              console.log("Sobrepeso");
            } else {
              if (Number(imcEditar) > 33.2 && imcEditar !== "") {
                console.log("Obesidad");
              }
            }
          }
        }
      }
      /////////////////////////
      if (semGestEditar == "42") {
        if (Number(imcEditar) < 25.1 && imcEditar !== "") {
          setDiagSemGestMelEditar("Bajo Peso");
        } else {
          if (
            Number(imcEditar) >= 25.1 &&
            Number(imcEditar) <= 29.2 &&
            imcEditar !== ""
          ) {
            setDiagSemGestMelEditar("Adecuado");
          } else {
            if (
              Number(imcEditar) >= 29.3 &&
              Number(imcEditar) <= 33.2 &&
              imcEditar !== ""
            ) {
              setDiagSemGestMelEditar("Sobrepeso");
            } else {
              if (Number(imcEditar) > 33.2 && imcEditar !== "") {
                setDiagSemGestMelEditar("Obesidad");
              }
            }
          }
        }
      }
    }
  }, [imcEditar]);
  //Routes
  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };

  if (ejecutar) {
    beneficiarioSamMan();
    beneficiarioMel();
    beneficiarioMicro();
    todosSeguimiento();
    todosSeguimientoBeneficiario();
  }

  useEffect(() => {
    //////Totales/////
    let samTotal = dataSam;
    let microTotal = dataMicro;
    let melTotal = dataMel;
    ///////////////////////
    let evalSeguimiento = 0;
    let evalIngreso = 0;
    let contador = 0;
    let indiceContador = "";
    let contadorSeguimiento = 0;
    let numerosSeguimientos = 0;

    ////////////////////////////SAM//////////////////////////////
    for (let i = 0; i < samTotal.length; i++) {
      numerosSeguimientos = 0;
      for (let j = 0; j < todosSeguimientos.length; j++) {
        if (samTotal[i].id == todosSeguimientos[j].idBeneficiario) {
          numerosSeguimientos = numerosSeguimientos + 1;
          samTotal[i].seguimientos = numerosSeguimientos;
          samTotal[i][todosSeguimientos[j].num_seguimiento] =
            todosSeguimientos[j].diagnostico_ingreso;

          if (
            todosSeguimientos[j].diagnostico_ingreso ==
            "Desnutrición Aguda Severa"
          ) {
            evalSeguimiento = 1;
          } else {
            if (
              todosSeguimientos[j].diagnostico_ingreso ==
              "Desnutrición Aguda Moderada"
            ) {
              evalSeguimiento = 2;
            } else {
              if (todosSeguimientos[j].diagnostico_ingreso == "Adecuado") {
                evalSeguimiento = 3;
              } else {
                if (todosSeguimientos[j].diagnostico_ingreso == "Soprepeso") {
                  evalSeguimiento = 4;
                } else {
                  evalSeguimiento = 5;
                }
              }
            }
          }
          if (samTotal[i].diagnostico_ingreso == "Desnutrición Aguda Severa") {
            evalIngreso = 1;
          } else {
            if (
              samTotal[i].diagnostico_ingreso == "Desnutrición Aguda Moderada"
            ) {
              evalIngreso = 2;
            } else {
              if (samTotal[i].diagnostico_ingreso == "Adecuado") {
                evalIngreso = 5;
              } else {
                if (samTotal[i].diagnostico_ingreso == "Soprepeso") {
                  evalIngreso = 3;
                } else {
                  evalIngreso = 4;
                }
              }
            }
          }
          if (evalSeguimiento > evalIngreso) {
            samTotal[i].evolucion = "Positiva";
          } else {
            samTotal[i].evolucion = "Negativa";
          }

          if (indiceContador == "") {
            if (todosSeguimientos[j].diagnostico_ingreso == "S/D") {
              indiceContador = j;
              contador = contador + 1;
            }
          } else {
            if (j == indiceContador + 1) {
              console.log(contador, "entro");
              contador = contador + 1;
            }
          }
          console.log(contador, "total");

          if ((todosSeguimientos[j].diagnostico_ingreso = "S/D")) {
            indiceContador = j;
            contadorSeguimiento = contadorSeguimiento + 1;
          }

          if (contadorSeguimiento < 4) {
            samTotal[i].adherencia = "No";
            if (contador >= 2) {
              samTotal[i].adherencia = "Desertó";
            }
          } else {
            samTotal[i].adherencia = "Si";
          }
        } else {
          samTotal[i].seguimientos = numerosSeguimientos;
        }
      }
    }
    ///////////////////////MICRO////////////////////////////////
    for (let i = 0; i < microTotal.length; i++) {
      numerosSeguimientos = 0;
      for (let j = 0; j < todosSeguimientos.length; j++) {
        if (microTotal[i].id == todosSeguimientos[j].idBeneficiario) {
          numerosSeguimientos = numerosSeguimientos + 1;
          microTotal[i].seguimientos = numerosSeguimientos;
          microTotal[i][todosSeguimientos[j].num_seguimiento] =
            todosSeguimientos[j].asistio;

          if (indiceContador == "") {
            if (todosSeguimientos[j].asistio == "No") {
              indiceContador = j;
              contador = contador + 1;
            }
          } else {
            if (j == indiceContador + 1) {
              console.log(contador, "entro");
              contador = contador + 1;
            }
          }
          console.log(contador, "total");

          if ((todosSeguimientos[j].asistio = "No")) {
            indiceContador = j;
            contadorSeguimiento = contadorSeguimiento + 1;
          }

          if (contadorSeguimiento < 2) {
            microTotal[i].adherencia = "No";
            if (contador >= 2) {
              microTotal[i].adherencia = "Desertó";
            }
          } else {
            microTotal[i].adherencia = "Si";
          }
        } else {
          microTotal[i].seguimientos = numerosSeguimientos;
        }
      }
    }
    //////////////////////////MEL///////////////////////////////////
    for (let i = 0; i < melTotal.length; i++) {
      numerosSeguimientos = 0;
      for (let j = 0; j < todosSeguimientos.length; j++) {
        if (melTotal[i].idmel == todosSeguimientos[j].idBeneficiario) {
          numerosSeguimientos = numerosSeguimientos + 1;
          melTotal[i].seguimientos = numerosSeguimientos;
          melTotal[i][todosSeguimientos[j].num_seguimiento] =
            todosSeguimientos[j].diagnostico_ingreso;

          if (todosSeguimientos[j].diagnostico_ingreso == "Bajo Peso") {
            evalSeguimiento = 1;
          } else {
            if (todosSeguimientos[j].diagnostico_ingreso == "Adecuado") {
              evalSeguimiento = 2;
            } else {
              if (todosSeguimientos[j].diagnostico_ingreso == "Soprepeso") {
                evalSeguimiento = 3;
              } else {
                evalSeguimiento = 4;
              }
            }
          }
          if (melTotal[i].diagnostico_ingreso == "Bajo Peso") {
            evalIngreso = 1;
          } else {
            if (melTotal[i].diagnostico_ingreso == "Adecuado") {
              evalIngreso = 2;
            } else {
              if (melTotal[i].diagnostico_ingreso == "Soprepeso") {
                evalIngreso = 3;
              } else {
                evalIngreso = 4;
              }
            }
          }
          if (evalSeguimiento > evalIngreso) {
            melTotal[i].evolucion = "Positiva";
          } else {
            melTotal[i].evolucion = "Negativa";
          }

          if (indiceContador == "") {
            if (todosSeguimientos[j].diagnostico_ingreso == "S/D") {
              indiceContador = j;
              contador = contador + 1;
            }
          } else {
            if (j == indiceContador + 1) {
              console.log(contador, "entro");
              contador = contador + 1;
            }
          }
          console.log(contador, "total");

          if ((todosSeguimientos[j].diagnostico_ingreso = "S/D")) {
            indiceContador = j;
            contadorSeguimiento = contadorSeguimiento + 1;
          }
          if (contadorSeguimiento < 2) {
            melTotal[i].adherencia = "No";
            if (contador >= 2) {
              melTotal[i].adherencia = "Desertó";
            }
          } else {
            melTotal[i].adherencia = "Si";
          }
        } else {
          melTotal[i].seguimientos = numerosSeguimientos;
        }
      }
    }
    ////////////////////////////////////////////////////////////////
    console.log(contadorSeguimiento, "CountSe");
    setDataSam([]);
    console.log(samTotal, "paso");
    console.log(todosSeguimientos, "paso");
  }, [optBenef]);

  /////////////////////////////////  SPINNER  /////////////////////////////////
    if (dataMicro == "")
      return (
        <Container component="main" maxWidth="xs">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
            <Typography variant="h3" ml={2}>Cargando...</Typography>
          </Backdrop>
        </Container>
      );

  return (
    <Box m="1.5rem 2.5rem">
      {rol === "1" ||
      rol === "2" ||
      rol === "6" ||
      rol === "7" ||
      rol === "8" ||
      rol === "9" ||
      rol === "10" ? (
        <>
          <Collapse in={openNoti}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenNoti(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{
                position: "fixed",
                right: 12,
                zIndex: 999,
                borderRadius: 2,
                boxShadow: 4,
              }}
            >
              ¡Control guardado exitosamente!
            </Alert>
          </Collapse>
          <Collapse in={openNotiError}>
            <Alert
              variant="filled"
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenNotiError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{
                position: "fixed",
                right: 12,
                zIndex: 999,
                borderRadius: 2,
                boxShadow: 4,
              }}
            >
              ¡Error al guardar datos!
            </Alert>
          </Collapse>
          <Collapse in={openNotiEliminar}>
            <Alert
              variant="filled"
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenNotiEliminar(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{
                position: "fixed",
                right: 12,
                zIndex: 999,
                borderRadius: 2,
                boxShadow: 4,
              }}
            >
              ¡Datos Eliminados!
            </Alert>
          </Collapse>
          <Box
            role="presentation"
            onClick={handleClickBreadCrumbs}
            sx={{ mb: 3 }}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/inicio"
                onClick={(event) => handleLinkHome(event, "home")}
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Inicio
              </Link>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <FamilyRestroomOutlinedIcon
                  sx={{ mr: 0.5 }}
                  fontSize="inherit"
                />
                Beneficiarios
              </Typography>
            </Breadcrumbs>
            <Typography
              variant="h3"
              sx={{ fontWeight: "bold", mt: 2 }}
              hidden={optBenef === "Búsqueda" || optBenef === "Control"}
            >
              Beneficiarios
            </Typography>
            <Typography
              variant="h3"
              sx={{ fontWeight: "bold", mt: 2 }}
              hidden={optBenef === "" || optBenef === "Control"}
            >
              Búsqueda de Beneficiarios
            </Typography>
            <Typography
              variant="h3"
              sx={{ fontWeight: "bold", mt: 2 }}
              hidden={optBenef === "Búsqueda" || optBenef === ""}
            >
              Control y Seguimiento de Beneficiarios
            </Typography>
            <Grid container spacing={2} mt={3}>
              <Grid xs={12} sm={3} ml={2}>
                <Box sx={{ minWidth: 120, mb: 3 }} fullwidth>
                  <FormControl
                    sx={{
                      width: isSmallScreen ? "100%" : "42%",
                      bgcolor: theme.palette.background.alt,
                    }}
                  >
                    <InputLabel>Opciones</InputLabel>
                    <Select
                      value={optBenef}
                      label="Opciones"
                      onChange={handleOptBenef}
                    >
                      <MenuItem value="">
                        <em>Seleccionar Opción...</em>
                      </MenuItem>
                      <MenuItem value={"Búsqueda"}>Búsqueda</MenuItem>
                      <MenuItem value={"Control"}>Control</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/* ///////////////////////////////// NADA SELECCIONADO /////////////////////////////// */}
          <Box
            mt="10px"
            hidden={optBenef === "Búsqueda" || optBenef === "Control"}
          >
            <Container>
              <Card sx={{ boxShadow: 0, bgcolor: "transparent" }}>
                <CardContent>
                  <Typography className="text-center">
                    <InfoOutlinedIcon
                      sx={{
                        fontWeight: "bold",
                        mt: 5,
                        fontSize: 150,
                        color: "GrayText",
                      }}
                    />
                  </Typography>
                  <Typography
                    variant={isSmallScreen ? "h4" : "h3"}
                    className="text-center"
                    sx={{ mt: 5, mb: 5, color: "GrayText" }}
                  >
                    Selecciona una Opción para Iniciar.
                  </Typography>
                </CardContent>
              </Card>
            </Container>
          </Box>
          {/* ////////////////////////////// "BÚSQUEDA" SELECCIONADO //////////////////////////// */}
          <Box hidden={optBenef === "" || optBenef === "Control"}>
            <Container>
              <Box sx={{ bgcolor: "transparent" }}>
                <SearchPage />
              </Box>
            </Container>
          </Box>
          {/* /////////////////////////////// "CONTROL" SELECCIONADO //////////////////////////// */}
          <Box hidden={optBenef === "" || optBenef === "Búsqueda"}>
            <Container>
              <Box
                mt="10px"
                display="grid"
                gridTemplateColumns="repeat(8, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                  "& > div": {
                    gridColumn: isNonMediumScreens ? undefined : "span 12",
                  },
                }}
              >
                <Box
                  gridColumn="span 8"
                  gridRow="span 3"
                  backgroundColor={theme.palette.background.alt}
                  p="1.5rem"
                  borderRadius="1.5rem"
                  mt="20px"
                  height="fit-content"
                  sx={{ boxShadow: 4 }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Tabs
                      value={tabsCat}
                      variant={isSmallScreen ? "scrollable" : "fullWidth"}
                      onChange={handleTabsCat}
                      scrollButtons
                      allowScrollButtonsMobile
                      aria-label="basic tabs example"
                    >
                      <Tab
                        icon={<PendingOutlined />}
                        iconPosition="start"
                        label="Pendientes"
                        {...a11yProps(0)}
                      />
                      <Tab
                        icon={<ContentPasteSearch />}
                        iconPosition="start"
                        label="En Control"
                        {...a11yProps(1)}
                      />
                      <Tab
                        icon={<SentimentVeryDissatisfied />}
                        iconPosition="start"
                        label="Desertor"
                        {...a11yProps(2)}
                      />
                      <Tab
                        icon={<ContentPasteGo />}
                        iconPosition="start"
                        label="Referido"
                        {...a11yProps(3)}
                      />
                      <Tab
                        icon={<CheckCircleOutline />}
                        iconPosition="start"
                        label="Alta Nutricional"
                        {...a11yProps(4)}
                      />
                    </Tabs>
                    {/* ////////////////////////// TAB PENDIENTES  /////////////////////////////////  */}
                    <CustomTabPanel value={tabsCat} index={0}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Typography variant="h5" mt={1} mb={2}>
                          <b>
                            Beneficiarios Pendientes por Control Nutricional
                          </b>
                        </Typography>
                        <Tabs
                          value={tabsPend}
                          variant={isSmallScreen ? "scrollable" : "fullWidth"}
                          onChange={handleTabsPend}
                          scrollButtons
                          allowScrollButtonsMobile
                          aria-label="basic tabs example"
                        >
                          <Tab
                            icon={<ChildCareOutlinedIcon />}
                            iconPosition="start"
                            label="Menores de 5 años - SAM y MAM"
                            {...a11yProps(0)}
                          />
                          <Tab
                            icon={<ChildCareOutlinedIcon />}
                            iconPosition="start"
                            label="Menores de 5 años - Micronutrientes"
                            {...a11yProps(1)}
                          />
                          <Tab
                            icon={<PregnantWomanOutlinedIcon />}
                            iconPosition="start"
                            label="MEL"
                            {...a11yProps(1)}
                          />
                        </Tabs>
                        <CustomTabPanel value={tabsPend} index={0}>
                          <MaterialReactTable
                            columns={columnsPendM5}
                            data={
                              todosSeguimientosBeneficiarios.length == 0
                                ? []
                                : dataMicro.ninosMam
                            }
                            localization={MRT_Localization_ES}
                            enableRowActions
                            positionExpandColumn="first"
                            positionActionsColumn="first"
                            renderRowActionMenuItems={({ closeMenu, row }) => [
                              <MenuItem
                                onClick={(event) => {
                                  closeMenu();
                                  handleOpenControl(row);
                                }}
                                /*style={{
                                  display:
                                    row.original.seguimientos < 8
                                      ? "block"
                                      : "none",
                                }}*/
                              >
                                <b>
                                  <ControlPointIcon sx={{ mr: 1 }} />
                                  Ingresar Control
                                </b>
                              </MenuItem>,
                            ]}
                            muiTopToolbarProps={{
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiTableHeadCellProps={{
                              align: "center",
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiBottomToolbarProps={{
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiTableBodyProps={{
                              sx: {
                                "& tr:nth-of-type(odd)": {
                                  backgroundColor:
                                    theme.palette.background.light,
                                },
                              },
                            }}
                            muiTableBodyCellProps={{
                              align: "center",
                            }}
                            muiTablePaperProps={{
                              elevation: 0,
                            }}
                            initialState={{
                              pagination: {
                                pageSize: 5,
                                pageIndex: 0,
                              },
                              sorting: [
                                {
                                  id: "fecha",
                                  desc: true,
                                },
                              ],
                            }}
                            muiTablePaginationProps={{
                              rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                              showFirstButton: false,
                              showLastButton: false,
                              SelectProps: {
                                native: true,
                              },
                              labelRowsPerPage: "Número de filas visibles",
                            }}
                          />
                        </CustomTabPanel>
                        <CustomTabPanel value={tabsPend} index={1}>
                          <MaterialReactTable
                            columns={columnsPendMicro}
                            data={
                              todosSeguimientosBeneficiarios.length == 0
                                ? []
                                : dataMicro.ninosControl
                            }
                            localization={MRT_Localization_ES}
                            enableRowActions
                            positionExpandColumn="first"
                            positionActionsColumn="first"
                            renderRowActionMenuItems={({ closeMenu, row }) => [
                              <MenuItem
                                onClick={(event) => {
                                  closeMenu();
                                  handleOpenControlMicro(row);
                                }}
                                style={{
                                  display:
                                    row.original.seguimientos < 4
                                      ? "block"
                                      : "none",
                                }}
                              >
                                <b>
                                  <ControlPointIcon sx={{ mr: 1 }} />
                                  Ingresar Seguimiento
                                </b>
                              </MenuItem>,
                            ]}
                            muiTopToolbarProps={{
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiTableHeadCellProps={{
                              align: "center",
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiBottomToolbarProps={{
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiTableBodyProps={{
                              sx: {
                                "& tr:nth-of-type(odd)": {
                                  backgroundColor:
                                    theme.palette.background.light,
                                },
                              },
                            }}
                            muiTableBodyCellProps={{
                              align: "center",
                            }}
                            muiTablePaperProps={{
                              elevation: 0,
                            }}
                            initialState={{
                              pagination: {
                                pageSize: 5,
                                pageIndex: 0,
                              },
                              sorting: [
                                {
                                  id: "fecha",
                                  desc: true,
                                },
                              ],
                            }}
                            muiTablePaginationProps={{
                              rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                              showFirstButton: false,
                              showLastButton: false,
                              SelectProps: {
                                native: true,
                              },
                              labelRowsPerPage: "Número de filas visibles",
                            }}
                            renderDetailPanel={({ row }) => (
                              <Box
                                sx={{
                                  display: "grid",
                                  margin: "auto",
                                  gridTemplateColumns: "1fr 1fr",
                                  width: "100%",
                                }}
                              >
                                <Typography>
                                  <b>Parroquia: </b> {row.original.parroquia}
                                </Typography>
                                <Typography>
                                  <b>Escolarizado: </b>{" "}
                                  {row.original.escolaridad}
                                </Typography>
                                <Typography>
                                  <b>Diagnóstico: </b>{" "}
                                  {row.original.diagnostico_ingreso}
                                </Typography>
                                <Typography>
                                  <b>Discapacidad: </b>{" "}
                                  {row.original.discapacidad}
                                </Typography>
                              </Box>
                            )}
                          />
                        </CustomTabPanel>
                        <CustomTabPanel value={tabsPend} index={2}>
                          <MaterialReactTable
                            columns={columnsPendMel}
                            data={
                              todosSeguimientosBeneficiarios.length == 0
                                ? []
                                : dataMicro.melBajo
                            }
                            localization={MRT_Localization_ES}
                            enableRowActions
                            positionExpandColumn="first"
                            positionActionsColumn="first"
                            renderRowActionMenuItems={({ closeMenu, row }) => [
                              <MenuItem
                                onClick={(event) => {
                                  closeMenu();
                                  handleOpenControlMel(row);
                                }}
                                style={{
                                  display:
                                    row.original.seguimientos < 4
                                      ? "block"
                                      : "none",
                                }}
                              >
                                <b>
                                  <ControlPointIcon sx={{ mr: 1 }} />
                                  Ingresar Control
                                </b>
                              </MenuItem>,
                            ]}
                            muiTopToolbarProps={{
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiTableHeadCellProps={{
                              align: "center",
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiBottomToolbarProps={{
                              sx: {
                                backgroundColor: theme.palette.background.alt,
                              },
                            }}
                            muiTableBodyProps={{
                              sx: {
                                "& tr:nth-of-type(odd)": {
                                  backgroundColor:
                                    theme.palette.background.light,
                                },
                              },
                            }}
                            muiTableBodyCellProps={{
                              align: "center",
                            }}
                            muiTablePaperProps={{
                              elevation: 0,
                            }}
                            initialState={{
                              pagination: {
                                pageSize: 5,
                                pageIndex: 0,
                              },
                              sorting: [
                                {
                                  id: "fecha",
                                  desc: true,
                                },
                              ],
                            }}
                            muiTablePaginationProps={{
                              rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                              showFirstButton: false,
                              showLastButton: false,
                              SelectProps: {
                                native: true,
                              },
                              labelRowsPerPage: "Número de filas visibles",
                            }}
                          />
                        </CustomTabPanel>
                      </Box>
                    </CustomTabPanel>
                    {/* ////////////////////////// TAB EN CONTROL  /////////////////////////////////  */}
                    <CustomTabPanel value={tabsCat} index={1}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Typography variant="h5" mt={1} mb={2}>
                          <b>Beneficiarios en Control Nutricional</b>
                        </Typography>
                        <Tabs
                          value={tabsBenef}
                          variant={isSmallScreen ? "scrollable" : "fullWidth"}
                          onChange={handleTabsBenef}
                          scrollButtons
                          allowScrollButtonsMobile
                          aria-label="basic tabs example"
                        >
                          <Tab
                            icon={<ChildCareOutlinedIcon />}
                            iconPosition="start"
                            label="Menores de 5 años - SAM y MAM"
                            {...a11yProps(0)}
                          />
                          <Tab
                            icon={<ChildCareOutlinedIcon />}
                            iconPosition="start"
                            label="Menores de 5 años - Micronutrientes"
                            {...a11yProps(1)}
                          />
                          <Tab
                            icon={<PregnantWomanOutlinedIcon />}
                            iconPosition="start"
                            label="MEL"
                            {...a11yProps(1)}
                          />
                        </Tabs>
                      </Box>
                      <CustomTabPanel value={tabsBenef} index={0}>
                        <MaterialReactTable
                          columns={columns}
                          data={
                            todosSeguimientosBeneficiarios.length == 0
                              ? []
                              : dataMicro.controlMam
                          }
                          localization={MRT_Localization_ES}
                          enableRowActions
                          positionExpandColumn="first"
                          positionActionsColumn="first"
                          renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleOpenControl(row);
                              }}
                              style={{
                                display:
                                  row.original.seguimientos < 8
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <b>
                                <ControlPointIcon sx={{ mr: 1 }} />
                                Ingresar Control
                              </b>
                            </MenuItem>,
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleOpenEditM5(row);
                              }}
                              style={{
                                display:
                                  row.original.seguimientos > 0 &&
                                  row.original.seguimientos <= 8
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <b>
                                <EditIcon sx={{ mr: 1 }} />
                                Editar Control
                              </b>
                            </MenuItem>,
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleFichaM5(row);
                              }}
                            >
                              <b>
                                <FeedOutlinedIcon sx={{ mr: 1 }} />
                                Ver Información
                              </b>
                            </MenuItem>,
                          ]}
                          muiTopToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableHeadCellProps={{
                            align: "center",
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiBottomToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableBodyProps={{
                            sx: {
                              "& tr:nth-of-type(odd)": {
                                backgroundColor: theme.palette.background.light,
                              },
                            },
                          }}
                          muiTableBodyCellProps={{
                            align: "center",
                          }}
                          muiTablePaperProps={{
                            elevation: 0,
                          }}
                          initialState={{
                            pagination: {
                              pageSize: 5,
                              pageIndex: 0,
                            },
                            sorting: [
                              {
                                id: "fecha",
                                desc: true,
                              },
                            ],
                          }}
                          muiTablePaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                            showFirstButton: false,
                            showLastButton: false,
                            SelectProps: {
                              native: true,
                            },
                            labelRowsPerPage: "Número de filas visibles",
                          }}
                        />
                      </CustomTabPanel>
                      <CustomTabPanel value={tabsBenef} index={1}>
                        <MaterialReactTable
                          columns={columnsMicro}
                          data={
                            todosSeguimientosBeneficiarios.length == 0
                              ? []
                              : dataMicro.controlMicro
                          }
                          localization={MRT_Localization_ES}
                          enableRowActions
                          positionExpandColumn="first"
                          positionActionsColumn="first"
                          renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleOpenControlMicro(row);
                              }}
                              style={{
                                display:
                                  row.original.seguimientos < 4
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <b>
                                <ControlPointIcon sx={{ mr: 1 }} />
                                Ingresar Seguimiento
                              </b>
                            </MenuItem>,
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleOpenEditMicro(row);
                              }}
                              style={{
                                display:
                                  row.original.seguimientos > 0 &&
                                  row.original.seguimientos <= 4
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <b>
                                <EditIcon sx={{ mr: 1 }} />
                                Editar Seguimiento
                              </b>
                            </MenuItem>,
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleFichaMicro(row);
                              }}
                            >
                              <b>
                                <FeedOutlinedIcon sx={{ mr: 1 }} />
                                Ver Información
                              </b>
                            </MenuItem>,
                          ]}
                          muiTopToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableHeadCellProps={{
                            align: "center",
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiBottomToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableBodyProps={{
                            sx: {
                              "& tr:nth-of-type(odd)": {
                                backgroundColor: theme.palette.background.light,
                              },
                            },
                          }}
                          muiTableBodyCellProps={{
                            align: "center",
                          }}
                          muiTablePaperProps={{
                            elevation: 0,
                          }}
                          initialState={{
                            pagination: {
                              pageSize: 5,
                              pageIndex: 0,
                            },
                            sorting: [
                              {
                                id: "fecha",
                                desc: true,
                              },
                            ],
                          }}
                          muiTablePaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                            showFirstButton: false,
                            showLastButton: false,
                            SelectProps: {
                              native: true,
                            },
                            labelRowsPerPage: "Número de filas visibles",
                          }}
                          renderDetailPanel={({ row }) => (
                            <Box
                              sx={{
                                display: "grid",
                                margin: "auto",
                                gridTemplateColumns: "1fr 1fr",
                                width: "100%",
                              }}
                            >
                              <Typography>
                                <b>Parroquia: </b> {row.original.parroquia}
                              </Typography>
                              <Typography>
                                <b>Escolarizado: </b> {row.original.escolaridad}
                              </Typography>
                              <Typography>
                                <b>Diagnóstico: </b>{" "}
                                {row.original.diagnostico_ingreso}
                              </Typography>
                              <Typography>
                                <b>Discapacidad: </b>{" "}
                                {row.original.discapacidad}
                              </Typography>
                            </Box>
                          )}
                        />
                      </CustomTabPanel>
                      <CustomTabPanel value={tabsBenef} index={2}>
                        <MaterialReactTable
                          columns={columnsMel}
                          data={
                            todosSeguimientosBeneficiarios.length == 0
                              ? []
                              : dataMicro.controlMel
                          }
                          localization={MRT_Localization_ES}
                          enableRowActions
                          positionExpandColumn="first"
                          positionActionsColumn="first"
                          renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleOpenControlMel(row);
                              }}
                              style={{
                                display:
                                  row.original.seguimientos < 4
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <b>
                                <ControlPointIcon sx={{ mr: 1 }} />
                                Ingresar Control
                              </b>
                            </MenuItem>,
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleOpenEditMel(row);
                              }}
                              style={{
                                display:
                                  row.original.seguimientos > 0 &&
                                  row.original.seguimientos <= 4
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <b>
                                <EditIcon sx={{ mr: 1 }} />
                                Editar Control
                              </b>
                            </MenuItem>,
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleFichaMel(row);
                              }}
                            >
                              <b>
                                <FeedOutlinedIcon sx={{ mr: 1 }} />
                                Ver Información
                              </b>
                            </MenuItem>,
                          ]}
                          muiTopToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableHeadCellProps={{
                            align: "center",
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiBottomToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableBodyProps={{
                            sx: {
                              "& tr:nth-of-type(odd)": {
                                backgroundColor: theme.palette.background.light,
                              },
                            },
                          }}
                          muiTableBodyCellProps={{
                            align: "center",
                          }}
                          muiTablePaperProps={{
                            elevation: 0,
                          }}
                          initialState={{
                            pagination: {
                              pageSize: 5,
                              pageIndex: 0,
                            },
                            sorting: [
                              {
                                id: "fecha",
                                desc: true,
                              },
                            ],
                          }}
                          muiTablePaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                            showFirstButton: false,
                            showLastButton: false,
                            SelectProps: {
                              native: true,
                            },
                            labelRowsPerPage: "Número de filas visibles",
                          }}
                        />
                      </CustomTabPanel>
                    </CustomTabPanel>
                    {/* ///////////////////////////// TAB DESERTOR  /////////////////////////////////  */}
                    <CustomTabPanel value={tabsCat} index={2}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Typography variant="h5" mt={1} mb={2}>
                          <b>Beneficiarios que Desertaron</b>
                        </Typography>
                        <Tabs
                          value={tabsDesertor}
                          variant={isSmallScreen ? "scrollable" : "fullWidth"}
                          onChange={handleTabsDesertor}
                          scrollButtons
                          allowScrollButtonsMobile
                          aria-label="basic tabs example"
                        >
                          <Tab
                            icon={<ChildCareOutlinedIcon />}
                            iconPosition="start"
                            label="Menores de 5 años - SAM y MAM"
                            {...a11yProps(0)}
                          />
                          <Tab
                            icon={<ChildCareOutlinedIcon />}
                            iconPosition="start"
                            label="Menores de 5 años - Micronutrientes"
                            {...a11yProps(1)}
                          />
                          <Tab
                            icon={<PregnantWomanOutlinedIcon />}
                            iconPosition="start"
                            label="MEL"
                            {...a11yProps(1)}
                          />
                        </Tabs>
                      </Box>
                      <CustomTabPanel value={tabsDesertor} index={0}>
                        <MaterialReactTable
                          columns={columnsDesertorM5}
                          data={
                            todosSeguimientosBeneficiarios.length == 0
                              ? []
                              : dataMicro.desertorMam
                          }
                          localization={MRT_Localization_ES}
                          enableRowActions
                          positionExpandColumn="first"
                          positionActionsColumn="first"
                          renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleFichaM5(row);
                              }}
                            >
                              <b>
                                <FeedOutlinedIcon sx={{ mr: 1 }} />
                                Ver Información
                              </b>
                            </MenuItem>,
                          ]}
                          muiTopToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableHeadCellProps={{
                            align: "center",
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiBottomToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableBodyProps={{
                            sx: {
                              "& tr:nth-of-type(odd)": {
                                backgroundColor: theme.palette.background.light,
                              },
                            },
                          }}
                          muiTableBodyCellProps={{
                            align: "center",
                          }}
                          muiTablePaperProps={{
                            elevation: 0,
                          }}
                          initialState={{
                            pagination: {
                              pageSize: 5,
                              pageIndex: 0,
                            },
                            sorting: [
                              {
                                id: "fecha",
                                desc: true,
                              },
                            ],
                          }}
                          muiTablePaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                            showFirstButton: false,
                            showLastButton: false,
                            SelectProps: {
                              native: true,
                            },
                            labelRowsPerPage: "Número de filas visibles",
                          }}
                        />
                      </CustomTabPanel>
                      <CustomTabPanel value={tabsDesertor} index={1}>
                        <MaterialReactTable
                          columns={columnsDesertorMicro}
                          data={
                            todosSeguimientosBeneficiarios.length == 0
                              ? []
                              : dataMicro.desertorMicro
                          }
                          localization={MRT_Localization_ES}
                          enableRowActions
                          positionExpandColumn="first"
                          positionActionsColumn="first"
                          renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleOpenControlMicro(row);
                              }}
                              style={{
                                display:
                                  row.original.seguimientos < 4
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <b>
                                <ControlPointIcon sx={{ mr: 1 }} />
                                Ingresar Seguimiento
                              </b>
                            </MenuItem>,
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleOpenEditMicro(row);
                              }}
                              style={{
                                display:
                                  row.original.seguimientos > 0 &&
                                  row.original.seguimientos <= 4
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <b>
                                <EditIcon sx={{ mr: 1 }} />
                                Editar Seguimiento
                              </b>
                            </MenuItem>,
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleFichaMicro(row);
                              }}
                            >
                              <b>
                                <FeedOutlinedIcon sx={{ mr: 1 }} />
                                Ver Información
                              </b>
                            </MenuItem>,
                          ]}
                          muiTopToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableHeadCellProps={{
                            align: "center",
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiBottomToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableBodyProps={{
                            sx: {
                              "& tr:nth-of-type(odd)": {
                                backgroundColor: theme.palette.background.light,
                              },
                            },
                          }}
                          muiTableBodyCellProps={{
                            align: "center",
                          }}
                          muiTablePaperProps={{
                            elevation: 0,
                          }}
                          initialState={{
                            pagination: {
                              pageSize: 5,
                              pageIndex: 0,
                            },
                            sorting: [
                              {
                                id: "fecha",
                                desc: true,
                              },
                            ],
                          }}
                          muiTablePaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                            showFirstButton: false,
                            showLastButton: false,
                            SelectProps: {
                              native: true,
                            },
                            labelRowsPerPage: "Número de filas visibles",
                          }}
                          renderDetailPanel={({ row }) => (
                            <Box
                              sx={{
                                display: "grid",
                                margin: "auto",
                                gridTemplateColumns: "1fr 1fr",
                                width: "100%",
                              }}
                            >
                              <Typography>
                                <b>Parroquia: </b> {row.original.parroquia}
                              </Typography>
                              <Typography>
                                <b>Escolarizado: </b> {row.original.escolaridad}
                              </Typography>
                              <Typography>
                                <b>Diagnóstico: </b>{" "}
                                {row.original.diagnostico_ingreso}
                              </Typography>
                              <Typography>
                                <b>Discapacidad: </b>{" "}
                                {row.original.discapacidad}
                              </Typography>
                            </Box>
                          )}
                        />
                      </CustomTabPanel>
                      <CustomTabPanel value={tabsDesertor} index={2}>
                        <MaterialReactTable
                          columns={columnsDesertorMel}
                          data={
                            todosSeguimientosBeneficiarios.length == 0
                              ? []
                              : dataMicro.desertorMel
                          }
                          localization={MRT_Localization_ES}
                          enableRowActions
                          positionExpandColumn="first"
                          positionActionsColumn="first"
                          renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleOpenControlMel(row);
                              }}
                              style={{
                                display:
                                  row.original.seguimientos < 4
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <b>
                                <ControlPointIcon sx={{ mr: 1 }} />
                                Ingresar Control
                              </b>
                            </MenuItem>,
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleOpenEditMel(row);
                              }}
                              style={{
                                display:
                                  row.original.seguimientos > 0 &&
                                  row.original.seguimientos <= 4
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <b>
                                <EditIcon sx={{ mr: 1 }} />
                                Editar Control
                              </b>
                            </MenuItem>,
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleFichaMel(row);
                              }}
                            >
                              <b>
                                <FeedOutlinedIcon sx={{ mr: 1 }} />
                                Ver Información
                              </b>
                            </MenuItem>,
                          ]}
                          muiTopToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableHeadCellProps={{
                            align: "center",
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiBottomToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableBodyProps={{
                            sx: {
                              "& tr:nth-of-type(odd)": {
                                backgroundColor: theme.palette.background.light,
                              },
                            },
                          }}
                          muiTableBodyCellProps={{
                            align: "center",
                          }}
                          muiTablePaperProps={{
                            elevation: 0,
                          }}
                          initialState={{
                            pagination: {
                              pageSize: 5,
                              pageIndex: 0,
                            },
                            sorting: [
                              {
                                id: "fecha",
                                desc: true,
                              },
                            ],
                          }}
                          muiTablePaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                            showFirstButton: false,
                            showLastButton: false,
                            SelectProps: {
                              native: true,
                            },
                            labelRowsPerPage: "Número de filas visibles",
                          }}
                        />
                      </CustomTabPanel>
                    </CustomTabPanel>
                    {/* ///////////////////////////// TAB REFERIDO  /////////////////////////////////  */}
                    <CustomTabPanel value={tabsCat} index={3}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Typography variant="h5" mt={1} mb={2}>
                          <b>Beneficiarios Referidos</b>
                        </Typography>
                        <Tabs
                          value={tabsReferidos}
                          variant={isSmallScreen ? "scrollable" : "fullWidth"}
                          onChange={handleTabsReferidos}
                          scrollButtons
                          allowScrollButtonsMobile
                          aria-label="basic tabs example"
                        >
                          <Tab
                            icon={<ChildCareOutlinedIcon />}
                            iconPosition="start"
                            label="Menores de 5 años - SAM y MAM"
                            {...a11yProps(0)}
                          />
                          <Tab
                            icon={<ChildCareOutlinedIcon />}
                            iconPosition="start"
                            label="Menores de 5 años - Micronutrientes"
                            {...a11yProps(1)}
                          />
                          <Tab
                            icon={<PregnantWomanOutlinedIcon />}
                            iconPosition="start"
                            label="MEL"
                            {...a11yProps(1)}
                          />
                        </Tabs>
                      </Box>
                      <CustomTabPanel value={tabsReferidos} index={0}>
                        <MaterialReactTable
                          columns={columnsReferidoM5}
                          data={
                            todosSeguimientosBeneficiarios.length == 0
                              ? []
                              : dataMicro.referidoMam
                          }
                          localization={MRT_Localization_ES}
                          enableRowActions
                          positionExpandColumn="first"
                          positionActionsColumn="first"
                          renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleFichaM5(row);
                              }}
                            >
                              <b>
                                <FeedOutlinedIcon sx={{ mr: 1 }} />
                                Ver Información
                              </b>
                            </MenuItem>,
                          ]}
                          muiTopToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableHeadCellProps={{
                            align: "center",
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiBottomToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableBodyProps={{
                            sx: {
                              "& tr:nth-of-type(odd)": {
                                backgroundColor: theme.palette.background.light,
                              },
                            },
                          }}
                          muiTableBodyCellProps={{
                            align: "center",
                          }}
                          muiTablePaperProps={{
                            elevation: 0,
                          }}
                          initialState={{
                            pagination: {
                              pageSize: 5,
                              pageIndex: 0,
                            },
                            sorting: [
                              {
                                id: "fecha",
                                desc: true,
                              },
                            ],
                          }}
                          muiTablePaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                            showFirstButton: false,
                            showLastButton: false,
                            SelectProps: {
                              native: true,
                            },
                            labelRowsPerPage: "Número de filas visibles",
                          }}
                        />
                      </CustomTabPanel>
                      <CustomTabPanel value={tabsReferidos} index={1}>
                        <MaterialReactTable
                          columns={columnsReferidoMicro}
                          data={
                            todosSeguimientosBeneficiarios.length == 0
                              ? []
                              : dataMicro.referidoMicro
                          }
                          localization={MRT_Localization_ES}
                          enableRowActions
                          positionExpandColumn="first"
                          positionActionsColumn="first"
                          renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleOpenControlMicro(row);
                              }}
                              style={{
                                display:
                                  row.original.seguimientos < 4
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <b>
                                <ControlPointIcon sx={{ mr: 1 }} />
                                Ingresar Seguimiento
                              </b>
                            </MenuItem>,
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleOpenEditMicro(row);
                              }}
                              style={{
                                display:
                                  row.original.seguimientos > 0 &&
                                  row.original.seguimientos <= 4
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <b>
                                <EditIcon sx={{ mr: 1 }} />
                                Editar Seguimiento
                              </b>
                            </MenuItem>,
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleFichaMicro(row);
                              }}
                            >
                              <b>
                                <FeedOutlinedIcon sx={{ mr: 1 }} />
                                Ver Información
                              </b>
                            </MenuItem>,
                          ]}
                          muiTopToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableHeadCellProps={{
                            align: "center",
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiBottomToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableBodyProps={{
                            sx: {
                              "& tr:nth-of-type(odd)": {
                                backgroundColor: theme.palette.background.light,
                              },
                            },
                          }}
                          muiTableBodyCellProps={{
                            align: "center",
                          }}
                          muiTablePaperProps={{
                            elevation: 0,
                          }}
                          initialState={{
                            pagination: {
                              pageSize: 5,
                              pageIndex: 0,
                            },
                            sorting: [
                              {
                                id: "fecha",
                                desc: true,
                              },
                            ],
                          }}
                          muiTablePaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                            showFirstButton: false,
                            showLastButton: false,
                            SelectProps: {
                              native: true,
                            },
                            labelRowsPerPage: "Número de filas visibles",
                          }}
                          renderDetailPanel={({ row }) => (
                            <Box
                              sx={{
                                display: "grid",
                                margin: "auto",
                                gridTemplateColumns: "1fr 1fr",
                                width: "100%",
                              }}
                            >
                              <Typography>
                                <b>Parroquia: </b> {row.original.parroquia}
                              </Typography>
                              <Typography>
                                <b>Escolarizado: </b> {row.original.escolaridad}
                              </Typography>
                              <Typography>
                                <b>Diagnóstico: </b>{" "}
                                {row.original.diagnostico_ingreso}
                              </Typography>
                              <Typography>
                                <b>Discapacidad: </b>{" "}
                                {row.original.discapacidad}
                              </Typography>
                            </Box>
                          )}
                        />
                      </CustomTabPanel>
                      <CustomTabPanel value={tabsReferidos} index={2}>
                        <MaterialReactTable
                          columns={columnsReferidoMel}
                          data={
                            todosSeguimientosBeneficiarios.length == 0
                              ? []
                              : dataMicro.referidoMel
                          }
                          localization={MRT_Localization_ES}
                          enableRowActions
                          positionExpandColumn="first"
                          positionActionsColumn="first"
                          renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleOpenControlMel(row);
                              }}
                              style={{
                                display:
                                  row.original.seguimientos < 4
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <b>
                                <ControlPointIcon sx={{ mr: 1 }} />
                                Ingresar Control
                              </b>
                            </MenuItem>,
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleOpenEditMel(row);
                              }}
                              style={{
                                display:
                                  row.original.seguimientos > 0 &&
                                  row.original.seguimientos <= 4
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <b>
                                <EditIcon sx={{ mr: 1 }} />
                                Editar Control
                              </b>
                            </MenuItem>,
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleFichaMel(row);
                              }}
                            >
                              <b>
                                <FeedOutlinedIcon sx={{ mr: 1 }} />
                                Ver Información
                              </b>
                            </MenuItem>,
                          ]}
                          muiTopToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableHeadCellProps={{
                            align: "center",
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiBottomToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableBodyProps={{
                            sx: {
                              "& tr:nth-of-type(odd)": {
                                backgroundColor: theme.palette.background.light,
                              },
                            },
                          }}
                          muiTableBodyCellProps={{
                            align: "center",
                          }}
                          muiTablePaperProps={{
                            elevation: 0,
                          }}
                          initialState={{
                            pagination: {
                              pageSize: 5,
                              pageIndex: 0,
                            },
                            sorting: [
                              {
                                id: "fecha",
                                desc: true,
                              },
                            ],
                          }}
                          muiTablePaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                            showFirstButton: false,
                            showLastButton: false,
                            SelectProps: {
                              native: true,
                            },
                            labelRowsPerPage: "Número de filas visibles",
                          }}
                        />
                      </CustomTabPanel>
                    </CustomTabPanel>
                    {/* ///////////////////////////// TAB ALTA NUTRICIONAL  ///////////////////////// */}
                    <CustomTabPanel value={tabsCat} index={4}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Typography variant="h5" mt={1} mb={2}>
                          <b>Beneficiarios con Alta Nutricional</b>
                        </Typography>
                        <Tabs
                          value={tabsAltaNut}
                          variant={isSmallScreen ? "scrollable" : "fullWidth"}
                          onChange={handleTabsAltaNut}
                          scrollButtons
                          allowScrollButtonsMobile
                          aria-label="basic tabs example"
                        >
                          <Tab
                            icon={<ChildCareOutlinedIcon />}
                            iconPosition="start"
                            label="Menores de 5 años - SAM y MAM"
                            {...a11yProps(0)}
                          />
                          <Tab
                            icon={<ChildCareOutlinedIcon />}
                            iconPosition="start"
                            label="Menores de 5 años - Micronutrientes"
                            {...a11yProps(1)}
                          />
                          <Tab
                            icon={<PregnantWomanOutlinedIcon />}
                            iconPosition="start"
                            label="MEL"
                            {...a11yProps(1)}
                          />
                        </Tabs>
                      </Box>
                      <CustomTabPanel value={tabsAltaNut} index={0}>
                        <MaterialReactTable
                          columns={columnsAltaM5}
                          data={
                            todosSeguimientosBeneficiarios.length == 0
                              ? []
                              : dataMicro.altaMam
                          }
                          localization={MRT_Localization_ES}
                          enableRowActions
                          positionExpandColumn="first"
                          positionActionsColumn="first"
                          renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleFichaM5(row);
                              }}
                            >
                              <b>
                                <FeedOutlinedIcon sx={{ mr: 1 }} />
                                Ver Información
                              </b>
                            </MenuItem>,
                          ]}
                          muiTopToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableHeadCellProps={{
                            align: "center",
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiBottomToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableBodyProps={{
                            sx: {
                              "& tr:nth-of-type(odd)": {
                                backgroundColor: theme.palette.background.light,
                              },
                            },
                          }}
                          muiTableBodyCellProps={{
                            align: "center",
                          }}
                          muiTablePaperProps={{
                            elevation: 0,
                          }}
                          initialState={{
                            pagination: {
                              pageSize: 5,
                              pageIndex: 0,
                            },
                            sorting: [
                              {
                                id: "fecha",
                                desc: true,
                              },
                            ],
                          }}
                          muiTablePaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                            showFirstButton: false,
                            showLastButton: false,
                            SelectProps: {
                              native: true,
                            },
                            labelRowsPerPage: "Número de filas visibles",
                          }}
                        />
                      </CustomTabPanel>
                      <CustomTabPanel value={tabsAltaNut} index={1}>
                        <MaterialReactTable
                          columns={columnsAltaMicro}
                          data={
                            todosSeguimientosBeneficiarios.length == 0
                              ? []
                              : dataMicro.altaMicro
                          }
                          localization={MRT_Localization_ES}
                          enableRowActions
                          positionExpandColumn="first"
                          positionActionsColumn="first"
                          renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleOpenControlMicro(row);
                              }}
                              style={{
                                display:
                                  row.original.seguimientos < 4
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <b>
                                <ControlPointIcon sx={{ mr: 1 }} />
                                Ingresar Seguimiento
                              </b>
                            </MenuItem>,
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleOpenEditMicro(row);
                              }}
                              style={{
                                display:
                                  row.original.seguimientos > 0 &&
                                  row.original.seguimientos <= 4
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <b>
                                <EditIcon sx={{ mr: 1 }} />
                                Editar Seguimiento
                              </b>
                            </MenuItem>,
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleFichaMicro(row);
                              }}
                            >
                              <b>
                                <FeedOutlinedIcon sx={{ mr: 1 }} />
                                Ver Información
                              </b>
                            </MenuItem>,
                          ]}
                          muiTopToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableHeadCellProps={{
                            align: "center",
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiBottomToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableBodyProps={{
                            sx: {
                              "& tr:nth-of-type(odd)": {
                                backgroundColor: theme.palette.background.light,
                              },
                            },
                          }}
                          muiTableBodyCellProps={{
                            align: "center",
                          }}
                          muiTablePaperProps={{
                            elevation: 0,
                          }}
                          initialState={{
                            pagination: {
                              pageSize: 5,
                              pageIndex: 0,
                            },
                            sorting: [
                              {
                                id: "fecha",
                                desc: true,
                              },
                            ],
                          }}
                          muiTablePaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                            showFirstButton: false,
                            showLastButton: false,
                            SelectProps: {
                              native: true,
                            },
                            labelRowsPerPage: "Número de filas visibles",
                          }}
                          renderDetailPanel={({ row }) => (
                            <Box
                              sx={{
                                display: "grid",
                                margin: "auto",
                                gridTemplateColumns: "1fr 1fr",
                                width: "100%",
                              }}
                            >
                              <Typography>
                                <b>Parroquia: </b> {row.original.parroquia}
                              </Typography>
                              <Typography>
                                <b>Escolarizado: </b> {row.original.escolaridad}
                              </Typography>
                              <Typography>
                                <b>Diagnóstico: </b>{" "}
                                {row.original.diagnostico_ingreso}
                              </Typography>
                              <Typography>
                                <b>Discapacidad: </b>{" "}
                                {row.original.discapacidad}
                              </Typography>
                            </Box>
                          )}
                        />
                      </CustomTabPanel>
                      <CustomTabPanel value={tabsAltaNut} index={2}>
                        <MaterialReactTable
                          columns={columnsAltaMel}
                          data={
                            todosSeguimientosBeneficiarios.length == 0
                              ? []
                              : dataMicro.altaMel
                          }
                          localization={MRT_Localization_ES}
                          enableRowActions
                          positionExpandColumn="first"
                          positionActionsColumn="first"
                          renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleOpenControlMel(row);
                              }}
                              style={{
                                display:
                                  row.original.seguimientos < 4
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <b>
                                <ControlPointIcon sx={{ mr: 1 }} />
                                Ingresar Control
                              </b>
                            </MenuItem>,
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleOpenEditMel(row);
                              }}
                              style={{
                                display:
                                  row.original.seguimientos > 0 &&
                                  row.original.seguimientos <= 4
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <b>
                                <EditIcon sx={{ mr: 1 }} />
                                Editar Control
                              </b>
                            </MenuItem>,
                            <MenuItem
                              onClick={(event) => {
                                closeMenu();
                                handleFichaMel(row);
                              }}
                            >
                              <b>
                                <FeedOutlinedIcon sx={{ mr: 1 }} />
                                Ver Información
                              </b>
                            </MenuItem>,
                          ]}
                          muiTopToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableHeadCellProps={{
                            align: "center",
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiBottomToolbarProps={{
                            sx: {
                              backgroundColor: theme.palette.background.alt,
                            },
                          }}
                          muiTableBodyProps={{
                            sx: {
                              "& tr:nth-of-type(odd)": {
                                backgroundColor: theme.palette.background.light,
                              },
                            },
                          }}
                          muiTableBodyCellProps={{
                            align: "center",
                          }}
                          muiTablePaperProps={{
                            elevation: 0,
                          }}
                          initialState={{
                            pagination: {
                              pageSize: 5,
                              pageIndex: 0,
                            },
                            sorting: [
                              {
                                id: "fecha",
                                desc: true,
                              },
                            ],
                          }}
                          muiTablePaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
                            showFirstButton: false,
                            showLastButton: false,
                            SelectProps: {
                              native: true,
                            },
                            labelRowsPerPage: "Número de filas visibles",
                          }}
                        />
                      </CustomTabPanel>
                    </CustomTabPanel>
                    {/* /////////////////////////////////////////////////////////////////////// */}
                  </Box>
                </Box>
              </Box>
            </Container>
            {/* //////////////////////////// MODAL M5 //////////////////////////// */}
            <Modal
              open={openControl}
              onClose={handleCloseControl}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography variant="h4">
                  <b>
                    {" "}
                    <ControlPointIcon sx={{ fontSize: 30 }} /> Ingresar Control:
                  </b>{" "}
                  {datoIngreso.nombre} {datoIngreso.apellido}
                </Typography>
                <Divider sx={{ mt: 2, mb: 3 }} />
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} sm={4} mb={isSmallScreen ? 1 : 3} mt={1}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        ¿Asistió?
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={optAsist}
                        label="¿Asistió?"
                        onChange={handleOptAsist}
                      >
                        <MenuItem value="">
                          <em>Seleccionar Asistencia...</em>
                        </MenuItem>
                        <MenuItem value={"Si"}>Si</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={1}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="es"
                    >
                      <DesktopDatePicker
                        label="Fecha de Evaluacion"
                        name="fechaEvaluacion"
                        inputFormat="DD/MM/YYYY"
                        value={fechaEvaluacion}
                        onChange={setFechaEvaluacion}
                        renderInput={(params) => {
                          return <TextField fullWidth {...params} />;
                        }}
                        clearable
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    mt={isSmallScreen ? 0 : 1}
                    hidden={isSmallScreen}
                  >
                    <FormControl fullWidth>
                      <InputLabel
                        id="demo-simple-select-label"
                        hidden={optAsist === "Si" || optAsist === ""}
                      >
                        Razón de Inasistencia
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={optRazon}
                        label="Razón de Inasistencia"
                        onChange={handleOptRazon}
                        hidden={optAsist === "Si" || optAsist === ""}
                      >
                        <MenuItem value="">
                          <em>Seleccionar Razón de Inasistencia...</em>
                        </MenuItem>
                        <MenuItem value={"Problemas de comunicación"}>
                          Problemas de comunicación
                        </MenuItem>
                        <MenuItem value={"Niño ausente en la comunidad"}>
                          Niño ausente en la comunidad
                        </MenuItem>
                        <MenuItem value={"Promotor Ausente"}>
                          Promotor Ausente
                        </MenuItem>
                        <MenuItem value={"S/D"}>S/D</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Edema
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={optEdema}
                        label="Edema"
                        onChange={handleOptEdema}
                        disabled={optAsist === "No" || optAsist === ""}
                      >
                        <MenuItem value="">
                          <em>Seleccionar Edema...</em>
                        </MenuItem>
                        <MenuItem value={"Si"}>Si</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                        <MenuItem value={"Aumentó"}>Aumentó</MenuItem>
                        <MenuItem value={"Disminuyó"}>Disminuyó</MenuItem>
                        <MenuItem value={"S/D"}>S/D</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <TextField
                      id="outlined-basic"
                      label="Peso (Kg)"
                      variant="outlined"
                      value={pesoBenef}
                      onChange={(event) => setPesoBenef(event.target.value)}
                      disabled={optAsist === "No" || optAsist === ""}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <TextField
                      id="outlined-basic"
                      label="Talla (cm)"
                      variant="outlined"
                      value={tallaBenef}
                      onChange={(event) => setTallaBenef(event.target.value)}
                      disabled={optAsist === "No" || optAsist === ""}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      id="outlined-basic"
                      label="Puntaje Z"
                      variant="outlined"
                      value={puntajeZ}
                      onChange={handlePuntajeZ}
                      disabled={optAsist === "No" || optAsist === ""}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      id="outlined-basic"
                      label="Clasificación Nutricional"
                      variant="outlined"
                      value={diagPZ}
                      onChange={(event) => setDiagPZ(event.target.value)}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      id="outlined-basic"
                      label="Circ. de Brazo (CMB)"
                      variant="outlined"
                      value={pb}
                      onChange={handlePb}
                      disabled={optAsist === "No" || optAsist === ""}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      id="outlined-basic"
                      label="Clasificación PB"
                      variant="outlined"
                      value={diagPB}
                      onChange={(event) => setDiagPB(event.target.value)}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      id="outlined-basic"
                      label="Diagnóstico"
                      variant="outlined"
                      value={diagFinal}
                      onChange={(event) => setDiagFinal(event.target.value)}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Estatus
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={estatusM5}
                        label="Edema"
                        onChange={handleEstatusM5}
                        disabled={optAsist === "No" || optAsist === ""}
                      >
                        <MenuItem value="">
                          <em>Seleccionar Estatus...</em>
                        </MenuItem>
                        <MenuItem value={0}>En Control</MenuItem>
                        <MenuItem value={1}>Desertor</MenuItem>
                        <MenuItem value={2}>Referido</MenuItem>
                        <MenuItem value={3}>Alta Nutricional</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      id="outlined-basic"
                      label="Observaciones"
                      variant="outlined"
                      value={observacionesM5}
                      onChange={(event) =>
                        setObservacionesM5(event.target.value)
                      }
                      disabled={optAsist === ""}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 4, mb: 2 }} />
                <Grid
                  container
                  sx={{ mt: 1 }}
                  spacing={1}
                  justifyContent="flex-end"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleCloseControl}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      sx={{ bgcolor: "teal", color: "white" }}
                      onClick={handleGuardarControl}
                      disabled={disableBtnM5 == true}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            {/* //////////////////////////// MODAL MICRONUTRIENTES //////////////////////////// */}
            <Modal
              open={openControlMicro}
              onClose={handleCloseControlMicro}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography variant="h4">
                  <b>
                    {" "}
                    <ControlPointIcon sx={{ fontSize: 25 }} /> Ingresar
                    Seguimiento:
                  </b>{" "}
                  {datoIngreso.nombre} {datoIngreso.apellido}
                </Typography>
                <Divider sx={{ mt: 2, mb: 3 }} />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} mb={isSmallScreen ? 1 : 3} mt={1}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Seguimiento
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={optSeg}
                        label="Seguimiento"
                        onChange={handleOptSeg}
                      >
                        <MenuItem value="">
                          <em>Seleccionar Seguimiento...</em>
                        </MenuItem>
                        <MenuItem value={"Si"}>Si</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={1}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="es"
                    >
                      <DesktopDatePicker
                        label="Fecha de Evaluacion"
                        name="fechaEvaluacion"
                        inputFormat="DD/MM/YYYY"
                        value={fechaEvalMicro}
                        onChange={setFechaEvalMicro}
                        renderInput={(params) => {
                          return <TextField fullWidth {...params} />;
                        }}
                        clearable
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    mt={1}
                    hidden={
                      (isSmallScreen && optSeg === "Si") ||
                      (isSmallScreen && optSeg === "")
                    }
                  >
                    <FormControl fullWidth>
                      <InputLabel
                        id="demo-simple-select-label"
                        hidden={optSeg === "Si" || optSeg === ""}
                      >
                        Motivo
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={optMotivo}
                        label="Motivo"
                        onChange={handleOptMotivo}
                        hidden={optSeg === "Si" || optSeg === ""}
                      >
                        <MenuItem value="">
                          <em>Seleccionar Motivo...</em>
                        </MenuItem>
                        <MenuItem value={"Sin Número"}>Sin Número</MenuItem>
                        <MenuItem value={"No Contestó"}>No Contestó</MenuItem>
                        <MenuItem value={"Número Equivocado"}>
                          Número Equivocado
                        </MenuItem>
                        <MenuItem value={"S/D"}>S/D</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={
                      optEsquema === "" ||
                      optEsquema === "Si" ||
                      optSeg === "No"
                        ? 6
                        : 6
                    }
                  >
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        ¿Completó el esquema?
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={optEsquema}
                        label="¿Completó el esquema?"
                        onChange={handleOptEsquema}
                        disabled={optSeg === "No" || optSeg === ""}
                      >
                        <MenuItem value="">
                          <em>Seleccionar Esquema...</em>
                        </MenuItem>
                        <MenuItem value={"Si"}>Si</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                        <MenuItem value={"S/D"}>S/D</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    hidden={
                      optEsquema === "" ||
                      optEsquema === "Si" ||
                      optSeg === "No"
                    }
                  >
                    <TextField
                      id="outlined-basic"
                      label="Motivo"
                      variant="outlined"
                      value={motivoEsquema}
                      onChange={(event) => setMotivoEsquema(event.target.value)}
                      disabled={
                        optSeg === "No" ||
                        optSeg === "" ||
                        optEsquema === "Si" ||
                        optEsquema === ""
                      }
                      hidden={
                        optEsquema === "" ||
                        optEsquema === "Si" ||
                        optSeg === "No"
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={
                      optEsquema === "" ||
                      optEsquema === "Si" ||
                      optSeg === "No"
                        ? 6
                        : 6
                    }
                  >
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Consejería
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={consejeria}
                        label="Consejería"
                        onChange={handleConsejeria}
                        disabled={optSeg === "No" || optSeg === ""}
                      >
                        <MenuItem value="">
                          <em>Seleccionar Consejería...</em>
                        </MenuItem>
                        <MenuItem value={"Si"}>Si</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                        <MenuItem value={"S/D"}>S/D</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Estatus
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={estatusMicro}
                        label="Edema"
                        onChange={handleEstatusMicro}
                        disabled={optSeg === ""}
                      >
                        <MenuItem value="">
                          <em>Seleccionar Estatus...</em>
                        </MenuItem>
                        <MenuItem value={0}>En Control</MenuItem>
                        <MenuItem value={1}>Desertor</MenuItem>
                        <MenuItem value={2}>Referido</MenuItem>
                        <MenuItem value={3}>Alta Nutricional</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={
                      optEsquema === "" ||
                      optEsquema === "Si" ||
                      optSeg === "No"
                        ? 6
                        : 6
                    }
                  >
                    <TextField
                      id="outlined-basic"
                      label="Observaciones"
                      variant="outlined"
                      value={observacionesMN}
                      onChange={(event) =>
                        setObservacionesMN(event.target.value)
                      }
                      disabled={optSeg === ""}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 4, mb: 2 }} />
                <Grid
                  container
                  sx={{ mt: 1 }}
                  spacing={1}
                  justifyContent="flex-end"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleCloseControlMicro}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      sx={{ bgcolor: "teal", color: "white" }}
                      onClick={handleGuardarControlMN}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            {/* /////////////////////////////////  MODAL MEL  ////////////////////////////// */}
            <Modal
              open={openControlMel}
              onClose={handleCloseControlMel}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography variant="h4">
                  <b>
                    {" "}
                    <ControlPointIcon sx={{ fontSize: 30 }} /> Ingresar Control:
                  </b>{" "}
                  {datoIngreso.nombre} {datoIngreso.apellido}
                </Typography>
                <Divider sx={{ mt: 2, mb: 3 }} />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} mb={isSmallScreen ? 1 : 3} mt={1}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        ¿Asistió?
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={optAsistMel}
                        label="¿Asistió?"
                        onChange={handleOptAsistMel}
                      >
                        <MenuItem value="">
                          <em>Seleccionar Asistencia...</em>
                        </MenuItem>
                        <MenuItem value={"Si"}>Si</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={1}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="es"
                    >
                      <DesktopDatePicker
                        label="Fecha de Evaluacion"
                        name="fechaEvaluacion"
                        inputFormat="DD/MM/YYYY"
                        value={fechaEvalMel}
                        onChange={setFechaEvalMel}
                        renderInput={(params) => {
                          return <TextField fullWidth {...params} />;
                        }}
                        clearable
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={4} mt={1} hidden={isSmallScreen}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="demo-simple-select-label"
                        hidden={optAsistMel === "Si" || optAsistMel === ""}
                      >
                        Razón de Inasistencia
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={optRazonMel}
                        label="Razón de Inasistencia"
                        onChange={handleOptRazonMel}
                        hidden={optAsistMel === "Si" || optAsistMel === ""}
                      >
                        <MenuItem value="">
                          <em>Seleccionar Razón de Inasistencia...</em>
                        </MenuItem>
                        <MenuItem value={"Problemas de comunicación"}>
                          Problemas de comunicación
                        </MenuItem>
                        <MenuItem value={"Niño ausente en la comunidad"}>
                          Paciente ausente en la comunidad
                        </MenuItem>
                        <MenuItem value={"Promotor Ausente"}>
                          Promotor Ausente
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Semanas de Gestación
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={semGest}
                        label="Semanas de Gestación"
                        onChange={handleSemGest}
                        disabled={optAsistMel === "No" || optAsistMel === ""}
                      >
                        <MenuItem value="">
                          <em>Seleccionar Semana de Gestación...</em>
                        </MenuItem>
                        <MenuItem value="6">6</MenuItem>
                        <MenuItem value="7">7</MenuItem>
                        <MenuItem value="8">8</MenuItem>
                        <MenuItem value="9">9</MenuItem>
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="11">11</MenuItem>
                        <MenuItem value="12">12</MenuItem>
                        <MenuItem value="13">13</MenuItem>
                        <MenuItem value="14">14</MenuItem>
                        <MenuItem value="15">15</MenuItem>
                        <MenuItem value="16">16</MenuItem>
                        <MenuItem value="17">17</MenuItem>
                        <MenuItem value="18">18</MenuItem>
                        <MenuItem value="19">19</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="20">21</MenuItem>
                        <MenuItem value="22">22</MenuItem>
                        <MenuItem value="23">23</MenuItem>
                        <MenuItem value="24">24</MenuItem>
                        <MenuItem value="25">25</MenuItem>
                        <MenuItem value="26">26</MenuItem>
                        <MenuItem value="27">27</MenuItem>
                        <MenuItem value="28">28</MenuItem>
                        <MenuItem value="29">29</MenuItem>
                        <MenuItem value="30">30</MenuItem>
                        <MenuItem value="31">31</MenuItem>
                        <MenuItem value="32">32</MenuItem>
                        <MenuItem value="33">33</MenuItem>
                        <MenuItem value="34">34</MenuItem>
                        <MenuItem value="35">35</MenuItem>
                        <MenuItem value="36">36</MenuItem>
                        <MenuItem value="37">37</MenuItem>
                        <MenuItem value="38">38</MenuItem>
                        <MenuItem value="39">39</MenuItem>
                        <MenuItem value="40">40</MenuItem>
                        <MenuItem value="41">41</MenuItem>
                        <MenuItem value="42">42</MenuItem>
                        <MenuItem value="S/D">S/D</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <TextField
                      id="outlined-basic"
                      label="Peso (Kg)"
                      variant="outlined"
                      value={pesoMel}
                      onChange={(event) => setPesoMel(event.target.value)}
                      disabled={optAsistMel === "No" || optAsistMel === ""}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <TextField
                      id="outlined-basic"
                      label="Talla (cm)"
                      variant="outlined"
                      value={tallaMel}
                      onChange={(event) => setTallaMel(event.target.value)}
                      disabled={optAsistMel === "No" || optAsistMel === ""}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      id="outlined-basic"
                      label="IMC"
                      variant="outlined"
                      value={imc}
                      onChange={handleImc}
                      disabled={optAsistMel === "No" || optAsistMel === ""}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      id="outlined-basic"
                      label="Clasificación IMC"
                      variant="outlined"
                      value={diagImc}
                      onChange={(event) => setDiagImc(event.target.value)}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      id="outlined-basic"
                      label="Circ. de Brazo (CMB)"
                      variant="outlined"
                      value={pbMel}
                      onChange={handlePbMel}
                      disabled={optAsistMel === "No" || optAsistMel === ""}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      id="outlined-basic"
                      label="Clasificación PB"
                      variant="outlined"
                      value={diagPbMel}
                      onChange={(event) => setDiagPbMel(event.target.value)}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="outlined-basic"
                      label="Diagnóstico"
                      variant="outlined"
                      value={diagFinalMel}
                      onChange={(event) => setDiagFinalMel(event.target.value)}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="outlined-basic"
                      label="Diagnóstico por Semanas de Gestación"
                      variant="outlined"
                      value={diagSemGestMel}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Estatus
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={estatusMel}
                        label="Edema"
                        onChange={handleEstatusMel}
                        disabled={optAsistMel === "No" || optAsistMel === ""}
                      >
                        <MenuItem value="">
                          <em>Seleccionar Estatus...</em>
                        </MenuItem>
                        <MenuItem value={0}>En Control</MenuItem>
                        <MenuItem value={1}>Desertor</MenuItem>
                        <MenuItem value={2}>Referido</MenuItem>
                        <MenuItem value={3}>Alta Nutricional</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="outlined-basic"
                      label="Observaciones"
                      variant="outlined"
                      value={observacionesMel}
                      onChange={(event) =>
                        setObservacionesMel(event.target.value)
                      }
                      disabled={optAsistMel === ""}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 4, mb: 2 }} />
                <Grid
                  container
                  sx={{ mt: 1 }}
                  spacing={1}
                  justifyContent="flex-end"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleCloseControlMel}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      sx={{ bgcolor: "teal", color: "white" }}
                      onClick={handleGuardarControlMel}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            {/*////////////////////////////////////  MODAL FICHA M5 //////////////////////////////////////*/}
            <Modal
              open={openFichaM5}
              onClose={handleCloseFichaM5}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styleFichaM5}>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={tabsFichaM5}
                      onChange={handleTabsFichaM5}
                      variant={isSmallScreen ? "scrollable" : "fullWidth"}
                      scrollButtons
                      allowScrollButtonsMobile
                      aria-label="basic tabs example"
                    >
                      <Tab
                        icon={<ContactPageOutlinedIcon />}
                        label="Info. General"
                        {...a11yProps(0)}
                      />
                      <Tab
                        icon={<Filter1OutlinedIcon />}
                        label="Control I"
                        {...a11yProps(1)}
                        hidden={seguimientos.length < 1}
                      />
                      <Tab
                        icon={<Filter2OutlinedIcon />}
                        label="Control II"
                        {...a11yProps(2)}
                        hidden={seguimientos.length < 2}
                      />
                      <Tab
                        icon={<Filter3OutlinedIcon />}
                        label="Control III"
                        {...a11yProps(3)}
                        hidden={seguimientos.length < 3}
                      />
                      <Tab
                        icon={<Filter4OutlinedIcon />}
                        label="Control IV"
                        {...a11yProps(4)}
                        hidden={seguimientos.length < 4}
                      />
                      <Tab
                        icon={<Filter5OutlinedIcon />}
                        label="Control V"
                        {...a11yProps(5)}
                        hidden={seguimientos.length < 5}
                      />
                      <Tab
                        icon={<Filter6OutlinedIcon />}
                        label="Control VI"
                        {...a11yProps(6)}
                        hidden={seguimientos.length < 6}
                      />
                      <Tab
                        icon={<Filter7OutlinedIcon />}
                        label="Control VII"
                        {...a11yProps(7)}
                        hidden={seguimientos.length < 7}
                      />
                      <Tab
                        icon={<Filter8OutlinedIcon />}
                        label="Control VIII"
                        {...a11yProps(8)}
                        hidden={seguimientos.length < 8}
                      />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={tabsFichaM5} index={0}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Nombres: </b> {datoIngreso.nombre}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Apellidos: </b> {datoIngreso.apellido}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Género: </b> {datoIngreso.sexo}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Fecha de Nacimiento: </b>{" "}
                          {datoIngreso.fecha_nacimiento}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Edad en Meses: </b> {datoIngreso.edad}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Registrado: </b> {datoIngreso.registrado}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Escolaridad: </b> {datoIngreso.escolaridad}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Grupo Etnico: </b> {datoIngreso.grupo_etnico}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Discapacidad: </b> {datoIngreso.discapacidad}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Municipio: </b> {datoIngreso.municipio}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Comunidad: </b> {datoIngreso.comunidad}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Equipo: </b>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Fecha Ingreso al Programa: </b>{" "}
                          {datoIngreso.fecha_evaluacion}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Peso(Kg): </b> {datoIngreso.peso}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Talla(cm): </b> {datoIngreso.talla}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>PB de Ingreso: </b> {datoIngreso.circunferencia}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Puntaje Z: </b> {datoIngreso.puntaje_z}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Diagnóstico de Ingreso: </b>{" "}
                          {datoIngreso.diagnostico_ingreso}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsFichaM5} index={1}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Fecha de Evaluación: </b>{" "}
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 1
                            ? ""
                            : seguimientos[0].fecha_evaluacion}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Estatus: </b>{" "}
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 1
                            ? ""
                            : seguimientos[0].estado === 0
                            ? "En control"
                            : seguimientos[0].estado === 1
                            ? "Desertor"
                            : seguimientos[0].estado === 2
                            ? "Referido"
                            : "Alta nutricional"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Peso(Kg): </b>{" "}
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 1
                            ? ""
                            : seguimientos[0].peso}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Talla(cm): </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 1
                            ? ""
                            : seguimientos[0].talla}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>PB: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 1
                            ? ""
                            : seguimientos[0].circunferencia}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 1
                            ? ""
                            : seguimientos[0].clasificacion_pb}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Puntaje Z: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 1
                            ? ""
                            : seguimientos[0].puntaje_z}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 1
                            ? ""
                            : seguimientos[0].clasificacion_nut}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 5 }} />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={5} variant="h5">
                          <b>Diagnóstico: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 1
                            ? ""
                            : seguimientos[0].diagnostico_ingreso}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={5} variant="h5">
                          <b>Observaciones: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 1
                            ? ""
                            : seguimientos[0].observacion}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsFichaM5} index={2}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Fecha de Evaluación: </b>{" "}
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 2
                            ? ""
                            : seguimientos[1].fecha_evaluacion}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Estatus: </b>{" "}
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 2
                            ? ""
                            : seguimientos[1].estado === 0
                            ? "En control"
                            : seguimientos[1].estado === 1
                            ? "Desertor"
                            : seguimientos[1].estado === 2
                            ? "Referido"
                            : "Alta nutricional"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Peso(Kg): </b>{" "}
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 2
                            ? ""
                            : seguimientos[1].peso}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Talla(cm): </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 2
                            ? ""
                            : seguimientos[1].talla}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>PB: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 2
                            ? ""
                            : seguimientos[1].circunferencia}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 2
                            ? ""
                            : seguimientos[1].clasificacion_pb}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Puntaje Z: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 2
                            ? ""
                            : seguimientos[1].puntaje_z}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 2
                            ? ""
                            : seguimientos[1].clasificacion_nut}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 5 }} />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={5} variant="h5">
                          <b>Diagnóstico: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 2
                            ? ""
                            : seguimientos[1].diagnostico_ingreso}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={5} variant="h5">
                          <b>Observaciones: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 2
                            ? ""
                            : seguimientos[1].observacion}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsFichaM5} index={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Fecha de Evaluación: </b>{" "}
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 3
                            ? ""
                            : seguimientos[2].fecha_evaluacion}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Estatus: </b>{" "}
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 3
                            ? ""
                            : seguimientos[2].estado === 0
                            ? "En control"
                            : seguimientos[2].estado === 1
                            ? "Desertor"
                            : seguimientos[2].estado === 2
                            ? "Referido"
                            : "Alta nutricional"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Peso(Kg): </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 3
                            ? ""
                            : seguimientos[2].peso}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Talla(cm): </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 3
                            ? ""
                            : seguimientos[2].talla}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>PB: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 3
                            ? ""
                            : seguimientos[2].circunferencia}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 3
                            ? ""
                            : seguimientos[2].clasificacion_pb}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Puntaje Z: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 3
                            ? ""
                            : seguimientos[2].puntaje_z}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 3
                            ? ""
                            : seguimientos[2].clasificacion_nut}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 5 }} />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={5} variant="h5">
                          <b>Diagnóstico: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 3
                            ? ""
                            : seguimientos[2].diagnostico_ingreso}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={5} variant="h5">
                          <b>Observaciones: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 3
                            ? ""
                            : seguimientos[2].observacion}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsFichaM5} index={4}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Fecha de Evaluación: </b>{" "}
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 4
                            ? ""
                            : seguimientos[3].fecha_evaluacion}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Estatus: </b>{" "}
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 4
                            ? ""
                            : seguimientos[3].estado === 0
                            ? "En control"
                            : seguimientos[3].estado === 1
                            ? "Desertor"
                            : seguimientos[3].estado === 2
                            ? "Referido"
                            : "Alta nutricional"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Peso(Kg): </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 4
                            ? ""
                            : seguimientos[3].peso}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Talla(cm): </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 4
                            ? ""
                            : seguimientos[3].tall}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>PB: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 4
                            ? ""
                            : seguimientos[3].circunferencia}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 4
                            ? ""
                            : seguimientos[3].clasificacion_pb}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Puntaje Z: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 4
                            ? ""
                            : seguimientos[3].puntaje_z}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 4
                            ? ""
                            : seguimientos[3].clasificacion_nut}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 5 }} />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={5} variant="h5">
                          <b>Diagnóstico: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 4
                            ? ""
                            : seguimientos[3].diagnostico_ingreso}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={5} variant="h5">
                          <b>Observaciones: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 4
                            ? ""
                            : seguimientos[3].observacion}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsFichaM5} index={5}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Fecha de Evaluación: </b>{" "}
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 5
                            ? ""
                            : seguimientos[4].fecha_evaluacion}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Estatus: </b>{" "}
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 5
                            ? ""
                            : seguimientos[4].estado === 0
                            ? "En control"
                            : seguimientos[4].estado === 1
                            ? "Desertor"
                            : seguimientos[4].estado === 2
                            ? "Referido"
                            : "Alta nutricional"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Peso(Kg): </b>{" "}
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 5
                            ? ""
                            : seguimientos[4].peso}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Talla(cm): </b>{" "}
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 5
                            ? ""
                            : seguimientos[4].talla}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>PB: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 5
                            ? ""
                            : seguimientos[4].circunferencia}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 5
                            ? ""
                            : seguimientos[4].clasificacion_pb}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Puntaje Z: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 5
                            ? ""
                            : seguimientos[4].puntaje_z}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 5
                            ? ""
                            : seguimientos[4].clasificacion_nut}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 5 }} />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={5} variant="h5">
                          <b>Diagnóstico: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 5
                            ? ""
                            : seguimientos[4].diagnostico_ingreso}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={5} variant="h5">
                          <b>Observaciones: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 5
                            ? ""
                            : seguimientos[4].Observacion}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsFichaM5} index={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Fecha de Evaluación: </b>{" "}
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 6
                            ? ""
                            : seguimientos[5].fecha_evaluacion}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Estatus: </b>{" "}
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 6
                            ? ""
                            : seguimientos[5].estado === 0
                            ? "En control"
                            : seguimientos[5].estado === 1
                            ? "Desertor"
                            : seguimientos[5].estado === 2
                            ? "Referido"
                            : "Alta nutricional"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Peso(Kg): </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 6
                            ? ""
                            : seguimientos[5].peso}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Talla(cm): </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 6
                            ? ""
                            : seguimientos[5].talla}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>PB: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 6
                            ? ""
                            : seguimientos[5].circunferencia}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 6
                            ? ""
                            : seguimientos[5].clasificacion_pb}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Puntaje Z: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 6
                            ? ""
                            : seguimientos[5].puntaje_z}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 6
                            ? ""
                            : seguimientos[5].clasificacion_nut}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 5 }} />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={5} variant="h5">
                          <b>Diagnóstico: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 6
                            ? ""
                            : seguimientos[5].diagnostico_ingreso}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={5} variant="h5">
                          <b>Observaciones: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 6
                            ? ""
                            : seguimientos[5].observacion}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsFichaM5} index={7}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Fecha de Evaluación: </b>{" "}
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 7
                            ? ""
                            : seguimientos[6].fecha_evaluacion}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Estatus: </b>{" "}
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 7
                            ? ""
                            : seguimientos[6].estado === 0
                            ? "En control"
                            : seguimientos[6].estado === 1
                            ? "Desertor"
                            : seguimientos[6].estado === 2
                            ? "Referido"
                            : "Alta nutricional"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Peso(Kg): </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 7
                            ? ""
                            : seguimientos[6].peso}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Talla(cm): </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 7
                            ? ""
                            : seguimientos[6].talla}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>PB: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 7
                            ? ""
                            : seguimientos[6].circunferencia}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 7
                            ? ""
                            : seguimientos[6].clasificacion_pb}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Puntaje Z: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 7
                            ? ""
                            : seguimientos[6].puntaje_z}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 7
                            ? ""
                            : seguimientos[6].clasificacion_nut}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 5 }} />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={5} variant="h5">
                          <b>Diagnóstico: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 7
                            ? ""
                            : seguimientos[6].diagnostico_ingreso}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={5} variant="h5">
                          <b>Observaciones: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 7
                            ? ""
                            : seguimientos[6].observacion}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsFichaM5} index={8}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Fecha de Evaluación: </b>{" "}
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 8
                            ? ""
                            : seguimientos[7].fecha_evaluacion}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Estatus: </b>{" "}
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 8
                            ? ""
                            : seguimientos[7].estado === 0
                            ? "En control"
                            : seguimientos[7].estado === 1
                            ? "Desertor"
                            : seguimientos[7].estado === 2
                            ? "Referido"
                            : "Alta nutricional"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Peso(Kg): </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 8
                            ? ""
                            : seguimientos[7].peso}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Talla(cm): </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 8
                            ? ""
                            : seguimientos[7].talla}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>PB: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 8
                            ? ""
                            : seguimientos[7].circunferencia}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 8
                            ? ""
                            : seguimientos[7].clasificacion_pb}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Puntaje Z: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 8
                            ? ""
                            : seguimientos[7].puntaje_z}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 8
                            ? ""
                            : seguimientos[7].clasificacion_nut}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid></Grid>
                    <Divider sx={{ mt: 5 }} />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={5} variant="h5">
                          <b>Diagnóstico: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 8
                            ? ""
                            : seguimientos[7].diagnostico_ingreso}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={5} variant="h5">
                          <b>Observaciones: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 8
                            ? ""
                            : seguimientos[7].observacion}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                </Box>
                <Divider sx={{ mt: 2, mb: 1 }} />
                <Grid
                  container
                  sx={{ mt: 1 }}
                  spacing={1}
                  justifyContent="flex-end"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleCloseFichaM5}
                    >
                      Cerrar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            {/*////////////////////////////////////  MODAL FICHA MICRONUTRIENTES //////////////////////////////////////*/}
            <Modal
              open={openFichaMicro}
              onClose={handleCloseFichaMicro}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styleFichaM5}>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={tabsFichaMicro}
                      onChange={handleTabsFichaMicro}
                      variant={isSmallScreen ? "scrollable" : "fullWidth"}
                      scrollButtons
                      allowScrollButtonsMobile
                      aria-label="basic tabs example"
                    >
                      <Tab
                        icon={<ContactPageOutlinedIcon />}
                        label="Info. General"
                        {...a11yProps(0)}
                      />
                      <Tab
                        icon={<Filter1OutlinedIcon />}
                        label="Control I"
                        {...a11yProps(1)}
                        hidden={seguimientos.length < 1}
                      />
                      <Tab
                        icon={<Filter2OutlinedIcon />}
                        label="Control II"
                        {...a11yProps(2)}
                        hidden={seguimientos.length < 2}
                      />
                      <Tab
                        icon={<Filter3OutlinedIcon />}
                        label="Control III"
                        {...a11yProps(3)}
                        hidden={seguimientos.length < 3}
                      />
                      <Tab
                        icon={<Filter4OutlinedIcon />}
                        label="Control IV"
                        {...a11yProps(4)}
                        hidden={seguimientos.length < 4}
                      />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={tabsFichaMicro} index={0}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Nombres: </b> {datoIngreso.nombre}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Apellidos: </b> {datoIngreso.apellido}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Género: </b> {datoIngreso.sexo}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Fecha de Nacimiento: </b>{" "}
                          {datoIngreso.fecha_nacimiento}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Edad en Meses: </b> {datoIngreso.edad}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Registrado: </b> {datoIngreso.registrado}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Escolaridad: </b> {datoIngreso.escolaridad}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Grupo Etnico: </b> {datoIngreso.grupo_etnico}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Discapacidad: </b> {datoIngreso.discapacidad}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Municipio: </b> {datoIngreso.municipio}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Comunidad: </b> {datoIngreso.comunidad}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Equipo: </b>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Fecha Ingreso al Programa: </b>{" "}
                          {datoIngreso.fecha_evaluacion}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Peso(Kg): </b> {datoIngreso.peso}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Talla(cm): </b> {datoIngreso.talla}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>PB de Ingreso: </b> {datoIngreso.circunferencia}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Puntaje Z: </b> {datoIngreso.puntaje_z}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Diagnóstico de Ingreso: </b>{" "}
                          {datoIngreso.diagnostico_ingreso}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsFichaMicro} index={1}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={2}>
                          <b>¿Asistió?: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 1
                            ? ""
                            : seguimientos[0].asistio}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={2}>
                          <b>¿Completó el Esquema?: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 1
                            ? ""
                            : seguimientos[0].esquema}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={2}>
                          <b>Consejería: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 1
                            ? ""
                            : seguimientos[0].consejeria}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={2}>
                          <b>Observaciones: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 1
                            ? ""
                            : seguimientos[0].observacion}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsFichaMicro} index={2}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={2}>
                          <b>¿Asistió?: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 2
                            ? ""
                            : seguimientos[1].asistio}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={2}>
                          <b>¿Completó el Esquema?: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 2
                            ? ""
                            : seguimientos[1].esquema}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={2}>
                          <b>Consejería: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 2
                            ? ""
                            : seguimientos[1].consejeria}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={2}>
                          <b>Observaciones: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 2
                            ? ""
                            : seguimientos[1].observacion}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsFichaMicro} index={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={2}>
                          <b>¿Asistió?: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 3
                            ? ""
                            : seguimientos[2].asistio}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={2}>
                          <b>¿Completó el Esquema?: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 3
                            ? ""
                            : seguimientos[2].esquema}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={2}>
                          <b>Consejería: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 3
                            ? ""
                            : seguimientos[2].consejeria}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={2}>
                          <b>Observaciones: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 3
                            ? ""
                            : seguimientos[2].observacion}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsFichaMicro} index={4}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={2}>
                          <b>¿Asistió?: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 4
                            ? ""
                            : seguimientos[3].asistio}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={2}>
                          <b>¿Completó el Esquema?: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 4
                            ? ""
                            : seguimientos[3].esquema}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={2}>
                          <b>Consejería: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 4
                            ? ""
                            : seguimientos[3].consejeria}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={2}>
                          <b>Observaciones: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 4
                            ? ""
                            : seguimientos[3].observacion}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                </Box>
                <Divider sx={{ mt: 2, mb: 1 }} />
                <Grid
                  container
                  sx={{ mt: 1 }}
                  spacing={1}
                  justifyContent="flex-end"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleCloseFichaMicro}
                    >
                      Cerrar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            {/*////////////////////////////////////  MODAL FICHA MEL //////////////////////////////////////*/}
            <Modal
              open={openFichaMel}
              onClose={handleCloseFichaMel}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styleFichaM5}>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={tabsFichaMel}
                      onChange={handleTabsFichaMel}
                      variant={isSmallScreen ? "scrollable" : "fullWidth"}
                      scrollButtons
                      allowScrollButtonsMobile
                      aria-label="basic tabs example"
                    >
                      <Tab
                        icon={<ContactPageOutlinedIcon />}
                        label="Info. General"
                        {...a11yProps(0)}
                      />
                      <Tab
                        icon={<Filter1OutlinedIcon />}
                        label="Control I"
                        {...a11yProps(1)}
                        hidden={seguimientos.length < 1}
                      />
                      <Tab
                        icon={<Filter2OutlinedIcon />}
                        label="Control II"
                        {...a11yProps(2)}
                        hidden={seguimientos.length < 2}
                      />
                      <Tab
                        icon={<Filter3OutlinedIcon />}
                        label="Control III"
                        {...a11yProps(3)}
                        hidden={seguimientos.length < 3}
                      />
                      <Tab
                        icon={<Filter4OutlinedIcon />}
                        label="Control IV"
                        {...a11yProps(4)}
                        hidden={seguimientos.length < 4}
                      />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={tabsFichaMel} index={0}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Nombres: </b> {datoIngreso.nombre}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Apellidos: </b> {datoIngreso.apellido}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Cédula: </b> {datoIngreso.cedula}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Fecha de Nacimiento: </b>{" "}
                          {datoIngreso.fecha_nacimiento}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Edad en Años: </b> {datoIngreso.edad}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Grupo Etnico: </b> {datoIngreso.grupo_etnico}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Discapacidad: </b> {datoIngreso.discapacidad}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Municipio: </b> {datoIngreso.municipio}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Comunidad: </b> {datoIngreso.comunidad}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Equipo: </b>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Fecha Ingreso al Programa: </b>{" "}
                          {datoIngreso.fecha_evaluacion}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Semanas de Gestación: </b>{" "}
                          {datoIngreso.semana_gestacion}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>IMC de Ingreso: </b> {datoIngreso.imc}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx IMC: </b> {datoIngreso.diagnostico_imc}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>PB de Ingreso: </b>{" "}
                          {datoIngreso.circunferencia_brazo}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx PB: </b> {datoIngreso.clasificacion_pb}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Diagnóstico de Ingreso: </b>{" "}
                          {datoIngreso.diagnostico_ingreso}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsFichaMel} index={1}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1} mb={1}>
                          <b>Semanas de Gestación: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 1
                            ? ""
                            : seguimientos[0].gestacion}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>IMC: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 1
                            ? ""
                            : seguimientos[0].imc}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx IMC: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 1
                            ? ""
                            : seguimientos[0].diagnostico_imc}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>PB: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 1
                            ? ""
                            : seguimientos[0].circunferencia}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx PB: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 1
                            ? ""
                            : seguimientos[0].clasificacion_pb}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={4} variant="h5">
                          <b>Diagnóstico: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 1
                            ? ""
                            : seguimientos[0].diagnostico_ingreso}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={4} variant="h5">
                          <b>Observaciones: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 1
                            ? ""
                            : seguimientos[0].observacion}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsFichaMel} index={2}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1} mb={1}>
                          <b>Semanas de Gestación: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 2
                            ? ""
                            : seguimientos[1].gestacion}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>IMC: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 2
                            ? ""
                            : seguimientos[1].imc}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx IMC: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 2
                            ? ""
                            : seguimientos[1].diagnostico_imc}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>PB: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 2
                            ? ""
                            : seguimientos[1].circunferencia}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx PB: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 2
                            ? ""
                            : seguimientos[1].clasificacion_pb}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={4} variant="h5">
                          <b>Diagnóstico: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 2
                            ? ""
                            : seguimientos[1].diagnostico_ingreso}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={4} variant="h5">
                          <b>Observaciones: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 2
                            ? ""
                            : seguimientos[1].observacion}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsFichaMel} index={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1} mb={1}>
                          <b>Semanas de Gestación: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 3
                            ? ""
                            : seguimientos[2].gestacion}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>IMC: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 3
                            ? ""
                            : seguimientos[2].imc}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx IMC: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 3
                            ? ""
                            : seguimientos[2].diagnostico_imc}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>PB: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 3
                            ? ""
                            : seguimientos[2].circunferencia}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx PB: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 3
                            ? ""
                            : seguimientos[2].clasificacion_pb}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={4} variant="h5">
                          <b>Diagnóstico: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 3
                            ? ""
                            : seguimientos[2].diagnostico_ingreso}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={4} variant="h5">
                          <b>Observaciones: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 3
                            ? ""
                            : seguimientos[2].observacion}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsFichaMel} index={4}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1} mb={1}>
                          <b>Semanas de Gestación: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 4
                            ? ""
                            : seguimientos[3].gestacion}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>IMC: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 4
                            ? ""
                            : seguimientos[3].imc}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx IMC: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 4
                            ? ""
                            : seguimientos[3].diagnostico_imc}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>PB: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 4
                            ? ""
                            : seguimientos[3].circunferencia}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={1}>
                          <b>Dx PB: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 4
                            ? ""
                            : seguimientos[3].clasificacion_pb}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={4} variant="h5">
                          <b>Diagnóstico: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 4
                            ? ""
                            : seguimientos[3].diagnostico_ingreso}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mt={4} variant="h5">
                          <b>Observaciones: </b>
                          {!seguimientos.length
                            ? ""
                            : seguimientos.length < 4
                            ? ""
                            : seguimientos[3].observacion}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                </Box>
                <Divider sx={{ mt: 2, mb: 1 }} />
                <Grid
                  container
                  sx={{ mt: 1 }}
                  spacing={1}
                  justifyContent="flex-end"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleCloseFichaMel}
                    >
                      Cerrar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            {/* /////////////////////////////////////  MODAL EDITAR M5  ///////////////////////////////////////// */}
            <Modal open={openEditM5} onClose={handleCloseEditM5}>
              <Box sx={style}>
                <Typography variant="h4">
                  <b>
                    {" "}
                    <EditIcon sx={{ fontSize: 30 }} /> Editar Control:
                  </b>{" "}
                  {datoIngreso.nombre} {datoIngreso.apellido}
                </Typography>
                <Divider sx={{ mt: 2, mb: 3 }} />
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={tabsEditM5}
                      onChange={handleTabsEditM5}
                      variant={isSmallScreen ? "scrollable" : "fullWidth"}
                      scrollButtons
                      allowScrollButtonsMobile
                    >
                      <Tab
                        icon={<Filter1OutlinedIcon />}
                        label="Control I"
                        {...a11yProps(0)}
                        hidden={seguimientos.length < 1}
                      />
                      <Tab
                        icon={<Filter2OutlinedIcon />}
                        label="Control II"
                        {...a11yProps(1)}
                        hidden={seguimientos.length < 2}
                      />
                      <Tab
                        icon={<Filter3OutlinedIcon />}
                        label="Control III"
                        {...a11yProps(2)}
                        hidden={seguimientos.length < 3}
                      />
                      <Tab
                        icon={<Filter4OutlinedIcon />}
                        label="Control IV"
                        {...a11yProps(3)}
                        hidden={seguimientos.length < 4}
                      />
                      <Tab
                        icon={<Filter5OutlinedIcon />}
                        label="Control V"
                        {...a11yProps(4)}
                        hidden={seguimientos.length < 5}
                      />
                      <Tab
                        icon={<Filter6OutlinedIcon />}
                        label="Control VI"
                        {...a11yProps(5)}
                        hidden={seguimientos.length < 6}
                      />
                      <Tab
                        icon={<Filter7OutlinedIcon />}
                        label="Control VII"
                        {...a11yProps(6)}
                        hidden={seguimientos.length < 7}
                      />
                      <Tab
                        icon={<Filter8OutlinedIcon />}
                        label="Control VIII"
                        {...a11yProps(7)}
                        hidden={seguimientos.length < 8}
                      />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={tabsEditM5} index={0}>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mb={isSmallScreen ? 1 : 3}
                        mt={1}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            ¿Asistió?
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optAsistEditar}
                            label="¿Asistió?"
                            onChange={handleOptAsistEditar}
                          >
                            <MenuItem value="">
                              <em>Seleccionar Asistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} mt={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="es"
                        >
                          <DesktopDatePicker
                            label="Fecha de Evaluacion"
                            name="fechaEvaluacion"
                            inputFormat="DD/MM/YYYY"
                            value={fechaEvaluacionEditar}
                            onChange={setFechaEvaluacionEditar}
                            renderInput={(params) => {
                              return <TextField fullWidth {...params} />;
                            }}
                            clearable
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mt={isSmallScreen ? 0 : 1}
                        hidden={isSmallScreen}
                      >
                        <FormControl fullWidth>
                          <InputLabel
                            id="demo-simple-select-label"
                            hidden={
                              optAsistEditar === "Si" || optAsistEditar === ""
                            }
                          >
                            Razón de Inasistencia
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optRazonEditar}
                            label="Razón de Inasistencia"
                            onChange={handleOptRazonEditar}
                            hidden={
                              optAsistEditar === "Si" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Razón de Inasistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Problemas de comunicación"}>
                              Problemas de comunicación
                            </MenuItem>
                            <MenuItem value={"Niño ausente en la comunidad"}>
                              Niño ausente en la comunidad
                            </MenuItem>
                            <MenuItem value={"Promotor Ausente"}>
                              Promotor Ausente
                            </MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Edema
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optEdemaEditar}
                            label="Edema"
                            onChange={handleOptEdemaEditar}
                            disabled={
                              optAsistEditar === "No" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Edema...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                            <MenuItem value={"Aumentó"}>Aumentó</MenuItem>
                            <MenuItem value={"Disminuyó"}>Disminuyó</MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Peso (Kg)"
                          variant="outlined"
                          value={pesoBenefEditar}
                          onChange={(event) =>
                            setPesoBenefEditar(event.target.value)
                          }
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Talla (cm)"
                          variant="outlined"
                          value={tallaBenefEditar}
                          onChange={(event) =>
                            setTallaBenefEditar(event.target.value)
                          }
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Puntaje Z"
                          variant="outlined"
                          value={puntajeZEditar}
                          onChange={handlePuntajeZEditar}
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación Nutricional"
                          variant="outlined"
                          value={diagPZEditar}
                          onChange={(event) =>
                            setDiagPZEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Circ. de Brazo (CMB)"
                          variant="outlined"
                          value={pbEditar}
                          onChange={handlePbEditar}
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación PB"
                          variant="outlined"
                          value={diagPBEditar}
                          onChange={(event) =>
                            setDiagPBEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Diagnóstico"
                          variant="outlined"
                          value={diagFinalEditar}
                          onChange={(event) =>
                            setDiagFinalEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Estatus
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={estatusM5Editar}
                            label="Estatus"
                            onChange={handleEstatusM5Editar}
                            disabled={
                              optAsistEditar === "No" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Estatus...</em>
                            </MenuItem>
                            <MenuItem value={0}>En Control</MenuItem>
                            <MenuItem value={1}>Desertor</MenuItem>
                            <MenuItem value={2}>Referido</MenuItem>
                            <MenuItem value={3}>Alta Nutricional</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Observaciones"
                          variant="outlined"
                          value={observacionesM5Editar}
                          onChange={(event) =>
                            setObservacionesM5Editar(event.target.value)
                          }
                          disabled={optAsistEditar === ""}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 2 }} />
                    <Grid
                      container
                      sx={{ mt: 1 }}
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={handleCloseEditM5}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={handleEliminarControlM5}
                        >
                          Eliminar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{ bgcolor: "teal", color: "white" }}
                          onClick={handleEditarControlM5}
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsEditM5} index={1}>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mb={isSmallScreen ? 1 : 3}
                        mt={1}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            ¿Asistió?
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optAsistEditar}
                            label="¿Asistió?"
                            onChange={handleOptAsistEditar}
                          >
                            <MenuItem value="">
                              <em>Seleccionar Asistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} mt={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="es"
                        >
                          <DesktopDatePicker
                            label="Fecha de Evaluacion"
                            name="fechaEvaluacion"
                            inputFormat="DD/MM/YYYY"
                            value={fechaEvaluacionEditar}
                            onChange={setFechaEvaluacionEditar}
                            renderInput={(params) => {
                              return <TextField fullWidth {...params} />;
                            }}
                            clearable
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mt={isSmallScreen ? 0 : 1}
                        hidden={isSmallScreen}
                      >
                        <FormControl fullWidth>
                          <InputLabel
                            id="demo-simple-select-label"
                            hidden={
                              optAsistEditar === "Si" || optAsistEditar === ""
                            }
                          >
                            Razón de Inasistencia
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optRazonEditar}
                            label="Razón de Inasistencia"
                            onChange={handleOptRazonEditar}
                            hidden={
                              optAsistEditar === "Si" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Razón de Inasistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Problemas de comunicación"}>
                              Problemas de comunicación
                            </MenuItem>
                            <MenuItem value={"Niño ausente en la comunidad"}>
                              Niño ausente en la comunidad
                            </MenuItem>
                            <MenuItem value={"Promotor Ausente"}>
                              Promotor Ausente
                            </MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Edema
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optEdemaEditar}
                            label="Edema"
                            onChange={handleOptEdemaEditar}
                            disabled={
                              optAsistEditar === "No" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Edema...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                            <MenuItem value={"Aumentó"}>Aumentó</MenuItem>
                            <MenuItem value={"Disminuyó"}>Disminuyó</MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Peso (Kg)"
                          variant="outlined"
                          value={pesoBenefEditar}
                          onChange={(event) =>
                            setPesoBenefEditar(event.target.value)
                          }
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Talla (cm)"
                          variant="outlined"
                          value={tallaBenefEditar}
                          onChange={(event) =>
                            setTallaBenefEditar(event.target.value)
                          }
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Puntaje Z"
                          variant="outlined"
                          value={puntajeZEditar}
                          onChange={handlePuntajeZEditar}
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación Nutricional"
                          variant="outlined"
                          value={diagPZEditar}
                          onChange={(event) =>
                            setDiagPZEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Circ. de Brazo (CMB)"
                          variant="outlined"
                          value={pbEditar}
                          onChange={handlePbEditar}
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación PB"
                          variant="outlined"
                          value={diagPBEditar}
                          onChange={(event) =>
                            setDiagPBEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Diagnóstico"
                          variant="outlined"
                          value={diagFinalEditar}
                          onChange={(event) =>
                            setDiagFinalEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Estatus
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={estatusM5Editar}
                            label="Estatus"
                            onChange={handleEstatusM5Editar}
                            disabled={
                              optAsistEditar === "No" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Estatus...</em>
                            </MenuItem>
                            <MenuItem value={0}>En Control</MenuItem>
                            <MenuItem value={1}>Desertor</MenuItem>
                            <MenuItem value={2}>Referido</MenuItem>
                            <MenuItem value={3}>Alta Nutricional</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Observaciones"
                          variant="outlined"
                          value={observacionesM5Editar}
                          onChange={(event) =>
                            setObservacionesM5Editar(event.target.value)
                          }
                          disabled={optAsistEditar === ""}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 2 }} />
                    <Grid
                      container
                      sx={{ mt: 1 }}
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={handleCloseEditM5}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={handleEliminarControlM5}
                        >
                          Eliminar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{ bgcolor: "teal", color: "white" }}
                          onClick={handleEditarControlM5}
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsEditM5} index={2}>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mb={isSmallScreen ? 1 : 3}
                        mt={1}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            ¿Asistió?
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optAsistEditar}
                            label="¿Asistió?"
                            onChange={handleOptAsistEditar}
                          >
                            <MenuItem value="">
                              <em>Seleccionar Asistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} mt={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="es"
                        >
                          <DesktopDatePicker
                            label="Fecha de Evaluacion"
                            name="fechaEvaluacion"
                            inputFormat="DD/MM/YYYY"
                            value={fechaEvaluacionEditar}
                            onChange={setFechaEvaluacionEditar}
                            renderInput={(params) => {
                              return <TextField fullWidth {...params} />;
                            }}
                            clearable
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mt={isSmallScreen ? 0 : 1}
                        hidden={isSmallScreen}
                      >
                        <FormControl fullWidth>
                          <InputLabel
                            id="demo-simple-select-label"
                            hidden={
                              optAsistEditar === "Si" || optAsistEditar === ""
                            }
                          >
                            Razón de Inasistencia
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optRazonEditar}
                            label="Razón de Inasistencia"
                            onChange={handleOptRazonEditar}
                            hidden={
                              optAsistEditar === "Si" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Razón de Inasistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Problemas de comunicación"}>
                              Problemas de comunicación
                            </MenuItem>
                            <MenuItem value={"Niño ausente en la comunidad"}>
                              Niño ausente en la comunidad
                            </MenuItem>
                            <MenuItem value={"Promotor Ausente"}>
                              Promotor Ausente
                            </MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Edema
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optEdemaEditar}
                            label="Edema"
                            onChange={handleOptEdemaEditar}
                            disabled={
                              optAsistEditar === "No" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Edema...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                            <MenuItem value={"Aumentó"}>Aumentó</MenuItem>
                            <MenuItem value={"Disminuyó"}>Disminuyó</MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Peso (Kg)"
                          variant="outlined"
                          value={pesoBenefEditar}
                          onChange={(event) =>
                            setPesoBenefEditar(event.target.value)
                          }
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Talla (cm)"
                          variant="outlined"
                          value={tallaBenefEditar}
                          onChange={(event) =>
                            setTallaBenefEditar(event.target.value)
                          }
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Puntaje Z"
                          variant="outlined"
                          value={puntajeZEditar}
                          onChange={handlePuntajeZEditar}
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación Nutricional"
                          variant="outlined"
                          value={diagPZEditar}
                          onChange={(event) =>
                            setDiagPZEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Circ. de Brazo (CMB)"
                          variant="outlined"
                          value={pbEditar}
                          onChange={handlePbEditar}
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación PB"
                          variant="outlined"
                          value={diagPBEditar}
                          onChange={(event) =>
                            setDiagPBEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Diagnóstico"
                          variant="outlined"
                          value={diagFinalEditar}
                          onChange={(event) =>
                            setDiagFinalEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Estatus
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={estatusM5Editar}
                            label="Estatus"
                            onChange={handleEstatusM5Editar}
                            disabled={
                              optAsistEditar === "No" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Estatus...</em>
                            </MenuItem>
                            <MenuItem value={0}>En Control</MenuItem>
                            <MenuItem value={1}>Desertor</MenuItem>
                            <MenuItem value={2}>Referido</MenuItem>
                            <MenuItem value={3}>Alta Nutricional</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Observaciones"
                          variant="outlined"
                          value={observacionesM5Editar}
                          onChange={(event) =>
                            setObservacionesM5Editar(event.target.value)
                          }
                          disabled={optAsistEditar === ""}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 2 }} />
                    <Grid
                      container
                      sx={{ mt: 1 }}
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={handleCloseEditM5}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={handleEliminarControlM5}
                        >
                          Eliminar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{ bgcolor: "teal", color: "white" }}
                          onClick={handleEditarControlM5}
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsEditM5} index={3}>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mb={isSmallScreen ? 1 : 3}
                        mt={1}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            ¿Asistió?
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optAsistEditar}
                            label="¿Asistió?"
                            onChange={handleOptAsistEditar}
                          >
                            <MenuItem value="">
                              <em>Seleccionar Asistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} mt={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="es"
                        >
                          <DesktopDatePicker
                            label="Fecha de Evaluacion"
                            name="fechaEvaluacion"
                            inputFormat="DD/MM/YYYY"
                            value={fechaEvaluacionEditar}
                            onChange={setFechaEvaluacionEditar}
                            renderInput={(params) => {
                              return <TextField fullWidth {...params} />;
                            }}
                            clearable
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mt={isSmallScreen ? 0 : 1}
                        hidden={isSmallScreen}
                      >
                        <FormControl fullWidth>
                          <InputLabel
                            id="demo-simple-select-label"
                            hidden={
                              optAsistEditar === "Si" || optAsistEditar === ""
                            }
                          >
                            Razón de Inasistencia
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optRazonEditar}
                            label="Razón de Inasistencia"
                            onChange={handleOptRazonEditar}
                            hidden={
                              optAsistEditar === "Si" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Razón de Inasistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Problemas de comunicación"}>
                              Problemas de comunicación
                            </MenuItem>
                            <MenuItem value={"Niño ausente en la comunidad"}>
                              Niño ausente en la comunidad
                            </MenuItem>
                            <MenuItem value={"Promotor Ausente"}>
                              Promotor Ausente
                            </MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Edema
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optEdemaEditar}
                            label="Edema"
                            onChange={handleOptEdemaEditar}
                            disabled={
                              optAsistEditar === "No" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Edema...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                            <MenuItem value={"Aumentó"}>Aumentó</MenuItem>
                            <MenuItem value={"Disminuyó"}>Disminuyó</MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Peso (Kg)"
                          variant="outlined"
                          value={pesoBenefEditar}
                          onChange={(event) =>
                            setPesoBenefEditar(event.target.value)
                          }
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Talla (cm)"
                          variant="outlined"
                          value={tallaBenefEditar}
                          onChange={(event) =>
                            setTallaBenefEditar(event.target.value)
                          }
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Puntaje Z"
                          variant="outlined"
                          value={puntajeZEditar}
                          onChange={handlePuntajeZEditar}
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación Nutricional"
                          variant="outlined"
                          value={diagPZEditar}
                          onChange={(event) =>
                            setDiagPZEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Circ. de Brazo (CMB)"
                          variant="outlined"
                          value={pbEditar}
                          onChange={handlePbEditar}
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación PB"
                          variant="outlined"
                          value={diagPBEditar}
                          onChange={(event) =>
                            setDiagPBEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Diagnóstico"
                          variant="outlined"
                          value={diagFinalEditar}
                          onChange={(event) =>
                            setDiagFinalEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Estatus
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={estatusM5Editar}
                            label="Estatus"
                            onChange={handleEstatusM5Editar}
                            disabled={
                              optAsistEditar === "No" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Estatus...</em>
                            </MenuItem>
                            <MenuItem value={0}>En Control</MenuItem>
                            <MenuItem value={1}>Desertor</MenuItem>
                            <MenuItem value={2}>Referido</MenuItem>
                            <MenuItem value={3}>Alta Nutricional</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Observaciones"
                          variant="outlined"
                          value={observacionesM5Editar}
                          onChange={(event) =>
                            setObservacionesM5Editar(event.target.value)
                          }
                          disabled={optAsistEditar === ""}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 2 }} />
                    <Grid
                      container
                      sx={{ mt: 1 }}
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={handleCloseEditM5}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={handleEliminarControlM5}
                        >
                          Eliminar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{ bgcolor: "teal", color: "white" }}
                          onClick={handleEditarControlM5}
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsEditM5} index={4}>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mb={isSmallScreen ? 1 : 3}
                        mt={1}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            ¿Asistió?
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optAsistEditar}
                            label="¿Asistió?"
                            onChange={handleOptAsistEditar}
                          >
                            <MenuItem value="">
                              <em>Seleccionar Asistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} mt={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="es"
                        >
                          <DesktopDatePicker
                            label="Fecha de Evaluacion"
                            name="fechaEvaluacion"
                            inputFormat="DD/MM/YYYY"
                            value={fechaEvaluacionEditar}
                            onChange={setFechaEvaluacionEditar}
                            renderInput={(params) => {
                              return <TextField fullWidth {...params} />;
                            }}
                            clearable
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mt={isSmallScreen ? 0 : 1}
                        hidden={isSmallScreen}
                      >
                        <FormControl fullWidth>
                          <InputLabel
                            id="demo-simple-select-label"
                            hidden={
                              optAsistEditar === "Si" || optAsistEditar === ""
                            }
                          >
                            Razón de Inasistencia
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optRazonEditar}
                            label="Razón de Inasistencia"
                            onChange={handleOptRazonEditar}
                            hidden={
                              optAsistEditar === "Si" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Razón de Inasistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Problemas de comunicación"}>
                              Problemas de comunicación
                            </MenuItem>
                            <MenuItem value={"Niño ausente en la comunidad"}>
                              Niño ausente en la comunidad
                            </MenuItem>
                            <MenuItem value={"Promotor Ausente"}>
                              Promotor Ausente
                            </MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Edema
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optEdemaEditar}
                            label="Edema"
                            onChange={handleOptEdemaEditar}
                            disabled={
                              optAsistEditar === "No" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Edema...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                            <MenuItem value={"Aumentó"}>Aumentó</MenuItem>
                            <MenuItem value={"Disminuyó"}>Disminuyó</MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Peso (Kg)"
                          variant="outlined"
                          value={pesoBenefEditar}
                          onChange={(event) =>
                            setPesoBenefEditar(event.target.value)
                          }
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Talla (cm)"
                          variant="outlined"
                          value={tallaBenefEditar}
                          onChange={(event) =>
                            setTallaBenefEditar(event.target.value)
                          }
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Puntaje Z"
                          variant="outlined"
                          value={puntajeZEditar}
                          onChange={handlePuntajeZEditar}
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación Nutricional"
                          variant="outlined"
                          value={diagPZEditar}
                          onChange={(event) =>
                            setDiagPZEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Circ. de Brazo (CMB)"
                          variant="outlined"
                          value={pbEditar}
                          onChange={handlePbEditar}
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación PB"
                          variant="outlined"
                          value={diagPBEditar}
                          onChange={(event) =>
                            setDiagPBEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Diagnóstico"
                          variant="outlined"
                          value={diagFinalEditar}
                          onChange={(event) =>
                            setDiagFinalEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Estatus
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={estatusM5Editar}
                            label="Estatus"
                            onChange={handleEstatusM5Editar}
                            disabled={
                              optAsistEditar === "No" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Estatus...</em>
                            </MenuItem>
                            <MenuItem value={0}>En Control</MenuItem>
                            <MenuItem value={1}>Desertor</MenuItem>
                            <MenuItem value={2}>Referido</MenuItem>
                            <MenuItem value={3}>Alta Nutricional</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Observaciones"
                          variant="outlined"
                          value={observacionesM5Editar}
                          onChange={(event) =>
                            setObservacionesM5Editar(event.target.value)
                          }
                          disabled={optAsistEditar === ""}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 2 }} />
                    <Grid
                      container
                      sx={{ mt: 1 }}
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={handleCloseEditM5}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={handleEliminarControlM5}
                        >
                          Eliminar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{ bgcolor: "teal", color: "white" }}
                          onClick={handleEditarControlM5}
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsEditM5} index={5}>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mb={isSmallScreen ? 1 : 3}
                        mt={1}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            ¿Asistió?
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optAsistEditar}
                            label="¿Asistió?"
                            onChange={handleOptAsistEditar}
                          >
                            <MenuItem value="">
                              <em>Seleccionar Asistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} mt={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="es"
                        >
                          <DesktopDatePicker
                            label="Fecha de Evaluacion"
                            name="fechaEvaluacion"
                            inputFormat="DD/MM/YYYY"
                            value={fechaEvaluacionEditar}
                            onChange={setFechaEvaluacionEditar}
                            renderInput={(params) => {
                              return <TextField fullWidth {...params} />;
                            }}
                            clearable
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mt={isSmallScreen ? 0 : 1}
                        hidden={isSmallScreen}
                      >
                        <FormControl fullWidth>
                          <InputLabel
                            id="demo-simple-select-label"
                            hidden={
                              optAsistEditar === "Si" || optAsistEditar === ""
                            }
                          >
                            Razón de Inasistencia
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optRazonEditar}
                            label="Razón de Inasistencia"
                            onChange={handleOptRazonEditar}
                            hidden={
                              optAsistEditar === "Si" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Razón de Inasistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Problemas de comunicación"}>
                              Problemas de comunicación
                            </MenuItem>
                            <MenuItem value={"Niño ausente en la comunidad"}>
                              Niño ausente en la comunidad
                            </MenuItem>
                            <MenuItem value={"Promotor Ausente"}>
                              Promotor Ausente
                            </MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Edema
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optEdemaEditar}
                            label="Edema"
                            onChange={handleOptEdemaEditar}
                            disabled={
                              optAsistEditar === "No" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Edema...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                            <MenuItem value={"Aumentó"}>Aumentó</MenuItem>
                            <MenuItem value={"Disminuyó"}>Disminuyó</MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Peso (Kg)"
                          variant="outlined"
                          value={pesoBenefEditar}
                          onChange={(event) =>
                            setPesoBenefEditar(event.target.value)
                          }
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Talla (cm)"
                          variant="outlined"
                          value={tallaBenefEditar}
                          onChange={(event) =>
                            setTallaBenefEditar(event.target.value)
                          }
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Puntaje Z"
                          variant="outlined"
                          value={puntajeZEditar}
                          onChange={handlePuntajeZEditar}
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación Nutricional"
                          variant="outlined"
                          value={diagPZEditar}
                          onChange={(event) =>
                            setDiagPZEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Circ. de Brazo (CMB)"
                          variant="outlined"
                          value={pbEditar}
                          onChange={handlePbEditar}
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación PB"
                          variant="outlined"
                          value={diagPBEditar}
                          onChange={(event) =>
                            setDiagPBEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Diagnóstico"
                          variant="outlined"
                          value={diagFinalEditar}
                          onChange={(event) =>
                            setDiagFinalEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Estatus
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={estatusM5Editar}
                            label="Estatus"
                            onChange={handleEstatusM5Editar}
                            disabled={
                              optAsistEditar === "No" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Estatus...</em>
                            </MenuItem>
                            <MenuItem value={0}>En Control</MenuItem>
                            <MenuItem value={1}>Desertor</MenuItem>
                            <MenuItem value={2}>Referido</MenuItem>
                            <MenuItem value={3}>Alta Nutricional</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Observaciones"
                          variant="outlined"
                          value={observacionesM5Editar}
                          onChange={(event) =>
                            setObservacionesM5Editar(event.target.value)
                          }
                          disabled={optAsistEditar === ""}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 2 }} />
                    <Grid
                      container
                      sx={{ mt: 1 }}
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={handleCloseEditM5}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={handleEliminarControlM5}
                        >
                          Eliminar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{ bgcolor: "teal", color: "white" }}
                          onClick={handleEditarControlM5}
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsEditM5} index={6}>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mb={isSmallScreen ? 1 : 3}
                        mt={1}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            ¿Asistió?
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optAsistEditar}
                            label="¿Asistió?"
                            onChange={handleOptAsist}
                          >
                            <MenuItem value="">
                              <em>Seleccionar Asistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} mt={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="es"
                        >
                          <DesktopDatePicker
                            label="Fecha de Evaluacion"
                            name="fechaEvaluacion"
                            inputFormat="DD/MM/YYYY"
                            value={fechaEvaluacionEditar}
                            onChange={setFechaEvaluacionEditar}
                            renderInput={(params) => {
                              return <TextField fullWidth {...params} />;
                            }}
                            clearable
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mt={isSmallScreen ? 0 : 1}
                        hidden={isSmallScreen}
                      >
                        <FormControl fullWidth>
                          <InputLabel
                            id="demo-simple-select-label"
                            hidden={
                              optAsistEditar === "Si" || optAsistEditar === ""
                            }
                          >
                            Razón de Inasistencia
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optRazonEditar}
                            label="Razón de Inasistencia"
                            onChange={handleOptRazonEditar}
                            hidden={
                              optAsistEditar === "Si" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Razón de Inasistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Problemas de comunicación"}>
                              Problemas de comunicación
                            </MenuItem>
                            <MenuItem value={"Niño ausente en la comunidad"}>
                              Niño ausente en la comunidad
                            </MenuItem>
                            <MenuItem value={"Promotor Ausente"}>
                              Promotor Ausente
                            </MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Edema
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optEdemaEditar}
                            label="Edema"
                            onChange={handleOptEdemaEditar}
                            disabled={
                              optAsistEditar === "No" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Edema...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                            <MenuItem value={"Aumentó"}>Aumentó</MenuItem>
                            <MenuItem value={"Disminuyó"}>Disminuyó</MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Peso (Kg)"
                          variant="outlined"
                          value={pesoBenefEditar}
                          onChange={(event) =>
                            setPesoBenefEditar(event.target.value)
                          }
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Talla (cm)"
                          variant="outlined"
                          value={tallaBenefEditar}
                          onChange={(event) =>
                            setTallaBenefEditar(event.target.value)
                          }
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Puntaje Z"
                          variant="outlined"
                          value={puntajeZEditar}
                          onChange={handlePuntajeZEditar}
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación Nutricional"
                          variant="outlined"
                          value={diagPZEditar}
                          onChange={(event) =>
                            setDiagPZEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Circ. de Brazo (CMB)"
                          variant="outlined"
                          value={pbEditar}
                          onChange={handlePbEditar}
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación PB"
                          variant="outlined"
                          value={diagPBEditar}
                          onChange={(event) =>
                            setDiagPBEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Diagnóstico"
                          variant="outlined"
                          value={diagFinalEditar}
                          onChange={(event) =>
                            setDiagFinalEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Estatus
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={estatusM5Editar}
                            label="Estatus"
                            onChange={handleEstatusM5Editar}
                            disabled={
                              optAsistEditar === "No" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Estatus...</em>
                            </MenuItem>
                            <MenuItem value={0}>En Control</MenuItem>
                            <MenuItem value={1}>Desertor</MenuItem>
                            <MenuItem value={2}>Referido</MenuItem>
                            <MenuItem value={3}>Alta Nutricional</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Observaciones"
                          variant="outlined"
                          value={observacionesM5Editar}
                          onChange={(event) =>
                            setObservacionesM5Editar(event.target.value)
                          }
                          disabled={optAsistEditar === ""}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 2 }} />
                    <Grid
                      container
                      sx={{ mt: 1 }}
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={handleCloseEditM5}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={handleEliminarControlM5}
                        >
                          Eliminar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{ bgcolor: "teal", color: "white" }}
                          onClick={handleEditarControlM5}
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsEditM5} index={7}>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mb={isSmallScreen ? 1 : 3}
                        mt={1}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            ¿Asistió?
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optAsistEditar}
                            label="¿Asistió?"
                            onChange={handleOptAsistEditar}
                          >
                            <MenuItem value="">
                              <em>Seleccionar Asistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} mt={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="es"
                        >
                          <DesktopDatePicker
                            label="Fecha de Evaluacion"
                            name="fechaEvaluacion"
                            inputFormat="DD/MM/YYYY"
                            value={fechaEvaluacionEditar}
                            onChange={setFechaEvaluacionEditar}
                            renderInput={(params) => {
                              return <TextField fullWidth {...params} />;
                            }}
                            clearable
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mt={isSmallScreen ? 0 : 1}
                        hidden={isSmallScreen}
                      >
                        <FormControl fullWidth>
                          <InputLabel
                            id="demo-simple-select-label"
                            hidden={
                              optAsistEditar === "Si" || optAsistEditar === ""
                            }
                          >
                            Razón de Inasistencia
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optRazonEditar}
                            label="Razón de Inasistencia"
                            onChange={handleOptRazonEditar}
                            hidden={
                              optAsistEditar === "Si" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Razón de Inasistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Problemas de comunicación"}>
                              Problemas de comunicación
                            </MenuItem>
                            <MenuItem value={"Niño ausente en la comunidad"}>
                              Niño ausente en la comunidad
                            </MenuItem>
                            <MenuItem value={"Promotor Ausente"}>
                              Promotor Ausente
                            </MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Edema
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optEdemaEditar}
                            label="Edema"
                            onChange={handleOptEdemaEditar}
                            disabled={
                              optAsistEditar === "No" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Edema...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                            <MenuItem value={"Aumentó"}>Aumentó</MenuItem>
                            <MenuItem value={"Disminuyó"}>Disminuyó</MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Peso (Kg)"
                          variant="outlined"
                          value={pesoBenefEditar}
                          onChange={(event) =>
                            setPesoBenefEditar(event.target.value)
                          }
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Talla (cm)"
                          variant="outlined"
                          value={tallaBenefEditar}
                          onChange={(event) =>
                            setTallaBenefEditar(event.target.value)
                          }
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Puntaje Z"
                          variant="outlined"
                          value={puntajeZEditar}
                          onChange={handlePuntajeZEditar}
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación Nutricional"
                          variant="outlined"
                          value={diagPZEditar}
                          onChange={(event) =>
                            setDiagPZEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Circ. de Brazo (CMB)"
                          variant="outlined"
                          value={pbEditar}
                          onChange={handlePbEditar}
                          disabled={
                            optAsistEditar === "No" || optAsistEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación PB"
                          variant="outlined"
                          value={diagPBEditar}
                          onChange={(event) =>
                            setDiagPBEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Diagnóstico"
                          variant="outlined"
                          value={diagFinalEditar}
                          onChange={(event) =>
                            setDiagFinalEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Estatus
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={estatusM5Editar}
                            label="Estatus"
                            onChange={handleEstatusM5Editar}
                            disabled={
                              optAsistEditar === "No" || optAsistEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Estatus...</em>
                            </MenuItem>
                            <MenuItem value={0}>En Control</MenuItem>
                            <MenuItem value={1}>Desertor</MenuItem>
                            <MenuItem value={2}>Referido</MenuItem>
                            <MenuItem value={3}>Alta Nutricional</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Observaciones"
                          variant="outlined"
                          value={observacionesM5Editar}
                          onChange={(event) =>
                            setObservacionesM5(event.target.value)
                          }
                          disabled={optAsistEditar === ""}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 2 }} />
                    <Grid
                      container
                      sx={{ mt: 1 }}
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={handleCloseEditM5}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={handleEliminarControlM5}
                        >
                          Eliminar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{ bgcolor: "teal", color: "white" }}
                          onClick={handleEditarControlM5}
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                </Box>
              </Box>
            </Modal>
            {/* //////////////////////////// MODAL EDITAR MICRONUTRIENTES //////////////////////////// */}
            <Modal
              open={openEditMicro}
              onClose={handleCloseEditMicro}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography variant="h4">
                  <b>
                    {" "}
                    <EditIcon sx={{ fontSize: 25 }} /> Editar Seguimiento:
                  </b>{" "}
                  {datoIngreso.nombre} {datoIngreso.apellido}
                </Typography>
                <Divider sx={{ mt: 2, mb: 3 }} />
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={tabsEditMicro}
                      onChange={handleTabsEditMicro}
                      variant={isSmallScreen ? "scrollable" : "fullWidth"}
                      scrollButtons
                      allowScrollButtonsMobile
                      aria-label="basic tabs example"
                    >
                      <Tab
                        icon={<Filter1OutlinedIcon />}
                        label="seguimiento I"
                        {...a11yProps(0)}
                        hidden={seguimientos.length < 1}
                      />
                      <Tab
                        icon={<Filter2OutlinedIcon />}
                        label="seguimiento II"
                        {...a11yProps(1)}
                        hidden={seguimientos.length < 2}
                      />
                      <Tab
                        icon={<Filter3OutlinedIcon />}
                        label="seguimiento III"
                        {...a11yProps(2)}
                        hidden={seguimientos.length < 3}
                      />
                      <Tab
                        icon={<Filter4OutlinedIcon />}
                        label="seguimiento IV"
                        {...a11yProps(3)}
                        hidden={seguimientos.length < 4}
                      />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={tabsEditMicro} index={0}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mb={isSmallScreen ? 1 : 3}
                        mt={1}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Seguimiento
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optSegEditar}
                            label="Seguimiento"
                            onChange={handleOptSegEditar}
                          >
                            <MenuItem value="">
                              <em>Seleccionar Seguimiento...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} mt={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="es"
                        >
                          <DesktopDatePicker
                            label="Fecha de Evaluacion"
                            name="fechaEvaluacion"
                            inputFormat="DD/MM/YYYY"
                            value={fechaEvalMicroEditar}
                            onChange={setFechaEvalMicroEditar}
                            renderInput={(params) => {
                              return <TextField fullWidth {...params} />;
                            }}
                            clearable
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mt={1}
                        hidden={
                          (isSmallScreen && optSegEditar === "Si") ||
                          (isSmallScreen && optSegEditar === "")
                        }
                      >
                        <FormControl fullWidth>
                          <InputLabel
                            id="demo-simple-select-label"
                            hidden={
                              optSegEditar === "Si" || optSegEditar === ""
                            }
                          >
                            Motivo
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optMotivoEditar}
                            label="Motivo"
                            onChange={handleOptMotivo}
                            hidden={
                              optSegEditar === "Si" || optSegEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Motivo...</em>
                            </MenuItem>
                            <MenuItem value={"Sin Número"}>Sin Número</MenuItem>
                            <MenuItem value={"No Contestó"}>
                              No Contestó
                            </MenuItem>
                            <MenuItem value={"Número Equivocado"}>
                              Número Equivocado
                            </MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        /*sm={
                          optEsquemaEditar === "" ||
                          optEsquemaEditar === "Si" ||
                          optSegEditar === "No"
                            ? 4
                            : 6
                        }*/
                      >
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            ¿Completó el esquema?
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optEsquemaEditar}
                            label="¿Completó el esquema?"
                            onChange={handleOptEsquemaEditar}
                            disabled={
                              optSegEditar === "No" || optSegEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Esquema...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        hidden={
                          optEsquemaEditar === "" ||
                          optEsquemaEditar === "Si" ||
                          optSegEditar === "No"
                        }
                      >
                        <TextField
                          id="outlined-basic"
                          label="Motivo"
                          variant="outlined"
                          value={motivoEsquemaEditar}
                          onChange={(event) =>
                            setMotivoEsquemaEditar(event.target.value)
                          }
                          disabled={
                            optSegEditar === "No" ||
                            optSegEditar === "" ||
                            optEsquemaEditar === "Si" ||
                            optEsquemaEditar === ""
                          }
                          hidden={
                            optEsquemaEditar === "" ||
                            optEsquemaEditar === "Si" ||
                            optSegEditar === "No"
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        /*sm={
                          optEsquemaEditar === "" ||
                          optEsquemaEditar === "Si" ||
                          optSegEditar === "No"
                            ? 4
                            : 6
                        }*/
                      >
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Consejería
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={consejeriaEditar}
                            label="Consejería"
                            onChange={handleConsejeriaEditar}
                            disabled={
                              optSegEditar === "No" || optSegEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Consejería...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Estatus
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={estatusMicroEditar}
                            label="Edema"
                            onChange={handleEstatusMicroEditar}
                            disabled={optSegEditar === ""}
                          >
                            <MenuItem value="">
                              <em>Seleccionar Estatus...</em>
                            </MenuItem>
                            <MenuItem value={0}>En Control</MenuItem>
                            <MenuItem value={1}>Desertor</MenuItem>
                            <MenuItem value={2}>Referido</MenuItem>
                            <MenuItem value={3}>Alta Nutricional</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        /*sm={
                          optEsquemaEditar === "" ||
                          optEsquemaEditar === "Si" ||
                          optSegEditar === "No"
                            ? 4
                            : 6
                        }*/
                      >
                        <TextField
                          id="outlined-basic"
                          label="Observaciones"
                          variant="outlined"
                          value={observacionesMNEditar}
                          onChange={(event) =>
                            setObservacionesMNEditar(event.target.value)
                          }
                          disabled={optSegEditar === ""}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 2 }} />
                    <Grid
                      container
                      sx={{ mt: 1 }}
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={handleCloseEditMicro}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={handleEliminarControlMicro}
                        >
                          Eliminar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{ bgcolor: "teal", color: "white" }}
                          onClick={handleEditarControlMicro}
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsEditMicro} index={1}>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mb={isSmallScreen ? 1 : 3}
                        mt={1}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Seguimiento
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optSegEditar}
                            label="Seguimiento"
                            onChange={handleOptSegEditar}
                          >
                            <MenuItem value="">
                              <em>Seleccionar Seguimiento...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} mt={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="es"
                        >
                          <DesktopDatePicker
                            label="Fecha de Evaluacion"
                            name="fechaEvaluacion"
                            inputFormat="DD/MM/YYYY"
                            value={fechaEvalMicroEditar}
                            onChange={setFechaEvalMicroEditar}
                            renderInput={(params) => {
                              return <TextField fullWidth {...params} />;
                            }}
                            clearable
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mt={1}
                        hidden={
                          (isSmallScreen && optSegEditar === "Si") ||
                          (isSmallScreen && optSegEditar === "")
                        }
                      >
                        <FormControl fullWidth>
                          <InputLabel
                            id="demo-simple-select-label"
                            hidden={
                              optSegEditar === "Si" || optSegEditar === ""
                            }
                          >
                            Motivo
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optMotivoEditar}
                            label="Motivo"
                            onChange={handleOptMotivoEditar}
                            hidden={
                              optSegEditar === "Si" || optSegEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Motivo...</em>
                            </MenuItem>
                            <MenuItem value={"Sin Número"}>Sin Número</MenuItem>
                            <MenuItem value={"No Contestó"}>
                              No Contestó
                            </MenuItem>
                            <MenuItem value={"Número Equivocado"}>
                              Número Equivocado
                            </MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            ¿Completó el esquema?
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optEsquemaEditar}
                            label="¿Completó el esquema?"
                            onChange={handleOptEsquemaEditar}
                            disabled={
                              optSegEditar === "No" || optSegEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Esquema...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        hidden={
                          optEsquemaEditar === "" ||
                          optEsquemaEditar === "Si" ||
                          optSegEditar === "No"
                        }
                      >
                        <TextField
                          id="outlined-basic"
                          label="Motivo"
                          variant="outlined"
                          value={motivoEsquemaEditar}
                          onChange={(event) =>
                            setMotivoEsquemaEditar(event.target.value)
                          }
                          disabled={
                            optSegEditar === "No" ||
                            optSegEditar === "" ||
                            optEsquemaEditar === "Si" ||
                            optEsquemaEditar === ""
                          }
                          hidden={
                            optEsquemaEditar === "" ||
                            optEsquemaEditar === "Si" ||
                            optSegEditar === "No"
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Consejería
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={consejeriaEditar}
                            label="Consejería"
                            onChange={handleConsejeriaEditar}
                            disabled={
                              optSegEditar === "No" || optSegEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Consejería...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Estatus
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={estatusMicroEditar}
                            label="Edema"
                            onChange={handleEstatusMicroEditar}
                            disabled={optSegEditar === ""}
                          >
                            <MenuItem value="">
                              <em>Seleccionar Estatus...</em>
                            </MenuItem>
                            <MenuItem value={0}>En Control</MenuItem>
                            <MenuItem value={1}>Desertor</MenuItem>
                            <MenuItem value={2}>Referido</MenuItem>
                            <MenuItem value={3}>Alta Nutricional</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={
                          optEsquemaEditar === "" ||
                          optEsquemaEditar === "Si" ||
                          optSegEditar === "No"
                            ? 4
                            : 6
                        }
                      >
                        <TextField
                          id="outlined-basic"
                          label="Observaciones"
                          variant="outlined"
                          value={observacionesMNEditar}
                          onChange={(event) =>
                            setObservacionesMNEditar(event.target.value)
                          }
                          disabled={optSegEditar === ""}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 2 }} />
                    <Grid
                      container
                      sx={{ mt: 1 }}
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={handleCloseEditMicro}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={handleEliminarControlMicro}
                        >
                          Eliminar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{ bgcolor: "teal", color: "white" }}
                          onClick={handleEditarControlMicro}
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsEditMicro} index={2}>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mb={isSmallScreen ? 1 : 3}
                        mt={1}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Seguimiento
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optSegEditar}
                            label="Seguimiento"
                            onChange={handleOptSegEditar}
                          >
                            <MenuItem value="">
                              <em>Seleccionar Seguimiento...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} mt={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="es"
                        >
                          <DesktopDatePicker
                            label="Fecha de Evaluacion"
                            name="fechaEvaluacion"
                            inputFormat="DD/MM/YYYY"
                            value={fechaEvalMicroEditar}
                            onChange={setFechaEvalMicroEditar}
                            renderInput={(params) => {
                              return <TextField fullWidth {...params} />;
                            }}
                            clearable
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mt={1}
                        hidden={
                          (isSmallScreen && optSegEditar === "Si") ||
                          (isSmallScreen && optSegEditar === "")
                        }
                      >
                        <FormControl fullWidth>
                          <InputLabel
                            id="demo-simple-select-label"
                            hidden={
                              optSegEditar === "Si" || optSegEditar === ""
                            }
                          >
                            Motivo
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optMotivoEditar}
                            label="Motivo"
                            onChange={handleOptMotivo}
                            hidden={
                              optSegEditar === "Si" || optSegEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Motivo...</em>
                            </MenuItem>
                            <MenuItem value={"Sin Número"}>Sin Número</MenuItem>
                            <MenuItem value={"No Contestó"}>
                              No Contestó
                            </MenuItem>
                            <MenuItem value={"Número Equivocado"}>
                              Número Equivocado
                            </MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            ¿Completó el esquema?
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optEsquemaEditar}
                            label="¿Completó el esquema?"
                            onChange={handleOptEsquemaEditar}
                            disabled={
                              optSegEditar === "No" || optSegEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Esquema...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        hidden={
                          optEsquemaEditar === "" ||
                          optEsquemaEditar === "Si" ||
                          optSegEditar === "No"
                        }
                      >
                        <TextField
                          id="outlined-basic"
                          label="Motivo"
                          variant="outlined"
                          value={motivoEsquemaEditar}
                          onChange={(event) =>
                            setMotivoEsquemaEditar(event.target.value)
                          }
                          disabled={
                            optSegEditar === "No" ||
                            optSegEditar === "" ||
                            optEsquemaEditar === "Si" ||
                            optEsquemaEditar === ""
                          }
                          hidden={
                            optEsquemaEditar === "" ||
                            optEsquemaEditar === "Si" ||
                            optSegEditar === "No"
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Consejería
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={consejeriaEditar}
                            label="Consejería"
                            onChange={handleConsejeriaEditar}
                            disabled={
                              optSegEditar === "No" || optSegEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Consejería...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Estatus
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={estatusMicroEditar}
                            label="Edema"
                            onChange={handleEstatusMicroEditar}
                            disabled={optSegEditar === ""}
                          >
                            <MenuItem value="">
                              <em>Seleccionar Estatus...</em>
                            </MenuItem>
                            <MenuItem value={0}>En Control</MenuItem>
                            <MenuItem value={1}>Desertor</MenuItem>
                            <MenuItem value={2}>Referido</MenuItem>
                            <MenuItem value={3}>Alta Nutricional</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Observaciones"
                          variant="outlined"
                          value={observacionesMNEditar}
                          onChange={(event) =>
                            setObservacionesMNEditar(event.target.value)
                          }
                          disabled={optSegEditar === ""}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 2 }} />
                    <Grid
                      container
                      sx={{ mt: 1 }}
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={handleCloseEditMicro}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={handleCloseEditMicro}
                        >
                          Eliminar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{ bgcolor: "teal", color: "white" }}
                          onClick={handleEditarControlMicro}
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsEditMicro} index={3}>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mb={isSmallScreen ? 1 : 3}
                        mt={1}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Seguimiento
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optSegEditar}
                            label="Seguimiento"
                            onChange={handleOptSegEditar}
                          >
                            <MenuItem value="">
                              <em>Seleccionar Seguimiento...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} mt={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="es"
                        >
                          <DesktopDatePicker
                            label="Fecha de Evaluacion"
                            name="fechaEvaluacion"
                            inputFormat="DD/MM/YYYY"
                            value={fechaEvalMicroEditar}
                            onChange={setFechaEvalMicroEditar}
                            renderInput={(params) => {
                              return <TextField fullWidth {...params} />;
                            }}
                            clearable
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mt={1}
                        hidden={
                          (isSmallScreen && optSegEditar === "Si") ||
                          (isSmallScreen && optSegEditar === "")
                        }
                      >
                        <FormControl fullWidth>
                          <InputLabel
                            id="demo-simple-select-label"
                            hidden={
                              optSegEditar === "Si" || optSegEditar === ""
                            }
                          >
                            Motivo
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optMotivoEditar}
                            label="Motivo"
                            onChange={handleOptMotivo}
                            hidden={
                              optSegEditar === "Si" || optSegEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Motivo...</em>
                            </MenuItem>
                            <MenuItem value={"Sin Número"}>Sin Número</MenuItem>
                            <MenuItem value={"No Contestó"}>
                              No Contestó
                            </MenuItem>
                            <MenuItem value={"Número Equivocado"}>
                              Número Equivocado
                            </MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            ¿Completó el esquema?
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optEsquemaEditar}
                            label="¿Completó el esquema?"
                            onChange={handleOptEsquemaEditar}
                            disabled={
                              optSegEditar === "No" || optSegEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Esquema...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        hidden={
                          optEsquemaEditar === "" ||
                          optEsquemaEditar === "Si" ||
                          optSegEditar === "No"
                        }
                      >
                        <TextField
                          id="outlined-basic"
                          label="Motivo"
                          variant="outlined"
                          value={motivoEsquemaEditar}
                          onChange={(event) =>
                            setMotivoEsquemaEditar(event.target.value)
                          }
                          disabled={
                            optSegEditar === "No" ||
                            optSegEditar === "" ||
                            optEsquemaEditar === "Si" ||
                            optEsquemaEditar === ""
                          }
                          hidden={
                            optEsquemaEditar === "" ||
                            optEsquemaEditar === "Si" ||
                            optSegEditar === "No"
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Consejería
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={consejeriaEditar}
                            label="Consejería"
                            onChange={handleConsejeriaEditar}
                            disabled={
                              optSegEditar === "No" || optSegEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Consejería...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                            <MenuItem value={"S/D"}>S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Estatus
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={estatusMicroEditar}
                            label="Edema"
                            onChange={handleEstatusMicroEditar}
                            disabled={optSegEditar === ""}
                          >
                            <MenuItem value="">
                              <em>Seleccionar Estatus...</em>
                            </MenuItem>
                            <MenuItem value={0}>En Control</MenuItem>
                            <MenuItem value={1}>Desertor</MenuItem>
                            <MenuItem value={2}>Referido</MenuItem>
                            <MenuItem value={3}>Alta Nutricional</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Observaciones"
                          variant="outlined"
                          value={observacionesMNEditar}
                          onChange={(event) =>
                            setObservacionesMNEditar(event.target.value)
                          }
                          disabled={optSegEditar === ""}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 2 }} />
                    <Grid
                      container
                      sx={{ mt: 1 }}
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={handleCloseEditMicro}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={handleEliminarControlMicro}
                        >
                          Eliminar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{ bgcolor: "teal", color: "white" }}
                          onClick={handleEditarControlMicro}
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                </Box>
              </Box>
            </Modal>
            {/* /////////////////////////////////  MODAL EDITAR MEL  ////////////////////////////// */}
            <Modal
              open={openEditMel}
              onClose={handleCloseEditMel}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography variant="h4">
                  <b>
                    {" "}
                    <EditIcon sx={{ fontSize: 30 }} /> Editar Control:
                  </b>{" "}
                  {datoIngreso.nombre} {datoIngreso.apellido}
                </Typography>
                <Divider sx={{ mt: 2, mb: 3 }} />
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={tabsEditMel}
                      onChange={handleTabsEditMel}
                      variant={isSmallScreen ? "scrollable" : "fullWidth"}
                      scrollButtons
                      allowScrollButtonsMobile
                      aria-label="basic tabs example"
                    >
                      <Tab
                        icon={<Filter1OutlinedIcon />}
                        label="Control I"
                        {...a11yProps(0)}
                        hidden={seguimientos.length < 1}
                      />
                      <Tab
                        icon={<Filter2OutlinedIcon />}
                        label="Control II"
                        {...a11yProps(1)}
                        hidden={seguimientos.length < 2}
                      />
                      <Tab
                        icon={<Filter3OutlinedIcon />}
                        label="Control III"
                        {...a11yProps(2)}
                        hidden={seguimientos.length < 3}
                      />
                      <Tab
                        icon={<Filter4OutlinedIcon />}
                        label="Control IV"
                        {...a11yProps(3)}
                        hidden={seguimientos.length < 4}
                      />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={tabsEditMel} index={0}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mb={isSmallScreen ? 1 : 3}
                        mt={1}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            ¿Asistió?
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optAsistMelEditar}
                            label="¿Asistió?"
                            onChange={handleOptAsistMelEditar}
                          >
                            <MenuItem value="">
                              <em>Seleccionar Asistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} mt={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="es"
                        >
                          <DesktopDatePicker
                            label="Fecha de Evaluacion"
                            name="fechaEvaluacion"
                            inputFormat="DD/MM/YYYY"
                            value={fechaEvalMelEditar}
                            onChange={setFechaEvalMelEditar}
                            renderInput={(params) => {
                              return <TextField fullWidth {...params} />;
                            }}
                            clearable
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={4} mt={1} hidden={isSmallScreen}>
                        <FormControl fullWidth>
                          <InputLabel
                            id="demo-simple-select-label"
                            hidden={
                              optAsistMelEditar === "Si" ||
                              optAsistMelEditar === ""
                            }
                          >
                            Razón de Inasistencia
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optRazonMelEditar}
                            label="Razón de Inasistencia"
                            onChange={handleOptRazonMelEditar}
                            hidden={
                              optAsistMelEditar === "Si" ||
                              optAsistMelEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Razón de Inasistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Problemas de comunicación"}>
                              Problemas de comunicación
                            </MenuItem>
                            <MenuItem value={"Niño ausente en la comunidad"}>
                              Paciente ausente en la comunidad
                            </MenuItem>
                            <MenuItem value={"Promotor Ausente"}>
                              Promotor Ausente
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Semanas de Gestación
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={semGestEditar}
                            label="Semanas de Gestación"
                            onChange={handleSemGestEditar}
                            disabled={
                              optAsistMelEditar === "No" ||
                              optAsistMelEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Semana de Gestación...</em>
                            </MenuItem>
                            <MenuItem value="6">6</MenuItem>
                            <MenuItem value="7">7</MenuItem>
                            <MenuItem value="8">8</MenuItem>
                            <MenuItem value="9">9</MenuItem>
                            <MenuItem value="10">10</MenuItem>
                            <MenuItem value="11">11</MenuItem>
                            <MenuItem value="12">12</MenuItem>
                            <MenuItem value="13">13</MenuItem>
                            <MenuItem value="14">14</MenuItem>
                            <MenuItem value="15">15</MenuItem>
                            <MenuItem value="16">16</MenuItem>
                            <MenuItem value="17">17</MenuItem>
                            <MenuItem value="18">18</MenuItem>
                            <MenuItem value="19">19</MenuItem>
                            <MenuItem value="20">20</MenuItem>
                            <MenuItem value="20">21</MenuItem>
                            <MenuItem value="22">22</MenuItem>
                            <MenuItem value="23">23</MenuItem>
                            <MenuItem value="24">24</MenuItem>
                            <MenuItem value="25">25</MenuItem>
                            <MenuItem value="26">26</MenuItem>
                            <MenuItem value="27">27</MenuItem>
                            <MenuItem value="28">28</MenuItem>
                            <MenuItem value="29">29</MenuItem>
                            <MenuItem value="30">30</MenuItem>
                            <MenuItem value="31">31</MenuItem>
                            <MenuItem value="32">32</MenuItem>
                            <MenuItem value="33">33</MenuItem>
                            <MenuItem value="34">34</MenuItem>
                            <MenuItem value="35">35</MenuItem>
                            <MenuItem value="36">36</MenuItem>
                            <MenuItem value="37">37</MenuItem>
                            <MenuItem value="38">38</MenuItem>
                            <MenuItem value="39">39</MenuItem>
                            <MenuItem value="40">40</MenuItem>
                            <MenuItem value="41">41</MenuItem>
                            <MenuItem value="42">42</MenuItem>
                            <MenuItem value="S/D">S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Peso (Kg)"
                          variant="outlined"
                          value={pesoMelEditar}
                          onChange={(event) =>
                            setPesoMelEditar(event.target.value)
                          }
                          disabled={
                            optAsistMelEditar === "No" ||
                            optAsistMelEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Talla (cm)"
                          variant="outlined"
                          value={tallaMelEditar}
                          onChange={(event) =>
                            setTallaMelEditar(event.target.value)
                          }
                          disabled={
                            optAsistMelEditar === "No" ||
                            optAsistMelEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="IMC"
                          variant="outlined"
                          value={imcEditar}
                          onChange={handleImcEditar}
                          disabled={
                            optAsistMelEditar === "No" ||
                            optAsistMelEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación IMC"
                          variant="outlined"
                          value={diagImcEditar}
                          onChange={(event) =>
                            setDiagImcEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Circ. de Brazo (CMB)"
                          variant="outlined"
                          value={pbMelEditar}
                          onChange={handlePbMelEditar}
                          disabled={
                            optAsistMelEditar === "No" ||
                            optAsistMelEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación PB"
                          variant="outlined"
                          value={diagPbMelEditar}
                          onChange={(event) =>
                            setDiagPbMelEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Diagnóstico"
                          variant="outlined"
                          value={diagFinalMelEditar}
                          onChange={(event) =>
                            setDiagFinalMelEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Diagnóstico por Semanas de Gestación"
                          variant="outlined"
                          value={diagSemGestMelEditar}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Estatus
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={estatusMelEditar}
                            label="Estatus"
                            onChange={handleEstatusMelEditar}
                            disabled={
                              optAsistMelEditar === "No" ||
                              optAsistMelEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Estatus...</em>
                            </MenuItem>
                            <MenuItem value={0}>En Control</MenuItem>
                            <MenuItem value={1}>Desertor</MenuItem>
                            <MenuItem value={2}>Referido</MenuItem>
                            <MenuItem value={3}>Alta Nutricional</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Observaciones"
                          variant="outlined"
                          value={observacionesMelEditar}
                          onChange={(event) =>
                            setObservacionesMelEditar(event.target.value)
                          }
                          disabled={optAsistMelEditar === ""}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 2 }} />
                    <Grid
                      container
                      sx={{ mt: 1 }}
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={handleCloseEditMel}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={handleEliminarControlMel}
                        >
                          Eliminar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{ bgcolor: "teal", color: "white" }}
                          onClick={handleEditarControlMel}
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsEditMel} index={1}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mb={isSmallScreen ? 1 : 3}
                        mt={1}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            ¿Asistió?
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optAsistMelEditar}
                            label="¿Asistió?"
                            onChange={handleOptAsistMelEditar}
                          >
                            <MenuItem value="">
                              <em>Seleccionar Asistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} mt={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="es"
                        >
                          <DesktopDatePicker
                            label="Fecha de Evaluacion"
                            name="fechaEvaluacion"
                            inputFormat="DD/MM/YYYY"
                            value={fechaEvalMelEditar}
                            onChange={setFechaEvalMelEditar}
                            renderInput={(params) => {
                              return <TextField fullWidth {...params} />;
                            }}
                            clearable
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={4} mt={1} hidden={isSmallScreen}>
                        <FormControl fullWidth>
                          <InputLabel
                            id="demo-simple-select-label"
                            hidden={
                              optAsistMelEditar === "Si" ||
                              optAsistMelEditar === ""
                            }
                          >
                            Razón de Inasistencia
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optRazonMelEditar}
                            label="Razón de Inasistencia"
                            onChange={handleOptRazonMelEditar}
                            hidden={
                              optAsistMelEditar === "Si" ||
                              optAsistMelEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Razón de Inasistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Problemas de comunicación"}>
                              Problemas de comunicación
                            </MenuItem>
                            <MenuItem value={"Niño ausente en la comunidad"}>
                              Paciente ausente en la comunidad
                            </MenuItem>
                            <MenuItem value={"Promotor Ausente"}>
                              Promotor Ausente
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Semanas de Gestación
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={semGestEditar}
                            label="Semanas de Gestación"
                            onChange={handleSemGestEditar}
                            disabled={
                              optAsistMelEditar === "No" ||
                              optAsistMelEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Semana de Gestación...</em>
                            </MenuItem>
                            <MenuItem value="6">6</MenuItem>
                            <MenuItem value="7">7</MenuItem>
                            <MenuItem value="8">8</MenuItem>
                            <MenuItem value="9">9</MenuItem>
                            <MenuItem value="10">10</MenuItem>
                            <MenuItem value="11">11</MenuItem>
                            <MenuItem value="12">12</MenuItem>
                            <MenuItem value="13">13</MenuItem>
                            <MenuItem value="14">14</MenuItem>
                            <MenuItem value="15">15</MenuItem>
                            <MenuItem value="16">16</MenuItem>
                            <MenuItem value="17">17</MenuItem>
                            <MenuItem value="18">18</MenuItem>
                            <MenuItem value="19">19</MenuItem>
                            <MenuItem value="20">20</MenuItem>
                            <MenuItem value="20">21</MenuItem>
                            <MenuItem value="22">22</MenuItem>
                            <MenuItem value="23">23</MenuItem>
                            <MenuItem value="24">24</MenuItem>
                            <MenuItem value="25">25</MenuItem>
                            <MenuItem value="26">26</MenuItem>
                            <MenuItem value="27">27</MenuItem>
                            <MenuItem value="28">28</MenuItem>
                            <MenuItem value="29">29</MenuItem>
                            <MenuItem value="30">30</MenuItem>
                            <MenuItem value="31">31</MenuItem>
                            <MenuItem value="32">32</MenuItem>
                            <MenuItem value="33">33</MenuItem>
                            <MenuItem value="34">34</MenuItem>
                            <MenuItem value="35">35</MenuItem>
                            <MenuItem value="36">36</MenuItem>
                            <MenuItem value="37">37</MenuItem>
                            <MenuItem value="38">38</MenuItem>
                            <MenuItem value="39">39</MenuItem>
                            <MenuItem value="40">40</MenuItem>
                            <MenuItem value="41">41</MenuItem>
                            <MenuItem value="42">42</MenuItem>
                            <MenuItem value="S/D">S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Peso (Kg)"
                          variant="outlined"
                          value={pesoMelEditar}
                          onChange={(event) =>
                            setPesoMelEditar(event.target.value)
                          }
                          disabled={
                            optAsistMelEditar === "No" ||
                            optAsistMelEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Talla (cm)"
                          variant="outlined"
                          value={tallaMelEditar}
                          onChange={(event) =>
                            setTallaMelEditar(event.target.value)
                          }
                          disabled={
                            optAsistMelEditar === "No" ||
                            optAsistMelEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="IMC"
                          variant="outlined"
                          value={imcEditar}
                          onChange={handleImcEditar}
                          disabled={
                            optAsistMelEditar === "No" ||
                            optAsistMelEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación IMC"
                          variant="outlined"
                          value={diagImcEditar}
                          onChange={(event) =>
                            setDiagImcEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Circ. de Brazo (CMB)"
                          variant="outlined"
                          value={pbMelEditar}
                          onChange={handlePbMelEditar}
                          disabled={
                            optAsistMelEditar === "No" ||
                            optAsistMelEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación PB"
                          variant="outlined"
                          value={diagPbMelEditar}
                          onChange={(event) =>
                            setDiagPbMelEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Diagnóstico"
                          variant="outlined"
                          value={diagFinalMelEditar}
                          onChange={(event) =>
                            setDiagFinalMelEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Diagnóstico por Semanas de Gestación"
                          variant="outlined"
                          value={diagSemGestMelEditar}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Estatus
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={estatusMelEditar}
                            label="Estatus"
                            onChange={handleEstatusMelEditar}
                            disabled={
                              optAsistMelEditar === "No" ||
                              optAsistMelEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Estatus...</em>
                            </MenuItem>
                            <MenuItem value={0}>En Control</MenuItem>
                            <MenuItem value={1}>Desertor</MenuItem>
                            <MenuItem value={2}>Referido</MenuItem>
                            <MenuItem value={3}>Alta Nutricional</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Observaciones"
                          variant="outlined"
                          value={observacionesMelEditar}
                          onChange={(event) =>
                            setObservacionesMelEditar(event.target.value)
                          }
                          disabled={optAsistMelEditar === ""}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 2 }} />
                    <Grid
                      container
                      sx={{ mt: 1 }}
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={handleCloseEditMel}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={handleEliminarControlMel}
                        >
                          Eliminar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{ bgcolor: "teal", color: "white" }}
                          onClick={handleEditarControlMel}
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsEditMel} index={3}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mb={isSmallScreen ? 1 : 3}
                        mt={1}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            ¿Asistió?
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optAsistMelEditar}
                            label="¿Asistió?"
                            onChange={handleOptAsistMelEditar}
                          >
                            <MenuItem value="">
                              <em>Seleccionar Asistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} mt={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="es"
                        >
                          <DesktopDatePicker
                            label="Fecha de Evaluacion"
                            name="fechaEvaluacion"
                            inputFormat="DD/MM/YYYY"
                            value={fechaEvalMelEditar}
                            onChange={setFechaEvalMelEditar}
                            renderInput={(params) => {
                              return <TextField fullWidth {...params} />;
                            }}
                            clearable
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={4} mt={1} hidden={isSmallScreen}>
                        <FormControl fullWidth>
                          <InputLabel
                            id="demo-simple-select-label"
                            hidden={
                              optAsistMelEditar === "Si" ||
                              optAsistMelEditar === ""
                            }
                          >
                            Razón de Inasistencia
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optRazonMelEditar}
                            label="Razón de Inasistencia"
                            onChange={handleOptRazonMelEditar}
                            hidden={
                              optAsistMelEditar === "Si" ||
                              optAsistMelEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Razón de Inasistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Problemas de comunicación"}>
                              Problemas de comunicación
                            </MenuItem>
                            <MenuItem value={"Niño ausente en la comunidad"}>
                              Paciente ausente en la comunidad
                            </MenuItem>
                            <MenuItem value={"Promotor Ausente"}>
                              Promotor Ausente
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Semanas de Gestación
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={semGestEditar}
                            label="Semanas de Gestación"
                            onChange={handleSemGestEditar}
                            disabled={
                              optAsistMelEditar === "No" ||
                              optAsistMelEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Semana de Gestación...</em>
                            </MenuItem>
                            <MenuItem value="6">6</MenuItem>
                            <MenuItem value="7">7</MenuItem>
                            <MenuItem value="8">8</MenuItem>
                            <MenuItem value="9">9</MenuItem>
                            <MenuItem value="10">10</MenuItem>
                            <MenuItem value="11">11</MenuItem>
                            <MenuItem value="12">12</MenuItem>
                            <MenuItem value="13">13</MenuItem>
                            <MenuItem value="14">14</MenuItem>
                            <MenuItem value="15">15</MenuItem>
                            <MenuItem value="16">16</MenuItem>
                            <MenuItem value="17">17</MenuItem>
                            <MenuItem value="18">18</MenuItem>
                            <MenuItem value="19">19</MenuItem>
                            <MenuItem value="20">20</MenuItem>
                            <MenuItem value="20">21</MenuItem>
                            <MenuItem value="22">22</MenuItem>
                            <MenuItem value="23">23</MenuItem>
                            <MenuItem value="24">24</MenuItem>
                            <MenuItem value="25">25</MenuItem>
                            <MenuItem value="26">26</MenuItem>
                            <MenuItem value="27">27</MenuItem>
                            <MenuItem value="28">28</MenuItem>
                            <MenuItem value="29">29</MenuItem>
                            <MenuItem value="30">30</MenuItem>
                            <MenuItem value="31">31</MenuItem>
                            <MenuItem value="32">32</MenuItem>
                            <MenuItem value="33">33</MenuItem>
                            <MenuItem value="34">34</MenuItem>
                            <MenuItem value="35">35</MenuItem>
                            <MenuItem value="36">36</MenuItem>
                            <MenuItem value="37">37</MenuItem>
                            <MenuItem value="38">38</MenuItem>
                            <MenuItem value="39">39</MenuItem>
                            <MenuItem value="40">40</MenuItem>
                            <MenuItem value="41">41</MenuItem>
                            <MenuItem value="42">42</MenuItem>
                            <MenuItem value="S/D">S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Peso (Kg)"
                          variant="outlined"
                          value={pesoMelEditar}
                          onChange={(event) =>
                            setPesoMelEditar(event.target.value)
                          }
                          disabled={
                            optAsistMelEditar === "No" ||
                            optAsistMelEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Talla (cm)"
                          variant="outlined"
                          value={tallaMelEditar}
                          onChange={(event) =>
                            setTallaMelEditar(event.target.value)
                          }
                          disabled={
                            optAsistMelEditar === "No" ||
                            optAsistMelEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="IMC"
                          variant="outlined"
                          value={imcEditar}
                          onChange={handleImcEditar}
                          disabled={
                            optAsistMelEditar === "No" ||
                            optAsistMelEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación IMC"
                          variant="outlined"
                          value={diagImcEditar}
                          onChange={(event) =>
                            setDiagImcEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Circ. de Brazo (CMB)"
                          variant="outlined"
                          value={pbMelEditar}
                          onChange={handlePbMelEditar}
                          disabled={
                            optAsistMelEditar === "No" ||
                            optAsistMelEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación PB"
                          variant="outlined"
                          value={diagPbMelEditar}
                          onChange={(event) =>
                            setDiagPbMelEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Diagnóstico"
                          variant="outlined"
                          value={diagFinalMelEditar}
                          onChange={(event) =>
                            setDiagFinalMelEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Diagnóstico por Semanas de Gestación"
                          variant="outlined"
                          value={diagSemGestMelEditar}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Estatus
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={estatusMelEditar}
                            label="Estatus"
                            onChange={handleEstatusMelEditar}
                            disabled={
                              optAsistMelEditar === "No" ||
                              optAsistMelEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Estatus...</em>
                            </MenuItem>
                            <MenuItem value={0}>En Control</MenuItem>
                            <MenuItem value={1}>Desertor</MenuItem>
                            <MenuItem value={2}>Referido</MenuItem>
                            <MenuItem value={3}>Alta Nutricional</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Observaciones"
                          variant="outlined"
                          value={observacionesMelEditar}
                          onChange={(event) =>
                            setObservacionesMelEditar(event.target.value)
                          }
                          disabled={optAsistMelEditar === ""}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 2 }} />
                    <Grid
                      container
                      sx={{ mt: 1 }}
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={handleCloseEditMel}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={handleCloseEditMel}
                        >
                          Eliminar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{ bgcolor: "teal", color: "white" }}
                          onClick={handleEditarControlMel}
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabsEditMel} index={2}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        mb={isSmallScreen ? 1 : 3}
                        mt={1}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            ¿Asistió?
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optAsistMelEditar}
                            label="¿Asistió?"
                            onChange={handleOptAsistMelEditar}
                          >
                            <MenuItem value="">
                              <em>Seleccionar Asistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Si"}>Si</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} mt={1}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="es"
                        >
                          <DesktopDatePicker
                            label="Fecha de Evaluacion"
                            name="fechaEvaluacion"
                            inputFormat="DD/MM/YYYY"
                            value={fechaEvalMelEditar}
                            onChange={setFechaEvalMelEditar}
                            renderInput={(params) => {
                              return <TextField fullWidth {...params} />;
                            }}
                            clearable
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={4} mt={1} hidden={isSmallScreen}>
                        <FormControl fullWidth>
                          <InputLabel
                            id="demo-simple-select-label"
                            hidden={
                              optAsistMelEditar === "Si" ||
                              optAsistMelEditar === ""
                            }
                          >
                            Razón de Inasistencia
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={optRazonMelEditar}
                            label="Razón de Inasistencia"
                            onChange={handleOptRazonMelEditar}
                            hidden={
                              optAsistMelEditar === "Si" ||
                              optAsistMelEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Razón de Inasistencia...</em>
                            </MenuItem>
                            <MenuItem value={"Problemas de comunicación"}>
                              Problemas de comunicación
                            </MenuItem>
                            <MenuItem value={"Niño ausente en la comunidad"}>
                              Paciente ausente en la comunidad
                            </MenuItem>
                            <MenuItem value={"Promotor Ausente"}>
                              Promotor Ausente
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Semanas de Gestación
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={semGestEditar}
                            label="Semanas de Gestación"
                            onChange={handleSemGestEditar}
                            disabled={
                              optAsistMelEditar === "No" ||
                              optAsistMelEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Semana de Gestación...</em>
                            </MenuItem>
                            <MenuItem value="6">6</MenuItem>
                            <MenuItem value="7">7</MenuItem>
                            <MenuItem value="8">8</MenuItem>
                            <MenuItem value="9">9</MenuItem>
                            <MenuItem value="10">10</MenuItem>
                            <MenuItem value="11">11</MenuItem>
                            <MenuItem value="12">12</MenuItem>
                            <MenuItem value="13">13</MenuItem>
                            <MenuItem value="14">14</MenuItem>
                            <MenuItem value="15">15</MenuItem>
                            <MenuItem value="16">16</MenuItem>
                            <MenuItem value="17">17</MenuItem>
                            <MenuItem value="18">18</MenuItem>
                            <MenuItem value="19">19</MenuItem>
                            <MenuItem value="20">20</MenuItem>
                            <MenuItem value="20">21</MenuItem>
                            <MenuItem value="22">22</MenuItem>
                            <MenuItem value="23">23</MenuItem>
                            <MenuItem value="24">24</MenuItem>
                            <MenuItem value="25">25</MenuItem>
                            <MenuItem value="26">26</MenuItem>
                            <MenuItem value="27">27</MenuItem>
                            <MenuItem value="28">28</MenuItem>
                            <MenuItem value="29">29</MenuItem>
                            <MenuItem value="30">30</MenuItem>
                            <MenuItem value="31">31</MenuItem>
                            <MenuItem value="32">32</MenuItem>
                            <MenuItem value="33">33</MenuItem>
                            <MenuItem value="34">34</MenuItem>
                            <MenuItem value="35">35</MenuItem>
                            <MenuItem value="36">36</MenuItem>
                            <MenuItem value="37">37</MenuItem>
                            <MenuItem value="38">38</MenuItem>
                            <MenuItem value="39">39</MenuItem>
                            <MenuItem value="40">40</MenuItem>
                            <MenuItem value="41">41</MenuItem>
                            <MenuItem value="42">42</MenuItem>
                            <MenuItem value="S/D">S/D</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Peso (Kg)"
                          variant="outlined"
                          value={pesoMelEditar}
                          onChange={(event) =>
                            setPesoMelEditar(event.target.value)
                          }
                          disabled={
                            optAsistMelEditar === "No" ||
                            optAsistMelEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          id="outlined-basic"
                          label="Talla (cm)"
                          variant="outlined"
                          value={tallaMelEditar}
                          onChange={(event) =>
                            setTallaMelEditar(event.target.value)
                          }
                          disabled={
                            optAsistMelEditar === "No" ||
                            optAsistMelEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="IMC"
                          variant="outlined"
                          value={imcEditar}
                          onChange={handleImcEditar}
                          disabled={
                            optAsistMelEditar === "No" ||
                            optAsistMelEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación IMC"
                          variant="outlined"
                          value={diagImcEditar}
                          onChange={(event) => setDiagImc(event.target.value)}
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Circ. de Brazo (CMB)"
                          variant="outlined"
                          value={pbMelEditar}
                          onChange={handlePbMelEditar}
                          disabled={
                            optAsistMelEditar === "No" ||
                            optAsistMelEditar === ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Clasificación PB"
                          variant="outlined"
                          value={diagPbMelEditar}
                          onChange={(event) =>
                            setDiagPbMelEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Diagnóstico"
                          variant="outlined"
                          value={diagFinalMelEditar}
                          onChange={(event) =>
                            setDiagFinalMelEditar(event.target.value)
                          }
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Diagnóstico por Semanas de Gestación"
                          value={diagSemGestMelEditar}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Estatus
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={estatusMelEditar}
                            label="Estatus"
                            onChange={handleEstatusMelEditar}
                            disabled={
                              optAsistMelEditar === "No" ||
                              optAsistMelEditar === ""
                            }
                          >
                            <MenuItem value="">
                              <em>Seleccionar Estatus...</em>
                            </MenuItem>
                            <MenuItem value={0}>En Control</MenuItem>
                            <MenuItem value={1}>Desertor</MenuItem>
                            <MenuItem value={2}>Referido</MenuItem>
                            <MenuItem value={3}>Alta Nutricional</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="outlined-basic"
                          label="Observaciones"
                          variant="outlined"
                          value={observacionesMelEditar}
                          onChange={(event) =>
                            setObservacionesMelEditar(event.target.value)
                          }
                          disabled={optAsistMelEditar === ""}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 2 }} />
                    <Grid
                      container
                      sx={{ mt: 1 }}
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={handleCloseEditMel}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={handleCloseEditMel}
                        >
                          Eliminar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{ bgcolor: "teal", color: "white" }}
                          onClick={handleEditarControlMel}
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </CustomTabPanel>
                </Box>
              </Box>
            </Modal>
          </Box>
        </>
      ) : (
        <Restricted />
      )}
    </Box>
  );
};

export default Beneficiarios;
