import React from "react";
import { styled } from "@mui/material/styles";
import { useState, useEffect } from "react";
import FlexBetween from "components/FlexBetween";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Divider,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import BreakdownChart from "components/BreakdownChart";
import Carousel from "components/Carousel";
import DashBoardCalendar from "components/Scheduler/dashBoardCalendar";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import LocalPharmacyOutlinedIcon from "@mui/icons-material/LocalPharmacyOutlined";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import nutLogo from "assets/NUT-outlined-icon.png";
import { useGetDashboardQuery } from "state/api";
import StatBox from "components/StatBox";
import Container from "@mui/material/Container";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import Avatar from "@mui/material/Avatar";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Chip from "@mui/material/Chip";
import clienteAxios from "../../config/clienteAxios";
import axios from "axios";
import { AirportShuttle, HolidayVillage } from "@mui/icons-material";

////////////////////////////// LINEAR PROGRESS //////////////////////////////////
const MIN = 0;
const MAX = 8;
// Function to normalise the values (MIN / MAX could be integrated)
const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN);

function BorderLinearProgress(props) {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 400 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "teal" : "#00A19B",
    },
  }));

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "85%", mr: 2 }}>
        <BorderLinearProgress
          variant="determinate"
          value={normalise(props.value)}
          sx={{ height: "25px", borderRadius: 3 }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="h3" color="text.secondary">
          <Avatar
            sx={{
              bgcolor: "#00A19B",
              color: "white",
              height: "60px",
              width: "60px",
            }}
          >
            <b>{`${Math.round(props.value)}/8`}</b>
          </Avatar>
        </Typography>
      </Box>
    </Box>
  );
}
///////////////////////////////////////////////////////////////////////////////

const Dashboard = () => {

  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { data } = useGetDashboardQuery();
  const [items, setItems] = useState([]);
  const [ejecutar, setEjecutar] = React.useState(true);
  const [data1, setData1] = React.useState([]);
  const [totalActividades, setTotalActividades] = React.useState([]);
  const [totalComunidades, setTotalComunidades] = React.useState([]);
  const [activides, setActividades] = React.useState("");
  const [nut, setNut] = React.useState([]);
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);
  const convocatorias = async () => {
    try {
      const { data2 } = await axios
        .get("/convocatoria/", {
          params: {
            id: 1,
          },
        })
        .then(function (response) {
          // setAlerta({})
console.log(response.data,"datos")
          setData1(response.data);
          setEjecutar(false);

          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };

  const beneficiariosNut = async () => {
    try {
      const { data2 } = await axios
        .get("/beneficiariosnut/")
        .then(function (response) {
          // setAlerta({})
          console.log(response.data);
          setNut(response.data);
          setEjecutar(false);

          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const getTotalComunidades = async () => {
    try {
      const { data2 } = await axios
        .get("/gettotalcomunidades/")
        .then(function (response) {
          // setAlerta({})
          console.log(response.data,"datos1")
          setTotalComunidades(response.data);
          setEjecutar(false);

          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  const getTotalActividades = async () => {
    try {
      const { data2 } = await axios
        .get("/gettotalactvidades/")
        .then(function (response) {
          // setAlerta({})
          console.log(response.data[0].total,"datos2")
          setTotalActividades(response.data[0].total);
          setEjecutar(false);

          return;
        })
        .catch(function (error) {
          console.log("error");
          return;
        });
    } catch (error) {
      return;
    }
  };
  var actv = [];
  if (ejecutar) {
    convocatorias();
    beneficiariosNut();
    getTotalComunidades(); 
    getTotalActividades();
  } else {
    for (let i = 0; i < data1.length; i++) {
      let dateStart = data1[i].fecha + "T" + data1[i].horaEncuentro;

      let dateEnd = data1[i].fecha + "T" + data1[i].horaRetorno;
      let appointments = {
        title: data1[i].nombre,
        startDate: dateStart,
        endDate: dateEnd,
        id: data1[i].id,
        location: data1[i].locacion,
      };

      actv = actv.concat(appointments);
    }

    //activ(actv);
  }
  console.log(actv);
  useEffect(() => {
    setActividades(actv);
  }, []);
  console.log(actv);
  console.log(nut);

  ///////////////////////// LINEAR PROGRESS STATE /////////////////////////////////

  const [progress, setProgress] = React.useState(4);

  /*React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 8 ? 1 : prevProgress + 1));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);*/

  ////////////////////////////////////////////////////////////////////////////////

  if (actv == "" || !nut)
    return (
      <Container component="main" maxWidth="xs">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Typography variant={isSmallScreen ? "h4" : "h2" } sx={{ fontWeight: "bold", mt: 2 }}>
          Bienvenido(a), {items.nombre}{" "}
          <Chip label="Período de Prueba" color="error" sx={{ fontSize:isSmallScreen ? 8 : 10 }} hidden />
        </Typography>
      </FlexBetween>
      <Box
        mt="22px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="1.5rem"
          boxShadow={5}
          width="fit-content"
          //hidden
        >
          <Carousel />
          {/*<OverviewChart view="sales" isDashboard={true} />*/}
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="1.5rem"
          boxShadow={5}
          width="100%"
          hidden
        >
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
            <PolicyOutlinedIcon sx={{ fontSize: "25px", mr: 1 }} /> Estatus del
            Período de Prueba
          </Typography>
          <Divider />
          <Typography mt={isSmallScreen ? 2 : 4} variant="body1">
            Actualmente te encuentras en <b>Período de Prueba</b>. En esta
            sección puedes visualizar tu progreso hasta el momento para
            completarlo y ser un voluntario regular de <b>PALUZ</b>.
          </Typography>
          <Box mt={isSmallScreen ? 1 : 4} sx={{ width: "100%" }}>
            <BorderLinearProgress value={progress} />
          </Box>
          <Typography mt={isSmallScreen ? 2 : 5} variant="body2" color="text.secondary">
            * Para completar el período de prueba y ser un voluntario regular de{" "}
            <b>PALUZ</b>, debes asisitr a un total de <b>8 jornadas</b> en un
            lapso de <b>3 meses</b>.
          </Typography>
        </Box>
        <StatBox
          title="Beneficiados (Nutrición)"
          value={nut.total}
          increase={0}
          icon={
            <Avatar sx={{ width: 75, height: 75, bgcolor: "teal" }}>
              <Box component="img" src={nutLogo} height="45px" width="45px" />
            </Avatar>
          }
        />
        <StatBox
          title="Beneficiados (Salud)"
          value={0}
          increase={0}
          icon={
            <Avatar sx={{ width: 75, height: 75, bgcolor: "teal" }}>
              <LocalHospitalOutlinedIcon
                sx={{ color: "white", fontSize: "45px" }}
              />
            </Avatar>
          }
        />
        <StatBox
          title="Comunidades Alcanzadas"
          value={totalComunidades.length}
          increase={0}
          icon={
            <Avatar sx={{ width: 75, height: 75, bgcolor: "teal" }}>
              <HolidayVillage sx={{ color: "white", fontSize: "45px" }} />
            </Avatar>
          }
        />
        <StatBox
          title="Actividades Realizadas"
          value={totalActividades}
          increase={0}
          icon={
            <Avatar sx={{ width: 75, height: 75, bgcolor: "teal" }}>
              <AirportShuttle  sx={{ color: "white", fontSize: "45px" }} />
            </Avatar>
          }
        />

        {/* ROW 2 */}
        <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="1.5rem"
          height="fit-content"
          boxShadow={5}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
            <CalendarMonthOutlinedIcon sx={{ fontSize: "25px", mr: 1 }} />
            Actividades Programadas
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <DashBoardCalendar data={actv} />
        </Box>
        {/*<Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="1.5rem"
          height={505}
          boxShadow={5}
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
           Progreso del Proyecto
          </Typography>
          <BreakdownChart isDashboard={true} />
          <Typography
            p="0 0.6rem"
            fontSize="0.8rem"
            sx={{ color: theme.palette.secondary[200] }}
          >
            Resumen de los pacientes atendidos con respecto al target establecido.
          </Typography>
        </Box>*/}
      </Box>
    </Box>
  );
};

export default Dashboard;
