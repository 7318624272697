import React, { useState, useMemo, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Box, Divider, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import HomeIcon from "@mui/icons-material/Home";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import logo from "assets/paluz-logo.png";
import Fab from "@mui/material/Fab";
import PrintIcon from "@mui/icons-material/Print";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Restricted from "components/Restricted";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

const ReporteSuministrosEntrada = () => {
  const nav = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("prop");
  const proyectoConv = JSON.parse(token);

  const [items, setItems] = React.useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const rol = items.rol;

  //Theme
  const theme = useTheme();

  //BreadCrumbs
  function handleClickBreadCrumbs(event) {
    event.preventDefault();
  }

  //Routes
  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };
  const handleLinkSum = (event, message) => {
    if (message === "suministros") {
      nav("/suministros");
    }
  };
  let sol = JSON.parse(proyectoConv.solicitud);
  const [data, setData] = useState(sol);
  const totalSolicitado = data.reduce(
    (sum, value) =>
      typeof value.solicitado == "number"
        ? sum + value.solicitado
        : sum + Number(value.solicitado),
    0
  );
  const totalEntregado = data.reduce(
    (sum, value) =>
      typeof value.entregado == "number"
        ? sum + value.entregado
        : sum + Number(value.entregado),
    0
  );
  const totalDevuelto = data.reduce(
    (sum, value) =>
      typeof value.devuelto == "number"
        ? sum + value.devuelto
        : sum + Number(value.devuelto),
    0
  );
  const totalUsado = data.reduce(
    (sum, value) =>
      typeof value.usado == "number"
        ? sum + value.usado
        : sum + Number(value.usado),
    0
  );

  //Modal Handlers
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Configuración PDF
  const createPDF = async () => {
    const canvas = await html2canvas(document.querySelector("#pdf"));
    const image = { type: "PNG", quality: 0.98 };
    const margin = [0, 0.5];

    var imgWidth = 8.5;
    var pageHeight = 10.68;

    var innerPageWidth = imgWidth - margin[0] * 2;
    var innerPageHeight = pageHeight - margin[1] * 2;

    // Calcular número de páginas.
    var pxFullHeight = canvas.height;
    var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
    var nPages = Math.ceil(pxFullHeight / pxPageHeight);

    // Definir pageHeight por separado para que pueda ser ajustada en la página final.
    var pageHeight = innerPageHeight;

    // Crear un canvas de una página para dividir la imagen completa.
    var pageCanvas = document.createElement("canvas");
    var pageCtx = pageCanvas.getContext("2d");
    pageCanvas.width = canvas.width;
    pageCanvas.height = pxPageHeight;

    // Inicializar el PDF.
    var pdf = new jsPDF("p", "in", "letter");

    for (var page = 0; page < nPages; page++) {
      // Ajustar la página final para reducir el tamaño del archivo.
      if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
        pageCanvas.height = pxFullHeight % pxPageHeight;
        pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
      }

      // Mostrar la imagen.
      var w = pageCanvas.width;
      var h = pageCanvas.height;
      pageCtx.fillStyle = "white";
      pageCtx.fillRect(0, 0, w, h);
      pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

      // Añadir la página al PDF.
      if (page > 0) pdf.addPage();
      debugger;
      var imgData = pageCanvas.toDataURL("image/" + image.type, image.quality);
      pdf.addImage(imgData, image.type, 0, 0.5, imgWidth, pageHeight);
    }

    // Contar el número de páginas del PDF.
    /*const pageCount = pdf.internal.getNumberOfPages();
    for(var i = 0; i < pageCount; i++) { 
    pdf.setPage(i); 
    let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
    pdf.setFontSize(8);
    pdf.text('Pág. ' + pageCurrent + '/' + pageCount, 1, pdf.internal.pageSize.height - 0.5);
  }*/

    // Generar el PDF en una nueva ventana.
    window.open(
      pdf.output("bloburl", {
        filename: `reporte_asistencia_${new Date().toJSON().slice(0, 10)}.pdf`,
      }),
      "_blank"
    );
  };

  return (
    <div className="exclude">
      {rol === "1" || rol === "2" || rol === "5" || rol === "7" ? (
        <>
          <Box m="1.5rem 2.5rem">
            <Box
              role="presentation"
              onClick={handleClickBreadCrumbs}
              sx={{ mb: 3 }}
            >
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  href="/inicio"
                  onClick={(event) => handleLinkHome(event, "home")}
                >
                  <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Inicio
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  href="/suministros"
                  onClick={(event) => handleLinkSum(event, "suministros")}
                >
                  <InventoryOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Suministros
                </Link>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  <ContentPasteOutlinedIcon
                    sx={{ mr: 0.5 }}
                    fontSize="inherit"
                  />
                  Reporte de Entrada
                </Typography>
              </Breadcrumbs>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Box
                display="flex-end"
                sx={{
                  "& > :not(style)": { m: 1 },
                  position: "fixed",
                  bottom: "0",
                }}
              >
                <Fab variant="extended" color="error" onClick={handleOpen}>
                  <CancelRoundedIcon sx={{ mr: 1 }} />
                  Cancelar
                </Fab>
                <Fab variant="extended" color="success" onClick={createPDF}>
                  <PrintIcon sx={{ mr: 1 }} />
                  Imprimir
                </Fab>
              </Box>
            </Box>
            <div id="pdf">
              <Container>
                <Box
                  gridColumn="span 8"
                  gridRow="span 3"
                  backgroundColor="white"
                  borderRadius="1.5rem"
                  p="1.5rem"
                  mt="25px"
                  height="fit-content"
                >
                  <Box
                    sx={{
                      marginTop: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Grid container justifyContent="center">
                      <Grid item sm={5}>
                        <Box
                          display="flex"
                          component="img"
                          alt="logo"
                          src={logo}
                          height="90%"
                          width="60%"
                          alignSelf="flex-start"
                        ></Box>
                      </Grid>
                      <Grid item sm={5} mt={2}>
                        <Box>
                          <Typography
                            variant="h1"
                            textAlign="end"
                            sx={{ fontFamily: "Arial", color: "black" }}
                          >
                            <b>ALMACÉN</b>
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="h4"
                            textAlign="end"
                            sx={{ fontFamily: "Arial", color: "black" }}
                          >
                            <strong>
                              ENTRADA-SALIDA DE MATERIAL EN ALMACÉN
                            </strong>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box
                      width="95%"
                      height="5px"
                      mt={1}
                      backgroundColor="teal"
                    ></Box>
                    <Box width="95%" mt={1} sx={{ border: "1px solid black" }}>
                      <Grid container spacing={2}>
                        <Grid item sm={4}>
                          <Typography
                            mb={3}
                            mt={1}
                            ml={1}
                            color="black"
                            sx={{ fontFamily: "Arial", fontSize: 14 }}
                          >
                            <b>CÓDIGO DEL PROYECTO:</b>
                          </Typography>
                          <Typography
                            mb={3}
                            ml={1}
                            color="black"
                            sx={{ fontFamily: "Arial", fontSize: 14 }}
                          >
                            <b>COORDINADOR:</b>
                          </Typography>
                          <Typography
                            mb={3}
                            ml={1}
                            color="black"
                            sx={{ fontFamily: "Arial", fontSize: 14 }}
                          >
                            <b>ACTIVIDAD:</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={4}>
                          <Typography
                            textAlign="center"
                            mt={6}
                            color="black"
                            sx={{ fontFamily: "Arial", fontSize: 14 }}
                          >
                            <CheckBoxIcon sx={{ fontSize: 20 }} />
                            <b>ENTRADA</b>
                          </Typography>
                          <Typography
                            textAlign="center"
                            mt={1}
                            color="black"
                            sx={{ fontFamily: "Arial", fontSize: 14 }}
                          >
                            <CheckBoxOutlineBlankIcon sx={{ fontSize: 20 }} />
                            <b>SALIDA</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={4}>
                          <Typography
                            textAlign="end"
                            mt={1}
                            mr={1}
                            color="black"
                            sx={{ fontFamily: "Arial", fontSize: 14 }}
                          >
                            Código: PA-ALM-F-004
                          </Typography>
                          <Typography
                            textAlign="end"
                            mr={1}
                            color="black "
                            sx={{ fontFamily: "Arial", fontSize: 14 }}
                          >
                            Versión: 1
                          </Typography>
                          <Typography
                            textAlign="end"
                            mt={3}
                            mr={1}
                            color="black"
                            sx={{ fontFamily: "Arial", fontSize: 14 }}
                          >
                            <b>FECHA:</b> ({proyectoConv.fecha})
                          </Typography>
                          <Typography
                            textAlign="end"
                            mt={5}
                            mr={1}
                            color="black"
                            sx={{ fontFamily: "Arial", fontSize: 14 }}
                          >
                            Pág. 2/2
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box width="95%" mt={1} sx={{ overflow: "auto" }}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "table",
                          tableLayout: "fixed",
                        }}
                      >
                        <TableContainer>
                          <Table sx={{ minWidth: 800 }}>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  width="45%"
                                  sx={{
                                    fontSize: 16,
                                    color: "black",
                                    border: "1px solid black",
                                    background: "lightGray",
                                    fontFamily: "Arial",
                                  }}
                                >
                                  <b>Descripción</b>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 16,
                                    color: "black",
                                    border: "1px solid black",
                                    background: "lightGray",
                                    fontFamily: "Arial",
                                  }}
                                >
                                  <b>Presentación</b>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 16,
                                    color: "black",
                                    border: "1px solid black",
                                    background: "lightGray",
                                    fontFamily: "Arial",
                                  }}
                                >
                                  <b>Fecha de Vencimiento</b>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  width="12%"
                                  sx={{
                                    fontSize: 16,
                                    color: "black",
                                    border: "1px solid black",
                                    background: "lightGray",
                                    fontFamily: "Arial",
                                  }}
                                >
                                  <b>N° de Lote</b>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  width="10%"
                                  sx={{
                                    fontSize: 16,
                                    color: "black",
                                    border: "1px solid black",
                                    background: "lightGray",
                                    fontFamily: "Arial",
                                  }}
                                >
                                  <b>Cantidad Asignada</b>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  width="10%"
                                  sx={{
                                    fontSize: 16,
                                    color: "black",
                                    border: "1px solid black",
                                    background: "lightGray",
                                    fontFamily: "Arial",
                                  }}
                                >
                                  <b>Cantidad Devuelta</b>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  width="10%"
                                  sx={{
                                    fontSize: 16,
                                    color: "black",
                                    border: "1px solid black",
                                    background: "lightGray",
                                    fontFamily: "Arial",
                                  }}
                                >
                                  <b>Cantidad Usada</b>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.map((x, i) => (
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      fontSize: 14,
                                      color: "black",
                                      border: "1px solid black",
                                      fontFamily: "Arial",
                                    }}
                                  >
                                    {x.articulo}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      fontSize: 14,
                                      color: "black",
                                      border: "1px solid black",
                                      fontFamily: "Arial",
                                    }}
                                  >
                                    {x.entregado}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      fontSize: 14,
                                      color: "black",
                                      border: "1px solid black",
                                      fontFamily: "Arial",
                                    }}
                                  >
                                    {x.entregado}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      fontSize: 14,
                                      color: "black",
                                      border: "1px solid black",
                                      fontFamily: "Arial",
                                    }}
                                  >
                                    {x.devuelto}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      fontSize: 14,
                                      color: "black",
                                      border: "1px solid black",
                                      fontFamily: "Arial",
                                    }}
                                  >
                                    {x.solicitado}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      fontSize: 14,
                                      color: "black",
                                      border: "1px solid black",
                                      fontFamily: "Arial",
                                    }}
                                  >
                                    {x.solicitado}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      fontSize: 14,
                                      color: "black",
                                      border: "1px solid black",
                                      fontFamily: "Arial",
                                    }}
                                  >
                                    {x.solicitado}
                                  </TableCell>
                                </TableRow>
                              ))}

                              {/* <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                    <TableCell align="center" sx={{ fontSize:14, color: 'black', border:'1px solid black', fontFamily:'Arial' }}>Datos</TableCell>
                                </TableRow> */}

                              {/*<TableRow>
                                    <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: 18, color:'white', background:'teal' }}>Total</TableCell>
                                    <TableCell align="center" sx={{ fontWeight: 'bold', color: 'black', background:'Gainsboro' }}>{totalSolicitado}</TableCell>
                                    <TableCell align="center" sx={{ fontWeight: 'bold', color: 'black', background:'Gainsboro' }}> {totalEntregado}</TableCell>
                                    <TableCell align="center" sx={{ fontWeight: 'bold', color: 'black', background:'Gainsboro' }}> {totalDevuelto}</TableCell>
                                    <TableCell align="center" sx={{ fontWeight: 'bold', color: 'black', background:'Gainsboro' }}>{totalUsado}</TableCell>
                                  </TableRow>*/}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Box>
                    <Box width="95%" mt={2} sx={{ border: "1px solid black" }}>
                      <Box sx={{ borderBottom: "1px solid black" }}>
                        <Typography
                          mb={6}
                          mt={1}
                          ml={1}
                          color="black"
                          sx={{ fontSize: 14, fontFamily: "Arial" }}
                        >
                          <b>Observaciones:</b>
                        </Typography>
                      </Box>
                      <Grid
                        container
                        backgroundColor="teal"
                        sx={{ borderBottom: "1px solid black" }}
                      >
                        <Grid item sm={6}>
                          <Box width="95%">
                            <Box width="95%" mt={1} mb={1} ml={1}>
                              <Typography
                                sx={{
                                  color: "white",
                                  fontFamily: "Arial",
                                  fontSize: 16,
                                }}
                              >
                                <b>ENTREGADO POR:</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item sm={6}>
                          <Box width="95%">
                            <Box width="95%" mt={1} mb={1} ml={1}>
                              <Typography
                                sx={{
                                  color: "white",
                                  fontFamily: "Arial",
                                  fontSize: 16,
                                }}
                              >
                                <b>RECIBIDO POR:</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item sm={6}>
                          <Box width="95%">
                            <Typography
                              mb={3}
                              mt={1}
                              ml={1}
                              color="black"
                              sx={{ fontFamily: "Arial", fontSize: 14 }}
                            >
                              <b>NOMBRE: </b>
                            </Typography>
                            <Typography
                              mb={3}
                              mt={3}
                              ml={1}
                              color="black"
                              sx={{ fontFamily: "Arial", fontSize: 14 }}
                            >
                              <b>FIRMA: </b>
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          sx={{ borderLeft: "1px solid black" }}
                        >
                          <Box width="95%">
                            <Typography
                              mb={3}
                              mt={1}
                              ml={1}
                              color="black"
                              sx={{ fontFamily: "Arial", fontSize: 14 }}
                            >
                              <b>NOMBRE DEL RESPONSABLE DE ALMACÉN: </b>
                            </Typography>
                            <Typography
                              mb={3}
                              mt={3}
                              ml={1}
                              color="black"
                              sx={{ fontFamily: "Arial", fontSize: 14 }}
                            >
                              <b>FIRMA: </b>
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Container>
            </div>
          </Box>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-description"
                align="center"
                sx={{ mt: 2 }}
              >
                ¿Está seguro de cancelar esta operación?
              </Typography>
              <Divider sx={{ mt: 2 }}></Divider>
              <Grid
                container
                sx={{ mt: 1 }}
                spacing={2}
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleClose}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{ bgcolor: "teal", color: "white" }}
                    onClick={(event) => handleLinkSum(event, "suministros")}
                  >
                    Si
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </>
      ) : (
        <Box m="1.5rem 2.5rem">
          <Restricted />
        </Box>
      )}
    </div>
  );
};

export default ReporteSuministrosEntrada;
