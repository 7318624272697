import React, { useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Box, Button, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearchOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ListConvos = (datos) => {
  console.log(datos.datos.solicitudesPro);

  const nav = useNavigate();

  //Theme
  const theme = useTheme();

  //Tabs Handler
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "fecha",
        header: "Fecha de Solicitud",
      },
      {
        accessorKey: "nombre_solicitante",
        header: "Solicitante",
      },
      {
        accessorKey: "estado",
        header: "Estado",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={{
              backgroundColor:
                cell.getValue() === 1
                  ? "green"
                  : cell.getValue() === 2
                  ? "darkorange"
                  : "firebrick",
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "9ch",
              p: "0.25rem",
            }}
          >
            {cell.getValue() === 1
              ? "Creado"
              : cell.getValue() === 2
              ? "Procesado"
              : "Finalizado"}
          </Box>
        ),
      },
    ],
    []
  );

  const data = [
    {
      id: "1",
      firstName: "Dylan",
      middleName: "Sprouse",
      lastName: "Murray",
      address: "261 Erdman Ford",
      city: "East Daphne",
      state: "Kentucky",
      country: "United States",
    },
    {
      id: "2",
      firstName: "Raquel",
      middleName: "Hakeem",
      lastName: "Kohler",
      address: "769 Dominic Grove",
      city: "Vancouver",
      state: "British Columbia",
      country: "Canada",
    },
    {
      id: "3",
      firstName: "Ervin",
      middleName: "Kris",
      lastName: "Reinger",
      address: "566 Brakus Inlet",
      city: "South Linda",
      state: "West Virginia",
      country: "United States",
    },
    {
      id: "4",
      firstName: "Brittany",
      middleName: "Kathryn",
      lastName: "McCullough",
      address: "722 Emie Stream",
      city: "Lincoln",
      state: "Nebraska",
      country: "United States",
    },
    {
      id: "5",
      firstName: "Branson",
      middleName: "John",
      lastName: "Frami",
      address: "32188 Larkin Turnpike",
      city: "Charleston",
      state: "South Carolina",
      country: "United States",
    },
    {
      id: "6",
      firstName: "Branson",
      middleName: "John",
      lastName: "Frami",
      address: "32188 Larkin Turnpike",
      city: "Charleston",
      state: "South Carolina",
      country: "United States",
    },
    {
      id: "7",
      firstName: "Branson",
      middleName: "John",
      lastName: "Frami",
      address: "32188 Larkin Turnpike",
      city: "Charleston",
      state: "South Carolina",
      country: "United States",
    },
    {
      id: "8",
      firstName: "Branson",
      middleName: "John",
      lastName: "Frami",
      address: "32188 Larkin Turnpike",
      city: "Charleston",
      state: "South Carolina",
      country: "United States",
    },
    {
      id: "9",
      firstName: "Branson",
      middleName: "John",
      lastName: "Frami",
      address: "32188 Larkin Turnpike",
      city: "Charleston",
      state: "South Carolina",
      country: "United States",
    },
    {
      id: "10",
      firstName: "Branson",
      middleName: "John",
      lastName: "Frami",
      address: "32188 Larkin Turnpike",
      city: "Charleston",
      state: "South Carolina",
      country: "United States",
    },
    {
      id: "11",
      firstName: "Branson",
      middleName: "John",
      lastName: "Frami",
      address: "32188 Larkin Turnpike",
      city: "Charleston",
      state: "South Carolina",
      country: "United States",
    },
  ];

  //Routes
  const handleLinkAsig = (event, message, row) => {
    let pro = JSON.stringify(row.original);
    if (message === "asignar") {
      nav(`/procesarSolicitud?prop=${pro}`);
    }
  };
  const handleLinkEditSol = (event, message, row) => {
    let pro = JSON.stringify(row.original);

    if (message === "editar") {
      nav(`/editarSolicitudSum?prop=${pro}`);
    }
  };
  const handleLinkFinSol = (event, message, row) => {
    let pro = JSON.stringify(row.original);

    if (message === "fin") {
      nav(`/finalizarSolicitud?prop=${pro}`);
    }
  };

  const handleLinkReporte = (event, message, row) => {
    let pro = JSON.stringify(row.original);

    if (message === "reporte") {
      nav(`/reporteSuministros?prop=${pro}`);
    }
  };

  const handleLinkEntrada = (event, message, row) => {
    let pro = JSON.stringify(row.original);

    if (message === "entrada") {
      nav(`/reporteSumEntrada?prop=${pro}`);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Pendientes"
            icon={<AssignmentOutlinedIcon />}
            iconPosition="start"
            {...a11yProps(0)}
          />
          <Tab
            label="Procesadas"
            icon={<ContentPasteSearchOutlinedIcon />}
            iconPosition="start"
            {...a11yProps(1)}
          />
          <Tab
            label="Finalizadas"
            icon={<InventoryOutlinedIcon />}
            iconPosition="start"
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <MaterialReactTable
          columns={columns}
          data={datos.datos.solicitudes}
          enableRowActions
          positionActionsColumn="last"
          enableFullScreenToggle={false}
          localization={MRT_Localization_ES}
          renderRowActionMenuItems={({ closeMenu, row }) => [
            <MenuItem
              onClick={(event) => handleLinkEditSol(event, "editar", row)}
              sx={{ fontWeight: "bold" }}
            >
              <NoteAltOutlinedIcon sx={{ mr: 1 }} /> Editar Solicitud{" "}
            </MenuItem>,
            <MenuItem
              onClick={(event) => {
                handleLinkAsig(event, "asignar", row);
                closeMenu();
              }}
              sx={{ fontWeight: "bold" }}
            >
              {" "}
              Procesar Solicitud{" "}
            </MenuItem>,
            <MenuItem
              onClick={(event) => {
                handleLinkFinSol(event, "fin", row);
                closeMenu();
              }}
              sx={{ fontWeight: "bold" }}
            >
              {" "}
              Finalizar Solicitud{" "}
            </MenuItem>,
            <MenuItem
              onClick={(event) => handleLinkReporte(event, "reporte", row)}
              sx={{ fontWeight: "bold" }}
            >
              <ContentPasteOutlinedIcon sx={{ mr: 1 }} /> Generar Reporte{" "}
            </MenuItem>,
            <MenuItem
              onClick={(event) => handleLinkEntrada(event, "entrada", row)}
              sx={{ fontWeight: "bold" }}
            >
              {" "}
              Reporte de Entrada{" "}
            </MenuItem>,
          ]}
          muiTopToolbarProps={{
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiTableHeadCellProps={{
            align: "center",
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiBottomToolbarProps={{
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiTableBodyProps={{
            sx: {
              "& tr:nth-of-type(odd)": {
                backgroundColor: theme.palette.background.light,
              },
            },
          }}
          muiTableBodyCellProps={{
            align: "center",
          }}
          muiTablePaperProps={{
            elevation: 0,
          }}
          initialState={{
            pagination: {
              pageSize: 3,
              pageIndex: 0,
            },
          }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
            showFirstButton: false,
            showLastButton: false,
            SelectProps: {
              native: true,
            },
            labelRowsPerPage: "Número de filas visibles",
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MaterialReactTable
          columns={columns}
          data={datos.datos.solicitudesPro}
          enableFullScreenToggle={false}
          localization={MRT_Localization_ES}
          muiTopToolbarProps={{
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiTableHeadCellProps={{
            align: "center",
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiBottomToolbarProps={{
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiTableBodyProps={{
            sx: {
              "& tr:nth-of-type(odd)": {
                backgroundColor: theme.palette.background.light,
              },
            },
          }}
          muiTableBodyCellProps={{
            align: "center",
          }}
          muiTablePaperProps={{
            elevation: 0, //change the mui box shadow
            //customize paper styles
          }}
          initialState={{
            pagination: {
              pageSize: 3,
              pageIndex: 0,
            },
          }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
            showFirstButton: false,
            showLastButton: false,
            SelectProps: {
              native: true,
            },
            labelRowsPerPage: "Número de filas visibles",
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <MaterialReactTable
          columns={columns}
          data={datos.datos.solicitudesFin}
          enableFullScreenToggle={false}
          localization={MRT_Localization_ES}
          muiTopToolbarProps={{
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiTableHeadCellProps={{
            align: "center",
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiBottomToolbarProps={{
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiTableBodyProps={{
            sx: {
              "& tr:nth-of-type(odd)": {
                backgroundColor: theme.palette.background.light,
              },
            },
          }}
          muiTableBodyCellProps={{
            align: "center",
          }}
          muiTablePaperProps={{
            elevation: 0, //change the mui box shadow
            //customize paper styles
          }}
          initialState={{
            pagination: {
              pageSize: 3,
              pageIndex: 0,
            },
          }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
            showFirstButton: false,
            showLastButton: false,
            SelectProps: {
              native: true,
            },
            labelRowsPerPage: "Número de filas visibles",
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MaterialReactTable
          columns={columns}
          data={datos.datos.solicitudesFin}
          enableFullScreenToggle={false}
          localization={MRT_Localization_ES}
          muiTopToolbarProps={{
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiTableHeadCellProps={{
            align: "center",
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiBottomToolbarProps={{
            sx: {
              backgroundColor: theme.palette.background.alt,
            },
          }}
          muiTableBodyProps={{
            sx: {
              "& tr:nth-of-type(odd)": {
                backgroundColor: theme.palette.background.light,
              },
            },
          }}
          muiTableBodyCellProps={{
            align: "center",
          }}
          muiTablePaperProps={{
            elevation: 0, //change the mui box shadow
            //customize paper styles
          }}
          initialState={{
            pagination: {
              pageSize: 3,
              pageIndex: 0,
            },
          }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
            showFirstButton: false,
            showLastButton: false,
            SelectProps: {
              native: true,
            },
            labelRowsPerPage: "Número de filas visibles",
          }}
        />
      </TabPanel>
    </Box>
  );
};

export default ListConvos;
