import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import "dayjs/locale/es";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import Backdrop from "@mui/material/Backdrop";
import Restricted from "components/Restricted";
import CircularProgress from "@mui/material/CircularProgress";
import clienteAxios from "../../config/clienteAxios";

const EditarSalud = () => {
  const nav = useNavigate();

  const [items, setItems] = React.useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("token"));
    if (items) {
      setItems(items);
    }
  }, []);

  const rol = items.rol;

  //Theme
  const theme = useTheme();

  function handleClickBreadCrumbs(event) {
    event.preventDefault();
  }

  //DatePicker Value
  const [valueRecep, setValueRecep] = React.useState(null);
  const [valueResp, setValueResp] = React.useState(null);
  const [valueFechaAct, setValueFechaAct] = React.useState(null);

  //Modal Handlers
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  /*const [openAlert, setOpenAlert] = React.useState(false);
const handleOpenAlert = () => {
  setOpenAlert(true);
  handleCloseAlert();
}
const handleCloseAlert = () => setTimeout(() => {
  setOpenAlert(false);
  nav("/proyectos")
}, 2000)*/

  //Motivo de Consulta Input
  const [data, setData] = useState([{ motivoConsulta: "" }]);
  const [activar, setActivar] = useState(true);
  let contador = 0;

  const handleClick = () => {
    setData([...data, { motivoConsulta: "" }]);
    setActivar(true);
  };
  const handleChangeInput = (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...data];
    onchangeVal[i][name] = value;
    setData(onchangeVal);
    for (let i = 0; i < data.length; i++) {
      if (data[i].motivoConsulta !== "") {
      } else {
        contador = contador + 1;
      }
      if (contador !== 0) {
        setActivar(true);
      } else {
        setActivar(false);
      }
    }
  };
  const handleDelete = (i) => {
    const deleteVal = [...data];
    deleteVal.splice(i, 1);
    setData(deleteVal);
  };

  //Tratamiento Inputs
  const [data2, setData2] = useState([{ medicamento: "", dosis: "" }]);
  const [activar2, setActivar2] = useState(true);
  let contador2 = 0;

  const handleClick2 = () => {
    setData2([...data2, { medicamento: "", dosis: "" }]);
    setActivar2(true);
  };
  const handleChangeInput2 = (e, i) => {
    const { name, value } = e.target;
    const onchangeVal2 = [...data2];
    onchangeVal2[i][name] = value;
    setData2(onchangeVal2);
    for (let i = 0; i < data2.length; i++) {
      if (data2[i].medicamento !== "" && data2[i].dosis !== "") {
      } else {
        contador2 = contador2 + 1;
      }
      if (contador2 !== 0) {
        setActivar2(true);
      } else {
        setActivar2(false);
      }
    }
  };
  const handleDelete2 = (i) => {
    const deleteVal2 = [...data2];
    deleteVal2.splice(i, 1);
    setData2(deleteVal2);
  };

  //Routes
  const handleLinkCancelar = (event, message) => {
    if (message === "cancelar") {
      nav("/transcripcion");
    }
  };

  const handleLinkHome = (event, message) => {
    if (message === "home") {
      nav("/inicio");
    }
  };

  const handleLinkTranscripcion = (event, message) => {
    if (message === "transcripcion") {
      nav("/transcripcion");
    }
  };

  //Modal Styles
  const style = {
    position: "inherit",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 375,
    bgcolor: theme.palette.background.alt,
    border: "2px solid #000",
    borderRadius: 4,
    boxShadow: 1,
    p: 4,
  };

  //Inputs States
  const [valueNumPag, setValueNumPag] = useState("");
  const [valueCedula, setValueCedula] = useState("");
  const [tlf, setValueTlf] = useState("");
  const [valueRep, setValueRep] = useState("");
  const [valueNombre, setValueNombre] = useState("");
  const [valueApellido, setValueApellido] = useState("");
  const [valueGrupoEtnico, setValueGrupoEtnico] = useState("");
  const [valueMunicipo, setValueMunicipio] = useState("");
  const [valueComunidad, setValueComunidad] = useState("");
  const [valueEdad, setValueEdad] = useState("");
  const [valueTipoDisc, setValueTipoDisc] = useState("");
  const [valueDiagnostico1, setValueDiagnostico1] = useState("");
  const [valueDiagnostico2, setValueDiagnostico2] = useState("");
  const [valueDiagnostico3, setValueDiagnostico3] = useState("");
  const [valueMedico, setValueMedico] = useState("");
  const [comentAdicionales, setValueCA] = useState("");

  //Select States
  const [valueGenero, setValueGenero] = React.useState("");
  const handleChangeGenero = (event) => {
    setValueGenero(event.target.value);
  };

  const [valueTipoEnf, setValueTipoEnf] = React.useState("");
  const handleChangeValueTipoEnf = (event) => {
    setValueTipoEnf(event.target.value);
  };

  const [rangoEdadBenef, setRangoEdadBenef] = React.useState("");
  const handleChangeRangoEdad = (event) => {
    setRangoEdadBenef(event.target.value);
  };

  const [valueDisc, setDisc] = React.useState("");
  const handleChangeDisc = (event) => {
    setDisc(event.target.value);
  };

  //Alerts Handlers
  const [openAlert, setOpenAlert] = React.useState(true);
  const [openNotiError, setOpenNotiError] = React.useState(true);

  /*
if (!socios.isSuccess || !usuarios || !sector.isSuccess) return (
  <Container component="main" maxWidth="xs">
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  </Container>
);  */

  return (
    <Box m="1.5rem 2.5rem">
      {rol === "1" || rol === "2" || rol === "11" ? (
        <>
          {/*<Collapse in={openAlert}>
        <Alert
          variant="filled"
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ position:'fixed', right:12, zIndex:999, borderRadius:2, boxShadow:4 }}
        >
          ¡Datos guardados exitosamente!
        </Alert>
   </Collapse>
   <Collapse in={openNotiError}>
        <Alert
          variant="filled"
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenNotiError(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ position:'fixed', right:12, zIndex:999, borderRadius:2, boxShadow:4 }}
        >
          ¡Error al guardar datos!
        </Alert>
    </Collapse>*/}
          <Box
            gridColumn="span 2"
            gridRow="span 1"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p="1.25rem 1rem"
            flex="1 1 100%"
            borderRadius="0.55rem"
          >
            <CssBaseline />

            <Box
              role="presentation"
              onClick={handleClickBreadCrumbs}
              sx={{ mb: 3 }}
            >
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  href="/inicio"
                  onClick={(event) => handleLinkHome(event, "home")}
                >
                  <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Inicio
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  href="/transcripcion"
                  onClick={(event) =>
                    handleLinkTranscripcion(event, "transcripcion")
                  }
                >
                  <DriveFileRenameOutlineIcon
                    sx={{ mr: 0.5 }}
                    fontSize="inherit"
                  />
                  Transcripción
                </Link>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  <EditIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Editar Beneficiario (Salud)
                </Typography>
              </Breadcrumbs>
              <Typography
                variant="h3"
                component="div"
                sx={{ mt: 3, mb: 1, fontWeight: "bold" }}
              >
                Editar Beneficiario: [Nombre Beneficiario]
              </Typography>
            </Box>
            <Container>
              <Card
                sx={{
                  borderRadius: 8,
                  boxShadow: 5,
                  bgcolor: theme.palette.background.alt,
                }}
              >
                <CardContent>
                  <Box
                    component="form"
                    noValidate
                    /*onSubmit={handleSubmit}*/ sx={{ mt: 3 }}
                  >
                    <Box>
                      <Divider sx={{ mt: 4, mb: 3 }}>
                        <Chip label="INFO. GENERAL" />
                      </Divider>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            type="number"
                            name="numeroPagina"
                            required
                            fullWidth
                            id="numeroPagina"
                            label="Número de Página"
                            value={valueNumPag}
                            onChange={(event) =>
                              setValueNumPag(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="es"
                          >
                            <DesktopDatePicker
                              label="Fecha de Actividad"
                              name="fechaActividad"
                              inputFormat="DD/MM/YYYY"
                              value={valueFechaAct}
                              onChange={setValueFechaAct}
                              renderInput={(params) => {
                                return <TextField fullWidth {...params} />;
                              }}
                              clearable
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name="cedula"
                            required
                            fullWidth
                            id="cedula"
                            label="Cédula de Identidad"
                            value={valueCedula}
                            onChange={(event) =>
                              setValueCedula(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name="telefono"
                            required
                            fullWidth
                            id="telefono"
                            label="Número Telefónico"
                            value={tlf}
                            onChange={(event) =>
                              setValueTlf(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name="representante"
                            required
                            fullWidth
                            id="representante"
                            label="Nombre y Apellido del Representante"
                            value={valueRep}
                            onChange={(event) =>
                              setValueRep(event.target.value)
                            }
                          />
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 4, mb: 3 }}>
                        <Chip label="DATOS DEL BENEFICIARIO" />
                      </Divider>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label="Nombres"
                            fullWidth
                            required
                            value={valueNombre}
                            onChange={(event) =>
                              setValueNombre(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label="Apellidos"
                            fullWidth
                            required
                            value={valueApellido}
                            onChange={(event) =>
                              setValueApellido(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label="Grupo Etnico"
                            fullWidth
                            required
                            value={valueGrupoEtnico}
                            onChange={(event) =>
                              setValueGrupoEtnico(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label="Municipio"
                            fullWidth
                            required
                            value={valueMunicipo}
                            onChange={(event) =>
                              setValueMunicipio(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label="Comunidad"
                            fullWidth
                            required
                            value={valueComunidad}
                            onChange={(event) =>
                              setValueComunidad(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="es"
                          >
                            <DesktopDatePicker
                              label="Fecha de Nacimiento"
                              name="fechaNacimiento"
                              inputFormat="DD/MM/YYYY"
                              value={valueRecep}
                              onChange={setValueRecep}
                              renderInput={(params) => {
                                return <TextField fullWidth {...params} />;
                              }}
                              clearable
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label="Edad"
                            type="number"
                            fullWidth
                            required
                            value={valueEdad}
                            onChange={(event) =>
                              setValueEdad(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Rango de Edad
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={rangoEdadBenef}
                              label="Rango de Edad"
                              onChange={handleChangeRangoEdad}
                            >
                              <MenuItem value={"0-4 años"}>0-4 años</MenuItem>
                              <MenuItem value={"5-17 años"}>5-17 años</MenuItem>
                              <MenuItem value={"18-49 años"}>
                                18-49 años
                              </MenuItem>
                              <MenuItem value={"50 años o más"}>
                                50 años o más
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <FormControl fullWidth>
                            <InputLabel id="genero">Género</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={valueGenero}
                              label="Género"
                              onChange={handleChangeGenero}
                            >
                              <MenuItem value={"Femenino"}>Femenino</MenuItem>
                              <MenuItem value={"Masculino"}>Masculino</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <FormControl fullWidth>
                            <InputLabel id="discapacidad">
                              Discapacidad
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={valueDisc}
                              label="Discapacidad"
                              onChange={handleChangeDisc}
                            >
                              <MenuItem value={"Si"}>Si</MenuItem>
                              <MenuItem value={"No"}>No</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label="Tipo de Discapacidad"
                            value={valueTipoDisc}
                            onChange={(event) =>
                              setValueTipoDisc(event.target.value)
                            }
                            disabled={valueDisc !== "Si"}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 4 }}>
                        <Chip label="MOTIVO DE CONSULTA" />
                      </Divider>
                      {data.map((val, i) => (
                        <div>
                          <Grid
                            container
                            spacing={2}
                            sx={{ mt: 1 }}
                            justifyContent="center"
                          >
                            <Grid item xs={12} sm={8}>
                              <TextField
                                required
                                fullWidth
                                id="motivoConsulta"
                                label="Motivo de Consulta"
                                name="motivoConsulta"
                                value={val.motivoConsulta}
                                onChange={(e) => handleChangeInput(e, i)}
                              />
                            </Grid>
                            <Grid item xs={5} sm={1}>
                              <Tooltip title="Eliminar">
                                <IconButton
                                  aria-label="delete"
                                  size="large"
                                  color="error"
                                  onClick={() => handleDelete(i)}
                                >
                                  <DeleteIcon fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </div>
                      ))}
                      <Grid container justifyContent="center">
                        <Grid item xs={8} sm={2} sx={{ mt: 3 }}>
                          <Button
                            variant="contained"
                            disabled={activar}
                            sx={{
                              bgcolor: "teal",
                              color: "white",
                              borderRadius: 2,
                            }}
                            fullWidth
                            onClick={handleClick}
                          >
                            Añadir Más
                          </Button>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 4, mb: 3 }}>
                        <Chip label="DIAGNÓSTICO" />
                      </Divider>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            label="Diagnóstico 1"
                            fullWidth
                            required
                            value={valueDiagnostico1}
                            onChange={(event) =>
                              setValueDiagnostico1(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            label="Diagnóstico 2"
                            fullWidth
                            required
                            value={valueDiagnostico2}
                            onChange={(event) =>
                              setValueDiagnostico2(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            label="Diagnóstico 3"
                            fullWidth
                            required
                            value={valueDiagnostico3}
                            onChange={(event) =>
                              setValueDiagnostico3(event.target.value)
                            }
                          />
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 4 }}>
                        <Chip label="TIPO DE ENFERMEDAD" />
                      </Divider>
                      <Grid
                        container
                        spacing={2}
                        sx={{ mt: 1 }}
                        justifyContent="center"
                      >
                        <Grid item xs={12} sm={8}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Tipo de Enfermedad
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={valueTipoEnf}
                              label="Tipo de Enfermedad"
                              onChange={handleChangeValueTipoEnf}
                            >
                              <MenuItem value={"Enfermedad 1"}>
                                Enfermedad 1
                              </MenuItem>
                              <MenuItem value={"Enfermedad 2"}>
                                Enfermedad 2
                              </MenuItem>
                              <MenuItem value={"Enfermedad 3"}>
                                Enfermedad 3
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 4, mb: 3 }}>
                        <Chip label="TRATAMIENTO" />
                      </Divider>
                      {data2.map((val, i) => (
                        <div>
                          <Grid
                            container
                            spacing={2}
                            sx={{ mt: 1 }}
                            justifyContent="center"
                          >
                            <Grid item xs={12} sm={5}>
                              <TextField
                                required
                                fullWidth
                                id="medicamento"
                                label="Medicamento"
                                name="medicamento"
                                value={val.medicamento}
                                onChange={(e) => handleChangeInput2(e, i)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              <TextField
                                id="dosis"
                                label="Dosis"
                                fullWidth
                                required
                                name="dosis"
                                value={val.dosis}
                                onChange={(e) => handleChangeInput2(e, i)}
                              />
                            </Grid>
                            <Grid item xs={5} sm={1}>
                              <Tooltip title="Eliminar">
                                <IconButton
                                  aria-label="delete"
                                  size="large"
                                  color="error"
                                  onClick={() => handleDelete2(i)}
                                >
                                  <DeleteIcon fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </div>
                      ))}
                      <Grid container justifyContent="center">
                        <Grid item xs={8} sm={2} sx={{ mt: 3 }}>
                          <Button
                            variant="contained"
                            disabled={activar2}
                            sx={{
                              bgcolor: "teal",
                              color: "white",
                              borderRadius: 2,
                            }}
                            fullWidth
                            onClick={handleClick2}
                          >
                            Añadir Más
                          </Button>
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 4, mb: 3 }}>
                        <Chip label="ADICIONALES" />
                      </Divider>
                      <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Médico Tratante"
                            fullWidth
                            value={valueMedico}
                            onChange={(event) =>
                              setValueMedico(event.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Comentarios"
                            value={comentAdicionales}
                            fullWidth
                            multiline
                            maxRows={4}
                            onChange={(event) => setValueCA(event.target.value)}
                          />
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 5 }}></Divider>
                      <Grid
                        container
                        sx={{ mt: 2 }}
                        spacing={1}
                        justifyContent="center"
                      >
                        <Grid item sm={3} xs={6}>
                          <Button
                            variant="contained"
                            color="error"
                            size="large"
                            fullWidth
                            onClick={handleOpen}
                          >
                            Cancelar
                          </Button>
                        </Grid>
                        <Grid item sm={3} xs={6}>
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{ bgcolor: "teal", color: "white" }}
                            /*disabled={!valueName || !valueCodigo || !valueFI || !valueFF || !soc || sectorOpc=="" || !valueDescripcion || corP=="" || corLo=="" || corSum=="" || corMe=="" }*/ size="large"
                            fullWidth
                          >
                            Guardar
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                slotProps={{ backdrop: { style: { opacity: 0.2 } } }}
              >
                <Box sx={style}>
                  <Typography
                    variant="h6"
                    className="text-center"
                    sx={{ mb: 1.5 }}
                  >
                    ¿Está seguro de cancelar esta operación?
                  </Typography>
                  <Divider sx={{ mt: 2 }} />
                  <Grid
                    container
                    sx={{ mt: 1 }}
                    spacing={1}
                    justifyContent="flex-end"
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                      >
                        No
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        sx={{ bgcolor: "teal", color: "white" }}
                        onClick={(event) =>
                          handleLinkCancelar(event, "cancelar")
                        }
                      >
                        Si
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
            </Container>
          </Box>
        </>
      ) : (
        <Restricted />
      )}
    </Box>
  );
};
export default EditarSalud;
